import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';
import Tooltip, {TooltipProps} from '@material-ui/core/Tooltip';

import {ADD_CHATBLOCK_PATH, ADD_CHATBOT_PATH, BOT_ICON_WITH_ARROW_PATH} from '../CustomSVGPaths';

export interface ICustomIconProps extends SvgIconProps {
    path: string;
    tooltip?: string;
    tooltipProps?: TooltipProps;
}

// Wrapper for custom icons with tooltip option
const CustomIcon = ({classes, tooltip, tooltipProps, path, ...iconProps}: ICustomIconProps) => {
    const pathProps = {transform: ''};

    switch (path) {
        case BOT_ICON_WITH_ARROW_PATH:
            pathProps.transform = 'translate(0 4)';
            break;
        case ADD_CHATBLOCK_PATH:
        case ADD_CHATBOT_PATH:
            pathProps.transform = 'translate(-11 0)';
            break;
    }

    const Icon = (
        <SvgIcon {...iconProps} classes={classes}>
            <path d={path} {...pathProps} />
        </SvgIcon>
    );

    return !tooltip ? Icon : <Tooltip title={tooltip} {...tooltipProps} children={Icon} />;
};

export default CustomIcon;
