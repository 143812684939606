import {reset} from 'redux-form';

import translations from '../../../providers/TranslationProvider/translations';
import {IMessengerMenuSettings} from '../../../definitions/settings/messengerMenu/messengerMenu.definitions';
import {ENotificationLevel} from '../../../enums/notifications/ENotificationLevel';
import {FORM_NAME_MIA_KEYWORDS} from '../../../pages/SettingsPage/components/MiaSettings/config';
import {getMessengerMenuSettingsRequest, putMessengerMenuSettingsRequest} from '../../../requests/botRequests';
import {addNotification} from '../../notifications/notifications.actions';

export const MESSENGER_MENU_SETTINGS_GET = 'MESSENGER_MENU_SETTINGS_GET';

export const MESSENGER_MENU_SETTINGS_GET_FULFILLED = 'MESSENGER_MENU_SETTINGS_GET_FULFILLED';
export const MESSENGER_MENU_SETTINGS_PUT = 'MESSENGER_MENU_SETTINGS_PUT';

export const MESSENGER_MENU_SETTINGS_PUT_FULFILLED = 'MESSENGER_MENU_SETTINGS_PUT_FULFILLED';

export const getMessengerMenuSettings = () => ({
    type: MESSENGER_MENU_SETTINGS_GET,
    payload: getMessengerMenuSettingsRequest(),
});

export const putMessengerMenuSettings = (settings: IMessengerMenuSettings) => {
    const parsedKeywords = settings.keywords ? settings.keywords.map(keyword => keyword.keyword).join(',') : '';
    const parsedSettings = {
        title: settings.title,
        ...(parsedKeywords ? {keywords: parsedKeywords} : {}),
    };

    return {
        type: MESSENGER_MENU_SETTINGS_PUT,
        payload: putMessengerMenuSettingsRequest(parsedSettings),
    };
};

export const putMessengerMenuKeywordAndNotify = (settings: IMessengerMenuSettings) => async (dispatch: any) => {
    await dispatch(putMessengerMenuSettings(settings));

    dispatch(reset(FORM_NAME_MIA_KEYWORDS));
    dispatch(addNotification(translations.save_operation_success, ENotificationLevel.Success));
};
