export enum ERights {
    // Dashboard Alers
    // Settings > User properties column "Nur Admins"
    // Settings > User properties checkbox "Dieses Feld wird Ihren Agenten nicht angezeigt."
    // Settings > Channel > URL Shortener / Tracking
    // Settings > MIA > "Antwort bei fehlenden Inhalten"
    // Settings > MIA > "Anzahl Inhalte auf einmal abrufbar"
    // Statistics > CSV Download
    // Ticketlist column "Agent"
    // Userlist show restricted user properties
    // Userlist > CSV Download
    Administrate = 'administrate',

    ContentEdit = 'contentEdit', // Access to MIA page

    // Access to Settings pages
    // Flag "userCanSignAvv"
    // Flag "userCanProcessOrder"
    // Userlist Filter "Targeting"
    SettingsChannel = 'settingsChannel',

    StatisticsChats = 'statisticsChats', // Access to statistics page
    StatisticsTickets = 'statisticsTickets', // Show ticket statistics on dashboard page
    StatisticsAgents = 'statisticsAgents',
    StatisticsClicks = 'statisticsClicks',

    // Access to ticket list page
    // Access to single Tickets (Chatpage)
    TicketRead = 'ticketRead',

    // Chat
    ChatRead = 'chatRead',

    TicketReadClosed = 'ticketReadClosed', // ticket list > filter "closed tickets"

    // User Profile Settings: Push notifications for new tickets
    // ticket list column and filter "Agents"
    TicketReadOthers = 'ticketReadOthers',

    TicketShowAssignment = 'ticketShowAssignment', // Access to Assignment page
    TicketShowFilter = 'ticketShowFilter', // ticket list: show filter
    UserShowList = 'userShowList', // Show user list page

    BotGet = 'botGet', // access to text modules with status RELEASED
    BotEdit = 'botEdit', // access to to all text modules
    BotCreateDraft = 'botCreateDraft', // allows adding DRAFT text modules and edit own DRAFTS
    // botEditStatus
    // botRemove

    AgentGetOthers = 'agentGetOthers',
    ReadCustomFields = 'crmCustomerReadField',
}

export default ERights;