export const MIA_GET_CONTENT = 'MIA_GET_CONTENT';
export const MIA_GET_CONTENT_PENDING = 'MIA_GET_CONTENT_PENDING';
export const MIA_GET_CONTENT_FULFILLED = 'MIA_GET_CONTENT_FULFILLED';
export const MIA_GET_CONTENT_REJECTED = 'MIA_GET_CONTENT_REJECTED';
export const MIA_CREATE_CONTENT = 'MIA_CREATE_CONTENT';
export const MIA_CREATE_CONTENT_FULFILLED = 'MIA_CREATE_CONTENT_FULFILLED';
export const MIA_UPDATE_CONTENT = 'MIA_UPDATE_CONTENT';
export const MIA_UPDATE_CONTENT_FULFILLED = 'MIA_UPDATE_CONTENT_FULFILLED';
export const MIA_DELETE_CONTENT = 'MIA_DELETE_CONTENT';
export const MIA_DELETE_CONTENT_PENDING = 'MIA_DELETE_CONTENT_PENDING';
