import moment from 'moment';
import {createSelector} from 'reselect';

import {IActorSettings} from '../../definitions/actor/actor.definitions';
import {IAgent} from '../../definitions/agent/agent.definitions';
import {IEntityById} from '../../definitions/generic/generic.definitions';
import {
    EPeriodDataType,
    IAgentStats,
    ICustomChart,
    ILabelStats,
    ILabelStatsItem,
    IPeriodData,
    IStatisticsBlacklistAction,
    IStatisticsMau,
    IStatisticsState,
    ISum,
} from '../../definitions/statistics/statistics.definitions';
import {EChartChatAction} from '../../enums/chart/EChartChatAction';
import {EChartChatKpi, TChartChatKpiIn, TChartChatKpiOut, TChartKpiAll} from '../../enums/chart/EChartChatKpi';
import {EChartChatSender} from '../../enums/chart/EChartChatSender';
import {EChartInterval} from '../../enums/chart/EChartInterval';
import {EChartPeriod} from '../../enums/chart/EChartPeriod';
import {EChartSource} from '../../enums/chart/EChartSource';
import {
    EChartTicketKpi,
    KpiToChatChartSelectionMap,
    KpiToChatChartSelectionMapIn,
    KpiToChatChartSelectionMapOut,
} from '../../enums/chart/EChartTicketKpi';
import {IState} from '../root.reducer';
import {getChartPeriodWithRange} from '../../utils/statistic/statistic.utils';
import {EChartAutomationKpi} from "../../enums/chart/EChartAutomationKpi";
import {usesTicketSystem} from "../channel/channel.selectors";

const getStatistics = (state: IState) => state.statistics;

export const getIfStatisticsBlackListHasLoaded = (state: IState) => state.statistics.blackList.loaded;

const getDefaultPeriodData = (): IPeriodData => ({
    period: EChartPeriod.Last30Days,
    from: moment().subtract(30, 'days').startOf('day').unix(),
    to: moment().endOf('day').unix(),
});

const _getTicketsChartSettings = (actorSettings: IActorSettings): IPeriodData => ({
    ...(actorSettings?.ticketsChart || getDefaultPeriodData()),
    type: EPeriodDataType.TicketsDate,
});

export const getTicketsChartSettings = createSelector(
    [(state: IState) => state.actor.settings],
    _getTicketsChartSettings,
);

const _getStatisticsPageIsLoading = (statistics: IStatisticsState): boolean => {
    const chatsIsLoading = statistics?.chats?.isLoading || false;
    const ticketsIsLoading = statistics?.tickets?.isLoading || false;

    return chatsIsLoading && ticketsIsLoading;
};

export const getStatisticsPageIsLoading = createSelector([getStatistics], _getStatisticsPageIsLoading);

export const getNumUnassignedTickets = createSelector([getStatistics], (statistics: IStatisticsState): number =>
    statistics?.general?.tickets_unassigned_total || 0);
export const getNumberOfUnreadChats = (state: IState) => state.statistics.simpleChat?.unread_chats || 0;

// gets the customCharts Settings for this actor
// and filters them by the restrictions provided by the blackList (chatKPIs, ticketKPIs and interval)
// returns a filtered list
const _getNonBlackListedCustomChartSettings = (
    customCharts: ICustomChart[],
    isLoading: boolean,
    interval?: EChartInterval[],
    action?: IStatisticsBlacklistAction,
): ICustomChart[] => {
    // guaranteed arrays, even if we get empty or crap data
    const forbiddenIntervals = interval || [];
    const forbiddenTicketActions = action?.tickets || [];
    const forbiddenChatActions = action?.chats || [];

    // filter old/obsolete charts
    const customChartsWithValidKpi = customCharts.filter((customChart: ICustomChart) => {
        return customChart.source === EChartSource.Chats
            ? !!(customChart.kpi && Object.values(EChartChatKpi).includes(customChart.kpi as EChartChatKpi))
            : customChart.source === EChartSource.Tickets
                ? !!(customChart.kpi && Object.values(EChartTicketKpi).includes(customChart.kpi as EChartTicketKpi))
                : true;
    });

    const allForbiddenKpi: (EChartChatKpi | EChartTicketKpi)[] = [...forbiddenTicketActions, ...forbiddenChatActions];
    // customChart KPI is null when it is a ticket chart, in that case the ticket_type actually stores the KPI
    const filteredByKpi = customChartsWithValidKpi.filter((customChart: ICustomChart) =>
        customChart.kpi
            ? !allForbiddenKpi.includes(customChart.kpi)
            : customChart.ticket_type && !allForbiddenKpi.includes(customChart.ticket_type),
    );

    // filter by forbidden periods
    return filteredByKpi.filter((customChart: ICustomChart) => !forbiddenIntervals.includes(customChart.interval));
};

export const getNonBlackListedCustomChart = createSelector(
    [
        (state: IState) => state.actor.settings.customCharts || [],
        (state: IState) => state.statistics.blackList.isLoading,
        (state: IState) => state.statistics.blackList.interval,
        (state: IState) => state.statistics.blackList.action,
    ],
    _getNonBlackListedCustomChartSettings,
);

export const TicketSourceMenuItems = [
    EChartTicketKpi.TotalOpenedTickets,
    EChartTicketKpi.TotalClosedTickets,
    EChartTicketKpi.AverageDurationUntilTicketAnswered,
    EChartTicketKpi.AverageDurationUntilTicketClosed,
];

const _getTicketsStatisticsBlacklist = (blacklistAction?: IStatisticsBlacklistAction): EChartTicketKpi[] => {
    if (!blacklistAction?.tickets?.length) {
        return [];
    }
    return blacklistAction.tickets.filter(ticketKpi => TicketSourceMenuItems.includes(ticketKpi));
};

export const getTicketsStatisticsBlacklist = createSelector(
    [(state: IState) => state.statistics.blackList.action],
    _getTicketsStatisticsBlacklist,
);

export type TMappedChatsStatisticsBlacklist = {
    actions: EChartChatAction[];
    senders: EChartChatSender[];
};

const _getMappedChatsStatisticsBlacklist = (action?: IStatisticsBlacklistAction): TMappedChatsStatisticsBlacklist => {
    if (!action?.chats?.length) {
        return {
            actions: [],
            senders: [],
        };
    }

    const mappedList = action.chats
        // there a lot more backend entries used than we use here. therefore we check with our entries to filter the unused ones
        .filter((blackListedChatAction: TChartKpiAll) => KpiToChatChartSelectionMap[blackListedChatAction])
        // mapping form the backend KPI array to the nomenclature used within the MCP system
        .map((blackListedChatAction: TChartKpiAll) => KpiToChatChartSelectionMap[blackListedChatAction]);

    return {
        actions: [...new Set(mappedList.map(item => item.action))], // a Set will not allow duplicate values so it is a very easy option to filter
        senders: mappedList.map(item => item && item.sender!).filter(item => item),
    };
};

export const getMappedChatsStatisticsBlacklist = createSelector(
    [(state: IState) => state.statistics.blackList.action],
    _getMappedChatsStatisticsBlacklist,
);

// determines if EVERY chat option we have is forbidden for chatStats in and out
const _getIfCanUseChatStats = (action?: IStatisticsBlacklistAction): { in: boolean; out: boolean } => {
    if (!action?.chats?.length) {
        return {
            in: true,
            out: true,
        };
    }

    const blackListKpis = action.chats; // compiler can remember shit when scope changes. This minimum pain solution also makes it more readable
    const ChartChatKpiOutArray = Object.keys(KpiToChatChartSelectionMapOut) as TChartChatKpiOut[]; // moron compiler forgets everything when using object.keys
    const ChartChatKpiInArray = Object.keys(KpiToChatChartSelectionMapIn) as TChartChatKpiIn[]; // moron compiler forgets everything when using object.keys

    // if we can remove all of our options we can NOT use any chats out
    const anyOutAllowed: boolean = ChartChatKpiOutArray.filter(kpi => !blackListKpis.includes(kpi)).length !== 0;
    const anyInAllowed: boolean = ChartChatKpiInArray.filter(kpi => !blackListKpis.includes(kpi)).length !== 0;

    return {
        in: anyInAllowed,
        out: anyOutAllowed,
    };
};

export const getIfCanUseChatStats = createSelector(
    [(state: IState) => state.statistics.blackList.action],
    _getIfCanUseChatStats,
);

const _getMauStatistics = (mau: IStatisticsMau): { previous: number; current: number; diff: number } => {
    const keys = Object.keys(mau).map(Number);

    if (keys.length === 0) {
        return {
            previous: 0,
            current: 0,
            diff: 0,
        };
    }

    const previous = mau[keys[keys.length - 2]]?.all?.total ?? 0;
    const current = mau[keys[keys.length - 1]]?.all?.total ?? 0;
    const diff = current - previous;

    return {
        previous,
        current,
        diff,
    };
};

export const getMauStatistics = createSelector([(state: IState) => state.statistics.mia.mau], _getMauStatistics);

const _getAgentStats = (agentIds: number[], stats: IAgentStats, agents: IEntityById<IAgent>, activeChannel: number) => {
    const ids =
        agentIds.length > 0
            ? agentIds
            : (Object.values(agents) as IAgent[])
                .filter(agent => agent.all_channels || agent.channels.includes(activeChannel))
                .map(agent => agent.id);

    return ids
        .map(id => {
            return {
                id,
                name: agents[id].name,
                ...stats[id],
            };
        });
};

export const getAgentStats = createSelector(
    [
        (state: IState) => state.statistics.agents.filter,
        (state: IState) => state.statistics.agents.stats,
        (state: IState) => state.entities.agents.byId,
        (state: IState) => state.channel.id,
    ],
    _getAgentStats,
);

export const getChartSettings = createSelector(
    [
        getTicketsChartSettings,
        (state: IState) => state.accounting.invoice_period,
        () => undefined, // we must provide the third parameter to omit TS error
    ],
    getChartPeriodWithRange,
);

const _getLabelsDataSet = (stats: ILabelStats) => {
    if (!stats) {
        console.warn("No stats to consume.");
        return [];
    }
    return Object.keys(stats).map(timestamp => {
        const date = moment.unix(Number(timestamp)).format('L');

        const labels = Object.keys(stats[Number(timestamp)]).reduce((reduced, currentLabelId) => {
            const item = stats[Number(timestamp)][Number(currentLabelId)];
            return {
                ...reduced,
                [item.label]: item.count,
            };
        }, {});

        return {
            date,
            ...labels,
        };
    });
};

export const getLabelsDataSet = createSelector([(state: IState) => state.statistics.labels.stats], _getLabelsDataSet);

const _getLabelsDataSum = (stats: ILabelStats) => {
    if (!stats) {
        console.warn("No stats to consume.");
        return {};
    }
    return Object.values(stats).reduce((acc: ISum, items: { [labelId: number]: ILabelStatsItem }) => {
        return Object.values(items).reduce((innerAcc: ISum, currentItem: ILabelStatsItem) => {
            if (innerAcc[currentItem.label]) {
                return {
                    ...innerAcc,
                    [currentItem.label]: innerAcc[currentItem.label] + currentItem.count,
                };
            }
            return {
                ...innerAcc,
                [currentItem.label]: currentItem.count,
            };
        }, acc);
    }, {} as ISum);
};

export const getLabelsDataSum = createSelector([(state: IState) => state.statistics.labels.stats], _getLabelsDataSum);

export const getAutomationStatisticsKPIs = (state: IState) => {
    const allKPIs = Object.values(EChartAutomationKpi);
    const isTicketSystemActive = usesTicketSystem(state);

    if (isTicketSystemActive) {
        return allKPIs;
    }

    return allKPIs.filter(kpi => ![
        EChartAutomationKpi.AutomationRatioAutoRoutedTickets,
        EChartAutomationKpi.AutomationTimeSavingAutorouting,
        EChartAutomationKpi.AutomationRatioTicketsWithChatBlocks,
        EChartAutomationKpi.AutomationRatioTicketsWithChatBot,
    ].includes(kpi));
};

const getLastMonth = (obj?: Object) => {
    return Object.keys(obj || {})
      .sort()
      .reverse();
};

export const getWhatsAppConversationsWaived = (state: IState) => {
    const [lastMonthKey] = getLastMonth(state?.statistics?.whatsappConversations);
    return state.statistics.whatsappConversations?.[lastMonthKey]?.account?.costs?.waived !== false;
};

export const getWhatsAppConversationsAccountStats = (state: IState) => {
    const [lastMonthKey] = getLastMonth(state?.statistics?.whatsappConversations);
    return state.statistics.whatsappConversations?.[lastMonthKey]?.account;
};

export const getConversationsStats = (state: IState) => {
    const [lastMonthKey] = getLastMonth(state?.statistics?.conversations);
    return state?.statistics?.conversations && state.statistics.conversations[lastMonthKey];
};
