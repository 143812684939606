import {Reducer} from 'redux';
import {IEntityById} from '../../definitions/generic/generic.definitions';
import {ITimezone, ITimezoneState} from '../../definitions/timezone/timezone.definitions';
import {TIMEZONES_GET_FULFILLED} from './timezone.types';

export const timezoneInitialState: ITimezoneState = {
    byId: {} as IEntityById<ITimezone>,
    allIds: [],
};

export const timezoneReducer: Reducer<ITimezoneState> = (state = timezoneInitialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case TIMEZONES_GET_FULFILLED: {
            if (!payload || !payload.length) {
                return state;
            }

            const timezones = payload.reduce(
                (timezones: IEntityById<ITimezone>, timezone: String, index: number) => ({
                    ...timezones,
                    [index]: {
                        id: index,
                        name: timezone,
                    },
                }),
                {},
            );

            return {
                byId: timezones,
                allIds: Object.keys(timezones).map(Number),
            };
        }
        default:
            break;
    }
    return state;
};
