import {Reducer} from 'redux';
import {IUiState} from '../../definitions/ui/ui.definitions';
import {
    ACTOR_GET_PROFILE,
    ACTOR_GET_PROFILE_REJECTED,
    ACTOR_LOGOUT_FULFILLED,
    ACTOR_LOGOUT_REJECTED,
} from '../actor/actor.types';
import {CHAT_PAGE_TOGGLE_BOT_PANEL} from '../bot/bot.types';
import {unblockUser} from '../user/user.actions';
import {
    TOGGLE_SSO,
    UI_CHANGE_DRAWER_STATE,
    UI_CHANGE_LOCKER,
    UI_CHANGE_SERVICE_UNAVAILABLE,
    UI_CHAT_PAGE_SET_CHAT_TO_DELETE,
    UI_CHAT_PAGE_SET_DRAGGED_PANEL,
    UI_HIDE_CONTENT,
    UI_HIDE_MENU,
    UI_SET_CONFIRM_DIALOG,
    UI_SHOW_CONTENT,
    UI_SHOW_MENU,
    UI_SHOW_MIA_FORM,
    UI_SHOW_MIA_PANEL,
    UI_TICKET_PAGE_TOGGLE_CHAT,
    UI_TICKET_PAGE_TOGGLE_PANELS,
    UI_UPDATE_AVAILABLE,
    UI_UPDATE_URL_SEARCH_PARAMS,
} from './ui.types';
import {
    disableSSOInStorage,
    enableSSOInStorage,
    isSSOEnabledInStorage,
    removeTokenFromStorage,
} from "../../utils/storage/storage.utils";

export const initialUiState: IUiState = {
    dialogs: {
        miaTeaserDialog: true,
    },
    drawerLocked: false,
    drawerOpen: false,
    chatPage: {
        botPanelOpen: false,
        draggedPanel: '',
        chatIdToDelete: null,
    },
    loading: {
        policies: false,
    },
    confirmDialog: {
        open: false,
        title: '',
        label: '',
        actionCreator: unblockUser,
    },
    showServiceUnavailable: false,
    xsFunctionality: {
        hideContent: true,
        hideMenu: false,
        showForm: true,
        showMenu: false,
    },
    showPanelsAndHideChat: false,
    language: window?.navigator?.language.split('-')[0] || 'en',
    ssoEnabled: isSSOEnabledInStorage(),
    urlSearchParams: new URLSearchParams(window.location.search),
};

export const uiReducer: Reducer<IUiState> = (state = initialUiState, action) => {
    switch (action.type) {
        case ACTOR_GET_PROFILE: {
            if (!action.meta.isSecondDevice) {
                return state;
            }
            return {
                ...state,
                isSecondDevice: action.meta.isSecondDevice,
            };
        }
        case UI_CHANGE_LOCKER:
            return {
                ...state,
                drawerLocked: action.payload,
            };
        case UI_SET_CONFIRM_DIALOG: {
            const {open, title, label, actionCreator} = action.payload;

            return {
                ...state,
                confirmDialog: {
                    ...state.confirmDialog,
                    open,
                    title: title ? title : state.confirmDialog.title,
                    label: label ? label : state.confirmDialog.label,
                    actionCreator: actionCreator ? actionCreator : state.confirmDialog.actionCreator,
                },
            };
        }
        case UI_CHAT_PAGE_SET_DRAGGED_PANEL: {
            return {
                ...state,
                chatPage: {
                    ...state.chatPage,
                    draggedPanel: action.payload,
                },
            };
        }
        case CHAT_PAGE_TOGGLE_BOT_PANEL: {
            return {
                ...state,
                chatPage: {
                    ...state.chatPage,
                    botPanelOpen: !state.chatPage.botPanelOpen,
                },
            };
        }

        case UI_CHANGE_DRAWER_STATE:
            return {
                ...state,
                drawerOpen: action.payload,
            };

        case UI_CHANGE_SERVICE_UNAVAILABLE:
            return {
                ...state,
                showServiceUnavailable: Boolean(action.payload),
            };

        case ACTOR_GET_PROFILE_REJECTED: {
            if (action.payload.status === 500) {
                return {
                    ...state,
                    showServiceUnavailable: Boolean(action.payload),
                };
            }
            return state;
        }

        case UI_HIDE_MENU:
            return {
                ...state,
                xsFunctionality: {
                    ...state.xsFunctionality,
                    hideMenu: true,
                },
            };

        case UI_SHOW_MENU:
            return {
                ...state,
                xsFunctionality: {
                    ...state.xsFunctionality,
                    hideMenu: false,
                },
            };

        case UI_HIDE_CONTENT:
            return {
                ...state,
                xsFunctionality: {
                    ...state.xsFunctionality,
                    hideContent: true,
                },
            };

        case UI_SHOW_CONTENT:
            return {
                ...state,
                xsFunctionality: {
                    ...state.xsFunctionality,
                    hideContent: false,
                },
            };

        case UI_SHOW_MIA_FORM:
            return {
                ...state,
                xsFunctionality: {
                    ...state.xsFunctionality,
                    showForm: true,
                    showMenu: false,
                },
            };

        case UI_SHOW_MIA_PANEL:
            return {
                ...state,
                xsFunctionality: {
                    ...state.xsFunctionality,
                    showForm: false,
                    showMenu: true,
                },
            };

        case UI_TICKET_PAGE_TOGGLE_PANELS: {
            return {
                ...state,
                showPanelsAndHideChat: true,
            };
        }

        case UI_TICKET_PAGE_TOGGLE_CHAT: {
            return {
                ...state,
                showPanelsAndHideChat: false,
            };
        }

        case UI_CHAT_PAGE_SET_CHAT_TO_DELETE:
            return {
                ...state,
                chatPage: {...state.chatPage, chatIdToDelete: action.payload},
            };

        case UI_UPDATE_AVAILABLE: {
            return {
                ...state,
                updateAvailable: true,
            };
        }

        case TOGGLE_SSO: {
            if (state.ssoEnabled) {
                disableSSOInStorage();

            } else {
                enableSSOInStorage();
            }

            removeTokenFromStorage();
            window.location.reload();

            return state;
        }

        // make sure SSO is enabled after logout (if SSO was disabled during session due to token login)
        case ACTOR_LOGOUT_REJECTED:
        case ACTOR_LOGOUT_FULFILLED: {
            enableSSOInStorage();
            return {
                ...state,
                ssoEnabled: true,
            };
        }

        case UI_UPDATE_URL_SEARCH_PARAMS: {
            const newUrlSearchParams = new URLSearchParams(state.urlSearchParams);

            state.urlSearchParams?.forEach((value, key) => {
                newUrlSearchParams.set(key, value);
            });

            return {
                ...state,
                urlSearchParams: newUrlSearchParams,
            };
        }

        default:
            return state;
    }
};
