const isUpdateStreamAction = (message: any, action?: string, startsWith?: () => boolean) => {
    if (!action) {
        throw new Error(`Missing argument 'action' in isUpdateStreamType. Got ${action}`);
    }

    if (!startsWith) {
        return message.action === action;
    }

    return message.action.startsWith(action);
};

const isUpdateStreamMethod = (message: any, method?: any) => {
    if (!method) {
        return true;
    }

    if (Array.isArray(method)) {
        return method.some(oneMethod => oneMethod === message.method);
    }

    return message.method === method;
};

export const isUpdateStream = (message: any, action?: string, method?: any, startsWith?: () => boolean) =>
    !!(message && isUpdateStreamAction(message, action, startsWith) && isUpdateStreamMethod(message, method));

/**
 * Create redux-form compatible event object to pass to onChange handlers
 *
 * Because redux-form will check if it is an event by the existence of stopPropagation and preventDefault, we simply
 * fake them, so redux-form's isEvent method will get the value from the target.value instead returning the whole event
 * see: https://github.com/redux-form/redux-form/blob/0485967d7f98b349e850570a2f2f3a4124f392d8/src/ConnectedField.js#L97
 * and: https://github.com/redux-form/redux-form/blob/master/src/events/isEvent.js
 *
 * @param targetName
 * @param targetValue
 * @param otherEventProps
 */
export const createFakeEvent = (targetName: undefined | string, targetValue: any, otherEventProps?: any) =>
    ({
        ...otherEventProps,
        target: {
            ...otherEventProps?.target,
            name: targetName,
            value: targetValue,
        },
        stopPropagation: () => {},
        preventDefault: () => {},
    } as any);
