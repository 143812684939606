import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {getIsConnected} from '../../../../../../redux/connection/connection.selectors';
import IconButton from '@material-ui/core/IconButton';
import BrandedIconSinch from '../../../../../../components/BrandedIconsSinch/BrandedIconSinch';
import {Tooltip, useTheme} from "@material-ui/core";
import translations from "../../../../../../providers/TranslationProvider/translations";
import {isActorAuthenticated, isActorLoggingOut} from "../../../../../../redux/actor/actor.selectors";

export const SocketConnectionState = () => {
    const theme = useTheme();
    const isConnected = useSelector(getIsConnected);
    const isAuthorized = useSelector(isActorAuthenticated);
    const isLoggingOut = useSelector(isActorLoggingOut);
    const [acknowledged, setAcknowledged] = useState(false);
    const [waitForConnecting, setWaitForConnecting] = useState(true);

    useEffect(() => {
        if (!isAuthorized) {
            setWaitForConnecting(true);
            return;
        }

        if (!isConnected) {
            setWaitForConnecting(true);

            const to = setTimeout(() => {
                setWaitForConnecting(false);
                setAcknowledged(false);
            }, 2000);

            return () => clearTimeout(to);
        } else {
            setWaitForConnecting(false);
        }
    }, [isConnected, isAuthorized]);

    // wait a bit for showing up if not connected, otherwise it would blink up on startup
    useEffect(() => {
        if (isAuthorized) {
            setTimeout(() => setWaitForConnecting(false), 10000);
        }
    }, [isAuthorized]);

    if (!isAuthorized || isLoggingOut || isConnected) {
        return null;
    }

    const tooltip = translations.socket_disconnected_tooltip;
    const tooltipOpen = waitForConnecting ? false : !acknowledged || undefined;

    return (
        <Tooltip title={tooltip} open={tooltipOpen} placement={'right'} onClick={() => setAcknowledged(true)}>
            <IconButton disabled color={"inherit"} focusRipple={!acknowledged}>
                <BrandedIconSinch menuIcon='Operators' fill={`${theme.palette.error.light} !important`}/>
            </IconButton>
        </Tooltip>
    );
};
