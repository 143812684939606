import {ChangeEvent, FC, lazy, MutableRefObject, ReactNode, Suspense, useState} from 'react';

import Box from '@material-ui/core/Box';
import {StandardTextFieldProps} from '@material-ui/core/TextField/TextField';
import {createFakeEvent} from '../../../utils/redux/redux.utils';

import InputAdornmentEnd from '../../InputAdornmentEnd/InputAdornmentEnd';
import CustomTextField from '../CustomTextField/CustomTextField';
import {CircularProgress} from '@material-ui/core';
// lazy imports
const AnimatedEmojiPicker = lazy(() => import('../../AnimatedEmojiPicker/AnimatedEmojiPicker'));

export interface ICustomTextFieldProps extends StandardTextFieldProps {
    classes?: {
        root?: string;
    };
    className?: string;
    identifier?: string;
    fullWidth?: boolean;
    setFocusTextField?: (value: boolean) => void;
    validateUntouched?: boolean;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
    focus?: boolean;
    InputProps?: any;
    meta?: any;
    onMount?: (inputRef: MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>) => void;
}

const LoaderFallback = () => <Box p='12px'><CircularProgress size={18} color='inherit' /></Box>;

const CustomEmojiTextField: FC<ICustomTextFieldProps> = (
    {
        id,
        setFocusTextField,
        validateUntouched,
        startAdornment,
        FormHelperTextProps,
        value,
        InputProps,
        endAdornment,
        meta,
        ...props
    },
) => {
    const text = (value as string) || '';
    const [selectionStart, setSelectionStart] = useState(text.length);
    const [selectionEnd, setSelectionEnd] = useState(text.length);
    const [inputRef, setInputRef] = useState<MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>>();

    const handleMount = (inputRef: MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>) => {
        setInputRef(inputRef);

        if (props.onMount) {
            props.onMount(inputRef);
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (props.onChange) {
            props.onChange(event);
        }
    };

    const handleSelect = (event: any) => {
        setSelectionStart(inputRef?.current?.selectionStart || 0);
        setSelectionEnd(inputRef?.current?.selectionEnd || 0);

        if (props.onSelect) {
            props.onSelect(event);
        }
    };

    const handleAddEmoji = (emoji: any) => {
        const nativeEmoji = emoji.native;
        const newValue = text.slice(0, selectionStart) + nativeEmoji + text.slice(selectionEnd);

        setSelectionStart(selectionStart + nativeEmoji.length);
        setSelectionEnd(selectionStart + nativeEmoji.length);

        // create fake event and trigger onChange
        if (props.onChange) {
            props.onChange(createFakeEvent(props.name, newValue));
        }
    };

    const pickerProps = {
        InputProps: {
            ...InputProps,
            endAdornment: !props.multiline ? (
                <Box mr={-1}>
                    <Suspense fallback={<LoaderFallback />}>
                        <AnimatedEmojiPicker disabled={props.disabled} onSelect={handleAddEmoji} />
                    </Suspense>
                    {endAdornment && endAdornment}
                </Box>
            ) : (
                <InputAdornmentEnd>
                    <Box mr={-1} mb={-2}>
                        <Suspense fallback={<LoaderFallback />}>
                            <AnimatedEmojiPicker disabled={props.disabled} onSelect={handleAddEmoji} />
                        </Suspense>
                        {endAdornment && endAdornment}
                    </Box>
                </InputAdornmentEnd>
            ),
        },
    };

    return (
        <CustomTextField
            {...pickerProps}
            {...props}
            value={text}
            onChange={handleChange}
            onMount={handleMount}
            onSelect={handleSelect}
            InputLabelProps={{shrink: true}}
        />
    );
};

export default CustomEmojiTextField;
