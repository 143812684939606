import {Reducer} from 'redux';

import {IAbsence, IAbsenceState} from '../../definitions/absence/abscence.definitions';
import {EUpdateStreamAction} from '../../enums/updateStream/EUpdateStreamAction';
import {EUpdateStreamMethod} from '../../enums/updateStream/EUpdateStreamMethod';
import {isUpdateStream} from '../../utils/redux/redux.utils';
import {IEntityById} from "../../definitions/generic/generic.definitions";
import {ABSENCE_GET_FULFILLED} from "./absence.types";
import {UPDATE_STREAM} from "../connection/connection.types";

export const absenceInitialState: IAbsenceState = {
    byId: {} as IEntityById<IAbsence>,
    allIds: [],
};

export const absenceReducer: Reducer<IAbsenceState> = (state = absenceInitialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case ABSENCE_GET_FULFILLED: {
            const {absences} = payload;
            if (!absences) {
                return state;
            }

            return {
                byId: absences,
                allIds: Object.keys(absences).map(Number),
            };
        }
        case UPDATE_STREAM: {
            if (
                isUpdateStream(payload.message, EUpdateStreamAction.BotAbsence, [
                    EUpdateStreamMethod.Post,
                    EUpdateStreamMethod.Put,
                ])
            ) {
                const messagePayload = payload.message?.payload;
                if (!messagePayload) {
                    return state;
                }

                const newByIds = {
                    ...state.byId,
                    [messagePayload.id]: messagePayload,
                };

                return {
                    byId: newByIds,
                    allIds: Object.keys(newByIds).map(Number),
                };
            }

            if (isUpdateStream(payload.message, EUpdateStreamAction.BotAbsence, EUpdateStreamMethod.Delete)) {
                const messagePayload = payload.message?.payload;
                if (!messagePayload) {
                    return state;
                }

                const {[messagePayload.id]: deleted, ...newById} = state.byId;

                return {
                    byId: newById,
                    allIds: Object.keys(newById).map(Number),
                };
            }

            return state;
        }
        default:
            return state;
    }
};
