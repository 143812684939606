
function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill="#272727"
                d="M31.54 6H16.46A3.46 3.46 0 0013 9.46V25.1a5.022 5.022 0 012-.001V9.46A1.46 1.46 0 0116.46 8h15.08A1.46 1.46 0 0133 9.46v3.639a5.101 5.101 0 012 .001V9.46A3.46 3.46 0 0031.54 6zM25.5 36.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
            ></path>
            <path
                fill="#272727"
                d="M13 37v1.55A3.46 3.46 0 0016.46 42h15.08A3.46 3.46 0 0035 38.54V25h-2v13.54A1.46 1.46 0 0131.54 40H16.46A1.46 1.46 0 0115 38.54V37h-2zM28 12h-8a1 1 0 010-2h8a1 1 0 010 2z"
            ></path>
            <path
                fill="#007171"
                fillRule="evenodd"
                d="M29 19c.463 2.282 2.581 4 5 4a5 5 0 000-10c-2.419 0-4.537 1.718-5 4h-2a4 4 0 00-4 4v6a2 2 0 01-2 2h-2c-.463-2.282-2.581-4-5-4a5 5 0 000 10c2.419 0 4.537-1.718 5-4h2a4 4 0 004-4v-6a2 2 0 012-2h2zm8-1a3 3 0 11-6 0 3 3 0 016 0zM17 30a3 3 0 10-6 0 3 3 0 006 0z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Icon;
