import {createStyles, makeStyles} from "@material-ui/core/styles";
import {FC} from "react";
import {Theme} from "@material-ui/core/styles/createTheme";
import Box from "@material-ui/core/Box";


const useStyles = makeStyles<Theme, OverflowScrollContainerProps>(theme => createStyles({
    root: {
        flex: 1, // spread inside flex containers
        maxHeight: ({marginTop = -2, marginBottom = -2}) => (!marginTop && !marginBottom)
            ? '100%'
            : `calc(100% + ${theme.spacing(-marginTop)}px + ${theme.spacing(-marginBottom)}px)`,
        // force scrollbar taking space, even if not visible
        overflowX: 'hidden',
        overflowY: 'scroll',
        // remove container padding because we have padding from scrollbar
        marginRight: ({marginRight = 2}) => -theme.spacing(marginRight),
        paddingRight: ({paddingRight = 1}) => theme.spacing(paddingRight),
        marginTop: ({marginTop = -2}) => theme.spacing(marginTop),
        marginBottom: ({marginBottom = -2}) => theme.spacing(marginBottom),

        '& > div': {
            marginTop: ({marginTop = -2}) => theme.spacing(-marginTop),
        },
    },
}));

interface OverflowScrollContainerProps {
    marginTop?: number;
    marginBottom?: number;
    marginRight?: number;
    paddingRight?: number;
}

const OverflowScrollContainer: FC<OverflowScrollContainerProps> = (props) => {
    const classes = useStyles(props);
    const {children} = props;

    return (
        <div className={classes.root}>
            <Box pb={2} /* extra space for scrolling */>
                {children}
            </Box>
        </div>
    );
};

OverflowScrollContainer.displayName = 'OverflowScrollContainer';

export default OverflowScrollContainer;