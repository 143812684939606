import {IState} from '../root.reducer';

export const getUi = (state: IState) => state.ui;

export const contentHidden = (state: IState) => state.ui.xsFunctionality.hideContent;
export const menuHidden = (state: IState) => state.ui.xsFunctionality.hideMenu;

export const getDrawerIsLocked = (state: IState) => state.ui.drawerLocked;
export const getDrawerIsOpen = (state: IState) => !state.ui.drawerLocked && state.ui.drawerOpen;


export const isSecondDevice = (state: IState) => Boolean(state.ui.isSecondDevice);

export const isSSOEnabled = (state: IState) => state.ui.ssoEnabled;
