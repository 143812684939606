
function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill="#FFBE3C"
                d="M15.293 28.759c.187.154.442.241.707.241.265 0 .52-.087.707-.241a.758.758 0 00.293-.583v-6.352a.758.758 0 00-.293-.583A1.118 1.118 0 0016 21c-.265 0-.52.087-.707.241a.758.758 0 00-.293.583v6.352c0 .219.105.428.293.583zM32.293 28.759c.187.154.442.241.707.241.265 0 .52-.087.707-.241a.758.758 0 00.293-.583v-6.352a.758.758 0 00-.293-.583A1.118 1.118 0 0033 21c-.265 0-.52.087-.707.241a.758.758 0 00-.293.583v6.352c0 .219.105.428.293.583z"
            ></path>
            <path
                fill="#FFBE3C"
                fillRule="evenodd"
                d="M21 39a2 2 0 012-2h3a2 2 0 012 2v2a2 2 0 01-2 2h-3a2 2 0 01-2-2v-2zm2 0h3v2h-3v-2z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                d="M17.073 13.06A10.53 10.53 0 0124.5 10a10.53 10.53 0 017.427 3.06A10.41 10.41 0 0135 20.44v7.627c0 1.422-.095 2.322-.304 3.144-.212.837-.551 1.638-1.108 2.88-.635 1.414-2.061 2.66-3.457 3.587a18.861 18.861 0 01-2.134 1.22A2 2 0 0128 39v2c.14-.029.24-.05.394-.08L28 41l.395-.081.006-.003.014-.006.049-.022.176-.08a20.858 20.858 0 002.598-1.464c1.477-.983 3.301-2.488 4.174-4.435.551-1.228.962-2.178 1.223-3.206.264-1.044.365-2.125.365-3.636V20.44a12.41 12.41 0 00-3.663-8.798A12.53 12.53 0 0024.5 8a12.53 12.53 0 00-8.837 3.642A12.41 12.41 0 0012 20.44v7.627a1 1 0 102 0V20.44a10.41 10.41 0 013.073-7.38z"
            ></path>
        </svg>
    );
}

export default Icon;
