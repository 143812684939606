import {Reducer} from 'redux';

import {IEntityById} from '../../definitions/generic/generic.definitions';
import {ITargetGroup, ITargetGroupState} from '../../definitions/targetGroup/targetGroup.definitions';
import {EUpdateStreamAction} from '../../enums/updateStream/EUpdateStreamAction';
import {EUpdateStreamMethod} from '../../enums/updateStream/EUpdateStreamMethod';
import {isUpdateStream} from '../../utils/redux/redux.utils';
import {UPDATE_STREAM} from '../connection/connection.types';
import {
    TARGET_GROUP_DELETE_FULFILLED,
    TARGET_GROUP_POST_FULFILLED,
    TARGET_GROUP_PUT_FULFILLED,
    TARGET_GROUPS_GET_FULFILLED,
} from './targetGroup.types';

export const targetGroupInitialState: ITargetGroupState = {
    byId: {} as IEntityById<ITargetGroup>,
    allIds: [],
};

export const targetGroupReducer: Reducer<ITargetGroupState> = (state = targetGroupInitialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case TARGET_GROUPS_GET_FULFILLED: {
            if (!payload) {
                return state;
            }

            const targetGroups = payload.reduce(
                (allTargetGroups: IEntityById<ITargetGroup>, currentTargetGroup: ITargetGroup) => ({
                    ...allTargetGroups,
                    [currentTargetGroup.id]: currentTargetGroup,
                }),
                {},
            );

            return {
                byId: targetGroups,
                allIds: Object.keys(targetGroups).map(Number),
            };
        }

        case TARGET_GROUP_PUT_FULFILLED:
        case TARGET_GROUP_POST_FULFILLED: {
            if (!payload || !payload.id) {
                return state;
            }

            const newByIds = {
                ...state.byId,
                [payload.id]: payload,
            };

            return {
                byId: newByIds,
                allIds: Object.keys(newByIds).map(Number),
            };
        }

        case TARGET_GROUP_DELETE_FULFILLED: {
            const {id} = payload;
            if (!id) {
                return state;
            }

            const {[id]: deleted, ...newById} = state.byId;

            return {
                byId: newById,
                allIds: Object.keys(newById).map(Number),
            };
        }

        case UPDATE_STREAM: {
            if (
                isUpdateStream(payload.message, EUpdateStreamAction.Targeting, [
                    EUpdateStreamMethod.Post,
                    EUpdateStreamMethod.Put,
                ])
            ) {
                const item = payload.message?.payload;
                if (!item) {
                    return state;
                }

                const newByIds = {
                    ...state.byId,
                    [item.id]: item,
                };

                return {
                    byId: newByIds,
                    allIds: Object.keys(newByIds).map(Number),
                };
            }

            if (isUpdateStream(payload.message, EUpdateStreamAction.Targeting, EUpdateStreamMethod.Delete)) {
                const id = payload.message?.payload?.id;
                if (!id) {
                    return state;
                }

                const {[id]: deleted, ...newById} = state.byId;

                return {
                    byId: newById,
                    allIds: Object.keys(newById).map(Number),
                };
            }

            return state;
        }
        default:
            return state;
    }
};
