import 'url-polyfill';
import 'formdata-polyfill';
import 'core-js/features/object/entries';
import ReactDOM from 'react-dom';
import {Provider, useSelector} from 'react-redux';
import {applicationStore} from "./redux/root.reducer";
import App from './App';
import AppWrapper from "./providers/AppWrapper/AppWrapper";
import registerServiceWorker from './registerServiceWorker';
import {BrowserRouter, useLocation} from 'react-router-dom';
import keycloak, {keycloakInitOptions} from "./sso/keycloak";
import {ReactKeycloakProvider, useKeycloak} from '@react-keycloak/web';
import {AuthClientError, AuthClientEvent, AuthClientTokens} from "@react-keycloak/core/lib/types";
import {useCallback, useState} from 'react';
import AutoLoginHandler from "./components/AutoLoginHandler/AutoLoginHandler";
import {EBaseRoutes} from "./enums/routes/ERoutes";
import LoadingPage from "./pages/LoadingPage/LoadingPage";
import {isSSOEnabled} from "./redux/ui/ui.selectors";
import {toggleSSO} from "./redux/ui/ui.actions";
import ThemeProvider from "./providers/ThemeProvider/ThemeProvider";
import {CssBaseline} from '@material-ui/core';
import {removeTokenFromStorage, updateTokenInStorage} from "./utils/storage/storage.utils";
import {updateDeviceIdInStorage} from "@sinch-engage/mcp-update-stream";
import {getUrlParameter} from "./utils/global/global.utils";
import {QUERY_PARAM_DEVICE_ID, QUERY_PARAM_TOKEN} from "./definitions/router/router.definitions";
import {MCP_TRANSLATION_MODULE} from "./providers/TranslationProvider/TranslationProvider";

// import necessary moment locale files
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/pt';
import 'moment/locale/it';
import {BASE_URL} from "./config";
import useSecurityCheck from "./utils/hooks/useSecurityCheck";

// print totally necessary ASCII logo (yes we need this)
console.info(`
   ___   __                   _
  / _ \\ / /  ___  ___  ___   (_)__ __
 / ___// _ \\/ _ \\/ -_)/ _ \\ / / \\ \\ /
/_/   /_//_/\\___/\\__//_//_//_/ /_\\_\\

`);

// print version info
console.info(`environment: ${process.env.NODE_ENV}`);
console.info(`api base url: ${BASE_URL}`);

// print all environment variables in non-production
if (process.env.NODE_ENV !== 'production') {
    console.info('ENVIRONMENT VARIABLES', process.env);
}

// if token in URL set (reseller login), then remove token from storage
const tokenFromUrl = getUrlParameter(QUERY_PARAM_TOKEN);
if (tokenFromUrl) {
    removeTokenFromStorage();
}

// if device ID in URL set (secondary device login), then save it in storage
const deviceIdFromUrl = getUrlParameter(QUERY_PARAM_DEVICE_ID);

if (deviceIdFromUrl) {
    updateDeviceIdInStorage(deviceIdFromUrl);
}

// disable SSO for secondary device and token login
if (tokenFromUrl || deviceIdFromUrl) {
    if (isSSOEnabled(applicationStore.getState())) {
        applicationStore.dispatch(toggleSSO());
    }
}

const render = (Component: any) => {
    return ReactDOM.render(
        <Provider store={applicationStore}>
            <BrowserRouter>
                <Component/>
            </BrowserRouter>
        </Provider>,
        document.getElementById('root'),
    );
};

const initialDataOptIns = {actorProfile: false};

// Redirect to appengage.sinch.com except for forwarding pages
if (
    window.location.hostname === 'app.messengerpeople.com'
    && !window.location.pathname.startsWith(EBaseRoutes.ForwardWidget)
    && !window.location.pathname.startsWith(EBaseRoutes.ForwardFlyer)
) {
    console.info('Redirecting to appengage.sinch.com');
    window.location.href = 'https://appengage.sinch.com';
} else {
    console.info('Not redirecting to appengage.sinch.com');
}

export const urlNeedsAuth = (pathname = window.location.pathname) => {
    return !(
        pathname.startsWith(EBaseRoutes.ForwardWidget)
        || pathname.startsWith(EBaseRoutes.ForwardFlyer)
        || pathname.startsWith(EBaseRoutes.Registration)
        || pathname.startsWith(EBaseRoutes.ResetPassword)
        || pathname.startsWith(EBaseRoutes.ForgotPassword)
    );
};

const Index = () => {
    useSecurityCheck();

    const ssoEnabled = useSelector(isSSOEnabled);
    const pathname = useLocation().pathname;

    // we need to change state to force rendering when receiving a new token, therefore we use this counter.
    // Without this, a const token = getTokenFromStorage() wont trigger hooks when used as a dependency.
    const [, setTokenUpdateCount] = useState(0);
    const onUpdateToken = useCallback((tokens: AuthClientTokens) => {
        updateTokenInStorage(tokens.token);
        setTokenUpdateCount((value) => value + 1);
    }, []);

    const onKeycloakEvent = useCallback((eventType: AuthClientEvent, error?: AuthClientError) => {
        if (error) {
            console.error(eventType, error.error, error.error_description);

            if (error.error_description) {
                alert(`An error occurred: ${error.error}: ${error.error_description}`);
            } else if (error.error) {
                alert(`An error occurred: ${error.error}`);
            } else {
                alert(`An error occurred: ${eventType}`);
            }
        }
    }, []);

    if (urlNeedsAuth(pathname) && ssoEnabled) {
        return (
            <ReactKeycloakProvider
                authClient={keycloak}
                initOptions={keycloakInitOptions}
                onTokens={onUpdateToken}
                onEvent={onKeycloakEvent}
            >
                <WaitForKeycloak/>
            </ReactKeycloakProvider>
        );
    }

    return (
        <>
            <AutoLoginHandler/>
            <AppWrapper
                initialDataOptIns={initialDataOptIns}
                loadingContent={<LoadingPage text="loading translation..." show/>}
                translationModule={MCP_TRANSLATION_MODULE}
                useUpdateStreamOverMessageBus={false}
                enableUpdateStreamPerformanceCheck
            >
                {({isGlobalDataLoaded}) => (
                    <App globalDataLoaded={isGlobalDataLoaded}/>
                )}
            </AppWrapper>
        </>
    );
};


const initialDataOptInsSSO = {actorProfile: true};

const WaitForKeycloak = () => {
    const {keycloak, initialized} = useKeycloak();
    const token = keycloak?.token;

    // do not rely on the token itself here because the token in storage is available after the rendering
    if (!initialized || !token) {
        return (
            <ThemeProvider>
                <CssBaseline/>
                <LoadingPage text={"checking authentication..."} show/>
            </ThemeProvider>
        );
    }

    return (
        <AppWrapper
            initialDataOptIns={initialDataOptInsSSO}
            loadingContent={<LoadingPage text="Loading translation..." show/>}
            translationModule={MCP_TRANSLATION_MODULE}
            useUpdateStreamOverMessageBus={false}
            enableUpdateStreamPerformanceCheck
        >
            {({isGlobalDataLoaded}) => (
                <App globalDataLoaded={isGlobalDataLoaded}/>
            )}
        </AppWrapper>
    );
};

render(Index);

registerServiceWorker();
