import {FC} from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import {MessengerIconProps} from '../MessengerIcon';
import translations from '../../../providers/TranslationProvider/translations';
import IMessage from "./Svgs/IMessage";

interface IMessageIconProps extends Omit<MessengerIconProps, 'messenger'> {
    invertedColor?: boolean;
}

const IMessageIcon: FC<IMessageIconProps> = props => {
    const {tooltip, invertedColor, ...rest} = props;

    const icon = (
        <SvgIcon {...rest} >
            <IMessage />
        </SvgIcon>
    );

    return tooltip ? <Tooltip title={translations.iMessage}>{icon}</Tooltip> : icon;
};

export default IMessageIcon;
