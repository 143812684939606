export const SETTINGS_CREATE_NOTIFICATION_TEMPLATE = 'SETTINGS_CREATE_NOTIFICATION_TEMPLATE';
export const SETTINGS_CREATE_NOTIFICATION_TEMPLATE_PENDING = 'SETTINGS_CREATE_NOTIFICATION_TEMPLATE_PENDING';
export const SETTINGS_CREATE_NOTIFICATION_TEMPLATE_FULFILLED = 'SETTINGS_CREATE_NOTIFICATION_TEMPLATE_FULFILLED';
export const SETTINGS_CREATE_NOTIFICATION_TEMPLATE_REJECTED = 'SETTINGS_CREATE_NOTIFICATION_TEMPLATE_REJECTED';
export const SETTINGS_DELETE_NOTIFICATION_TEMPLATE = 'SETTINGS_DELETE_NOTIFICATION_TEMPLATE';
export const SETTINGS_DELETE_NOTIFICATION_TEMPLATE_PENDING = 'SETTINGS_DELETE_NOTIFICATION_TEMPLATE_PENDING';
export const SETTINGS_DELETE_NOTIFICATION_TEMPLATE_REJECTED = 'SETTINGS_DELETE_NOTIFICATION_TEMPLATE_REJECTED';
export const SETTINGS_DELETE_NOTIFICATION_TEMPLATE_FULFILLED = 'SETTINGS_DELETE_NOTIFICATION_TEMPLATE_FULFILLED';
export const SETTINGS_UPDATE_NOTIFICATION_TEMPLATE = 'SETTINGS_UPDATE_NOTIFICATION_TEMPLATE';
export const SETTINGS_UPDATE_NOTIFICATION_TEMPLATE_PENDING = 'SETTINGS_UPDATE_NOTIFICATION_TEMPLATE_PENDING';
export const SETTINGS_UPDATE_NOTIFICATION_TEMPLATE_FULFILLED = 'SETTINGS_UPDATE_NOTIFICATION_TEMPLATE_FULFILLED';
export const SETTINGS_UPDATE_NOTIFICATION_TEMPLATE_REJECTED = 'SETTINGS_UPDATE_NOTIFICATION_TEMPLATE_REJECTED';
export const SETTINGS_REVIEW_NOTIFICATION_TEMPLATE = 'SETTINGS_REVIEW_NOTIFICATION_TEMPLATE';
export const SETTINGS_REVIEW_NOTIFICATION_TEMPLATE_PENDING = 'SETTINGS_REVIEW_NOTIFICATION_TEMPLATE_PENDING';
export const SETTINGS_REVIEW_NOTIFICATION_TEMPLATE_FULFILLED = 'SETTINGS_REVIEW_NOTIFICATION_TEMPLATE_FULFILLED';
export const SETTINGS_REVIEW_NOTIFICATION_TEMPLATE_REJECTED = 'SETTINGS_REVIEW_NOTIFICATION_TEMPLATE_REJECTED';
export const SETTINGS_GET_NOTIFICATION_TEMPLATE = 'SETTINGS_GET_NOTIFICATION_TEMPLATE';
export const SETTINGS_GET_NOTIFICATION_TEMPLATE_PENDING = 'SETTINGS_GET_NOTIFICATION_TEMPLATE_PENDING';
export const SETTINGS_GET_NOTIFICATION_TEMPLATE_FULFILLED = 'SETTINGS_GET_NOTIFICATION_TEMPLATE_FULFILLED';
export const SETTINGS_GET_NOTIFICATION_TEMPLATE_REJECTED = 'SETTINGS_GET_NOTIFICATION_TEMPLATE_REJECTED';
export const SETTINGS_GET_NOTIFICATION_TEMPLATES = 'SETTINGS_GET_NOTIFICATION_TEMPLATES';
export const SETTINGS_GET_NOTIFICATION_TEMPLATES_PENDING = 'SETTINGS_GET_NOTIFICATION_TEMPLATES_PENDING';
export const SETTINGS_GET_NOTIFICATION_TEMPLATES_FULFILLED = 'SETTINGS_GET_NOTIFICATION_TEMPLATES_FULFILLED';
export const SETTINGS_GET_NOTIFICATION_TEMPLATES_REJECTED = 'SETTINGS_GET_NOTIFICATION_TEMPLATES_REJECTED';
export const SETTINGS_GET_NOTIFICATION_LANGUAGES = 'SETTINGS_GET_NOTIFICATION_LANGUAGES';
export const SETTINGS_GET_NOTIFICATION_LANGUAGES_PENDING = 'SETTINGS_GET_NOTIFICATION_LANGUAGES_PENDING';
export const SETTINGS_GET_NOTIFICATION_LANGUAGES_FULFILLED = 'SETTINGS_GET_NOTIFICATION_LANGUAGES_FULFILLED';
export const SETTINGS_GET_NOTIFICATION_LANGUAGES_REJECTED = 'SETTINGS_GET_NOTIFICATION_LANGUAGES_REJECTED';
export const SETTINGS_GET_NOTIFICATION_CATEGORIES = 'SETTINGS_GET_NOTIFICATION_CATEGORIES';
export const SETTINGS_GET_NOTIFICATION_CATEGORIES_PENDING = 'SETTINGS_GET_NOTIFICATION_CATEGORIES_PENDING';
export const SETTINGS_GET_NOTIFICATION_CATEGORIES_FULFILLED = 'SETTINGS_GET_NOTIFICATION_CATEGORIES_FULFILLED';
export const SETTINGS_GET_NOTIFICATION_CATEGORIES_REJECTED = 'SETTINGS_GET_NOTIFICATION_CATEGORIES_REJECTED';
