import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import List from '@material-ui/core/List';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Tooltip from '@material-ui/core/Tooltip';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';

import LinkButton from '../../../components/LinkButton/LinkButton';
import translations from '../../../providers/TranslationProvider/translations';
import {isActorAuthenticated} from '../../../redux/actor/actor.selectors';
import {changeDrawerState} from '../../../redux/ui/ui.actions';
import {getDrawerIsLocked, getDrawerIsOpen, isSecondDevice} from '../../../redux/ui/ui.selectors';
import logoDark from '../../../resources/img/logo_dark.svg';
import logoLight from '../../../resources/img/logo_light.svg';
import DrawerLock from './DrawerMenuList/DrawerMenuItems/BottomMenu/DrawerLock';
import SecondaryLogin from './DrawerMenuList/DrawerMenuItems/BottomMenu/SecondaryLogin';
import {SocketConnectionState} from './DrawerMenuList/DrawerMenuItems/BottomMenu/SocketConnectionState';
import {ChannelMenu} from './DrawerMenuList/DrawerMenuItems/ChannelMenu/ChannelMenu';
import {MainMenu} from './DrawerMenuList/DrawerMenuItems/MainMenu/MainMenu';
import {UserMenu} from './DrawerMenuList/DrawerMenuItems/UserMenu/UserMenu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {MultiChannelTicketAlerts} from './MultiChannelTicketAlerts';
import BrandedIconSinch from '../../../components/BrandedIconsSinch/BrandedIconSinch';
import LoaderSpinner from '../../../components/Loader/LoaderSpinner';
import {useTypedSelector} from '../../../utils/hooks/useTypedSelector';
import {useTheme} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: 1,
        },
        drawerPaper: {
            display: 'block',
            width: 248,
            paddingLeft: 0,
            overflowX: 'hidden',
            overflowY: 'auto',
            whiteSpace: 'nowrap',
            minHeight: '100%',
            backgroundColor: theme.palette.background.paper,
            transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',

            [theme.breakpoints.down('xs')]: {
                left: -80,
                width: 296,
                paddingLeft: 56,
            },
        },
        drawerPaper_close: {
            width: 80,
            transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, background-image 1s ease-in-out 0.5s',
        },
        drawerContent: {
            position: 'relative',
            paddingBottom: 104, // this padding preserves the space of the absolutely placed buttons
            minHeight: '100vh',
            display: 'flex',
            flexFlow: 'column nowrap',

            '& *': { // prevent user text selection inside drawer
                webkitUserSelect: 'none',
                userSelect: 'none',
            },

            '& a': {
                textDecoration: 'none',
                color: 'inherit',
            },
        },
        spinnerAndLogo: {
            height: 48, // the height of the container has to be static to avoid movement of menu during animation
            display: 'flex',
            padding: 0,
            maxWidth: '100%', // apple fix
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(3),
        },
        loaderContainer: {
            display: 'flex',
            justifyContent: 'center',
            transform: 'scale(0)',
            width: '0 !important',
            height: '0 !important',
            transition: '.3s ease-in-out',
            marginLeft: 0,
        },
        loaderContainerClosed: {
            display: 'flex',
            justifyContent: 'center',
            transform: 'scale(1)',
            width: '40px !important',
            height: '40px !important',
            transition: '.3s ease-in-out',
            marginLeft: 19,
        },
        logoContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            flex: 1,
            alignItems: 'center',
            maxWidth: '100%',  // apple fix
        },
        logoMcp: {
            maxWidth: 0,
            margin: 0,
            padding: 0,
        },
        logoMcpOpen: {
            flex: 1,
            margin: '0 8px',
        },
        logoContainerClosed: {
            transform: 'scale(0) translate(0, 0)',
        },
        menuList: {
            position: 'static',
            flex: 1,
            display: 'flex',
            flexFlow: 'column nowrap',
        },
        menuListOpen: {
            // TODO: implement animation for list items somehow
        },
        bottomButtons: {
            display: 'flex',
            justifyContent: 'flex-start',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            padding: '0 16px',
        },
        upperBottomButtons: {
            display: 'flex',
            justifyContent: 'flex-start',
            position: 'absolute',
            bottom: 56,
            width: '100%',
            padding: '0 16px',
        },
        icon: {
            width: 32,
            height: 32,
        },
        logo_icon: {
            width: 24,
            height: 24,
        },
        logo_name: {
            flex: 1,
            width: '100%',
            margin: `0 ${theme.spacing(2)}px`,
        },
        bottomLinkButton: {
            display: 'flex',
            padding: 12,
        },
    }),
);

export interface DrawerChildProps {
    isDrawerOpen: boolean;
}

export const Drawer = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const shouldBeOpen = useSelector(getDrawerIsOpen);
    const isLocked = useSelector(getDrawerIsLocked);
    const isAuthenticated = useSelector(isActorAuthenticated);
    const secondDevice = useSelector(isSecondDevice);
    const isGlobalLoading = useTypedSelector(state => !!state.loaderTransition.requestCounter);

    const viewPortWiderThanXS = useMediaQuery('(min-width: 600px)');
    const isTouchDevice = useRef(false);
    const theme = useTheme();
    const lightMode = theme.palette.type !== 'dark';

    const [closeTimeout, setCloseTimeout] = useState<ReturnType<typeof setTimeout>>();
    const [isOpen, setIsOpen] = useState(false);

    const handleOpenDrawer = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleTouchStart = useCallback(() => {
        isTouchDevice.current = true;
    }, []);

    const handleCloseDrawer = useCallback(() => {
        setIsOpen(false);
    }, []);

    const clearCloseTimeout = useCallback(() => {
        if (closeTimeout) {
            clearTimeout(closeTimeout);
            setCloseTimeout(undefined);
        }
    }, [closeTimeout]);

    const handleMouseOver = useCallback(() => {
        if (!isTouchDevice.current) {
            clearCloseTimeout();
            handleOpenDrawer();
        }
    }, [clearCloseTimeout, handleOpenDrawer]);

    const handleMouseOut = useCallback(() => {
        if (!isTouchDevice.current) {
            const timeout = setTimeout(handleCloseDrawer, 200);
            setCloseTimeout(timeout);
        }
    }, [handleCloseDrawer]);

    // if drawer state changes is store, update component state (controlled)
    useEffect(() => {
        setIsOpen(shouldBeOpen);
    }, [shouldBeOpen]);

    // if drawer closes, update store
    useEffect(() => {
        if (!isOpen) {
            dispatch(changeDrawerState(false));
        }
    }, [dispatch, isOpen]);

    // if drawer gets locked, close it
    useEffect(() => {
        if (isLocked) {
            setIsOpen(false);
        }
    }, [isLocked]);

    // clear timeout on unmount
    useEffect(
        () => () => {
            clearCloseTimeout();
        },
        [clearCloseTimeout],
    );

    // drawer is only open if it is not locked
    const open = !isLocked && isOpen;

    // classes used for animation
    const loaderAnimatedClass = clsx(classes.loaderContainer, !open && classes.loaderContainerClosed);
    const logoAnimatedClass = clsx(classes.logoContainer, !open && classes.logoContainerClosed);

    const drawerClassName = useMemo(
        () => ({paper: clsx(classes.drawerPaper, !open && classes.drawerPaper_close)}),
        [classes.drawerPaper, classes.drawerPaper_close, open],
    );

    return (
        <>
            {shouldBeOpen && (
                <Backdrop open={shouldBeOpen} invisible onClick={handleCloseDrawer} className={classes.backdrop}/>
            )}
            <SwipeableDrawer
                elevation={12}
                variant='permanent'
                classes={drawerClassName}
                open={open} // this does nothing
                onTouchStart={handleTouchStart}
                onClose={handleCloseDrawer}
                onOpen={handleOpenDrawer}
                onMouseEnter={handleMouseOver}
                onMouseLeave={handleMouseOut}
            >
                <div className={classes.drawerContent}>
                    <div className={classes.spinnerAndLogo}>
                        <div className={loaderAnimatedClass}>
                            <LoaderSpinner
                                proportions={open ? 24 : 40}
                                loading={!open && isGlobalLoading} // when drawer is open, the loader is invisible -> there is no point in wasting resources in animating it
                            />
                        </div>
                        <div className={logoAnimatedClass}>
                            <div className={logoAnimatedClass}>
                                <img src={lightMode ? logoDark : logoLight} className={classes.logo_name}
                                     alt='company_logo'/>
                            </div>
                        </div>
                    </div>
                    <List className={clsx(classes.menuList, open && classes.menuListOpen)} disablePadding>
                        {isAuthenticated && <UserMenu isDrawerOpen={open}/>}
                        <ChannelMenu isDrawerOpen={open}/>
                        <MainMenu isDrawerOpen={open}/>
                        <div className={classes.upperBottomButtons}>
                            <SocketConnectionState/>
                            <MultiChannelTicketAlerts/>
                            <Tooltip title={translations.help}>
                                <div>
                                    <LinkButton
                                        href={translations.help_center_link}
                                        icon={<BrandedIconSinch menuIcon='Idea'/>}
                                        className={classes.bottomLinkButton}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                        <div className={classes.bottomButtons}>
                            {viewPortWiderThanXS && <DrawerLock isLocked={isLocked}/>}
                            {!secondDevice && <SecondaryLogin/>}
                            <Tooltip title={translations.release_notes}>
                                <div>
                                    <LinkButton
                                        href='https://helpcenter.messengerpeople.com/de/messenger-communication-platform/release-notes'
                                        icon={<BrandedIconSinch menuIcon='Time'/>}
                                        className={classes.bottomLinkButton}
                                    />
                                </div>
                            </Tooltip>
                            <Tooltip title={translations.status_page_link}>
                                <div>
                                    <LinkButton
                                        href='https://status.messengerpeople.com/'
                                        icon={<BrandedIconSinch menuIcon='Announcement'/>}
                                        className={classes.bottomLinkButton}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                    </List>
                </div>
            </SwipeableDrawer>
        </>
    );
};
