import {IActor} from '../../definitions/actor/actor.definitions';
import {IUpdateStreamMessage} from '../../definitions/updateStream/updateStream.definitions';
import ERights from '../../enums/actor/ERights';
import {EUpdateStreamAction} from '../../enums/updateStream/EUpdateStreamAction';
import {EUpdateStreamMethod} from '../../enums/updateStream/EUpdateStreamMethod';
import {isUpdateStream} from '../redux/redux.utils';

export const isActorLoginMessage = (message: IUpdateStreamMessage) =>
    isUpdateStream(message, EUpdateStreamAction.ActorLogin, [EUpdateStreamMethod.Post]);

export const isActorDeviceLoginMessage = (message: IUpdateStreamMessage) =>
    isUpdateStream(message, EUpdateStreamAction.ActorDeviceLogin);

// triggered on logout only for the current agent -> for other tabs and secondary token
export const isActorLogoutMessage = (message: IUpdateStreamMessage) =>
    isUpdateStream(message, EUpdateStreamAction.ActorLogin, [EUpdateStreamMethod.Delete]);

export const isActorDeviceLogoutMessage = (message: IUpdateStreamMessage) =>
    isUpdateStream(message, EUpdateStreamAction.ActorDeviceLogin, [EUpdateStreamMethod.Delete]);

export const actorHasRights = (actor?: IActor, requiredRights?: ERights | ERights[], matchIfAny?: boolean) => {
    // Deny access if no actor given
    if (!actor) return false;

    const userRights = actor.rights || [];

    // allow everything for admins
    if (userRights.includes(ERights.Administrate)) {
        return true;
    }

    const userHasRights = userRights.length;
    const shouldCheckForRequiredRights = Array.isArray(requiredRights) ? requiredRights.length : !! requiredRights;

    // Allow access if no rights required
    if (!shouldCheckForRequiredRights) return true;

    // Deny access if actor has no rights or no required right to check (for coding security reasons)
    if (!userHasRights || !requiredRights) return false;

    if (typeof requiredRights === 'string') {
        return userRights.includes(requiredRights);
    }

    // Allow access if actor has all the required rights
    if (!matchIfAny)
        return requiredRights.every((requestedRight: ERights) => userRights.includes(requestedRight));

    // Allow access if actor has at least one of the required rights
    return requiredRights.some((requestedRight: ERights) => userRights.includes(requestedRight));
};
