import {PureComponent} from 'react';

import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Field, reduxForm, submit} from 'redux-form';

import DialogTemplate from '../../../../components/DialogTemplate/DialogTemplate';
import FormRow from '../../../../components/FormRow/FormRow';
import {renderTextField} from '../../../../components/ReduxFormInput/ReduxFormInput';
import translations from '../../../../providers/TranslationProvider/translations';
import {ISkill} from '../../../../definitions/skill/skill.definitions';
import {IState} from '../../../../redux/root.reducer';
import {validateRequired} from '../../../../utils/formValidations/formValidation.utils';
import {getDialogActionsConfig} from '../../../../utils/reduxForms/reduxForm.utils';

export const SKILLS_DIALOG_FORM = 'skillsDialogForm';

const validate = (values: ISkill) => ({
    name: validateRequired(values.name),
});

interface ISkillDialogInnerProps {
    open: boolean;
    skill: ISkill | null;
    handleClose: () => void;
    handleSave: (skill: ISkill) => void;
    submit: (form: string) => void;
    isEdit: boolean;
    initialValues?: object;
}

interface ISkillDialogOuterProps {
    open: boolean;
    skill: ISkill | null;
    handleClose(): void;
    handleSave: (skill: ISkill) => void;
}

export class SkillsDialog extends PureComponent<ISkillDialogInnerProps> {
    handleClose = () => {
        this.props.handleClose();
    };

    handleSave = () => {
        this.handleClose();
        return this.props.submit(SKILLS_DIALOG_FORM);
    };

    render() {
        const {open, handleClose, isEdit} = this.props;

        const title = isEdit ? translations.edit_skill : translations.new_skill;

        const actionsConfig = getDialogActionsConfig({
            props: this.props,
            onClose: this.handleClose,
            onSubmit: this.handleSave,
            isCreate: !isEdit,
        } as any);

        return (
            <DialogTemplate
                open={open}
                onClose={handleClose}
                ariaLabel="skills"
                title={title}
                closable={true}
                actionsConfig={actionsConfig}
                fullWidth
                maxWidth="xs"
                content={
                    <FormRow>
                        <Field name="name" component={renderTextField} label={translations.name} fullWidth autoFocus />
                    </FormRow>
                }
            />
        );
    }
}

const mapStateToProps = ({form}: IState, {skill}: ISkillDialogInnerProps) => {
    const currentValues = (form[SKILLS_DIALOG_FORM] && form[SKILLS_DIALOG_FORM].values) || {};

    return {
        initialValues: skill,
        isEdit: !!(currentValues && currentValues.id >= 0),
    };
};

const mapDispatchToProps = (dispatch: any, props: ISkillDialogInnerProps) => ({
    onSubmit: props.handleSave,
    submit: (form: string) => dispatch(submit(form)),
});

const form = {
    form: SKILLS_DIALOG_FORM,
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
    destroyOnUnmount: true,
    validate,
};

export default compose<ISkillDialogInnerProps, ISkillDialogOuterProps>(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(form),
)(SkillsDialog);
