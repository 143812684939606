import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import LoaderSpinner from './LoaderSpinner';

const useStyles = makeStyles(
    createStyles({
        container: {
            position: 'absolute',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            '& > div': {
                height: 'initial !important',
                overflow: 'initial !important',
            },
        },
    }),
);

/* uses the same proportions the LoadingPage uses for consistency */
const LoadingFallback = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <LoaderSpinner proportions={120} />
        </div>
    );
};

export default LoadingFallback;
