import {FC} from 'react';

import {URL_REGEX} from '../../config';
import LinkButton from '../LinkButton/LinkButton';
import Twemoji from '../Twemoji/Twemoji';

interface ILinkifyTextProps {
    text: string;
    emojify?: boolean;
    showFullUrl?: boolean;
}

export const LinkifyText: FC<ILinkifyTextProps> = ({text, emojify, showFullUrl}) => {
    const parseLinks = (text: string) => {
        if (!text) {
            return null;
        }
        const textParts = text.split(URL_REGEX);
        return textParts.map((part, index) => {
            if (part.search(URL_REGEX) >= 0) {
                const href = part.search('http') === 0 ? part : `https://${part}`;
                const hrefParts = href.split('//');
                const hostPart = hrefParts.length > 0 ? hrefParts[1] : href;
                const host = hostPart.indexOf('/') >= 0 ? hostPart.split('/')[0] : hostPart;

                return <LinkButton key={index} message={showFullUrl ? href : host} href={href} />;
            }
            return emojify ? <Twemoji key={index}>{part}</Twemoji> : part;
        });
    };

    return <>{parseLinks(text)}</>;
};

export default LinkifyText;
