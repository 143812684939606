import {useTypedSelector} from "../../utils/hooks/useTypedSelector";
import {QUERY_PARAM_SWITCH_CHANNEL} from "../../definitions/router/router.definitions";
import {logout} from "../../redux/actor/actor.actions";
import {getUrlParameter} from "../../utils/global/global.utils";
import translations from "../../providers/TranslationProvider/translations";
import {useEffect} from "react";
import {useDispatch} from "react-redux";

export const AutoLogoutHandler = () => {
    const dispatch = useDispatch();
    const securityCheckResponse = useTypedSelector(state => state.security);

    const {
        logout: doLogout,
        logoutReason,
        reload,
    } = securityCheckResponse;

    useEffect(() => {
        if (logoutReason && !getUrlParameter(QUERY_PARAM_SWITCH_CHANNEL)) {
            alert(translations[logoutReason]);
        }
    }, [dispatch, logoutReason]);

    useEffect(() => {
        if (doLogout) {
            console.info('Logout user...');
            dispatch(logout());
        }
    }, [dispatch, doLogout]);

    useEffect(() => {
        if (reload) {
            console.info('Page reloads...');
            window.location.reload();
        }
    }, [dispatch, reload]);

    return null;
};

export default AutoLogoutHandler;
