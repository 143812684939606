import {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import translations from '../../../../../../providers/TranslationProvider/translations';
import {logout} from '../../../../../../redux/actor/actor.actions';
import {DrawerMenuItem} from '../../DrawerMenuItem';
import MuiExitToAppIcon from '@material-ui/icons/ExitToApp';


export const UserMenuLogoutButton = () => {
    const dispatch = useDispatch();

    const handleClick = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    return (
        <DrawerMenuItem
            name={translations.logout}
            onClick={handleClick}
            icon={<MuiExitToAppIcon />}
            isSubItem
        />
    );
};
