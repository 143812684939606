import makeStyles from '@material-ui/core/styles/makeStyles';
import {FC, ReactChild} from 'react';

import Alert from '@material-ui/lab/Alert';
import {Color} from '@material-ui/lab/Alert/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import {ucfirst} from '../../utils/global/global.utils';
import {createStyles} from '@material-ui/core';
import {NECTARY_COLORS} from '../../theme/muiTheme';

export interface CustomAlertProps {
    variant?: Color;
    customTitle?: ReactChild;
    showTitle?: boolean;
    className?: string;
}



const useStyles = makeStyles(
    createStyles({
        message: {
            wordBreak: 'break-word',
        },
        success: {
            color: NECTARY_COLORS.stormy500,
            backgroundColor: NECTARY_COLORS.success200,
        },
        info: {
            color: NECTARY_COLORS.stormy500,
            backgroundColor: NECTARY_COLORS.informative200,
        },
        warning: {
            color: NECTARY_COLORS.stormy500,
            backgroundColor: NECTARY_COLORS.warning200,
        },
        error: {
            color: NECTARY_COLORS.stormy500,
            backgroundColor: NECTARY_COLORS.error200,
        },
    }),
);

const CustomAlert: FC<CustomAlertProps> = (
    {
        variant = 'info',
        customTitle = '',
        showTitle = false,
        className,
        children,
    },
) => {
    const classes = useStyles();
    const title = !customTitle && showTitle ? ucfirst(variant) : customTitle;

    return (
        <Alert severity={variant} className={className} classes={{root: classes[variant], message: classes.message}}>
            {title && <AlertTitle>{title}</AlertTitle>}
            {children}
        </Alert>
    );
};

export default CustomAlert;
