export const SKILLS_GET = 'SKILLS_GET';
export const SKILLS_GET_PENDING = 'SKILLS_GET_PENDING';
export const SKILLS_GET_FULFILLED = 'SKILLS_GET_FULFILLED';
export const SKILLS_GET_REJECTED = 'SKILLS_GET_REJECTED';

export const SKILL_POST = 'SKILL_POST';
export const SKILL_POST_FULFILLED = 'SKILL_POST_FULFILLED';

export const SKILL_PUT = 'SKILL_PUT';
export const SKILL_PUT_FULFILLED = 'SKILL_PUT_FULFILLED';

export const SKILL_DELETE = 'SKILL_DELETE';
export const SKILL_DELETE_FULFILLED = 'SKILL_DELETE_FULFILLED';

