import {FC} from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import {Link} from 'react-router-dom';

import {getTicketRoute} from '../../../../utils/routes/routes.utils';
import {useStyles} from './TicketCounter.styles';

interface IPendingCounterProps {
    ticketStats: {
        answered: number;
        assigned: number;
        critical: number;
        pending: number;
        overdue: number;
    };
    mostCriticalTicketId?: number;
}

const PendingCounter: FC<IPendingCounterProps> = props => {
    const {mostCriticalTicketId, ticketStats} = props;
    const classes = useStyles();

    const PendingCounterBox = (
        <Box color="text.primary" display="flex">
            <Typography variant="body2" children={ticketStats.pending} />
            {Boolean(ticketStats.critical) && (
                <>
                    <Typography variant="body2" className={classes.slash} children="/" />
                    <WarningIcon color="error" className={classes.warningIcon} />
                    <Typography color="error" variant="body2" children={ticketStats.critical} />
                </>
            )}
        </Box>
    );

    if (ticketStats.pending && mostCriticalTicketId) {
        return <Link to={getTicketRoute(mostCriticalTicketId)}>{PendingCounterBox}</Link>;
    }

    return PendingCounterBox;
};

export default PendingCounter;
