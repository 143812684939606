import {Reducer} from 'redux';
import {IMessengerMenuItem} from '../../../definitions/settings/messengerMenu/messengerMenu.definitions';
import {EMessengerMenuItemTypes} from '../../../enums/mia/EMessengerMenuItemTypes';
import {
    MESSENGER_MENU_ITEMS_DELETE_FULFILLED,
    MESSENGER_MENU_ITEMS_GET_FULFILLED,
    MESSENGER_MENU_ITEMS_POST_FULFILLED,
    MESSENGER_MENU_ITEMS_PUT_FULFILLED,
} from './messengerMenuItems.types';
import isEmpty from "lodash/isEmpty";

export const getMessengerMenuItemType = (menuItem: any) => {
    if (menuItem.topic_id && menuItem.topic_id > 0) {
        return EMessengerMenuItemTypes.BotTopic;
    }
    if (menuItem.pull) {
        return EMessengerMenuItemTypes.SendContents;
    }
    return EMessengerMenuItemTypes.Text;
};

export const messengerMenuItemsInitialState: IMessengerMenuItem[] = [];

export const messengerMenuItemsReducer: Reducer<IMessengerMenuItem[]> = (
    state = messengerMenuItemsInitialState,
    {type, payload},
) => {
    switch (type) {
        case MESSENGER_MENU_ITEMS_GET_FULFILLED:
        case MESSENGER_MENU_ITEMS_POST_FULFILLED:
        case MESSENGER_MENU_ITEMS_PUT_FULFILLED:
        case MESSENGER_MENU_ITEMS_DELETE_FULFILLED:
            return isEmpty(payload.menu_items)
                ? ([] as IMessengerMenuItem[])
                : payload.menu_items.map(
                      (item: any) =>
                          ({
                              id: item.id,
                              message: item.message,
                              topicId: item.topic_id,
                              position: item.position,
                              keyword: item?.keywords[0] || '',
                              itemType: getMessengerMenuItemType(item),
                              attachment: item.attachment || '',
                              attachment_type: item.attachment_type || '',
                              description: item.description || '',
                              targeting: item.targeting || 0,
                              visible: item.visible || 0,
                          } as IMessengerMenuItem),
                  );
        default:
            return state;
    }
};
