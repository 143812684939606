import {FC, memo} from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import {useSelector} from 'react-redux';

import {ILinkItem} from '../../definitions/statistics/statistics.definitions';
import {EMessenger} from '../../enums/messenger/EMessenger';
import {IState} from '../../redux/root.reducer';
import MessengerIcon from '../MessengerIcon/MessengerIcon';

const useStyles = makeStyles(() =>
    createStyles({
        tooltip: {
            backgroundColor: 'transparent',
        },
        detailsCount: {
            textAlign: 'right',
            padding: 0,
            paddingLeft: '1.25em',
        },
        clicks: {
            cursor: 'pointer',
        },
    }),
);

interface ILinkStatsProps {
    linkItem: ILinkItem;
}

const LinkStats: FC<ILinkStatsProps> = props => {
    const classes = useStyles();
    const messengers = useSelector((state: IState) => state.channel.messenger);
    const currentlyUsedMessengers = Object.keys(props.linkItem).filter(key => messengers[key as EMessenger] === 1);

    return (
        <Tooltip
            placement="right-start"
            interactive
            classes={{tooltip: classes.tooltip}}
            title={
                <Paper elevation={1}>
                    <List dense={true}>
                        {currentlyUsedMessengers.map(messenger => (
                            <ListItem key={messenger}>
                                <ListItemIcon>
                                    <MessengerIcon messenger={messenger as EMessenger} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={props.linkItem[messenger as EMessenger]}
                                    className={classes.detailsCount}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            }
        >
            <span className={classes.clicks}>{` [${props.linkItem.clicks}]`}</span>
        </Tooltip>
    );
};

export default memo(LinkStats);
