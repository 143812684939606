import {ENotificationLevel} from '../../enums/notifications/ENotificationLevel';
import {getOnboardingStatusRequest, sendWelcomeMessageRequest} from '../../requests/onboardingRequests';
import {translateSlug} from '../../utils/translation/translation.utils';
import {addNotification} from '../notifications/notifications.actions';
import {ONBOARDING_GET_STATUS, SEND_WB_WELCOME_NOTIFICATION} from "./onboarding.types";

export const sendWbWelcomeNotification = (number: string) => ({
    type: SEND_WB_WELCOME_NOTIFICATION,
    payload: sendWelcomeMessageRequest(number.replace('+', '')),
});

export const sendWbWelcomeNotificationAndNotify = (number: string) => (dispatch: any) =>
    dispatch(sendWbWelcomeNotification(number)).then(() => {
        dispatch(
            addNotification(translateSlug('welcome_message_has_been_sent_to_x', number), ENotificationLevel.Success),
        );
    });

export const getOnboardingStatus = () => ({
    type: ONBOARDING_GET_STATUS,
    payload: getOnboardingStatusRequest(),
});
