export enum EChartPeriod {
    Custom = 'custom',
    InvoicingPeriod = 'invoicing_period',
    LastMonth = 'last_month',
    Last30Days = 'last_30_days',
    Last365Days = 'last_365_days',
    LastInvoicingPeriod = 'last_invoicing_period',
}

export type TChartInvoicePeriod = EChartPeriod.InvoicingPeriod | EChartPeriod.LastInvoicingPeriod;

export const isDynamicChartPeriod = (period: EChartPeriod): boolean =>
    [
        EChartPeriod.InvoicingPeriod,
        EChartPeriod.LastInvoicingPeriod,
        EChartPeriod.Last30Days,
        EChartPeriod.LastMonth,
        EChartPeriod.Last365Days,
    ].includes(period);
