import {IPreparedNote} from '../../definitions/notes/notes.definitions';
import {IActionTypesMap, TActionTypes} from '../../definitions/request/requestsState.definitions';

import {IChat, IDefinedChat} from '../../definitions/chat/chat.definitions';
import {IChartInfo} from '../../definitions/statistics/statistics.definitions';
import {IUpdateStreamMessage} from '../../definitions/updateStream/updateStream.definitions';
import {EUpdateStreamAction} from '../../enums/updateStream/EUpdateStreamAction';
import {MutableRefObject, Ref} from 'react';

export const isDefinedChat = (chat: IChat): chat is IDefinedChat => !!(chat.meta && chat.meta.mood);

export const isMutableRef = (
    ref: Ref<any>,
): ref is MutableRefObject<HTMLInputElement | HTMLTextAreaElement> => {
    if (typeof ref === 'function') {
        return false;
    }
    return !!ref?.current;
};

export const isChartInfo = (data: any): data is IChartInfo => {
    if (!data) {
        return false;
    }
    if (data.isCollapsed !== undefined) {
        return true;
    }
    return !!data.interval;
};

export const isActionTypesMap = (actionTypes: TActionTypes): actionTypes is IActionTypesMap =>
    !Array.isArray(actionTypes);

const isNoteUpdateStreamMessage = (message: IUpdateStreamMessage):
    message is IUpdateStreamMessage<'post' | 'delete' | 'put', IPreparedNote | {id: number}, EUpdateStreamAction.PreparedNotes> =>
    message.action === EUpdateStreamAction.PreparedNotes;

export const isPostNoteUpdateStreamMessage = (message: IUpdateStreamMessage):
    message is IUpdateStreamMessage<'post', IPreparedNote, EUpdateStreamAction.PreparedNotes> => {
    if (!isNoteUpdateStreamMessage(message)) {
        return false;
    }
    return message.method === 'post';
};

export const isPutNoteUpdateStreamMessage = (message: IUpdateStreamMessage):
    message is IUpdateStreamMessage<'put', IPreparedNote, EUpdateStreamAction.PreparedNotes> => {
    if (!isNoteUpdateStreamMessage(message)) {
        return false;
    }
    return message.method === 'put';
};

export const isDeleteNoteUpdateStreamMessage = (message: IUpdateStreamMessage):
    message is IUpdateStreamMessage<'delete', {id: number}, EUpdateStreamAction.PreparedNotes> => {
    if (!isNoteUpdateStreamMessage(message)) {
        return false;
    }
    return message.method === 'delete';
};