import {FC, useCallback, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    createConnectedAction,
    createDisconnectedAction,
    createUpdateAction,
    resetSocketAction,
} from '../../redux/connection/connection.actions';
import {IState} from '../../redux/root.reducer';
import {SocketConnector, UpdateStream, UpdateStreamMessage} from "@sinch-engage/mcp-update-stream";
import {getChannelId} from "../../redux/channel/channel.selectors";
import {BASE_URL} from "../../config";
import {isProductionUrl} from "../../utils/global/global.utils";
import {AGENT_ME_URL} from "../../requests/agentRequests";
import {reportErrorRequest} from "../../requests/Api/Api";
import useTokenFromStorage from "../../utils/hooks/useTokenFromStorage";

interface ISocketConnectionProviderProps {
    socket?: string;
    enablePerformanceCheck?: boolean;
}

const UpdateStreamProvider: FC<ISocketConnectionProviderProps> = (
    {
        socket,
        enablePerformanceCheck,
    }
) => {
    const dispatch = useDispatch();
    const token = useTokenFromStorage();

    const socketClientRef = useRef<UpdateStream>();

    const action = useSelector((state: IState) => state.connection.performAction);
    const channelId = useSelector(getChannelId);

    // Listen to state changes from connection reducer
    useEffect(() => {
        if (action === 'connect') {
            socketClientRef.current?.connect();
        } else if (action === 'disconnect') {
            socketClientRef.current?.disconnect();
        }
    }, [action]);

    (window as any).gws = socketClientRef.current;

    const handleMessage = useCallback((message: UpdateStreamMessage) => {
        try {
            dispatch(createUpdateAction({message: message}, enablePerformanceCheck));
        } catch (e) {
            console.error("UpdateStreamProvider.handleMessage", e, message);
        }
    }, [dispatch, enablePerformanceCheck]);

    const handleConnect = useCallback(() => {
        dispatch(resetSocketAction());
    }, [dispatch]);

    const handleAutorize = useCallback((deviceId: string) => {
        dispatch(createConnectedAction(deviceId));
    }, [dispatch]);

    const handleDisconnect = useCallback(() => {
        dispatch(createDisconnectedAction());
    }, [dispatch]);

    const handleError = useCallback((error) => {
        try {
            reportErrorRequest({
                type: 'Websocket',
                uri: window.location.href,
                message: error.message,
                trace: JSON.stringify(error),
            });
        } catch(e) {
            // eat it
        }
    }, []);

    const handleTooManyRetries = useCallback(() => {
        reportErrorRequest({
            type: 'Websocket',
            uri: window.location.href,
            message: 'Too many retries, reload page.',
        }).finally(() => {
            // something is broken, try to reload the page.
            window.location.reload();
        });
    }, []);

    useEffect(() => {
        if (!socket) {
            console.error('No update stream URL defined. Live updates will not work!');
        }
    }, [socket]);

    if (!socket || !token || !channelId) {
        return null;
    }

    return (
        <SocketConnector
            token={token}
            projectId={channelId}
            socketUrl={socket}
            authUrl={`${BASE_URL}/${AGENT_ME_URL}`}
            onMessage={handleMessage}
            onConnect={handleConnect}
            onAuthorize={handleAutorize}
            onDisconnect={handleDisconnect}
            onError={handleError}
            autoReconnect={false}
            onTooManyRetries={handleTooManyRetries}
            ref={socketClientRef}
            disableBus={true}
            debug={!isProductionUrl()}
        />
    );
};

export default UpdateStreamProvider;
