import {FC, ReactNode} from 'react';
import {useSelector} from 'react-redux';
import {Redirect, useLocation} from 'react-router';
import ERights from '../../enums/actor/ERights';
import {EBaseRoutes} from '../../enums/routes/ERoutes';
import {actorHasRights, getActorRights, isActorAuthenticated} from '../../redux/actor/actor.selectors';
import {useParamSelector} from '../../redux/utils';
import {isSSOEnabled} from "../../redux/ui/ui.selectors";

interface IAccessControlProviderProps {
    requiredRights?: ERights | ERights[];
    children?: ReactNode;
    matchIfAny?: boolean;
    renderNoAccess?: () => any;
}

export const AccessControl: FC<IAccessControlProviderProps> = (
    {
        children,
        matchIfAny,
        requiredRights,
        renderNoAccess,
    }
) => {
    const location = useLocation();
    const allowed = useParamSelector(actorHasRights, requiredRights, matchIfAny);
    const isAuthenticated = useSelector(isActorAuthenticated);
    const ssoEnabled = useSelector(isSSOEnabled);
    const rights = useSelector(getActorRights);

    if (!isAuthenticated && !ssoEnabled) {
        return <Redirect to={{pathname: EBaseRoutes.Login, search: location.search, state: {from: location}}}/>;
    }

    // don't redirect if we don't have the rights loaded yet, otherwise deep links won't work
    if (!rights?.length) {
        return null;
    }

    return allowed ? children : renderNoAccess ? renderNoAccess() : null;
};

export default AccessControl;
