import {FC} from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import classNames from 'classnames';

import {getErrorText} from '../../utils/chat/chat.utils';

const SEND_STATUS = {
    SENT: 1,
    DELIVERED: 2,
    READ: 3,
    FAILED: -1,
    USER_REJECTED: -2,
};

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        whatsAppMessageStatusIcon: {
            color: theme.palette.primary.main,
        },
        whatsAppMessageStatusIconRead: {
            color: theme.palette.info.main,
        },
        whatsAppMessageStatusIconError: {
            color: theme.palette.error.main,
        },
    }),
);

interface ISendStatus {
    sendStatus: number;
    outgoing: boolean;
    sendError?: string;
}

const SendStatus: FC<ISendStatus> = ({sendStatus, outgoing, sendError}) => {
    const classes = useStyles();

    if (!sendStatus || !outgoing) {
        return null;
    }

    const errorText = getErrorText(sendError);

    switch (sendStatus) {
        case SEND_STATUS.SENT:
            return <DoneIcon color="inherit" className={classes.whatsAppMessageStatusIcon} />;
        case SEND_STATUS.DELIVERED:
            return <DoneAllIcon color="inherit" className={classes.whatsAppMessageStatusIcon} />;
        case SEND_STATUS.READ:
            return (
                <DoneAllIcon
                    color="inherit"
                    className={classNames(classes.whatsAppMessageStatusIcon, classes.whatsAppMessageStatusIconRead)}
                />
            );
        case SEND_STATUS.USER_REJECTED:
            return (
                <Tooltip title={errorText}>
                    <ErrorOutlineIcon
                        color="inherit"
                        className={classNames(
                            classes.whatsAppMessageStatusIcon,
                            classes.whatsAppMessageStatusIconError,
                        )}
                    />
                </Tooltip>
            );
        case SEND_STATUS.FAILED: {
            return (
                <Tooltip title={errorText}>
                    <ErrorOutlineIcon
                        color="inherit"
                        className={classNames(
                            classes.whatsAppMessageStatusIcon,
                            classes.whatsAppMessageStatusIconError,
                        )}
                    />
                </Tooltip>
            );
        }

        default:
            return null;
    }
};

export default SendStatus;
