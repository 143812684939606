export enum EWBActivationStatus {
    Active = 'active',
}

export enum EWBSetupStatus {
    None = 'NONE',
    Manual = 'MANUAL',
    Sandbox = 'SANDBOX',
    Live = 'LIVE',
}
