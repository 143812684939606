import {SortDirectionType} from 'react-virtualized';
import {Reducer} from 'redux';
import {ITicketFilter} from '../../definitions/ticketPage/ticketFilter.definitions';
import {ITicket} from '../../definitions/tickets/tickets.definitions';
import {ETicketListSortTypes} from '../../enums/ui/ETicketListSortTypes';
import {defaultFilterState} from '../../components/TicketTable/Filter/filter.constants';
import {ACTOR_GET_PROFILE_FULFILLED} from '../actor/actor.types';
import {
    RESET_PANEL_MARKS,
    TICKET_LIST_PAGE_SET_FILTER,
    TICKET_LIST_PAGE_SET_SEARCH_TERM,
    TICKET_LIST_PAGE_SET_SORTING,
    TICKET_PAGE_SET_REQUEST_HASH,
    UPDATE_PANEL_MARKS,
    UPDATE_TICKETS_TO_SHOW,
} from './ticketPage.types';

export interface TicketPageState {
    filter: ITicketFilter;
    markedPanels: number[];
    searchTerm: string;
    sortedTickets: ITicket[];
    sortBy?: ETicketListSortTypes;
    sortDirection?: SortDirectionType;
    requestHash?: string;
}

export const ticketPageInitialState: TicketPageState = {
    filter: defaultFilterState,
    markedPanels: [],
    searchTerm: '',
    sortedTickets: [],
    sortBy: ETicketListSortTypes.Time,
    sortDirection: 'ASC',
};

export const ticketPageReducer: Reducer<TicketPageState> = (state = ticketPageInitialState, action) => {
    const {payload, type} = action;

    switch (type) {
        case UPDATE_TICKETS_TO_SHOW:
            return {
                ...state,
                sortedTickets: payload,
            };

        case UPDATE_PANEL_MARKS: {
            // always add to marked panels if multiple marks
            if (Array.isArray(payload)) {
                return {
                    ...state,
                    markedPanels: [...new Set([...state.markedPanels, ...payload])],
                };
            }

            // check if to add or to remove
            if (state.markedPanels.includes(payload)) {
                const marked = [...state.markedPanels];
                return {...state, markedPanels: marked.filter(itemId => itemId !== payload)};
            }

            return {
                ...state,
                markedPanels: [...new Set([...state.markedPanels, payload])],
            };
        }

        case RESET_PANEL_MARKS:
            return {
                ...state,
                markedPanels: [],
            };

        case TICKET_LIST_PAGE_SET_FILTER:
            return {
                ...state,
                filter: payload,
            };

        case TICKET_LIST_PAGE_SET_SORTING:
            return {
                ...state,
                sortDirection: payload.sortDirection === 'DESC' ? 'DESC' : 'ASC',
                sortBy: payload.sortBy,
            };

        case TICKET_LIST_PAGE_SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: payload,
            };

        case ACTOR_GET_PROFILE_FULFILLED: {
            // try set initial sorting from agent settings
            if (!payload?.settings?.ticketsSortColumn) {
                return state;
            }

            return {
                ...state,
                sortBy: payload.settings.ticketsSortColumn,
                sortDirection: payload.settings?.ticketsSortDirection === 'DESC' ? 'DESC' : 'ASC',
            };
        }

        case TICKET_PAGE_SET_REQUEST_HASH:
            return {
                ...state,
                requestHash: payload,
            };

        default:
            return state;
    }
};
