import {memo} from 'react';

import Box from '@material-ui/core/Box';
import {useSelector} from 'react-redux';

import IconFactory from '../../../../components/IconFactory/IconFactory';
import {EIcons} from '../../../../enums/icons/EIcons';
import {getActorLanguage} from '../../../../redux/actor/actor.selectors';
import {getMauStatistics} from '../../../../redux/statistics/statistics.selectors';

const MonthlyActiveUser = () => {
    const {current, diff} = useSelector(getMauStatistics);

    const icon = diff > 0 ? EIcons.TRENDING_UP : EIcons.TRENDING_DOWN;
    const color = diff > 0 ? 'primary' : 'error';
    const userLanguage = useSelector(getActorLanguage);

    return (
        <Box display="flex" alignItems="center" component="span">
            <Box mr={2} display="flex" component="span">
                {IconFactory.getIconWithProps(icon, {color})}
            </Box>
            {current.toLocaleString(userLanguage)}
        </Box>
    );
};

export default memo(MonthlyActiveUser);
