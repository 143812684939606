import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useSelector} from 'react-redux';

import {IState} from '../../redux/root.reducer';
import translations from '../../providers/TranslationProvider/translations';
import defaultChannelImage from './default_channel_image.png';

const useStyles = makeStyles(
    createStyles({
        image: {
            width: '100%',
            display: 'block',
            maxWidth: 250,
        },
    }),
);

const CurrentChannelPicture = () => {
    const classes = useStyles();
    const channelPicture = useSelector((state: IState) => state.channel.image);
    const imageSrc = channelPicture || defaultChannelImage;

    return <img className={classes.image} src={imageSrc} alt={translations.channel_image}/>;
};

export default CurrentChannelPicture;
