import {PASSWORD_CRITERIA} from '../../config';

export interface IIntervals {
    strength: number;
    message: string;
}

export const MAX_PASSWORD_LENGTH = 512;

export const PW_SHORT: IIntervals = {
    strength: 0,
    message: 'to_short',
};
export const PW_WEAK: IIntervals = {
    strength: 1,
    message: 'weak',
};
export const PW_MEDIUM: IIntervals = {
    strength: 2,
    message: 'medium',
};
export const PW_STRONG: IIntervals = {
    strength: 3,
    message: 'strong',
};
export const PW_VERY_STRONG: IIntervals = {
    strength: 4,
    message: 'very_strong',
};

const INTERVALS: IIntervals[] = [PW_SHORT, PW_WEAK, PW_MEDIUM, PW_STRONG, PW_VERY_STRONG];

export const getPasswordSecurity = (password: string | undefined, passwordMinLength: number): IIntervals => {
    const passwordStrength = calculatePasswordStrength(password, passwordMinLength);
    return INTERVALS.find((interval: IIntervals) => interval.strength === passwordStrength) || PW_WEAK;
};

const calculatePasswordStrength = (password: string | undefined, passwordMinLength: number): number => {
    if (!password || password.length < passwordMinLength) {
        return 0;
    }

    return PASSWORD_CRITERIA.reduce((strength, criterion) => {
        const matches = password.match(criterion);

        if (matches) {
            return strength + 1;
        }
        return strength;
    }, 0);
};
