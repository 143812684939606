import {FC, ReactNode, useCallback, useState} from 'react';
import {DataGrid, DataGridProps, GridSortModel} from '@material-ui/data-grid';
import translations from '../../providers/TranslationProvider/translations';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {useTableStyles} from '../../pages/StatisticsPage/StatisticPanels/StatisticsTable.styles';
import NoRowsOverlay from './components/NoRowsOverlay/NoRowsOverlay';
import TableLoader from './components/TableLoader/TableLoader';
import NoPaginationFooter from './components/NoPaginationFooter/NoPaginationFooter';


export interface CustomDataGridProps extends DataGridProps {
    noPages?: boolean;
    topDivider?: boolean;
    emptyMessage?: ReactNode;
}

const CustomDataGrid: FC<CustomDataGridProps> = (
    {
        pageSize: _pageSize,
        loading,
        rows,
        onSortModelChange,
        rowsPerPageOptions,
        onPageSizeChange,
        components,
        componentsProps,
        noPages,
        topDivider,
        emptyMessage,
        hideFooter,
        ...rest
    }
) => {
    const classes = useTableStyles();

    const [sortModel, setSortModel] = useState<GridSortModel>();
    const [pageSize, setPageSize] = useState(_pageSize);
    const [page, setPage] = useState(0);

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handleSortModelChange = useCallback((model?: GridSortModel) => {
        setSortModel(model);
        setPage(0);

        if (model && onSortModelChange) {
            return onSortModelChange(model);
        }
    }, [onSortModelChange]);

    const handlePageSizeChange = useCallback((pageSize: number) => {
        setPageSize(pageSize);

        if (onPageSizeChange) {
            return onPageSizeChange(pageSize);
        }
    }, [onPageSizeChange]);

    return (
        <>
            {/*{topDivider && (*/}
            {/*    <Box mb={1} mx={2} textAlign={'center'}>*/}
            {/*        <Divider*/}
            {/*            style={{width: '100%', maxWidth: '80%', marginLeft: 60, marginRight: 60, display: 'inline-block'}}/>*/}
            {/*    </Box>*/}
            {/*)}*/}
            <DataGrid
                loading={loading}
                showColumnRightBorder={false}
                rows={rows}
                pageSize={noPages && rows.length > 100 ? 100 : pageSize}
                rowsPerPageOptions={noPages && rows.length > 100 ? [100] : rowsPerPageOptions}
                onPageSizeChange={handlePageSizeChange}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                onPageChange={handlePageChange}
                page={page}
                hideFooterSelectedRowCount
                components={{
                    ColumnSortedAscendingIcon: ArrowDropUpIcon,
                    ColumnSortedDescendingIcon: ArrowDropDownIcon,
                    NoRowsOverlay: NoRowsOverlay,
                    LoadingOverlay: TableLoader,
                    Pagination: noPages && rows.length <= 100 ? NoPaginationFooter : undefined,
                    // Toolbar: GridToolbar,
                    ...components,
                }}
                componentsProps={{
                    pagination: noPages && rows.length <= 100 ? {
                        rowCount: rows.length,
                    } : undefined,
                    noRowsOverlay: {
                        emptyMessage: emptyMessage,
                    },
                    ...componentsProps,
                }}
                className={classes.dataGrid}
                classes={{
                    root: classes.dataGridRoot,
                    columnHeader: classes.columnHeader,
                    row: classes.row,
                    cell: classes.cell,
                }}
                disableColumnMenu
                // disableColumnSelector
                disableSelectionOnClick
                disableExtendRowFullWidth // we need this for the .cell:last-child rule to work if the table gets stretched
                localeText={{
                    footerTotalRows: 'hey',
                    footerTotalVisibleRows: (visibleCount, totalCount) =>
                        `${visibleCount.toLocaleString()} ${translations.of} ${totalCount.toLocaleString()}`,
                }}
                hideFooter={(!loading && !rows.length) || hideFooter}
                {...rest}
            />
        </>
    );
};

export default CustomDataGrid;
