import {FC, RefObject, useCallback, useEffect, useState} from 'react';


import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useDispatch, useSelector} from 'react-redux';

import {ICustomField} from '../../../definitions/customField/customField.definitions';
import {getCustomFields} from '../../../redux/customField/customField.actions';
import {getAutocompleteOptions} from '../../../redux/customField/customField.selectors';
import {createFakeEvent} from '../../../utils/redux/redux.utils';
import translations from '../../../providers/TranslationProvider/translations';
import CustomEmojiTextField from '../CustomEmojiTextField/CustomEmojiTextField';
import CustomTextField, {ICustomTextFieldProps} from '../CustomTextField/CustomTextField';

const useStyles = makeStyles(() =>
    createStyles({
        popper_root: {
            zIndex: 1301,
        },
        popper_paper: {
            maxHeight: 300,
            overflow: 'auto',
        },
    }),
);

interface ICustomAutocompleteTextFieldProps extends ICustomTextFieldProps {
    emoji?: true;
}

const CustomAutocompleteTextField: FC<ICustomAutocompleteTextFieldProps> = ({value, emoji, ...props}) => {
    const text = (value as string) || '';
    const classes = useStyles();
    const dispatch = useDispatch();
    const [menuOpen, setMenuOpen] = useState(false);
    const [focusList, setFocusList] = useState(false);
    const [startOfVariable, setStartOfVariable] = useState(0);
    const [typedWord, setTypedWord] = useState('');
    const [filteredOptions, setFilteredOptions] = useState<ICustomField[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | any>(null);
    const [inputRef, setInputRef] = useState<null | RefObject<HTMLInputElement | HTMLTextAreaElement>>(null);
    const options = useSelector(getAutocompleteOptions);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
        setMenuOpen(false);
        setFocusList(false);

        // if we have a passed reference of the input field, focus it again
        if (inputRef?.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    const setVariable = (variable: string) => {
        const partBeforeTypedWord = text.substring(0, startOfVariable);
        const partAfterTypedWord = text.substring(startOfVariable + typedWord.length);
        const newValue = `${partBeforeTypedWord}${variable}${partAfterTypedWord}`;

        // create fake event and trigger onChange
        if (props.onChange) {
            props.onChange(createFakeEvent(props.name, newValue));
        }

        handleClose();
    };

    const handleKeyDown = (event: any) => {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }

        // open list with CTRL + Space
        if (!menuOpen && typedWord && event.ctrlKey && event.key === ' ' && filteredOptions.length) {
            setMenuOpen(true);
            return;
        }

        // focus first element with ⬇ key
        if (event.key === 'ArrowDown' && !focusList) {
            event.preventDefault();
            setFocusList(true);
            return;
        }

        if (props.onKeyDown) {
            return props.onKeyDown(event);
        }
    };

    // update selection on key up (after the character has been added or removed)
    const handleKeyUp = (event: any) => {
        if (props.onKeyUp) {
            return props.onKeyUp(event);
        }
    };

    const handleChange = (event: any) => {
        if (props.onChange) {
            return props.onChange(event);
        }
    };

    // listen for arrow down and escape events to focus and un-focus the menu
    const handleGlobalKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClose();
            }
        },
        [handleClose],
    );

    // Load custom fields
    useEffect(() => {
        dispatch(getCustomFields());
    }, [dispatch]);

    useEffect(() => {
        const _selectionStart = inputRef?.current?.selectionStart ?? 0;
        const textUntilCursor = text.substring(0, _selectionStart);
        const lastTypedCharacter = text.charAt(_selectionStart - 1);
        const startOfVariable = textUntilCursor.lastIndexOf('$');

        // if no variable started or last char is not a valid variable character,
        if (startOfVariable === -1 || !/[$0-9A-Za-z_]/.test(lastTypedCharacter)) {
            setStartOfVariable(-1);
            setTypedWord('');
            setFilteredOptions([]);
            return;
        }

        const typedWord = textUntilCursor.substring(startOfVariable);

        setStartOfVariable(startOfVariable);
        setTypedWord(typedWord);
        setFilteredOptions(
            options.filter(
                option =>
                    (option?.name?.toLowerCase()?.indexOf(typedWord.toLowerCase()) !== -1 ?? false) ||
                    (option?.label?.toLowerCase()?.indexOf(typedWord.toLowerCase()) !== -1 ?? false),
            ),
        );
    }, [text, setFilteredOptions, inputRef, options]);

    // filter options to match typed word and open menu
    useEffect(() => {
        setMenuOpen(Boolean(typedWord && filteredOptions.length));
    }, [typedWord, filteredOptions.length]);

    // Attach/detach handler for keyboard menu navigation
    useEffect(() => {
        if (!inputRef?.current) {
            return;
        }
        if (menuOpen) {
            document.addEventListener('keyup', handleGlobalKeyPress);
        } else {
            document.removeEventListener('keyup', handleGlobalKeyPress);
        }
        return () => window.removeEventListener('keyup', handleGlobalKeyPress);
    }, [inputRef, menuOpen, handleGlobalKeyPress]);

    const Component = emoji ? CustomEmojiTextField : CustomTextField;

    return (
        <>
            <Component
                {...props}
                value={text}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                onMount={setInputRef}
                onChange={handleChange}
                helperText={props.helperText || translations.shortcut_add_user_property}
            />
            <Popper
                open={Boolean(anchorEl) && menuOpen}
                placement='bottom-start'
                anchorEl={anchorEl}
                className={classes.popper_root}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <Paper className={classes.popper_paper}>
                        <MenuList autoFocusItem={focusList}>
                            {filteredOptions.map(option => (
                                <MenuItem
                                    key={option.name}
                                    value={option.name}
                                    onClick={() => setVariable(option.name)}
                                >
                                    {option.name}
                                    {option.label ? ` - ${option.label}` : ''}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </>
    );
};

export default CustomAutocompleteTextField;
