import {combineReducers} from 'redux';

import {queryBuilderReducer} from './queryBuilderReducer/queryBuilder.reducer';
import {requestReducer as targetingRequestReducer} from './request/request.reducer';
import {configReducer as targetingConfigReducer} from './targetingConfig/config.reducer';

const targetingReducer = combineReducers({
    request: targetingRequestReducer,
    config: targetingConfigReducer,
    queryBuilder: queryBuilderReducer,
});

export default targetingReducer;
