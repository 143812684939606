import {IDynamicAnswer} from '../../definitions/dynamicAnswer/dynamicAnswer.definitions';
import {IEntityStateForUuids, SubReducer} from '../../definitions/generic/generic.definitions';
import {EUpdateStreamMethod} from '../../enums/updateStream/EUpdateStreamMethod';
import {isUpdateStream} from '../../utils/redux/redux.utils';
import {UPDATE_STREAM} from '../connection/connection.types';
import {createEntityReducer} from '../entity/entity.reducer';
import {
    DYNAMIC_ANSWERS_GET_FULFILLED,
    DYNAMIC_ANSWERS_GET_PENDING,
    DYNAMIC_ANSWERS_GET_REJECTED,
} from './dynamicAnswer.types';

export const additionalReducer: SubReducer<IEntityStateForUuids<IDynamicAnswer>> = (state, action) => {
    const {type, payload} = action;

    switch (type) {
        case UPDATE_STREAM: {
            if (
                isUpdateStream(payload.message, 'bot_chat_dynamic', [EUpdateStreamMethod.Post, EUpdateStreamMethod.Put])
            ) {
                const payloadDynamicAnswer = payload?.message?.payload;
                if (!payloadDynamicAnswer.id || !payloadDynamicAnswer.name) {
                    return state;
                }

                const newByIds = {
                    ...state.byId,
                    [payloadDynamicAnswer.id]: payloadDynamicAnswer,
                };

                return {
                    byId: newByIds,
                    ids: Object.keys(newByIds).map(String),
                };
            }

            if (isUpdateStream(payload.message, 'bot_chat_dynamic', EUpdateStreamMethod.Delete)) {
                const {id} = payload?.message?.payload || {};
                if (!id) {
                    return state;
                }

                const {[id]: deleted, ...newById} = state.byId;

                return {
                    byId: newById,
                    ids: Object.keys(newById).map(String),
                };
            }
            return state;
        }
        default:
            return state;
    }
};

export const dynamicAnswerReducer = createEntityReducer<IDynamicAnswer>(
    'dynamicAnswers',
    [DYNAMIC_ANSWERS_GET_PENDING, DYNAMIC_ANSWERS_GET_REJECTED, DYNAMIC_ANSWERS_GET_FULFILLED],
    additionalReducer,
);
