import React, {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import ERights from '../../enums/actor/ERights';
import {EBaseRoutes, ESettingsRoutes} from '../../enums/routes/ERoutes';
import {getActor, getActorId} from '../../redux/actor/actor.selectors';
import {actorHasAcceptedAvv, isPaymentBlocked, isTestAccount} from '../../redux/accounting/accounting.selectors';
import {actorHasRights} from '../../utils/actor/actor.utils';
import DialogTemplate from '../DialogTemplate/DialogTemplate';
import translations from '../../providers/TranslationProvider/translations';
import {reportErrorRequest} from "../../requests/Api/Api";
import {getChannelId} from "../../redux/channel/channel.selectors";
import {matchPath} from "../../utils/routes/routes.utils";

const invoicesSettingsRoute = ESettingsRoutes.Invoices;
const avvSettingsRoute = ESettingsRoutes.Avv;

const BlockDialog: FC = () => {
    const history = useHistory();
    const location = useLocation();
    const pathname = location.pathname;
    const avvAccepted = useSelector(actorHasAcceptedAvv);
    const testAccount = useSelector(isTestAccount);
    const actor = useSelector(getActor);
    const userCanSignAvv = actorHasRights(actor, ERights.SettingsChannel);
    const isUserAdministrator = actorHasRights(actor, ERights.Administrate);
    const isUserBlocked = useSelector(isPaymentBlocked);
    const agentId = useSelector(getActorId);
    const channelId = useSelector(getChannelId);
    const dialogRef = useRef(null);

    // flag to remount contents by rendering null and then the contents again
    const [needsRemount, setNeedsRemount] = useState(false);

    const shouldShowDialog = () => {
        if (testAccount) {
            return false;
        }

        if (isUserBlocked && pathname !== invoicesSettingsRoute) {
            return true;
        }

        const canSendOnCurrentPage =
            matchPath(pathname, EBaseRoutes.Chat) ||
            matchPath(pathname, EBaseRoutes.Mia) ||
            matchPath(pathname, EBaseRoutes.User);

        return !avvAccepted && canSendOnCurrentPage;
    };

    const dialogProps = useMemo(() => {
        if (isUserBlocked) {
            if (isUserAdministrator) {
                return {
                    title: translations.headline_account_blocked,
                    contentText: translations.account_blocked_admin,
                    actionsConfig: [
                        {
                            onClick: () => history.push(invoicesSettingsRoute),
                            label: translations.go_to_invoices,
                        },
                    ],
                };
            }

            return {
                title: translations.headline_account_blocked,
                contentText: translations.account_blocked,
            };
        }

        if (!avvAccepted && userCanSignAvv) {
            return {
                title: translations.headline_avv_not_signed,
                contentText: translations.avv_not_signed_admin,
                actionsConfig: [
                    {
                        onClick: history.goBack,
                        label: translations.back,
                    },
                    {
                        onClick: () => history.push(avvSettingsRoute),
                        label: translations.go_to_avv,
                    },
                ],
            };
        }

        if (!avvAccepted && !userCanSignAvv) {
            return {
                title: translations.headline_avv_not_signed,
                contentText: translations.avv_not_signed,
                actionsConfig: [
                    {
                        onClick: history.goBack,
                        label: translations.back,
                    },
                ],
            };
        }

        return null;
    }, [avvAccepted, history, isUserAdministrator, isUserBlocked, userCanSignAvv]);

    const shouldShow = shouldShowDialog();

    // Observe DOM changes to the overlay and remount Dialog if change/removal detected
    useEffect(() => {
        const observer = new MutationObserver(function (mutations) {
            // Whether you iterate over mutations..
            mutations.forEach(function (mutation) {
                // check if overlay has been removed or altered
                const isAltered = dialogRef.current === mutation.target;
                const isRemoved = dialogRef.current && Array.from(mutation.removedNodes).includes(dialogRef.current);
                if (isAltered || isRemoved) {
                    // set
                    setNeedsRemount(true);

                    reportErrorRequest({
                        uri: window.location.href,
                        message: `Agent ${agentId} tried to remove blocking dialog in channel ${channelId}`,
                        type: 'HackAttempt',
                        trace: JSON.stringify(mutation),
                    });
                }
            });
        });

        if (shouldShow) {
            observer.observe(document, {
                subtree: true,
                childList: true,
                attributes: true,
            });
        }

        return () => {
            observer.disconnect();
        };
    }, [shouldShow, agentId, channelId]);

    useEffect(() => {
        if (needsRemount) {
            setNeedsRemount(false);
        }
    }, [needsRemount]);

    // Frequently check visibility of blocking overlay and if not visible anymore, reload page
    useEffect(() => {
        if (!shouldShow || needsRemount) {
            return;
        }

        const checkInterval = setInterval(() => {
            if (dialogRef.current) {
                const dialogStyles = getComputedStyle(dialogRef.current);
                if (dialogStyles.visibility !== 'visible' || dialogStyles.display !== 'block') {
                    Promise.resolve(reportErrorRequest({
                        uri: window.location.href,
                        message: `Agent ${agentId} tried to hide blocking dialog in channel ${channelId}`,
                        type: 'HackAttempt',
                    })).then(() => {
                        window.location.reload();
                    });
                }
            }
        }, 5000);

        return () => {
            clearInterval(checkInterval);
        };
    }, [agentId, channelId, needsRemount, shouldShow]);

    if (!shouldShow || needsRemount) {
        return null;
    }

    return <DialogTemplate open ariaLabel="blockDialog" closable={false} ref={dialogRef} {...dialogProps} />;
};

export default BlockDialog;
