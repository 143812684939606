import {Component} from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import ReactPlayer from 'react-player';

import {basename, getAttachmentTypeByFileExtensionOrUrl} from '../../utils/global/global.utils';
import {ChatType} from '../../utils/ticket/ticket.utils';
import MediaError from '../MediaError/MediaError';
import MessageContent from '../MessageContent/MessageContent';

const styles = {
    dialogImage: {
        maxWidth: 'calc(100% - 8px)',
        borderRadius: 4,
        maxHeight: 500,
    },
    dialogImageAttachment: {
        height: 200,
        width: 200,
        minHeight: 300,
    },
};

interface IAttachmentPreviewProps {
    classes: {
        dialogImage: string;
        dialogImageAttachment: string;
    };
    isLoading?: boolean;
    uploadUrl?: string;
}

class AttachmentPreview extends Component<IAttachmentPreviewProps> {
    state = {
        error: '',
    };

    handleError = () => {
        this.setState({
            error: true,
        });
    };

    render() {
        const {isLoading = false, uploadUrl = '', classes} = this.props;

        if (isLoading) {
            return <LinearProgress />;
        }

        if (!uploadUrl) {
            return null;
        }

        if (this.state.error) {
            return <MediaError fileUrl={uploadUrl} />;
        }

        switch (getAttachmentTypeByFileExtensionOrUrl(uploadUrl)) {
            case 'image':
                return <img className={classes.dialogImage} alt="" src={uploadUrl} />;

            case 'video':
                return <ReactPlayer controls url={uploadUrl} width="" height="" onError={this.handleError} />;

            case 'audio':
                return <ReactPlayer controls url={uploadUrl} width="" height={50} onError={this.handleError} />;

            case 'document':
                return (
                    <MessageContent
                        item={{
                            media: uploadUrl,
                            filename: basename(uploadUrl),
                            chat: '',
                            chattype: ChatType.DOCUMENT,
                            pending: false,
                            outgoing: false,
                            send_status: 0,
                        }}
                    />
                );

            default:
                return null;
        }
    }
}

const StyledAttachmentPreview = withStyles(styles)(AttachmentPreview);
export default StyledAttachmentPreview;
