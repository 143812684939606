import {AxiosResponse} from 'axios';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import {AnyAction, Dispatch} from 'redux';
import {reset} from 'redux-form';
import {ThunkDispatch} from 'redux-thunk';
import {v1 as uuidV1} from 'uuid';

import translations from '../../providers/TranslationProvider/translations';
import {
    IActor,
    IActorSettings,
    IDndItem,
    IDragAndDropColumns,
    IDragAndDropState,
    IDragNDropPage,
    ITicketTableColumn,
    IUserTableColumn,
} from '../../definitions/actor/actor.definitions';
import {IAgent} from '../../definitions/agent/agent.definitions';
import {IImagePayload} from '../../definitions/channel/channel.definitions';
import {IResponseData} from '../../definitions/response/responseData.definitions';
import {QUERY_PARAM_GO_TO_CHANNEL, QUERY_PARAM_SWITCH_CHANNEL} from '../../definitions/router/router.definitions';
import {ELoginErrors} from '../../enums/actor/ELoginErrors';
import {EChartChatAction} from '../../enums/chart/EChartChatAction';
import {EChartInterval} from '../../enums/chart/EChartInterval';
import {EChartPeriod} from '../../enums/chart/EChartPeriod';
import {EChartSource} from '../../enums/chart/EChartSource';
import {ChatChartSelectionToKpiMap} from '../../enums/chart/EChartTicketKpi';
import {EChartType} from '../../enums/chart/EChartType';
import {EBaseRoutes} from '../../enums/routes/ERoutes';
import {PASSWORD_FORM_NAME} from '../../pages/ProfilePage/PasswordSettings/config';
import {ICustomFormValues} from '../../pages/StatisticsPage/StatisticsPageDialogs/AddChartDialog';
import {
    changeStartChannel,
    deleteAgentRequest,
    getProfileRequest,
    setAgentStatusRequest,
    setPasswordRequest,
    updateAgentRequest,
} from '../../requests/agentRequests';
import {internalNewsRequest} from '../../requests/internalRequest';
import {loginRequest, logoutRequest, requestPasswordResetRequest} from '../../requests/loginRequest';
import {uploadFormDataRequest} from '../../requests/uploadRequests';
import {clearSessionInStorage, updateTokenInStorage} from '../../utils/storage/storage.utils';
import {IState} from '../root.reducer';
import {Location} from 'history';
import {isSSOEnabled} from "../ui/ui.selectors";
import {
    ACTOR_CHANGE_PASSWORD,
    ACTOR_DELETE,
    ACTOR_GET_INTERNAL_NEWS,
    ACTOR_GET_PROFILE,
    ACTOR_LOGIN_PENDING,
    ACTOR_LOGIN_REJECTED,
    ACTOR_LOGOUT,
    ACTOR_RESET,
    ACTOR_SET_MIN_PASSWORD_LENGTH,
    ACTOR_SET_STATUS,
    ACTOR_UPDATE,
    ACTOR_UPDATE_SETTINGS,
    PARTNER_LOGIN_FULFILLED,
} from './actor.types';
import {ICustomChart} from "../../definitions/statistics/statistics.definitions";
import {STATISTICS_PAGE_DND_NAME} from "../../pages/StatisticsPage/config";
import {matchPath} from "../../utils/routes/routes.utils";


export const requestActorLogin = () => ({
    type: ACTOR_LOGIN_PENDING,
});

export const actorLoginFailed = (response: AxiosResponse<IResponseData>) => {
    clearSessionInStorage();

    const {reason, token} = response?.data || {};
    const statusText = reason === ELoginErrors.AccountBlocked ? translations.login_blocked : translations.login_failed;

    return {
        type: ACTOR_LOGIN_REJECTED,
        payload: {
            reason,
            response: {
                statusText,
                token,
            },
        },
    };
};

export const getInternalNews = () => ({
    type: ACTOR_GET_INTERNAL_NEWS,
    payload: internalNewsRequest(),
});

export const changeChannel = (channelId: number, referrer?: Location<any>) => (dispatch: Dispatch, getState: () => IState) => {
    // Handle SSO or default API login
    const handleChangeAccordingToAuthenticationMethod = () => {
        if (isSSOEnabled(getState())) {
            return changeStartChannel(channelId);
        } else {
            const token = getState().entities.channels.byIdWithTokens?.[channelId]?.token;

            if (!token) {
                alert("Channel token empty.");
                return;
            }

            // try to set start channel before updating the token.
            // this is necessary for channel switch with secondary device.
            // because changing channel is not allowed without SSO on primary device, we need to catch and finally..
            return changeStartChannel(channelId).catch(e => e).finally(() => updateTokenInStorage(token));
        }
    };

    return Promise.resolve(handleChangeAccordingToAuthenticationMethod()).then(() => {
        // if we have a referrer, go to that location again, otherwise go to same page, except if we are currently on
        // a chat page, because the ticket won't be there on the other channel and would show 'invalid ticket id' error.
        const searchParams = new URLSearchParams(
            `${referrer?.search ? `${referrer.search}&` : '?'}${QUERY_PARAM_SWITCH_CHANNEL}=1`,
        );

        // we are switching channel right now, so remove the channel parameter
        searchParams.delete(QUERY_PARAM_GO_TO_CHANNEL);
        const searchString = [...searchParams].length ? `?${searchParams.toString()}` : undefined;

        // reload page with desired target url
        window.location.href = referrer?.pathname
            ? `${referrer.pathname}${searchString}`
            : matchPath(window.location.pathname, EBaseRoutes.Chat)
                ? `${EBaseRoutes.Dashboard}${searchString}`
                : `${window.location.href}${searchString}`;
    });
};

export const logout = () => ({
    type: ACTOR_LOGOUT,
    payload: logoutRequest(),
});

export const getProfile = (isSecondDevice?: 0 | 1) => ({
    type: ACTOR_GET_PROFILE,
    payload: getProfileRequest(),
    meta: {isSecondDevice},
});

export const pageLogin = () => (dispatch: Dispatch) => {
    // set loading flag
    dispatch(requestActorLogin());

    return loginRequest()
        .then((response: any) => {
            updateTokenInStorage(response.token);
            dispatch({type: PARTNER_LOGIN_FULFILLED, payload: response}); // only for partner login to be able to switch channels afterwards
            return dispatch(getProfile(response.isSecondDevice));
        })
        .catch(error => {
            return dispatch(actorLoginFailed(error));
        });
};

export const requestPasswordReset = (parameters: any) => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
        const state = getState();
        const {passwordReset} = state.request;
        if (!passwordReset.requesting) {
            return dispatch({
                type: ACTOR_RESET,
                payload: requestPasswordResetRequest(parameters),
            });
        }
    };
};

export const setMinPasswordLength = (minPasswordLength: number) => ({
    type: ACTOR_SET_MIN_PASSWORD_LENGTH,
    payload: minPasswordLength,
});

/**
 * This method updates the actor state directly without making any request. This comes handy when changing stuff
 * for not logged-in users like the language on forwarding pages or the name in a signup process.
 *
 * @param actor
 */
export const updateActorState = (actor: Partial<IActor> | Partial<IAgent>) => (
    dispatch: Dispatch,
    getState: () => IState,
) => {
    // add id of actor to request object
    const oldActor = getState().actor;

    // send only changed settings
    const requestObject = {
        id: oldActor.id,
        ...actor,
    };

    // noinspection JSUnusedLocalSymbols
    return dispatch({
        type: ACTOR_UPDATE,
        payload: new Promise((resolve, reject) => resolve(requestObject)), // fake promise the
        meta: {
            payload: requestObject,
            silent: true,
        },
    });
};

export const updateActor = (actor: Partial<IActor> | Partial<IAgent>, silent = false) => (
    dispatch: Dispatch,
    getState: () => IState,
) => {
    // add id of actor to request object
    const oldActor = getState().actor;

    // send only changed settings
    const requestObject = {
        id: oldActor.id,
        ...actor,
    };

    return dispatch({
        type: ACTOR_UPDATE,
        payload: updateAgentRequest(requestObject),
        meta: {
            payload: requestObject,
            oldState: oldActor,
            silent,
        },
    });
};

export const updateActorSettings = (settings: Partial<IActorSettings>, silent = false) => (
    dispatch: Dispatch,
    getState: () => IState,
) => {
    // add id of actor to request object
    const oldActor = getState().actor;

    if (!oldActor.id) {
        return;
    }

    // send only changed settings
    const requestObject = {
        id: oldActor.id,
        settings: settings,
    };

    return dispatch({
        type: ACTOR_UPDATE_SETTINGS,
        payload: updateAgentRequest(requestObject),
        meta: {
            newPartialSettings: settings, // pass along new partial settings, so we can update settings state in pending case
            oldSettings: oldActor.settings, // pass along old settings, so we can restore them in case of rejected request
            silent,
        },
    });
};

export const deleteActor = (agentId: number) => ({
    type: ACTOR_DELETE,
    payload: deleteAgentRequest(agentId),
    meta: {
        id: agentId,
    },
});

export const changePasswordAndResetForm = (newPassword: string) => (dispatch: any) =>
    dispatch(changePassword(newPassword)).then(() => dispatch(reset(PASSWORD_FORM_NAME)));

export const setActorStatus = (statusIndex: any) => ({
    type: ACTOR_SET_STATUS,
    payload: setAgentStatusRequest(statusIndex),
});

export const changePassword = (newPassword: string) => ({
    type: ACTOR_CHANGE_PASSWORD,
    payload: setPasswordRequest(newPassword),
});

export const setActorPictureWithDispatch = (actor: IActor, postBody: IImagePayload) => (
    dispatch: Dispatch,
    getState: () => IState,
) => uploadFormDataRequest(postBody).then(image => updateActor({
    id: actor.id,
    profile_image: image,
})(dispatch, getState));

export const saveTicketTableConfig = (ticketTableConfig: ITicketTableColumn[]) => (
    dispatch: Dispatch,
    getState: () => IState,
) => {
    const state = getState();
    const channelId = state.channel.id;

    return updateActorSettings(
        {
            ticketTableConfig: {
                ...state.actor.settings.ticketTableConfig,
                [channelId]: ticketTableConfig,
            },
        },
        true,
    )(dispatch, getState);
};

export const saveTicketTableConfigVersion = (version: number) => (dispatch: any, getState: any) => {
    const channelId = getState().channel.id;
    return dispatch(updateActorSettings({ticketTableConfigVersion: {[channelId]: version}}, true));
};

const getSpecificChartValues = (chartValues: ICustomFormValues) => {
    if (chartValues.source === EChartSource.Chats) {
        const {ticket_type, ...specific} = chartValues;

        return {
            ...specific,
            kpi:
                specific.chat_action === EChartChatAction.Sent
                    ? ChatChartSelectionToKpiMap[EChartSource.Chats][EChartChatAction.Sent][specific.chat_sender!]
                    : ChatChartSelectionToKpiMap[EChartSource.Chats][EChartChatAction.Received],
        };
    }

    const {chat_action, chat_sender, ...specific} = chartValues;

    return {
        ...specific,
        kpi: specific.ticket_type,
    };
};

const getNewDnDState = (customChart: ICustomChart, getState: () => IState) => {
    const {dragAndDrop = {}} = getState().actor.settings;

    return {
        ...dragAndDrop,
        [STATISTICS_PAGE_DND_NAME]: {
            ...(dragAndDrop[STATISTICS_PAGE_DND_NAME] || {}),
            items: {
                ...(dragAndDrop[STATISTICS_PAGE_DND_NAME].items || {}),
                [customChart.id]: {
                    id: customChart.id,
                    name: customChart.name,
                    type: 'customChart',
                },
            },
            columns: {
                ...(dragAndDrop[STATISTICS_PAGE_DND_NAME].columns || {}),
                column2: {
                    ...(dragAndDrop[STATISTICS_PAGE_DND_NAME].columns || {}).column2,
                    itemOrder: [
                        ...(dragAndDrop[STATISTICS_PAGE_DND_NAME].columns || {}).column2.itemOrder,
                        customChart.id,
                    ],
                },
            },
        },
    } as IDragAndDropState<IDndItem<string>>;
};

export const addCustomChart = (chartValues: ICustomFormValues) => (dispatch: Dispatch, getState: () => IState) => {
    const customChart = {
        id: uuidV1(),
        period: EChartPeriod.Last30Days,
        from: moment().subtract(30, 'days').unix(),
        to: moment().unix(),
        chart_type: EChartType.Bar,
        accumulated: false,
        interval: EChartInterval.Days,
        ...getSpecificChartValues(chartValues),
    };

    return updateActorSettings(
        {
            dragAndDrop: getNewDnDState(customChart, getState),
            customCharts: [...getState().actor.settings.customCharts, customChart],
        },
        true,
    )(dispatch, getState);
};

// dnd
// saves a Drag and Drop state for a specific page in a specific Channel
export const saveDragAndDrop = (
    dragDropState: IDragAndDropState<IDndItem<string>>,
    optionalSettings?: Partial<IActorSettings>,
) => (dispatch: Dispatch, getState: () => IState) => {
    const state = getState();

    // if set, check if optional settings have changed
    const optionalSettingsHaveChanged = Boolean(
        optionalSettings &&
        Object.keys(optionalSettings).find(
            attribute =>
                !state.actor.settings[attribute] ||
                !isEqual(optionalSettings[attribute], state.actor.settings[attribute]),
        ),
    );

    // quit if nothing has changed
    if (!optionalSettingsHaveChanged && isEqual(dragDropState, state.actor.settings.dragAndDrop)) {
        return;
    }

    return updateActorSettings(
        {
            ...optionalSettings,
            dragAndDrop: {
                ...state.actor.settings.dragAndDrop,
                ...dragDropState,
            },
        },
        true,
    )(dispatch, getState);
};

export interface IDragEndPayload {
    page: string;
    columns: {
        [key: string]: string[];
    };
}

export const toggleMaximizePanel = (page: string, panel: string, initialOrder: IDragNDropPage) => (
    dispatch: Dispatch,
    getState: () => IState,
) => {
    const dragNDropState = getState().actor.settings.dragNDrop || {};
    const dragNDropPage = dragNDropState[page] || initialOrder;

    const maximized = dragNDropPage.maximized && dragNDropPage.maximized !== '' ? '' : panel;

    const updatedPage = {
        ...dragNDropPage,
        maximized,
    };

    return updateActorSettings(
        {
            dragNDrop: {
                ...dragNDropState,
                [page]: updatedPage,
            },
        },
        true,
    )(dispatch, getState);
};

export const toggleCollapse = (page: string, panel: string, initialOrder: IDragNDropPage) => (
    dispatch: Dispatch,
    getState: () => IState,
) => {
    const dragNDropState = getState().actor.settings.dragNDrop || {};

    const dragNDropPage = dragNDropState[page] || initialOrder;

    const updatedPage = {
        ...dragNDropPage,
        collapsed: {
            ...dragNDropPage.collapsed,
            [panel]: !dragNDropPage.collapsed[panel],
        },
    };

    return updateActorSettings(
        {
            dragNDrop: {
                ...dragNDropState,
                [page]: updatedPage,
            },
        },
        true,
    )(dispatch, getState);
};

export const onDrag = (page: string, dragNDropPage: IDragNDropPage) => (dispatch: Dispatch, getState: () => IState) => {
    const dragNDropState = getState().actor.settings.dragNDrop || {};

    return updateActorSettings(
        {
            dragNDrop: {
                ...dragNDropState,
                [page]: dragNDropPage,
            },
        },
        true,
    )(dispatch, getState);
};

export const onDragEnd = (payload: IDragEndPayload) => (dispatch: Dispatch, getState: () => IState) => {
    const state = getState();

    if (!state.actor.settings.dragAndDrop) {
        return false;
    }
    const stateOfPage = state.actor.settings.dragAndDrop[payload.page];
    const columns = stateOfPage?.columns;
    const newColumns: IDragAndDropColumns = {
        ...columns,
    };

    Object.keys(payload.columns).forEach((key: string) => {
        if (columns?.[key]) {
            newColumns[key] = {
                ...columns[key],
                itemOrder: payload.columns[key],
            };
        } else {
            console.error(`DragAndDrop State Error: Could now find column with key ${key} in state`);
        }
    });

    return updateActorSettings(
        {
            dragAndDrop: {
                ...state.actor.settings.dragAndDrop,
                [payload.page]: {
                    ...stateOfPage,
                    columns: newColumns,
                },
            },
        },
        true,
    )(dispatch, getState);
};

export const saveUserTableConfig = (userTableConfig: IUserTableColumn[]) => (dispatch: Dispatch, getState: any) => {
    const state = getState();
    const channelId = state.channel.id;

    return updateActorSettings(
        {
            userTableConfig: {
                ...state.actor.settings.userTableConfig,
                [channelId]: userTableConfig,
            },
        },
        true,
    )(dispatch, getState);
};
