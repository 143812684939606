import {Reducer} from 'redux';

import {IActor} from '../../definitions/actor/actor.definitions';
import {ITicket} from '../../definitions/tickets/tickets.definitions';
import {EUpdateStreamAction} from '../../enums/updateStream/EUpdateStreamAction';
import {EUpdateStreamMethod} from '../../enums/updateStream/EUpdateStreamMethod';
import {sortTicketsByStatusAndTimeSinceLastMessage} from '../../utils/ticket/ticket.utils';
import {UPDATE_STREAM} from '../connection/connection.types';
import {updateUserInTicketList} from '../tickets/tickets.reducer.utils';
import {isUserPropertyUpdate} from '../user/user.reducer';
import {actorInitialState, actorSettingsInitialState} from './actor.reducer.initialState';
import {handleActorTicketRelatedUpdateStream} from './actorTickets.utils';
import {
    ACTOR_GET_INTERNAL_NEWS_FULFILLED,
    ACTOR_GET_PROFILE_FULFILLED,
    ACTOR_GET_STATISTICS_FULFILLED,
    ACTOR_LOGIN_PENDING,
    ACTOR_LOGIN_REJECTED,
    ACTOR_LOGOUT_FULFILLED,
    ACTOR_LOGOUT_PENDING,
    ACTOR_LOGOUT_REJECTED,
    ACTOR_SET_STATUS_FULFILLED,
    ACTOR_UPDATE_FULFILLED,
    ACTOR_UPDATE_PENDING,
    ACTOR_UPDATE_REJECTED,
    ACTOR_UPDATE_SETTINGS_PENDING,
    ACTOR_UPDATE_SETTINGS_REJECTED,
} from "./actor.types";
import {
    TICKET_ASSIGN_FULFILLED,
    TICKET_CLOSE_FULFILLED,
    TICKET_OPEN_FULFILLED,
    TICKETS_GET_FULFILLED,
} from "../tickets/tickets.types";

export const actorReducer: Reducer<IActor> = (state = actorInitialState, action) => {
    const {type, payload, meta} = action;
    switch (type) {
        case UPDATE_STREAM: {
            const {message} = payload;
            if (message.action === EUpdateStreamAction.Agent && message.method === EUpdateStreamMethod.Put) {
                if (message.payload.id !== state.id) {
                    return state;
                }

                const actor = removeDuplicateAgentValues(message.payload);

                return {
                    ...state,
                    ...actor,
                    settings: {
                        ...actorSettingsInitialState,
                        ...actor.settings,
                    },
                    ...(actor.roles && actor.id === state.id && {roles: actor.roles}),
                    ...(actor.rights && actor.id === state.id && {rights: actor.rights}),
                };
            }

            if (isUserPropertyUpdate(payload)) {
                return {
                    ...state,
                    tickets: updateUserInTicketList(payload.message.payload, state.tickets),
                };
            }

            if (message.agent_id === state.id && message.action === EUpdateStreamAction.AgentStatus) {
                return {
                    ...state,
                    status: message.payload.status,
                };
            }

            return handleActorTicketRelatedUpdateStream(state, payload);
        }
        case ACTOR_LOGIN_PENDING: {
            return {
                ...state,
                loggingIn: true,
            };
        }

        case ACTOR_LOGIN_REJECTED: {
            const {token} = payload.response;
            return {
                ...state,
                loggingIn: false,
                token,
            };
        }
        case ACTOR_GET_PROFILE_FULFILLED: { // SSO
            const {
                // TODO: remove unnecessary stuff from v14 (cleanup)
                code,
                __type,
                settings,
                channels,
                agents,
                rights,
                roles,
                customer_info,
                available_languages,
                ...actorRest
            } = payload;

            const actor = removeDuplicateAgentValues(actorRest);

            return {
                ...state,
                ...actor,
                rights,
                roles,
                success: true, // this is mandatory!
                loggingIn: false,
                loggingOut: false,
                settings: {
                    ...state.settings,
                    ...settings,
                },
            };
        }

        case ACTOR_GET_STATISTICS_FULFILLED: {
            return {
                ...state,
                stats: payload,
            };
        }
        case ACTOR_GET_INTERNAL_NEWS_FULFILLED: {
            return {
                ...state,
                news: payload,
            };
        }
        case ACTOR_LOGOUT_PENDING: {
            return {...state, loggingOut: true};
        }

        case ACTOR_LOGOUT_REJECTED:
        case ACTOR_LOGOUT_FULFILLED: {
            return {
                ...state,
                success: false,
                // Since we use SSO and always enable SSO on logout, we don't need to reset the loggingOut state anymore. If we
                // reset the loggingOut state here, the page content would show up shorty until redirect to login page.
                // loggingOut: false,
            };
        }

        case ACTOR_SET_STATUS_FULFILLED:
            return {
                ...state,
                status: payload.status,
            };

        case ACTOR_UPDATE_PENDING: {
            if (!meta.payload || meta.payload?.id !== state.id) {
                return state;
            }
            return {
                ...state,
                ...meta.payload,
            };
        }
        case ACTOR_UPDATE_FULFILLED: {
            if (payload.id !== state.id) {
                break;
            }

            const {code, ...actorPayload} = payload;
            const actor = removeDuplicateAgentValues(actorPayload);

            return {
                ...state,
                ...actor,
                // don't update settings via normal ACTOR_UPDATE / use ACTOR_UPDATE_SETTINGS instead
                settings: state.settings,
            };
        }
        case ACTOR_UPDATE_REJECTED: {
            if (!meta.payload || meta.payload?.id !== state.id) {
                return state;
            }
            return {
                ...meta.oldState,
            };
        }

        case ACTOR_UPDATE_SETTINGS_PENDING: {
            // update only the settings that have been submitted
            const newPartialSettings = action.meta?.newPartialSettings || {};
            const updatedSettings = Object.keys(newPartialSettings).reduce(
                (updatedSettings, key) => ({
                    ...updatedSettings,
                    [key]: newPartialSettings[key],
                }),
                {...state.settings},
            );

            return {
                ...state,
                settings: updatedSettings,
            };
        }

        case ACTOR_UPDATE_SETTINGS_REJECTED:
            return {
                ...state,
                settings: {...action.meta.oldSettings},
            };

        case TICKETS_GET_FULFILLED: {
            if (meta?.isOwnTickets) {
                return {
                    ...state,
                    tickets: sortTicketsByStatusAndTimeSinceLastMessage(payload.tickets),
                };
            }

            break;
        }

        case TICKET_CLOSE_FULFILLED: {
            return updateActorTicketsState(state, payload.ticket || payload.tickets);
        }

        case TICKET_ASSIGN_FULFILLED: {
            return updateActorTicketsState(state, payload.ticket || payload.tickets);
        }

        case TICKET_OPEN_FULFILLED: {
            return updateActorTicketsState(state, payload.ticket || payload.tickets);
        }

        default:
            break;
    }
    return state;
};

export interface IUpdateStreamTicket extends ITicket {
    old_agent_id?: number | string;
}
export type IUpdateStreamTickets = IUpdateStreamTicket[];

const updateActorTicketsState = (state: IActor, updatedTickets: IUpdateStreamTicket | IUpdateStreamTickets) => {
    let actorTickets = state.tickets;

    for (const updatedTicket of Array.isArray(updatedTickets) ? updatedTickets : [updatedTickets]) {
        const isActorsTicket: boolean = updatedTicket.agent_id === state.id;
        // NOTE: we have to compare the user_id too to check for a ticket to be already in the list in case we reopen
        // it, and it gets a new ticket id. otherwise we have a duplicate in the actor.tickets state
        const wasActorsTicket: boolean =
            updatedTicket.old_agent_id === state.id ||
            !!actorTickets.find(ticket => ticket.user_id === updatedTicket.user_id || ticket.id === updatedTicket.id);

        // if it is ours, was ours or got ours now...
        if (isActorsTicket) {
            // update/remove the ticket in case it's already there
            if (wasActorsTicket) {
                // only leave in the list if it still belongs to the actor and is not closed
                if (updatedTicket.status) {
                    actorTickets = actorTickets.reduce(
                        (resultTickets: ITicket[], ticket) => [
                            ...resultTickets,
                            ticket.user_id === updatedTicket.user_id ? updatedTicket : ticket,
                        ],
                        [],
                    );
                } else {
                    // if we still own it, but it got closed, remove from list
                    actorTickets = actorTickets.filter(
                        ticket => ticket.user_id !== updatedTicket.user_id && ticket.id !== updatedTicket.id,
                    );
                }
            } else if (updatedTicket.status) {
                // or add ticket if it wasn't there
                actorTickets = [...actorTickets, updatedTicket];
            }
        }
        // The ticket does not belong to us or not anymore
        // so if we owned the ticket, remove it now
        else if (wasActorsTicket) {
            actorTickets = actorTickets.filter(
                ticket => ticket.user_id !== updatedTicket.user_id && ticket.id !== updatedTicket.id,
            );
        }
    }

    if (actorTickets !== state.tickets) {
        // always resort if a ticket of the actor got  added or updated
        return {
            ...state,
            tickets: sortTicketsByStatusAndTimeSinceLastMessage(actorTickets),
        };
    }

    return state;
};

const removeDuplicateAgentValues = (agentPayload: any) => {
    // remove some values to prevent duplicate entries in actor<->agent
    const {name, channels, ...actor} = agentPayload;

    return actor;
};
