import {FC, useCallback} from 'react';

import Typography from '@material-ui/core/Typography';
import {useSelector} from 'react-redux';

import {EIcons} from '../../enums/icons/EIcons';
import {getFirstCardWidget, getFirstChipWidget} from '../../redux/settings/widgetEditor/widgetEditor.selectors';
import {translateSlug} from '../../utils/translation/translation.utils';
import {openWidgetInNewTab} from '../../utils/widget/widget.utils';
import ContainedButton from '../Buttons/ContainedButton';
import IconFactory from '../IconFactory/IconFactory';

interface WidgetDirectLinksProps {
    gutterBottom?: boolean;
}

const WidgetDirectLinks: FC<WidgetDirectLinksProps> = props => {
    const {gutterBottom} = props;
    const cardWidget = useSelector(getFirstCardWidget);
    const chipWidget = useSelector(getFirstChipWidget);

    const handleOpenWidget = useCallback((id: string) => openWidgetInNewTab(id), []);

    if (!cardWidget && !chipWidget) {
        return null;
    }

    return (
        <Typography gutterBottom={gutterBottom} variant="body2" component="div" paragraph>
            {chipWidget && (
                <ContainedButton
                    onClick={() => handleOpenWidget(chipWidget?.uniqueid)}
                    label={translateSlug('open_widget_x', chipWidget?.name || 'Chip')}
                    startIcon={IconFactory.getIconWithProps(EIcons.OPEN_IN_NEW)}
                />
            )}
            {cardWidget && (
                <ContainedButton
                    onClick={() => handleOpenWidget(cardWidget?.uniqueid)}
                    label={translateSlug('open_widget_x', cardWidget?.name || 'Card')}
                    startIcon={IconFactory.getIconWithProps(EIcons.OPEN_IN_NEW)}
                />
            )}
        </Typography>
    );
};

export default WidgetDirectLinks;
