import {AnyAction, Dispatch} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import translations from '../../providers/TranslationProvider/translations';
import {ENotificationLevel} from '../../enums/notifications/ENotificationLevel';
import {
    deletePaymentSourceRequest,
    getAccountInfoRequest,
    getAccountRequest,
    getCostsRequest,
    getPaymentInformationRequest,
    getPricingInformationRequest,
    IPurchaseRequest,
    postPurchaseRequest,
    setAccountInfoRequest,
    setAccountRequest,
    setDefaultPaymentSourceRequest,
} from '../../requests/accountingRequest';
import {addNotification} from '../notifications/notifications.actions';
import {IState} from '../root.reducer';
import {IPaymentSource, TDefaultPaymentMethodRequestParams} from "../../definitions/accounting/accounting.definitions";
import {isTestAccount as isTestAccountSelector} from "./accounting.selectors";
import {
    ACCOUNT_COSTS_GET,
    ACCOUNT_GET,
    ACCOUNT_INFO_GET,
    ACCOUNT_INFO_SET,
    ACCOUNT_PAYMENT_INFO_GET,
    ACCOUNT_PRICING_INFO_GET,
    ACCOUNT_PURCHASE,
    ACCOUNT_REMOVE_PAYMENT_SOURCE,
    ACCOUNT_SET_DEFAULT_PAYMENT_SOURCE,
    ACCOUNT_UPDATE,
} from './accounting.types';

export interface ISetAccountInfoParams {
    two_factor_channel?: number;
    two_factor_authentication?: boolean;
}

export const getAccount = () => (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
    const state = getState();
    const {accounting} = state.request;
    if (!accounting.get && !accounting.loaded) {
        return dispatch({
            type: ACCOUNT_GET,
            payload: getAccountRequest(),
        });
    }
};

export const updateAccount = (payload: any) => ({
    type: ACCOUNT_UPDATE,
    payload: setAccountRequest(payload),
});

export const updateAccountInformationAndNotify = (payload: any) => (dispatch: any) => {
    dispatch(updateAccount(payload)).then(() =>
        dispatch(addNotification(translations.save_operation_success, ENotificationLevel.Success)),
    );
};

export const getPricingInformation = (force?: boolean) => (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
    const state = getState();
    const {pricingInformation} = state.request;
    const isTestAccount = isTestAccountSelector(state);

    if (isTestAccount && !pricingInformation.get && (force || !pricingInformation.loaded)) {
        return dispatch({
            type: ACCOUNT_PRICING_INFO_GET,
            payload: getPricingInformationRequest(),
        });
    }
};


export const getPaymentInformation = () => (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
    const state = getState();
    const {paymentInformation} = state.request;
    if (!paymentInformation.get && !paymentInformation.loaded) {
        return dispatch({
            type: ACCOUNT_PAYMENT_INFO_GET,
            payload: getPaymentInformationRequest(true),
        });
    }
};



export const purchaseProduct = (params: IPurchaseRequest) => ({
    type: ACCOUNT_PURCHASE,
    payload: postPurchaseRequest(params),
});

export const setDefaultPaymentSource = (params: TDefaultPaymentMethodRequestParams) => ({
    type: ACCOUNT_SET_DEFAULT_PAYMENT_SOURCE,
    payload: setDefaultPaymentSourceRequest(params),
});

export const deletePaymentSource = (params: IPaymentSource) => ({
    type: ACCOUNT_REMOVE_PAYMENT_SOURCE,
    payload: deletePaymentSourceRequest(params),
});

export const getCosts = () => (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
    const state = getState();
    const {accountCosts} = state.request;
    if (!accountCosts.get && !accountCosts.loaded) {
        return dispatch({
            type: ACCOUNT_COSTS_GET,
            payload: getCostsRequest(),
        });
    }
};

export const setAccountInfo = (param: ISetAccountInfoParams) => (dispatch: Dispatch, getState: () => IState) => {
    const {two_factor_channel, two_factor_authentication} = getState().accounting;
    return dispatch({
        type: ACCOUNT_INFO_SET,
        meta: {two_factor_channel, two_factor_authentication},
        payload: setAccountInfoRequest(param),
    });
};

export const getAccountInfo = () => (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
    const state = getState();
    const {accountInfo} = state.request;
    if (!accountInfo.get && !accountInfo.loaded) {
        return dispatch({
            type: ACCOUNT_INFO_GET,
            payload: getAccountInfoRequest(),
        });
    }
};
