import {Reducer} from 'redux';
import {IBookingState, TBookingActions} from '../../../definitions/settings/booking/booking.definitions';
import {BOOKING_SELECT_PAYMENT_METHOD, BOOKING_SELECT_PLAN, BOOKING_TOGGLE_DIALOG} from './booking.types';
import {getUrlParameter} from "../../../utils/global/global.utils";
import {QUERY_PARAM_BOOKING_PLAN, QUERY_PARAM_OPEN_DIALOG} from "../../../definitions/router/router.definitions";

export const bookingInitState = {
    selectedPlan: parseInt(getUrlParameter(QUERY_PARAM_BOOKING_PLAN) || '') || undefined,
    selectedPaymentMethod: undefined,
    conditionsChecked: false,
    open: getUrlParameter(QUERY_PARAM_OPEN_DIALOG) === 'booking',  // open dialog after redirect from stripe
};

export const bookingReducer: Reducer<IBookingState, TBookingActions> = (state = bookingInitState, action) => {
    switch (action.type) {
        case BOOKING_SELECT_PLAN:
            return {...state, selectedPlan: action.payload};
        case BOOKING_SELECT_PAYMENT_METHOD:
            return {...state, selectedPaymentMethod: action.payload};
        case BOOKING_TOGGLE_DIALOG:
            return {...state, open: action.payload};
        default:
            return state;
    }
};
