import {FC, useState} from 'react';

import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import {useDispatch} from 'react-redux';

import {toggleChatPageImageLightbox} from '../../../redux/chatPage/chatPage.actions';
import DialogTemplate from '../../DialogTemplate/DialogTemplate';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chatImageContainer: {
            borderRadius: theme.spacing(0.5),
            overflow: 'hidden',
            display: 'inline-block',
            maxWidth: 300,
            cursor: 'pointer',
            backgroundColor: 'rgba(0,0,0,0.8)',
        },
        chatImage: {
            width: 'auto',
            maxWidth: '100%',
            height: 'auto',
            maxHeight: 'calc(100vh - 300px)',
            display: 'block',
            margin: '0 auto',

            [theme.breakpoints.down('xs')]: {
                maxHeight: 'calc(100vh - 200px)',
            },
        },
        lightboxContainer: {
            // there is a bug in chrome that needs this stuff for object fit
            // https://stackoverflow.com/questions/30700144/responsive-object-fit-cover-fix-on-chrome (FW)
            textAlign: 'center',
            width: '100%',
            minWidth: '100%',
            height: 800,
            maxHeight: 800,
            overflow: 'hidden',
        },
        lightboxImage: {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
        },
        zoom_zero: {
            transform: 'initial',
        },
        zoom_one: {
            transform: 'scale(1.1)',
        },
        zoom_two: {
            transform: 'scale(1.2)',
        },
        zoom_three: {
            transform: 'scale(1.3)',
        },
        zoom_four: {
            transform: 'scale(1.5)',
        },
    }),
);

type TZoomLevel = 'zoom_zero' | 'zoom_one' | 'zoom_two' | 'zoom_three' | 'zoom_four';

enum EZoomLevel {
    zoom_zero,
    zoom_one,
    zoom_two,
    zoom_three,
    zoom_four,
}

enum EMouseDelta {
    Forward = 1,
    Backward = -1,
}

const ZOOM_MAX = 4;

interface IImageContentProps {
    media: string;
    imageClassName?: string;
}

const ImageContent: FC<IImageContentProps> = props => {
    const {media, imageClassName} = props;

    const classes = useStyles();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [zoom, setZoom] = useState(0);

    const handleOpen = () => {
        setOpen(true);
        dispatch(toggleChatPageImageLightbox());
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleWheel = (event: any) => {
        const zoomForwardValue = zoom + 1;
        const zoomBackwardValue = zoom - 1;

        const mouseDelta = Math.sign(event.deltaY);

        const {Forward, Backward} = EMouseDelta;

        // forwards scroll
        if (mouseDelta === Forward && zoomBackwardValue >= 0) {
            setZoom(zoomBackwardValue);
        }
        // backwards scroll
        else if (mouseDelta === Backward && zoomForwardValue <= ZOOM_MAX) {
            setZoom(zoomForwardValue);
        }
    };

    const chatImageClassName = classNames(classes.chatImage, imageClassName);
    const lightboxImageClassName = classNames(classes.lightboxImage, classes[EZoomLevel[zoom] as TZoomLevel]);

    return (
        <>
            <div className={classes.chatImageContainer} onClick={handleOpen}>
                <img src={media} alt='chatImage' className={chatImageClassName}/* loading={"lazy"}*/ />
            </div>
            <DialogTemplate closable fullScreen open={open} onClose={handleClose}>
                <div onWheel={handleWheel} className={classes.lightboxContainer}>
                    <img src={media} alt='lightboxImage' className={lightboxImageClassName} />
                </div>
            </DialogTemplate>
        </>
    );
};

ImageContent.displayName = 'ImageContent';

export default ImageContent;
