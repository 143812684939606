import {SortDirectionType} from 'react-virtualized';

import {ITicketFilter} from '../../definitions/ticketPage/ticketFilter.definitions';
import {
    RESET_PANEL_MARKS,
    TICKET_LIST_PAGE_SET_FILTER,
    TICKET_LIST_PAGE_SET_SEARCH_TERM,
    TICKET_LIST_PAGE_SET_SORTING,
    TICKET_PAGE_SET_REQUEST_HASH,
    UPDATE_PANEL_MARKS,
} from "./ticketPage.types";


export const updatePanelMarks = (idOrIds: number | number[]) => ({
    type: UPDATE_PANEL_MARKS,
    payload: idOrIds,
});

export const resetPanelMarks = () => ({
    type: RESET_PANEL_MARKS,
});

export const setFilter = (filter: ITicketFilter) => ({
    type: TICKET_LIST_PAGE_SET_FILTER,
    payload: filter,
});

export const setSorting = (payload: {sortBy?: string; sortDirection?: SortDirectionType}) => ({
    type: TICKET_LIST_PAGE_SET_SORTING,
    payload: payload,
});

export const setSearchTerm = (searchTerm?: string) => ({
    type: TICKET_LIST_PAGE_SET_SEARCH_TERM,
    payload: searchTerm,
});

export const setRequestHash = (requestHash: string) => ({
    type: TICKET_PAGE_SET_REQUEST_HASH,
    payload: requestHash,
});
