import {combineReducers} from 'redux';

import {apiReducer} from './api/api.reducer';
import {avvReducer} from './avv/avv.reducer';
import {bookingReducer} from './booking/booking.reducer';
import {channelFeaturesReducer} from './channelFeatures/channelFeatures.reducer';
import {facebookReducer} from './facebook/facebook.reducer';
import {messengerReducer} from './messenger/messenger.reducer';
import {messengerMenuItemsReducer} from './messengerMenu/messengerMenuItems.reducer';
import {messengerMenuSettingsReducer} from './messengerMenu/messengerMenuSettings.reducer';
import {notificationTemplatesReducer} from './notificationTemplates/notificationTemplates.reducer';
import {settingsShopReducer} from './shop/settingsShopReducer';
import {widgetEditorReducer} from './widgetEditor/widgetEditor.reducer';

const settingsReducer = combineReducers({
    facebook: facebookReducer,
    avv: avvReducer,
    api: apiReducer,
    messenger: messengerReducer,
    widgetEditor: widgetEditorReducer,
    notificationTemplates: notificationTemplatesReducer,
    channelFeatures: channelFeaturesReducer,
    messengerMenu: combineReducers({
        settings: messengerMenuSettingsReducer,
        items: messengerMenuItemsReducer,
    }),
    booking: bookingReducer,
    shop: settingsShopReducer,
});

export default settingsReducer;
