import {createSelector} from 'reselect';
import {IAccountingState, IChannelPricing} from '../../definitions/accounting/accounting.definitions';
import {IState} from '../root.reducer';
import {validateBillingInformation} from "../../utils/accounting/accounting.utils";

export const getAccounting = (state: IState) => state.accounting;
export const getCustomerId = (state: IState) => state.accounting.id;

export const isGdprDisabled = (state: IState): boolean => Boolean(state.accounting.gdpr_disabled);
export const isGdprNeeded = (state: IState): boolean => Boolean(state.accounting.gdpr_needed);

export const isInvoicingEnabled = (state: IState): boolean => state.accounting.invoicing;

export const isBookingEnabled = (state: IState): boolean => state.accounting.booking_enabled;

export const isTestAccount = (state: IState): boolean =>
    state.accounting.testphase_finished !== undefined && state.accounting.testphase_end !== undefined;

export const isTestPeriodExpired = (state: IState): boolean => state.accounting.testphase_finished === true;

export const getEndOfTestPeriod = (state: IState) => state.accounting.testphase_end || 0;

export const actorHasAcceptedAvv = (state: IState) => !state.accounting.gdpr_needed || state.accounting.gdpr_accepted;

export const isPaymentBlocked = (state: IState) =>
    !!state.accounting.paymentBlock && state.accounting.paymentBlock.blocked;



export const isTwoFactorAuthEnabledForChannel = (state: IState) => state.accounting.two_factor_authentication;

export const getAvailableAgentRoles = (state: IState) => state.accounting.roles;

const _getPricing = (accounting: IAccountingState, activeChannelId: number): IChannelPricing | undefined => {
    if (!accounting.costs.channel_pricing || accounting.costs.channel_pricing.length === 0) {
        return undefined;
    }
    if (accounting.costs.merge_channels) {
        return accounting.costs.channel_pricing[0];
    }
    return accounting.costs.channel_pricing.find(channel => channel.id === activeChannelId);
};

export const getPricing = createSelector([getAccounting, (state: IState) => state.actor.activeChannel], _getPricing);

export const getCostsInformation = (state: IState) => state?.accounting?.costs?.pricing_information;

export const getCostsLoading = (state: IState) => state?.accounting?.costs?.pricing_information?.loading;

export const getPricingInformation = (state: IState) => state?.accounting?.pricing_information;

export const getConversationPricing = (state: IState) => getPricingInformation(state)?.prices?.required.find(info => info.category === "conversation");

export const getPaymentInformation = (state: IState) => state?.accounting?.payment_information;

export const getPricingModels = (state: IState) => getPricingInformation(state)?.pricing_models;

export const getPaymentMethods = (state: IState) => getPaymentInformation(state)?.payment_methods;

export const getSetupIntent = (state: IState) => getPaymentInformation(state)?.setup_intent;

export const getPaymentSources = (state: IState) => getPaymentInformation(state)?.payment_sources;

export const hasValidAccountInformation = createSelector([getAccounting], (accounting => {
    return !Object.values(validateBillingInformation(accounting)).filter(value => !!value).length;
}));

export const getCurrency = (state: IState) => getPricingInformation(state)?.currency || 'eur';

export const getAgentsCosts = (state: IState) => getCostsInformation(state)?.total?.agent;

export const getAgentsIncluded = (state: IState) => getAgentsCosts(state)?.included || 2;

export const getAgentAdditionalAmount = (state: IState) => getAgentsCosts(state)?.amount || 0;

export const getIncludedFeatures = (state: IState) => getPricingInformation(state)?.features_included;

export const getMaxAllowedAgents = (state: IState) => state?.accounting?.costs?.max_agents;

export const customerHasBooked = (state: IState) => !!getCostsInformation(state)?.pricing_model_name;
