import {selectPreferredLanguage} from '../../providers/TranslationProvider/TranslationProvider';
import {IActor, IActorSettings, IActorStatistics} from '../../definitions/actor/actor.definitions';
import {EAgentStatus} from '../../enums/agent/EAgentStatus';
import {ETitleType} from '../../enums/paper/ETitleType';
import {ETicketSorting} from '../../enums/ticket/ETicketSorting';

export const actorSettingsInitialState: IActorSettings = {
    ticketSorting: ETicketSorting.Ascending,
    allowNotifications: 0,
    pushReceivedChat: 0,
    pushAssignedTicket: 0,
    pushNewTicket: 0,
    customCharts: [],
    emojiMartFrequently: null,
    userTableConfig: [],
    theming: {},
    agentSorting: {sortBy: 'firstname', sortDirection: 'asc'},
    tableConfigs: {},
};

export const actorStatisticsInitialState: IActorStatistics = {
    tickets_unassigned_total: 0,
    tickets_closed_total: 0,
    chats_average_in_closed_tickets: 0,
    tickets_open_total: 0,
    tickets_total: 0,
    tickets_open_answered: 0,
    tickets_open_unanswered: 0,
    tickets_opened_this_week: 0,
    tickets_closed_this_week: 0,
    chats_average_in_closed_tickets_this_week: 0,
    tickets_opened_this_month: 0,
    tickets_closed_this_month: 0,
    chats_average_in_closed_tickets_this_month: 0,
    tickets_opened_last_7_days: 0,
    tickets_closed_last_7_days: 0,
    chats_average_in_closed_tickets_last_7_days: 0,
    tickets_opened_last_30_days: 0,
    tickets_closed_last_30_days: 0,
    chats_average_in_closed_tickets_last_30_days: 0,
    tickets_average_closing_time_in_seconds: 0,
    tickets_average_closed_per_month: 0,
};

export const actorInitialState: IActor = {
    // agent values
    id: 0,
    language: selectPreferredLanguage(),
    firstname: '',
    lastname: '',
    email: '',
    profile_image: '',
    status: EAgentStatus.Offline,
    roles: [],
    all_channels: 0,
    active: 0,
    skills: [],
    tickets: [],
    logged_in: false,
    settings: actorSettingsInitialState,
    notification: 0,
    notification_delay: 5,

    // Actor specific values
    success: false,
    loggingIn: false,
    loggingOut: false,
    servertime: 0,
    activeChannel: 0,
    token: '',
    rights: [],
    stats: actorStatisticsInitialState,
    news: {
        title: '',
        text: '',
        type: ETitleType.Default,
    },
};
