import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import {IState} from '../root.reducer';
import {
    deleteCustomFieldRequest,
    getCustomFieldsRequest,
    postCustomFieldRequest,
    putCustomFieldRequest,
} from '../../requests/customerSettingRequests';
import {CUSTOM_FIELD_DELETE, CUSTOM_FIELD_POST, CUSTOM_FIELD_PUT, CUSTOM_FIELDS_GET} from "./customField.types";


export const getCustomFields = () => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
        const state = getState();
        const customFields = state.request.customFields;

        if (!customFields?.get && !customFields?.loaded) {
            return dispatch({
                type: CUSTOM_FIELDS_GET,
                payload: getCustomFieldsRequest(),
            });
        }
    };
};

export const postCustomField = (targetGroup: any) => ({
    type: CUSTOM_FIELD_POST,
    payload: postCustomFieldRequest(targetGroup),
});

export const putCustomField = (targetGroup: any) => ({
    type: CUSTOM_FIELD_PUT,
    payload: putCustomFieldRequest(targetGroup),
});

export const deleteCustomField = (id: number) => ({
    type: CUSTOM_FIELD_DELETE,
    payload: deleteCustomFieldRequest(id),
});
