import Grid from '@material-ui/core/Grid';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {ChangeEvent, FC} from 'react';

import {IHeaderBarLayoutTicketChips} from '../../../../definitions/headerBar/headerBar.definitions';
import {ITicket} from '../../../../definitions/tickets/tickets.definitions';
import TicketChip from './TicketChip';

const useStyles = makeStyles(() =>
    createStyles({
        mobileRoot: {
            display: 'flex',
            flex: '1 1 auto',
            justifyContent: 'center',
        },
    }),
);

interface IChipContainerProps {
    isMobile: boolean;
    tickets: ITicket[];
    layout: IHeaderBarLayoutTicketChips;
    overdueTime: number;
    criticalTime: number;
    activeTicketId: number;
    handleClick: (ticket: ITicket) => void;
    handlePopoverClose: () => void;
    handlePopoverOpen: (event: ChangeEvent<HTMLInputElement>, ticket: ITicket) => void;
}

const ChipContainer: FC<IChipContainerProps> = props => {
    const {
        isMobile,
        tickets,
        layout,
        overdueTime,
        criticalTime,
        activeTicketId,
        handleClick,
        handlePopoverClose,
        handlePopoverOpen,
    } = props;
    const classes = useStyles();

    const hasTickets = tickets && tickets.length > 0;
    const chipLayout = {[layout.breakpoint]: layout.cells};

    if (!hasTickets) {
        return null;
    }

    const ticketChips = tickets.map((ticket, index) => {
        if (index >= layout.maxTicketsToShow) {
            return null;
        }

        const ticketChip = (
            <TicketChip
                key={index}
                index={index}
                ticket={ticket}
                overdueTime={overdueTime}
                criticalTime={criticalTime}
                handleClick={handleClick}
                handlePopoverClose={handlePopoverClose}
                handlePopoverOpen={handlePopoverOpen}
                activeTicketId={activeTicketId}
            />
        );

        if (isMobile) {
            return ticketChip;
        }

        return (
            <Grid item {...chipLayout} key={index}>
                {ticketChip}
            </Grid>
        );
    });

    if (isMobile) {
        return <div className={classes.mobileRoot}>{ticketChips}</div>;
    }

    return <>{ticketChips}</>;
};

export default ChipContainer;
