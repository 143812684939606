import {FunctionComponent, useEffect, useMemo} from 'react';

import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import Masonry from 'react-masonry-css'; // Masonry component is on the Roadmap for MUI Components but not yet implemented
import {connect, useDispatch, useSelector} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {compose} from 'recompose';

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import LinkifyText from '../../components/LinkifyText/LinkifyText';
import PaperTemplate from '../../components/PaperTemplate/PaperTemplate';
import PaperTitleBar from '../../components/PaperTitleBar/PaperTitleBar';
import translations from '../../providers/TranslationProvider/translations';
import {IActorNews} from '../../definitions/actor/actor.definitions';
import ERights from '../../enums/actor/ERights';
import {ESettingsRoutes} from '../../enums/routes/ERoutes';
import {ETextModuleStatus} from '../../enums/textModule/ETextModulesStatus';
import {usesTicketSystem} from '../../redux/channel/channel.selectors';
import {isTestAccount} from '../../redux/accounting/accounting.selectors';
import {IState} from '../../redux/root.reducer';
import {getTextModules} from '../../redux/textModule/textModule.actions';
import {getTextModulesByStatus} from '../../redux/textModule/textModules.selectors';
import {actorHasRights} from '../../utils/actor/actor.utils';
import DashboardAgentStats from './DashboardAgentStats';
import DashboardAlerts from './DashboardAlerts';
import DashboardStatistics from './DashboardStatistics';
import DashboardWelcome from './DashboardWelcome';
import DashboardWhatsAppOnboarding from './DashboardWhatsAppOnboarding';
import OnboardingStepsToComplete from './OnboardingStepsToComplete';
import {EWBSetupStatus} from '../../enums/messenger/EWBActivationStatus';
import {EMessenger} from '../../enums/messenger/EMessenger';
import {getMessengerTranslation} from '../../utils/messenger/messenger.utils';
import {useParamSelector} from '../../redux/utils';
import CustomAlert from '../../components/CustomAlert/CustomAlert';
import Box from '@material-ui/core/Box';
import {ETitleType} from '../../enums/paper/ETitleType';
import OverflowScrollContainer from "../../components/OverflowScrollContainer/OverflowScrollContainer";
import {Theme} from "@material-ui/core/styles/createTheme";
import {useTypedSelector} from "../../utils/hooks/useTypedSelector";
import {DashboardSEOnboarding4} from "./DashboardSEOnboarding/DashboardSEOnboarding4";

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            paddingTop: 0,
        },
        link: {
            color: theme.palette.primary.main,
            textDecoration: 'underline',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        masonry: {
            height: '100%',
            '& > div': {
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                },
                [theme.breakpoints.up('sm')]: {
                    width: '50%',
                },
            },
        },
        panel: {
            display: 'flex',
            'div&$panel': {
                height: 'auto',
            },
        },
        masonryGrid: {
            marginLeft: theme.spacing(-1),
            marginRight: theme.spacing(-1),
            display: 'flex',
        },
        masonryColumn: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),

            '& > div': {
                marginBottom: theme.spacing(2),

                '&:last-child': {
                    marginBottom: 0,
                },
            },
        },
    }),
);

interface IDashboardPageProps {
    isTestAccount: boolean;
    showWhatsAppBusinessForm: boolean;
    paymentBlock?: any; // removed lodash get -> TODO type this properly
    news?: IActorNews[];
    whatsAppBusinessStatus: any;
}

export const DashboardPage: FunctionComponent<IDashboardPageProps> = (
    {
        news,
        isTestAccount,
        showWhatsAppBusinessForm,
        paymentBlock,
        whatsAppBusinessStatus,
    }
) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const newsItem = news && news.length ? news[0] : null;
    const showWhatsAppPanel =
        isTestAccount &&
        showWhatsAppBusinessForm &&
        !(whatsAppBusinessStatus && whatsAppBusinessStatus.setup_status?.toUpperCase() !== EWBSetupStatus.None);
    const isTicketSystemActive = useSelector(usesTicketSystem);
    // const isSimpleChatActive = useSelector(usesSimpleChat);
    const isSmLayout = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const actor = useSelector((state: IState) => state.actor);
    const textModules = useSelector((state: IState) =>
        getTextModulesByStatus(state, ETextModuleStatus.Draft),
    );
    const showTextModulePanel = actorHasRights(actor, [ERights.BotEdit]) && textModules.length;
    const messenger_errors = useSelector((state: IState) => state.channel.messenger_errors);
    const messenger_errors_keys = messenger_errors && Object.keys(messenger_errors);
    const isAdmin = useParamSelector(actorHasRights, ERights.Administrate);

    const contractStart = useSelector((state: IState) => state.accounting.contract_start);
    const testphaseStart = useTypedSelector(state => state.accounting.testphase_start);

    const onboardingEnabled = useMemo(() => {
        // don't show onboarding, if neither contract start nor testphase start are set (i.e. during load)
        if (!testphaseStart && !contractStart) {
            return false;
        }

        // if booked, check if its a MessengerPeople account (date is in the future)
        if (contractStart) {
            const bookingIsInTheFuture = moment.unix(contractStart).isAfter(moment().add(1, 'days'));

            // hide onboarding if its a MessengerPeople account (date in future)
            if (bookingIsInTheFuture) {
                return false;
            }
        }

        return !contractStart;
    }, [testphaseStart, contractStart]);

    const getPaymentAlerts = () => {
        const amount = paymentBlock?.amount || 0;
        const blockDate = paymentBlock?.block_date || 0;

        if (amount < 1 || blockDate === 0) {
            return null;
        }

        if (amount === 1) {
            return [
                `${translations.open_invoice_number.replace('%OPEN_INVOICES%', amount)}
                 ${blockDate && translations.open_invoice_date
                    .replace('%BLOCK_DATE%', moment.unix(blockDate).format('Do MMMM YYYY'))}`,
            ];
        }

        return [
            `${translations.open_invoices_number.replace('%OPEN_INVOICES%', amount)}
             ${blockDate && translations.open_invoice_date
                .replace('%BLOCK_DATE%', moment.unix(blockDate).format('Do MMMM YYYY'))}`,
        ];
    };

    useEffect(() => {
        dispatch(
            getTextModules({
                status: ETextModuleStatus.Draft,
            }),
        );
    }, [dispatch]);

    const paymentAlerts = getPaymentAlerts();

    const masonryItems = useMemo(() => {
        const _masonryItems = [<DashboardWelcome key="DashboardWelcome"/>];

        if (onboardingEnabled) {
            _masonryItems.push(<OnboardingStepsToComplete key="OnboardingStepsToComplete"/>);
        }

        if (newsItem) {
            _masonryItems.push(
                <PaperTemplate
                    key="LinkifyText"
                    stretch
                    titleBar={<PaperTitleBar title={newsItem.title} titleType={newsItem.type}/>}
                >
                    <Typography color="inherit" variant="body2" style={{whiteSpace: 'pre-line'}}>
                        <LinkifyText emojify text={newsItem.text}/>
                    </Typography>
                </PaperTemplate>,
            );
        }

        if (showTextModulePanel) {
            _masonryItems.push(
                <PaperTemplate
                    key="textmodules"
                    stretch
                    titleBar={<PaperTitleBar title={translations.textmodule_dashboard_header}/>}
                >
                    <Typography color="inherit" variant="body2">
                        {translations.textmodule_dashboard_info}
                    </Typography>
                    <Link to={ESettingsRoutes.TextModules} className={classes.link}>
                        {translations.textmodule_dashboard_link}
                    </Link>
                </PaperTemplate>,
            );
        }

        if (isTicketSystemActive && actorHasRights(actor, [ERights.StatisticsTickets])) {
            _masonryItems.push(
                <div key="DashboardStatistics">
                    <DashboardStatistics/>
                </div>
            );
        }

        if (paymentAlerts && actorHasRights(actor, [ERights.Administrate])) {
            _masonryItems.push(
                <div key="DashboardAlerts">
                    <DashboardAlerts paymentAlerts={paymentAlerts}/>
                </div>
            );
        }

        if (showWhatsAppPanel) {
            _masonryItems.push(<DashboardWhatsAppOnboarding key="DashboardWhatsAppOnboarding"/>);
        }

        if (messenger_errors_keys?.length) {
            _masonryItems.push(
                <PaperTemplate
                    key="messenger_errors"
                    stretch
                    titleBar={
                        <PaperTitleBar
                            title={translations.messenger_errors_dashboard_header}
                            titleType={ETitleType.Error}
                        />
                    }
                >
                    <Box mb={2}>
                        <Typography color="inherit" variant="body2">
                            {translations.messenger_errors_dashboard_body}
                        </Typography>
                    </Box>
                    {messenger_errors_keys.map((key) => {
                        const messenger = key as EMessenger;
                        return (
                            <CustomAlert key={messenger} variant="error">
                                {getMessengerTranslation(messenger, translations)}: {messenger_errors[messenger]}
                            </CustomAlert>
                        );
                    })}
                    <Box mt={2}>
                        {isAdmin ? (
                            <Link to={ESettingsRoutes.Messenger} className={classes.link}>
                                {translations.messenger_dashboard_link}
                            </Link>
                        ) : (
                            <Typography color="inherit" variant="body2">
                                {translations.messenger_errors_hint}
                            </Typography>
                        )}
                    </Box>
                </PaperTemplate>,
            );
        }

        if (isTicketSystemActive && actorHasRights(actor, [ERights.StatisticsAgents])) {
            _masonryItems.push(<DashboardAgentStats key="DashboardAgentStats"/>);
        }

        // if (isSimpleChatActive) {
        //     _masonryItems.push(<ActiveUserChart key="ActiveUserChart"/>);
        // }


        return _masonryItems;

    }, [
        actor,
        classes.link,
        isTicketSystemActive,
        isAdmin,
        onboardingEnabled,
        messenger_errors,
        messenger_errors_keys,
        newsItem,
        paymentAlerts,
        showTextModulePanel,
        showWhatsAppPanel,
    ]);

    return (
        <OverflowScrollContainer>
            <DashboardSEOnboarding4/>
            <Masonry
                breakpointCols={isSmLayout ? 1 : 2}
                className={classes.masonryGrid}
                columnClassName={classes.masonryColumn}
            >
                {masonryItems.map(masonryItem => masonryItem)}
            </Masonry>
        </OverflowScrollContainer>
    );
};

const mapStateToProps = (state: IState) => ({
    isTestAccount: isTestAccount(state),
    showWhatsAppBusinessForm: Boolean(state.channel.messenger.WB),
    paymentBlock: state.actor && state.accounting?.paymentBlock,
    news: state.actor.news,
    whatsAppBusinessStatus: state.settings.messenger.whatsAppBusinessStatus,
});

const ConnectedDashboardPage = compose<IDashboardPageProps, {}>(connect(mapStateToProps), withRouter)(DashboardPage);

const DashboardPagePageWithBoundary = (props: RouteComponentProps<any>) => (
    <ErrorBoundary>
        <ConnectedDashboardPage {...props} />
    </ErrorBoundary>
);

export default DashboardPagePageWithBoundary;
