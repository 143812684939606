import {IChannel} from '../../definitions/channel/channel.definitions';
import {ITicket} from '../../definitions/tickets/tickets.definitions';
import {isTicketAnswered, isTicketOlderOrEqualThanMinutes} from './ticket.utils';

const TICKET_OVERDUE_TIME = 60;
const TICKET_CRITICAL_TIME = 600;

export const getInitStats = () => ({
    assigned: 0,
    answered: 0,
    pending: 0,
    overdue: 0,
    critical: 0,
});

export const getCalculatedStats = (tickets: ITicket[], channel: IChannel) => {
    const stats = getInitStats();

    const {ticket_overdue_time = TICKET_OVERDUE_TIME, ticket_critical_time = TICKET_CRITICAL_TIME} = channel;

    tickets.forEach((ticket: ITicket) => {
        stats.assigned++;

        if (isTicketAnswered(ticket)) {
            stats.answered++;
        } else {
            stats.pending++;

            if (isTicketOlderOrEqualThanMinutes(ticket, ticket_critical_time)) {
                stats.critical++;
            } else if (isTicketOlderOrEqualThanMinutes(ticket, ticket_overdue_time)) {
                stats.overdue++;
            }
        }
    });

    return stats;
};
