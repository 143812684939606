import {PureComponent} from 'react';

import {connect} from 'react-redux';
import {RouteChildrenProps, withRouter} from 'react-router';

import ERights from '../../enums/actor/ERights';
import {ESettingsRoutes} from '../../enums/routes/ERoutes';
import {isTestAccount, isTestPeriodExpired} from '../../redux/accounting/accounting.selectors';
import {IState} from '../../redux/root.reducer';
import {actorHasRights} from '../../utils/actor/actor.utils';
import DialogTemplate from '../DialogTemplate/DialogTemplate';
import translations from '../../providers/TranslationProvider/translations';

const accountSettingsRoute = ESettingsRoutes.Account;

interface ITestPeriodExpiredDialogMappedProps {
    isTestAccount: boolean;
    userCanProcessOrder: boolean;
    isTestPeriodExpired: boolean;
    isAccountSettingsPage: boolean;
}

class TestPeriodExpiredDialog extends PureComponent<
    RouteChildrenProps & ITestPeriodExpiredDialogMappedProps
> {
    render() {
        const {isTestAccount, isTestPeriodExpired, userCanProcessOrder, isAccountSettingsPage} = this.props;

        // Don't show during test period, if not a test period anymore or if currently on the booking page
        if (!isTestAccount || !isTestPeriodExpired || isAccountSettingsPage) {
            return null;
        }

        // If actor can sign avv, we need to have some other props for the dialog
        const additionalAdminProps = !userCanProcessOrder
            ? {}
            : {
                  description: translations.description_test_time_over_agents,
                  actionsConfig: [
                      {
                          onClick: () => this.props.history.push(accountSettingsRoute),
                          label: translations.go_to_settings,
                      },
                  ],
              };

        return (
            <DialogTemplate
                open
                ariaLabel="dialogTestIsOver"
                closable={false}
                contentText={translations.description_test_time_over}
                title={translations.headline_test_time_over}
                {...additionalAdminProps}
            />
        );
    }
}

const mapStateToProps = (state: IState, {location}: RouteChildrenProps) => {
    return {
        isTestAccount: isTestAccount(state),
        userCanProcessOrder: actorHasRights(state.actor, ERights.SettingsChannel),
        isTestPeriodExpired: isTestPeriodExpired(state),
        isAccountSettingsPage: location?.pathname === accountSettingsRoute,
    };
};

export default withRouter(connect(mapStateToProps)(TestPeriodExpiredDialog));
