import {IActor} from '../../definitions/actor/actor.definitions';
import {IUpdateStreamPayload} from '../../definitions/updateStream/updateStream.definitions';
import {sortTicketsByStatusAndTimeSinceLastMessage} from '../../utils/ticket/ticket.utils';
import {ITicket} from '../../definitions/tickets/tickets.definitions';
import {
    isChatUpdateStreamPayload,
    isTicketNoteOrLabelAdd,
    isUpdateStreamPayloadWithTicket,
} from '../tickets/tickets.reducer.utils';

const isTicketRelated = (payload: IUpdateStreamPayload) => {
    if (isChatUpdateStreamPayload(payload)) {
        return true;
    }
    if (isUpdateStreamPayloadWithTicket(payload)) {
        return true;
    }
    return !!isTicketNoteOrLabelAdd(payload);
};

const isRelevant = (actorId: number, affectedAgents?: string | number[]) => {
    if (!affectedAgents) {
        return true;
    }
    if (typeof affectedAgents === 'string' && affectedAgents.split(',').includes(actorId.toString())) {
        return true;
    }
    return Array.isArray(affectedAgents) && affectedAgents.includes(actorId);
};

export const handleActorTicketRelatedUpdateStream = (state: IActor, payload: IUpdateStreamPayload): IActor => {
    // is it relevant to the actor
    if (!isRelevant(state.id, payload.message.affected_agent)) {
        return state;
    }

    // is it ticket stuff
    if (!isTicketRelated(payload)) {
        return state;
    }

    let tickets = [...state.tickets];

    if (isChatUpdateStreamPayload(payload)) {
        const newChat = {...payload.message.payload};

        tickets = state.tickets.map(ticket => {
            if (ticket.id !== newChat.ticket_id) {
                return ticket;
            }

            // always remove old chat and replace it with new one
            const oldChats = ticket.chats ? ticket.chats.filter(chat => chat.id !== newChat.id) : [];

            return {
                ...ticket,
                chats: [...oldChats, newChat],
            };
        });
    }

    if (isUpdateStreamPayloadWithTicket(payload) || isTicketNoteOrLabelAdd(payload)) {
        let ticketFromPayload: ITicket;

        if (isTicketNoteOrLabelAdd(payload)) {
            ticketFromPayload = {...payload.message.payload};
        } else {
            if (!payload?.message?.payload?.ticket) {
                return state;
            }

            ticketFromPayload = {...payload.message.payload.ticket};
        }

        // NOTE: we have to compare the user_id too to check for a ticket to be already in the list in case we reopen
        // it, and it gets a new ticket id. otherwise we have a duplicate in the actor.tickets state (LH)
        const ticketFromState = state.tickets.find(
            ticket => ticket.user_id === ticketFromPayload.user_id || ticket.id === ticketFromPayload.id,
        );

        // remove ticket
        if (ticketFromState?.agent_id !== ticketFromPayload.agent_id) {
            tickets = tickets.filter(ticket => ticket.id !== ticketFromPayload.id);
        }

        // update ticket
        if (ticketFromState?.agent_id === ticketFromPayload.agent_id) {
            tickets = tickets.map(ticket => {
                if (ticket.id !== ticketFromPayload.id) {
                    return ticket;
                }

                return ticketFromPayload;
            });
        }

        // add ticket
        if (!ticketFromState && ticketFromPayload.agent_id === state.id) {
            tickets = [...state.tickets, ticketFromPayload];
        }
    }

    return {
        ...state,
        tickets: sortTicketsByStatusAndTimeSinceLastMessage(tickets),
    };
};
