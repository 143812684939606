import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';

import {countryCodes} from '../../../enums/country/countryCodes';
import translations from '../../../providers/TranslationProvider/translations';
import {ChangeEvent, Component} from 'react';

export const styles = (theme: Theme) =>
    createStyles({
        selector: {
            width: '100%',
            marginTop: theme.spacing(0.5),
        },
    });

interface IPhoneCountryCodeSelectorState {
    value: string;
    searchTerm: string;
    open: boolean;
    options: any;
}

interface IPhoneCountryCodeSelectorProps {
    classes: {
        selector: string;
    };
    value?: string;
    onChange: (event: ChangeEvent<any>) => any;
    translations?: any;
}

export class PhoneCountryCodeSelector extends Component<IPhoneCountryCodeSelectorProps,
    IPhoneCountryCodeSelectorState> {
    state = {
        value: this.props.value ? String(this.props.value) : '0',
        searchTerm: '',
        open: false,
        options: null,
    };

    componentDidMount() {
        this.buildOptions(true);
    }

    componentWillUnmount() {
        this.handleClose();
    }

    componentDidUpdate(prevProps: IPhoneCountryCodeSelectorProps, prevState: IPhoneCountryCodeSelectorState) {
        if (this.state.open) {
            document.addEventListener('keydown', this.handleKeyDown);
        }

        if (!this.state.open) {
            this.handleClose();
        }

        if (prevState.searchTerm !== this.state.searchTerm) {
            this.buildOptions();
        }

        if (prevProps.value !== this.props.value) {
            this.setState({value: String(this.props.value)});
        }
    }

    handleKeyDown = (event: KeyboardEvent) => {
        const {searchTerm} = this.state;

        if (event.key.length === 1) {
            this.setState({searchTerm: searchTerm + event.key});
        }

        if (event.key === 'Backspace') {
            this.setState({searchTerm: searchTerm.slice(0, searchTerm.length - 1)});
        }
    };

    handleClose = () => {
        document.removeEventListener('keydown', this.handleKeyDown);
    };

    render() {
        return (
            <FormControl className={this.props.classes.selector}>
                <Select
                    open={this.state.open}
                    onClose={() => this.setState({open: false})}
                    onOpen={() => this.setState({open: true})}
                    label={translations.country_code}
                    onChange={(event: ChangeEvent<any>) => {
                        this.setState(
                            {
                                value: event.target.value,
                            },
                            this.props.onChange(event),
                        );
                    }}
                    value={this.state.value}
                    className={this.props.classes.selector}
                    inputProps={{
                        name: 'COUNTRY_CODE',
                    }}
                    input={<OutlinedInput labelWidth={0} />}
                >
                    <MenuItem disabled value='0'>
                        {this.renderText()}
                    </MenuItem>
                    {this.state.options}
                </Select>
            </FormControl>
        );
    }

    buildMenuItem = (value: string, text: string, key: number) => (
        <MenuItem key={key} value={value}>{`${text} (+${value})`}</MenuItem>
    );

    getLabel = (label: string) => {
        if (!this.props.translations || !this.props.translations[label]) {
            return translations[label];
        }

        return this.props.translations[label];
    };

    renderText = () => {
        if (this.state.searchTerm.length > 0) {
            return this.state.searchTerm;
        }

        if (this.state.open) {
            return `${this.getLabel('please_filter_your_countrys')}...`;
        }

        return `${this.getLabel('please_choose_your_country_code')}...`;
    };

    buildOptions = (initial = false) => {
        if (!initial && this.state.searchTerm === '' && this.state.value === '0') {
            return null;
        }

        const options = Object.keys(countryCodes)
            .filter(countryCodeKey => countryCodeKey.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) !== -1)
            .map((countryCodeKey: string, index: number) => {
                const countryCode = countryCodes[countryCodeKey];

                if (!countryCode.text) {
                    const text = countryCodeKey.replace(/([a-zA-Z])(?=[A-Z])/g, ' ');

                    return this.buildMenuItem(countryCode.value, text.charAt(0).toUpperCase() + text.slice(1), index);
                }

                return this.buildMenuItem(countryCode.value, countryCode.text, index);
            });

        this.setState({options});
    };
}

const StyledPhoneCountryCodeSelector = withStyles(styles)(PhoneCountryCodeSelector);
export default StyledPhoneCountryCodeSelector;
