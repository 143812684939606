import {IChannelTag} from '../definitions/channel/channel.definitions';
import {request} from './Api/Api';

const CHANNEL_URL = 'channel';
const CHANNEL_LABELS_URL = `${CHANNEL_URL}/labels`;
const CHANNEL_AGENTS_URL = `${CHANNEL_URL}/agents`;
const CHANNEL_INVOICES_URL = `${CHANNEL_URL}/invoices`;
const CHANNEL_APIKEY_URL = `${CHANNEL_URL}/apikey`;

// set default values for fields not represented in the form (SHOULD BE SERVER-SIDE!)
const parseChannelLabel = ({id, name, color_bg, color_tx}: IChannelTag) => ({
    id,
    name,
    color_bg,
    color_tx: color_tx || '000000',
});

export const getChannelsRequest = () => request({method: 'GET', url: CHANNEL_URL});

export const updateChannelRequest = (payload: any) =>
    request(
        {method: 'PUT', url: CHANNEL_URL},
        {
            ...payload,
            content_notifications: payload.content_notifications
                ? JSON.stringify(payload.content_notifications)
                : undefined,
            webchat_availability: payload.webchat_availability
                ? JSON.stringify(payload.webchat_availability)
                : undefined,
        },
    );

export const getChannelLabelsRequest = () => request({method: 'GET', url: CHANNEL_LABELS_URL});



export const createChannelLabelRequest = (channelLabel: IChannelTag) =>
    request({method: 'POST', url: CHANNEL_LABELS_URL}, parseChannelLabel(channelLabel));

export const updateChannelLabelRequest = (channelLabel: IChannelTag) =>
    request({method: 'PUT', url: CHANNEL_LABELS_URL}, parseChannelLabel(channelLabel));

export const deleteChannelLabelRequest = (labelId: number) => {
    return request({method: 'DELETE', url: CHANNEL_LABELS_URL}, {id: labelId});
};

export const getChannelInvoicesRequest = () => request({method: 'GET', url: CHANNEL_INVOICES_URL});

export const getChannelAgentsRequest = () => request({method: 'GET', url: CHANNEL_AGENTS_URL});

export const getApiKeyRequest = () => request({method: 'GET', url: CHANNEL_APIKEY_URL});

export const putApiKeyRequest = () => request({method: 'PUT', url: CHANNEL_APIKEY_URL});

export const ChannelParams = Object.freeze({
    USE_AUTOROUTES: 'ticket_use_autoroutes',
    TICKET_AUTO_ASSIGN: 'ticket_auto_assign',
    TICKET_DEACTIVATE_BOT: 'ticket_deactivate_bot',
    TICKET_MAX_PER_AGENT: 'ticket_max_per_agent',
    TICKET_ASSIGN_ONLINE_AGENT: 'ticket_assign_online_agent',
    TICKET_ASSIGN_LAST_AGENT: 'ticket_assign_last_agent',
});

export const TicketDeactivateBot = Object.freeze({
    DISABLED: 0,
    ON_TICKET_CREATE: 1,
    ON_CHAT_RECEIVE: 2,
});
