import {Action, AnyAction, Reducer} from "redux";
import {isMultiChannelTicketUpdate} from "../tickets/tickets.reducer.utils";
import {IEntityById} from "../../definitions/generic/generic.definitions";
import {
    MULTI_CHANNEL_GET_FULFILLED,
    MULTI_CHANNEL_GET_PENDING,
    MULTI_CHANNEL_GET_REJECTED,
} from "./multiChannel.types";
import {UPDATE_STREAM} from "../connection/connection.types";

// definitions
// multiChannel update stream action
export interface MultiChannelUpdateStreamAction extends Action, AnyAction {
    type: typeof UPDATE_STREAM;
    payload?: {
        message: {
            action: string;
            channel_id: number;
            method: string;
            name: string;
            affected_agent: string;
            payload: {
                channel_id: number;
                ticket_id: number;
            }
        },
    }
}

// payload: array of ticketIds for each channelId
export interface MultichannelAction extends Action, AnyAction {
    type: typeof MULTI_CHANNEL_GET_FULFILLED | typeof MULTI_CHANNEL_GET_PENDING | typeof MULTI_CHANNEL_GET_REJECTED;
    payload: IEntityById<number[]>
}

// each channel can have an array of tickets ids that are new
export interface MultiChannelState extends IEntityById<number[]> {
}

export const multiChannelReducer: Reducer<MultiChannelState> = (state = {}, action: MultiChannelUpdateStreamAction | MultichannelAction) => {
    switch (action.type) {
        case UPDATE_STREAM: {
            if (action.payload && isMultiChannelTicketUpdate(action.payload)) {
                const payload = action.payload.message.payload;
                const channelsTickets = state[payload.channel_id];
                const uniqueTicketIds = channelsTickets
                    ? [...new Set([...channelsTickets, payload.ticket_id])]
                    : [payload.ticket_id];

                return {
                    ...state,
                    [payload.channel_id]: uniqueTicketIds,
                };
            }
            return state;
        }

        case MULTI_CHANNEL_GET_FULFILLED: {
            const payload = action.payload;
            return Object.keys(payload).reduce((_state, channel) => {
                return ({
                    ..._state,
                    [channel]: [...new Set(payload[Number(channel)])],
                });
            }, {});
        }

        case MULTI_CHANNEL_GET_REJECTED: {
            return state;
        }

        default:
            return state;
    }
};
