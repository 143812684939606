import {ChangeEvent, createRef, FC, useEffect, useState} from 'react';

import Button from '@material-ui/core/Button';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';

import {EMessenger} from '../../enums/messenger/EMessenger';
import {EBreakpoints} from '../../enums/ui/EBreakpoints';
import {resetChatPageUpload} from '../../redux/chatPage/chatPage.actions';
import {IState} from '../../redux/root.reducer';
import {AcceptedMimeTypes} from '../../utils/ticket/ticket.utils';
import MediaError from '../MediaError/MediaError';
import translations from '../../providers/TranslationProvider/translations';
import AttachmentPreview from '../AttachmentPreview/AttachmentPreview';
import {getActiveChannel} from '../../redux/actor/actor.selectors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        attachment: {
            maxHeight: 300,
        },
        upload: {
            display: 'none',
        },
        attachmentRow: {
            marginTop: theme.spacing(1),
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            maxWidth: '100%',

            '& img, video': {
                width: 'auto',
                maxWidth: '100%',
                height: 'auto',
                maxHeight: 'calc(100vh - 300px)',
                display: 'block',

                [theme.breakpoints.down(EBreakpoints.XS)]: {
                    maxHeight: 'calc(100vh - 200px)',
                },
            },
        },
        buttonRow: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',

            '& > *:first-child': {
                marginRight: theme.spacing(0.5),
            },
        },
    }),
);

interface IMediaUploaderProps {
    messenger?: EMessenger;
    uploadUrl: string;
    isLoading: boolean;
    error?: string;
    className?: string;
    name?: string;
    handleDelete: () => void;
    handleUpload: (postBody: any, messenger: string) => any;
}

const MediaUploader: FC<IMediaUploaderProps> = props => {
    const {className, name = 'file', uploadUrl, isLoading, error = '', handleDelete, messenger, handleUpload} = props;

    const classes = useStyles();
    const dispatch = useDispatch();
    const inputRef: any = createRef();
    const [mediaError, setMediaError] = useState(error);
    const uploadError = useSelector((state: IState) => state.chatPage.uploadError);
    const activeChannel = useSelector(getActiveChannel);

    useEffect(() => {
        setMediaError(uploadError || '');
    }, [uploadError]);

    useEffect(() => {
        return () => {
            dispatch(resetChatPageUpload());
        };
    }, [dispatch]);

    const renderAttachmentPreview = () => {
        if (!uploadUrl && !isLoading && !mediaError) {
            return null;
        }

        if (mediaError) {
            if (mediaError === 'no_file') {
                return null;
            }
            return <MediaError message={translations[mediaError] || mediaError} />;
        }

        return <AttachmentPreview uploadUrl={uploadUrl} isLoading={isLoading} />;
    };

    const deleteInput = () => {
        if (inputRef.current) {
            inputRef.current.value = '';
            inputRef.current = null;
        }
        handleDelete();
    };

    const handleChangeAttachment = (event: ChangeEvent<HTMLInputElement>) => {
        const postBody = {
            channel_id: activeChannel,
            attachment: event?.target?.files ? event.target.files[0] : '',
            messenger: Array.isArray(messenger) ? messenger.join(',') : messenger,
        };

        if (!postBody.attachment) {
            return;
        }

        if (inputRef.current) {
            inputRef.current.value = '';
            inputRef.current = null;
        }

        handleUpload(postBody, '').catch((error: Error) => {
            setMediaError(error.message);
        });
    };

    return (
        <>
            <div className={classes.buttonRow}>
                <div style={{display: uploadUrl ? 'none' : 'block'}}>
                    <input
                        multiple
                        type='file'
                        name={name}
                        id={name}
                        ref={inputRef}
                        accept={Object.keys(AcceptedMimeTypes).join(',')}
                        className={classes.upload}
                        onChange={handleChangeAttachment}
                    />

                    <label htmlFor={name}>
                        <Button variant='outlined' component='span'>
                            {translations.upload_file}
                        </Button>
                    </label>
                </div>
                {Boolean(uploadUrl) && (
                    <Button variant='outlined' onClick={deleteInput}>
                        {translations.remove_attachment}
                    </Button>
                )}
            </div>
            <div className={classNames(classes.attachmentRow, className)}>{renderAttachmentPreview()}</div>
        </>
    );
};

export default MediaUploader;
