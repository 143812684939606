import {SvgIcon} from '@material-ui/core';
import {FC} from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import {MessengerIconProps} from "../MessengerIcon";
import translations from "../../../providers/TranslationProvider/translations";

const LiveChatIcon: FC<Omit<MessengerIconProps, 'messenger'>> = props => {
    const {tooltip, ...rest} = props;

    const icon = (
        <SvgIcon viewBox={'0 0 400 400'} style={{fillRule:'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2}} {...rest}>
            <g style={{transform: 'scale(1.14285714)', transformOrigin: 'center'}}>
                <circle cx="200" cy="200" r="175" style={{fill: '#ff9300'}}/>
                <path d="M278.455,205c11.038,0 20,8.962 20,20l-0,40c-0,11.038 -8.962,20 -20,20l-150,0c-11.038,0 -20,-8.962 -20,-20l-0,-20c-0,0 -10.619,-21.239 -16.382,-32.764c-0.775,-1.55 -0.692,-3.391 0.219,-4.865c0.911,-1.474 2.52,-2.371 4.253,-2.371l181.91,0Zm-90,50c-0,-3.311 -2.689,-6 -6,-6l-48,0c-3.312,0 -6,2.689 -6,6c-0,3.311 2.688,6 6,6l48,0c3.311,0 6,-2.689 6,-6Zm90,-22c-0,-3.311 -2.689,-6 -6,-6l-138,0c-3.312,0 -6,2.689 -6,6c-0,3.311 2.688,6 6,6l138,0c3.311,0 6,-2.689 6,-6Zm-0,-118c11.038,0 20,8.962 20,20l-0,40c-0,11.038 -8.962,20 -20,20l-150,0c-11.038,0 -20,-8.962 -20,-20l-0,-20c-0,0 -10.619,-21.239 -16.382,-32.764c-0.775,-1.55 -0.692,-3.391 0.219,-4.865c0.911,-1.474 2.52,-2.371 4.253,-2.371l181.91,0Zm-60,50c-0,-3.311 -2.689,-6 -6,-6l-78,0c-3.312,0 -6,2.689 -6,6c-0,3.311 2.688,6 6,6l78,0c3.311,0 6,-2.689 6,-6Zm60,-22c-0,-3.311 -2.689,-6 -6,-6l-138,0c-3.312,0 -6,2.689 -6,6c-0,3.311 2.688,6 6,6l138,0c3.311,0 6,-2.689 6,-6Z" style={{fill: '#fdefdc'}}/>
            </g>
        </SvgIcon>
    );

    return tooltip ? <Tooltip title={translations.rcs_messenger}>{icon}</Tooltip> : icon;
};

export default LiveChatIcon;
