import {SvgIcon} from '@material-ui/core';
import {FC} from "react";
import {MessengerIconProps} from "../MessengerIcon";

export const LineIcon: FC<Omit<MessengerIconProps, 'messenger'>> = props => {
    const {tooltip, ...rest} = props;

    return (
        <SvgIcon {...rest} viewBox='0 0 48 48' preserveAspectRatio='xMinYMin meet'>
            <path
                fill={'#06c755'}
                d='M24 48c13.3 0 24-10.7 24-24S37.3 0 24 0 0 10.7 0 24s10.7 24 24 24z'
            />
            <path
                fill='#fff'
                d='M38.2 22c0-6.4-6.4-11.5-14.2-11.5-7.8 0-14.2 5.2-14.2 11.5 0 5.7 5.1 10.5 11.9 11.4.5.1 1.1.3 1.3.7.1.4.1.9 0 1.3 0 0-.2 1-.2 1.2-.1.4-.3 1.4 1.2.8 1.5-.6 8.2-4.8 11.2-8.3 2-2.3 3-4.6 3-7.1z'
            />
            <path
                d='M33.5 25.7h-4c-.1 0-.3-.1-.3-.3v-6.2c0-.1.1-.3.3-.3h4c.1 0 .3.1.3.3v1c0 .1-.1.3-.3.3h-2.7v1h2.7c.1 0 .3.1.3.3v1c0 .1-.1.3-.3.3h-2.7v1h2.7c.1 0 .3.1.3.3v1c0 .1-.2.3-.3.3zm-14.8 0c.1 0 .3-.1.3-.3v-1c0-.1-.1-.3-.3-.3H16v-4.9c0-.1-.1-.3-.3-.3h-1c-.1 0-.3.1-.3.3v6.2c0 .1.1.3.3.3h4zm2.4-6.8h-1c-.1 0-.3.1-.3.3v6.2c0 .1.1.3.3.3h1c.1 0 .3-.1.3-.3v-6.2c0-.2-.1-.3-.3-.3zm6.9 0h-1c-.1 0-.3.1-.3.3v3.7L23.9 19h-1c-.1 0-.3.1-.3.3v6.2c0 .1.1.3.3.3h1c.1 0 .3-.1.3-.3v-3.7l2.8 3.8s0 .1.1.1h1.1c.1 0 .3-.1.3-.3v-6.2c-.2-.2-.4-.3-.5-.3z'
                fill={'#06c755'}
            />
        </SvgIcon>
    );
};

