import {FC, ReactNode, useEffect} from 'react';
import Box from '@material-ui/core/Box';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import translations from '../../providers/TranslationProvider/translations';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500,
            fontSize: 30,
        },
        text: {
            textAlign: 'center',
        },
    }),
);

interface IContentEmptyMessageProps {
    message?: string | null | {};
    children?: ReactNode;
    redirect?: string;
}

const ContentEmptyMessage: FC<IContentEmptyMessageProps> = ({children, message, redirect}) => {
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        if (redirect) {
            history.replace(redirect);
        }
    }, [history, redirect]);

    return (
        <Box className={classes.root} color="text.secondary">
            {children || (
                <Typography variant="h6" className={classes.text}>
                    {message || translations.nothing_to_show}
                </Typography>
            )}
        </Box>
    );
};

export default ContentEmptyMessage;
