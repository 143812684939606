import {FC} from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import {StandardTextFieldProps} from '@material-ui/core/TextField';

import CustomTextField from '../CustomTextField/CustomTextField';

const CustomTimePicker: FC<StandardTextFieldProps> = props => (
    <CustomTextField
        type="time"
        InputLabelProps={{shrink: true}}
        InputProps={{startAdornment: <InputAdornment position="start">h</InputAdornment>}}
        {...props}
    />
);

export default CustomTimePicker;
