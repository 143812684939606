import {createSelector} from 'reselect';

import {IEntityById} from '../../definitions/generic/generic.definitions';
import {ITextModule} from '../../definitions/textModule/textModule.definitions';
import {ETextModuleStatus} from '../../enums/textModule/ETextModulesStatus';
import {IState} from '../root.reducer';

const getTextModules = (state: IState) => state.entities.textModules.byId;
const getStatus = (state: IState, status: ETextModuleStatus) => status;
const getHideModulesWithAttachment = (state: IState, _: any, hideModulesWithMedia = false) => hideModulesWithMedia;

const _getTextModules = (allTextModules: IEntityById<ITextModule>, status: ETextModuleStatus, hideModulesWithMedia: boolean) => (
    Object.values(allTextModules)
        .filter(textModule => textModule.status === status)
        // optionally hide modules that contain attachments
        .filter(textModule => !hideModulesWithMedia || !textModule.attachment)
        .sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }

            return 0;
        })
);

export const getTextModulesByStatus = createSelector([
    getTextModules,
    getStatus,
    getHideModulesWithAttachment,
], _getTextModules);
