export enum EChartChatKpi {
    Out = 'out',
    BotOut = 'bot_out',
    AllOut = 'all_out',
    In = 'in',
    AllIn = 'all_in',
}

export type TChartChatKpiOut = EChartChatKpi.Out | EChartChatKpi.BotOut | EChartChatKpi.AllOut;
export type TChartChatKpiIn = EChartChatKpi.AllIn;
export type TChartKpiAll = EChartChatKpi.Out | EChartChatKpi.BotOut | EChartChatKpi.AllOut | EChartChatKpi.AllIn;
