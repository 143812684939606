import {FC} from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import {useHistory} from 'react-router';

import TicketAvatar from '../../../../components/TicketAvatar/TicketAvatar';
import IconFactory from '../../../../components/IconFactory/IconFactory';
import {ITicket} from '../../../../definitions/tickets/tickets.definitions';
import {EIcons} from '../../../../enums/icons/EIcons';
import {idToPhone} from '../../../../utils/global/global.utils';
import {getTicketRoute} from '../../../../utils/routes/routes.utils';
import {AlertLevel, getMessageTimesAndAlertStatus, isTicketAnswered} from '../../../../utils/ticket/ticket.utils';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        avatar: {
            width: 16,
            height: 16,
        },
        initalsAvatar: {
            background: theme.palette.common.white,
            border: '1px solid',
            borderColor: theme.palette.background.default,
            width: 16,
            height: 16,
        },
        listContentContainer: {
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        chipLabelName: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginLeft: theme.spacing(2),
        },
        chipLabelContext: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        flexContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        menuItem: {},
        menuItem_active: {
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:hover': {
                color: theme.palette.text.primary,
            },
        },
        [AlertLevel.default]: {
            color: theme.palette.text.secondary,
        },
        [AlertLevel.light]: {
            color: theme.palette.error.main,
        },
        [AlertLevel.strong]: {
            color: theme.palette.error.main,
        },
    }),
);

interface ITicketListItemProps {
    ticket: ITicket;
    index: number;
    overdueTime: number;
    criticalTime: number;
    isActive: boolean;
}

const TicketListItem: FC<ITicketListItemProps> = props => {
    const {ticket, index, overdueTime, criticalTime, isActive} = props;

    const history = useHistory();
    const classes = useStyles();

    if (!ticket) {
        return null;
    }

    const contactName = ticket.name ? ticket.name : idToPhone(ticket.user_id);
    // time and sorting
    const timingAndAlert = getMessageTimesAndAlertStatus(ticket, overdueTime, criticalTime);
    const alertLevelClass = classes[timingAndAlert.alertLevel];

    const handleClick = () => {
        history.push(getTicketRoute(ticket.id));
    };

    return (
        <MenuItem
            key={index}
            onClick={handleClick}
            className={classNames(classes.menuItem, isActive && classes.menuItem_active)}
        >
            <div className={classes.listContentContainer}>
                <div className={classes.flexContainer}>
                    <TicketAvatar ticket={ticket} classes={classes} />
                    <Typography className={classes.chipLabelName} variant="body2" component="div" color="inherit">
                        {contactName}
                    </Typography>
                </div>
                {isTicketAnswered(ticket) ? (
                    IconFactory.getIconWithProps(EIcons.REPLY)
                ) : (
                    <Typography
                        className={classNames(classes.chipLabelContext, !isActive && alertLevelClass)}
                        variant="body2"
                        component="div"
                        color="inherit"
                        children={timingAndAlert.fromNow}
                    />
                )}
            </div>
        </MenuItem>
    );
};

export default TicketListItem;
