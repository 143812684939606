import {AnyAction} from 'redux';
import {ELogoutReasons} from '../../definitions/security/security.definitions';
import {
    isActorDeviceLoginMessage,
    isActorDeviceLogoutMessage,
    isActorLogoutMessage,
} from '../../utils/actor/actor.utils';
import {enableSSOInStorage, getTokenFromStorage} from '../../utils/storage/storage.utils';
import {IState} from '../root.reducer';
import {ISecurityState} from "./security.reducer";
import {isSSOEnabled} from "../ui/ui.selectors";
import {ACTOR_LOGIN_REJECTED, ACTOR_LOGOUT_FULFILLED, ACTOR_LOGOUT_REJECTED} from "../actor/actor.types";
import {UPDATE_STREAM} from "../connection/connection.types";
import {isTypeRejected} from "../utils";
import {getErrorFromPayload} from "../notifications/notifications.utils";
import {getTimestampFromDeviceId} from "@sinch-engage/mcp-update-stream";
import {DEVICE_ID_CHANNEL_CHANGED_ACTION} from "../../utils/hooks/useSecurityCheck";

export const needsToLogout = (action: AnyAction, state?: IState): undefined | ISecurityState => {
    if (!state?.actor) {
        return;
    }

    const {type, payload} = action;

    if (type === ACTOR_LOGOUT_FULFILLED || type === ACTOR_LOGOUT_REJECTED) {
        return {
            resetState: true,
        };
    }

    if (type === ACTOR_LOGIN_REJECTED) {
        enableSSOInStorage();
    }

    if (isTypeRejected(type) && getErrorFromPayload(payload) === 'The CRM login expired') {
        // eslint-disable-next-line no-console
        console.debug("CRM login expired");
        return {
            logout: true,
            logoutReason: ELogoutReasons.Idle,
        };
    }

    if (!state.actor.success) {
        return;
    }

    const storedToken = getTokenFromStorage();
    const stateDeviceId = state.connection.deviceId;

    if (type === DEVICE_ID_CHANNEL_CHANGED_ACTION) {
        console.info('Session changed in another tab. Reload to use same session...');

        return {
            reload: true,
        };
    }

    if (type === UPDATE_STREAM) {
        const messagePayload = payload.message.payload;
        const messageDeviceId = payload.message.agent_device_id || messagePayload.deviceId;
        const isDeleteLogin = isActorLogoutMessage(payload.message) && messageDeviceId !== stateDeviceId;

        // logout if the same user logs out on any device or browser
        if (isDeleteLogin || isActorDeviceLogoutMessage(payload.message)) {
            console.info('Logged out on another device/tab.');

            return {
                logout: true,
                logoutReason: ELogoutReasons.LoggedOut,
            };
        }

        // handle user login update stream
        if (isActorDeviceLoginMessage(payload.message)) {
            const sameDevice = stateDeviceId?.startsWith(`${getTimestampFromDeviceId(messageDeviceId)}_`);
            const differentChannel = sameDevice && messageDeviceId !== stateDeviceId;

            // reload page if we have a token and its the same device (also if from another user or channel)
            if (differentChannel) {
                console.info('Channel changed in another tab. Reload to use same session...');

                return {
                    reload: true,
                };
            }

            if (!sameDevice) {
                console.info('Account used on another device or browser. Logout...');

                return {
                    logout: true,
                    logoutReason: ELogoutReasons.LoggedInOnAnotherDevice,
                };
            }
        }

        if (messageDeviceId && messageDeviceId !== stateDeviceId) {
            console.info('Channel changed in another tab or device. Reloading...');

            return {
                reload: true,
            };
        }
    }

    // if no sso enabled and the token is gone or expired, logout the user (because no auto update of the token occurs)
    if (!storedToken && !isSSOEnabled(state)) {
        console.info('Could not read token from storage. Session is gone...');

        return {
            logout: true,
            logoutReason: ELogoutReasons.NoTokenInStorage,
        };
    }

    return;
};
