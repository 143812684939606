import {FC, ReactNode} from 'react';

import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import classNames from 'classnames';
import translations from '../../../../providers/TranslationProvider/translations';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'absolute',
            bottom: theme.spacing(1),
            right: theme.spacing(1),
            zIndex: 900,
            transition: 'all 0.4s ease-in-out',
        },
        root_hidden: {
            visibility: 'hidden',
            opacity: 0,
            transition: 'all 0.1s ease-out',
        },
    }),
);

interface IScrollToBottomButtonProps {
    hidden: boolean;
    badgeContent: ReactNode;
    onClick: () => void;
}

const ScrollToBottomButton: FC<IScrollToBottomButtonProps> = props => {
    const {hidden, badgeContent, onClick} = props;
    const classes = useStyles();
    const rootClass = classNames(classes.root, {[classes.root_hidden]: hidden});

    return (
        <div className={rootClass} onClick={onClick}>
            <Badge badgeContent={badgeContent} color="primary" overlap="circular">
                <IconButton color="default" aria-label={translations.scroll_to_bottom}>
                    <VerticalAlignBottomIcon fontSize="large" />
                </IconButton>
            </Badge>
        </div>
    );
};

export default ScrollToBottomButton;
