import {
    validateEmail,
    validateRequired,
    validateRequiredEmail,
    validateRequiredStringLengthMin,
} from "../formValidations/formValidation.utils";
import {IAccountingState} from "../../definitions/accounting/accounting.definitions";

const validateVatBasedOnCountry = (country?: string, vat?: string) => {
    switch(country) {
        case 'BR' :
            return validateRequiredStringLengthMin(vat, 14);
        case 'MX':
            return validateRequiredStringLengthMin(vat, 12);
        default:
            return false;
    }
};

export const validateBillingInformation = (accounting: Partial<IAccountingState>) => ({
    company: validateRequired(accounting.company),
    vertical: validateRequired(accounting.vertical),
    employees: validateRequired(accounting.employees),
    firstname: validateRequiredStringLengthMin(accounting.firstname, 1),
    lastname: validateRequiredStringLengthMin(accounting.lastname, 1),
    addressline1: validateRequired(accounting.addressline1),
    zip: validateRequiredStringLengthMin(accounting.zip, 1),
    city: validateRequired(accounting.city),
    invoice_email: validateRequiredEmail(accounting.invoice_email),
    invoice_email2: validateEmail(accounting.invoice_email2),
    country: validateRequired(accounting.country),
    vat: validateVatBasedOnCountry(accounting.country, accounting.vat),
    regime_fiscal: accounting.country === 'MX' && validateRequired(accounting.regime_fiscal),
});
