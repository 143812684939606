import {request} from './Api/Api';
import {BEACON_API_URL} from "../config";

const MESSENGER_URL = 'messenger';
const MESSENGER_WB_URL = `${MESSENGER_URL}/wb`;
const MESSENGER_MP_API = `${MESSENGER_URL}/mpapi`;
const MESSENGER_USER = `${MESSENGER_URL}/user`;
const MESSENGER_MP_API_CODE = `${MESSENGER_URL}/mpapi/code`;
const MESSENGER_WB_SETUP_URL = `${MESSENGER_WB_URL}/setup`;
const MESSENGER_WB_PHONES_SETUP_URL = `${MESSENGER_URL}/whatsapp/setup`;
const MESSENGER_WB_PHONES_START_URL = `${MESSENGER_URL}/whatsapp/start`;
const MESSENGER_SETUP_TOKEN_URL = `${MESSENGER_URL}/setup_token`;
const MESSENGER_LC_SETUP_URL = `${MESSENGER_URL}/lc/setup`;


export const postWhatsAppBusinessMessengerSetupRequest = (formData: any) =>
    request({method: 'POST', url: MESSENGER_WB_PHONES_SETUP_URL},
        {"token": formData.accessToken, "granted_scopes": formData.grantedScopes});

export const postWhatsAppBusinessMessengerStartRequest = (formData: any) =>
    request({method: 'POST', url: MESSENGER_WB_PHONES_START_URL},
        {"number": formData});

export const getWhatsAppBusinessSetupStatusFetchRequest = () => request({method: 'GET', url: MESSENGER_WB_SETUP_URL});

export const getMessengerSetupTokenFetchRequest = (messenger: String) =>
    request(
        {
            method: 'GET',
            url: MESSENGER_SETUP_TOKEN_URL,
        },
        {messenger},
    );

export const getMessengersRequest = () => request({method: 'GET', url: MESSENGER_URL});

export const GetWhatsAppBusinessParameters = Object.freeze({
    TEST_NUMBERS: 'test_numbers',
    PROFILE: 'profile',
    CODE: 'code',
    FIELDS: 'fields',
});

export const getWhatsAppBusinessTestPhaseNumberRequest = () => request({method: 'GET', url: MESSENGER_WB_URL});

export const putWhatsAppBusinessRequest = (formData: any) => request({method: 'PUT', url: MESSENGER_WB_URL}, formData);

export const putMpApiCodeRequest = (code: any) => request({method: 'PUT', url: MESSENGER_MP_API_CODE}, {code});

export const deleteMessengerUserRequest = (messenger: string) =>
    request({method: 'DELETE', url: MESSENGER_USER}, {messenger});

export const deleteMpApiRequest = (messenger: string) =>
    request({method: 'DELETE', url: MESSENGER_MP_API}, {messenger});

export const postMpApiRequest = (uuid: string) => request({method: 'POST', url: MESSENGER_MP_API}, {uuid});

export const getLiveChatSetupRequest = () => request({method: 'GET', url: MESSENGER_LC_SETUP_URL});

export const getLiveChatConversationRequest = (channelId: string, userId: string, conversationId?: string) => request({
    method: 'GET',
    url: `${BEACON_API_URL}/conversation/${channelId}/${userId}/${conversationId}`,
});
