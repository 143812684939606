import {Tooltip} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import BarChartIcon from '@material-ui/icons/BarChart';
import SettingsIcon from '@material-ui/icons/Settings';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import createStyles from '@material-ui/styles/createStyles';
import React, {FC, useState} from 'react';
import {IPeriodData} from '../../definitions/statistics/statistics.definitions';
import {EChartInterval} from '../../enums/chart/EChartInterval';
import {EChartPeriod} from '../../enums/chart/EChartPeriod';
import {EChartType} from '../../enums/chart/EChartType';
import EditPeriod from '../../pages/StatisticsPage/StatisticsPageDialogs/EditPeriod';
import FormSection from '../FormSection/FormSection';
import translations from '../../providers/TranslationProvider/translations';

interface Props {
    periodData: IPeriodData;
    onChangePeriod: (period: IPeriodData) => void;
    onChangeInterval?: (event: any, value: EChartInterval) => void;
    onChangeChartType?: (event: any, value: EChartInterval) => void;
    onChangeAccumulated?: (event: any, value: EChartInterval) => void;
    interval?: EChartInterval;
    chartType?: EChartType;
    accumulated?: boolean;
    forbiddenIntervals?: EChartInterval[];
    minPeriodDate?: number;
    forbiddenChartPeriods?: EChartPeriod[];
}

const useStyles = makeStyles(() =>
    createStyles({
        toggleButtonGroup: {
            display: 'flex',

            '& > *': {
                width: '100%',
            },
        },
    }),
);

const EditChart: FC<Props> = props => {
    const {
        periodData,
        onChangePeriod,
        onChangeInterval,
        onChangeAccumulated,
        onChangeChartType,
        interval = EChartInterval.Days,
        chartType = EChartType.Bar,
        accumulated = false,
        forbiddenIntervals,
        minPeriodDate,
        forbiddenChartPeriods,
    } = props;

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);

    return (
        <>
            <Tooltip title={translations.chart_settings}>
                <IconButton
                    aria-label={translations.chart_settings}
                    onClick={event => setAnchorEl(event.target as HTMLAnchorElement)}>
                    <SettingsIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Popover
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <Box maxWidth={360} minWidth={260} p={2}>
                    <EditPeriod
                        period={periodData.period}
                        minDate={minPeriodDate}
                        fromDate={periodData.from}
                        toDate={periodData.to}
                        handleUpdatePeriod={onChangePeriod}
                        handleClose={() => setAnchorEl(null)}
                        forbiddenChartPeriods={forbiddenChartPeriods}
                    />
                    {onChangeChartType && (
                        <FormSection headline={translations.view}>
                            <ToggleButtonGroup
                                className={classes.toggleButtonGroup}
                                value={chartType}
                                exclusive
                                onChange={onChangeChartType}
                                size={'small'}
                            >
                                <ToggleButton value={EChartType.Area}>
                                    <ShowChartIcon />
                                </ToggleButton>
                                <ToggleButton value={EChartType.Bar}>
                                    <BarChartIcon />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </FormSection>
                    )}
                    {onChangeAccumulated && (
                        <FormSection headline={translations.values}>
                            <ToggleButtonGroup
                                className={classes.toggleButtonGroup}
                                value={accumulated}
                                exclusive
                                onChange={onChangeAccumulated}
                                size={'small'}
                            >
                                <ToggleButton value={false}>{translations.single}</ToggleButton>
                                <ToggleButton value={true}>{translations.accumulated}</ToggleButton>
                            </ToggleButtonGroup>
                        </FormSection>
                    )}
                    {onChangeInterval && (
                        <FormSection headline={translations.interval}>
                            <ToggleButtonGroup
                                className={classes.toggleButtonGroup}
                                value={interval}
                                exclusive
                                onChange={onChangeInterval}
                                size={'small'}
                            >
                                {[EChartInterval.Hours, EChartInterval.Days, EChartInterval.Weeks, EChartInterval.Months]
                                    .filter(option => !forbiddenIntervals?.includes(option))
                                    .map(option => (
                                        <ToggleButton key={option} value={option}>
                                            {translations[option]}
                                        </ToggleButton>
                                    ))}
                            </ToggleButtonGroup>
                        </FormSection>
                    )}
                </Box>
            </Popover>
        </>
    );
};

export default EditChart;
