import {FC, useState} from 'react';

import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import CustomIconButton from '../../../../components/CustomIconButton/CustomIconButton';
import translations from '../../../../providers/TranslationProvider/translations';
import {ITicket} from '../../../../definitions/tickets/tickets.definitions';
import {EIcons} from '../../../../enums/icons/EIcons';
import TicketListItem from './TicketListItem';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ticketListButton: {
            marginLeft: 'auto',
            marginRight: -6,
        },
        badge: {
            width: 'auto',
            minWidth: 14,
            height: 14,
            padding: '0 3px',
            fontSize: 8,
            marginRight: 0,
        },
        header: {
            marginLeft: theme.spacing(2),
        },
        divider: {
            marginBottom: theme.spacing(1),
        },
    }),
);

const ITEM_HEIGHT = 88;
const ARIA_LABEL = 'ticketListMenu';

interface ITicketPreviewProps {
    ticketsForPreview: ITicket[];
    criticalTime: number;
    overdueTime: number;
    activeTicketId: number;
}

const TicketPreview: FC<ITicketPreviewProps> = props => {
    const {ticketsForPreview, criticalTime, overdueTime, activeTicketId} = props;
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div className={classes.ticketListButton}>
                <Badge
                    badgeContent={ticketsForPreview.length}
                    classes={{badge: classes.badge}}
                    overlap={"rectangular"}
                >
                    <CustomIconButton
                        aria-owns={anchorEl ? ARIA_LABEL : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                        icon={EIcons.QUEUE}
                        disabled={!ticketsForPreview.length}
                        iconProps={{
                            fontSize: 'small',
                        }}
                    />
                </Badge>
            </div>
            <Menu
                id={ARIA_LABEL}
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4,
                        width: 300,
                    },
                }}
            >
                <Typography variant="body2" gutterBottom className={classes.header}>
                    {translations.additional_tickets}
                </Typography>
                <Divider className={classes.divider} />
                {Boolean(ticketsForPreview?.length) &&
                    ticketsForPreview.map((ticket, index) => (
                        <TicketListItem
                            key={ticket.id}
                            ticket={ticket}
                            index={index}
                            overdueTime={overdueTime}
                            criticalTime={criticalTime}
                            isActive={activeTicketId === ticket.id}
                        />
                    ))}
            </Menu>
        </>
    );
};

export default TicketPreview;
