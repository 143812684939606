import {ITextModule, ITextModuleGetRequestParams} from '../../definitions/textModule/textModule.definitions';
import {
    deleteTextModuleRequest,
    getTextModulesRequest,
    postTextModuleRequest,
    putTextModuleRequest,
} from '../../requests/botRequests';
import {TEXT_MODULE_DELETE, TEXT_MODULE_POST, TEXT_MODULE_PUT, TEXT_MODULES_GET} from "./textModule.types";


export const getTextModules = (params?: ITextModuleGetRequestParams) => ({
    type: TEXT_MODULES_GET,
    payload: getTextModulesRequest(params),
});

export const postTextModule = (textModule: ITextModule) => ({
    type: TEXT_MODULE_POST,
    payload: postTextModuleRequest(textModule),
});

export const putTextModule = (textModule: ITextModule) => ({
    type: TEXT_MODULE_PUT,
    payload: putTextModuleRequest(textModule),
});

export const deleteTextModule = (textModule: ITextModule) => ({
    type: TEXT_MODULE_DELETE,
    payload: deleteTextModuleRequest(textModule),
});
