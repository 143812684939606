// noinspection JSUnusedGlobalSymbols

import {FACEBOOK_GRAPH_API_V3, FACEBOOK_GRAPH_API_V5} from '../config';
import {
    ISetPageParameters,
    TFacebookGetStartedText,
} from '../definitions/settings/facebook/facebook.actions.definitions';
import {request} from './Api/Api';

const CHANNEL_FACEBOOK_URL = 'channel/facebook';

export const getFacebookPageRequest = () => request({method: 'GET', url: CHANNEL_FACEBOOK_URL});

export const setFacebookPageRequest = (parameters: ISetPageParameters) =>
    request({method: 'PUT', url: CHANNEL_FACEBOOK_URL}, parameters);

export const getFacebookPagesFromFacebookRequest = (accessToken: string) => {
    return fetch(`${FACEBOOK_GRAPH_API_V3}/me/accounts?access_token=${accessToken}`)
        .then(response => {
            return response.json();
        })
        .then(response => Promise.resolve(response));
};

export interface IFacebookError {
    message: string;
    type: string;
    code: number;
}

export const getFacebookActivePageDataRequest = (accessToken: string) => {
    return fetch(`${FACEBOOK_GRAPH_API_V5}/me/messenger_profile?fields=get_started&access_token=${accessToken}`)
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            throw response;
        })
        .catch(error => {
            if (error instanceof Error) {
                // failed to handle the response
                return {
                    error,
                };
            }
            // error message from API
            return error.json().then((responseJson: IFacebookError) => responseJson);
        });
};

export enum EFacebookParameters {
    getStarted = 'get_started',
}

export type TSetFacebookGetStartedData = {
    [EFacebookParameters.getStarted]: {
        payload: TFacebookGetStartedText;
    };
};

export interface IFacebookMessengerProfile {
    data?: Array<TSetFacebookGetStartedData | any>;
    error?: {
        message: string;
        type: string;
        code: number;
        fbtrace_id: string;
    };
}

export const setFacebookActivePageDataRequest = (accessToken: string, data: TSetFacebookGetStartedData) => {
    return fetch(`${FACEBOOK_GRAPH_API_V5}/me/messenger_profile?&access_token=${accessToken}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then(response => {
            return response.json();
        })
        .then(response => Promise.resolve(response));
};

export const deleteFacebookActivePageDataRequest = (accessToken: string, fields: string[]) => {
    return fetch(`${FACEBOOK_GRAPH_API_V5}/me/messenger_profile?&access_token=${accessToken}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'DELETE',
        body: JSON.stringify({fields: fields}),
    })
        .then(response => {
            return response.json();
        })
        .then(response => Promise.resolve(response));
};

export const getWhatsAppLoginData = () => request({url: 'messenger', method: 'GET'});
