import {FC} from 'react';

import Avatar from '@material-ui/core/Avatar';
import classNames from 'classnames';

import {getDefaultProfileImageUrl} from '../../config';
import {ITicket} from '../../definitions/tickets/tickets.definitions';
import {showNameOrNumber, toInitials} from '../../utils/global/global.utils';
import CustomAvatar from '../Avatar/Avatar';

interface ITicketAvatarProps {
    ticket: ITicket;
    classes: any;
    width?: number;
    height?: number;
    className?: string;
}

const TicketAvatar: FC<ITicketAvatarProps> = props => {
    const {ticket, classes, width, height} = props;

    const altText = ticket.name || '';

    if (ticket.profile_image === getDefaultProfileImageUrl()) {
        const avatarClasses = classNames(
            classes.initalsAvatar && classes.initalsAvatar,
            props.className && props.className,
        );

        return (
            <Avatar className={avatarClasses} alt={altText}>
                {toInitials(showNameOrNumber(ticket, false, ''))}
            </Avatar>
        );
    }

    const customAvatarClasses = classNames(
        classes.initalsAvatar && classes.initalsAvatar,
        props.className && props.className,
    );

    return (
        <CustomAvatar
            className={customAvatarClasses}
            src={ticket.profile_image}
            width={width}
            height={height}
            alt={altText}
        />
    );
};

export default TicketAvatar;
