import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import {
    getLiveChatSetupRequest,
    getMessengersRequest,
    getWhatsAppBusinessSetupStatusFetchRequest,
    getWhatsAppBusinessTestPhaseNumberRequest,
    postWhatsAppBusinessMessengerSetupRequest,
    postWhatsAppBusinessMessengerStartRequest,
    putWhatsAppBusinessRequest,
} from '../../../requests/messengerRequests';
import {IState} from '../../root.reducer';
import {
    SETTINGS_MESSENGER_GET,
    SETTINGS_MESSENGER_LC_GET_SETUP,
    SETTINGS_MESSENGER_WA_BUSINESS_SETUP_STATUS_POST,
    SETTINGS_MESSENGER_WHATSAPP_BUSINESS_GET_TESTPHASE_NUMBER,
    SETTINGS_MESSENGER_WHATSAPP_BUSINESS_PUT,
    SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_CLOSE_DIALOG,
    SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_GET,
    SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_OPEN_DIALOG,
    WHATSAPP_PHONE_NUMBER_FACEBOOK_SET_ERROR,
} from './messenger.types';
import {
    FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER,
    FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER_SAVED,
} from "../facebook/facebook.types";


export const openDialog = () => ({
    type: SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_OPEN_DIALOG,
});

export const closeDialog = () => ({
    type: SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_CLOSE_DIALOG,
});

export const getMessengers = (force = false) => (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => IState,
) => {
    const state = getState();
    const {messengers} = state.request;
    if (force || (!messengers.get && !messengers.loaded)) {
        return dispatch({
            type: SETTINGS_MESSENGER_GET,
            payload: getMessengersRequest(),
        });
    }
};

export const postWhatsAppBusinessMessengerSetup = (formData: any) => {
    return {
        type: SETTINGS_MESSENGER_WA_BUSINESS_SETUP_STATUS_POST,
        payload: postWhatsAppBusinessMessengerSetupRequest(formData),
    };
};

export const setSelectedNumber = (number: number) => ({
    type: FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER,
    payload: number,
});

export const postNumberSelected = (number: string) => ({
    type: FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER_SAVED,
    payload: postWhatsAppBusinessMessengerStartRequest(number),
});

export const getMessengerSetupStatus = () => ({
    type: SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_GET,
    payload: getWhatsAppBusinessSetupStatusFetchRequest(),
});

export const getWhatsAppBusinessTestPhaseNumbers = () => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
        const state = getState();
        const {whatsAppTestNumbers} = state.request;
        if (!whatsAppTestNumbers.get && !whatsAppTestNumbers.loaded) {
            return dispatch({
                type: SETTINGS_MESSENGER_WHATSAPP_BUSINESS_GET_TESTPHASE_NUMBER,
                payload: getWhatsAppBusinessTestPhaseNumberRequest(),
            });
        }
    };
};

export const WABusinessFacebookError = (error: { message: string; response: any }) => ({
    type: WHATSAPP_PHONE_NUMBER_FACEBOOK_SET_ERROR,
    payload: error,
});

export const saveWhatsAppBusinessAccount = (data: any) => ({
    type: SETTINGS_MESSENGER_WHATSAPP_BUSINESS_PUT,
    payload: putWhatsAppBusinessRequest({
        address: data.address,
        business_category: data.business_category,
        contact_email: data.contact_email,
        description: data.description,
        website: data.website,
        website2: data.website2,
    }),
});

export const getLiveChatSetup = () => ({
    type: SETTINGS_MESSENGER_LC_GET_SETUP,
    payload: getLiveChatSetupRequest(),
});
