import {Reducer} from 'redux';
import {SettingsShopState, ShopConfig, ShopConfigActions} from '../../../definitions/settings/shop/shop.definitions';
import {
    CREATE_SHOP_CONFIG_FULFILLED,
    DELETE_SHOP_CONFIG_FULFILLED,
    READ_SHOP_CONFIG_FULFILLED,
} from './settingsShop.types';

const initState: SettingsShopState = {
    shopTypes: {},
    shopConfig: null,
};

export const settingsShopReducer: Reducer<SettingsShopState, ShopConfigActions> = (state = initState, action) => {
    switch (action.type) {
        case READ_SHOP_CONFIG_FULFILLED: {
            const {shopTypes, shopConfig} = action.payload;

            return {
                ...state,
                shopTypes,
                shopConfig:
                    Array.isArray(shopConfig) && shopConfig.length === 0
                        ? state.shopConfig
                        : (shopConfig as ShopConfig),
            };
        }
        case CREATE_SHOP_CONFIG_FULFILLED:
            return {
                ...action.payload,
            };
        case DELETE_SHOP_CONFIG_FULFILLED: {
            const {meta} = action;

            if (!meta.shop) {
                return {...state, shopConfig: null};
            }

            const shops = state.shopConfig?.shops;

            if (shops && !Array.isArray(shops)) {
                const {[meta.shop]: _, ...newConfig} = shops;

                return {
                    ...state,
                    shopConfig: {
                        ...state.shopConfig,
                        shops: {...newConfig},
                    },
                };
            }

            return state;
        }
        default:
            return state;
    }
};
