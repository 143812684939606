import {FC, ReactElement, ReactNode} from "react";
import {Jss} from 'jss';
import {CssBaseline} from "@material-ui/core";
import ThemeProvider from "../ThemeProvider/ThemeProvider";
import TranslationProvider from "../TranslationProvider/TranslationProvider";
import useGlobalData, {IUseGlobalDataProps} from "../../utils/hooks/useGlobalData";
import UpdateStreamProvider from "../UpdateStreamProvider/UpdateStreamProvider";
import UpdateStreamConsumer from "../../components/UpdateStreamConsumer/UpdateStreamConsumer";
import {
    setBaseUrl,
    setBeaconApiUrl,
    setBotBuilderUrl,
    setCDNUrl,
    setContactUrl,
    setFAQsUrl,
    setImageServerUrl,
    setImprintUrl,
    setSupportEmail,
    setTACUrl,
    setTACUrlBrazil,
    setTACUrlMexico,
    setWebsocketUrl,
    WEBSOCKET_URL,
} from "../../config";

interface AppWrapperChildProps {
    isGlobalDataLoaded: boolean;
}

interface AppWrapperProps {
    initialDataOptIns?: IUseGlobalDataProps;
    children: ((props: AppWrapperChildProps) => ReactNode);
    loadingContent?: ReactElement; // optionally content to show while loading the language
    jss?: Jss;
    translationModule?: string;
    useUpdateStreamOverMessageBus?: boolean;
    enableUpdateStreamPerformanceCheck?: boolean;

    // below are all process.env overrides:
    apiBaseUrl?: string;
    updateStreamServer?: string;
    cdnUrl?: string;
    botBuilderUrl?: string;
    beaconApiUrl?: string;
    imageServerUrl?: string;
    supportEmail?: string;
    contactUrl?: string;
    faqsUrl?: string;
    imprintUrl?: string;
    termsUrl?: string;
    termsUrlBrazil?: string;
    termsUrlMexico?: string;
}

const AppWrapper: FC<AppWrapperProps> = (
    {
        children,
        initialDataOptIns,
        loadingContent,
        jss,
        translationModule,
        useUpdateStreamOverMessageBus = true,
        enableUpdateStreamPerformanceCheck,
        apiBaseUrl,
        updateStreamServer,
        cdnUrl,
        botBuilderUrl,
        beaconApiUrl,
        imageServerUrl,
        supportEmail,
        contactUrl,
        faqsUrl,
        imprintUrl,
        termsUrl,
        termsUrlBrazil,
        termsUrlMexico,
    }
) => {
    // Fetch all necessary data
    const isGlobalDataLoaded = useGlobalData(initialDataOptIns);

    // override config settings if provided
    if (apiBaseUrl) setBaseUrl(apiBaseUrl);
    if (updateStreamServer) setWebsocketUrl(updateStreamServer);
    if (cdnUrl) setCDNUrl(cdnUrl);
    if (botBuilderUrl) setBotBuilderUrl(botBuilderUrl);
    if (beaconApiUrl) setBeaconApiUrl(beaconApiUrl);
    if (imageServerUrl) setImageServerUrl(imageServerUrl);
    if (supportEmail) setSupportEmail(supportEmail);
    if (contactUrl) setContactUrl(contactUrl);
    if (faqsUrl) setFAQsUrl(faqsUrl);
    if (imprintUrl) setImprintUrl(imprintUrl);
    if (termsUrl) setTACUrl(termsUrl);
    if (termsUrlBrazil) setTACUrlBrazil(termsUrlBrazil);
    if (termsUrlMexico) setTACUrlMexico(termsUrlMexico);

    return (
        <>
            {useUpdateStreamOverMessageBus ? (
                <UpdateStreamConsumer enablePerformanceCheck={enableUpdateStreamPerformanceCheck}/>
            ) : (
                <UpdateStreamProvider
                    socket={WEBSOCKET_URL}
                    enablePerformanceCheck={enableUpdateStreamPerformanceCheck}
                />
            )}
            <ThemeProvider jss={jss}>
                <CssBaseline/>
                <TranslationProvider loadingContent={loadingContent} module={translationModule}>
                    {children({isGlobalDataLoaded})}
                </TranslationProvider>
            </ThemeProvider>
        </>
    );
};

export default AppWrapper;
