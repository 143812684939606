// @ts-nocheck --> <Avatar /> compiler will confuse this custom avatar component and think it is the material UI Avatar component that is used as an alias (MiuAvatar) here
import {FC, ReactComponentElement} from 'react';

import Avatar from '@material-ui/core/Avatar';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

import IconFactory from '../../../../../components/IconFactory/IconFactory';
import Twemoji from '../../../../../components/Twemoji/Twemoji';
import {getDefaultProfileImageUrl} from '../../../../../config';
import {EAgentEmailTypes} from '../../../../../enums/api/EAgentEmailTypes';
import {EIcons} from '../../../../../enums/icons/EIcons';

export const useStyles = makeStyles(() =>
    createStyles({
        avatar: {
            height: 24,
            width: 24,
            borderRadius: 2,
            fontSize: 13,
        },
    }),
);

interface IChatAvatar {
    name: string | ReactComponentElement<any>;
    agent_email: string;
    agent_id?: number;
    bot: 0 | 1;
    image: string;
    outgoing?: boolean;
    is_newsletter?: boolean;
    channelImage: string;
    channelName: string;
    className?: string;
}

const ChatAvatar: FC<IChatAvatar> = ({
                                         name,
                                         agent_email,
                                         agent_id,
                                         bot,
                                         image,
                                         outgoing,
                                         is_newsletter,
                                         channelImage,
                                         channelName,
                                         className,
                                     }) => {
    const classes = useStyles();
    const combinedClassName = classNames(classes.avatar, className);

    // beware wary souls!
    // the item is not the ticket item but is something that gets constructed in chatList.utils.js
    // yes it should be refactored
    // no I can not do that right now
    if (is_newsletter) {
        return <Avatar className={combinedClassName}>{IconFactory.getIconWithProps(EIcons.MIA)}</Avatar>;
    }

    if (agent_email === EAgentEmailTypes.Api) {
        return (
            <Avatar
                className={combinedClassName}
                src={channelImage}
                name={<Twemoji>{channelName}</Twemoji>}
                width={24}
                height={24}
            />
        );
    }

    if (bot && outgoing) {
        return <Avatar className={combinedClassName}>{IconFactory.getIconWithProps(EIcons.CARD_BOTS)}</Avatar>;
    }

    // don't show actor's name if it was a hacked message (login via CRM)
    const isCrmUser = !agent_id && Boolean(agent_email);
    const serverDefaultsRemovedImage = image && image !== getDefaultProfileImageUrl() ? image : undefined;

    return (
        <>
            <Avatar
                className={combinedClassName}
                src={serverDefaultsRemovedImage}
                name={isCrmUser ? undefined : name}
                width={24}
                height={24}
            />
        </>
    );
};

export default ChatAvatar;
