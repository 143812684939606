import {ElementType, FC} from 'react';

import ListSubheader from '@material-ui/core/ListSubheader';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(1)}px`,
            marginBottom: theme.spacing(1),
            display: 'flex',
            backgroundColor: theme.palette.background.paper,
        },
        line: {
            flex: 1,
            height: 1,
            background: theme.palette.action.disabledBackground,
            marginLeft: theme.spacing(1),
        },
        line_before: {
            marginLeft: 0,
            marginRight: theme.spacing(1),
        },
        chatDays: {
            flex: 1,
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
        },
    }),
);

interface IDateSeparatorProps {
    dateText: string;
    className?: string;
    component?: ElementType;
}

const DateSeparator: FC<IDateSeparatorProps> = props => {
    const {component = 'li', dateText, className} = props;

    const classes = useStyles();

    return (
        <ListSubheader className={classNames(classes.root, className && className)} component={component}>
            <div className={classes.chatDays}>
                <span className={`${classes.line} ${classes.line_before}`} />
                <Typography variant='subtitle2'>{dateText}</Typography>
                <span className={classes.line} />
            </div>
        </ListSubheader>
    );
};

export default DateSeparator;
