export const customColors = {
    facebook: '#4267B2',

    messenger: {
        FB: '#0084FF',
        TG: '#0088CC',
        IN: '#0076BA',
        WN: '#0C7DBD',
        IM: '#51F06D',
        VI: '#7360f2',
        WA: '#25D366',
        WB: '#25D366',
        CC: '#AAAAAA',
        TC: '#EF6C00',
        LC: '#d62946',
        RC: '#fcb515',
        IG: '#FEBA33',
        LI: '#06c755',
    },
};
