import {Reducer} from 'redux';

import {IAccountingState, IPricingModel} from '../../definitions/accounting/accounting.definitions';
import {EUpdateStreamAction} from '../../enums/updateStream/EUpdateStreamAction';
import {EUpdateStreamMethod} from '../../enums/updateStream/EUpdateStreamMethod';
import {UPDATE_STREAM} from '../connection/connection.types';
import {
    ACCOUNT_COSTS_GET_FULFILLED,
    ACCOUNT_COSTS_GET_PENDING,
    ACCOUNT_COSTS_GET_REJECTED,
    ACCOUNT_GET_FULFILLED,
    ACCOUNT_GET_PAYMENT_SOURCES_FULFILLED,
    ACCOUNT_GET_PENDING,
    ACCOUNT_INFO_GET_FULFILLED,
    ACCOUNT_INFO_SET_FULFILLED,
    ACCOUNT_INFO_SET_REJECTED,
    ACCOUNT_PAYMENT_INFO_GET_FULFILLED,
    ACCOUNT_PRICING_INFO_GET_FULFILLED,
    ACCOUNT_PROJECT_PRICING_INFO_GET_FULFILLED,
    ACCOUNT_PURCHASE_FULFILLED,
    ACCOUNT_REMOVE_PAYMENT_SOURCE_FULFILLED,
    ACCOUNT_SET_DEFAULT_PAYMENT_SOURCE_FULFILLED,
    ACCOUNT_UPDATE_FULFILLED,
} from './accounting.types';
import {ACTOR_SET_MIN_PASSWORD_LENGTH} from "../actor/actor.types";
import {CHANNEL_GET_AGENTS_FULFILLED} from "../channel/channel.types";

export const accountingInitialState: IAccountingState = {
    costs: {
        nextInvoice: null,
        pricing_information: {
            loading: false,
        },
    },
    loading: false,
    invoice_period: {
        from: 0,
        to: 0,
    },
    two_factor_authentication: false,
    two_factor_channel: 0,

    // customer
    id: 0,
    gdpr_disabled: false,
    gdpr_needed: false,
    gdpr_accepted: false,
    booking_enabled: false,
    invoicing: false,
    wa_policies_needed: false,
    next_invoice: 0,
    minimum_password_length: 8,
    roles: [],
    email_verified: false,
};

export const accountingReducer: Reducer<IAccountingState> = (state = accountingInitialState, action) => {
    switch (action.type) {
        case UPDATE_STREAM: {
            const {message} = action.payload;

            if (
                message.action === EUpdateStreamAction.AccountingPricing &&
                message.method === EUpdateStreamMethod.Put
            ) {
                const {testphase_finished, ...newState} = state;
                return newState;
            }

            if (message.action === EUpdateStreamAction.EmailVerify) {
                return {
                    ...state,
                    email_verified: true,
                };
            }

            return state;
        }

        case ACTOR_SET_MIN_PASSWORD_LENGTH:
            return {
                ...state,
                minimum_password_length: action.payload,
            };

        case ACCOUNT_GET_FULFILLED:
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        case ACCOUNT_GET_PENDING: {
            return {
                ...state,
                loading: true,
            };
        }
        case ACCOUNT_COSTS_GET_PENDING:
            return {
                ...state,
                costs: {
                    pricing_information: {
                        loading: true,
                    },
                },
            };
        case ACCOUNT_COSTS_GET_REJECTED:
            return {
                ...state,
                costs: {
                    pricing_information: {
                        loading: false,
                    },
                },
            };
        case ACCOUNT_COSTS_GET_FULFILLED:
            action.payload.pricing_information.loading = false;
            return {
                ...state,
                costs: {
                    ...action.payload,
                },
            };
        case ACCOUNT_UPDATE_FULFILLED: {
            const updatedData = {...action.payload};
            delete updatedData.code;

            return {
                ...state,
                ...updatedData,
            };
        }
        case ACCOUNT_INFO_SET_REJECTED: {
            const {two_factor_authentication, two_factor_channel} = action.meta;
            return {
                ...state,
                two_factor_authentication,
                two_factor_channel,
            };
        }
        case ACCOUNT_INFO_SET_FULFILLED:
        case ACCOUNT_INFO_GET_FULFILLED: {
            return {
                ...state,
                ...action.payload,
            };
        }

        case CHANNEL_GET_AGENTS_FULFILLED: {
            return {
                ...state,
                roles: [...action.payload.roles],
            };
        }

        case ACCOUNT_PROJECT_PRICING_INFO_GET_FULFILLED: {
            return {
                ...state,
                pricing_information: {
                    ...state.pricing_information,
                    ...action.payload,
                },
            };
        }

        case ACCOUNT_PRICING_INFO_GET_FULFILLED: {
            // TODO: remove filtering when BackOffice API cleaned up
            const filtered = Object.keys(action.payload).reduce((result, key) => {
                if (!key.startsWith('payment_') && key !== 'setup_intent') {
                    return {
                        ...result,
                        [key]: action.payload[key],
                    };
                }
                return result;
            }, {} as any);

            return {
                ...state,
                pricing_information: {
                    ...state.pricing_information,
                    ...filtered,
                    // sort pricing models by base fee
                    pricing_models: filtered.pricing_models?.sort((pm1: IPricingModel, pm2: IPricingModel) => {
                        return pm1.pricing_information.prices.required[0].amount - pm2.pricing_information.prices.required[0].amount;
                    }) || [],
                },
            };
        }

        case ACCOUNT_PAYMENT_INFO_GET_FULFILLED: {
            return {
                ...state,
                payment_information: action.payload,
            };
        }

        case ACCOUNT_GET_PAYMENT_SOURCES_FULFILLED: {
            return {
                ...state,
                payment_information: {
                    ...state.payment_information,
                    payment_sources: action.payload,
                },
            };
        }

        case ACCOUNT_SET_DEFAULT_PAYMENT_SOURCE_FULFILLED: {
            return {
                ...state,
                payment_information: {
                    ...state.payment_information,
                    ...action.payload,
                },
            };
        }

        case ACCOUNT_REMOVE_PAYMENT_SOURCE_FULFILLED: {
            return {
                ...state,
                payment_information: {
                    ...state.payment_information,
                    payment_sources: state.payment_information?.payment_sources
                        ?.filter(ps => ps.id !== action.payload.payment_source_id),
                },
            };
        }

        case ACCOUNT_PURCHASE_FULFILLED: {
            setTimeout(() => window.location.reload(), 500);
            return state;
        }
    }

    return state;
};
