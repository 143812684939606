import {FC, memo} from 'react';

import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Field, reduxForm, submit} from 'redux-form';

import DialogTemplate from '../../../../components/DialogTemplate/DialogTemplate';
import FormRow from '../../../../components/FormRow/FormRow';
import {
    renderAutocompleteTextField,
    renderTextField,
    UploadField,
} from '../../../../components/ReduxFormInput/ReduxFormInput';
import translations from '../../../../providers/TranslationProvider/translations';
import {ITextModule} from '../../../../definitions/textModule/textModule.definitions';
import {ETextModuleStatus} from '../../../../enums/textModule/ETextModulesStatus';
import {IState} from '../../../../redux/root.reducer';
import {validateRequired} from '../../../../utils/formValidations/formValidation.utils';
import {getDialogActionsConfig} from '../../../../utils/reduxForms/reduxForm.utils';

interface ITextModuleInitialValues {
    id?: number;
    attachment?: string;
    attachment_filename?: number;
    message?: string;
    name?: string;
    status?: ETextModuleStatus;
}

interface ITextModulesDialogInnerProps {
    isOpen: boolean;
    textModule: ITextModule | {};
    onSubmit: (textModule?: ITextModule) => void;
    handleClose: () => void;
    submit: (form: string) => void;
    initialValues: ITextModuleInitialValues;
    pristine: boolean;
    dirty: boolean;
}

interface ITextModulesDialogOuterProps {
    isOpen: boolean;
    textModule: ITextModule | {};
    onSubmit: (textModule?: ITextModule) => void;
    handleClose: () => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        buttonContainer: {
            justifyContent: 'start',
            '& >div': {
                width: 'auto !important',
            },
        },
    }),
);

const TextModulesDialog: FC<ITextModulesDialogInnerProps> = props => {
    const {isOpen, submit, handleClose, initialValues} = props;
    const classes = useStyles();

    const isCreate = !(initialValues && initialValues.id);
    const isDraft = initialValues?.status === ETextModuleStatus.Draft;
    const isRejected = initialValues?.status === ETextModuleStatus.Rejected;

    const getDialogTitle = () => {
        if (isDraft || isRejected) {
            return translations.text_module_accept_title;
        }

        if (isCreate) {
            return translations.new_text_module;
        }

        return translations.edit_text_module;
    };

    const title = getDialogTitle();

    const actionsConfig = getDialogActionsConfig({
        props: {
            ...props,
            pristine: isDraft || isRejected ? false : props.pristine,
            dirty: isDraft || isRejected ? true : props.dirty,
        },
        onClose: handleClose,
        onSubmit: () => {
            handleClose();
            submit(FORM_NAME_CHATBLOCKS);
        },
        isCreate,
        submitLabel: isDraft || isRejected ? translations.text_module_accept : '',
    });

    return (
        <DialogTemplate
            open={isOpen}
            onClose={handleClose}
            ariaLabel="text-module"
            title={title}
            closable
            actionsConfig={actionsConfig}
            disableEscapeKeyDown // disabled because it interferes with autocomplete functionality
            disableEnforceFocus // this allows the Popper menu of the AutocompleteTextField to gain focus
            content={
                <>
                    <FormRow>
                        <Field name="name" component={renderTextField} label={translations.name} fullWidth autoFocus />
                    </FormRow>
                    <FormRow>
                        <Field
                            component={renderAutocompleteTextField}
                            name="message"
                            label={translations.text}
                            multiline
                            emoji
                        />
                    </FormRow>
                    <FormRow className={classes.buttonContainer}>
                        <UploadField form={FORM_NAME_CHATBLOCKS} name="attachment" />
                    </FormRow>
                </>
            }
        />
    );
};

export const FORM_NAME_CHATBLOCKS = 'textModules';

const validate = (values: ITextModuleInitialValues) => ({
    name: validateRequired(values.name),
    message: validateRequired(values.message),
});

const form = {
    form: FORM_NAME_CHATBLOCKS,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: true,
    updateUnregisteredFields: false,
    validate,
};

const mapStateToProps = (state: IState, {textModule}: ITextModulesDialogInnerProps) => ({
    initialValues: textModule,
});

const mapDispatchToProps = {
    submit,
};

export default compose<ITextModulesDialogInnerProps, ITextModulesDialogOuterProps>(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(form),
    memo,
)(TextModulesDialog);
