import {FC, memo, ReactElement, ReactNode} from 'react';


import Box from '@material-ui/core/Box';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: `${theme.spacing(3)}px 0`,

            '&:first-child': {
                marginTop: 0,
            },
            '&:last-child': {
                marginBottom: 0,
            },
        },
        headline: {
            '&:first-child': {
                marginTop: 0,
            },
        },
    }),
);

interface IContentSectionProps {
    headline?: string | ReactElement;
    children: ReactNode;
    titleGutter?: number;
    customClass?: any;
}

const ContentSection: FC<IContentSectionProps> = props => {
    const {children, headline, titleGutter = 2, customClass} = props;
    const classes = useStyles();

    return (
        <div className={classNames(classes.root, customClass && customClass)}>
            {!!headline && (
                <Box className={classes.headline} mt={1} mb={titleGutter}>
                    <Typography variant="body1" children={headline} />
                </Box>
            )}
            {children}
        </div>
    );
};

export default memo(ContentSection);
