
function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill="#272727"
                fillRule="evenodd"
                d="M7.077 34.1c.532.875 1.453 1.386 2.413 1.386.237 0 .475-.03.703-.088l4.332-1.132 11.524-2.833 7.857-2.017c.912-.236 1.653-.925 1.986-1.83a3.018 3.018 0 00-.333-2.726L25.403 9.777a2.81 2.81 0 00-2.299-1.26h-.038c-.902 0-1.767.443-2.3 1.201L6.555 29.74a3.024 3.024 0 00-.114 3.316l.637 1.043zm15.21-23.22a.952.952 0 01.77-.404h.019c.313.01.588.157.77.423L34.01 25.992c.19.265.228.6.114.905a.975.975 0 01-.665.61l-7.848 2.017-11.533 2.834-4.342 1.131a.926.926 0 01-1.036-.433l-.636-1.043a1.008 1.008 0 01.038-1.102L22.287 10.88z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#272727"
                d="M17.879 39.55c-1.32-.57-2.309-1.692-3.098-3.306l1.93-.472c.598 1.082 1.234 1.672 1.918 1.968.884.383 2.043.383 3.724-.02 1.416-.433 2.31-1.004 2.86-1.742.437-.59.722-1.387.78-2.509l1.909-.472c0 1.682-.361 3.07-1.188 4.182-.893 1.2-2.232 1.938-3.857 2.44-.01 0-.019.002-.029.005a.114.114 0 01-.028.005c-1.824.442-3.477.55-4.921-.08z"
            ></path>
            <path
                fill="#007171"
                d="M29.82 12.975l4.257-4.29c.38-.384.38-1.004.02-1.387a.924.924 0 00-1.34-.02l-4.038 4.073 1.102 1.624zM32.586 17.048l7.638-5.401c.437-.306.541-.915.247-1.368a.927.927 0 00-1.32-.256l-7.658 5.421 1.093 1.604zM40.48 19.242l-4.73 2.44-1.112-1.633 4.997-2.568c.465-.246 1.035-.04 1.273.443a1.002 1.002 0 01-.428 1.318z"
            ></path>
        </svg>
    );
}

export default Icon;
