import {Reducer} from 'redux';

import {ITextModule, ITextModulesState} from '../../definitions/textModule/textModule.definitions';
import {EUpdateStreamMethod} from '../../enums/updateStream/EUpdateStreamMethod';
import {isUpdateStream} from '../../utils/redux/redux.utils';
import {UPDATE_STREAM} from '../connection/connection.types';
import {TEXT_MODULES_GET_FULFILLED} from './textModule.types';
import {EUpdateStreamAction} from "../../enums/updateStream/EUpdateStreamAction";
import {IEntityById} from "../../definitions/generic/generic.definitions";

export const textModuleInitialState: ITextModulesState = {
    byId: {} as IEntityById<ITextModule>,
    allIds: [],
};

export const textModuleReducer: Reducer<ITextModulesState> = (state = textModuleInitialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case TEXT_MODULES_GET_FULFILLED: {
            const chatblocks = payload?.chatblocks;
            if (!chatblocks) {
                return state;
            }

            const newByIds = {
                ...state.byId,
                ...chatblocks,
            };

            return {
                byId: newByIds,
                allIds: Object.keys(newByIds).map(Number),
            };
        }

        case UPDATE_STREAM: {
            if (
                isUpdateStream(payload.message, EUpdateStreamAction.BotChatblock, [EUpdateStreamMethod.Post, EUpdateStreamMethod.Put])
            ) {
                const payloadTextModules = payload?.message?.payload;
                if (!payloadTextModules.id || !payloadTextModules.name) {
                    return state;
                }

                const newByIds = {
                    ...state.byId,
                    [payloadTextModules.id]: payloadTextModules,
                };

                return {
                    byId: newByIds,
                    allIds: Object.keys(newByIds).map(Number),
                };
            }

            if (isUpdateStream(payload.message, EUpdateStreamAction.BotChatblock, EUpdateStreamMethod.Delete)) {
                const {id} = payload?.message?.payload || {};
                if (!id) {
                    return state;
                }

                const {[id]: deleted, ...newById} = state.byId;

                return {
                    byId: newById,
                    allIds: Object.keys(newById).map(Number),
                };
            }
            return state;
        }
        default:
            return state;
    }
};
