import {FC, useCallback, useEffect} from 'react';

import Typography from '@material-ui/core/Typography';
import {useDispatch, useSelector} from 'react-redux';

import ContainedButton from '../../components/Buttons/ContainedButton';
import ContentSection from '../../components/ContentSection/ContentSection';
import LinkifyText from '../../components/LinkifyText/LinkifyText';
import PaperTemplate from '../../components/PaperTemplate/PaperTemplate';
import PaperTitleBar from '../../components/PaperTitleBar/PaperTitleBar';
import translations from '../../providers/TranslationProvider/translations';
import Twemoji from '../../components/Twemoji/Twemoji';
import WelcomeNotificationForm from '../../components/WelcomeNotificationForm/WelcomeNotificationForm';
import WidgetDirectLinks from '../../components/WidgetDirectLinks/WidgetDirectLinks';
import {IState} from '../../redux/root.reducer';
import {getFirstCardWidget, getFirstChipWidget} from '../../redux/settings/widgetEditor/widgetEditor.selectors';
import {getAllWidgets} from '../../redux/settings/widgetEditor/widgetEditor.actions';

interface IDashboardWhatsAppOnboardingProps {
    contentOnly?: boolean;
}

const DashboardWhatsAppOnboarding: FC<IDashboardWhatsAppOnboardingProps> = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllWidgets());
    }, [dispatch]);

    const channel = useSelector((state: IState) => state.channel);
    const cardWidget = useSelector(getFirstCardWidget);
    const chipWidget = useSelector(getFirstChipWidget);
    const hasWidget = Boolean(cardWidget || chipWidget);

    const handleTestOnboardingClick = useCallback(() => {
        const tid = channel.widget_hash.substring(channel.widget_hash.length - 10);
        const url = `https://app.messengerpeople.com/forward?number=4915792470000&message=TID:${tid}`;

        const onBoardingWindow = window.open(url, '_blank');
        if (onBoardingWindow) {
            onBoardingWindow.focus();
        }
    }, [channel.widget_hash]);

    // TODO looks hacky -> refactor to a component
    const renderContent = useCallback(
        () => (
            <>
                <Typography gutterBottom variant="body2">
                    <Twemoji>{translations.whats_app_business_onboarding_text_one}</Twemoji>
                </Typography>
                <Typography gutterBottom variant="body2" paragraph>
                    <LinkifyText emojify text={`${translations.whats_app_business_onboarding_text_two} `} />
                    <span style={{fontWeight: 500}}>+49 1579 2470000</span>
                    <LinkifyText emojify text={` ${translations.whats_app_business_onboarding_text_three}`} />
                </Typography>

                <WidgetDirectLinks gutterBottom />

                {!hasWidget && (
                    <Typography gutterBottom component="div" paragraph>
                        <Typography gutterBottom variant="body2">
                            <LinkifyText emojify text={translations.whats_app_business_onboarding_text_four} />
                        </Typography>
                        <Typography gutterBottom variant="subtitle1">
                            TID:{channel.widget_hash.substring(channel.widget_hash.length - 10)}
                        </Typography>
                        <ContainedButton
                            color="primary"
                            label={translations.open_whatsapp_test}
                            onClick={handleTestOnboardingClick}
                        />
                    </Typography>
                )}

                <WelcomeNotificationForm />
            </>
        ),
        [channel, handleTestOnboardingClick, hasWidget],
    );

    if (props.contentOnly) {
        return renderContent();
    }

    return (
        <PaperTemplate titleBar={<PaperTitleBar title={translations.whats_app_business_test_user} />} stretch>
            <ContentSection>{renderContent()}</ContentSection>
        </PaperTemplate>
    );
};

export default DashboardWhatsAppOnboarding;
