import {ChangeEvent, FC, Fragment, useCallback, useEffect} from 'react';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel, {FormControlLabelProps} from '@material-ui/core/FormControlLabel/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {Theme} from '@material-ui/core/styles/createTheme';
import withStyles from '@material-ui/core/styles/withStyles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {change, Field, focus, WrappedFieldInputProps} from 'redux-form';
import {BaseFieldProps, WrappedFieldMetaProps} from 'redux-form/lib/Field';

import {EMessenger} from '../../enums/messenger/EMessenger';
import {reduxFormResetFile, reduxFormUploadFile} from '../../redux/formUploader/formUploader.actions';
import {IState} from '../../redux/root.reducer';
import CustomAutocompleteTextField from '../CustomInputs/CustomAutocompleteTextField/CustomAutocompleteTextField';
import CustomAutoSelect from '../CustomInputs/CustomAutoSelect/CustomAutoSelect';
import CustomColorPicker from '../CustomInputs/CustomColorPicker/CustomColorPicker';
import CustomDatePicker from '../CustomInputs/CustomDatePicker/CustomDatePicker';
import CustomDateTimePicker from '../CustomInputs/CustomDateTimePicker/CustomDateTimePicker';
import CustomEmojiTextField from '../CustomInputs/CustomEmojiTextField/CustomEmojiTextField';
import CustomLocationPicker from '../CustomInputs/CustomLocationPicker/CustomLocationPicker';
import CustomSelect, {ICustomSelect} from '../CustomInputs/CustomSelect/CustomSelect';
import CustomTextField, {ICustomTextFieldProps} from '../CustomInputs/CustomTextField/CustomTextField';
import CustomTimePicker from '../CustomInputs/CustomTimePicker/CustomTimePicker';
import PhoneCountryCodeSelector from '../CustomInputs/PhoneCountryCodeSelector/PhoneCountryCodeSelector';
import PhoneNumberTextField from '../CustomInputs/PhoneNumberTextField/PhoneNumberTextField';
import MediaUploader from '../MediaUploader/MediaUploader';
import translations from '../../providers/TranslationProvider/translations';
import Twemoji from '../Twemoji/Twemoji';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TargetingSelector from "../TargetingSelector/TargetingSelector";

const checkboxStyle = (theme: Theme) => ({
    error: {
        color: theme.palette.error.main,
    },
    errorField: {
        marginTop: 0,
        marginBottom: 8,
    },
});

export const errorProps = (meta: WrappedFieldMetaProps, validateUntouched?: boolean) => {
    const {touched, error} = meta;
    if ((!touched && !validateUntouched) || !error) {
        return null;
    }

    return {
        error: true,
        helperText: error,
        FormHelperTextProps: {
            error: true,
        },
    };
};

interface IError {
    meta: Partial<WrappedFieldMetaProps>;
    children?: any;
    className?: string;
    showErrorUntouched?: boolean;
}

const Error: FC<IError> = ({meta, className, showErrorUntouched}) => {
    if (!meta.error) {
        return null;
    }

    if (!showErrorUntouched) {
        if (!meta.touched) {
            return null;
        }
    }

    return <FormHelperText error children={meta.error} className={className}/>;
};



export interface IRenderTextFieldProps extends ICustomTextFieldProps {
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
}

export const renderTextField = ({input, label, validateUntouched, ...custom}: IRenderTextFieldProps) => (
    <CustomTextField label={label} {...input} {...custom} {...errorProps(custom.meta, validateUntouched)} />
);

export const renderAutocompleteTextField = ({input, validateUntouched, ...custom}: IRenderTextFieldProps) => (
    <CustomAutocompleteTextField {...input} {...custom} {...errorProps(custom.meta, validateUntouched)} />
);

export const renderEmojiTextField = ({input, meta, validateUntouched, ...custom}: IRenderTextFieldProps) => (
    <CustomEmojiTextField meta={meta} {...input} {...custom} {...errorProps(meta)} />
);

export const renderNumberInput = ({input, label, meta, ...custom}: IRenderTextFieldProps) => (
    <TextField label={label} {...input} {...custom} {...errorProps(meta)} type="number"/>
);

export const renderLocationField = ({input, label, meta, ...custom}: IRenderTextFieldProps) => (
    <CustomLocationPicker label={label || ''} input={input} custom={custom} meta={meta} {...errorProps(meta)} />
);

export const renderDatePicker = ({input, label, meta, ...custom}: IRenderTextFieldProps) => (
    <CustomDatePicker label={label} {...input} {...custom} {...errorProps(meta)} />
);

export const renderTimePicker = ({input, label, meta, ...custom}: IRenderTextFieldProps) => (
    <CustomTimePicker label={label} {...input} {...custom} {...errorProps(meta)} />
);

export const renderDateTimePicker = ({input, label, meta, ...custom}: IRenderTextFieldProps) => (
    <CustomDateTimePicker onChange={input.onChange} value={input.value} {...custom} {...errorProps(meta)} />
);

interface IRenderSwitchProps extends FormControlLabelProps {
    input: WrappedFieldInputProps;
    color?: 'primary' | 'secondary' | 'default';
    meta: WrappedFieldMetaProps;
}

export const RenderSwitch: FC<IRenderSwitchProps> = ({input, label, color = 'primary', control, ...custom}) => {
    // Fix redux-form's lack of setting anyTouched and marking switches as active on change.
    // We need this for our ThrottleFormProvider to be able to auto-submit switch changes
    const handleChange = useCallback(
        (event: ChangeEvent<{}>, checked: boolean) => {
            custom.meta.dispatch(focus(custom.meta.form, input.name));
            input.onChange(event, checked ? '1' : '0'); // without stringify onChange gives a ts error
        },
        [custom.meta, input],
    );

    return (
        <FormControlLabel
            label={label}
            onChange={handleChange}
            checked={!!input.value}
            control={<Switch color={color}/>}
            {...custom}
        />
    );
};

export const renderEnabledDisabledSwitch = ({label, ...rest}: IRenderSwitchProps) => {
    const labelOverwrite = rest.input.value ? translations.enabled : translations.disabled;
    return RenderSwitch({label: labelOverwrite, ...rest});
};

interface IRenderSelectFieldProps extends ICustomSelect {
    meta: WrappedFieldMetaProps;
    key: string | number | undefined;
    validateUntouched?: boolean;
}

export const renderSelectField = ({input, label, meta, validateUntouched, ...custom}: IRenderSelectFieldProps) => (
    <CustomSelect label={label} {...input} {...custom} {...errorProps(meta, validateUntouched)} />
);

export const renderTargetingSelector = (
    {
        input,
        label,
        meta,
        validateUntouched,
        ...custom
    }: IRenderSelectFieldProps
) => (
    <TargetingSelector label={label} {...input} {...custom} {...errorProps(meta, validateUntouched)} />
);

interface IRenderCheckboxProps {
    classes: {
        error: string;
        errorField: string;
    };
    input: any;
    label: string;
    meta: WrappedFieldMetaProps;
    color?: 'primary' | 'secondary' | 'default';
    disabled?: boolean;
    fullWidth?: boolean;
}

export const renderCheckbox = withStyles(checkboxStyle)(
    ({classes, input, label, meta, color = 'primary', disabled, fullWidth = true}: IRenderCheckboxProps) => {
        // Fix redux-form's lack of setting anyTouched and marking checkboxes as active on change.
        // We need this for our ThrottleFormProvider to be able to auto-submit checkbox changes
        const handleChange = useCallback(
            (event: ChangeEvent<{}>, checked: boolean) => {
                meta.dispatch(focus(meta.form, input.name));
                input.onChange(event, checked);
            },
            [meta, input],
        );

        const {touched, error} = meta;
        const checkboxClassName = touched && error ? classes.error : '';

        return (
            <FormControl error={Boolean(error)} fullWidth={fullWidth}>
                <FormControlLabel
                    label={label}
                    onChange={handleChange}
                    checked={!!input.value}
                    disabled={!!disabled}
                    control={<Checkbox color={color} className={checkboxClassName}/>}
                />
                <Error meta={{touched, error}} className={classes.errorField}/>
            </FormControl>
        );
    },
);

const useCheckboxGroupStyles = makeStyles(createStyles({
    errorField: {
        marginTop: -6,
        marginBottom: 8,
    },
}));

interface IRenderCheckboxGroupProps {
    row?: boolean;
    commaSeparated: boolean;
    name: string;
    options: {
        label: string;
        value: any;
        disabled?: boolean;
    }[];
    input?: any;
    meta?: WrappedFieldMetaProps;
    classes?: {
        errorField: string;
    };
    forceChecked?: boolean;
    color?: 'primary' | 'secondary' | 'default';
    value?: any;
    onChange?: (value: any) => void;
}

interface CheckboxGroupProps {
    row?: boolean;
    commaSeparated: boolean;
    name: string;
    options: {
        label: string;
        value: any;
        disabled?: boolean;
    }[];
    meta?: WrappedFieldMetaProps;
    forceChecked?: boolean;
    color?: 'primary' | 'secondary' | 'default';
    value?: any;
    onChange: (value: any) => void;
    onBlur?: (event: any) => void;
    disabled?: boolean;
}

const getValuesArray = (values: string, commaSeparated?: boolean) => {
    if (commaSeparated) {
        return values ? values.trim().split(',') : [];
    }
    return [...values];
};

export const CheckboxGroup: FC<CheckboxGroupProps> = (
    {
        options,
        row = false,
        onBlur,
        color = 'primary',
        name,
        forceChecked = false,
        value,
        commaSeparated,
        onChange,
        disabled,
        meta,
    }
) => {
    let valuesArray = getValuesArray(value, commaSeparated);
    const classes = useCheckboxGroupStyles();

    return (
        <FormControl>
            <FormGroup row={row}>
                {options.map((option, index: number) => (
                    <FormControlLabel
                        key={option.label}
                        control={
                            <Checkbox
                                color={color}
                                onBlur={onBlur}
                            />
                        }
                        name={`${name}[${index}]`}
                        label={<Twemoji>{option.label}</Twemoji>}
                        value={option.value}
                        checked={forceChecked || valuesArray.includes(option.value)}
                        disabled={!!option.disabled || !!disabled}
                        onChange={(event, checked) => {
                            // Distinguish between commaSeparated values and arrays
                            // const values = getValuesArray(value || input.value);

                            // Add or remove item
                            if (checked) {
                                valuesArray = [...valuesArray.filter(item => item !== option.value), option.value].sort();
                            } else {
                                valuesArray = valuesArray.filter(item => item !== option.value);
                            }

                            return onChange(commaSeparated ? valuesArray.join(',') : valuesArray);
                        }}
                    />
                ))}
            </FormGroup>
            {meta && (
                <Error meta={meta} className={classes?.errorField}/>
            )}
        </FormControl>
    );
};

export const RenderCheckboxGroup: FC<IRenderCheckboxGroupProps> = (
    {
        input,
        ...rest
    }
) => {
    return (
        <CheckboxGroup
            {...rest}
            onBlur={() => input?.onBlur(input.value)} // necessary for meta.touched to work
            onChange={input.onChange}
            value={input.value}
        />
    );
};

// TODO this component is a jsx component -> once it has been refactored to Typescript this can be properly typed
// Documentation at https://github.com/JedWatson/react-select
export const renderFilteredSelect = ({input, label, meta, ...custom}: any) => {
    // this was necessary somehow ..
    custom.onChange = (value: any) => input.onChange(value);
    custom.onBlur = () => {
    };

    return <CustomAutoSelect label={label} {...input} {...custom} {...errorProps(meta)} />;
};

interface IRenderMediaUploaderProps {
    dispatch: Dispatch<any>;
    formName: string;
    input: any;
    meta: WrappedFieldMetaProps;
    getFilenameCallback?: any;
    error?: string;
    uploadUrl: string;
    loading: boolean;
    messenger: any;
}

const mapStateToMediaUploaderProps = (state: IState) => state.formUploader;

const renderMediaUploader = connect(mapStateToMediaUploaderProps)((
        {
            dispatch,
            formName,
            input,
            meta,
            getFilenameCallback,
            error,
            uploadUrl,
            loading,
            messenger,
        }: IRenderMediaUploaderProps
    ) => {
        // clear form values on unmount
        useEffect(
            () => () => {
                dispatch(reduxFormResetFile());
            },
            [dispatch],
        );

        const upload = (payload: any) => {
            // pass original filename to the filename callback
            if (getFilenameCallback) {
                getFilenameCallback(payload.attachment.name);
            }
            return dispatch(reduxFormUploadFile(payload, formName, input.name));
        };

        const reset = () => {
            dispatch(reduxFormResetFile());
            dispatch(change(formName, input.name, ''));
        };

        return (
            <Box width="100%">
                <MediaUploader
                    handleUpload={upload}
                    handleDelete={reset}
                    uploadUrl={input.value || uploadUrl}
                    name={input.name}
                    error={error}
                    isLoading={loading}
                    messenger={messenger}
                />
                <Error meta={meta}/>
            </Box>
        );
    },
);

export interface IUploadFieldProps {
    form: string;

    getFilenameCallback?(param?: any): any;

    messenger?: string | EMessenger[];
}

export const UploadField = (
    {
        form,
        name,
        getFilenameCallback,
        messenger,
        ...props
    }: IUploadFieldProps & BaseFieldProps
) => (
    <Field
        name={name}
        formName={form}
        component={renderMediaUploader}
        getFilenameCallback={getFilenameCallback}
        messenger={messenger}
        {...props}
    />
);

interface IOption {
    label: string;
    value: any;
    description?: string;
}

interface IRenderRadioButtons {
    input: any;
    label: string;
    options: IOption[] | IOption;
}

export const renderRadioButtons = ({input, label, options}: IRenderRadioButtons) => {
    const normalizedOptions: IOption[] = Array.isArray(options)
        ? options
        : Object.keys(options).map(key => {
            const ductTapeTs = options as any;
            return {
                label: ductTapeTs[key],
                value: key,
            };
        });

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">
                <Typography variant="subtitle1">{label}</Typography>
            </FormLabel>
            <RadioGroup aria-label="type" value={input.value} onChange={event => input.onChange(event.target.value)}>
                {normalizedOptions.map((option, index) => (
                    <Fragment key={option.value}>
                        <FormControlLabel
                            key={index}
                            control={<Radio color="primary"/>}
                            name={option.label}
                            label={option.label}
                            value={option.value}
                        />
                        {option.description && <Typography variant="caption" children={option.description}/>}
                    </Fragment>
                ))}
            </RadioGroup>
        </FormControl>
    );
};

interface IRenderColorPickerProps {
    input: any;
    label: string;
    color: 'primary' | 'secondary' | 'default';
    stripHashAwayFromColor?: boolean;
}

export const renderColorPicker = ({input, color, label, stripHashAwayFromColor}: IRenderColorPickerProps) => (
    <CustomColorPicker
        label={label}
        onChangeCompleted={changedColor => {
            if (stripHashAwayFromColor) {
                const filteredColor = changedColor?.replace('#', '');
                input.onChange(filteredColor);
                return;
            }
            input.onChange(changedColor);
        }}
        color={color}
    />
);

interface IRenderCountryCodeSelectProps {
    input: any;
    countryCodeFallback?: any;
}

export const renderCountryCodeSelect = ({input, countryCodeFallback}: IRenderCountryCodeSelectProps) => {
    return (
        <PhoneCountryCodeSelector value={input.value ? input.value : countryCodeFallback} onChange={input.onChange}/>
    );
};

interface IRenderPhoneTextFieldProps extends IRenderTextFieldProps {
    countryCode?: any;
}

export const renderPhoneTextField = ({input, countryCode, label, meta}: IRenderPhoneTextFieldProps) => {
    // only allow digits
    const handleChange = (event: any) => {
        event.target.value = event.target.value.replace(/\D/g, '');
        return input.onChange(event);
    };

    return (
        <PhoneNumberTextField
            {...input}
            countryCode={countryCode}
            onChange={handleChange}
            {...errorProps(meta)}
            label={label}
        />
    );
};
