// noinspection RegExpRedundantEscape
import {FC, memo, ReactElement} from 'react';

import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReactHtmlParser from 'react-html-parser';
// @ts-ignore
import Linkify from 'react-linkify';

import LinkButton from '../LinkButton/LinkButton';
import Twemoji from '../Twemoji/Twemoji';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchHighlight: {
            whiteSpace: 'pre-wrap',
            color: theme.palette.common.white,
            backgroundColor: theme.palette.warning.main,
            borderRadius: 3,
            wordWrap: 'break-word',
        },
        preWrap: {
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
        },
    }),
);

function escapeRegExpFn(string: string): string {
    // noinspection RegExpRedundantEscape
    return string.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

interface IResult {
    index: number;
    current: string;
    found: boolean;
}

export const tokenizeSearchTerm = (searchTerm: string, text: string) => {
    if (!searchTerm || !text) {
        return [{index: 0, current: text, found: false}];
    }

    const escaped = escapeRegExpFn(searchTerm);

    const tokenizer = new RegExp(`(${escaped})+`, 'ig');
    let results: IResult[] = [];
    let token;
    let lastIndex = 0;

    while ((token = tokenizer.exec(text)) !== null) {
        if (lastIndex !== token.index) {
            results = [...results, {index: lastIndex, current: text.slice(lastIndex, token.index), found: false}];
        }
        results = [...results, {index: token.index, current: token[0], found: true}];
        lastIndex = token.index + token[0].length;
    }

    if (lastIndex !== text.length) {
        results = [...results, {index: lastIndex, current: text.slice(lastIndex), found: false}];
    }

    return results;
};

interface IHighlightSearchTextProps {
    text: string;
    searchTerm?: string;
    linkify?: boolean;
    showLinkStats?: boolean;
    linkStatsRefId?: number;
}

const HighlightSearchText: FC<IHighlightSearchTextProps> = memo((
    {
        searchTerm = '',
        text,
        linkify,
        showLinkStats,
        linkStatsRefId,
    }
) => {
    const classes = useStyles();

    let tokenizedText: any;

    if (!searchTerm) {
        tokenizedText = <Twemoji className={classes.preWrap}>{text ? ReactHtmlParser(text) : ''}</Twemoji>;
    } else {
        tokenizedText = ReactHtmlParser(text).reduce((result, element, index) => {
            if (typeof element === 'object') {
                if (element.type === 'b') {
                    return [
                        ...result,
                        ...tokenizeSearchTerm(searchTerm, element.props.children[0]).map(item => (
                            <Twemoji
                                key={`${index}-${item.index}`}
                                className={item.found ? classes.searchHighlight : classes.preWrap}
                            >
                                <b>{item.current}</b>
                            </Twemoji>
                        )),
                    ];
                }
                if (element.type === 'br') {
                    return [...result, <br key={`${index}-single`}/>];
                }
                return result;
            }

            return [
                ...result,
                ...tokenizeSearchTerm(searchTerm, element).map(item => (
                    <Twemoji
                        key={`${index}-${item.index}`}
                        className={item.found ? classes.searchHighlight : classes.preWrap}
                    >
                        {item.current}
                    </Twemoji>
                )),
            ];
        }, [] as ReactElement[]);
    }

    // use linkify only if no search term set, otherwise it will mess up
    if (linkify && !searchTerm) {
        return (
            <Linkify properties={{showLinkStats, linkStatsRefId}} component={LinkButton}>
                {tokenizedText}
            </Linkify>
        );
    }

    return tokenizedText;
});

HighlightSearchText.displayName = 'HighlightSearchText';

export default HighlightSearchText;
