import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import RootRef from '@material-ui/core/RootRef';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import moment from 'moment';
import {FC, useLayoutEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import {tokenizeSearchTerm} from '../../../../../components/HighlightSearchText/HighlightSearchText';
import Twemoji from '../../../../../components/Twemoji/Twemoji';
import {EChatAction} from '../../../../../enums/chat/EChat';
import {usesMia} from '../../../../../redux/channel/channel.selectors';
import {getChatName, getLastOutgoingChatId} from '../../../../../redux/tickets/tickets.selectors';
import {useParamSelector} from '../../../../../redux/utils';
import {translateSlug} from '../../../../../utils/translation/translation.utils';
import {useTypedSelector} from '../../../../../utils/hooks/useTypedSelector';
import ChatAvatar from './ChatAvatar';
import ChatListMessage from './ChatListMessage';
import OverflowEllipsisTooltip from '../../../../../components/OverflowEllipsisTooltip/OverflowEllipsisTooltip';
import {IChat} from "../../../../../definitions/chat/chat.definitions";
import {IChatListUser} from "../../../../../definitions/user/user.definitions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'flex-start',
            padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
            minWidth: 0,
            wordBreak: 'break-word',
            flexDirection: 'column',

            '&:hover': {
                background: theme.palette.action.hover,
            },
        },
        root_outgoing: {
            alignItems: 'flex-end',

            '& $messageHeaderAvatar': {
                order: 2,
                marginRight: 0,
                marginLeft: theme.spacing(1),
            },
            '& $messageHeaderTextName': {
                order: 2,
                paddingRight: 0,
                paddingLeft: theme.spacing(1),
                flexDirection: 'row-reverse',
            },
        },
        root_interim: {
            opacity: 0.6,
        },
        systemMessage: {
            display: 'flex',
            justifyContent: (item: IChatItem) => {
                if (item.meta?.action === EChatAction.DeletedByAgent) {
                    return 'flex-start';
                }
                return 'center';
            },
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            margin: `${theme.spacing(1)}px 0`,
        },
        deletedMessage: {
            background: theme.palette.secondary.main,
        },
        errorMessage: {},
        messageHeader: {
            display: 'flex',
        },
        messageHeaderAvatar: {
            marginRight: theme.spacing(1),
        },
        messageHeaderText: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline',
            marginBottom: theme.spacing(0.5),
        },
        messageHeaderTextName: {
            paddingRight: theme.spacing(1),
            minWidth: 0,
            wordWrap: 'break-word',
        },
    }),
);

interface IChatItem extends IChat {
    userName: string;
    combine?: boolean;
    agentName: string | null;
    userId: number;
    image: string;
    is_newsletter?: boolean;
    agent_email: string;
}

interface IChatListItemProps {
    item: IChatItem;
    searchTerm: string;
    registerSearchPosition: (param: any) => void;
    channelImage: string;
    channelName: string;
    user: IChatListUser;
    chats?: IChat[];
    loadedImages: number;
}

const ChatListItem: FC<IChatListItemProps> = (
    {
        registerSearchPosition,
        searchTerm,
        item,
        user,
        chats,
        channelImage,
        channelName,
        loadedImages,
    }
) => {
    const messageRef = useRef<HTMLElement>();
    const classes = useStyles(item);

    const isMiaEnabled = useSelector(usesMia);
    const chatName = useParamSelector(getChatName, item, user);
    const [hovered, setHovered] = useState(false);
    const chat = item.chat;
    const chatLength = chats?.length;
    const lastOutgoingChatId = useParamSelector(getLastOutgoingChatId, chats);
    const forceShowAddTextModuleButton = lastOutgoingChatId === item.id;

    const agentNameFromAction = useTypedSelector(state => {
        const agentFromAction = item.meta?.agent ? state.entities.agents.byId[item.meta.agent] : null;
        return agentFromAction ? `${agentFromAction.firstname} ${agentFromAction.lastname}` : '';
    });

    // register search findings' offset position (check scroll height changes due to new chats and loaded images)
    useLayoutEffect(() => {
        if (searchTerm && chat && messageRef.current && tokenizeSearchTerm(searchTerm, chat).find(item => item.found)) {
            registerSearchPosition(messageRef.current.offsetTop);
        }
    }, [chat, registerSearchPosition, searchTerm, chatLength, loadedImages]);

    const className = classNames(
        classes.root,
        {[classes.root_outgoing]: item.outgoing},
        {[classes.root_interim]: item.interimMessage},
    );

    // check if the message is a system message (dont show start/stop message for non-mia channels)
    let systemMessage;
    if (item.meta?.action && !(!isMiaEnabled && ['started', 'stopped'].includes(item.meta.action))) {
        const isDeleted = item.meta.action === EChatAction.DeletedByAgent;
        const message = isDeleted
            ? translateSlug('chat_deleted_by_agent', agentNameFromAction)
            : translateSlug(item.meta.action);

        systemMessage = (
            <ListItem component="li" className={classes.systemMessage}>
                <Chip
                    label={<OverflowEllipsisTooltip children={message}/>}
                    variant="outlined"
                    className={isDeleted ? classes.deletedMessage : ''}
                />
            </ListItem>
        );

        // if it is an outgoing message, only show the system message chip,
        // otherwise show it additionally to the incoming message
        if (item.outgoing || item.meta.action === EChatAction.DeletedByAgent) {
            return <RootRef rootRef={messageRef}>{systemMessage}</RootRef>;
        }
    }

    return (
        <>
            <RootRef rootRef={messageRef}>
                <ListItem
                    component="li"
                    className={className}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >
                    {!item.combine && (
                        <header className={classes.messageHeader}>
                            <ChatAvatar
                                name={chatName}
                                agent_email={item.agent_email}
                                agent_id={item.agent_id}
                                bot={item.bot}
                                image={item.image}
                                outgoing={item.outgoing}
                                is_newsletter={item.is_newsletter}
                                channelImage={channelImage}
                                channelName={channelName}
                                className={classes.messageHeaderAvatar}
                            />
                            <div className={classes.messageHeaderText}>
                                <Typography component="div" variant="body2" className={classes.messageHeaderTextName}>
                                    <Twemoji>{chatName}</Twemoji>
                                </Typography>
                                <Typography component="div" color="textSecondary">
                                    <Box fontSize={10}>{moment.unix(item.chattime).format('H:mm')}</Box>
                                </Typography>
                            </div>
                        </header>
                    )}
                    <ChatListMessage
                        item={item}
                        searchTerm={searchTerm}
                        hovered={hovered}
                        hideTime={item.combine}
                        forceShowMenu={forceShowAddTextModuleButton}
                    />
                </ListItem>
            </RootRef>
            {systemMessage}
        </>
    );
};

ChatListItem.displayName = 'ChatListItem';

export default ChatListItem;
