import {EBaseRoutes} from '../../enums/routes/ERoutes';
import {generatePath, matchPath as routersMatchPath} from "react-router";

export const getTicketIdFromTicketRoute = (pathname: string) =>
    +pathname.replace(EBaseRoutes.Conversation.replace(':chatId?', ''), '');

export const getTicketRoute = (chatId: number) => generatePath(EBaseRoutes.Conversation, {chatId: String(chatId) || ''});

/**
 * Provide additional object for engage routes to be checked along with default routes
 */
export const engageRoutes: {[key: string]: string} = {};

export const addEngageRoute = (key: string, value: string) => {
    engageRoutes[key] = value;
};

export const matchPath = (haystack: string, needle: string, exact = false) =>
    routersMatchPath(haystack, {path: needle, exact})
        || (engageRoutes[needle] && routersMatchPath(haystack, {path: engageRoutes[needle], exact}));
