import {FC, useCallback} from 'react';

import Button from '@material-ui/core/Button';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PhoneIcon from '@material-ui/icons/Phone';
import {IMetaButton} from "../../../../../definitions/chat/chat.definitions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            marginRight: (props: IChatListMessageButtons) => props.marginRight ? theme.spacing(4) : undefined,
        },
        button: {
            display: 'inline-flex',
            marginTop: theme.spacing(0.5),
        },
    }),
);

interface IChatListMessageButtons {
    buttons: IMetaButton[];
    marginRight?: boolean
}

const ChatListMessageButtons: FC<IChatListMessageButtons> = props => {
    const {buttons} = props;
    const classes = useStyles(props);

    const openUrl = useCallback((url: string) => window.open(url), []);

    return (
        <div className={classes.wrapper}>
            {buttons.map(button => {
                if (button.type !== 'link') {
                    return (
                        <Button variant="outlined" size="small" className={classes.button} key={button.info} disabled>
                            {button.caption}
                        </Button>
                    );
                }

                // icon and href for link buttons
                const isPhoneLink = button.info.startsWith('+');
                const icon = isPhoneLink ? <PhoneIcon fontSize="small"/> : <ExitToAppIcon fontSize="small"/>;
                const href = isPhoneLink ? `tel:${button.info}` : button.info;

                return (
                    <Button
                        key={button.info}
                        variant="outlined"
                        size="small"
                        startIcon={icon}
                        onClick={() => openUrl(href)}
                        className={classes.button}
                    >
                        {button.caption}
                    </Button>
                );
            })}
        </div>
    );
};

export default ChatListMessageButtons;
