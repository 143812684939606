import {FC, useMemo} from 'react';

import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import moment from 'moment/moment';

import ChatListMenu from './ChatListMenu';
import ChatListMessageButtons from './ChatListMessageButtons';
import {convertChatToMessageContent} from "../../../../../utils/chat/chat.utils";
import {IChat} from "../../../../../definitions/chat/chat.definitions";
import MessageContent from "../../../../../components/MessageContent/MessageContent";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            maxWidth: '70%',

            // css only
            '&:hover $visibleOnHover': {
                visibility: 'visible',
                opacity: 1,
                transition: 'all 0.2s ease-out',
            },
        },
        messageWrapper: {
            display: 'flex',
            flex: '1',
            position: 'relative',
        },
        timeStamp: {
            paddingTop: 3,
            flexBasis: 36,
            minWidth: 36,
            maxWidth: 36,
            wordWrap: 'break-word',
            marginRight: -4,
        },
        visibleOnHover: {
            visibility: 'hidden',
            opacity: 0,
            transition: 'all 0.2s ease-in',
        },
        timeStamp_hidden: {},
        buttonWrapper: {
            marginRight: theme.spacing(4),
        },
        menu: {
            position: 'absolute',
            top: theme.spacing(-0.5),
            transform: 'translateX(100%)',
            right: theme.spacing(-0.5),
        },

        // controlled hovering from parent component (hover over list item will trigger hover)
        root_hovered: {
            '& $visibleOnHover': {
                visibility: 'visible',
                opacity: 1,
                transition: 'all 0.2s ease-out',
            },
        },

        root_outgoing: {
            '& $messageWrapper': {
                flexDirection: 'row-reverse',
            },
            '& $timeStamp': {
                textAlign: 'right',
            },
            '& $menu': {
                right: 'auto',
                left: theme.spacing(-0.5),
                transform: 'translateX(-100%)',
            },
        },
    }),
);

interface IChatListMessage {
    item: IChat;
    searchTerm?: string;
    hovered?: boolean; // for controlled hovering
    hideTime?: boolean;
    forceShowMenu?: boolean;
}

const ChatListMessage: FC<IChatListMessage> = ({item, searchTerm, hovered, hideTime, forceShowMenu}) => {
    const classes = useStyles();

    const timeStampClassName = classNames(classes.timeStamp, classes.visibleOnHover, {
        [classes.timeStamp_hidden]: hideTime,
    });

    const rootClassName = classNames(
        classes.root,
        {[classes.root_hovered]: hovered},
        {[classes.root_outgoing]: item.outgoing},
    );

    const menuClassName = classNames(classes.menu, {[classes.visibleOnHover]: !forceShowMenu});
    const messageContent = useMemo(() => convertChatToMessageContent(item), [item]);

    if (!messageContent) {
        return null;
    }

    return (
        <div className={rootClassName}>
            <div className={classes.messageWrapper}>
                <Typography component="div" variant="overline" className={timeStampClassName}>
                    {moment.unix(item.chattime).format('H:mm')}
                </Typography>
                <MessageContent item={messageContent} searchTerm={searchTerm} />
                <ChatListMenu item={item} className={menuClassName} />
            </div>
            {!!item.meta?.buttons?.length && <ChatListMessageButtons buttons={item.meta.buttons} marginRight={true} />}
        </div>
    );
};

ChatListMessage.displayName = 'ChatListMessage';

export default ChatListMessage;
