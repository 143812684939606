import {EChartAutomationKpi} from './EChartAutomationKpi';
import {EChartChatAction} from './EChartChatAction';
import {EChartChatKpi, TChartChatKpiIn, TChartChatKpiOut, TChartKpiAll} from './EChartChatKpi';
import {EChartChatSender} from './EChartChatSender';
import {EChartSource} from './EChartSource';

// all KPIs must be the order that is display on the statistics page
export enum EChartTicketKpi {
    TotalOpenedTickets = 'total_opened_tickets',
    AverageInvolvedAgentsPerTicket = 'average_involved_agents_per_ticket',
    TotalClosedTickets = 'total_closed_tickets',
    AverageDurationUntilFirstAssignment = 'average_duration_until_first_assignment',
    AverageDurationUntilInitialAgentReaction = 'average_duration_until_initial_agent_reaction',
    AverageDurationUntilTicketAnswered = 'average_duration_until_ticket_answered',
    AverageDurationUntilTicketClosed = 'average_duration_until_ticket_closed',
    AverageDurationUntilUserReaction = 'average_duration_until_user_reaction',
}

export const shouldShowKpiInHours = (kpi: string): boolean =>
    [
        EChartAutomationKpi.AutomationTimeSavingAutorouting,
        EChartAutomationKpi.AutomationTimeSavingOutgoingMessages,
        EChartAutomationKpi.AutomationTimeSavingChatBlocks,
    ].includes(kpi as any);

export const shouldShowKpiInTimePeriod = (kpi: string): boolean =>
    [
        EChartTicketKpi.AverageDurationUntilFirstAssignment,
        EChartTicketKpi.AverageDurationUntilInitialAgentReaction,
        EChartTicketKpi.AverageDurationUntilTicketAnswered,
        EChartTicketKpi.AverageDurationUntilTicketClosed,
        EChartTicketKpi.AverageDurationUntilUserReaction,
    ].includes(kpi as any);

export const shouldShowKpiInPercent = (kpi: string): boolean =>
    [
        EChartAutomationKpi.AutomationRatioAutomatedMessages,
        EChartAutomationKpi.AutomationRatioAutoRoutedTickets,
        EChartAutomationKpi.AutomationRatioTicketsWithChatBlocks,
        EChartAutomationKpi.AutomationRatioTicketsWithChatBot,
    ].includes(kpi as any);

// this object is used to map the values generated by the
// AddChartDialog to the values that are used by our Api
export const ChatChartSelectionToKpiMap = {
    [EChartSource.Chats]: {
        [EChartChatAction.Sent]: {
            [EChartChatSender.All]: EChartChatKpi.AllOut,
            [EChartChatSender.Agent]: EChartChatKpi.Out,
            [EChartChatSender.Bot]: EChartChatKpi.BotOut,
        },
        [EChartChatAction.Received]: EChartChatKpi.AllIn,
    },
};

// this is what a ChatChart Kpi gets mapped to
export type TChatChartSelection = {
    source: EChartSource;
    action: EChartChatAction;
    sender: EChartChatSender | null;
};

export const KpiToChatChartSelectionMapOut: {[key in TChartChatKpiOut]: TChatChartSelection} = {
    [EChartChatKpi.Out]: {
        source: EChartSource.Chats,
        action: EChartChatAction.Sent,
        sender: EChartChatSender.Agent,
    },
    [EChartChatKpi.AllOut]: {
        source: EChartSource.Chats,
        action: EChartChatAction.Sent,
        sender: EChartChatSender.All,
    },
    [EChartChatKpi.BotOut]: {
        source: EChartSource.Chats,
        action: EChartChatAction.Sent,
        sender: EChartChatSender.Bot,
    },
};

export const KpiToChatChartSelectionMapIn: {[key in TChartChatKpiIn]: TChatChartSelection} = {
    [EChartChatKpi.AllIn]: {
        source: EChartSource.Chats,
        action: EChartChatAction.Received,
        sender: null,
    },
};

export type KpiToChatChartSelectionMappedToFrontend = {
    [key in TChartKpiAll]: TChatChartSelection;
};

export const KpiToChatChartSelectionMap: KpiToChatChartSelectionMappedToFrontend = {
    ...KpiToChatChartSelectionMapIn,
    ...KpiToChatChartSelectionMapOut,
};
