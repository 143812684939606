import {normalize, NormalizedSchema} from 'normalizr';
import {Reducer} from 'redux';
import {IEntityById, IEntityState} from '../../../definitions/generic/generic.definitions';
import {IMiaContent} from '../../../definitions/mia/mia.definitions';
import {EUpdateStreamAction} from '../../../enums/updateStream/EUpdateStreamAction';
import {UPDATE_STREAM} from '../../connection/connection.types';
import {
    MIA_CREATE_CONTENT_FULFILLED,
    MIA_DELETE_CONTENT_PENDING,
    MIA_GET_CONTENT_FULFILLED,
    MIA_UPDATE_CONTENT_FULFILLED,
} from './content.types';
import {responseSchema} from './content.normalizr';

export const contentInitState: IEntityState<IMiaContent> = {
    byId: {} as IEntityById<IMiaContent>,
    ids: [],
};

const getMergedItems = (
    state: IEntityState<IMiaContent>,
    normalized: NormalizedSchema<{ miaContents: IEntityById<IMiaContent> }, { content: number[] }>,
) => {
    const newById = normalized.entities.miaContents;
    const newIds = normalized.result.content;

    let oldById = {...state.byId};
    const oldIds = [...state.ids];

    Object.keys(newById).forEach((id: string) => {
        oldById = {
            ...oldById,
            [+id]: newById[+id],
        };
    });

    newIds.forEach(id => {
        if (!oldIds.includes(id)) {
            oldIds.push(id);
        }
    });

    return {
        byId: oldById,
        ids: oldIds,
    };
};

export const contentReducer: Reducer<IEntityState<IMiaContent>> = (state = contentInitState, action) => {
    switch (action.type) {
        case MIA_DELETE_CONTENT_PENDING: {
            const newIds = state.ids.filter(id => id !== action.meta.id);
            const {[action.meta.id]: deleted, ...newById} = state.byId;

            return {
                byId: newById,
                ids: newIds,
            };
        }
        case MIA_GET_CONTENT_FULFILLED: {
            const normalized: NormalizedSchema<{ miaContents: IEntityById<IMiaContent> },
                { content: number[] }> = normalize(action.payload, responseSchema);

            if (normalized.result.content.length === 0) {
                return state;
            }

            return getMergedItems(state, normalized);
        }
        case MIA_UPDATE_CONTENT_FULFILLED:
        case MIA_CREATE_CONTENT_FULFILLED: {
            const item = action.payload.content[0];
            const ids = [...state.ids];
            if (!ids.includes(item.id)) {
                ids.push(item.id);
            }
            return {
                byId: {...state.byId, [item.id]: item},
                ids,
            };
        }
        case UPDATE_STREAM: {
            const {message} = action.payload;
            if (message.action === EUpdateStreamAction.MiaContent) {
                if (message.method === 'delete') {
                    const payload = message.payload;

                    const ids = state.ids.filter(id => id !== payload.id);
                    const {[payload.id]: _, ...byId} = state.byId;

                    return {
                        ids,
                        byId,
                    };
                } else {
                    const payload = message.payload;
                    const content = payload.content?.[0] || payload; // TODO clean up Update Stream -> post/put payload should be identical

                    const ids = state.ids.indexOf(content.id) < 0 ? [...state.ids, content.id] : [...state.ids];
                    const byId = {...state.byId, [content.id]: content};

                    return {
                        ids,
                        byId,
                    };
                }
            }
            return state;
        }
        default:
            return state;
    }
};
