import sortBy from 'lodash/sortBy';
import moment from 'moment/moment';

import {IChannelTag} from '../../definitions/channel/channel.definitions';
import {IEntityByKey} from '../../definitions/generic/generic.definitions';
import {ITicket} from '../../definitions/tickets/tickets.definitions';

const getLastChatOfTicket = (ticket?: ITicket) => {
    return (ticket?.chats && ticket.chats[0]) || null;
};

const getChatTimeOfLastMessage = (ticket?: ITicket) => {
    // use waiting_since for pending tickets
    if (ticket?.waiting_since) {
        return ticket.waiting_since;
    }

    const lastChat = getLastChatOfTicket(ticket);

    return !lastChat ? 0 : lastChat.chattime;
};

export const isTicketAnswered = (ticket: ITicket) => !!ticket && !ticket.waiting_since;

// if ticket is not answered, it is considered as pending
const isTicketPending = (ticket: ITicket) => !isTicketAnswered(ticket);

/**
 * Get delta of last chat time and a past point in time
 * >=0 means the chat time is older
 * @param chatTime
 * @param secondsInPast
 * @returns {number}
 */
export const getDeltaInSecondsBetweenChatTimeAndPastSeconds = (chatTime: number, secondsInPast: number) => {
    const nowInSeconds = Math.floor(Date.now() / 1000);

    return nowInSeconds - secondsInPast - chatTime;
};

/**
 * Check if a ticket is older or equal than a given time period
 * @param ticket
 * @param minutes
 * @returns {boolean}
 */
export const isTicketOlderOrEqualThanMinutes = (ticket: ITicket, minutes: number) =>
    getDeltaInSecondsBetweenChatTimeAndPastSeconds(getChatTimeOfLastMessage(ticket), minutes * 60) >= 0;

export const sortTicketsByStatusAndTimeSinceLastMessage = (
    tickets: ITicket[],
    filterFunc = (ticket: ITicket) => !!ticket.status,
) => {
    if (!tickets?.length) {
        return [];
    }

    const filteredTickets = tickets.filter(filterFunc);

    const pendingTickets = filterTicketsByAnsweredState(filteredTickets, false);
    const answeredTickets = filterTicketsByAnsweredState(filteredTickets, true);

    return [
        ...sortTicketsByTimeSinceLastMessage(pendingTickets),
        ...sortTicketsByTimeSinceLastMessage(answeredTickets),
    ];
};

const filterTicketsByAnsweredState = (tickets: ITicket[], isAnswered: boolean) =>
    tickets.filter(ticket => (isAnswered ? isTicketAnswered(ticket) : isTicketPending(ticket)));

export const sortTicketsByTimeSinceLastMessage = (tickets: ITicket[], reverse?: boolean) => {
    const sortedTickets = tickets.sort((a, b) => getChatTimeOfLastMessage(a) - getChatTimeOfLastMessage(b));

    return reverse ? sortedTickets.reverse() : sortedTickets;
};

// normalize preview message length


export const AlertLevel = {
    strong: 'strong',
    light: 'light',
    default: 'default',
};

export const getWaitingSinceMessage = (ticket: ITicket) => moment.unix(getChatTimeOfLastMessage(ticket)).fromNow(true);

export const getClosedSinceMessage = (ticket: ITicket) => moment.unix(ticket.closed).format('DD.MM.YYYY - HH:mm');

export const getPostponedToMessage = (ticket: ITicket) => moment.unix(ticket?.postponed ? ticket?.postponed: 0).format('DD.MM.YYYY - HH:mm');

export const getIsTicketPostponed = (postponed?: number) => (postponed || 0) > moment().unix();

export const getMessageTimesAndAlertStatus = (
    ticket: ITicket,
    overdueTimeMinutes: number,
    criticalTimeMinutes: number,
    hideSuffix = true,
) => {
    const lastChatTime = getChatTimeOfLastMessage(ticket);

    const fromNowText = moment.unix(lastChatTime).fromNow(hideSuffix);

    if (!ticket.waiting_since || !lastChatTime) {
        return {
            fromNow: fromNowText,
            alertLevel: AlertLevel.default,
        };
    }

    // only set alert level if ticket is pending
    const alertLevel = isTicketAnswered(ticket)
        ? AlertLevel.default
        : isTicketOlderOrEqualThanMinutes(ticket, criticalTimeMinutes)
            ? AlertLevel.strong
            : isTicketOlderOrEqualThanMinutes(ticket, overdueTimeMinutes)
                ? AlertLevel.light
                : AlertLevel.default;

    return {
        fromNow: fromNowText,
        alertLevel,
    };
};

export const AcceptedMimeTypes = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'video/mp4',
    'video/mpeg',
    'video/quicktime',
    'audio/mpeg',
    'audio/mp3',
    'audio/amr',
    'application/pdf',
    // Please leave, will be implemented in future
    // "text/x-vcard": "vcf", // Spec 3
    // "text/vcard": "vcf" // ab Spec 4
];

export const ChatType = {
    AUDIO: 'audio',
    CONTACT: 'contact',
    DOCS: 'docs',
    DOCUMENT: 'document',
    IMAGE: 'image',
    LOCATION: 'location',
    VIDEO: 'video',
    TEXT: 'text',
};

export const TagColorType = {
    RED: 'E44436',
    ORANGE: 'FF9300',
    GREEN: '4AAE20',
    GRAY: '707070',
};

export const TagColorPriority = [TagColorType.RED, TagColorType.ORANGE, TagColorType.GREEN, TagColorType.GRAY];

const tagPriority = (tag: IChannelTag) => {
    if (!tag || !('color_bg' in tag)) {
        console.error('You used a wrong label, this one does not have colors. Use labels from the channel');
        return TagColorPriority.length;
    }
    const index = TagColorPriority.findIndex(color => tag.color_bg === color);
    return index >= 0 ? index : TagColorPriority.length;
};

/***
 * returns the tags/labels of the ticket sorted by priority and name
 * Important these tags must contain color info
 * @param tags[]
 * @return string[]
 */
export const getSortedTags = (tags: IChannelTag[]) => {
    const tagsWithPriority = tags.map(label => ({
        label,
        priority: tagPriority(label),
    }));

    return sortBy(tagsWithPriority, [
        ({priority}) => priority,
        ({label}) => (label?.name?.toLowerCase() || ''),
    ]).map(withPriority => withPriority.label);
};

/***
 * returns the tags/labels of the ticket sorted by priority and name
 * @param ticket
 * @param labels
 */
export const getSortedTagsFromTicket = (
    ticket: ITicket,
    labels: IEntityByKey<IChannelTag> = {},
) => {
    const ticketTags = (ticket.labels || [])
        .map(id => labels[id])
        .filter(tag => !!tag); // filter out deleted tags
    return getSortedTags(ticketTags);
};
