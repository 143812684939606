import {IEntityByKey} from '../../definitions/generic/generic.definitions';

interface ICountryCodeDetails {
    value: string;
    text?: string;
    optional?: string;
}

export const countryCodes: IEntityByKey<ICountryCodeDetails> = Object.freeze({
    algeria: {
        value: '213',
    },
    andoora: {
        value: '376',
    },
    angola: {
        value: '244',
    },
    anguilla: {
        value: '1264',
    },
    antigua: {
        value: '1268',
        text: 'Antigua & Barbuda',
    },
    argentina: {
        value: '54',
    },
    armenia: {
        value: '374',
    },
    aruba: {
        value: '297',
    },
    australia: {
        value: '61',
    },
    austria: {
        value: '43',
    },
    azerbaijan: {
        value: '994',
    },
    bahamas: {
        value: '1',
        optional: '242',
    },
    bahrain: {
        value: '973',
    },
    bangladesh: {
        value: '880',
    },
    barbados: {
        value: '1246',
    },
    belarus: {
        value: '375',
    },
    belgium: {
        value: '320',
    },
    belize: {
        value: '501',
    },
    benin: {
        value: '229',
    },
    bermuda: {
        value: '1441',
    },
    bhutan: {
        value: '975',
    },
    bolivia: {
        value: '591',
    },
    bosniaHerzigovina: {
        value: '387',
    },
    botswana: {
        value: '267',
    },
    brazil: {
        value: '55',
    },
    brunei: {
        value: '673',
    },
    bulgaria: {
        value: '359',
    },
    burkinaFaso: {
        value: '226',
    },
    burundi: {
        value: '257',
    },
    cambodia: {
        value: '855',
    },
    cameroon: {
        value: '237',
    },
    canada: {
        value: '1',
    },
    capeVerdeIslands: {
        value: '238',
    },
    caymanIslands: {
        value: '1345',
    },
    centralAfricanRepublic: {
        value: '236',
    },
    chile: {
        value: '56',
    },
    china: {
        value: '86',
    },
    colombia: {
        value: '57',
    },
    comoros: {
        value: '269',
    },
    congo: {
        value: '242',
    },
    cookIslands: {
        value: '682',
    },
    costaRica: {
        value: '506',
    },
    cotedivoire: {
        value: '225',
        text: "Côte d'Ivoire",
    },
    croatia: {
        value: '385',
    },
    cuba: {
        value: '53',
    },
    cyprusNorth: {
        value: '90392',
    },
    cyprusSouth: {
        value: '357',
    },
    czechRepublic: {
        value: '420',
    },
    denmark: {
        value: '45',
    },
    djibouti: {
        value: '253',
    },
    dominica: {
        value: '1809',
    },
    dominicanRepublic: {
        value: '1',
        optional: '809',
    },
    ecuador: {
        value: '593',
    },
    egypt: {
        value: '20',
    },
    elSalvador: {
        value: '503',
    },
    equatorialGuinea: {
        value: '240',
    },
    eritrea: {
        value: '291',
    },
    estonia: {
        value: '372',
    },
    ethiopia: {
        value: '251',
    },
    falklandIslands: {
        value: '500',
    },
    faroeIslands: {
        value: '298',
    },
    fiji: {
        value: '679',
    },
    finland: {
        value: '358',
    },
    france: {
        value: '33',
    },
    frenchGuiana: {
        value: '594',
    },
    frenchPolynesia: {
        value: '689',
    },
    gabon: {
        value: '241',
    },
    gambia: {
        value: '220',
    },
    georgia: {
        value: '7880',
    },
    germany: {
        value: '49',
    },
    ghana: {
        value: '233',
    },
    gibraltar: {
        value: '350',
    },
    greece: {
        value: '30',
    },
    greenland: {
        value: '299',
    },
    grenada: {
        value: '1473',
    },
    guadeloupe: {
        value: '590',
    },
    guam: {
        value: '671',
    },
    guatemala: {
        value: '502',
    },
    guinea: {
        value: '224',
    },
    guineaBissau: {
        value: '245',
    },
    guyana: {
        value: '592',
    },
    haiti: {
        value: '509',
    },
    honduras: {
        value: '504',
    },
    hongKong: {
        value: '852',
    },
    hungary: {
        value: '36',
    },
    iceland: {
        value: '354',
    },
    india: {
        value: '91',
    },
    indonesia: {
        value: '62',
    },
    iran: {
        value: '98',
    },
    iraq: {
        value: '964',
    },
    ireland: {
        value: '353',
    },
    israel: {
        value: '972',
    },
    italy: {
        value: '39',
    },
    jamaica: {
        value: '1',
        optional: '876',
    },
    japan: {
        value: '81',
    },
    jordan: {
        value: '962',
    },
    kazakhstan: {
        value: '7',
    },
    kenya: {
        value: '254',
    },
    kiribati: {
        value: '686',
    },
    koreaNorth: {
        value: '850',
    },
    koreaSouth: {
        value: '82',
    },
    kuwait: {
        value: '965',
    },
    kyrgyzstan: {
        value: '996',
    },
    laos: {
        value: '856',
    },
    latvia: {
        value: '371',
    },
    lebanon: {
        value: '961',
    },
    lesotho: {
        value: '266',
    },
    liberia: {
        value: '231',
    },
    libya: {
        value: '218',
    },
    liechtenstein: {
        value: '417',
    },
    lithunia: {
        value: '370',
    },
    luxembourg: {
        value: '352',
    },
    macao: {
        value: '853',
    },
    macedonia: {
        value: '389',
    },
    madagascar: {
        value: '261',
    },
    malawi: {
        value: '265',
    },
    malaysia: {
        value: '60',
    },
    maldives: {
        value: '960',
    },
    mali: {
        value: '223',
    },
    malta: {
        value: '356',
    },
    marshallIslands: {
        value: '692',
    },
    martinique: {
        value: '596',
    },
    mauritania: {
        value: '222',
    },
    mayotte: {
        value: '269',
    },
    mexico: {
        value: '52',
    },
    micronesia: {
        value: '691',
    },
    moldova: {
        value: '373',
    },
    monaco: {
        value: '377',
    },
    mongolia: {
        value: '976',
    },
    montserrat: {
        value: '1664',
    },
    morocco: {
        value: '212',
    },
    mozambique: {
        value: '258',
    },
    myanmar: {
        value: '95',
    },
    namibia: {
        value: '264',
    },
    nauru: {
        value: '674',
    },
    nepal: {
        value: '977',
    },
    netherlands: {
        value: '31',
    },
    newCaledonia: {
        value: '687',
    },
    newZealand: {
        value: '64',
    },
    nicaragua: {
        value: '505',
    },
    niger: {
        value: '227',
    },
    nigeria: {
        value: '234',
    },
    niue: {
        value: '683',
    },
    norfolgIslands: {
        value: '672',
    },
    northernMarianas: {
        value: '670',
    },
    norway: {
        value: '47',
    },
    oman: {
        value: '968',
    },
    palau: {
        value: '680',
    },
    panama: {
        value: '507',
    },
    papuaNewGuinea: {
        value: '675',
    },
    paraguay: {
        value: '595',
    },
    peru: {
        value: '51',
    },
    philippines: {
        value: '63',
    },
    poland: {
        value: '48',
    },
    portugal: {
        value: '351',
    },
    puertoRico: {
        value: '1787',
    },
    qatar: {
        value: '974',
    },
    reunion: {
        value: '262',
    },
    romania: {
        value: '40',
    },
    russia: {
        value: '7',
    },
    rwanda: {
        value: '250',
    },
    sanMarino: {
        value: '378',
    },
    saudiArabia: {
        value: '966',
    },
    senegal: {
        value: '221',
    },
    serbia: {
        value: '381',
    },
    seychelles: {
        value: '248',
    },
    sierraLeone: {
        value: '232',
    },
    singapore: {
        value: '65',
    },
    slovakRepublic: {
        value: '421',
    },
    slovenia: {
        value: '386',
    },
    solomonIslands: {
        value: '677',
    },
    somalia: {
        value: '252',
    },
    southAfrica: {
        value: '27',
    },
    spain: {
        value: '34',
    },
    sriLanka: {
        value: '94',
    },
    stHelena: {
        value: '290',
    },
    stKitts: {
        value: '1869',
    },
    stLucia: {
        value: '1758',
    },
    sudan: {
        value: '249',
    },
    suriname: {
        value: '597',
    },
    swaziland: {
        value: '268',
    },
    sweden: {
        value: '46',
    },
    switzerland: {
        value: '41',
    },
    syria: {
        value: '963',
    },
    taiwan: {
        value: '886',
    },
    tajikstan: {
        value: '7',
    },
    thailand: {
        value: '66',
    },
    togo: {
        value: '228',
    },
    tonga: {
        value: '676',
    },
    trinidadTobago: {
        value: '1868',
    },
    tunisia: {
        value: '216',
    },
    turkey: {
        value: '90',
    },
    turkmenistan: {
        value: '7',
    },
    tuvalu: {
        value: '1649',
    },
    uganda: {
        value: '256',
    },
    uk: {
        value: '44',
    },
    ukraine: {
        value: '380',
    },
    unitedArabEmirates: {
        value: '971',
    },
    uruguay: {
        value: '598',
    },
    usa: {
        value: '1',
    },
    uzbekistan: {
        value: '7',
    },
    vanuatu: {
        value: '678',
    },
    vaticanCity: {
        value: '379',
    },
    venezuela: {
        value: '58',
    },
    vietnam: {
        value: '84',
    },
    virginIslandsBritish: {
        value: '84',
    },
    virginIslandsUs: {
        value: '84',
    },
    yemenNorth: {
        value: '969',
    },
    wallisFutuna: {
        value: '681',
        text: 'Wallis & Futuna',
    },
    yemenSouth: {
        value: '967',
    },
    zambia: {
        value: '260',
    },
    zimbabwe: {
        value: '263',
    },
});
