import {FC, ReactNode, Ref, SetStateAction, useEffect, useState} from 'react';


import Collapse from '@material-ui/core/Collapse';
import Paper from '@material-ui/core/Paper';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { GoogleMap, Marker, useLoadScript} from '@react-google-maps/api';

import {GOOGLE_MAPS_API_KEY} from '../../../config';
import {EIcons} from '../../../enums/icons/EIcons';
import CustomIconButton from '../../CustomIconButton/CustomIconButton';
import CustomTextField from '../CustomTextField/CustomTextField';
import {StandardTextFieldProps, TextFieldProps} from '@material-ui/core/TextField/TextField';
import {WrappedFieldMetaProps} from "redux-form";

const containerStyles: any = {
    // TS compiler will crap up if i dont force any here
    position: 'relative',
    border: '1px solid #bdbdbd',
    borderTop: 'none',
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    overflow: 'hidden',
};

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: '100%',
            position: 'relative',
        },
        mapContainerOpen: {
            ...containerStyles,
            height: 280,
        },
        mapContainerClosed: {
            ...containerStyles,
            height: 0,
        },
        textFieldNoBottomBorder: {
            '& fieldset': {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderBottom: 'none',

                borderColor: '#bdbdbd',
            },
        },
    }),
);

interface LocationFieldProps extends StandardTextFieldProps {
    value: {
        address?: string;
        location?: {
            lat: string | number;
            lng: string | number;
        }
    };
}

interface ICustomLocationPickerProps {
    input: TextFieldProps | LocationFieldProps;
    custom?: any;
    meta?: WrappedFieldMetaProps;
    label?: ReactNode;
    inputRef?: Ref<any>;
}

interface ILocation {
    address: string;
    location?: ICoords
}

interface ICoords {
  lat: number,
  lng: number
}

const containerStyle = {
  width: '100%',
  height: '100%',
};

const options = {
  disableDefaultUI: true,
};

const isLocation = (value: string | ILocation): value is ILocation => {
    return typeof value !== 'string';
};

const CustomLocationPicker: FC<ICustomLocationPickerProps> = (
    {
        label = '',
        inputRef,
        input,
        custom,
        meta,
    },
) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const [value, setValue] = useState<string>();
    const [mapCenter, setMapCenter] = useState<ICoords>();

    const { isLoaded } = useLoadScript({
      googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    });

    useEffect(() => {
        if (isLocation(input.value as string | ILocation)) {
            const value: ILocation = input.value as ILocation;
            setValue(value.address as string);
            setMapCenter(value.location as SetStateAction<ICoords | undefined>);
        } else {
            setValue(undefined);
            setMapCenter(undefined);
        }
    }, [input.value]);

    const toggleMap = () => {
        if (mapCenter) {
            setOpen(!open);
        }
    };

  return (
        <div className={classes.root}>
            <CustomTextField
                inputRef={inputRef}
                label={label}
                {...input}
                {...(value ? {value} : {})}
                {...custom}
                meta={meta}
                classes={open ? {root: classes.textFieldNoBottomBorder} : {}}
                InputProps={{
                    endAdornment: (
                        <CustomIconButton
                            edge={'end'}
                            onClick={toggleMap}
                            icon={EIcons.LOCATION}
                            disabled={!mapCenter}
                            size={'small'}
                            iconProps={{
                                fontSize: 'small',
                            }}
                        />
                    ),
                }}
            />
            <Collapse in={open} style={{transformOrigin: 'top'}}>
                <Paper elevation={0}>
                    <div className={open ? classes.mapContainerOpen : classes.mapContainerClosed}>
                        {Boolean(mapCenter) && Number(mapCenter?.lat) && Number(mapCenter?.lng) &&
                          Boolean(isLoaded) ? (
                              <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={{
                                  lat: Number(mapCenter?.lat),
                                  lng: Number(mapCenter?.lng)}}
                                zoom={14.2}
                                options={options}
                              >
                                <Marker
                                  position={mapCenter as any}
                                  onClick={(t: any) => {
                                    const { latLng } = t;
                                    const lat = latLng.lat();
                                    const lng = latLng.lng();
                                    if (window) {
                                      window.open(
                                        `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`,
                                        '_blank');
                                    }
                                    return;
                                  }} />
                              </GoogleMap>
                          ) : null
                        }
                    </div>
                </Paper>
            </Collapse>
        </div>
    );
};

export default CustomLocationPicker;
