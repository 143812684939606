import {FC} from 'react';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import IconFactory from '../../../../components/IconFactory/IconFactory';
import {ITicket} from '../../../../definitions/tickets/tickets.definitions';
import {EIcons} from '../../../../enums/icons/EIcons';
import {isTicketAnswered} from '../../../../utils/ticket/ticket.utils';

const useStyles = makeStyles(() =>
    createStyles({
        label: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            flexFlow: 'column nowrap',
            color: 'inherit',
        },
        labelName: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: 10,
            fontWeight: 300,
        },
        labelContext: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: 10,
            fontWeight: 500,
        },
        replyIcon: {
            width: 12,
            height: 12,
        },
    }),
);

interface ILabelProps {
    contactName: string;
    ticket: ITicket;
    timingAndAlert: {
        fromNow: string;
        alertLevel: string;
    };
}

const Label: FC<ILabelProps> = props => {
    const {contactName, ticket, timingAndAlert} = props;
    const classes = useStyles();

    return (
        <div className={classes.label}>
            <div className={classes.labelName}>{contactName}</div>
            <div className={classes.labelContext}>
                {isTicketAnswered(ticket)
                    ? IconFactory.getIconWithProps(EIcons.REPLY, {className: classes.replyIcon})
                    : timingAndAlert.fromNow}
            </div>
        </div>
    );
};

export default Label;
