export const ABSENCE_SET = 'ABSENCE_SET';

export const ABSENCE_SET_FULFILLED = 'ABSENCE_SET_FULFILLED';

export const ABSENCE_GET = 'ABSENCE_GET';
export const ABSENCE_GET_PENDING = 'ABSENCE_GET_PENDING';
export const ABSENCE_GET_FULFILLED = 'ABSENCE_GET_FULFILLED';
export const ABSENCE_GET_REJECTED = 'ABSENCE_GET_REJECTED';

export const ABSENCE_PUT = 'ABSENCE_PUT';

export const ABSENCE_PUT_FULFILLED = 'ABSENCE_PUT_FULFILLED';

export const ABSENCES_LIST_DELETE_ITEM = 'ABSENCES_LIST_DELETE_ITEM';

export const ABSENCES_LIST_DELETE_ITEM_FULFILLED = 'ABSENCES_LIST_DELETE_ITEM_FULFILLED';
