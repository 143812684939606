import {ReactElement, ReactNode, useCallback, useEffect, useMemo, useState} from 'react';

import {
    IActionMenuItem,
    ITableConfig,
    ITableRow,
    ITableSorting,
} from '../../definitions/components/tableConfig.definitions';
import {GridColumns, GridSortModel} from '@material-ui/data-grid';
import translations from '../../providers/TranslationProvider/translations';
import ActionMenu from './components/ActionMenu/ActionMenu';
import {useSelector} from 'react-redux';
import {getActorSettings} from '../../redux/actor/actor.selectors';
import CustomDataGrid, {CustomDataGridProps} from '../CustomDataGrid/CustomDataGrid';


interface ITableListProps<Item extends ITableRow> extends Omit<CustomDataGridProps, 'columns' | 'rows'> {
    name?: string; // used to store in actor settings
    rows: Item[];
    config: ITableConfig[];
    actionMenuItems?: IActionMenuItem<Item>[];
    contentEmptyMessage?: string | ReactNode;
    sorting?: ITableSorting;
    handleSorting?: (field: string, direction?: 'asc' | 'desc') => void;
}

const TableList: <Item extends ITableRow>(
    props: ITableListProps<Item>,
) => ReactElement<ITableListProps<Item>> = props => {
    const {
        name,
        config,
        rows,
        actionMenuItems,
        contentEmptyMessage,
        loading,
        autoPageSize,
        pageSize: _pageSize = 10,
        noPages,
        rowsPerPageOptions = [10, 20, 30],
        components,
        componentsProps,
        ...rest
    } = props;

    const arrayRows: typeof rows = !Array.isArray(rows) ? Object.values(rows) : rows;

    const filteredRows = useMemo(() => {
        // add id field if not present, because its required in DataGrid component
        return arrayRows.filter(row => typeof row === 'object').map((row, index) => ({
            id: row.id || index,
            ...row,
        }));
    }, [arrayRows]);

    const columns: GridColumns = useMemo(() => {
        const mainColumns: GridColumns = config.map(column => ({
            field: column.attributeKey || column.translationKey || ' ',
            headerName: column.headerName || (column.translationKey ? translations[column.translationKey] : ''),
            width: column.width,
            minWidth: column.minWidth,
            flex: column.flex || 1,
            valueGetter: column.valueGetter,
            renderCell: column.cellRenderer,
            valueFormatter: column.valueFormatter ? (params) => column.valueFormatter!(params.row as any) : undefined,
            renderHeader: column.renderHeader ? (params) => column.renderHeader!(params) : undefined,
            sortable: !column.isNotSortable,
            sortComperator: column.sortComperator ? (v1: any, v2: any, p1: any, p2: any) => column.sortComperator!(v1, v2, p1, p2) : undefined,
        }));

        // render with action menu column
        if (actionMenuItems?.length) {
            return [{
                field: 'id',
                headerName: ' ',
                width: 50,
                sortable: false,
                renderCell: (params) => (
                    <ActionMenu
                        tooltip={translations.custom_menu_label}
                        items={actionMenuItems!}
                        row={params.row as any}
                        edge={'start'}
                    />
                ),
            }, ...mainColumns];
        }

        return mainColumns;

    }, [config, actionMenuItems]);

    const tableConfigs = useSelector(getActorSettings).tableConfigs;
    const tableConfigSortBy = name && tableConfigs[name]?.sortBy;
    const tableConfigSortDirection = name && tableConfigs[name]?.sortDirection;
    const tableConfigRowsPerPage = name && tableConfigs[name]?.rowsPerPage;
    const [sortModel, setSortModel] = useState<GridSortModel>();
    const [pageSize, setPageSize] = useState(tableConfigRowsPerPage || _pageSize);
    const [page, setPage] = useState(0);

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handleSortModelChange = useCallback((model?: GridSortModel) => {
        setSortModel(model);
        setPage(0);

        // TODO: remove handleSorting and store sort model in agent settings (by table name or something)
        if (!name) {
            return;
        }

        // console.log(`UPDATE table ${name} with`, model && model[0]);
        // this dispatch starts an infinite loop somehow ?!
        // dispatch(updateActorSettings({
        //     tableConfigs: {
        //         ...tableConfigs,
        //         [name]: {
        //             ...tableConfigs[name],
        //             sortBy: model && model[0]?.field,
        //             sortDirection: model && (model[0]?.sort || undefined), // don't allow null here
        //         },
        //     },
        // }, true));
    }, [name]);

    useEffect(() => {
        if (!!sortModel || !tableConfigSortBy || !tableConfigSortDirection) {
            return;
        }

        // this is only called once, even with infinite loop ?!
        // console.log(`INIT sort settings for table ${name} with`, {
        //     sort: tableConfigSortDirection,
        //     field: tableConfigSortBy,
        // });

        setSortModel([{sort: tableConfigSortDirection || undefined, field: tableConfigSortBy}]);
    }, [sortModel, tableConfigSortBy, tableConfigSortDirection]);

    return (
        <CustomDataGrid
            loading={loading}
            showColumnRightBorder={false}
            columns={columns}
            rows={filteredRows || []}
            pageSize={(noPages || autoPageSize) ? undefined : pageSize}
            onPageSizeChange={(rows) => setPageSize(rows)}
            rowsPerPageOptions={(noPages || autoPageSize) ? undefined : rowsPerPageOptions}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            onPageChange={handlePageChange}
            page={page}
            autoPageSize={autoPageSize} // TODO: doesn't work by now (must be the container height)
            noPages={noPages}
            emptyMessage={contentEmptyMessage}
            {...rest}
        />
    );
};

export default TableList;
