import {FC} from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import {customColors} from '../../../theme/customColors';
import {MessengerIconProps} from '../MessengerIcon';

const WBIcon: FC<Omit<MessengerIconProps, 'messenger'>> = props => {
    const {tooltip, ...rest} = props;

    const icon = (
        <SvgIcon {...rest}>
            <g fill="none" fillRule="evenodd">
                <path
                    fill={customColors.messenger.WA}
                    d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Zm5.05,6.91A7.11,7.11,0,0,0,5.89,15.49l-1,3.68,3.76-1a7.15,7.15,0,0,0,3.39.87h0a7.11,7.11,0,0,0,5-12.13Zm-5,10.93h0A5.87,5.87,0,0,1,9,17l-.22-.12-2.23.58.6-2.18-.14-.22a5.89,5.89,0,0,1,5-9,5.91,5.91,0,0,1,5.9,5.9A5.91,5.91,0,0,1,12.05,17.85Zm3.23-4.42c-.17-.09-1-.52-1.21-.58s-.28-.09-.4.09-.46.57-.56.69-.21.14-.38,0a5,5,0,0,1-1.43-.88,5.44,5.44,0,0,1-1-1.23.26.26,0,0,1,.08-.36c.08-.08.18-.21.27-.32a1.38,1.38,0,0,0,.17-.29.34.34,0,0,0,0-.31c-.05-.09-.4-1-.55-1.32s-.29-.3-.4-.3H9.53a.63.63,0,0,0-.47.22,2,2,0,0,0-.62,1.48,3.45,3.45,0,0,0,.72,1.84,7.91,7.91,0,0,0,3,2.68c.43.18.76.29,1,.37a2.33,2.33,0,0,0,1.12.07,1.85,1.85,0,0,0,1.2-.84,1.42,1.42,0,0,0,.1-.85C15.58,13.56,15.46,13.51,15.28,13.43Z"
                />
            </g>
        </SvgIcon>
    );

    return tooltip ? <Tooltip title="WhatsApp">{icon}</Tooltip> : icon;
};

export default WBIcon;
