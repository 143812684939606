import {createSelector} from 'reselect';

import {IChannel, TChannelMessenger} from '../../definitions/channel/channel.definitions';
import {EMiaSettingsFormFields} from '../../definitions/mia/mia.definitions';
import {EMessenger} from '../../enums/messenger/EMessenger';
import {getSortedTags} from '../../utils/ticket/ticket.utils';
import {IState} from '../root.reducer';

export const getChannel = (state: IState) => state.channel;
export const getChannelId = (state: IState) => state.channel.id;
export const getMessenger = (state: IState) => state.channel.messenger;
export const getMessengerSettings = (state: IState) => state.channel.messengerSettings;
export const loading = (state: IState) => state.settings.messenger.setup_data.loading;
export const usesMia = (state: IState) => Boolean(state.channel.use_content);
export const usesSimpleChat = (state: IState) => !!state.channel.use_simple_chat;
export const usesShop = (state: IState) => !!state.channel.use_shop;
export const usesRCS = (state: IState) => !!state.channel.rcs_available;
export const usesBotBuilder = (state: IState) => !!state.channel.use_chatbot_mp;

const IDLE_TIMEOUT = 20;
const MULTIPLIER = 60 * 1000;

const _getMiaSettingsFormValues = (channel: IChannel) => ({
    [EMiaSettingsFormFields.LatestContent]: channel.content_pulled_all,
    [EMiaSettingsFormFields.ContentPullNumber]: channel.content_pull_num,
    [EMiaSettingsFormFields.ContentPullTime]: channel.content_pull_time,
    [EMiaSettingsFormFields.NotifyInstalled]: channel.notify_installed,
    [EMiaSettingsFormFields.MiaLogoffConfirmationText]: channel.goodbye_message,
    [EMiaSettingsFormFields.EveryWordStarts]: channel.every_word_starts,
    [EMiaSettingsFormFields.StartWord]: channel.startword,
    [EMiaSettingsFormFields.WelcomeMessageMia]: channel.welcomemessage_mia,
    [EMiaSettingsFormFields.WelcomeMessageMiaNotify]: channel.welcomemessage_mia_notify,
});

const _getMiaSettingForUserActivation = (channel: IChannel) => channel.every_word_starts;

const _getIdleTimeout = (channel: IChannel) => {
    if (channel?.ui_settings?.session_timeout) {
        return channel.ui_settings.session_timeout * MULTIPLIER;
    }
    return IDLE_TIMEOUT * MULTIPLIER;
};

const _getActiveMessenger = (messenger: TChannelMessenger): EMessenger[] => {
    const messengerKeys = Object.keys(messenger) as EMessenger[];
    const messengerValues = Object.values(messenger);
    return messengerKeys.filter((item: string, index: number) => messengerValues[index] === 1);
};

const _isLinkTrackingActive = (channel: IChannel) => {
    return Boolean(channel.enable_urltracking);
};

export const isLinkTrackingActive = createSelector([getChannel], _isLinkTrackingActive);

const _areTicketsActive = (channel: IChannel, usesSimpleChat: boolean) => {
    return !usesSimpleChat && Boolean(channel.use_tickets);
};

export const usesLiveChat = (state: IState) => Boolean(state.channel.messenger.LC);

export const usesTicketSystem = createSelector([getChannel, usesSimpleChat], _areTicketsActive);

export const getMiaSettingsFormValues = createSelector([getChannel], _getMiaSettingsFormValues);

export const getMiaSettingForUserActivation = createSelector([getChannel], _getMiaSettingForUserActivation);

export const getIdleTimeout = createSelector([getChannel], _getIdleTimeout);

const _getAllTags = (channel: IChannel) => getSortedTags(Object.values(channel.labels || {}));

export const getAllTags = createSelector([getChannel], _getAllTags);

export const getActiveMessenger = createSelector([getMessenger], _getActiveMessenger);
