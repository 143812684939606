import {FC} from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import {StandardTextFieldProps} from '@material-ui/core/TextField/TextField';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';

import CustomTextField from '../CustomTextField/CustomTextField';

const CustomDatePicker: FC<StandardTextFieldProps> = props => (
    <CustomTextField
        type="date"
        InputLabelProps={{shrink: true}}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <AccessAlarmIcon />
                </InputAdornment>
            ),
        }}
        {...props}
    />
);

export default CustomDatePicker;
