import Paper from '@material-ui/core/Paper';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import {FC, ReactNode, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import CSSTransition from 'react-transition-group/CSSTransition';
import translations from '../../providers/TranslationProvider/translations';
import {URL_CONTACT, URL_FAQ, URL_IMPRINT} from '../../config';
import {translateSlug} from '../../utils/translation/translation.utils';
import logoColoured from './logo_coloured.svg';
import logoDark from '../../resources/img/logo_dark.svg';
import logoLight from "../../resources/img/logo_light.svg";
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {useTheme} from '@material-ui/core';

const breakpointSmallHeight = '@media (max-height: 690px)';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            userSelect: 'none',
            webkitUserSelect: 'none',
            position: 'fixed',
            zIndex: 1,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            minWidth: 0,
            minHeight: 0,
            justifyContent: 'center',
            alignItems: 'center',

            transition: 'background-color 1s ease-out',
            overflowY: 'scroll',

            [theme.breakpoints.up('md')]: {
                backgroundColor: theme.palette.background.default,
                transition: 'background-color 1s ease-in',
                overflowY: 'hidden',
            },
        },
        contentContainer: {
            display: 'flex',
            flexFlow: 'row nowrap',
            justifyContent: 'center',
            maxHeight: '100%',

            [theme.breakpoints.up('md')]: {
                width: '100%',
            },
        },
        mainContent: {
            flex: 1,
            height: '100%',

            [theme.breakpoints.up('md')]: {
                maxWidth: theme.spacing(69),
                minWidth: theme.spacing(45),
            },
        },
        paper: {
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.default,

            [theme.breakpoints.up('md')]: {
                backgroundColor: theme.palette.type === 'dark' ? '#424242' : '#fff',
                overflowY: 'scroll',
                maxHeight: '95vh',
            },
        },
        container: {
            margin: '40px auto 8px auto',
            maxWidth: 296,

            [breakpointSmallHeight]: {
                marginTop: theme.spacing(2),
            },
        },
        section: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),

            [breakpointSmallHeight]: {
                marginBottom: theme.spacing(4),
            },
        },
        backgroundLeft: {
            flex: 1,
            position: 'relative',
            zIndex: -1,
            overflow: 'hidden',
            maxWidth: theme.spacing(45),
            marginLeft: 20,

            '& > img': {
                width: '200%',
                position: 'absolute',
                right: '-100%',
                top: '25%',
            },
        },
        backgroundRight: {
            flex: 1,
            position: 'relative',
            zIndex: -1,
            overflow: 'hidden',
            maxWidth: theme.spacing(45),

            '& > img': {
                width: '200%',
                position: 'absolute',
                left: 'calc(-100% - 20px)',
                top: '20%',
            },
        },
        logoContainer: {
            margin: `${theme.spacing(2)}px 0 0 0`,

            '& img': {
                display: "block",
            },
        },
        logo: {
            width: '100%',
            height: 'auto',
        },
        footerRoot: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(1),

            [breakpointSmallHeight]: {
                marginTop: 0,
            },
        },
        linkContainer: {
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'center',
            opacity: 0.6,

            '& > div > button': {
                display: 'block',
                margin: '8px auto',
            },

            '& a': {
                color: 'inherit',
                textDecoration: 'none',

                '&:hover': {
                    color: theme.palette.primary.main,
                },
            },
        },
        linksSpacer: {
            margin: `0 ${theme.spacing(0.5)}px`,
        },
        copyright: {
            alignSelf: 'center',
        },
        metaLink: {
            textAlign: 'right',
            margin: `${theme.spacing(1)}px 1px`,

            '& > a': {
                color: 'inherit',
                textDecoration: 'none',
            },
        },
        transition: {
            '&-enter': {
                opacity: 0,
                transform: 'scale(0.7)',
            },
            '&-enter-active': {
                opacity: 1,
                transition: 'opacity 200ms, transform 200ms',
                transform: 'scale(1)',
            },
            '&-exit': {
                opacity: 1,
                transform: 'scale(0.7)',
            },
            '&-exit-active': {
                opacity: 0,
                transition: 'opacity 200ms, transform 200ms',
                transform: 'scale(1)',
            },
        },
    }),
);

interface ITypographyLink {
    label: string;
    href: string;
}

const TypographyLink = ({label, ...props}: ITypographyLink) => (
    <Typography variant='caption' component='a' children={label} {...props} />
);

interface IExternalPageProps {
    backGround?: string; // background will be split into two halves to be shown on the left- and right-hand side of the central content
    header?: boolean;
    footer?: boolean;
    metaLink?: string;
    metaLinkUrl?: string;
    snackBar?: ReactNode;
}

const ExternalPage: FC<IExternalPageProps> = (
    {
        children,
        backGround,
        footer,
        header,
        metaLink,
        metaLinkUrl,
    },
) => {
    const classes = useStyles();
    const rootRef = useRef<HTMLDivElement>(null);
    const [mounted, setMounted] = useState(false);
    // mobile
    const screenWidthBiggerSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const hasMinHeight = useMediaQuery('@media (min-height: 691px)');

    useEffect(() => {
        // Preload image before CSSTransition will mount the element.
        // If we don't mount the element, it will flicker up before animating in.
        // new Image().src = logo;

        // give a little time for the image to appear (if not loaded then, dont care..)
        const timeout = setTimeout(() => {
            setMounted(true);
        }, 50);

        return () => clearTimeout(timeout);
    }, []);

    const showIllustration = backGround && screenWidthBiggerSm;

    const theme = useTheme();
    const lightMode = theme.palette.type !== 'dark';

    return (
        <>
            <div className={classes.root} ref={rootRef}>
                <div className={classes.contentContainer}>
                    {showIllustration && (
                        <div className={classes.backgroundLeft}>
                            <img alt='background illustration' src={backGround}/>
                        </div>
                    )}
                    <div className={classes.mainContent}>
                        <CSSTransition in={mounted} timeout={1000} classNames={classes.transition} mountOnEnter
                                       unmountOnExit>
                            <Paper className={classes.paper} elevation={screenWidthBiggerSm ? 14 : 0}>
                                <div className={classes.container}>
                                    {header && (
                                        <div className={classes.logoContainer}>
                                            {!hasMinHeight || !lightMode ? (
                                                <img src={lightMode ? logoDark : logoLight} alt='MessengerPeople logo'/>
                                            ) : (
                                                // TODO: add light image version
                                                <img src={logoColoured} alt='MessengerPeople logo' className={classes.logo}/>
                                            )}
                                        </div>
                                    )}
                                    <div className={classes.section}>
                                        {children}
                                        {metaLink && metaLinkUrl && (
                                            <Typography
                                                variant='caption'
                                                className={classes.metaLink}
                                                component='div'
                                                children={<Link to={metaLinkUrl} children={metaLink}/>}
                                            />
                                        )}
                                    </div>
                                    {!!footer && (
                                        <div>
                                            <div className={classes.footerRoot}>
                                                <Typography variant='caption' className={classes.copyright}>
                                                    {`© ${translateSlug('copyright_year', new Date().getFullYear().toString())}`}
                                                </Typography>
                                                <div className={classes.linkContainer}>
                                                    <TypographyLink href={URL_CONTACT} label={translations.contact}/>
                                                    <Typography className={classes.linksSpacer} variant='caption'>
                                                        |
                                                    </Typography>
                                                    <TypographyLink href={URL_FAQ} label={translations.faq}/>
                                                    <Typography className={classes.linksSpacer} variant='caption'>
                                                        |
                                                    </Typography>
                                                    <TypographyLink href={URL_IMPRINT} label={translations.imprint}/>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Paper>
                        </CSSTransition>
                    </div>
                    {showIllustration && (
                        <div className={classes.backgroundRight}>
                            <img alt='background illustration' src={backGround}/>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ExternalPage;
