import {useEffect} from "react";
import {useTypedSelector} from "../../utils/hooks/useTypedSelector";
import {QUERY_PARAM_GO_TO_CHANNEL} from "../../definitions/router/router.definitions";
import {getChannelId} from "../../redux/channel/channel.selectors";
import {useDispatch, useSelector} from "react-redux";
import {changeChannel} from "../../redux/actor/actor.actions";
import {useLocation} from "react-router-dom";


export const UrlSearchParamsHandler = () => {
    const dispatch = useDispatch();

    const location = useLocation();
    const channel = useSelector(getChannelId);
    const requestedChannel = useTypedSelector(state => Number(state.ui.urlSearchParams.get(QUERY_PARAM_GO_TO_CHANNEL)));

    // redirect to another channel
    useEffect(() => {
        if (channel && requestedChannel && (requestedChannel !== channel)) {
            dispatch(changeChannel(requestedChannel, location));
        }
    }, [channel, dispatch, location, requestedChannel]);

    return null;
};

export default UrlSearchParamsHandler;
