import {FC} from 'react';

import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {ChatType} from '../../utils/ticket/ticket.utils';
import AudioContent from './Content/AudioContent';
import ContactContent from './Content/ContactContent';
import DocumentContent from './Content/DocumentContent';
import ImageContent from './Content/ImageContent';
import LocationContent from './Content/LocationContent';
import VideoContent from './Content/VideoContent';
import {IMessageContentItem} from "../../definitions/chat/chat.definitions";

export const useStyles = makeStyles(
    createStyles({
        image: isQuote => ({
            height: isQuote ? 50 : 'auto',
        }),
    }),
);

interface MessageMediaProps extends IMessageContentItem {
    searchTerm?: string;
    isQuote?: boolean;
}

const MessageMedia: FC<MessageMediaProps> = props => {
    const {media, chattime, chattype, searchTerm = '', isQuote = false} = props;

    const classes = useStyles(isQuote);

    if (!media) {
        return null;
    }

    if (typeof media === 'string') {
        switch (chattype) {
            case ChatType.IMAGE:
                return <ImageContent media={media} imageClassName={classes.image}/>;
            case ChatType.AUDIO:
                return <AudioContent media={media}/>;
            case ChatType.VIDEO:
                return <VideoContent media={media} chatTime={chattime}/>;
            case ChatType.DOCS:
            case ChatType.DOCUMENT:
                return <DocumentContent media={media}/>;
            case ChatType.CONTACT:
                return <ContactContent media={media} searchTerm={searchTerm}/>;
            default:
                return null;
        }
    }

    if (chattype === ChatType.LOCATION) {
        return <LocationContent media={media}/>;
    }

    return null;
};

MessageMedia.displayName = 'MessageMedia';

export default MessageMedia;
