export const UNSET_PRODUCT_ID = 'UNSET_PRODUCT_ID';

export const GET_SHOPPING_CART = 'GET_SHOPPING_CART';

export const GET_SHOPPING_CART_FULFILLED = 'GET_SHOPPING_CART_FULFILLED';

export const DELETE_SHOPPING_CART_ITEM = 'DELETE_SHOPPING_CART_ITEM';
export const DELETE_SHOPPING_CART_ITEM_PENDING = 'DELETE_SHOPPING_CART_ITEM_PENDING';


export const UPDATE_SHOPPING_CART = 'UPDATE_SHOPPING_CART';
export const UPDATE_SHOPPING_CART_PENDING = 'UPDATE_SHOPPING_CART_PENDING';


export const GET_SHOPPING_ORDERS = 'GET_SHOPPING_ORDERS';
export const GET_SHOPPING_ORDERS_FULFILLED = 'GET_SHOPPING_ORDERS_FULFILLED';
