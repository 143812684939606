import {Reducer} from 'redux';
import {CHANNEL_FEATURES_PUT_FULFILLED} from '../settings/channelFeatures/channelFeatures.types';
import {ACTOR_GET_PROFILE_FULFILLED} from "../actor/actor.types";

export interface IFeaturesState {
    moodDetection?: boolean;
}

const initialState: IFeaturesState = {};

export const featuresReducer: Reducer<IFeaturesState> = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case ACTOR_GET_PROFILE_FULFILLED: {
            const {activeChannel, channels} = payload;
            const channelModules = (activeChannel && channels?.[activeChannel]?.modules) || [];
            return {
                ...state,
                moodDetection: channelModules.includes('mood'),
                translation: channelModules.includes('translation'),
            };
        }
        case CHANNEL_FEATURES_PUT_FULFILLED:
            return state;
        default:
            return state;
    }
};
