import {useEffect, useState} from "react";
import {getTokenFromStorage, LOCAL_STORAGE_TOKEN_KEY} from "../storage/storage.utils";

// handles token updates in localStorage
const useTokenFromStorage = () => {
    const [token, setToken] = useState<string>();

    if (getTokenFromStorage() !== token) {
        setToken(getTokenFromStorage());
    }

    // detect token changes from other tabs
    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key !== LOCAL_STORAGE_TOKEN_KEY) {
                return;
            }

            const oldToken = event.oldValue;
            const newToken = event.newValue;

            // if the token changed, dispatch something to react on it!
            if (oldToken && newToken && newToken !== oldToken) {
                setToken(newToken);
            }
        };

        global.addEventListener('storage', handleStorageChange);

        return () => global.removeEventListener('storage', handleStorageChange);

    }, []);

    return token;
};

export default useTokenFromStorage;

