import {ChangeEvent, FC, useState} from 'react';

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';

import CustomIconButton from '../../../components/CustomIconButton/CustomIconButton';
import {ITicket} from '../../../definitions/tickets/tickets.definitions';
import {EIcons} from '../../../enums/icons/EIcons';
import {EBaseRoutes} from '../../../enums/routes/ERoutes';
import {EBreakpoints} from '../../../enums/ui/EBreakpoints';
import {IState} from '../../../redux/root.reducer';
import {getTicketStatsByProfileId} from '../../../redux/tickets/tickets.selectors';
import {changeDrawerState} from '../../../redux/ui/ui.actions';
import {getDrawerIsOpen} from '../../../redux/ui/ui.selectors';
import {getTicketRoute} from '../../../utils/routes/routes.utils';
import {getIsTicketPostponed, sortTicketsByStatusAndTimeSinceLastMessage} from '../../../utils/ticket/ticket.utils';
import ChipContainer from './ChipContainer/ChipContainer';
import PopOver from './PopOver/PopOver';
import TicketCounter from './TicketCounter/TicketCounter';
import TicketPreview from './TicketPreview/TicketPreview';
import {getActiveConversationId} from '../../../redux/chatPage/chatPage.selectors';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
            [theme.breakpoints.up('sm')]: {
                padding: `0 ${theme.spacing(2)}px 0 96px`,
            },

            [theme.breakpoints.down('xs')]: {
                padding: `0 ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`,
            },
        },
        toolbar: {
            backgroundColor: theme.palette.background.paper,
        },
        mobileToolbar: {
            backgroundColor: theme.palette.background.paper,
            paddingRight: theme.spacing(1),
        },
        gridContainer: {
            minWidth: 0,
        },
    }),
);

interface IHeaderContent {
    ticketStats: any;
    mostCriticalTicket: number;
    actorId: number;
    ticketChipsLayout: any;
    ticketOrVirtualId: any;
    ticketsToShow: any;
    overdueTime: any;
    criticalTime: any;
    handleClick: any;
    showPopover: any;
    hidePopover: any;
    isMobile: any;
    ticketsForPreview: any;
}

const HeaderContent: FC<IHeaderContent> = (
    {
        ticketStats,
        mostCriticalTicket,
        actorId,
        ticketChipsLayout,
        ticketOrVirtualId,
        ticketsToShow,
        overdueTime,
        criticalTime,
        handleClick,
        showPopover,
        hidePopover,
        isMobile,
        ticketsForPreview,
    },
) => (
    <>
        <TicketCounter
            isMobile={isMobile}
            ticketStats={ticketStats}
            mostCriticalTicketId={mostCriticalTicket}
            agentId={actorId}
        />
        {Boolean(ticketChipsLayout.maxTicketsToShow) && (
            <ChipContainer
                activeTicketId={ticketOrVirtualId}
                tickets={ticketsToShow}
                overdueTime={overdueTime}
                criticalTime={criticalTime}
                layout={ticketChipsLayout}
                handleClick={handleClick}
                handlePopoverOpen={showPopover}
                handlePopoverClose={hidePopover}
                isMobile={isMobile}
            />
        )}
        {Boolean(ticketsForPreview?.length) && (
            <TicketPreview
                overdueTime={overdueTime}
                criticalTime={criticalTime}
                ticketsForPreview={ticketsForPreview}
                activeTicketId={ticketOrVirtualId}
            />
        )}
    </>
);

const HeaderBar = () => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);
    const [popoverTicket, setPopoverTicket] = useState<ITicket>();

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down(EBreakpoints.XS));
    const isUpSm = useMediaQuery((theme: Theme) => theme.breakpoints.up(EBreakpoints.SM));
    const isUpMd = useMediaQuery((theme: Theme) => theme.breakpoints.up(EBreakpoints.MD));
    const isUpLg = useMediaQuery((theme: Theme) => theme.breakpoints.up(EBreakpoints.LG));
    const isUpXl = useMediaQuery((theme: Theme) => theme.breakpoints.up(EBreakpoints.XL));

    const actorId = useSelector((state: IState) => state.actor.id);
    const actorTickets = useSelector((state: IState) => state.actor.tickets.filter(ticket => !getIsTicketPostponed(ticket.postponed)));
    const chatPageTicketId = useSelector(getActiveConversationId);
    const ticketStats = useSelector((state: IState) => getTicketStatsByProfileId(state));
    const overdueTime = useSelector((state: IState) => state.channel.ticket_overdue_time || 0);
    const criticalTime = useSelector((state: IState) => state.channel.ticket_critical_time || 0);
    const drawerIsOpen = useSelector((state: IState) => getDrawerIsOpen(state));

    const ticketsByPriority = sortTicketsByStatusAndTimeSinceLastMessage(actorTickets);
    const mostCriticalTicket = ticketsByPriority && ticketsByPriority[0]?.id;
    const ticketOrVirtualId = +location.pathname.replace(EBaseRoutes.Conversation.replace(':chatId?', ''), '');
    const appBarPosition = isMobile ? 'fixed' : 'absolute';

    const getTicketsToShow = (maxTicketsToShow: number) => {
        if (!chatPageTicketId || maxTicketsToShow > 1) {
            return actorTickets.slice(0, maxTicketsToShow);
        }

        return actorTickets.filter(ticket => ticket.id === chatPageTicketId);
    };

    const getTicketsForPreview = (maxTicketsToShow: number) => {
        if (!chatPageTicketId || maxTicketsToShow > 1) {
            return actorTickets.slice(maxTicketsToShow);
        }

        return actorTickets.filter(ticket => ticket.id !== chatPageTicketId);
    };

    const getTicketChipsLayout = () => {
        // first XS layout
        const layout = {breakpoint: EBreakpoints.XS, cells: 5, maxTicketsToShow: 1};

        if (isUpXl) {
            layout.breakpoint = EBreakpoints.XL;
            layout.cells = 1;
            layout.maxTicketsToShow = 8;
            return layout;
        }

        if (isUpLg) {
            layout.breakpoint = EBreakpoints.LG;
            layout.cells = 1;
            layout.maxTicketsToShow = 8;
            return layout;
        }

        if (isUpMd) {
            layout.breakpoint = EBreakpoints.MD;
            layout.cells = 2;
            layout.maxTicketsToShow = 3;
            return layout;
        }

        if (isUpSm) {
            layout.breakpoint = EBreakpoints.SM;
            layout.cells = 4;
            layout.maxTicketsToShow = 2;
            return layout;
        }

        return layout;
    };

    const ticketChipsLayout = getTicketChipsLayout();
    const ticketsToShow = getTicketsToShow(ticketChipsLayout.maxTicketsToShow);
    const ticketsForPreview = getTicketsForPreview(ticketChipsLayout.maxTicketsToShow);

    const handleClick = (ticket: ITicket) => {
        history.push(getTicketRoute(ticket.id));
    };

    const showPopover = (event: ChangeEvent<HTMLInputElement>, ticket: ITicket) => {
        if (!event.target || event.currentTarget === anchorEl) {
            return;
        }

        setAnchorEl(event.currentTarget);
        setPopoverTicket(ticket);
    };

    const hidePopover = () => {
        setAnchorEl(null);
        setPopoverTicket(undefined);
    };

    const handleOpenDrawer = () => {
        dispatch(changeDrawerState(!drawerIsOpen));
    };


    return (
        <AppBar elevation={1} position={appBarPosition} className={classes.root}>
            <Toolbar
                disableGutters
                variant='dense' // ( ☉ω☉∩ )
                className={classNames(!isMobile && classes.toolbar, isMobile && classes.mobileToolbar)}
            >
                {isMobile && <CustomIconButton onClick={handleOpenDrawer} icon={EIcons.MENU} />}
                {isMobile && <HeaderContent {...{
                    ticketStats,
                    mostCriticalTicket,
                    actorId,
                    ticketChipsLayout,
                    ticketOrVirtualId,
                    ticketsToShow,
                    overdueTime,
                    criticalTime,
                    handleClick,
                    showPopover,
                    hidePopover,
                    isMobile,
                    ticketsForPreview,
                }} />}
                {!isMobile && (
                    <Grid container spacing={2} direction='row' alignItems='center' className={classes.gridContainer}>
                        <HeaderContent {...{
                            ticketStats,
                            mostCriticalTicket,
                            actorId,
                            ticketChipsLayout,
                            ticketOrVirtualId,
                            ticketsToShow,
                            overdueTime,
                            criticalTime,
                            handleClick,
                            showPopover,
                            hidePopover,
                            isMobile,
                            ticketsForPreview,
                        }} />
                    </Grid>
                )}
                <PopOver ticketId={popoverTicket?.id} anchorEl={anchorEl} throttle={3} />
            </Toolbar>
        </AppBar>
    );
};

export default HeaderBar;
