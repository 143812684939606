import {getTargetingCountRequest, getTargetingRequest} from '../../../requests/miaRequest';
import {IGetTargetingParameters} from '../content/content.actions';
import {MIA_GET_TARGETING, MIA_GET_TARGETING_COUNT} from "./targeting.types";


export const getTargeting = (data: IGetTargetingParameters) => ({
    type: MIA_GET_TARGETING,
    payload: getTargetingRequest(data),
});

export const getTargetingCount = (id: number) => ({
    type: MIA_GET_TARGETING_COUNT,
    payload: getTargetingCountRequest(id),
    meta: {id},
});
