
function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill="#272727"
                fillRule="evenodd"
                d="M25 11h-8c-5.523 0-10 4.477-10 10v10h18c5.523 0 10-4.477 10-10s-4.477-10-10-10zm-8-2C10.373 9 5 14.373 5 21v12h20c6.627 0 12-5.373 12-12S31.627 9 25 9h-8z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#272727"
                d="M16 21a2 2 0 11-4 0 2 2 0 014 0zM23 21a2 2 0 11-4 0 2 2 0 014 0zM30 21a2 2 0 11-4 0 2 2 0 014 0z"
            ></path>
            <path
                fill="#007171"
                d="M23 39a11.97 11.97 0 01-8.944-4h2.943A9.956 9.956 0 0023 37h18V26.922a9.91 9.91 0 00-4.198-8.106 11.91 11.91 0 00-.878-2.79C40.094 17.883 43 22.063 43 26.922V39H23z"
            ></path>
        </svg>
    );
}

export default Icon;
