import {v4 as uuid} from 'uuid';

import {NotificationDefinitions} from '../../definitions/notification/notification.definitions';
import {ENotificationLevel} from '../../enums/notifications/ENotificationLevel';

export const createSnackNotification = (
    message: string,
    level: ENotificationLevel,
    persist = false,
    id?: string | number,
): NotificationDefinitions => ({
    id: id || uuid(),
    message,
    level,
    persist,
});

export const getErrorFromPayload = (payload: any): string | undefined =>
    payload?.data?.error ||
    payload?.data?.reason ||
    payload?.response?.data?.reason ||
    payload?.response?.statusText ||
    payload?.message;
