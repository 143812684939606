import {FC, useCallback, useState} from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import {useDispatch, useSelector} from 'react-redux';

import {EIcons} from '../../enums/icons/EIcons';
import {sendWbWelcomeNotificationAndNotify} from '../../redux/onboarding/onboarding.actions';
import {IState} from '../../redux/root.reducer';
import CustomIconButton from '../CustomIconButton/CustomIconButton';
import CustomTextField from '../CustomInputs/CustomTextField/CustomTextField';
import FormContent from '../FormContent/FormContent';
import FormRow from '../FormRow/FormRow';
import translations from '../../providers/TranslationProvider/translations';
import Twemoji from '../Twemoji/Twemoji';

interface WelcomeNotificationFormProps {}

export const WelcomeNotificationForm: FC<WelcomeNotificationFormProps> = () => {
    const dispatch = useDispatch();

    const [welcomeMessageNumber, setWelcomeMessageNumber] = useState('');
    const sendingWelcomeMessage = useSelector((state: IState) => state.onboarding.misc.sendingWelcomeNotificationWB);

    const handleNumberChange = useCallback(
        (event: any) => {
            setWelcomeMessageNumber(event.target.value);
        },
        [],
    );

    const handleSubmit = useCallback(() => {
        if (!welcomeMessageNumber) {
            return;
        }
        dispatch(sendWbWelcomeNotificationAndNotify(welcomeMessageNumber));
    }, [dispatch, welcomeMessageNumber]);

    return (
        <>
            <FormContent>
                <Typography gutterBottom variant="body2" component="div">
                    <Twemoji>{translations.send_whatsapp_welcome_notification_text}</Twemoji>
                </Typography>
            </FormContent>
            <FormRow>
                <CustomTextField
                    onChange={handleNumberChange}
                    fullWidth
                    placeholder="+491234567890"
                    disabled={sendingWelcomeMessage}
                    InputProps={{
                        name: 'welcome_message_number',
                        endAdornment: sendingWelcomeMessage ? (
                            <InputAdornment position="end">
                                <CircularProgress size={20} />
                            </InputAdornment>
                        ) : (
                            <CustomIconButton
                                edge={"end"}
                                icon={EIcons.SEND}
                                disabled={!welcomeMessageNumber}
                                onClick={handleSubmit}
                            />
                        ),
                    }}
                />
            </FormRow>
        </>
    );
};

export default WelcomeNotificationForm;
