export const UI_CHANGE_LOCKER = 'UI_CHANGE_LOCKER';
export const UI_CHANGE_DRAWER_STATE = 'UI_CHANGE_DRAWER_STATE ';
export const UI_SET_CONFIRM_DIALOG = 'UI_SET_CONFIRM_DIALOG';
export const UI_CHANGE_SERVICE_UNAVAILABLE = 'UI_CHANGE_SERVICE_UNAVAILABLE';
export const UI_HIDE_MENU = 'UI_HIDE_MENU';
export const UI_SHOW_MENU = 'UI_SHOW_MENU';
export const UI_HIDE_CONTENT = 'UI_HIDE_CONTENT';
export const UI_SHOW_CONTENT = 'UI_SHOW_CONTENT';
export const UI_SHOW_MIA_FORM = 'UI_SHOW_MIA_FORM';
export const UI_SHOW_MIA_PANEL = 'UI_SHOW_MIA_PANEL';
export const UI_UPDATE_AVAILABLE = 'UI_UPDATE_AVAILABLE';
export const UI_TICKET_PAGE_TOGGLE_PANELS = 'TICKET_TOGGLE_PANELS';
export const UI_TICKET_PAGE_TOGGLE_CHAT = 'TICKET_TOGGLE_CHAT';
export const UI_CHAT_PAGE_SET_DRAGGED_PANEL = 'UI_CHAT_PAGE_SET_DRAGGED_PANEL';
export const UI_CHAT_PAGE_SET_CHAT_TO_DELETE = 'UI_CHAT_PAGE_SET_MESSAGE_TO_DELETE';
export const UI_UPDATE_URL_SEARCH_PARAMS = 'UI_UPDATE_URL_SEARCH_PARAMS';
export const TOGGLE_SSO = 'TOGGLE_SSO';
