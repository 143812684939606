import {Reducer} from 'redux';
import {
    IGetMiaContentActionFulfilled,
    IGetMiaContentActionPending,
    IGetMiaStatisticActionFulfilled,
    IGetMiaStatisticActionPending,
    IGetMiaTargetingActionFulfilled,
    IGetMiaTargetingActionPending,
} from '../../../definitions/mia/mia.actions.definitions';
import {IMiaRequestState} from '../../../definitions/mia/mia.definitions';
import {EPanelDataTypes} from '../../../enums/mia/EPanelDataTypes';
import {MIA_GET_TARGETING_FULFILLED, MIA_GET_TARGETING_PENDING} from "../targeting/targeting.types";
import {MIA_GET_STATISTICS_FULFILLED, MIA_GET_STATISTICS_PENDING} from "../statistic/statistic.types";
import {MIA_GET_CONTENT_FULFILLED, MIA_GET_CONTENT_PENDING} from "../content/content.types";

export const miaRequestInitState: IMiaRequestState = {
    loading: {
        statistic: false,
        content: {
            [EPanelDataTypes.Scheduled]: false,
            [EPanelDataTypes.Draft]: false,
            [EPanelDataTypes.Published]: false,
            [EPanelDataTypes.Today]: false,
        },
        targeting: false,
    },
};

export type TMiaRequestActions =
    | IGetMiaContentActionPending
    | IGetMiaTargetingActionPending
    | IGetMiaTargetingActionFulfilled
    | IGetMiaStatisticActionFulfilled
    | IGetMiaContentActionFulfilled
    | IGetMiaStatisticActionPending;

export const requestReducer: Reducer<IMiaRequestState, TMiaRequestActions> = (state = miaRequestInitState, action) => {
    switch (action.type) {
        case MIA_GET_TARGETING_FULFILLED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    targeting: false,
                },
            };
        case MIA_GET_CONTENT_FULFILLED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    content: {
                        ...state.loading.content,
                        [action.meta.type]: false,
                    },
                },
            };
        case MIA_GET_STATISTICS_FULFILLED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    statistic: false,
                },
            };
        case MIA_GET_TARGETING_PENDING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    targeting: true,
                },
            };
        case MIA_GET_STATISTICS_PENDING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    statistic: true,
                },
            };
        case MIA_GET_CONTENT_PENDING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    content: {
                        ...state.loading.content,
                        [action.meta.type]: true,
                    },
                },
            };
        default:
            return state;
    }
};
