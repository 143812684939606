import {ReactText} from 'react';

import {EChartAgentType} from '../../enums/chart/EChartAgentType';
import {EChartAutomationKpi} from '../../enums/chart/EChartAutomationKpi';
import {EChartChatAction} from '../../enums/chart/EChartChatAction';
import {EChartChatKpi, TChartKpiAll} from '../../enums/chart/EChartChatKpi';
import {EChartChatSender} from '../../enums/chart/EChartChatSender';
import {EChartInterval} from '../../enums/chart/EChartInterval';
import {EChartPeriod, TChartInvoicePeriod} from '../../enums/chart/EChartPeriod';
import {EChartSource} from '../../enums/chart/EChartSource';
import {EChartTicketKpi} from '../../enums/chart/EChartTicketKpi';
import {EChartType} from '../../enums/chart/EChartType';
import {EMessenger} from '../../enums/messenger/EMessenger';
import {TPartialRecord} from '../generic/generic.definitions';

export interface IChart {
    from: number;
    to: number;
    period: EChartPeriod;
    interval?: EChartInterval;
}

export interface ICustomChart {
    id: string; // this ID is created client side
    name: string;
    period: EChartPeriod;
    chart_type: EChartType;
    accumulated: boolean;
    source: EChartSource;
    interval: EChartInterval;
    from?: number;
    to?: number;
    kpi?: EChartChatKpi | EChartTicketKpi;
    position?: number; //  obsolete but necessary for legacy reasons
    chat_action?: EChartChatAction;
    chat_sender?: EChartChatSender;
    ticket_type?: EChartTicketKpi;
}

export interface ICustomChartRequest {
    id: string;
    from: number;
    to: number;
    interval: EChartInterval;
    source: EChartSource;
    kpi?: EChartChatKpi | EChartTicketKpi;
}

interface IMauStatisticsRangeRequest {
    from: number;
    to: number;
    by_messenger: boolean;
}

interface IMauStatisticsPeriodRequest {
    period: TChartInvoicePeriod;
    by_messenger: boolean;
}

export interface IPeriod {
    from: number;
    to: number;
}

export interface IStatisticRequestParameters extends IPeriod {
    interval?: EChartInterval;
}

export type TMauStatisticsRequest = IMauStatisticsPeriodRequest | IMauStatisticsRangeRequest;

export interface IStatisticsAgentsRequestParams extends IPeriod {
    agent_id: number;
}

export interface IStatisticsMiaUsersRequestParams {
    start: number;
    end: number;
    days: number;
    output_format?: 'csv' | 'json';
}

export interface IStatisticsChart {
    timestamp: number;
    value: number;
    accumulatedValue: number;
}

export interface IStatisticsGeneralData {
    [x: string]: number;
}

export enum EPeriodDataType {
    ChatsDate = 'chatsDate',
    TicketsDate = 'ticketsDate',
    ChatbotsDate = 'chatbotsDate',
    MiaUserDate = 'miaUserDate',
}

export interface IPeriodData extends IPeriod {
    period: EChartPeriod;
    type?: EPeriodDataType;
}

export interface IPeriodDataWithDetails extends IPeriodData {
    details?: boolean;
}

export interface IChartInfo {
    isCollapsed: boolean;
    interval?: EChartInterval;
}

export interface IInvoicePeriodData extends IPeriod {
    period: TChartInvoicePeriod;
}

export interface IOverviewChartData {
    translation: string;
    value?: ReactText;
    description?: string;
    valueClickHandler?: () => void;
    valueTooltip?: string;
}

export interface IStatisticsChats {
    total_sent_messages: number;
    total_sent_messages_in_ticket: number;
    total_received_messages: number;
    total_received_messages_in_ticket: number;
    average_chats_per_ticket: number;
    average_outgoing_chats_per_ticket: number;
    average_outgoing_agent_chats_per_ticket: number;
    average_outgoing_bot_chats_per_ticket: number;
    average_incoming_chats_per_ticket: number;
    total_sent_WA_notifications: number;
    total_bot_answered_messages: number;
    isLoading: boolean;
}

export interface IStatisticsTickets {
    total_opened_tickets: number;
    total_closed_tickets: number;
    average_open_tickets: number;
    total_forwardings: number;
    average_forwardings_per_ticket: number;
    average_involved_agents_per_ticket: number;
    average_duration_until_first_assignment: number;
    average_duration_until_ticket_answered: number;
    average_duration_until_initial_agent_reaction: number;
    average_duration_until_followup_agent_reactions: number;
    average_duration_until_user_reaction: number;
    average_duration_until_ticket_closed: number;
    average_chats_when_ticket_closed: number;
    isLoading: boolean;
}

export interface IStatisticsChatbots {
    totalCount: number;
    successCount: number;
    searches: {
        [x: string]: number;
    };
    isLoading: boolean;
}

export interface IMauPerPeriod extends TPartialRecord<EMessenger, number> {
    total: number;
}

export interface IStatisticsMauType {
    mia: IMauPerPeriod;
    chat: IMauPerPeriod;
    all: IMauPerPeriod;
}

export interface IStatisticsMau {
    [timestamp: number]: IStatisticsMauType;
}

export interface IStatisticsMia {
    mau: IStatisticsMau;
    isLoading: boolean;
}

export interface IStatisticsCustomChart {
    id: string;
    stats: {
        [x: string]: any;
    };
    isLoading: boolean;
}

export interface IStatisticsBlacklistAction {
    chats?: TChartKpiAll[];
    tickets?: EChartTicketKpi[];
}

export interface IStatisticsBlackList {
    interval?: EChartInterval[];
    action?: IStatisticsBlacklistAction;
    isLoading: boolean;
    loaded: boolean;
}

export interface IAgentStats {
    [id: number]: Record<EChartAgentType, number>;
}

export interface INotificationsStats {
    template: string;
    total: number;
    total_sent: number;
    total_failed: number;
    total_undelivered: number;
    total_delivered: number;
    total_read: number;
}

export interface IUserStats {
    timestamp: number;
    day: string;
    user: {
        started: number;
        stopped: number;
        active: number;
    };
}

export interface ILabelStatsItem {
    id: number;
    label: string;
    color: string;
    count: number;
}

export interface ILabelStats {
    [timestamp: number]: {
        [labelId: number]: ILabelStatsItem;
    };
}

export interface IChatblocksStatsItem {
    id: number;
    name: string;
    sent: number;
    answered_24h: number;
    answer_rate: number;
}

export interface IChatblockStats {
    [timestamp: number]: IChatblocksStatsItem;
}

export interface IClickStats {
    created: number;
    clicks?: number;
    messenger_clicks?: any;
    content?: {
        reach: number;
        messenger: any;
    };
}

export interface ISum {
    [key: string]: number;
}

interface IStatistics<T = any> {
    stats: T;
    loaded: boolean;
    authorized?: boolean;
}

export interface IStatisticsAgents extends IStatistics<IAgentStats> {
    filter: number[];
}

export interface ILinkItem extends TPartialRecord<EMessenger, number> {
    clicks: number;
    ref_id: number;
    url: string;
    short_id: string;
    short_url: string;
}

export interface ILinkStats {
    [x: string]: ILinkItem;
}

export interface IStatisticsTables {
    agents: IStatisticsAgents;
    users: IStatistics<IUserStats[]>;
    notifications: IStatistics<INotificationsStats[]>;
    labels: IStatistics<ILabelStats>;
    clicks: IStatistics<IClickStats>;
    chatblocks: IStatistics<IChatblockStats>;
}

export type TStatisticsAutomation = {
    [key in EChartAutomationKpi]?: number;
};

export type TStatisticsSimpleChat = {
    unread_chats: number;
    unread_chats_user_ids: number[];
};

export type TConversationStats = {
    total: number;
    regular: number;
    free_tier: number;
    free_entry_point: number;
}

export type TCostsAndConversationStats = {
    costs: {
        total: number;
        user_initiated: number;
        business_initiated: number;
        waived?: boolean;
    }
    conversations: {
        total: number;
        user_initiated: TConversationStats;
        business_initiated: TConversationStats;
    }
}

export type TStatisticsWhatsAppConversations = {
    [date: string]: {
        account: TCostsAndConversationStats;
        channel: TCostsAndConversationStats;
    }
};

export type TStatisticsConversations = {
    [date: string]: {
        start: number;
        messenger: any;
        total: number;
    }
};

export interface IStatisticsState extends IStatisticsTables {
    contentStatistics?: {
        days: {day: any; pushed_to: any; pulled_from: any; timestamp?: number; users?: 0}[];
    };
    general?: IStatisticsGeneralData;
    linkStats?: ILinkStats;
    chats: IStatisticsChats;
    tickets: IStatisticsTickets;
    chatbots: IStatisticsChatbots;
    mia: IStatisticsMia;
    blackList: IStatisticsBlackList;
    customCharts: IStatisticsCustomChart[];
    automation?: TStatisticsAutomation;
    clicks: any;
    simpleChat?: TStatisticsSimpleChat;
    whatsappConversations?: TStatisticsWhatsAppConversations;
    conversations?: TStatisticsConversations;
}

export type TChartType = 'bar' | 'line';

export type TLegendData = ILegendDataRow[];

export interface ILegendDataRow {
    color_bg: string;
    name: string;
}

export interface ILegendSum {
    [key: string]: number;
}
