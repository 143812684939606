import {Reducer} from 'redux';
import {ILanguages} from '../../definitions/languages/languages.definitions';
import {SETTINGS_GET_NOTIFICATION_LANGUAGES_FULFILLED} from '../settings/notificationTemplates/notificationTemplates.types';

const languagesInitialState: ILanguages = {
    wbNotifications: {},
};

export const languagesReducer: Reducer<ILanguages> = (state = languagesInitialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case SETTINGS_GET_NOTIFICATION_LANGUAGES_FULFILLED:
            return {
                ...state,
                wbNotifications: {
                    ...payload.reduce(
                        (acc: any, language: any) => ({
                            ...acc,
                            [language.code]: language.name,
                        }),
                        {},
                    ),
                },
            };

        default:
            break;
    }

    return state;
};
