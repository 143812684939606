import {FC, useState} from 'react';

import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';
import ReactPlayer from 'react-player';

import MediaError from '../../MediaError/MediaError';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chatMediaContainer: {
            borderRadius: theme.spacing(0.5),
            overflow: 'hidden',
            display: 'inline-block',
            maxWidth: 300,
            cursor: 'pointer',
            backgroundColor: 'rgba(0,0,0,0.8)',

            '& img, video': {
                width: 'auto',
                maxWidth: '100%',
                height: 'auto',
                maxHeight: 'calc(100vh - 300px)',
                display: 'block',
                margin: '0 auto',

                [theme.breakpoints.down('xs')]: {
                    maxHeight: 'calc(100vh - 200px)',
                },
            },

            '& span': {
                display: 'inline-flex',
            },
        },
    }),
);

interface IVideoContent {
    media: string;
    chatTime?: number;
}

const VideoContent: FC<IVideoContent> = ({media, chatTime}) => {
    const classes = useStyles();
    const [error, setError] = useState(false);

    if (error) {
        return <MediaError fileUrl={media} />;
    }

    const now = moment().unix();
    // preload only videos sent within the last hour
    const dontPreloadVideo: boolean = (chatTime || now) < now - 3600;

    return (
        <div className={classes.chatMediaContainer}>
            <ReactPlayer
                light={dontPreloadVideo}
                pip
                controls
                url={media}
                width=""
                height=""
                onError={() => setError(true)}
            />
        </div>
    );
};

VideoContent.displayName = 'VideoContent';

export default VideoContent;
