import {Reducer} from 'redux';
import {ILoadingState} from '../../definitions/loading/loadingState.definitions';
import {
    TEXT_MODULES_GET_FULFILLED,
    TEXT_MODULES_GET_PENDING,
    TEXT_MODULES_GET_REJECTED,
} from '../textModule/textModule.types';
import {isTypeFulfilled, isTypePending, isTypeRejected} from '../utils';

export type TRequest = 'getTextModules';

export const loadingInitialState: ILoadingState = {
    requestCounter: 0,
    requests: {
        getTextModules: 0,
    },
};

export const loaderReducer: Reducer<ILoadingState> = (state = loadingInitialState, action) => {
    const {type} = action;

    switch (type) {
        case TEXT_MODULES_GET_PENDING:
            return getNewState(state, 'getTextModules', true);
        case TEXT_MODULES_GET_REJECTED:
        case TEXT_MODULES_GET_FULFILLED:
            return getNewState(state, 'getTextModules', false);
    }

    if (isTypePending(type)) {
        return {...state, requestCounter: (state.requestCounter || 0) + 1};
    }

    if ((isTypeFulfilled(type) || isTypeRejected(type)) && state.requestCounter > 0) {
        return {...state, requestCounter: state.requestCounter - 1};
    }

    return state;
};

const getNewState = (state: ILoadingState, request: TRequest, isPending: boolean): ILoadingState => {
    const summand = isPending ? 1 : -1;

    return {
        ...state,
        requestCounter: state.requestCounter + summand,
        requests: {
            ...state.requests,
            [request]: state.requests[request] + summand,
        },
    };
};
