export enum EMessenger {
    WhatsApp = 'WA',
    WhatsAppBusiness = 'WB',
    Viber = 'VI',
    FacebookMessenger = 'FB',
    Telegram = 'TG',
    IMessage = 'IM',
    InstaAndNotify = 'IN',
    Insta2 = 'WN',
    CustomMessenger = 'CC',
    TelegramChannel = 'TC',
    LiveChat = 'LC',
    Instagram = 'IG',
    RCS = 'RC',
    Line = 'LI',
}

export enum EMetaMessengers {
    ViberNew = 'VI_NEW',
}
