import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import {
    ICustomChartRequest,
    IPeriod,
    IPeriodData,
    IPeriodDataWithDetails,
    IStatisticsAgentsRequestParams,
    TMauStatisticsRequest,
} from '../../definitions/statistics/statistics.definitions';
import {
    fetchOldTicketStats,
    getAgentStatisticByIdRequest, getConversationStatisticsRequest,
    getSimpleChatStatisticsRequest,
    getStatisticsAgentsRequest,
    getStatisticsAutomationRequest,
    getStatisticsBlacklistRequest,
    getStatisticsChatblocksRequest,
    getStatisticsChatbotsRequest,
    getStatisticsChatsRequest,
    getStatisticsClicksRequest,
    getStatisticsCustomChartRequest,
    getStatisticsLabelsRequest,
    getStatisticsMiaRequest,
    getStatisticsMiaUsersRequest,
    getStatisticsNotificationsRequest,
    getStatisticsTicketsRequest,
    getWhatsAppConversationStatisticsRequest,
} from '../../requests/statisticsRequests';
import {IState} from '../root.reducer';
import {TGetState, TThunkDispatch} from '../../definitions/common.definitons';
import {EChartInterval} from '../../enums/chart/EChartInterval';
import {
    OLD_TICKET_STATS_GET,
    STATISTICS_AGENTS_GET,
    STATISTICS_AGENTS_GET_BY_ID,
    STATISTICS_AGENTS_SET_FILTER,
    STATISTICS_AUTOMATION_GET,
    STATISTICS_CHATBLOCKS_GET,
    STATISTICS_CHATBOTS_GET,
    STATISTICS_CHATS_GET,
    STATISTICS_CLICKS_GET,
    STATISTICS_CONVERSATIONS,
    STATISTICS_CUSTOM_CHART_GET,
    STATISTICS_GET_BLACKLIST,
    STATISTICS_LABELS_GET,
    STATISTICS_MIA_GET,
    STATISTICS_MIA_USERS_GET,
    STATISTICS_NOTIFICATIONS_GET,
    STATISTICS_SIMPLE_CHAT_GET,
    STATISTICS_TICKETS_GET,
    STATISTICS_WHATSAPP_CONVERSATIONS,
} from './statistics.types';


export const getStatisticsChats = (params: IPeriodData) => ({
    type: STATISTICS_CHATS_GET,
    payload: getStatisticsChatsRequest(params),
});

export const getStatisticsBlacklist = () => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
        const state = getState();
        const {statisticsBlacklist} = state.request;
        if (!statisticsBlacklist.get && !statisticsBlacklist.loaded) {
            return dispatch({
                type: STATISTICS_GET_BLACKLIST,
                payload: getStatisticsBlacklistRequest(),
            });
        }
    };
};

export const getStatisticsTickets = (params: IPeriodData) => ({
    type: STATISTICS_TICKETS_GET,
    payload: getStatisticsTicketsRequest(params),
});

export const getOldTicketStats = (performance = true) => ({
    type: OLD_TICKET_STATS_GET,
    payload: fetchOldTicketStats(performance),
});

export const getStatisticsChatbots = (params: IPeriodDataWithDetails) => ({
    type: STATISTICS_CHATBOTS_GET,
    payload: getStatisticsChatbotsRequest(params),
});

export const getStatisticsMau = (params: TMauStatisticsRequest) => ({
    type: STATISTICS_MIA_GET,
    payload: getStatisticsMiaRequest(params),
});

export const getStatisticsCustomChart = (params: ICustomChartRequest) => ({
    type: STATISTICS_CUSTOM_CHART_GET,
    payload: getStatisticsCustomChartRequest(params),
    meta: {
        id: params.id,
    },
});

export const getStatisticsAgents = (params: IPeriod) => ({
    type: STATISTICS_AGENTS_GET,
    payload: getStatisticsAgentsRequest(params),
});

export const getAgentStatisticById = (params: IStatisticsAgentsRequestParams) => ({
    type: STATISTICS_AGENTS_GET_BY_ID,
    payload: getAgentStatisticByIdRequest(params),
    meta: {agent_id: params.agent_id},
});

export const setAgentsFilter = (agentIds: number[] = []) => ({
    type: STATISTICS_AGENTS_SET_FILTER,
    payload: agentIds,
});

export const getStatisticsMiaUsers = (params: IPeriod) => ({
    type: STATISTICS_MIA_USERS_GET,
    payload: getStatisticsMiaUsersRequest({days: 1, start: params.from, end: params.to}),
});

export const getStatisticsNotifications = (params: IPeriod) => ({
    type: STATISTICS_NOTIFICATIONS_GET,
    payload: getStatisticsNotificationsRequest(params),
    meta: {
        silent: true,
    },
});

export const getStatisticsLabels = (params: IPeriod) => (dispatch: TThunkDispatch, getState: TGetState) => {
    const interval = getState().actor.settings.labels?.interval || EChartInterval.Days;

    return dispatch({
        type: STATISTICS_LABELS_GET,
        payload: getStatisticsLabelsRequest({...params, interval}),
    });
};

export const getStatisticsChatblocks = (params: IPeriod) => ({
    type: STATISTICS_CHATBLOCKS_GET,
    payload: getStatisticsChatblocksRequest(params),
});

export const getStatisticsAutomation = (params: IPeriodData) => ({
    type: STATISTICS_AUTOMATION_GET,
    payload: getStatisticsAutomationRequest(params),
});

export const getStatisticsClicks = (params: IPeriod) => ({
    type: STATISTICS_CLICKS_GET,
    payload: getStatisticsClicksRequest(params),
});

export const getSimpleChatStatistics = () => ({
    type: STATISTICS_SIMPLE_CHAT_GET,
    payload: getSimpleChatStatisticsRequest(),
});

export const getWhatsAppConversationStatistics = (params?: IPeriod) => ({
    type: STATISTICS_WHATSAPP_CONVERSATIONS,
    payload: getWhatsAppConversationStatisticsRequest(params),
});

export const getConversationStatistics = (params?: IPeriod) => ({
    type: STATISTICS_CONVERSATIONS,
    payload: getConversationStatisticsRequest(params),
});
