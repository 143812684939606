import {AnyAction} from 'redux';
import {reset} from 'redux-form';
import {ThunkDispatch} from 'redux-thunk';
import translations from '../../providers/TranslationProvider/translations';
import {BASE_URL} from '../../config';
import {IChannel, IChannelTag, IImagePayload} from '../../definitions/channel/channel.definitions';
import {ENotificationLevel} from '../../enums/notifications/ENotificationLevel';
import {FORM_NAME_TAGS} from '../../pages/SettingsPage/components/TagsSettings/TagsSettingsDialog';
import {
    createChannelLabelRequest,
    deleteChannelLabelRequest,
    getChannelAgentsRequest,
    getChannelInvoicesRequest,
    getChannelLabelsRequest,
    getChannelsRequest,
    TicketDeactivateBot,
    updateChannelLabelRequest,
    updateChannelRequest,
} from '../../requests/channelRequest';
import {uploadFormDataRequest} from '../../requests/uploadRequests';
import {addNotification} from '../notifications/notifications.actions';
import {IState} from '../root.reducer';
import {deleteMessengerUserRequest, deleteMpApiRequest, postMpApiRequest} from '../../requests/messengerRequests';
import {
    CHANNEL_CREATE_LABEL,
    CHANNEL_DELETE_LABEL,
    CHANNEL_GET,
    CHANNEL_GET_AGENTS,
    CHANNEL_GET_INVOICES,
    CHANNEL_GET_LABELS,
    CHANNEL_MESSENGER_MP_API_DELETE,
    CHANNEL_MESSENGER_MP_API_POST,
    CHANNEL_MESSENGER_USER_DELETE,
    CHANNEL_SET,
    CHANNEL_SET_IMAGE,
    CHANNEL_UPDATE_LABEL,
} from './channel.types';
import {downloadRequest} from "../../requests/downloadRequest";


export const getChannels = () => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
        const state = getState();
        const {getChannel} = state.request;
        if (!getChannel.get && !getChannel.loaded) {
            return dispatch({
                type: CHANNEL_GET,
                payload: getChannelsRequest(),
            });
        }
    };
};

export const setChannelData = (payload: Partial<IChannel>) => ({
    type: CHANNEL_SET,
    meta: payload,
    payload: updateChannelRequest(payload),
});

export const setChannelBotData = (payload: Partial<IChannel>) => (dispatch: any) => {
    const status = payload.ticket_deactivate_bot;

    if (status && !Object.values(TicketDeactivateBot).includes(status)) {
        throw new Error(`Unexpected status. Expected 0, 1 or 2 but got "${status}".`);
    }

    const formattedData = formatData({ticket_deactivate_bot: status});
    return dispatch(setChannelData(formattedData));
};

const formatData = (data: any) => {
    if (!data[0] || !data[0].content_notifications) {
        return data;
    }

    const formatted = [...data];
    const values = {...data[0]};
    values.content_notifications = [values.content_notifications];
    formatted[0] = values;
    return formatted;
};

export const setFormattedChannelData = (payload: Partial<IChannel>) => (dispatch: any) => {
    const formatted = formatData(payload);
    return dispatch(setChannelData(formatted));
};

export const createChannelLabel = (tag: IChannelTag) => ({
    type: CHANNEL_CREATE_LABEL,
    payload: createChannelLabelRequest(tag),
});

export const resetChannelLabelData = () => (dispatch: any) => dispatch(reset(FORM_NAME_TAGS));

export const updateChannelLabel = (tag: IChannelTag) => ({
    type: CHANNEL_UPDATE_LABEL,
    payload: updateChannelLabelRequest(tag),
});

export const deleteChannelLabel = (labelId: number) => ({
    type: CHANNEL_DELETE_LABEL,
    payload: deleteChannelLabelRequest(labelId),
});

export const deleteChannelLabelAndNotify = (labelId: number) => (dispatch: any) =>
    dispatch(deleteChannelLabel(labelId)).then(() =>
        dispatch(addNotification(translations.save_operation_success, ENotificationLevel.Success)),
    );

export const setChannelImageWithDispatch = (payload: IImagePayload) => (dispatch: any) => {
    return uploadFormDataRequest(payload).then(image => {
        return dispatch(updateChannelImage({image}));
    });
};

export const updateChannelImage = (payload: Partial<IChannel>) => ({
    type: CHANNEL_SET_IMAGE,
    payload: updateChannelRequest(payload),
});

export const getChannelInvoices = () => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
        const state = getState();
        const {invoices} = state.request;
        if (!invoices.get && !invoices.loaded) {
            return dispatch({
                type: CHANNEL_GET_INVOICES,
                payload: getChannelInvoicesRequest(),
            });
        }
    };
};

export const getChannelAgents = () => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
        const state = getState();
        const {invoices} = state.request;

        if (!invoices.get && !invoices.loaded) {
            return dispatch({
                type: CHANNEL_GET_AGENTS,
                payload: getChannelAgentsRequest(),
            });
        }
    };
};

export const getChannelLabels = () => ({
    type: CHANNEL_GET_LABELS,
    payload: getChannelLabelsRequest(),
});


export const getChannelFlyer = () => (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => IState,
) => {
    const state = getState();
    const language = ['en', 'de'].includes(state.channel.language) ? state.channel.language : 'en';

    return downloadRequest(`${BASE_URL}/channels/download-flyer?lang=${language}`);
};

export const connectMessenger = (uuid: string) => ({
    type: CHANNEL_MESSENGER_MP_API_POST,
    payload: postMpApiRequest(uuid),
});

export const resetMessenger = (messenger: string) => ({
    type: CHANNEL_MESSENGER_MP_API_DELETE,
    payload: deleteMpApiRequest(messenger),
    meta: {messenger},
});

export const deleteMessengerUser = (messenger: string) => ({
    type: CHANNEL_MESSENGER_USER_DELETE,
    payload: deleteMessengerUserRequest(messenger),
    meta: {messenger},
});
