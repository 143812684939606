import {IAgent} from '../definitions/agent/agent.definitions';
import {request} from './Api/Api';
import {CLIENT_DOMAIN_URL_PARAM, HEADER_X_CLIENT_DOMAIN} from "../config";

const AGENT_URL = 'agent/{id}';
export const AGENT_ME_URL = `agent/me?${CLIENT_DOMAIN_URL_PARAM}=${HEADER_X_CLIENT_DOMAIN}`;
const AGENT_STATUS_URL = 'agent/status';
const AGENT_PASSWORD_URL = 'agent/password';
const AGENT_CHANGE_CHANNEL = 'agent/channel';
const AGENT_TWO_FACTOR = 'agent/{id}/twoFactor';

export const getAgentsRequest = (openTickets?: boolean) => request({method: 'GET', url: AGENT_URL}, openTickets ? {open_tickets: 1} : undefined);

export const getProfileRequest = () => request({method: 'GET', url: AGENT_ME_URL});

export const setAgentStatusRequest = (statusIndex: string) => {
    return request({method: 'PUT', url: AGENT_STATUS_URL}, {status: statusIndex});
};

export const updateAgentRequest = (agent: any) => {
    // don't send rights
    const {rights, settings, skills, ...data} = agent;
    return request(
        {method: 'PUT', url: AGENT_URL},
        {
            ...data,
            settings: settings ? JSON.stringify(settings) : undefined,
            skills: skills ? JSON.stringify(skills) : undefined,
        },
    );
};

export const addAgentRequest = (agent: IAgent) =>
    request(
        {method: 'POST', url: AGENT_URL},
        {
            ...agent,
            skills: agent.skills ? JSON.stringify(agent.skills) : undefined,
        },
    );

export const deleteAgentRequest = (agentId: number) =>
    request({method: 'DELETE', url: AGENT_URL}, {id: agentId});

export const setPasswordRequest = (newPassword: string) =>
    request(
        {method: 'PUT', url: AGENT_PASSWORD_URL},
        {
            new_password: newPassword,
        },
    );

export const changeStartChannel = (channelId: number) =>
    request({method: 'PUT', url: AGENT_CHANGE_CHANNEL}, {channel_id: channelId});

export const getTwoFactorAuth = (id?: number) => request({method: 'GET', url: AGENT_TWO_FACTOR}, id && {id});

export const enableTwoFactorAuth = (id?: number) => request({method: 'POST', url: AGENT_TWO_FACTOR}, id && {id});

export const disableTwoFactorAuth = (id?: number) => request({method: 'DELETE', url: AGENT_TWO_FACTOR}, id && {id});

export const resetTwoFactorAuth = (id?: number) => request({method: 'PUT', url: AGENT_TWO_FACTOR}, id && {id});
