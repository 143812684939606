import {IGetFacebookPagePayload} from "./facebook/facebook.actions.definitions";

export enum ESetupWbStatus {
    none = 'none',
    received = 'received',
    in_submission = 'in_submission',
    waba_created = 'waba_created',
    accepted = 'accepted',
    fbm_id_invalid = 'fbm_id_invalid',
    rejected = 'rejected',
    ignore = 'ignore',
}

export enum ESetupFBMVerification {
    unknown = 'unknown',
    unverified = 'unverified',
    in_progress = 'in_progress',
    verified = 'verified',
    verification_declined = 'verification_declined',
}

export enum EVNameStatus {
    none = 'none',
    in_review = 'in_review',
    in_use = 'in_use',
    accepted = 'accepted',
    rejected = 'rejected',
}

export interface ILiveChatSetup {
    uuid: string;
    client_id: string;
    conversation_timeout: number;
    support_whatsapp: boolean;
    show_agent_names: boolean;
}

export interface ISettingsMessenger {
    whatsAppBusinessTestphaseNumbers: any[];
    whatsAppBusinessAccountInformation: any;
    whatsAppBusinessStatus: any;
    setupStatus: any;
    openDialog: boolean;
    setup_data: {
        cc?: string;
        phone_number?: string;
        vname?: string;
        business_manager_id?: string;
        setup_request_confirmed: boolean;
        setup_fbm_verification: ESetupFBMVerification;
        setup_wb_status: ESetupWbStatus;
        setup_vname_status: EVNameStatus;
        setup_contact_type?: 'email' | 'whatsapp';
        setup_contact?: string;
        livechat?: ILiveChatSetup;
        numbers?: string[],
        selectedNumber?: string,
        connectedPage?: IGetFacebookPagePayload | null;
        loading: boolean;
        error?: any;
        setup_loading: boolean;
    };
}

