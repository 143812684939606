import {FC} from 'react';

import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import {useSelector} from 'react-redux';

import {getAgentById} from '../../redux/agents/agents.selectors';
import {IState} from '../../redux/root.reducer';
import LinkButton from '../LinkButton/LinkButton';
import MessageMedia from './MessageMedia';
import ImageContent from "./Content/ImageContent";
import {IMessageContentItem} from "../../definitions/chat/chat.definitions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        quote: {
            borderRadius: theme.spacing(1) / 2,
            padding: theme.spacing(1),
            backgroundColor: 'rgba(0,0,0,0.08)',
            borderLeft: '3px solid rgba(0,0,0,0.2)',
            marginBottom: theme.spacing(1),
        },
        quoteName: {
            paddingBottom: 2,
        },
        image: {
            height: 'auto',
        },
    }),
);

interface IMessageQuoteProps {
    quote: IMessageContentItem;
    searchTerm?: string;
}

const MessageQuote: FC<IMessageQuoteProps> = props => {
    const {quote, searchTerm = ''} = props;

    const classes = useStyles();

    const quoteAgent = useSelector((state: IState) => getAgentById(state, quote.agent_id || 0));

    const isInstagramStory = quote.chattype === 'story';

    const quoteName = () => {
        if (quote.bot) {
            return 'Bot';
        }

        if (isInstagramStory) {
            return quote.header;
        }

        if (!quote.outgoing) {
            return '';
        }

        if (quoteAgent && quoteAgent.name) {
            return quoteAgent.name;
        }

        if (quoteAgent && quoteAgent.email) {
            return quoteAgent.email;
        }

        if (quote.agent_id === 0 && quote.agent_email) {
            return quote.agent_email;
        }

        return '-';
    };

    return (
        <div className={classes.quote}>
            <MessageMedia {...quote} searchTerm={searchTerm} isQuote={true} />
            <Typography component="p" variant="caption" className={classes.quoteName}>
                {quoteName()}
            </Typography>
            <Typography component="p" variant="body2" style={{fontWeight: 300}}>
                {isInstagramStory && typeof quote.media === 'string' && (
                    <ImageContent media={quote.media} imageClassName={classes.image} />
                )}
                {isInstagramStory && quote.chatlink && <LinkButton href={quote.chatlink} message={quote.chat} />}
                {isInstagramStory && !quote.chatlink && quote.chat}
                {!isInstagramStory && quote.chat}
            </Typography>
        </div>
    );
};

MessageQuote.displayName = 'MessageQuote';

export default MessageQuote;
