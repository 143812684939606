export const SEARCH_PRODUCT = 'SEARCH_PRODUCT';
export const SEARCH_PRODUCT_PENDING = 'SEARCH_PRODUCT_PENDING';
export const SEARCH_PRODUCT_FULFILLED = 'SEARCH_PRODUCT_FULFILLED';
export const SEARCH_PRODUCT_REJECTED = 'SEARCH_PRODUCT_REJECTED';

export const SCROLL_PRODUCTS = 'SCROLL_PRODUCTS';
export const SCROLL_PRODUCTS_PENDING = 'SCROLL_PRODUCTS_PENDING';
export const SCROLL_PRODUCTS_FULFILLED = 'SCROLL_PRODUCTS_FULFILLED';
export const SCROLL_PRODUCTS_REJECTED = 'SCROLL_PRODUCTS_REJECTED';

export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS';
export const GET_PRODUCT_DETAILS_PENDING = 'GET_PRODUCT_DETAILS_PENDING';
export const GET_PRODUCT_DETAILS_FULFILLED = 'GET_PRODUCT_DETAILS_FULFILLED';
export const GET_PRODUCT_DETAILS_REJECTED = 'GET_PRODUCT_DETAILS_REJECTED';
