import useUpdateStreamOverMessageBus from "../../utils/hooks/useUpdateStreamOverMessageBus";

interface UpdateStreamConsumerProps {
    enablePerformanceCheck?: boolean;
}

const UpdateStreamConsumer = (props: UpdateStreamConsumerProps) => {
    useUpdateStreamOverMessageBus(props.enablePerformanceCheck);
    return null;
};

export default UpdateStreamConsumer;
