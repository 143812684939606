import {ComponentProps, FC} from 'react';

import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import {QUERY_PARAM_AFFILIATE_ID, QUERY_PARAM_SPKG} from '../../definitions/router/router.definitions';
import {getCookie, getUrlParameter} from '../../utils/global/global.utils';
import ExternalPage from '../ExternalPage/ExternalPage';
import RegistrationForm from './RegistrationForm/RegistrationForm';
import Teamwork from '../../resources/img/Illustrations/Teamwork_from_phone.svg';

interface IAuthenticationPageProps {
    isAuthenticated: boolean;
    location: any;
}

const affiliateId = getUrlParameter(QUERY_PARAM_AFFILIATE_ID) || undefined;
const spkg = getUrlParameter(QUERY_PARAM_SPKG) || getCookie(QUERY_PARAM_SPKG) || undefined;

const AuthenticationPage: FC<IAuthenticationPageProps> = () => {
    return (
        <ExternalPage header footer backGround={Teamwork}>
            <RegistrationForm affiliate={affiliateId} spkg={spkg}/>
        </ExternalPage>
    );
};

const AuthenticationPageWithBoundary = (props: ComponentProps<any>) => (
    <ErrorBoundary>
        <AuthenticationPage {...props} />
    </ErrorBoundary>
);

export default AuthenticationPageWithBoundary;
