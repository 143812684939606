import axios, {AxiosRequestConfig} from 'axios';
import {isProductionUrl} from "../../utils/global/global.utils";
import {BASE_URL, HEADER_X_CLIENT_DOMAIN} from "../../config";
import {getTokenFromStorage, isSSOEnabledInStorage} from "../../utils/storage/storage.utils";
import {getTokenOrUpdate} from "../../sso/keycloak";
import {applicationStore} from "../../redux/root.reducer";

interface IEndpoint {
    url: string;
    method: AxiosRequestConfig["method"];
}

interface IConfig extends IEndpoint {
    params?: AxiosRequestConfig["params"];
    data?: AxiosRequestConfig["data"];
}

export const API_ID = '/{id}';

const DATA_METHODS = ['PUT', 'POST', 'DELETE', 'PATCH'];

const ERROR_REPORT_URL = 'report-error';

axios.defaults.baseURL = BASE_URL;
axios.defaults.timeout = 300000;

// Asynchronously inject valid keycloak token if SSO enabled
axios.interceptors.request.use(
    async config => {
        if (!config?.headers?.noAuth) {
            // if sso is enabled, update token first (catch errors, ie. when keycloak is not initiated)
            if (isSSOEnabledInStorage()) {
                getTokenOrUpdate().catch(() => {
                    console.error(`Session timed out before requesting ${config.url}`);

                    // report error (don't do for error-report requests)
                    if (!config.url?.startsWith(ERROR_REPORT_URL)) {
                        reportErrorRequest({
                            type: 'Api',
                            uri: window.location.href,
                            message: `Failed to refresh token before requesting ${config.url}`,
                        });
                    }
                });
            }

            // now the token in storage should be up to date
            const token = getTokenFromStorage();

            if (config && config.headers && token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            // add client domain for product tracking
            if (config && config.headers) {
                config.headers['X-Client-Domain'] = HEADER_X_CLIENT_DOMAIN;
            }
        }

        // delete this custom header, because its not allowed by CORS rules
        if (config && config.headers) {
            delete config.headers.noAuth;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const request = (endpoint: IEndpoint, params?: AxiosRequestConfig["params"], noAuth?: true) => {
    const config = getConfig(endpoint, params);
    const isNoAuth = noAuth ? {headers: {noAuth}} : undefined;
    return axios
        .request({...config, ...isNoAuth})
        .then(response => response.data)
        .catch(error => Promise.reject(error?.response));
};

const getConfig = (endpoint: IEndpoint, params?: AxiosRequestConfig["params"]): IConfig => {
    const config: IConfig = {
        ...endpoint,
        url: getUrl(endpoint.url, params),
    };

    const isDataMethod = config.method && DATA_METHODS.includes(config.method);

    if (isDataMethod && params) {
        config.data = params;
    }

    if (!isDataMethod && params) {
        config.params = params;
    }

    return config;
};

const getUrl = (url: string, params?: AxiosRequestConfig["params"]) => {
    const id = params ? params.id : null;
    const containsId = url.includes(API_ID);
    if (!containsId) {
        return url;
    }
    // if no id is set, slice the id, without id all entries from table will be returned
    if (!id) {
        return url.replace(API_ID, '');
    }
    // replace id and keep forward slash
    return url.replace(API_ID, `/${id}`);
};


let reportsCount = 0;
const MAX_REPORTS_PER_SESSION = 25;

export const reportErrorRequest = (data: { type: string; uri: string; message: string; trace?: string; }) => {
    reportsCount++;

    if (reportsCount > MAX_REPORTS_PER_SESSION) {
        return Promise.resolve();
    }

    const message = data.message;

    // don't send errors that we can not fix
    if (!message ||
        message === '{}' || // empty error
        message === 'Request aborted' || // user aborted the request somehow
        message.indexOf("Invariant failed") !== -1 ||
        message.indexOf("Failed to execute 'removeChild' on 'Node'") !== -1 || // caused by chrome's translation feature
        message.indexOf("Failed to execute 'insertBefore' on 'Node'") !== -1 || // caused by chrome's translation feature
        message.indexOf("The node to be removed is not a child of this node") !== -1 || // caused by chrome's translation feature
        message.indexOf('"reason":"last_answer_older_than_24_hours"') !== -1 || // user fail
        message.indexOf('You are overriding current access token') !== -1 // user fail
    ) {
        return Promise.resolve();
    }

    try {
        // add user agent, channel id and agent id
        const state = applicationStore.getState();
        const channelId = state.channel?.id;
        const agentId = state.actor?.id;

        data.trace = `Channel: ${channelId} | Agent: ${agentId} | System: ${navigator.userAgent} | Trace: ${data.trace}`;
    } catch(e) {
        // move on without additional info...
    }

    if (process.env.NODE_ENV !== 'production' || !isProductionUrl()) {
        console.warn("Report Error:", data);
        return Promise.resolve();
    }

    try {
        return request({method: 'POST', url: ERROR_REPORT_URL}, data);
    } catch (e) {
        console.warn(e);
        return Promise.reject('Failed to report');
    }
};
