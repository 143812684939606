import translations from "../../providers/TranslationProvider/translations";

/**
 * @param slug
 * @param {string|number} replaceWith
 * @param {string|number} secondReplace
 * @returns string|undefined
 */
export const translateSlug = (slug: string, replaceWith: string|number = '', secondReplace?: string|number) => {
    if (!translations[slug]) {
        return undefined;
    }

    return secondReplace
        ? translations[slug].replace('%s', replaceWith).replace('$$', secondReplace)
        : translations[slug].replace('%s', replaceWith);
};
