import {Reducer} from 'redux';

import {ILinkStats, IStatisticsState} from '../../definitions/statistics/statistics.definitions';
import {UPDATE_STREAM} from '../connection/connection.types';
import {MIA_GET_CONTENT_FULFILLED} from '../mia/content/content.types';
import {
    isTicketAdd,
    isTicketClose,
    isTicketReOpen,
    isTicketUpdate,
    isUnreadChatUpdate,
} from '../tickets/tickets.reducer.utils';
import {
    OLD_TICKET_STATS_GET_FULFILLED,
    STATISTICS_AGENTS_GET_BY_ID_FULFILLED,
    STATISTICS_AGENTS_GET_FULFILLED,
    STATISTICS_AGENTS_GET_PENDING,
    STATISTICS_AGENTS_GET_REJECTED,
    STATISTICS_AGENTS_SET_FILTER,
    STATISTICS_AUTOMATION_GET_FULFILLED,
    STATISTICS_CHATBLOCKS_GET_FULFILLED,
    STATISTICS_CHATBLOCKS_GET_PENDING,
    STATISTICS_CHATBLOCKS_GET_REJECTED,
    STATISTICS_CHATBOTS_GET_FULFILLED,
    STATISTICS_CHATBOTS_GET_PENDING,
    STATISTICS_CHATBOTS_GET_REJECTED,
    STATISTICS_CHATS_GET_FULFILLED,
    STATISTICS_CHATS_GET_PENDING,
    STATISTICS_CHATS_GET_REJECTED,
    STATISTICS_CLICKS_GET_FULFILLED,
    STATISTICS_CLICKS_GET_PENDING,
    STATISTICS_CLICKS_GET_REJECTED,
    STATISTICS_CONVERSATIONS_FULFILLED,
    STATISTICS_CUSTOM_CHART_GET_FULFILLED,
    STATISTICS_CUSTOM_CHART_GET_PENDING,
    STATISTICS_CUSTOM_CHART_GET_REJECTED,
    STATISTICS_GET_BLACKLIST_FULFILLED,
    STATISTICS_GET_BLACKLIST_PENDING,
    STATISTICS_GET_BLACKLIST_REJECTED,
    STATISTICS_LABELS_GET_FULFILLED,
    STATISTICS_LABELS_GET_PENDING,
    STATISTICS_LABELS_GET_REJECTED,
    STATISTICS_MIA_GET_FULFILLED,
    STATISTICS_MIA_GET_PENDING,
    STATISTICS_MIA_GET_REJECTED,
    STATISTICS_MIA_USERS_GET_FULFILLED,
    STATISTICS_MIA_USERS_GET_PENDING,
    STATISTICS_MIA_USERS_GET_REJECTED,
    STATISTICS_NOTIFICATIONS_GET_FULFILLED,
    STATISTICS_NOTIFICATIONS_GET_PENDING,
    STATISTICS_NOTIFICATIONS_GET_REJECTED,
    STATISTICS_SIMPLE_CHAT_GET_FULFILLED,
    STATISTICS_TICKETS_GET_FULFILLED,
    STATISTICS_TICKETS_GET_PENDING,
    STATISTICS_TICKETS_GET_REJECTED,
    STATISTICS_WHATSAPP_CONVERSATIONS_FULFILLED,
} from './statistics.types';
import {statisticsInitialState} from './statistics.reducer.initialState';
import {isUserUpdate} from "../user/user.reducer";
import isEmpty from "lodash/isEmpty";

export const statisticsReducer: Reducer<IStatisticsState> = (state = statisticsInitialState, action) => {
    switch (action.type) {
        case STATISTICS_CLICKS_GET_PENDING:
            return {
                ...state,
                clicks: {
                    ...state.clicks,
                    loaded: false,
                },
            };

        case STATISTICS_CLICKS_GET_REJECTED:
            return {
                ...state,
                clicks: {
                    ...state.clicks,
                    loaded: true,
                    authorized: action.payload.status !== 401,
                },
            };
        case STATISTICS_CLICKS_GET_FULFILLED: {
            const stats = {...action.payload};

            Object.keys(stats).forEach(function (key) {
                const messenger_clicks = stats[key].messenger_clicks;
                const messenger_reaches = stats[key].content?.messenger;
                const messenger_rates: any = {};
                if (messenger_clicks && messenger_reaches) {
                    Object.keys(messenger_reaches).forEach(function (m) {
                        if (messenger_reaches[m] && messenger_clicks[m]) {
                            messenger_rates[m] = messenger_clicks[m] / messenger_reaches[m];
                        }
                    });
                }
                stats[key].messenger_rates = messenger_rates;
                if (stats[key].content && stats[key].content) {
                    stats[key].rate = stats[key].clicks / stats[key].content.reach;
                } else {
                    stats[key].rate = 0;
                }
            });

            return {
                ...state,
                clicks: {
                    ...state.clicks,
                    stats,
                    loaded: true,
                },
            };
        }
        case STATISTICS_LABELS_GET_PENDING: {
            return {...state, labels: {...state.labels, loaded: false}};
        }
        case STATISTICS_LABELS_GET_REJECTED: {
            return {
                ...state,
                labels: {
                    ...state.labels,
                    loaded: true,
                    authorized: action.payload.status !== 401,
                },
            };
        }
        case STATISTICS_LABELS_GET_FULFILLED: {
            const stats = Array.isArray(action.payload) ? null : {...action.payload};

            return {...state, labels: {loaded: true, stats}};
        }
        case STATISTICS_AGENTS_GET_PENDING: {
            return {
                ...state,
                agents: {
                    ...state.agents,
                    loaded: false,
                },
            };
        }
        case STATISTICS_AGENTS_GET_BY_ID_FULFILLED: {
            const {agent_id} = action.meta;

            let stats = {...state.agents.stats};

            if (Array.isArray(action.payload)) {
                delete stats[agent_id];
            } else {
                stats = {...stats, ...action.payload};
            }

            return {
                ...state,
                agents: {
                    ...state.agents,
                    stats,
                },
            };
        }
        // increase/decrease unassigned tickets counter with ticket changes
        case UPDATE_STREAM: {
            const payload = action.payload;
            const usesSimpleChat = action.meta.usesSimpleChat;

            if (usesSimpleChat) {
                // create array if not already existing
                let usersWithUnreadChats = [...new Set(state.simpleChat?.unread_chats_user_ids)];

                // remove user if he was marked as read
                if (isUserUpdate(payload, 'unread') && !payload.message.payload.unread) {
                    usersWithUnreadChats = usersWithUnreadChats.filter(u => u !== payload.message.payload.id);
                }

                // add user if an unread chat comes in (only incoming chats can be unread)
                if (isUnreadChatUpdate(payload)) {
                    usersWithUnreadChats = [...new Set([...usersWithUnreadChats, payload.message.payload.user_id])];
                }

                // only update state if changed
                if (state.simpleChat?.unread_chats_user_ids?.length !== usersWithUnreadChats.length) {
                    return {
                        ...state,
                        simpleChat: {
                            ...state.simpleChat,
                            unread_chats: usersWithUnreadChats.length,
                            unread_chats_user_ids: usersWithUnreadChats,
                        },
                    };
                }

                return state;
            }


            const ticket = {...payload.message?.payload?.ticket};

            if (!ticket || !state.general) {
                return state;
            }

            // check if we have more or less unassigned tickets now
            let increaseOrDecrease = 0;
            if (isTicketUpdate(payload)) {
                // only update if ticket assignment status (not the agent) changed
                if (Boolean(ticket.agent_id) !== Boolean(ticket.old_agent_id)) {
                    increaseOrDecrease = ticket.agent_id ? -1 : 1;
                }
            } else if (isTicketClose(payload)) {
                if (!ticket.agent_id) {
                    increaseOrDecrease = -1;
                }
            } else if (isTicketReOpen(payload) || isTicketAdd(payload)) {
                if (!ticket.agent_id) {
                    increaseOrDecrease = 1;
                }
            }

            if (increaseOrDecrease) {
                return {
                    ...state,
                    general: {
                        ...state.general,
                        tickets_unassigned_total: Math.max(
                            0,
                            Number(state.general.tickets_unassigned_total) + increaseOrDecrease,
                        ),
                    },
                };
            }

            return state;
        }
        case STATISTICS_AGENTS_GET_REJECTED: {
            return {
                ...state,
                agents: {
                    ...state.agents,
                    loaded: true,
                    authorized: action.payload.status !== 401,
                },
            };
        }
        case STATISTICS_AGENTS_GET_FULFILLED:
            return {
                ...state,
                agents: {
                    ...state.agents,
                    stats: {...action.payload},
                    loaded: true,
                },
            };
        case STATISTICS_NOTIFICATIONS_GET_PENDING: {
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    loaded: false,
                },
            };
        }
        case STATISTICS_NOTIFICATIONS_GET_REJECTED: {
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    loaded: true,
                    authorized: action.payload.status !== 401,
                },
            };
        }
        case STATISTICS_NOTIFICATIONS_GET_FULFILLED:
            return {
                ...state,
                notifications: {
                    ...state.notifications,
                    stats: [...action.payload],
                    loaded: true,
                },
            };
        case STATISTICS_CHATBLOCKS_GET_PENDING: {
            return {
                ...state,
                chatblocks: {
                    ...state.chatblocks,
                    loaded: false,
                },
            };
        }
        case STATISTICS_CHATBLOCKS_GET_REJECTED: {
            return {
                ...state,
                chatblocks: {
                    ...state.chatblocks,
                    loaded: true,
                    authorized: action.payload.status !== 401,
                },
            };
        }
        case STATISTICS_CHATBLOCKS_GET_FULFILLED: {
            return {
                ...state,
                chatblocks: {
                    ...state.chatblocks,
                    stats: {...action.payload},
                    loaded: true,
                },
            };
        }
        case STATISTICS_MIA_USERS_GET_PENDING: {
            return {
                ...state,
                users: {
                    ...state.users,
                    loaded: false,
                },
            };
        }
        case STATISTICS_MIA_USERS_GET_REJECTED: {
            return {
                ...state,
                users: {
                    ...state.users,
                    loaded: true,
                    authorized: action.payload.status !== 401,
                },
            };
        }
        case STATISTICS_MIA_USERS_GET_FULFILLED:
            return {
                ...state,
                users: {
                    ...state.users,
                    stats: [...action.payload],
                    loaded: true,
                },
            };
        case STATISTICS_AGENTS_SET_FILTER: {
            return {
                ...state,
                agents: {
                    ...state.agents,
                    filter: [...action.payload],
                },
            };
        }
        case STATISTICS_CHATS_GET_PENDING:
            return {
                ...state,
                chats: {
                    ...state.chats,
                    isLoading: true,
                },
            };
        case STATISTICS_CHATS_GET_REJECTED:
            return {
                ...state,
                chats: {
                    ...state.chats,
                    isLoading: false,
                    authorized: action.payload.status !== 401,
                },
            };
        case STATISTICS_CHATS_GET_FULFILLED:
            return {
                ...state,
                chats: {
                    ...action.payload,
                    isLoading: false,
                },
            };
        case STATISTICS_GET_BLACKLIST_PENDING:
            return {
                ...state,
                blackList: {
                    ...state.blackList,
                    isLoading: true,
                    loaded: false,
                },
            };
        case STATISTICS_GET_BLACKLIST_REJECTED:
            return {
                ...state,
                blackList: {
                    ...state.blackList,
                    isLoading: false,
                    loaded: false,
                },
            };
        case STATISTICS_GET_BLACKLIST_FULFILLED:
            return {
                ...state,
                blackList: {
                    ...action.payload,
                    isLoading: false,
                    loaded: true,
                },
            };
        case STATISTICS_TICKETS_GET_PENDING:
            return {
                ...state,
                tickets: {
                    ...state.tickets,
                    isLoading: true,
                },
            };
        case STATISTICS_TICKETS_GET_REJECTED:
            return {
                ...state,
                tickets: {
                    ...state.tickets,
                    isLoading: false,
                    authorized: action.payload.status !== 401,
                },
            };
        case STATISTICS_TICKETS_GET_FULFILLED:
            return {
                ...state,
                tickets: {
                    ...action.payload,
                    isLoading: false,
                },
            };

        case STATISTICS_CHATBOTS_GET_PENDING:
            return {
                ...state,
                chatbots: {
                    ...state.chatbots,
                    isLoading: true,
                },
            };
        case STATISTICS_CHATBOTS_GET_REJECTED:
            return {
                ...state,
                chatbots: {
                    ...state.chatbots,
                    isLoading: false,
                    authorized: action.payload.status !== 401,
                },
            };
        case STATISTICS_CHATBOTS_GET_FULFILLED:
            return {
                ...state,
                chatbots: {
                    ...action.payload,
                    isLoading: false,
                },
            };

        case STATISTICS_MIA_GET_PENDING:
            return {
                ...state,
                mia: {
                    ...state.mia,
                    isLoading: true,
                },
            };

        case STATISTICS_MIA_GET_REJECTED:
            return {
                ...state,
                mia: {
                    ...state.mia,
                    isLoading: false,
                    authorized: action.payload.status !== 401,
                },
            };
        case STATISTICS_MIA_GET_FULFILLED:
            return {
                ...state,
                mia: {
                    mau: action.payload,
                    isLoading: false,
                },
            };

        case STATISTICS_CUSTOM_CHART_GET_PENDING: {
            const {customCharts} = state;
            const id = action.meta.id;

            if (!id || !customCharts?.length) {
                return state;
            }

            return {
                ...state,
                customCharts: customCharts.map(customChart => {
                    if (customChart.id === id) {
                        return {
                            ...customChart,
                            isLoading: true,
                        };
                    }
                    return customChart;
                }),
            };
        }

        case STATISTICS_CUSTOM_CHART_GET_REJECTED: {
            const {customCharts} = state;
            const id = action.meta.id;

            if (!id || !customCharts?.length) {
                return state;
            }

            return {
                ...state,
                customCharts: customCharts.map(customChart => {
                    if (customChart.id === id) {
                        customChart.isLoading = false;
                    }
                    return customChart;
                }),
            };
        }

        case STATISTICS_CUSTOM_CHART_GET_FULFILLED: {
            const {customCharts} = state;
            const id = action.meta.id;

            if (!customCharts) {
                return state;
            }

            const existingChart = customCharts.find(customChart => customChart.id === id);

            const newCustomChart = {
                id,
                stats: action.payload.chats
                    ? formatChatStats(action.payload.chats)
                    : formatTicketStats(action.payload.tickets),
                isLoading: false,
            };

            if (existingChart) {
                return {
                    ...state,
                    customCharts: customCharts.map(customChart => {
                        if (customChart.id === id) {
                            return newCustomChart;
                        }
                        return customChart;
                    }),
                };
            }

            return {
                ...state,
                customCharts: [...customCharts, newCustomChart],
            };
        }

        case OLD_TICKET_STATS_GET_FULFILLED: {
            return {
                ...state,
                general: action.payload,
            };
        }

        // Add click statistics for shortened urls of MIA content
        case MIA_GET_CONTENT_FULFILLED: {
            const links: ILinkStats = {};
            if (action.payload.content) {
                action.payload.content.forEach((item: any) => {
                    item.clicks &&
                    item.clicks.forEach((link: any) => {
                        links[link.short_id] = link;
                    });
                });
            }

            if (!isEmpty(links)) {
                return {
                    ...state,
                    linkStats: {
                        ...state.linkStats,
                        ...links,
                    },
                };
            }
            return state;
        }

        case STATISTICS_AUTOMATION_GET_FULFILLED: {
            return {
                ...state,
                automation: action.payload,
            };
        }

        case STATISTICS_SIMPLE_CHAT_GET_FULFILLED: {
            return {
                ...state,
                simpleChat: {
                    ...state.simpleChat,
                    ...action.payload,
                },
            };
        }

        case STATISTICS_WHATSAPP_CONVERSATIONS_FULFILLED: {
            return {
                ...state,
                whatsappConversations: {
                    ...state.whatsappConversations,
                    ...action.payload,
                },
            };
        }

        case STATISTICS_CONVERSATIONS_FULFILLED: {
            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    ...action.payload,
                },
            };
        }
        default:
            return state;
    }
};

const formatChatStats = (chatStats: any) => {
    if (!chatStats) {
        return {};
    }

    // TODO WTF happens here? seems way too overcomplicated
    const preparedStats = Object.keys(chatStats).map((key: string) => {
        const currentChatStats = chatStats[key];
        const chatKeys = Object.keys(currentChatStats);
        if (chatKeys.length !== 1 || typeof currentChatStats[chatKeys[0]] === 'undefined') {
            return null;
        }
        const currentKey = chatKeys[0];

        return {
            timestamp: Number(key),
            value: currentChatStats[currentKey],
        };
    });

    return formatStats(preparedStats);
};

const formatTicketStats = (ticketStats: any) => {
    if (!ticketStats) {
        return {};
    }

    const ticketKeys = Object.keys(ticketStats);
    if (ticketKeys.length !== 1 || typeof ticketStats[ticketKeys[0]] === 'undefined') {
        return {};
    }
    const currentKey = ticketKeys[0];
    const currentTicketStats = ticketStats[currentKey];

    const preparedStats = Object.keys(currentTicketStats).map((key: string) => ({
        timestamp: Number(key),
        value: currentTicketStats[key],
    }));

    return formatStats(preparedStats);
};

const formatStats = (stats: any) => {
    if (!stats) {
        return {};
    }

    return stats.reduce((all: any, current: any) => {
        return {
            ...all,
            [current.timestamp]: {
                timestamp: current.timestamp,
                value: current.value,
            },
        };
    }, {});
};
