import {FC, useCallback, useEffect, useMemo, useState} from 'react';

import {CircularProgress} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import QRCode from 'qrcode.react';

import CustomAlert from '../../../../../../components/CustomAlert/CustomAlert';
import CustomIconButton from '../../../../../../components/CustomIconButton/CustomIconButton';
import DialogTemplate from '../../../../../../components/DialogTemplate/DialogTemplate';
import translations from '../../../../../../providers/TranslationProvider/translations';
import {request} from '../../../../../../requests/Api/Api';
import BrandedIconSinch from '../../../../../../components/BrandedIconsSinch/BrandedIconSinch';

const useStyles = makeStyles(() =>
    createStyles({
        qrCode: {
            width: 'auto !important',
            height: 'auto !important',
            maxWidth: '100%',
        },
    }),
);

const SecondaryLogin: FC = () => {
    const classes = useStyles();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isRequestingUrl, setIsRequestingUrl] = useState(false);
    const [url, setUrl] = useState<string>();
    const theme = useTheme();

    const handleCloseDialog = useCallback(() => {
        setIsDialogOpen(false);
        setUrl(undefined);
    }, []);

    const actionsConfig = useMemo(
        () => [
            {
                onClick: handleCloseDialog,
                label: translations.close,
            },
        ],
        [handleCloseDialog],
    );

    useEffect(() => {
        if (isDialogOpen) {
            setIsRequestingUrl(true);

            request({ method: 'GET', url: '/agent/temporary_token' })
                .then(response => {
                    setUrl(`${window.location.host}${response.login_query}`);
                })
                .catch(() => {
                    setIsDialogOpen(false);
                })
                .finally(() => {
                    setIsRequestingUrl(false);
                });
        }
    }, [isDialogOpen]);

    const customIconColor = isDialogOpen ? theme.palette.primary.main : theme.palette.primary.contrastText;

    return (
        <>
            <div>
                <CustomIconButton
                    icon={<BrandedIconSinch menuIcon='MobileIntegration'/>}
                    iconProps={{ customIconColor }}
                    onClick={() => setIsDialogOpen(true)}
                    tooltip={translations.secondary_login_dialog_title}
                />
            </div>
            <DialogTemplate
                open={isDialogOpen}
                onClose={handleCloseDialog}
                maxWidth="sm"
                title={translations.secondary_login_dialog_title}
                contentText={translations.secondary_login_dialog_text}
                content={
                    isRequestingUrl ? (
                        <CircularProgress/>
                    ) : url ? (
                        <Box style={{ textAlign: 'center' }}>
                            <QRCode value={url} size={200} className={classes.qrCode}/>
                        </Box>
                    ) : (
                        <CustomAlert variant="error">{translations.an_error_occured}</CustomAlert>
                    )
                }
                actionsConfig={actionsConfig}
                closable
            />
        </>
    );
};

export default SecondaryLogin;
