import {Reducer} from 'redux';
import {needsToLogout} from './security.utils';
import {initialUiState} from "../ui/ui.reducer";
import {IState} from "../root.reducer";

export type ISecurityState = {
    resetState?: boolean;
    logout?: boolean;
    reload?: boolean;
    logoutReason?: string;
}

const securityReducerInitialState = {};

export const securityReducer: Reducer<ISecurityState> = (state = securityReducerInitialState) => {
    // because we want to reset the whole store, we can not handle the resetState flag here but have to handled it
    // in the securityWrapper, which has access to the whole store.
    return state;
};


/**
 * Security reducer is here.
 * Reset state without mutating the state directly
 * see: https://stackoverflow.com/a/35641992/709987
 *
 * @param appReducer
 */
const securityWrapper = (appReducer: Reducer): Reducer<IState, any> => (state, action) => {
    if (!state) {
        return appReducer(state, action);
    }

    // reset state if flag is set
    if (state.security.resetState) {
        (Object.keys(state) as (keyof IState)[]).forEach(reducer => {
            // For UI, we need to maintain the 'updateAvailable', otherwise the update button would disappear
            if (reducer === 'ui') {
                const {updateAvailable} = state[reducer];
                state[reducer] = {
                    ...initialUiState,
                    updateAvailable,
                };
            } else if (reducer !== 'translations') {
                // @ts-ignore
                state[reducer] = undefined;
            }
        });

        return appReducer(state, action);
    }

    const logoutCheckResult = needsToLogout(action, state);

    if (logoutCheckResult) {
        state.security = {
            ...state.security,
            ...logoutCheckResult,
        };
    }

    return appReducer(state, action);
};

export default securityWrapper;
