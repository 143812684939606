import {useDispatch, useSelector} from "react-redux";
import {IState} from "../../redux/root.reducer";
import {useCallback, useEffect, useState} from "react";
import {getAccount, getAccountInfo, getPricingInformation} from "../../redux/accounting/accounting.actions";
import {getChannelAgents, getChannelLabels, getChannels} from "../../redux/channel/channel.actions";
import {getAgents} from "../../redux/agents/agents.actions";
import {getInternalNews, getProfile} from "../../redux/actor/actor.actions";
import {getSimpleChatStatistics} from "../../redux/statistics/statistics.actions";
import {getActorTickets} from "../../redux/tickets/tickets.actions";
import {getCustomFields} from "../../redux/customField/customField.actions";
import {getLiveChatSetup} from "../../redux/settings/messenger/messenger.actions";
import {readShopConfig} from "../../redux/settings/shop/settingsShop.actions";
import {useHistory} from "react-router";
import {getChannelId, usesLiveChat, usesShop, usesSimpleChat} from "../../redux/channel/channel.selectors";
import {getMultiChannelInfo} from "../../redux/multichannel/multiChannel.actions";
import {getActorRights, isActorAdmin} from "../../redux/actor/actor.selectors";
import ERights from "../../enums/actor/ERights";
import usePrevious from "./usePrevious";
import {getUrlParameter} from "../global/global.utils";
import {QUERY_PARAM_SWITCH_CHANNEL, QUERY_PARAM_TOKEN} from "../../definitions/router/router.definitions";
import {isTestAccount as isTestAccountSelector} from "../../redux/accounting/accounting.selectors";


export interface IUseGlobalDataProps {
    internalNews?: boolean;
    accounting?: boolean;
    accountingInfo?: boolean;
    actorTickets?: boolean;
    actorProfile?: boolean;
    agents?: boolean;
    channelLabels?: boolean;
    simpleChatStatistics?: boolean;
    channels?: boolean;
    liveChatSetup?: boolean;
    multiChannelInfo?: boolean;
    shopConfig?: boolean;
    customFields?: boolean;
}

const useGlobalData = (props?: IUseGlobalDataProps) => {
    const {
        internalNews = true,
        accounting = true,
        accountingInfo = true,
        channels = true,
        channelLabels = true,
        multiChannelInfo = true,
        agents = true,
        actorTickets = true,
        actorProfile = true,
        liveChatSetup = true,
        shopConfig = true,
        simpleChatStatistics = true,
        customFields = true,
    } = props || {};

    const dispatch = useDispatch();
    const history = useHistory();

    const [isGlobalDataLoaded, setIsGlobalDataLoaded] = useState(false);


    const actorId = useSelector((state: IState) => state.actor).id;
    const useShop = useSelector(usesShop);
    const isChannelDataLoaded = !!useSelector(getChannelId);
    const isLiveChatEnabled = useSelector(usesLiveChat);
    const isSimpleChatActive = useSelector(usesSimpleChat);
    const actorRights = useSelector(getActorRights);
    const isAdmin = useSelector(isActorAdmin);
    const isTestAccount = useSelector(isTestAccountSelector);

    const [loadingCounter, setLoadingCounter] = useState(0);
    const previousLoadingCounter = usePrevious(loadingCounter);
    const [, setLoadingError] = useState(false);

    const incrementLoadingCounter = useCallback(() => {
        setLoadingCounter(value => value + 1);
    }, []);

    const decrementLoadingCounter = useCallback(() => {
        setLoadingCounter(value => value - 1);
    }, []);

    const dispatchPromise = useCallback((method: Function, params?: any) => {
        incrementLoadingCounter();
        Promise.resolve(dispatch(method(params)))
            .catch(() => setLoadingError(true))
            .finally(decrementLoadingCounter);
    }, [decrementLoadingCounter, dispatch, incrementLoadingCounter]);

    useEffect(() => {
        if (actorProfile) {
            dispatch(getProfile());
        }
    }, [dispatch, actorProfile]);

    // useEffect(() => {
    //     if (loadingError) {
    //         dispatch(logout());
    //     }
    // }, [dispatch, loadingError]);

    useEffect(() => {
        if (previousLoadingCounter && !loadingCounter) {
            setIsGlobalDataLoaded(true);
        }
    }, [loadingCounter, previousLoadingCounter]);

    useEffect(() => {
        if (actorId) {
            const promises: any[] = [];

            // Conditionally add request actions
            internalNews && promises.push(getInternalNews);
            accounting && promises.push(getAccount);
            accountingInfo && promises.push(getAccountInfo); // necessary to detect account blocking state!!
            channels && promises.push(getChannels);
            multiChannelInfo && promises.push(getMultiChannelInfo);

            // wait for global data to be loaded
            incrementLoadingCounter();
            Promise.all(promises.map(promise => dispatch(promise())))
                .then(() => decrementLoadingCounter())
                .catch(() => setLoadingError(true));
        }
    }, [
        dispatch,
        actorId,
        incrementLoadingCounter,
        decrementLoadingCounter,
        internalNews,
        accounting,
        accountingInfo,
        channels,
        multiChannelInfo,
    ]);

    // all agents of the customer (used in settings/agents and assignment agent list, not for simple chat)
    useEffect(() => {
        if (agents && isChannelDataLoaded && actorRights.includes(ERights.AgentGetOthers)) {
            dispatchPromise(getAgents, !isSimpleChatActive);
        }
    }, [agents, isChannelDataLoaded, isSimpleChatActive, actorRights, dispatchPromise]);

    // get agents and roles of the current channel (if the actor has 'channelSettings' right, this will get
    // all roles of the customer)
    useEffect(() => {
        if (agents && actorRights.includes(ERights.AgentGetOthers)) {
            dispatchPromise(getChannelAgents);
        }
    }, [agents, actorRights, dispatchPromise]);

    // load custom fields
    useEffect(() => {
        if (customFields && actorRights.includes(ERights.ReadCustomFields)) {
            dispatchPromise(getCustomFields);
        }
    }, [customFields, actorRights, dispatchPromise]);

    // get channel labels (not for simple chat)
    useEffect(() => {
        if (channelLabels && isChannelDataLoaded && !isSimpleChatActive) {
            dispatchPromise(getChannelLabels);
        }
    }, [dispatchPromise, isSimpleChatActive, channelLabels, isChannelDataLoaded]);

    // get actor's tickets
    useEffect(() => {
        if (actorTickets && actorId && isChannelDataLoaded && !isSimpleChatActive) {
            dispatchPromise(getActorTickets, actorId);
        }
    }, [actorTickets, actorId, dispatchPromise, isChannelDataLoaded, isSimpleChatActive]);

    // load livechat setup if enabled
    useEffect(() => {
        if (liveChatSetup && isLiveChatEnabled) {
            dispatchPromise(getLiveChatSetup);
        }
    }, [liveChatSetup, isLiveChatEnabled, dispatchPromise]);

    // load shop config if enabled
    useEffect(() => {
        if (shopConfig && useShop) {
            dispatchPromise(readShopConfig);
        }
    }, [dispatchPromise, shopConfig, useShop]);

    // load simple chat stats
    useEffect(() => {
        if (simpleChatStatistics && isSimpleChatActive) {
            dispatchPromise(getSimpleChatStatistics);
        }
    }, [simpleChatStatistics, isSimpleChatActive, dispatchPromise]);

    // load available pricing information
    useEffect(() => {
        if (isTestAccount && isAdmin && accounting) {
            dispatchPromise(getPricingInformation);
        }
    }, [isAdmin, isTestAccount, accounting, dispatchPromise]);

    // remove query params for switching channel after load, otherwise user gets stuck!
    useEffect(() => {
        const isSwitchingChannel = getUrlParameter(QUERY_PARAM_SWITCH_CHANNEL);
        const isLoginViaToken = getUrlParameter(QUERY_PARAM_TOKEN);

        if ((isSwitchingChannel || isLoginViaToken) && isGlobalDataLoaded) {
            history?.replace(history.location.pathname);
        }
    }, [isGlobalDataLoaded, history]);

    return isGlobalDataLoaded;
};

export default useGlobalData;
