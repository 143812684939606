import moment from 'moment';
import {Reducer} from 'redux';
import translations from '../../../providers/TranslationProvider/translations';
import {IGetMiaStatisticActionFulfilled} from '../../../definitions/mia/mia.actions.definitions';
import {IMiaStatistic, IMiaStatisticsById, IMiaStatisticsState} from '../../../definitions/mia/mia.definitions';
import {EChartInterval} from '../../../enums/chart/EChartInterval';
import {MIA_GET_STATISTICS_FULFILLED} from './statistic.types';

const noData = {
    noData: 0,
};

export const statisticInitState: IMiaStatisticsState = {
    [EChartInterval.Days]: {
        ids: [],
        byId: {} as IMiaStatisticsById,
    },
    [EChartInterval.Weeks]: {
        ids: [],
        byId: {} as IMiaStatisticsById,
    },
    [EChartInterval.Months]: {
        ids: [],
        byId: {} as IMiaStatisticsById,
    },
};

enum EDateOutputFormats {
    days = 'Do MMMM',
    weeks = 'WW',
    months = 'MMMM',
}

enum EDateInputFormats {
    days = 'YYYY-DDD',
    weeks = 'YYYY-WW',
    months = 'YYYY-M',
}

export const statisticReducer: Reducer<IMiaStatisticsState, IGetMiaStatisticActionFulfilled> = (
    state = statisticInitState,
    action,
) => {
    if (action.type === MIA_GET_STATISTICS_FULFILLED) {
        const interval = action.meta.interval;

        const dateFormatted = Object.values(action.payload).map((dataPoint, index) => {
            const key = Object.keys(action.payload)[index];
            const formattedTimeDays: string = moment(key, EDateInputFormats.days).format(EDateOutputFormats.days);
            const formattedTimeWeeks = `${translations.week} ${moment(key, EDateInputFormats.weeks).format(
                EDateOutputFormats.weeks,
            )}`;
            const formattedTimeMonths: string = moment(key, EDateInputFormats.months).format(EDateOutputFormats.months);

            // in some rare cases there can actually be no data available
            if (dataPoint && dataPoint !== 'no data') {
                switch (interval) {
                    case EChartInterval.Days: {
                        return {
                            ...dataPoint,
                            name: formattedTimeDays,
                        };
                    }
                    case EChartInterval.Weeks: {
                        return {
                            ...dataPoint,
                            name: formattedTimeWeeks,
                        };
                    }
                    case EChartInterval.Months: {
                        return {
                            ...dataPoint,
                            name: formattedTimeMonths,
                        };
                    }
                }
            } else {
                switch (interval) {
                    case EChartInterval.Days: {
                        return {
                            name: formattedTimeDays,
                            total: noData,
                        };
                    }
                    case EChartInterval.Weeks: {
                        return {
                            name: formattedTimeWeeks,
                            total: noData,
                        };
                    }
                    case EChartInterval.Months: {
                        return {
                            name: formattedTimeMonths,
                            total: noData,
                        };
                    }
                }
            }

            console.error(`Failed to process data point '${key}': ${dataPoint}`);
            return {
                name: '',
                total: noData,
            };
        });

        const actionPayloadFormatted: {[key: string]: IMiaStatistic} = {};

        Object.keys(action.payload).forEach((key, index) => (actionPayloadFormatted[key] = dateFormatted[index]));

        return {
            ...state,
            [interval]: {
                ids: Object.keys(action.payload),
                byId: {
                    ...actionPayloadFormatted,
                },
            },
        };
    }
    return state;
};
