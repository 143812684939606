import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import classNames from 'classnames';
import {IPaperTemplateProps} from '../../definitions/components/paperTemplate.definitions';

import LoaderSpinner from '../Loader/LoaderSpinner';
import {FC} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {OVERLAY_LOADER_DEFAULT_SIZE} from '../OverlayLoader/OverlayLoader';
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";

export const CENTER_TYPES = {
    NONE: false,
    CENTER: true,
    VERTICAL: 'vertical',
    HORIZONTAL: 'horizontal',
};

const useStyles = makeStyles<Theme, IPaperTemplateProps>((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 auto',
            alignSelf: 'flex-start',
            maxHeight: '100%',
            maxWidth: '100%',
            position: 'relative',
        },
        root_noFullWidth: {
            maxWidth: 600 + theme.spacing(4),
        },
        rootFullHeight: {
            height: '100%',
        },
        rootStretch: {
            alignSelf: 'stretch',
        },
        rootGutterBottom: {
            marginBottom: theme.spacing(2),
        },
        content: {
            padding: theme.spacing(3),
            cursor: 'initial',
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
            overflow: ({contentOverflow}) => contentOverflow,
        },
        contentFullHeight: {
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            height: '100%', // without this, the div is 0px height in safari
        },
        contentFullHeightNoFlex: {
            flex: '1 1 auto',
            flexDirection: 'column',
        },
        contentNoTopPadding: {
            paddingTop: 0,
        },
        contentNoBottomPadding: {
            paddingBottom: 0,
        },
        contentNoPadding: {
            padding: 0,
        },
        contentCentered: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        contentCenteredVertical: {
            display: 'flex',
            justifyContent: 'center',
        },
        contentCenteredHorizontal: {
            display: 'flex',
            alignItems: 'center',
        },
        contentScrollable: {
            overflow: 'auto',
            cursor: 'initial',
            '-webkit-overflow-scrolling': 'touch',
        },
        // This is for the special case in DragNDrop with 2 columns where each Paper should be stretched but no larger
        // than the available page. Since this is not possible by the flexbox specifications, we need this 'hack' to
        // accomplish our special behaviour. The zero-height allows the "align-items: stretch" rule on each Column to
        // take no more space than available for the Papers while allowing the Papers content to be scrolled without
        // using overflow: auto on the Paper itself, which would cut off the shadows and makes need of even more ugly
        // hacks that result in ugly layouts.
        contentInsideFlexStretch: {
            height: 0,
        },
        loaderContainer: {
            height: '100%',
            width: '100%',
            position: 'absolute',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        loaderContainerOverlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: theme.palette.background.paper,
            opacity: theme.palette.action.disabledOpacity,
        },
        isCollapsed: {
            display: 'none',
        },
    }),
);

const PaperTemplate: FC<IPaperTemplateProps> = props => {
    const {
        children,
        fullHeight,
        fullHeightNoFlex,
        noFullWidth,
        titleBar,
        disableScroll,
        isInsideFlexStretch,
        noTopPadding,
        noBottomPadding,
        noPadding,
        stretch,
        centerContent,
        elevation,
        gutterBottom,
        showLoader,
        isCollapsed,
        contentOverflow,
        onScroll,
        className,
        ...rest // necessary for D&D
    } = props;

    const classes = useStyles(props);

    const rootClassNames = classNames(
        classes.root,
        noFullWidth && classes.root_noFullWidth,
        fullHeight && classes.rootFullHeight,
        stretch && classes.rootStretch,
        gutterBottom && classes.rootGutterBottom,
        className,
    );

    const contentClassNames = classNames(
        classes.content,
        fullHeight && classes.contentFullHeight,
        fullHeightNoFlex && classes.contentFullHeightNoFlex,
        noTopPadding && !noPadding && classes.contentNoTopPadding,
        noBottomPadding && !noPadding && classes.contentNoBottomPadding,
        noPadding && classes.contentNoPadding,
        centerContent === CENTER_TYPES.CENTER && classes.contentCentered,
        centerContent === CENTER_TYPES.HORIZONTAL && classes.contentCenteredHorizontal,
        centerContent === CENTER_TYPES.VERTICAL && classes.contentCenteredVertical,
        isInsideFlexStretch && classes.contentInsideFlexStretch,
        !disableScroll && classes.contentScrollable,
        isCollapsed && classes.isCollapsed,
    );

    return (
        <Paper {...rest} className={rootClassNames} elevation={elevation}>
            <ErrorBoundary>
                {titleBar}
                {children && (
                    <>
                        {!isCollapsed && <Divider/>}
                        <div className={contentClassNames} onScroll={onScroll}>{children}</div>
                    </>
                )}
                {Boolean(showLoader) && (
                    <div className={classes.loaderContainer}>
                        <div className={classes.loaderContainerOverlay}/>
                        <LoaderSpinner proportions={OVERLAY_LOADER_DEFAULT_SIZE}/>
                    </div>
                )}
            </ErrorBoundary>
        </Paper>
    );
};

PaperTemplate.displayName = 'PaperTemplate';

export default PaperTemplate;
