import {
    IStatisticsAgents,
    IStatisticsBlackList,
    IStatisticsChatbots,
    IStatisticsChats,
    IStatisticsMia,
    IStatisticsState,
    IStatisticsTickets,
    TStatisticsAutomation,
} from '../../definitions/statistics/statistics.definitions';

export const chatsChartSettingsInitialState: IStatisticsChats = {
    total_sent_messages: 0,
    total_sent_messages_in_ticket: 0,
    total_received_messages: 0,
    total_received_messages_in_ticket: 0,
    average_chats_per_ticket: 0,
    average_outgoing_chats_per_ticket: 0,
    average_outgoing_agent_chats_per_ticket: 0,
    average_outgoing_bot_chats_per_ticket: 0,
    average_incoming_chats_per_ticket: 0,
    total_sent_WA_notifications: 0,
    total_bot_answered_messages: 0,
    isLoading: false,
};

export const ticketsChartSettingsInitialState: IStatisticsTickets = {
    total_opened_tickets: 0,
    total_closed_tickets: 0,
    average_open_tickets: 0,
    total_forwardings: 0,
    average_forwardings_per_ticket: 0,
    average_involved_agents_per_ticket: 0,
    average_duration_until_first_assignment: 0,
    average_duration_until_ticket_answered: 0,
    average_duration_until_initial_agent_reaction: 0,
    average_duration_until_followup_agent_reactions: 0,
    average_duration_until_user_reaction: 0,
    average_duration_until_ticket_closed: 0,
    average_chats_when_ticket_closed: 0,
    isLoading: false,
};

export const chatbotsChartSettingsInitialState: IStatisticsChatbots = {
    totalCount: 0,
    successCount: 0,
    searches: {},
    isLoading: false,
};

export const statisticsBlackListInitialSettings: IStatisticsBlackList = {
    isLoading: false,
    loaded: false,
};

export const miaStatisticsInitialSettings: IStatisticsMia = {
    mau: {},
    isLoading: false,
};

const agentStatsInitialState: IStatisticsAgents = {
    stats: {},
    filter: [],
    loaded: false,
    authorized: true,
};

export const automationStatisticsInitialState: TStatisticsAutomation = {};

export const statisticsInitialState: IStatisticsState = {
    chats: chatsChartSettingsInitialState,
    tickets: ticketsChartSettingsInitialState,
    chatbots: chatbotsChartSettingsInitialState,
    mia: miaStatisticsInitialSettings,
    customCharts: [],
    blackList: statisticsBlackListInitialSettings,
    agents: agentStatsInitialState,
    users: {
        stats: [],
        loaded: false,
        authorized: true,
    },
    notifications: {
        stats: [],
        loaded: false,
    },
    labels: {
        stats: [],
        loaded: false,
    },
    automation: automationStatisticsInitialState,
    clicks: {
        stats: {},
        loaded: false,
        authorized: true,
    },
    chatblocks: {
        stats: {},
        loaded: false,
        authorized: true,
    },
};
