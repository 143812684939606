import {Dispatch} from 'redux';

import {ENotificationLevel} from '../enums/notifications/ENotificationLevel';
import {addNotification} from '../redux/notifications/notifications.actions';
import {translateSlug} from '../utils/translation/translation.utils';
import {request} from './Api/Api';

export const registerAccountRequest = (
    email: string,
    password: string,
    language = 'en',
    affiliate?: string,
    data: {[key: string]: any} = {},
) => {
    const params = {
        email,
        password,
        language,
        affiliate_hash: affiliate,
        data,
    };

    return request({method: 'POST', url: 'auth/local/register'}, params, true);
};

export const resendVerificationMail = () => (dispatch: Dispatch) =>
    request({method: 'POST', url: 'auth/resend-verification-mail'})?.then((result: any) => {
        dispatch(
            addNotification(
                translateSlug('email_has_been_sent_to_x', result.email_address),
                ENotificationLevel.Success,
            ),
        );
    });

// throw for redux-form's asyncValidation
export const checkIfEmailExistsRequest = (email: string) =>
    request({method: 'GET', url: 'auth/check-email-exists'}, {email}, true)?.catch(error => {
        return {username: error.data.error};
    });


