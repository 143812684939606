import {EBaseRoutes} from "../enums/routes/ERoutes";
import {isChatUpdateStreamPayload} from "../redux/tickets/tickets.reducer.utils";
import {isUserPropertyUpdate} from "../redux/user/user.reducer";
import {ITicket} from "../definitions/tickets/tickets.definitions";
import {IUpdateStreamPayload} from "../definitions/updateStream/updateStream.definitions";
import {matchPath} from "../utils/routes/routes.utils";

interface IsRelevantForCurrentActorOrPageProps {
    useTickets: boolean;
    actorTickets?: ITicket[];
    payload: IUpdateStreamPayload<any>;
    userId?: number;
}

export const isRelevantForCurrentActorOrPage = (
    {
        useTickets,
        actorTickets,
        payload,
        userId,
    }: IsRelevantForCurrentActorOrPageProps
) => {
    const pathname = window.location.pathname;

    // always consume update stream on the following pages, because they rely on live data
    if (
        matchPath(pathname, EBaseRoutes.TicketList)
        || matchPath(pathname, EBaseRoutes.User)
        || matchPath(pathname, EBaseRoutes.Assignment)
    ) {
        return true;
    }

    if (!useTickets) {
        return true;
    }

    // get the user id from the update stream payload
    let payloadUserId: number | undefined = undefined;

    if (isChatUpdateStreamPayload(payload)) {
        payloadUserId = payload.message.payload.user_id;
    }
    if (isUserPropertyUpdate(payload)) {
        payloadUserId = payload.message.payload.id;
    }

    // always consume update streams that don't have a user id, so it might be valuable
    if (!payloadUserId) {
        return true;
    }

    // check if a conversation of the user is open in chat page
    if (payloadUserId && matchPath(pathname, EBaseRoutes.Chat) && userId === payloadUserId) {
        return true;
    }

    // check if actor has a ticket assigned that belongs to the user
    if (actorTickets?.find(ticket => ticket.user_id === payloadUserId)) {
        return true;
    }

    // eslint-disable-next-line no-console
    console.debug(`ignore update stream for user ${payloadUserId}`);

    return false;
};
