import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {animationDuration} from './DrawerMenuItems/ChannelMenu/ChannelMenu';
import {TooltipHelper} from './DrawerMenuItems/UserMenu/UserMenu';

export const useSharedStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            overflowX: 'hidden',
        },
        tooltipHelper: {
            ...TooltipHelper,
        },
        avatar: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            transition: `margin ${animationDuration}ms ease-in-out`,
            marginRight: 16,
            fontSize: 13,
        },
        gutters: {
            paddingLeft: theme.spacing(2),
        },
        name: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        button: {
            position: 'relative',
            padding: 16,
            paddingRight: 40, // because of the absolute positioned icon to the right not claiming its required space
            '& span': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
        list: {
            padding: 0,
        },
        expandIcon: {
            // use absolute position to prevent the icon jumping on scrollbar toggle
            position: 'absolute',
            left: 204,
        },
    }),
);
