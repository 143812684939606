import {FC, ReactNode, useCallback, useEffect, useState} from 'react';

import Box from '@material-ui/core/Box';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';

import ContainedButton from '../../components/Buttons/ContainedButton';
import IconFactory from '../../components/IconFactory/IconFactory';
import LinkifyText from '../../components/LinkifyText/LinkifyText';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';
import PaperTemplate from '../../components/PaperTemplate/PaperTemplate';
import PaperTitleBar from '../../components/PaperTitleBar/PaperTitleBar';
import translations from '../../providers/TranslationProvider/translations';
import Twemoji from '../../components/Twemoji/Twemoji';
import WelcomeNotificationForm from '../../components/WelcomeNotificationForm/WelcomeNotificationForm';
import WidgetDirectLinks from '../../components/WidgetDirectLinks/WidgetDirectLinks';
import {IOnobardingStep} from '../../definitions/accounting/accounting.definitions';
import {EIcons} from '../../enums/icons/EIcons';
import {getEndOfTestPeriod, isTestAccount} from '../../redux/accounting/accounting.selectors';
import {IState} from '../../redux/root.reducer';
import {hasWidgets} from '../../redux/settings/widgetEditor/widgetEditor.selectors';
import {resendVerificationMail} from '../../requests/registerRequests';
import {useTypedSelector} from '../../utils/hooks/useTypedSelector';
import {getTestPhaseText} from "../SettingsPage/components/AccountInformationSettings/components/AccountInformationPanel/TestPhaseInformation/TestPhaseInformation";
import {getOnboardingStatus} from "../../redux/onboarding/onboarding.actions";
import {getMessengers} from "../../redux/settings/messenger/messenger.actions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        stepper: {
            userSelect: 'none',
            paddingLeft: 0,
            paddingRight: 0,
        },
        stepHeader: {
            paddingLeft: 4,
        },
        stepHeaderClickable: {
            cursor: 'pointer !important',
        },
        stepContainer: {
            '& > a': {
                color: theme.palette.primary.main,
            },

            '& > svg': {
                width: 50,
                height: 50,
                color: theme.palette.primary.main,
                fill: theme.palette.primary.main,
                marginRight: theme.spacing(4),
                transition: 'all ease-out 0.2s',
            },
        },
        stepHeadline: {
            marginBottom: theme.spacing(0.5),
        },
        stepActions: {
            marginTop: theme.spacing(1),
        },
    }),
);

const getActiveStep = (steps: IOnobardingStep[]) => {
    for (let index = 0; index < steps.length; index++) {
        if (!steps[index].completed) {
            return index;
        }
    }
    return -1;
};

const OnboardingStepsToComplete: FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const endOfTestPeriod = useSelector(getEndOfTestPeriod);

    const hasWidget = useSelector(hasWidgets);
    const testAccount = useSelector(isTestAccount);
    const steps = useSelector((state: IState) => state.onboarding.steps.items);
    const [activeStep, setActiveStep] = useState(0);

    const loading = useTypedSelector(state => state.onboarding.steps.loading);

    const handleClick = useCallback(
        (url: string) => {
            // different handling of _values like resend mail (perhaps more special cases to come)
            if (url === '_sendVerification') {
                dispatch(resendVerificationMail());
                return;
            }
            if (url.substring(0, 4) === 'http') {
                window.open(url, '_blank');
                return;
            }
            history.push({
                pathname: url,
                search: '?wiz=1',
            });
        },
        [history, dispatch],
    );

    useEffect(() => {
        dispatch(getOnboardingStatus());
        dispatch(getMessengers());
    }, [dispatch]);

    useEffect(() => {
        setActiveStep(getActiveStep(steps));
    }, [steps]);

    if (!steps?.length) {
        return null;
    }

    return (
        <PaperTemplate titleBar={<PaperTitleBar title={translations.headline_dashboard_steps_to_complete} />} stretch>
            <Typography variant="body2">
                {testAccount && `${getTestPhaseText(endOfTestPeriod)} `}
                <Box component="span" whiteSpace="pre-wrap">
                    <LinkifyText emojify text={translations.onboarding_step_guide_description_link} />
                </Box>
            </Typography>
            <OverlayLoader isLoading={loading}>
                <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper}>
                    {steps.map((step: IOnobardingStep, index: number) => {
                        const isActiveStep = index === activeStep;
                        const canOpen = !isActiveStep && !step.completed;

                        let specialStepContent: ReactNode = null;

                        // show special content according to step
                        if (step.slug_header === 'onboarding_status_tickets_header') {
                            if (hasWidget) {
                                specialStepContent = (
                                    <>
                                        <WidgetDirectLinks gutterBottom />
                                        <WelcomeNotificationForm />
                                    </>
                                );
                            } else {
                                specialStepContent = <WelcomeNotificationForm />;
                            }
                        }

                        return (
                            <Step key={index} completed={step.completed}>
                                <StepLabel
                                    className={classNames(classes.stepHeader, {[classes.stepHeaderClickable]: canOpen})}
                                    onClick={canOpen ? () => setActiveStep(index) : undefined}
                                >
                                    <Twemoji>{translations[step.slug_header]}</Twemoji>
                                </StepLabel>
                                <StepContent>
                                    <div className={classes.stepContainer}>
                                        <Typography variant="body2">{translations[step.slug_description]}</Typography>
                                        <div className={classes.stepActions}>
                                            {specialStepContent || (
                                                !!step.url && step.button_caption && (
                                                    <ContainedButton
                                                        startIcon={IconFactory.getIconWithProps(EIcons.SETTINGS)}
                                                        label={translations[step.button_caption]}
                                                        onClick={() => handleClick(step.url)}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </div>
                                </StepContent>
                            </Step>
                        );
                    })}
                </Stepper>
            </OverlayLoader>
        </PaperTemplate>
    );
};

export default OnboardingStepsToComplete;
