import {Reducer} from 'redux';

import {ITargetingRequestState} from '../../../definitions/targeting/targeting.definitions';
import {TARGET_GROUPS_GET_FULFILLED, TARGET_GROUPS_GET_PENDING} from "../../targetGroup/targetGroup.types";
import {TARGETING_GET_CONFIGURATION_FULFILLED, TARGETING_GET_CONFIGURATION_PENDING} from "../targeting.types";

export const targetingRequestInitialState: ITargetingRequestState = {
    loading: {
        targetingList: false,
        targetingConfig: false,
    },
};

export const requestReducer: Reducer<ITargetingRequestState> = (state = targetingRequestInitialState, action) => {
    switch (action.type) {
        case TARGET_GROUPS_GET_PENDING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    targetingList: true,
                },
            };
        case TARGET_GROUPS_GET_FULFILLED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    targetingList: false,
                },
            };
        case TARGETING_GET_CONFIGURATION_FULFILLED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    targetingConfig: false,
                },
            };
        case TARGETING_GET_CONFIGURATION_PENDING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    targetingConfig: true,
                },
            };
        default:
            return state;
    }
};
