import {Fragment, MouseEvent, PureComponent} from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import translations from '../../../../providers/TranslationProvider/translations';
import {IActionMenuItem, ITableRow} from '../../../../definitions/components/tableConfig.definitions';
import {EIcons} from '../../../../enums/icons/EIcons';
import CustomIconButton from '../../../CustomIconButton/CustomIconButton';
import {IconButtonProps} from "@material-ui/core";

interface IActionMenuProps<Item extends ITableRow> {
    items: IActionMenuItem<Item>[];
    row: Item;
    onOpen?: (value: any) => any;
    tooltip?: string;
    edge?: IconButtonProps['edge'],
}

class ActionMenu<T extends ITableRow> extends PureComponent<IActionMenuProps<T>> {
    state = {
        anchorEl: null,
    };

    handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        this.setState({anchorEl: event.currentTarget});
        if (this.props.onOpen && this.props.row.id) {
            this.props.onOpen(this.props.row.id);
        }
    };

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    render() {
        const {items, row, tooltip, edge} = this.props;
        const {anchorEl} = this.state;
        const isOpen = Boolean(anchorEl);
        const ariaId = 'long-menu';

        return (
            <Fragment>
                <CustomIconButton
                    aria-label={translations.more}
                    aria-owns={isOpen ? ariaId : undefined}
                    aria-haspopup="true"
                    onClick={this.handleClick}
                    icon={EIcons.VERTICAL_POINTS}
                    iconProps={{fontSize: 'small'}}
                    tooltip={tooltip || ''}
                    edge={edge}
                />
                <Menu id={ariaId} anchorEl={anchorEl} open={isOpen} onClose={this.handleClose}>
                    {items.map((item, idx) => {
                        const disabled =
                            item.disabled === true || (typeof item.disabled === 'function' && item.disabled(row));

                        return (
                            <MenuItem
                                key={idx}
                                disabled={disabled}
                                onClick={() => {
                                    this.handleClose();
                                    if (item.onClick) {
                                        item.onClick(row);
                                    }
                                }}
                            >
                                {item.label}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </Fragment>
        );
    }
}

export default ActionMenu;
