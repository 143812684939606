import {FC} from 'react';
import {CircularProgress} from '@material-ui/core';
import Button, {ButtonProps} from '@material-ui/core/Button';

interface IContainedButtonProps extends ButtonProps {
    label: string;
    loading?: boolean;
}

const ContainedButton: FC<IContainedButtonProps> = props => {
    const {label, variant = 'contained', color = 'primary', onClick = () => undefined, loading, disabled, ...rest} = props;

    return (
        <Button variant={variant} color={color} onClick={onClick} disabled={loading || disabled} {...rest}>
            {loading ? <CircularProgress size={24} color={'inherit'}/> : label}
        </Button>
    );
};

Object.freeze({
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
});

export default ContainedButton;
