import {Dispatch} from 'redux';
import {IState} from '../root.reducer';
import {usesSimpleChat, usesTicketSystem} from "../channel/channel.selectors";
import {isRelevantForCurrentActorOrPage} from "../../socket/socket.utils";
import {selectActorTickets} from "../actor/actor.selectors";
import {getChatPageUser} from "../chatPage/chatPage.selectors";
import {UpdateStreamMessage} from "@sinch-engage/mcp-update-stream";
import {UpdateStreamMessage as UpdateStreamBusMessage} from "@sinch/bus/lib/messages/update-steam";
import {IUpdateStreamPayload} from "../../definitions/updateStream/updateStream.definitions";
import {
    SOCKET_CONNECTED,
    SOCKET_DISCONNECTED,
    SOCKET_RECONNECT,
    SOCKET_RESET_ACTION,
    UPDATE_STREAM,
} from "./connection.types";

export const createConnectedAction = (deviceId: string) => ({type: SOCKET_CONNECTED, payload: deviceId});
export const createDisconnectedAction = () => ({type: SOCKET_DISCONNECTED});
export const createReconnectAction = () => ({type: SOCKET_RECONNECT});
export const resetSocketAction = () => ({type: SOCKET_RESET_ACTION});

const dispatchIfRelevant = (payload: IUpdateStreamPayload<any>, enablePerformanceCheck?: boolean) => (dispatch: Dispatch, getState: () => IState) => {
    const state = getState();

    const useTickets = usesTicketSystem(state);
    const actorTickets = selectActorTickets(state);
    const chatPageUser = getChatPageUser(state);

    // To optimize the overall UI performance, we pre-check the update stream's payload and only
    // dispatch an UPDATE_STREAM action if it is relevant to the current actor or page content.
    if (!enablePerformanceCheck || isRelevantForCurrentActorOrPage({
        useTickets,
        actorTickets,
        payload,
        userId: chatPageUser?.id,
    })) {
        return dispatch({
            type: UPDATE_STREAM,
            payload,
            meta: {
                actorId: state.actor.id,
                activeConversationId: state.chatPage.activeConversationId,
                usesSimpleChat: usesSimpleChat(state),
            },
        });
    }
};

export const createUpdateAction = (payload: { message: UpdateStreamMessage }, enablePerformanceCheck?: boolean) => dispatchIfRelevant(payload, enablePerformanceCheck);
export const createUpdateActionFromMessageBusMessage = (message: UpdateStreamBusMessage, enablePerformanceCheck?: boolean) => dispatchIfRelevant({
    message: {
        payload: message.payload || {},
        ...(message.meta || {} as any),
    },
}, enablePerformanceCheck);
