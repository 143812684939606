import {SvgIconProps} from '@material-ui/core';
import {FC} from 'react';
import {EMessenger} from '../../enums/messenger/EMessenger';
import CustomMessenger from './Messengers/CustomMessenger';
import FacebookMessengerIcon from './Messengers/FacebookMessengerIcon';
import IMessageIcon from './Messengers/IMessageIcon';
import NotifyIcon from './Messengers/NotifyIcon';
import TelegramIcon from './Messengers/TelegramIcon';
import ViberIcon from './Messengers/ViberIcon';
import WBIcon from './Messengers/WBIcon';
import LiveChatIcon from './Messengers/LiveChatIcon';
import InstagramIcon from './Messengers/InstagramIcon';
import RCSIcon from "./Messengers/RCSIcon";
import {LineIcon} from "./Messengers/LineIcon";

export interface MessengerIconProps extends SvgIconProps {
    messenger?: EMessenger;
    tooltip?: boolean;
}

const MessengerIcon: FC<MessengerIconProps> = props => {
    const {messenger, ...iconProps} = props;

    switch (messenger) {
        case EMessenger.WhatsApp:
        case EMessenger.WhatsAppBusiness:
            return <WBIcon {...iconProps} />;
        case EMessenger.TelegramChannel:
        case EMessenger.Telegram:
            return <TelegramIcon {...iconProps} />;
        case EMessenger.IMessage:
            return <IMessageIcon {...iconProps} />;
        case EMessenger.FacebookMessenger:
            return <FacebookMessengerIcon {...iconProps} />;
        case EMessenger.InstaAndNotify:
        case EMessenger.Insta2:
            return <NotifyIcon {...iconProps} />;
        case EMessenger.Viber:
            return <ViberIcon {...iconProps} />;
        case EMessenger.Instagram:
            return <InstagramIcon {...iconProps} />;
        case EMessenger.CustomMessenger:
            return <CustomMessenger {...iconProps} />;
        case EMessenger.LiveChat:
            return <LiveChatIcon {...iconProps} />;
        case EMessenger.RCS: // TODO replace icon
            return <RCSIcon {...iconProps} />;
        case EMessenger.Line:
            return <LineIcon {...iconProps} />;
        default:
            return null;
    }
};

export default MessengerIcon;
