import LocalizedStrings, {LocalizedStringsMethods} from 'react-localization';
import {reportErrorRequest} from "../../requests/Api/Api";
import isEmpty from "lodash/isEmpty";

export interface ITranslations extends LocalizedStringsMethods {
    [x: string]: any;
}

const localization: ITranslations = new LocalizedStrings({en: {}});

const reportedSlugs: {[key: string]: string} = {};

// Create proxy around translations to prevent errors caused by untranslated slugs and to report them
const translations = new Proxy(localization, {
    get(obj, prop) {
        const slug = String(prop);

        // Skip if prop is undefined, empty or the slug has spaces in it (can happen for generic translations).
        // Also skip if not loaded yet ($$typeof).
        if (!prop || prop === '$$typeof' || slug.indexOf(' ') !== -1) {
            return;
        }

        const translatedString = obj[slug];
        const language = obj.getLanguage();

        if (translatedString === undefined) {
            // Only report once if translations have been loaded and not asking for optional generic translation
            try {
                if (reportedSlugs[slug] !== language && !slug.endsWith('_description') && !slug.endsWith('__info') && !isEmpty(obj._props[language])) {
                    reportedSlugs[slug] = language;

                    reportErrorRequest({
                        type: "Translation",
                        message: `Missing translation for slug '${slug}'`,
                        uri: window.location.href,
                    });
                }
            } catch (e) {
                console.warn(e);
            }

            // return empty string
            return '';
        }

        // return translated string
        return obj[slug] || '';
    },
});

export default translations;
