import {FC, useCallback, useMemo, useState} from 'react';

import IconButton from '@material-ui/core/IconButton';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddComment from '@material-ui/icons/AddComment';
import classNames from 'classnames';
import {useDispatch} from 'react-redux';

import CustomIconButton from '../../../../../components/CustomIconButton/CustomIconButton';
import {ITextModule} from '../../../../../definitions/textModule/textModule.definitions';
import {EChatMessage} from '../../../../../enums/chat/EChat';
import {EIcons} from '../../../../../enums/icons/EIcons';
import {ETextModuleStatus} from '../../../../../enums/textModule/ETextModulesStatus';
import {setChatToDelete} from '../../../../../redux/ui/ui.actions';
import {useTypedSelector} from '../../../../../utils/hooks/useTypedSelector';
import AddTextModuleDialog from './AddTextModuleDialog';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            alignSelf: 'center',
        },
        button: {
            marginBottom: theme.spacing(0.5),
        },
    }),
);

interface IChatListMenuProps {
    item: any;
    className?: string;
}

const ChatListButtons: FC<IChatListMenuProps> = ({className, item}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [showTextModuleDialog, setShowTextModuleDialog] = useState(false);

    const isDeletingChatsEnabled = useTypedSelector(state => Boolean(state.channel.delete_chat));
    const showDeleteButton =
        isDeletingChatsEnabled && !!item.id && !item.outgoing && item.chat !== EChatMessage.Deleted;
    const showAddTextModuleButton = Boolean(item.outgoing) && !item.bot && !item.notification;
    const rootClassName = classNames(classes.root, className);

    const addTextModule = useCallback(() => {
        setShowTextModuleDialog(true);
    }, []);

    const handleCloseTextModuleDialog = useCallback(() => {
        setShowTextModuleDialog(false);
    }, []);

    const initialTextModule: ITextModule = useMemo(
        () => ({
            id: 0,
            name: '',
            message: item.chat,
            attachment: item.media,
            status: ETextModuleStatus.Draft,
            usage_count: 0,
        }),
        [item],
    );

    if (!showAddTextModuleButton && !showDeleteButton) {
        return null;
    }

    return (
        <div className={rootClassName}>
            {showAddTextModuleButton && (
                <>
                    <IconButton className={classes.button} onClick={addTextModule}>
                        <AddComment fontSize="small" />
                    </IconButton>
                    <AddTextModuleDialog
                        isOpen={showTextModuleDialog}
                        initialTextModule={initialTextModule}
                        handleClose={handleCloseTextModuleDialog}
                    />
                </>
            )}
            {showDeleteButton && (
                <CustomIconButton
                    onClick={() => dispatch(setChatToDelete(item.id!))}
                    icon={EIcons.TRASH}
                    iconProps={{fontSize: 'small'}}
                    className={classes.button}
                />
            )}
        </div>
    );
};

export default ChatListButtons;
