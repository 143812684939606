export enum EUpdateStreamAction {
    Accounting = 'accounting',
    AccountingPricing = 'accounting_pricing',
    ActorLogin = 'login',
    ActorDeviceLogin = 'device_login',
    Agent = 'agent',
    AgentLogin = 'agent_login',
    AgentLogout = 'agent_logout',
    AgentStatus = 'agent_status',
    AgentCreate = 'agent_create',
    AgentDelete = 'agent_delete',
    AutoRoutes = 'skills_autoroutes',
    BotAbsence = 'bot_absence',
    BotChatblock = 'bot_chatblock',
    Channel = 'channel',
    ChannelApiKey = 'channel_apikey',
    ChannelLabels = 'channel_labels',
    Chat = 'chat',
    ChatBot = 'chat_bot',
    ChatNotification = 'chat_notification',
    ChatReceive = 'received_chat',
    Custom = 'custom',
    EmailVerify = 'verify_email',
    GdprGenerate = 'gdpr_generate',
    GdprSign = 'gdpr_sign',
    Job = 'job',
    InvalidateUsers = 'invalidate_users',
    MiaContent = 'content',
    SignOfLife = 'signoflife',
    Targeting = 'targeting',
    Ticket = 'ticket',
    TicketClose = 'ticket_close',
    TicketLabel = 'ticket_label',
    TicketNote = 'ticket_note',
    TicketOpen = 'ticket_open',
    Token = 'token',
    MultiChannel = 'multi_channel',
    User = 'user',
    UserBlock = 'user_block',
    UserDelete = 'user_delete',
    UserStart = 'user_start',
    UserStop = 'user_stop',
    UserUnblock = 'user_unblock',
    WidgetInfo = 'widget_info',
    PreparedNotes = 'prepared_notes'
}
