export const WIDGET_EDITOR_CHANGE_VALUE = 'WIDGET_EDITOR_CHANGE_VALUE';
export const WIDGET_EDITOR_RESET_CHIP_CONFIG = 'WIDGET_EDITOR_RESET_CHIP_CONFIG';
export const WIDGET_EDITOR_EDIT_WIDGET = 'WIDGET_EDITOR_EDIT_WIDGET';
export const WIDGET_EDITOR_RESET = 'WIDGET_EDITOR_RESET';
export const WIDGET_EDITOR_SET_DEFAULTS = 'WIDGET_EDITOR_SET_DEFAULTS';
export const WIDGET_EDITOR_SAVE_WIDGET = 'WIDGET_EDITOR_SAVE_WIDGET';
export const WIDGET_EDITOR_SAVE_WIDGET_PENDING = 'WIDGET_EDITOR_SAVE_WIDGET_PENDING';
export const WIDGET_EDITOR_SAVE_WIDGET_REJECTED = 'WIDGET_EDITOR_SAVE_WIDGET_REJECTED';
export const WIDGET_EDITOR_SAVE_WIDGET_FULFILLED = 'WIDGET_EDITOR_SAVE_WIDGET_FULFILLED';

export const SETTINGS_WIDGET_SET_STEP = 'SETTINGS_WIDGET_SET_STEP';
export const SETTINGS_WIDGET_RESET = 'SETTINGS_WIDGET_RESET';
export const SETTINGS_WIDGET_GET_ALL = 'SETTINGS_WIDGET_GET_ALL';
export const SETTINGS_WIDGET_GET_ALL_PENDING = 'SETTINGS_WIDGET_GET_ALL_PENDING';
export const SETTINGS_WIDGET_GET_ALL_REJECTED = 'SETTINGS_WIDGET_GET_ALL_REJECTED';
export const SETTINGS_WIDGET_GET_ALL_FULFILLED = 'SETTINGS_WIDGET_GET_ALL_FULFILLED';
export const SETTINGS_WIDGET_GET_POSSIBLE_SETTINGS = 'SETTINGS_WIDGET_GET_POSSIBLE_SETTINGS';
export const SETTINGS_WIDGET_GET_POSSIBLE_SETTINGS_FULFILLED = 'SETTINGS_WIDGET_GET_POSSIBLE_SETTINGS_FULFILLED';
export const SETTINGS_WIDGET_DELETE = 'SETTINGS_WIDGET_DELETE';
export const SETTINGS_WIDGET_SEND_VIBER_MESSAGE = 'SETTINGS_WIDGET_SEND_VIBER_MESSAGE';
export const SETTINGS_WIDGET_SEND_VIBER_MESSAGE_FULFILLED = 'SETTINGS_WIDGET_SEND_VIBER_MESSAGE_FULFILLED';
export const SETTINGS_WIDGET_GET_VIBER_TOKEN = 'SETTINGS_WIDGET_GET_VIBER_TOKEN';
export const SETTINGS_WIDGET_GET_VIBER_TOKEN_FULFILLED = 'SETTINGS_WIDGET_GET_VIBER_TOKEN_FULFILLED';
export const SETTINGS_WIDGET_RESET_VIBER_SUCCESS = 'SETTINGS_WIDGET_RESET_VIBER_SUCCESS';
export const SETTINGS_WIDGET_GET_BY_ID = 'SETTINGS_WIDGET_GET_BY_ID';
export const SETTINGS_WIDGET_GET_BY_ID_FULFILLED = 'SETTINGS_WIDGET_GET_BY_ID_FULFILLED';
