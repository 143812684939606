import {AnyAction, applyMiddleware, combineReducers, ReducersMapObject, Store} from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import {absenceReducer} from './absence/absence.reducer';
import {accountingReducer} from './accounting/accounting.reducer';
import {actorReducer} from './actor/actor.reducer';
import {agentsReducer} from './agents/agents.reducer';
import {assignmentReducer} from './assignmentPage/assignmentPage.reducer';
import {autorouteReducer} from './autoroute/autoroute.reducer';
import {botTopicsEntityReducer, botTopicsRequestReducer} from './botTopics/botTopics.reducer';
import {channelReducer} from './channel/channel.reducer';
import {channelsReducer} from './channel/channels.reducer';
import {chatPageReducer} from './chatPage/chatPage.reducer';
import {connectionReducer} from './connection/connection.reducer';
import {countriesReducer} from './countries/countries.reducer';
import {customFieldReducer} from './customField/customField.reducer';
import {dynamicAnswerReducer} from './dynamicAnswer/dynamicAnswer.reducer';
import {errorMiddleware} from './error/error.middleware';
import {featuresReducer} from './features/features.reducer';
import {customFormReducer} from './form/form.reducer';
import {formUploaderReducer} from './formUploader/formUploader.reducer';
import {languagesReducer} from './languages/languages.reducer';
import {loaderReducer} from './loader/loader.reducer';
import {miaReducer} from './mia/mia.reducer';
import {notesEntityReducer} from './notes/notes.reducer';
import {notificationReducer} from './notifications/notifications.reducer';
import {onboardingReducer} from './onboarding/onboarding.reducer';
import {productsEntityReducer} from './products/products.reducer';
import {pushNotificationMiddleware} from './pushNotifications/pushNotification.middleware';
import {pwrInstallationReducer} from './pwrInstallation/pwrInstallation.reducer';
import {requestReducer} from './request/request.reducer';
import securityWrapper, {securityReducer} from './security/security.reducer';
import {selectedReducer} from './selected/selected.reducer';
import settingsReducer from './settings/settings.reducer';
import {shopReducer} from './shop/shop.reducer';
import {skillReducer} from './skill/skill.reducer';
import {statisticsReducer} from './statistics/statistics.reducer';
import {targetGroupReducer} from './targetGroup/targetGroup.reducer';
import targetingReducer from './targeting/targeting.reducer';
import {textModuleReducer} from './textModule/textModule.reducer';
import {ticketPageReducer} from './ticketPage/ticketPage.reducer';
import {ticketPreviewsEntityReducer, ticketsEntityReducer, ticketsRequestReducer} from './tickets/tickets.reducer';
import {timezoneReducer} from './timezone/timezone.reducer';
import {translationReducer} from './translations/translations.reducer';
import {uiReducer} from './ui/ui.reducer';
import {userEntityReducer, userRequestReducer} from './user/user.reducer';
import userPageReducer from './userPage/userPage.reducer';
import {jobsReducer} from './jobs/jobs.reducer';
import {multiChannelReducer} from './multichannel/multiChannel.reducer';
import {imessageOnboardingReducer} from '../pages/ImessageLandingPage/Redux/ImessageOnboardingReducer';
import dynamicMiddlewares from "./dynamicMiddlewares";
import {configureStore} from "@reduxjs/toolkit";

export const entitiesReducer = combineReducers({
    absences: absenceReducer,
    agents: agentsReducer,
    autoroutes: autorouteReducer,
    botTopics: botTopicsEntityReducer,
    channels: channelsReducer,
    countries: countriesReducer,
    customFields: customFieldReducer,
    dynamicAnswers: dynamicAnswerReducer,
    skills: skillReducer,
    targetGroups: targetGroupReducer,
    textModules: textModuleReducer,
    timezones: timezoneReducer,
    tickets: ticketsEntityReducer,
    ticketPreviews: ticketPreviewsEntityReducer,
    users: userEntityReducer,
    notes: notesEntityReducer,
    jobs: jobsReducer,
    products: productsEntityReducer,
});

export const requestsReducer = combineReducers({
    botTopics: botTopicsRequestReducer,
    tickets: ticketsRequestReducer,
    users: userRequestReducer,
});

export type IEntitiesState = ReturnType<typeof entitiesReducer>;
export type IRequestsState = ReturnType<typeof requestsReducer>;

export const staticReducers = {
    accounting: accountingReducer,
    actor: actorReducer,
    assignmentPage: assignmentReducer,
    channel: channelReducer,
    chatPage: chatPageReducer,
    connection: connectionReducer,
    // customer: customerReducer,
    entities: entitiesReducer,
    features: featuresReducer,
    form: customFormReducer,
    formUploader: formUploaderReducer,
    languages: languagesReducer,
    loaderTransition: loaderReducer,
    mia: miaReducer,
    notifications: notificationReducer,
    onboarding: onboardingReducer,
    pwrInstallation: pwrInstallationReducer,
    request: requestReducer,
    requestNew: requestsReducer,
    selected: selectedReducer,
    settings: settingsReducer,
    statistics: statisticsReducer,
    targeting: targetingReducer,
    ticketPage: ticketPageReducer,
    translations: translationReducer,
    ui: uiReducer,
    userPage: userPageReducer,
    shop: shopReducer,
    security: securityReducer,
    multiChannel: multiChannelReducer,
    imessageOnboarding: imessageOnboardingReducer,
};

const useReduxDevTools = typeof window === 'object'
    && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    && (
        window.location.hostname === 'localhost'
        || window.location.hostname.startsWith('staging')
        || window.location.hostname.endsWith('.ngrok.io')
        || window.location.hostname.endsWith('.preview.messengerpeople.dev')
    );

// noinspection JSUnresolvedVariable
// const composeEnhancers = useReduxDevTools
//     ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
//     : compose;

export const metaMiddleware = () => (next: any) => (action: AnyAction) => {
    next({
        ...action,
        meta: {
            ...action?.meta,
            timestamp: Date.now(),
        },
    });
};

const storeEnhancer =
    applyMiddleware(
        thunkMiddleware,
        errorMiddleware,
        promiseMiddleware,
        metaMiddleware,
        pushNotificationMiddleware,
        dynamicMiddlewares,
    );


// Just create combined state so we can generate the state type from it
const staticCombinedReducers = combineReducers(staticReducers);
export type IStaticState = ReturnType<typeof staticCombinedReducers>;

// Create object to hold the injected reducers
let asyncReducers: ReducersMapObject = {};

// Provide method to create the root reducer from static and injected reducers
export const createRootReducer = () => {
    const initialReducers: ReducersMapObject<IStaticState> = {
        ...staticReducers,
        ...asyncReducers,
    };

    // We wrap the whole reducer in the security wrapper
    return securityWrapper(combineReducers<IStaticState>(initialReducers));
};

// Provide method to inject one or more reducers into the store
export const injectReducer = (store: Store<IStaticState>, reducers: ReducersMapObject) => {
    asyncReducers = {...asyncReducers, ...reducers};

    store.replaceReducer(createRootReducer());
};

// Create initial store (static reducers only), that can be extended via injectReducer at runtime
// TODO: use react toolkit's configureStore
// export const applicationStore: Store<IStaticState> = createStore(createRootReducer(), composeEnhancers(storeEnhancer));
export const applicationStore: Store<IStaticState> = configureStore({
    reducer: createRootReducer(),
    middleware: getDefaultMiddleware => getDefaultMiddleware({serializableCheck: false}) as any,
    enhancers: [storeEnhancer as any],
    devTools: useReduxDevTools,
});

export type IState = ReturnType<typeof applicationStore.getState>;

