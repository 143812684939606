import {FC, memo} from 'react';

import MomentUtils from '@date-io/moment';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {DateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {DateTimePickerProps} from '@material-ui/pickers/DateTimePicker/DateTimePicker';
import {useSelector} from 'react-redux';

import translations from '../../../providers/TranslationProvider/translations';
import {TLanguageCode} from '../../../definitions/translations/translations.definitions';
import {getActorLanguage} from '../../../redux/actor/actor.selectors';

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        dateRangeIcon: {
            color: theme.palette.text.secondary,
            marginRight: theme.spacing(1),
        },
        datePicker: {
            margin: theme.spacing(1),
            width: 220,

            '&:last-child': {
                marginRight: 0,
            },
        },
    }),
);

interface ICustomDateTimePickerProps extends DateTimePickerProps {
    value: number;
    onChange: (any?: any) => void;
    language?: TLanguageCode;
    disablePast?: boolean;
    // those fixes were needed to partially improve the used interface (e.g.: using the correct extends) without completely falling down the rabbit hole and having to fix everything
    onError?: (error?: any, value?: any) => void; // TS fix
    variant?: any; // TS fix
}

export const CustomDateTimePicker: FC<ICustomDateTimePickerProps> = props => {
    const classes = useStyle();
    const {value, onChange, language: LanguageFromProps, disablePast = true, ...custom} = props;
    const language = useSelector(getActorLanguage);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale={LanguageFromProps || language}>
            <DateTimePicker
                value={value * 1000}
                onChange={onChange}
                className={classes.datePicker}
                InputProps={{startAdornment: <DateRangeIcon className={classes.dateRangeIcon} />}}
                format={translations.user_list_datetime_format}
                inputVariant="outlined"
                name="published"
                animateYearScrolling
                disablePast={disablePast}
                ampm={false}
                {...custom}
            />
        </MuiPickersUtilsProvider>
    );
};

export default memo(CustomDateTimePicker);
