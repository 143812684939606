import {FC} from 'react';

import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';

import PaperTemplate from '../../components/PaperTemplate/PaperTemplate';
import PaperTitleBar from '../../components/PaperTitleBar/PaperTitleBar';
import translations from '../../providers/TranslationProvider/translations';
import Twemoji from '../../components/Twemoji/Twemoji';
import {ETitleType} from '../../enums/paper/ETitleType';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        alert: {
            display: 'flex',
            alignItems: 'center',
            padding: `${theme.spacing(1)}px 0`,
        },
        warningIcon: {
            color: theme.palette.warning.main,
            marginRight: theme.spacing(1),
        },
    }),
);

interface IDashboardAlertsProps {
    paymentAlerts: string[];
}

const DashboardAlerts: FC<IDashboardAlertsProps> = props => {
    const {paymentAlerts} = props;

    const classes = useStyles();

    if (paymentAlerts.length === 0) {
        return null;
    }

    return (
        <PaperTemplate
            stretch
            titleBar={<PaperTitleBar title={translations['alerts']} titleType={ETitleType.Warning} />}
        >
            {paymentAlerts.map((notification: any, index: number) => (
                <div key={index} className={classes.alert}>
                    <WarningIcon className={classes.warningIcon} color="inherit" />
                    <Typography color="inherit" variant="body2">
                        <Twemoji>{notification}</Twemoji>
                    </Typography>
                </div>
            ))}
        </PaperTemplate>
    );
};

export default DashboardAlerts;
