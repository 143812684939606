import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PendingIcon from '@material-ui/icons/WatchLater';
import classNames from 'classnames';
import {FC, useMemo} from 'react';
import {translateSlug} from '../../utils/translation/translation.utils';
import {useTypedSelector} from '../../utils/hooks/useTypedSelector';
import HighlightSearchText from '../HighlightSearchText/HighlightSearchText';
import MediaError from '../MediaError/MediaError';
import translations from '../../providers/TranslationProvider/translations';
import MessageMedia from './MessageMedia';
import MessageQuote from './MessageQuote';
import SendStatus from './SendStatus';
import {EMediaTypes} from '../../enums/mia/EMediaTypes';
import {format, isArabic, printError} from '../../utils/chat/chat.utils';
import {IMessageContentItem} from '../../definitions/chat/chat.definitions';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import {NECTARY_COLORS} from '../../theme/muiTheme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            minWidth: 0,
            flexDirection: 'row',
            position: 'relative',
        },
        root_outgoing: {
            flexDirection: 'row-reverse',

            '& $content': {
                color: theme.palette.type === 'dark' ? NECTARY_COLORS.stormy400 : 'inherit',
                background: NECTARY_COLORS.honey200,
            },

            '& a': {
                color: theme.palette.primary.dark,
            },
        },
        content: {
            padding: 8,
            background: theme.palette.background.default,
            borderRadius: 4,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            // we need z-index because of overflow hidden (otherwise video would be over title bars)
            zIndex: 0,
        },
        messageContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            // gap between image and text
            marginTop: theme.spacing(1),
            // only set margin-top, if the message comes after some media content
            '&:first-child': {
                marginTop: 0,
            },
        },
        message: {
            minWidth: 0,
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            fontWeight: 300,
            flex: 1,
            '& b': {
                fontWeight: 500,
            },
        },
        messageRtl: {
            textAlign: 'right',
            direction: 'rtl',
            minWidth: 0,
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            fontWeight: 300,
            flex: 1,
            '& b': {
                fontWeight: 500,
            },
        },
        pendingIcon: {
            height: 16,
        },
        messagesIcons: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            '& svg': {
                fontSize: 14,
                marginLeft: theme.spacing(1),
                marginTop: theme.spacing(0.25),
                alignSelf: 'flex-end',
            },
        },
        notificationIcon: {
            color: theme.palette.text.secondary,
        },
        text: {
            width: '100%',
        },
    }),
);

interface IMessageContentProps {
    item: IMessageContentItem;
    searchTerm?: string;
    showLinkStats?: boolean;
    linkStatsRefId?: number;
}

const MessageContent: FC<IMessageContentProps> = (
    {
        item,
        searchTerm,
        showLinkStats,
        linkStatsRefId,
    },
) => {
    const classes = useStyles();
    const agents = useTypedSelector(state => state.entities.agents);
    const finalText = useMemo(() => format(item, agents.byId), [item, agents.byId]);
    const _isArabic = useMemo(() => isArabic(item.chat), [item.chat]);
    const isPostbackButton = Boolean(item?.meta?.button && item.meta.button !== item.chat);

    let notification;

    try {
        notification = item.chattype === EMediaTypes.Notification && typeof item.media === 'string'
            ? JSON.parse(item.media)
            : null;
    } catch (e) {
        if (e instanceof SyntaxError) {
            printError(e, true, item.id);
        } else {
            printError(e as Error, false, item.id);
        }
    }

    const notificationHeaderType = notification?.header_media_type?.toLowerCase();

    return (
        <div className={classNames(classes.root, {[classes.root_outgoing]: item.outgoing})}>
            <div className={classes.content}>
                {item.meta?.context && (
                    <MessageQuote quote={item.meta.context} searchTerm={searchTerm}/>
                )}
                {notification && notificationHeaderType !== 'text' ? (
                    <MessageMedia
                        {...item}
                        media={notification.header_media}
                        chattype={notificationHeaderType}
                        searchTerm={searchTerm}
                    />
                ) : (
                    <MessageMedia {...item} searchTerm={searchTerm}/>
                )}
                <div className={classes.messageContainer}>
                    {item.meta?.error ? (
                        <MediaError message={translateSlug(item.meta.error)}/>
                    ) : (
                        <div className={classes.text}>
                            {finalText && (
                                isPostbackButton ? (
                                    <Tooltip title={`${translations.postback} ${item.chat}`.trim()}>
                                        <Typography
                                            component='p'
                                            variant='body2'
                                            className={_isArabic ? classes.messageRtl : classes.message}
                                        >
                                            <HighlightSearchText
                                                text={finalText}
                                                searchTerm={searchTerm}
                                                linkify
                                                showLinkStats={showLinkStats}
                                                linkStatsRefId={linkStatsRefId}
                                            />
                                        </Typography>
                                    </Tooltip>
                                ) : (
                                    <Typography
                                        component='p'
                                        variant='body2'
                                        className={_isArabic ? classes.messageRtl : classes.message}
                                    >
                                        <HighlightSearchText
                                            text={finalText}
                                            searchTerm={searchTerm}
                                            linkify
                                            showLinkStats={showLinkStats}
                                            linkStatsRefId={linkStatsRefId}
                                        />
                                    </Typography>
                                )
                            )}
                        </div>
                    )}
                    <div className={classes.messagesIcons}>
                        {item.notification && (
                            <Tooltip title={translations.wb_notification}>
                                <NotificationsIcon className={classes.notificationIcon}/>
                            </Tooltip>
                        )}
                        <SendStatus
                            sendStatus={item.send_status}
                            outgoing={item.outgoing}
                            sendError={item.meta?.send_error}
                        />
                    </div>
                </div>
            </div>
            {item.pending && <PendingIcon className={classes.pendingIcon}/>}
        </div>
    );
};

MessageContent.displayName = 'MessageContent';

const MessageContentWithErrorBoundary: FC<IMessageContentProps> = props => {
    return (
        <ErrorBoundary
            fallbackUi={<Typography variant='body2'>{translations.message_can_not_be_displayed}</Typography>}
        >
            <MessageContent {...props} />
        </ErrorBoundary>
    );
};

export default MessageContentWithErrorBoundary;
