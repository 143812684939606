import {AnyAction, Reducer} from 'redux';
import {ISetCapturedEventAction} from './pwrInstallation.actions';
import {PWR_INSTALLATION_EVENT} from './pwrInstallation.types';

// https://stackoverflow.com/questions/51503754/typescript-type-beforeinstallpromptevent
export interface BeforeInstallPromptEvent extends Event {
    /**
     * Returns an array of DOMString items containing the platforms on which the event was dispatched.
     * This is provided for user agents that want to present a choice of versions to the user such as,
     * for example, "web" or "play" which would allow the user to chose between a web version or
     * an Android version.
     */
    readonly platforms: Array<string>;
    /**
     * Returns a Promise that resolves to a DOMString containing either "accepted" or "dismissed".
     */
    readonly userChoice: Promise<{
        outcome: 'accepted' | 'dismissed';
        platform: string;
    }>;

    /**
     * Allows a developer to show the install prompt at a time of their own choosing.
     * This method returns a Promise.
     */
    prompt(): Promise<{outcome: string; platform: string}>;
}

export interface IPwrInstallationState {
    beforeinstallprompt?: BeforeInstallPromptEvent;
}

const pwrInitialState = {
    beforeinstallprompt: undefined,
};

// THIS WILL ONLY WORK IN A PRODUCTION BUILD
// so if you try to debug/change/fix anything run: 'yarn build & yarn serve -S build'
// (reason: service worker initialisation)
export const pwrInstallationReducer: Reducer<IPwrInstallationState, ISetCapturedEventAction | AnyAction> = (
    state = pwrInitialState,
    action,
) => {
    const {type, payload} = action;

    switch (type) {
        case PWR_INSTALLATION_EVENT:
            return {
                beforeinstallprompt: payload,
            };
        default:
            return state;
    }
};
