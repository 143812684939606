import {useSelector} from "react-redux";
import {getCustomerId, isTestAccount} from "../../redux/accounting/accounting.selectors";
import {blacklistedCustomersForSE} from "../../config";


const useSEOnboarding = () => {
    const testAccount = useSelector(isTestAccount);
    const customerId = useSelector(getCustomerId);

    // disable for special cases
    return !(testAccount || !customerId || blacklistedCustomersForSE.includes(customerId));
};

export default useSEOnboarding;

