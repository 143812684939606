export const TARGETING_QUERY_BUILDER_SET_ITEM = 'TARGETING_QUERY_BUILDER_SET_ITEM';
export const TARGETING_QUERY_BUILDER_UPDATE_ITEM = 'TARGETING_QUERY_BUILDER_UPDATE_ITEM';
export const TARGETING_QUERY_BUILDER_RESET_QUERY = 'TARGETING_QUERY_BUILDER_RESET_QUERY';
export const TARGETING_QUERY_BUILDER_SET_OUTDATED = 'TARGETING_QUERY_BUILDER_SET_OUTDATED';
export const TARGETING_QUERY_BUILDER_SET_PRISTINE = 'TARGETING_QUERY_BUILDER_SET_PRISTINE';
export const TARGETING_QUERY_BUILDER_ADD_ERROR = 'TARGETING_QUERY_BUILDER_ADD_ERROR';
export const TARGETING_QUERY_BUILDER_REMOVE_ERROR = 'TARGETING_QUERY_BUILDER_REMOVE_ERROR';

export const TARGETING_QUERY_BUILDER_GET_COUNT = 'TARGETING_QUERY_BUILDER_GET_COUNT';
export const TARGETING_QUERY_BUILDER_GET_COUNT_PENDING = 'TARGETING_QUERY_BUILDER_GET_COUNT_PENDING';
export const TARGETING_QUERY_BUILDER_GET_COUNT_FULFILLED = 'TARGETING_QUERY_BUILDER_GET_COUNT_FULFILLED';
export const TARGETING_QUERY_BUILDER_GET_COUNT_REJECTED = 'TARGETING_QUERY_BUILDER_GET_COUNT_REJECTED';
