import {FC} from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import {customColors} from '../../../theme/customColors';
import {MessengerIconProps} from '../MessengerIcon';

const ViberIcon: FC<Omit<MessengerIconProps, 'messenger'>> = props => {
    const {tooltip, ...rest} = props;

    const icon = (
        <SvgIcon {...rest}>
            <g fill="none" fillRule="evenodd">
                <path
                    fill={customColors.messenger.VI}
                    d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0ZM8.3,17.6c-3.54-1-3.49-4.67-3.44-6.63s.41-3.54,1.49-4.62c1.94-1.78,5.92-1.54,5.92-1.54,3.38,0,4.89,1.12,5.27,1.46,1.24,1.08,1.93,3.48,1.47,7.26a4.43,4.43,0,0,1-3.71,4.18,13.39,13.39,0,0,1-4.49.33c-.27.31-.62.72-.88,1-.47.5-.75,1-1.24.82s-.39-.74-.39-.74V17.6ZM11.72,5.86c-1,0-3.27.14-4.53,1.28A5.37,5.37,0,0,0,5.94,11c0,1.63-.21,4.76,2.78,5.59V17c0,.66,0,2,0,2.16s0,.24.1.26A.28.28,0,0,0,9,19.31c.48-.49,2-2.33,2-2.33a12.55,12.55,0,0,0,3.86-.33,3.52,3.52,0,0,0,3-3.39c.39-3.16-.15-5.32-1.2-6.23-.31-.28-1.68-1.14-4.54-1.15a4.36,4.36,0,0,0-.46,0Zm.84,7.58a.5.5,0,0,0,.41-.15l.28-.36a.65.65,0,0,1,.79-.11c.18.11.5.3.7.45l.65.52a.54.54,0,0,1,.11.71h0a2.63,2.63,0,0,1-.59.72h0a1.17,1.17,0,0,1-.6.28h-.08a.69.69,0,0,1-.25,0h0a8.6,8.6,0,0,1-1.64-.76,9.3,9.3,0,0,1-1.37-.9,7.44,7.44,0,0,1-.62-.55l-.08-.08a7.44,7.44,0,0,1-.55-.62,10.16,10.16,0,0,1-.91-1.37,8.33,8.33,0,0,1-.75-1.64H8a.73.73,0,0,1,0-.25V9.2a1.2,1.2,0,0,1,.29-.59h0A2.78,2.78,0,0,1,9,8H9a.55.55,0,0,1,.71.12l.52.65c.14.2.34.52.44.7a.6.6,0,0,1-.11.78l-.35.28a.55.55,0,0,0-.16.42A3.7,3.7,0,0,0,12.56,13.44Zm3.09-1.64a.18.18,0,0,0,.18-.2,4,4,0,0,0-1.14-3A3.91,3.91,0,0,0,11.9,7.5a.19.19,0,0,0-.2.19.19.19,0,0,0,.19.19,3.6,3.6,0,0,1,2.54,1,3.72,3.72,0,0,1,1,2.73.2.2,0,0,0,.19.19Zm-1-.39h0a.2.2,0,0,1-.19-.2,2.16,2.16,0,0,0-.57-1.61,2.34,2.34,0,0,0-1.66-.73.18.18,0,0,1-.18-.2.2.2,0,0,1,.2-.18,2.75,2.75,0,0,1,1.92.85,2.58,2.58,0,0,1,.67,1.88A.2.2,0,0,1,14.64,11.41Zm-1-.33a.19.19,0,0,1-.19-.18.9.9,0,0,0-1-1,.2.2,0,0,1-.18-.2.19.19,0,0,1,.2-.18,1.27,1.27,0,0,1,1.32,1.36.19.19,0,0,1-.18.2Z"
                />
            </g>
        </SvgIcon>
    );

    return tooltip ? <Tooltip title="Viber">{icon}</Tooltip> : icon;
};

export default ViberIcon;
