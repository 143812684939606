// outsourced to prevent circular dependencies
export const TOOLTIP_PROPS = {
    enterDelay: 500,
};

export const CHAT_LIST_INITIAL_NUM_CHATS_LOADED = 50;
export const CHAT_LIST_MAX_MESSAGES_TO_LOAD = 250;

export const CHAT_PAGE_DND_NAME = 'chatPage';
export const SHOP_PAGE_DND_NAME = 'shopPage';
export const CHAT_PAGE_COLUMN_ORDER = Object.freeze(['columnA', 'columnB']);
export const SHOP_PAGE_COLUMN_ORDER = Object.freeze(['columnA', 'columnB']);
