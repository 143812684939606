import {SvgIcon} from '@material-ui/core';
import {FC} from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import {MessengerIconProps} from "../MessengerIcon";
import translations from "../../../providers/TranslationProvider/translations";

const LiveChatIcon: FC<Omit<MessengerIconProps, 'messenger'>> = props => {
    const {tooltip, ...rest} = props;

    const icon = (
        <SvgIcon viewBox={'0 0 1459 1459'} style={{fillRule:'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2}} {...rest}>
            <g>
                <circle cx="729.167" cy="729.167" r="729.167" style={{fill: '#d62946'}}/>
                <path
                    d="M469.151,1100.21c-116.747,-82.002 -193.109,-217.678 -193.109,-371.048c-0,-250.087 203.038,-453.125 453.125,-453.125c250.086,-0 453.125,203.038 453.125,453.125c-0,250.086 -203.039,453.125 -453.125,453.125c-14.498,-0 -28.838,-0.683 -42.989,-2.018c-42.34,51.682 -106.649,84.684 -178.606,84.684c-48.115,-0 -92.81,-14.755 -129.804,-39.984c43.299,-29.528 76.049,-73.403 91.383,-124.759Z"
                    style={{fill: '#fde8ec'}}
                />
                <circle cx="520.833" cy="729.167" r="72.917" style={{fill: '#d62946'}}/>
                <circle cx="729.167" cy="729.167" r="72.917" style={{fill: '#d62946'}}/>
                <circle cx="937.5" cy="729.167" r="72.917" style={{fill: '#d62946'}}/>
            </g>
        </SvgIcon>
    );

    return tooltip ? <Tooltip title={translations.livechat}>{icon}</Tooltip> : icon;
};

export default LiveChatIcon;
