import {FC} from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {translateSlug} from "../../../../utils/translation/translation.utils";

interface NoPaginationFooterProps {
    rowCount: number;
}

const NoPaginationFooter: FC<NoPaginationFooterProps> = (
    {
        rowCount,
    }
) => {
    const rowCountSlug = rowCount === 1 ? 'table_footer_row_count_singular' : 'table_footer_row_count';

    return (
        <Toolbar variant={'dense'}>
            <Typography variant={'body2'}>
                {translateSlug(rowCountSlug, rowCount)}
            </Typography>
        </Toolbar>
    );
};

export default NoPaginationFooter;
