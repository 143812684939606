import {useEffect} from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {useSnackbar} from 'notistack';
import {useDispatch, useSelector} from 'react-redux';

import {IEntityByKey} from '../../definitions/generic/generic.definitions';
import {ENotificationLevel} from '../../enums/notifications/ENotificationLevel';
import {resetError} from '../../redux/notifications/notifications.actions';
import {IState} from '../../redux/root.reducer';

const durationByLevel: IEntityByKey<number> = {
    [ENotificationLevel.Info]: 2500,
    [ENotificationLevel.Success]: 2500,
    [ENotificationLevel.Warning]: 4000,
    [ENotificationLevel.Error]: 4000,
};

const NotificationSnackbar = () => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const notifications = useSelector((state: IState) => state.notifications ?? []);

    useEffect(() => {
        notifications.forEach(item => {
            enqueueSnackbar(item.message, {
                variant: item.level,
                persist: item.persist,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
                autoHideDuration: durationByLevel[item.level] || 2500,
                action: !item.persist
                    ? undefined
                    : key => (
                          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
                              <CloseIcon fontSize="small" />
                          </IconButton>
                      ),
            });
            dispatch(resetError(item.id));
        });
    }, [closeSnackbar, dispatch, enqueueSnackbar, notifications]);

    return null;
};

export default NotificationSnackbar;
