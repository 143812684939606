import {FC} from 'react';

import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';

import translations from '../../providers/TranslationProvider/translations';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderLeft: `4px solid ${theme.palette.warning.main}`,
            paddingLeft: theme.spacing(1),
            marginTop: theme.spacing(0.5),
        },
        headerContainer: {
            display: 'flex',
            flexFlow: 'row nowrap',
            marginBottom: theme.spacing(1),
            color: theme.palette.warning.main,
        },
        text: {
            color: 'inherit',
        },
        icon: {
            marginRight: theme.spacing(1),
            color: theme.palette.warning.main,
        },
    }),
);

interface IMediaErrorProps {
    fileUrl?: string;
    message?: string;
}

const MediaError: FC<IMediaErrorProps> = props => {
    const {fileUrl = '', message = ''} = props;

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.headerContainer}>
                <ErrorIcon className={classes.icon} />
                <Typography variant="body2">{message || translations.no_media}</Typography>
            </div>
            {Boolean(fileUrl) && (
                <Typography variant="body2">
                    {translations.Try_downloading_the_file__try}
                    <a className={classes.text} href={fileUrl} download target="_blank" rel="noopener noreferrer">
                        {translations.Try_downloading_the_file__downloading}
                    </a>
                    {translations.Try_downloading_the_file__the_file}
                </Typography>
            )}
        </div>
    );
};

MediaError.displayName = 'MediaError';

export default MediaError;
