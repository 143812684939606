import {Reducer} from 'redux';
import {
    getAllConnectableChannels,
    postConnectImessageToChannel,
} from './imessageOnboardingRequest';

export const STORE_IMESSAGE_ONBOARDING_PARAMS = 'STORE_IMESSAGE_ONBOARDING_PARAMS';
export const REMOVE_IMESSAGE_ONBOARDING_PARAMS = 'REMOVE_IMESSAGE_ONBOARDING_PARAMS';

export const GET_IMESSAGE_CONNECTABLE_CHANNELS = 'GET_IMESSAGE_CONNECTABLE_CHANNELS';
export const GET_IMESSAGE_CONNECTABLE_CHANNELS_PENDING = 'GET_IMESSAGE_CONNECTABLE_CHANNELS_PENDING';
export const GET_IMESSAGE_CONNECTABLE_CHANNELS_FULFILLED = 'GET_IMESSAGE_CONNECTABLE_CHANNELS_FULFILLED';
export const GET_IMESSAGE_CONNECTABLE_CHANNELS_REJECTED = 'GET_IMESSAGE_CONNECTABLE_CHANNELS_REJECTED';

export const POST_IMESSAGE_CONNECT_TO_CHANNEL = 'POST_IMESSAGE_CONNECT_TO_CHANNEL';
export const POST_IMESSAGE_CONNECT_TO_CHANNEL_PENDING = 'POST_IMESSAGE_CONNECT_TO_CHANNEL_PENDING';
export const POST_IMESSAGE_CONNECT_TO_CHANNEL_FULFILLED = 'POST_IMESSAGE_CONNECT_TO_CHANNEL_FULFILLED';
export const POST_IMESSAGE_CONNECT_TO_CHANNEL_REJECTED = 'POST_IMESSAGE_CONNECT_TO_CHANNEL_REJECTED';

export const DELETE_IMESSAGE_CONNECT_TO_CHANNEL_PENDING = 'DELETE_IMESSAGE_CONNECT_TO_CHANNEL_PENDING';
export const DELETE_IMESSAGE_CONNECT_TO_CHANNEL_FULFILLED = 'DELETE_IMESSAGE_CONNECT_TO_CHANNEL_FULFILLED';
export const DELETE_IMESSAGE_CONNECT_TO_CHANNEL_REJECTED = 'DELETE_IMESSAGE_CONNECT_TO_CHANNEL_REJECTED';

export interface IConnectChannelPayload {
    id: string,
    name: string,
    channel_id: string
}

export const storeAppleParams = (id: string, name: string) => ({
    type: STORE_IMESSAGE_ONBOARDING_PARAMS,
    payload: {id, name},
});



export const getImessageConnectableChannels = (business_id: string) => ({
    type: GET_IMESSAGE_CONNECTABLE_CHANNELS,
    payload: getAllConnectableChannels(business_id),
});

export const connectImessageToChannel = (payload: IConnectChannelPayload) => ({
    type: POST_IMESSAGE_CONNECT_TO_CHANNEL,
    payload: postConnectImessageToChannel(payload),
    meta: {
        channelId: payload.channel_id,
    },
});



interface IConnectedChannels {
    [id: string]: string;
}

export type TRequestError = undefined | {channelId: string, reason: any}

const initialRequestState = {
    loading: false,
    loaded: false,
    error: undefined as TRequestError,
};

export type TRequestState = typeof initialRequestState;

const pendingRequestState = {
    loading: false,
    loaded: false,
    error: undefined,
};

const rejectedRequestState = (error: any): TRequestState => ({
    loading: false,
    loaded: false,
    error,
});

const fulfilledRequestState = {
    loading: false,
    loaded: false,
    error: undefined,
};

const initialState = {
    name: '',
    id: '',
    connectableChannelsRequest: initialRequestState,
    connectedChannelRequest: initialRequestState,
    deleteConnectedChannelRequest: initialRequestState,
};

export type TInitialState = typeof initialState;

export interface IMessageOnboardingState extends TInitialState {
    connectedChannel?: string;
    connectableChannels?: IConnectedChannels;
}

export const imessageOnboardingReducer: Reducer<IMessageOnboardingState> = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case STORE_IMESSAGE_ONBOARDING_PARAMS: {
            return {
                ...state,
                id: payload.id,
                name: payload.name,
            };
        }
        case REMOVE_IMESSAGE_ONBOARDING_PARAMS: {
            return {
                ...state,
                id: undefined,
                name: undefined,
            };
        }
        case GET_IMESSAGE_CONNECTABLE_CHANNELS_PENDING: {
            return {
                ...state,
                connectableChannelsRequest: pendingRequestState,
            };
        }
        case GET_IMESSAGE_CONNECTABLE_CHANNELS_REJECTED: {
            return {
                ...state,
                connectableChannelsRequest: rejectedRequestState(payload?.data?.reason),
            };
        }
        case GET_IMESSAGE_CONNECTABLE_CHANNELS_FULFILLED: {
            const channels = [...payload.channels];
            return {
                ...state,
                connectableChannels: channels.reduce((channels: IConnectedChannels, channel: {id: string, name: string}) => {
                    const _channel = {[channel.id]: channel.name};
                    return ({
                        ...channels,
                        ..._channel,
                    });
                }, {}),
                connectableChannelsRequest: fulfilledRequestState,
            };
        }
        case POST_IMESSAGE_CONNECT_TO_CHANNEL_PENDING: {
            return {
                ...state,
                connectedChannel: undefined,
                connectedChannelRequest: pendingRequestState,
            };
        }
        case POST_IMESSAGE_CONNECT_TO_CHANNEL_REJECTED: {
            return {
                ...state,
                connectedChannel: undefined,
                connectedChannelRequest: rejectedRequestState({
                    channelId: action.meta.channelId,
                    reason: payload?.data?.reason,
                }),
            };
        }
        case POST_IMESSAGE_CONNECT_TO_CHANNEL_FULFILLED: {
            return {
                ...state,
                connectedChannel: payload?.success && action.meta.channelId,
                connectedChannelRequest: fulfilledRequestState,
            };
        }
        case DELETE_IMESSAGE_CONNECT_TO_CHANNEL_PENDING: {
            return {
                ...state,
                connectedChannel: undefined,
                deleteConnectedChannelRequest: pendingRequestState,
            };
        }
        case DELETE_IMESSAGE_CONNECT_TO_CHANNEL_REJECTED: {
            return {
                ...state,
                connectedChannel: undefined,
                deleteConnectedChannelRequest: rejectedRequestState(payload?.data?.reason),
            };
        }
        case DELETE_IMESSAGE_CONNECT_TO_CHANNEL_FULFILLED: {
            return {
                ...state,
                connectedChannel: undefined,
                deleteConnectedChannelRequest: fulfilledRequestState,
            };
        }
        default:
            return state;
    }
};