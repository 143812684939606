import moment from 'moment';
import {TDefaultTicketFilter} from "../../../definitions/ticketPage/ticketFilter.definitions";
import ERights from "../../../enums/actor/ERights";

export const MAX_MINUTES = 24 * 60;
export const MIN_MINUTES = 0;
export const TIME_STEP = 15;

export interface ITicketFilterConfigItem {
    filterKey: string;
    translationKey: string;
    inset?: boolean;
    requiredRights?: ERights[];
}

export const TicketFilterConfig: Readonly<{
    [key: string]: ITicketFilterConfigItem;
}> = {
    OPEN: {
        filterKey: 'open',
        translationKey: 'open_tickets',
    },
    NEW: {
        filterKey: 'new',
        translationKey: 'new',
        inset: true,
    },
    PENDING: {
        filterKey: 'pending',
        translationKey: 'unanswered',
        inset: true,
    },
    ANSWERED: {
        filterKey: 'answered',
        translationKey: 'answered',
        inset: true,
    },
    POSTPONED: {
        filterKey: 'postponed_only',
        translationKey: 'postponed_tickets',
        inset: true,
    },
    UNASSIGNED: {
        filterKey: 'unassigned',
        translationKey: 'unassigned',
        inset: true,
        requiredRights: [ERights.TicketReadOthers],
    },
    CLOSED: {
        filterKey: 'closed',
        translationKey: 'closed_tickets',
        requiredRights: [ERights.TicketReadClosed],
    },
};

export const MessengerFilter: Readonly<any> = {
    ALL: {
        filterKey: 'allMessenger',
        translationKey: 'all',
    },
    WA: {
        filterKey: 'WA',
        translationKey: 'whatsapp',
    },
    WB: {
        filterKey: 'WB',
        translationKey: 'whatsapp_business',
    },
    FB: {
        filterKey: 'FB',
        translationKey: 'facebook',
    },
    TG: {
        filterKey: 'TG',
        translationKey: 'telegram',
    },
    IN: {
        filterKey: 'IN',
        translationKey: 'notify',
    },
    IM: {
        filterKey: 'IM',
        translationKey: 'i_message',
    },
    VI: {
        filterKey: 'VI',
        translationKey: 'viber',
    },
    CC: {
        filterKey: 'CC',
        translationKey: 'custom_messenger',
    },
    LC: {
        filterKey: 'LC',
        translationKey: 'livechat',
    },
    IG: {
        filterKey: 'IG',
        translationKey: 'instagram',
    },
};

export const defaultFilterState: TDefaultTicketFilter = {
    tags: {},
    messenger: MessengerFilter.ALL.filterKey,
    min: 0,
    max: MAX_MINUTES,
    closedTickets: false,
    selectedAgent: -1,
    waiting_since: null,
    closed_in: null,
    closed_in_min: moment().subtract(1, 'month').startOf('month').unix(),
    closed_in_max: moment().endOf('day').unix(),
    tickets: TicketFilterConfig.OPEN.filterKey,
    status: TicketFilterConfig.OPEN.filterKey, // This is for tabs: Open, Postponed, Closed
};

export enum ETicketTableFilterChip {
    MESSENGER = 'MESSENGER',
    WAITING_SINCE = 'WAITING_SINCE',
    CLOSED_SINCE = 'CLOSED_SINCE',
    STATE = 'STATE',
    AGENT = 'AGENT',
    TAGS = 'TAGS',
}
