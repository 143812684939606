
function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill="#272727"
                fillRule="evenodd"
                d="M14.493 24.522c-.317.353-.493.8-.493 1.186v8.459c0 .395.182.88.513 1.27.332.394.7.563.987.563h17c.26 0 .733-.135 1-.5.27-.369.5-.87.5-1.327v-8.459c0-.325-.255-.76-.642-1.14C32.97 24.194 32.337 24 32 24H15.5c-.318 0-.69.169-1.007.522zm-1.488-1.336c.6-.668 1.478-1.186 2.495-1.186H32c.996 0 2.103.505 2.757 1.146.654.64 1.243 1.56 1.243 2.568v8.459c0 .877-.363 1.793-.885 2.507-.525.718-1.54 1.32-2.615 1.32h-17c-1.048 0-1.93-.58-2.514-1.27-.586-.693-.986-1.623-.986-2.563v-8.459c0-.947.406-1.855 1.005-2.522z"
                clipRule="evenodd"
            />
            <path
                fill="#007171"
                d="M19.751 13.727A6.065 6.065 0 0124 12c1.597 0 3.125.623 4.249 1.727A5.813 5.813 0 0130 17.875V20h2v-2.125c0-2.095-.847-4.1-2.35-5.575A8.065 8.065 0 0024 10a8.065 8.065 0 00-5.65 2.3A7.813 7.813 0 0016 17.875V20h2v-2.125c0-1.552.628-3.045 1.751-4.148zM25.534 28.333c0 .39-.148.744-.391 1.01L26 32h-4l.925-2.656a1.5 1.5 0 112.609-1.01z"
            />
        </svg>
    );
}

export default Icon;
