import sortBy from 'lodash/sortBy';
import {IChannel} from '../../definitions/channel/channel.definitions';
import {IEntityById} from '../../definitions/generic/generic.definitions';
import {IState} from '../root.reducer';

export const getChannels = (state: IState): IEntityById<IChannel> => state.entities.channels.byId;
export const isChannelAnonymized = (state: IState) => Boolean(state.channel.hidden_phone);

export const getChannelSelectOptions = (state:IState) =>
    sortBy(Object.values(state.entities.channels.byId), channel => channel.name)
        .map(channel => ({
            label: channel.name,
            value: Number(channel.id),
            // disabled: agent && agent.all_channels,
        }));

export const getLabels = (state: IState): IEntityById<IChannel> => state.entities.channels.byId;

export const isWhatsAppForCustomerActive = (state: IState) => Boolean(state.channel.messenger.WB);