import {ICustomField} from '../definitions/customField/customField.definitions';
import {request} from './Api/Api';

// set default values for fields not represented in the form TODO: SHOULD BE SERVER-SIDE (LH)
const parseFormData = (customField: ICustomField) => ({
    ...customField,
    expiration: '-1',
    user_visible: customField.user_visible ? '1' : '0',
});

const CUSTOM_URL = 'custom/{id}';

export const getCustomFieldsRequest = () => request({method: 'GET', url: CUSTOM_URL});

export const postCustomFieldRequest = (customField: ICustomField) =>
    request({method: 'POST', url: CUSTOM_URL}, parseFormData(customField));

export const putCustomFieldRequest = (customField: ICustomField) =>
    request({method: 'PUT', url: CUSTOM_URL}, parseFormData(customField));

export const deleteCustomFieldRequest = (id: number) => request({method: 'DELETE', url: CUSTOM_URL}, {id});
