import {ShopProductActions, ShopState, ShopUpdateStream} from '../../definitions/settings/shop/shop.definitions';
import {IUpdateStream} from '../../definitions/updateStream/updateStream.definitions';
import {UPDATE_STREAM} from '../connection/connection.types';
import {
    GET_PRODUCT_DETAILS_PENDING,
    SCROLL_PRODUCTS_FULFILLED,
    SEARCH_PRODUCT_FULFILLED,
} from '../products/products.types';
import {
    DELETE_SHOPPING_CART_ITEM_PENDING,
    GET_SHOPPING_CART_FULFILLED,
    GET_SHOPPING_ORDERS_FULFILLED,
    UNSET_PRODUCT_ID,
    UPDATE_SHOPPING_CART_PENDING,
} from './shop.types';

const initState: ShopState = {
    offset: '',
    selectedProduct: '',
    orders: [],
};

const isShopUpdateStream = (action: IUpdateStream): action is ShopUpdateStream => {
    return action.payload.message.action === 'shop_cart';
};

export const shopReducer = (state = initState, action: ShopProductActions): ShopState => {
    switch (action.type) {
        case UPDATE_STREAM: {
            if (isShopUpdateStream(action)) {
                if (action.payload.message.payload.user_id === state.cartUserId) {
                    return {...state, cart: action.payload.message.payload.cart};
                }

                return state;
            }

            return state;
        }
        case SCROLL_PRODUCTS_FULFILLED:
        case SEARCH_PRODUCT_FULFILLED:
            return {
                ...state,
                offset: action.payload.offset,
            };
        case GET_PRODUCT_DETAILS_PENDING:
            return {...state, selectedProduct: action.meta.productId};
        case UNSET_PRODUCT_ID: {
            return {...state, selectedProduct: ''};
        }
        case GET_SHOPPING_CART_FULFILLED:
            return {...state, cart: {...action.payload.cart}, cartUserId: action.meta.userId};
        case DELETE_SHOPPING_CART_ITEM_PENDING: {

            const {variant_id} = action.meta;

            const cart = state.cart;

            if (!cart) {
                return state;
            }

            const lineItems = cart.lineItems.filter(item => item.product.variants[0].id !== variant_id);

            if (lineItems.length === 0) {
                const {cart, ...rest} = state;
                return rest;
            }

            return {...state, cart: {...cart, lineItems}};
        }
        case UPDATE_SHOPPING_CART_PENDING: {

            const {variant_id, quantity} = action.meta;

            const cart = state.cart;

            if (!cart) {
                return state;
            }

            const lineItems = cart.lineItems.map(item => {
                if (item.product.variants[0].id !== variant_id) {
                    return item;
                }

                return {
                    ...item,
                    quantity,
                };
            });

            return {...state, cart: {...cart, lineItems}};
        }
        case GET_SHOPPING_ORDERS_FULFILLED:
            return {...state, orders: [...action.payload.orders]};
        default:
            return state;
    }
};
