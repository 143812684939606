import {IEntityById} from '../../definitions/generic/generic.definitions';
import {IAgent} from '../../definitions/agent/agent.definitions';
import {EChatAction, EChatMessage} from '../../enums/chat/EChat';
import {translateSlug} from '../translation/translation.utils';
import {IChat, IMessageContentItem} from '../../definitions/chat/chat.definitions';

export const getErrorText = (sendError?: string) => {
    if (!sendError) {
        return '';
    }

    const regex = new RegExp('details":"(.*)"', 'gm');

    const group = regex.exec(sendError);

    if (!group) {
        return sendError;
    }

    return group[1] || sendError;
};

export const isArabic = (text?: string) => {
    return !!text && /[\u0600-\u06FF\u0750-\u077F]/.test(text);
};

export const convertChatToMessageContent = (chat?: IChat) => {
    if (!chat) {
        return undefined;
    }

    return {
        id: chat.id,
        agent_id: chat.agent_id,
        agent_email: chat.agent_email,
        chat: chat.chat,
        chattime: chat.chattime,
        chattype: chat.chattype,
        media: chat.media,
        outgoing: chat.outgoing,
        send_status: chat.send_status, // only for outgoing chats
        pending: chat.pending,
        bot: chat.bot,
        notification: chat.notification,
        meta: chat.meta,
    } as IMessageContentItem;
};

// We don't support multiline markup, because WhatsApp neither does..
const REGEX_BOLD = /\*(.*?)\*/gm;
const REGEX_ITALIC = /_(.*?)_/gm;
const REGEX_LINE_BREAK = /(\\n)/gm;
const REGEX_URL = /(https?:\/\/[^\s]+)/gm;
const URL_PLACEHOLDER = '{{~link~}}';

const findUrls = (s: string) => s.match(REGEX_URL);
const replaceUrls = (s: string) => s.replace(REGEX_URL, URL_PLACEHOLDER);
const boldString = (s: string) => s.replace(REGEX_BOLD, ` <b>$1</b> `);
const italicString = (s: string) => s.replace(REGEX_ITALIC, ` <i>$1</i> `);
const lineBreak = (s: string) => s.replace(REGEX_LINE_BREAK, '<br>');

export const format = (item: IMessageContentItem, agents: IEntityById<IAgent>) => {
    // if a button is clicked and has defined different postback than the button text,
    // we can get the original button text from the meta information.
    const chat = item?.meta?.button || item.chat;

    if (!chat) {
        return '';
    }

    const meta = item.meta;

    if (chat === EChatMessage.Deleted && meta?.action === EChatAction.DeletedByAgent) {
        const agent = meta?.agent ? `${agents[meta.agent].firstname} ${agents[meta.agent].lastname}` : 'unknown';
        return translateSlug('chat_deleted_by_agent', agent);
    }

    // We only need to encode the < character, because it accidentally cuts off text that follows a < without a space,
    // because it considers it to be an opening html tag. It's crucial to do this before the other format stuff below!
    // Without this, the following text examples would get cut off like this
    // Foo<1 Bar       ->  Foo
    // Foo <Bar        ->  Foo
    const htmlEntityEncodedChat = chat.replace('<', '&lt;');

    // find and remember urls in text
    const urlsInText = findUrls(htmlEntityEncodedChat) || [];

    // replace urls with placeholders
    let parsedString = replaceUrls(htmlEntityEncodedChat);

    // apply format
    parsedString = boldString(parsedString);
    parsedString = italicString(parsedString);
    parsedString = lineBreak(parsedString);

    // replace placeholders with urls again
    urlsInText.forEach(url => {
        parsedString = parsedString.replace(URL_PLACEHOLDER, url);
    });

    return parsedString;
};

export const printError = (error: Error, explicit: boolean, itemId?: number) => {
    console.error(`[${explicit ? 'EXPLICIT' : 'INEXPLICIT'}] #${itemId}: ${error.name}: ${error.message}`);
};
