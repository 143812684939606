import {Reducer} from 'redux';

import {ICountriesState, ICountry} from '../../definitions/countries/countries.definitions';
import {COUNTRIES_GET_FULFILLED} from './countries.types';
import {IEntityByKey} from "../../definitions/generic/generic.definitions";

export const countryInitialState: ICountriesState = {
    byId: {} as IEntityByKey<ICountry>,
    allIds: [],
};

export const countriesReducer: Reducer<ICountriesState> = (state = countryInitialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case COUNTRIES_GET_FULFILLED: {
            return {
                ...state,
                byId: payload.reduce((result: IEntityByKey<ICountry>, country: ICountry) => ({...result, [country.country_code.toLowerCase()]: country}), {}),
                allIds: payload.map((country: ICountry) => country.country_code.toLowerCase()),
            };
        }

        default:
            break;
    }

    return state;
};
