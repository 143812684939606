import {FC, ReactNode} from 'react';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import LoaderSpinner from '../Loader/LoaderSpinner';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: 'relative',
            height: '100%',
        },
        root_children: {
            height: '100%',
        },
        root_loading: {
            '& > *': {
                opacity: 0.5,
            },
        },
        content_hidden: {
            visibility: 'hidden',
        },
        overlay: {
            opacity: 1,
            position: 'absolute',
            maxHeight: 'calc(100vh - 150px)',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
        infoContainer: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
    }),
);

interface IOverlayLoaderProps {
    children: ReactNode;
    isLoading?: boolean;
    hideContentWhileLoading?: boolean;
    size?: number;
    className?: string;
}

export const OVERLAY_LOADER_DEFAULT_SIZE = 24;

const OverlayLoader: FC<IOverlayLoaderProps> = (
    {
        isLoading = false,
        children,
        hideContentWhileLoading = false,
        size = OVERLAY_LOADER_DEFAULT_SIZE,
        className,
    },
) => {
    const classes = useStyles();
    const hideContent = isLoading && hideContentWhileLoading;

    return (
        <div className={classNames(classes.root, isLoading && classes.root_loading, className)}>
            <div className={classNames(classes.root_children, hideContent && classes.content_hidden)}>{children}</div>
            {isLoading && (
                <div className={classes.overlay}>
                    <div className={classes.infoContainer}>
                        <LoaderSpinner proportions={size} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default OverlayLoader;
