import {createSelector} from 'reselect';

import {IEntityByKey, IIndexSignature} from '../../../definitions/generic/generic.definitions';
import {EMessenger} from '../../../enums/messenger/EMessenger';
import {
    ISettingsWidgetState,
    IWidget,
    IWidgetMessenger,
    TWidgetMessengers,
} from '../../../definitions/settings/widgetEditor/widgetEditor.definitions';
import {IState} from '../../root.reducer';
import {
    ECardNCubeConfigOption,
    ECardNCubeStylingOption,
    EChipWidgetStylingOption,
    ECubeStylingOptions,
    EWidgetCommonConfigOption,
    EWidgetDialogSteps,
    EWidgetTypeId,
} from '../../../enums/widget/EWidget';

export const getSettingsWidget = (state: IState) => state.settings.widgetEditor;

const _getMessengers = (widget: ISettingsWidgetState) => {
    if (!widget.settings) {
        return null;
    }

    return widget.settings.messengers;
};

export const getMessengers = createSelector([getSettingsWidget], _getMessengers);

export const getActiveWidgetId = (state: IState) => state.settings.widgetEditor.widgetForm.uniqueid;

const getAllWidgets = (state: IState) => state.settings.widgetEditor.widgets;

export const getActiveWidget = createSelector(
    [getActiveWidgetId, getAllWidgets],
    (widgetId: string | undefined, allWidgets?: IEntityByKey<IWidget>) => {
        return (widgetId && allWidgets) ? allWidgets[widgetId] : null;
    },
);

export const getFirstCardWidget = createSelector([getAllWidgets], (allWidgets?: IIndexSignature):
    | IWidget
    | undefined => {
    return allWidgets && Object.values(allWidgets).find((widget: IWidget) => widget.widget_type_id === EWidgetTypeId.Card);
});

export const getFirstChipWidget = createSelector([getAllWidgets], (allWidgets?: IIndexSignature):
    | IWidget
    | undefined => {
    return allWidgets && Object.values(allWidgets).find((widget: IWidget) => widget.widget_type_id === EWidgetTypeId.Chip);
});

export const hasWidgets = createSelector([getAllWidgets], (allWidgets?: IIndexSignature): boolean =>
    Boolean(allWidgets && Object.values(allWidgets).length),
);

export const getWidgetFromStateById = (state: IState, widgetUniqueId: string | null) => {
    if (!widgetUniqueId) {
        return null;
    }
    return state.settings.widgetEditor.widgets?.[widgetUniqueId];
};

export const getMessengerConfigForWidgetTemplate = (state: IState) => {
    const messengers = state?.settings?.widgetEditor?.settings?.messengers || ({} as TWidgetMessengers);

    return (
        Object.keys(messengers)
            // filter messengers that are active in this channel
            .filter(mKey => Boolean(state.channel.messenger[mKey as EMessenger]) && mKey !== EMessenger.WhatsApp)
            .map(mKey => ({...messengers[mKey as EMessenger]}))
            .map(messenger => getMessengerEntriesForPreview(messenger as IWidgetMessenger))
    );
};

const getMessengerEntriesForPreview = (messenger: IWidgetMessenger) => ({
    id: messenger.id,
    label: messenger.name,
    name: messenger.name,
    svg: messenger.svg,
    color: messenger.color,
    sequence: 0,
});

const _getWidgetConfigFromForm = (widgetEditor: ISettingsWidgetState): Partial<IWidget> => {
    const {
        widgetForm,
        settings: {configOptions},
        step,
    } = widgetEditor;

    // config that all parts have in common
    const standardConfig = Object.keys(widgetForm).reduce((config, key) => {
        if (Object.values<string>(EWidgetCommonConfigOption).includes(key)) {
            return {
                ...config,
                // @ts-ignore
                [key]: widgetForm[key],
            };
        }
        return config;
    }, {});

    // config for card and cube
    const cardNCubeConfig = Object.keys(widgetForm).reduce((config, key) => {
        if (widgetForm.widget_type_id !== EWidgetTypeId.Chip) {
            if (Object.values<string>(ECardNCubeConfigOption).includes(key)) {
                return {
                    ...config,
                    // @ts-ignore
                    [key]: widgetForm[key],
                };
            }
        }

        return config;
    }, {});

    // styling options have to be in this weird form, because in the past attempts were made to build them generically, but failed
    const settings = Object.keys(widgetForm).reduce((settings, key) => {
        if (key.startsWith('liveChat')) {
            return {
                ...settings,
                // @ts-ignore
                [key]: {...configOptions[key], value: widgetForm[key]},
            };
        }
        if (widgetForm.widget_type_id === EWidgetTypeId.Chip) {
            if (Object.values<string>(EChipWidgetStylingOption).includes(key)) {
                return {
                    ...settings,
                    // @ts-ignore
                    [key]: {...configOptions[key], value: widgetForm[key]},
                };
            }
        } else {
            if (Object.values<string>(ECardNCubeStylingOption).includes(key)) {
                return {
                    ...settings,
                    // @ts-ignore
                    [key]: {...configOptions[key], value: widgetForm[key]},
                };
            }

            if (Object.values<string>(ECubeStylingOptions).includes(key)) {
                return {
                    ...settings,
                    // @ts-ignore
                    [key]: {...configOptions[key], value: widgetForm[key]},
                };
            }
        }

        return settings;
    }, {});

    const widgetConfig = {
        ...standardConfig,
        ...cardNCubeConfig,
        settings,
    } as Partial<IWidget>;

    if (step === EWidgetDialogSteps.CubeDesigner2) {
        return {
            ...widgetConfig,
            selectedMessenger: Object.keys(widgetForm.messengers)[0] as EMessenger,
        };
    }

    return widgetConfig;
};

export const getWidgetConfigFromForm = createSelector(
    [(state: IState) => state.settings.widgetEditor],
    _getWidgetConfigFromForm,
);
