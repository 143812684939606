export enum ECustomFieldTypes {
    Default = 'default',
    Number = 'number',
    Text = 'text',
    Word = 'word',
    Enum = 'enum',
    Collection = 'collection',
    Location = 'location',
    Date = 'date',
    Time = 'time',
    Entity = 'entity',
    MiaTopics = 'miatopics',
}
