import {FC} from 'react';

import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import DateSeparator from '../../../../components/DateSeparator/DateSeparator';
import ChatListItem from './ChatListItem/ChatListItem';
import {IChat} from "../../../../definitions/chat/chat.definitions";
import {IChatListUser} from "../../../../definitions/user/user.definitions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minWidth: 0,
            '& > ul:last-child': {
                paddingBottom: 0,
            },
        },
        list: {
            minWidth: 0,
            padding: 0,
            paddingBottom: 32,
        },
        dateHeader: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    }),
);

interface IChatListDays {
    day: string;
    thisDaysChats: any[];
    searchTerm: string;
    channelImage: string;
    channelName: string;
    registerSearchPosition: (param: any) => void;
    chats?: IChat[];
    user: IChatListUser;
    loadedImages: number;
}

const ChatListDays: FC<IChatListDays> = (
    {
        day,
        thisDaysChats,
        searchTerm,
        channelImage,
        registerSearchPosition,
        channelName,
        chats,
        user,
        loadedImages,
    }
) => {
    const classes = useStyles();

    const combinedChats = thisDaysChats.map((currentChat, index) => {
        const previousChat = thisDaysChats[index - 1];

        const sameAgent = previousChat?.agent_id === currentChat.agent_id;
        const sameDirection = previousChat?.outgoing === currentChat.outgoing;
        const differentUser = previousChat?.meta?.user?.id !== currentChat?.meta?.user?.id
            || previousChat?.meta?.user?.name !== currentChat?.meta?.user?.name;

        if (sameAgent && sameDirection && !differentUser) {
            return {
                ...currentChat,
                combine: true,
            };
        }

        return currentChat;
    });

    return (
        <li key={day} className={classes.root}>
            <ul className={classes.list}>
                <DateSeparator dateText={day} className={classes.dateHeader}/>

                {combinedChats.map(chat => (
                    <ChatListItem
                        key={chat.id || 'new-item'}
                        chats={chats}
                        user={user}
                        item={chat}
                        searchTerm={searchTerm}
                        registerSearchPosition={registerSearchPosition}
                        channelImage={channelImage}
                        channelName={channelName}
                        loadedImages={loadedImages}
                    />
                ))}
            </ul>
        </li>
    );
};

ChatListDays.displayName = 'ChatListDays';

export default ChatListDays;
