import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        textField: {
            margin: 0,
            width: '100%',
            '& > div': {
                maxHeight: 'unset',
            },
        },
        input: {
            display: 'flex',
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 15,
            paddingRight: 0,
            minHeight: 48,
            height: 'auto',

            '& span': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
            // hide separator
            '& > div > span': {
                display: 'none',
            },
        },
        valueContainer: {
            flex: 1,
            padding: `10px 0`,
            overflow: 'hidden',
            // input field
            '& > div:last-child': {
                display: 'inline-flex',
                position: 'relative',
                height: '24px',
                marginTop: '4px',
                marginRight: '8px',
                marginBottom: '4px',
            },
        },
        noOptionsMessage: {
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        },
        singleValue: {
            fontSize: 16,
        },
        placeholder: {
            position: 'absolute',
            left: 2,
            fontSize: 16,
        },
        paper: {
            position: 'absolute',
            top: '100%',
            zIndex: 10,
            left: 0,
            right: 0,
        },
        dropdown: {
            color: theme.palette.secondary.contrastText,
            cursor: 'pointer',
            marginRight: 8,
            marginLeft: 8,
        },
        textFieldRoot: {
            maxHeight: 'auto',
        },
        multiRoot: {
            borderWidth: 1,
            height: theme.spacing(3),
            position: 'relative',
            maxWidth: '100%',
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),

            '&:hover $deleteButton': {
                visibility: 'visible',
                opacity: 1,
            },
        },
        multiLabel: {
            ...theme.typography['body2'],
            marginTop: 1,
        },
        deleteButton: {
            position: 'absolute',
            right: 1,
            fontSize: 20,
            padding: 5,
            marginRight: 0,
            visibility: 'hidden',
            opacity: 0,
            transition: 'all 0.2s ease-in-out',
            backgroundColor: '#fff',
            borderRadius: '50%',
        },
    }),
);
