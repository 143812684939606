import {forwardRef, MouseEvent, ReactNode} from 'react';

import IconButton, {IconButtonProps} from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import {ITooltipProps} from '../../definitions/components/tooltipProps.definitions';
import {EIcons} from '../../enums/icons/EIcons';
import {ucfirst} from '../../utils/global/global.utils';
import IconFactory from '../IconFactory/IconFactory';
import {SvgIconProps} from '@material-ui/core/SvgIcon/SvgIcon';

interface IIconProps extends SvgIconProps {
    customIconColor?: string;
}

interface ICustomIconButtonProps extends IconButtonProps {
    icon: EIcons | ReactNode;
    iconProps?: IIconProps;
    tooltip?: string;
    tooltipProps?: ITooltipProps;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    component?: any;
    className?: string;
    to?: string;
}

const CustomIconButton = forwardRef<HTMLButtonElement, ICustomIconButtonProps>((props, ref) => {
    const {icon, tooltip, tooltipProps, iconProps, className, ...buttonProps} = props;

    const buttonClasses = className ? {root: className} : undefined;

    const Button = (
        <IconButton ref={ref} classes={buttonClasses} {...buttonProps}>
            {typeof icon === 'string' ? IconFactory.getIconWithProps(icon as EIcons, iconProps) : icon}
        </IconButton>
    );

    return !tooltip ? Button : <Tooltip title={ucfirst(tooltip)} {...tooltipProps} children={<span>{Button}</span>} />;
});

export default CustomIconButton;
