import moment from 'moment';
import {Reducer} from 'redux';

import {IChatpageState} from '../../definitions/chatpage/chatpage.definitions';
import {ITicket} from '../../definitions/tickets/tickets.definitions';
import {EChatAction, EChatMessage} from '../../enums/chat/EChat';
import {EUpdateStreamAction} from '../../enums/updateStream/EUpdateStreamAction';
import {ChatType} from '../../utils/ticket/ticket.utils';
import {UPDATE_STREAM} from '../connection/connection.types';
import {
    TICKET_GET_BY_ID_FULFILLED,
    TICKET_GET_BY_ID_PENDING,
    TICKET_GET_BY_ID_REJECTED,
    TICKET_UPDATE_NOTE_FULFILLED,
} from '../tickets/tickets.types';
import {INTERIM_MESSAGE_ID} from '../tickets/tickets.reducer';
import {
    isChatUpdate,
    isLabelAdd,
    isLabelDelete,
    isOutgoingChat,
    isOutgoingWbNotification,
    isReceiveChat,
    isTicketAdd,
    isTicketClose,
    isTicketNote,
    isTicketReOpen,
    isTicketUpdate,
    removeInterimMessage,
    sortChats,
    updateSingleTicket,
} from '../tickets/tickets.reducer.utils';
import {isUserPropertyUpdate} from '../user/user.reducer';
import {
    CHAT_PAGE_ADD_INTERIM_CHAT,
    CHAT_PAGE_GET_LIVE_CHAT_CONVERSATION_FULFILLED,
    CHAT_PAGE_GET_LIVE_CHAT_CONVERSATION_REJECTED,
    CHAT_PAGE_GET_TICKET_HISTORY_FULFILLED, CHAT_PAGE_GET_TICKET_HISTORY_PENDING,
    CHAT_PAGE_GET_USER_FULFILLED,
    CHAT_PAGE_GET_USER_PENDING,
    CHAT_PAGE_GET_USER_REJECTED,
    CHAT_PAGE_PUT_USER_REJECTED,
    CHAT_PAGE_RESET,
    CHAT_PAGE_RESET_ATTACHMENT_PREVIEW,
    CHAT_PAGE_RESET_MESSAGE,
    CHAT_PAGE_RESET_UPLOAD,
    CHAT_PAGE_SEARCH_TERM_SET,
    CHAT_PAGE_SET_FOCUS_TEXT_FIELD,
    CHAT_PAGE_SET_MESSAGE,
    CHAT_PAGE_TOGGLE_ARCHIVE,
    CHAT_PAGE_TOGGLE_IMAGE_LIGHTBOX,
    CHAT_PAGE_TOGGLE_SIDEBAR,
    CHAT_PAGE_UPLOAD_MEDIA_FULFILLED,
    CHAT_PAGE_UPLOAD_MEDIA_PENDING,
    CHAT_PAGE_UPLOAD_MEDIA_REJECTED,
    CHATS_GET_BY_USER_FULFILLED,
    CHATS_GET_BY_USER_PENDING,
    CHATS_GET_BY_USER_REJECTED,
} from './chatPage.types';
import {
    handleChatUpdate,
    handleOutgoingChat,
    handleReceiveChat,
    handleTicketNoteAndLabelUpdate,
    handleTicketUpdate,
    handleUserPropertyUpdate,
    handleUserStartStop,
} from './chatPage.reducer.utils';
import {USER_SET_UNREAD_FULFILLED, USER_START_FULFILLED, USER_STOP_FULFILLED} from "../user/user.types";
import {CHAT_DELETE_PENDING, CHAT_DELETE_REJECTED, CHAT_SEND_FULFILLED, CHAT_SEND_PENDING} from '../chat/chat.types';
import {IChat} from "../../definitions/chat/chat.definitions";

export const chatPageInitialState: IChatpageState = {
    activeConversationId: 0,
    chatBlockId: undefined,
    attachment: undefined,
    message: '',
    messageBinding: false,
    lightboxOpen: false,
    searchTerm: '',
    uploadUrl: '',
    focusTextField: false,
    history: [],
};

export const chatPageReducer: Reducer<IChatpageState> = (state = chatPageInitialState, action) => {
    const {payload, type, meta} = action;

    switch (type) {
        // update stream at first, because it is the most case
        case UPDATE_STREAM: {
            const {message} = payload;

            // Don't clone here! It will be done in the handlers below and if nothing changes returns previous state.
            let updatedState = state;

            if (isReceiveChat(payload)) {
                updatedState = handleReceiveChat(updatedState, action);
            }

            if (isOutgoingChat(payload) || isOutgoingWbNotification(payload)) {
                updatedState = handleOutgoingChat(updatedState, action);
            }

            if (message.action === EUpdateStreamAction.UserStart || message.action === EUpdateStreamAction.UserStop) {
                updatedState = handleUserStartStop(updatedState, action);
            }

            if (isTicketNote(payload) || isLabelAdd(payload) || isLabelDelete(payload)) {
                updatedState = handleTicketNoteAndLabelUpdate(updatedState, action);
            }

            if (isTicketReOpen(payload) || isTicketClose(payload) || isTicketAdd(payload) || isTicketUpdate(payload)) {
                updatedState = handleTicketUpdate(updatedState, action);
            }

            if (isChatUpdate(payload)) {
                updatedState = handleChatUpdate(updatedState, action);
            }

            if (isUserPropertyUpdate(payload)) {
                updatedState = handleUserPropertyUpdate(updatedState, message);
            }

            return updatedState;
        }

        case CHAT_PAGE_RESET:
            return {...chatPageInitialState};

        case CHAT_PAGE_ADD_INTERIM_CHAT: {

            // Create interim message
            const interimChat: IChat = {
                id: INTERIM_MESSAGE_ID,
                interimUuid: payload.interimUuid,
                chattime: moment().unix(),
                chat: payload.message || '',
                media: payload.attachment,
                chattype: ChatType.TEXT,
                outgoing: true,
                agent_id: payload.agentId,
                pending: true,
                interimMessage: true,
                bot: 0,
                // add notification to compare in mergeOutgoingChat function and remove interim message
                notification: payload.notification,
            };

            return {
                ...state,
                chats: [interimChat, ...state.chats || []],
            };
        }

        case CHAT_SEND_PENDING: {
            return {
                ...state,
                // reset stuff
                message: '',
                attachment: undefined,
                chatBlockId: undefined,
            };
        }

        // remove interim messages on fulfilled sending
        case CHAT_SEND_FULFILLED: {
            if (!state.chats) {
                return state;
            }

            const interimChat = state.chats.find(chat => chat.interimUuid === meta.interimUuid);

            // if there is no chat with the uuid, the UPDATE_STREAM handle was faster and there is nothing more to do.
            // See chatPage.reducer.utils -> handleOutgoingChat
            if (!interimChat) {
                return state;
            }

            const newChat = {...interimChat, chat: payload.message};

            return {
                ...state,
                chats: removeInterimMessage(state.chats, newChat),
            };
        }

        case CHAT_PAGE_TOGGLE_IMAGE_LIGHTBOX:
            return {...state, lightboxOpen: !state.lightboxOpen};

        case CHAT_PAGE_SEARCH_TERM_SET:
            return {...state, searchTerm: payload};

        case CHAT_PAGE_RESET_UPLOAD: {
            return {
                ...state,
                uploadUrl: '',
                uploadError: undefined,
            };
        }

        case CHAT_PAGE_UPLOAD_MEDIA_PENDING:
            return {...state, uploadUrl: '', uploadError: undefined, uploading: true};

        case CHAT_PAGE_UPLOAD_MEDIA_FULFILLED:
            return {...state, uploadUrl: payload, uploadError: undefined, uploading: false};

        case CHAT_PAGE_UPLOAD_MEDIA_REJECTED:
            return {...state, uploadUrl: '', uploadError: payload.message, uploading: false};

        case CHAT_PAGE_GET_USER_PENDING:
            return {
                ...state,
                isUserFetching: true,
                requestUserId: meta.id,
                // clear old data when changing user
                user: meta.id !== state.requestUserId ? undefined : state.user,
            };

        case CHAT_PAGE_GET_USER_REJECTED:
            if (state.requestUserId !== meta.id) {
                return state;
            }
            return {...state, isUserFetching: false};

        case CHAT_PAGE_GET_USER_FULFILLED:
            if (state.requestUserId !== meta.id) {
                return state;
            }
            return {...state, user: payload, isUserFetching: false};

        case CHAT_PAGE_PUT_USER_REJECTED: {
            const {oldUser} = meta;

            return {...state, user: oldUser};
        }
        case CHAT_PAGE_SET_MESSAGE: {
            const {message, attachment, id} = payload;

            return {...state, message: message, attachment, messageBinding: true, chatBlockId: id};
        }

        case CHATS_GET_BY_USER_PENDING:
            return {
                ...state,
                isTicketFetching: true,
                requestConversationId: meta.id,
                // clear old data when changing user
                chats: meta.id !== state.requestConversationId ? undefined : state.chats,
            };

        case CHATS_GET_BY_USER_FULFILLED: {
            if (state.requestConversationId !== meta.id) {
                return state;
            }

            return {
                ...state,
                isTicketFetching: false,
                activeConversationId: meta.id,
                chats: sortChats(Object.values(payload.chats)),
            };
        }

        case CHATS_GET_BY_USER_REJECTED: {
            if (state.requestConversationId !== meta.id) {
                return state;
            }

            return {
                ...state,
                isTicketFetching: false,
            };
        }

        case TICKET_GET_BY_ID_PENDING:
            return {
                ...state,
                isTicketFetching: true,
                requestConversationId: meta.id,
                // clear old data when changing user
                ticket: meta.id !== state.requestConversationId ? undefined : state.ticket,
                chats: meta.id !== state.requestConversationId ? undefined : state.chats,
                history: meta.id !== state.requestConversationId ? [] : state.history,
            };

        case TICKET_GET_BY_ID_REJECTED: {
            if (state.requestConversationId !== meta.id) {
                return state;
            }

            return {
                ...state,
                isTicketFetching: false,
            };
        }

        case TICKET_GET_BY_ID_FULFILLED: {
            const ticket = payload.tickets[0] as ITicket;

            if (state.requestConversationId !== meta.id) {
                return state;
            }

            return {
                ...state,
                isTicketFetching: false,
                activeConversationId: ticket.id || ticket.user_id,
                ticket: ticket,
                chats: sortChats(ticket.chats),
                // create a 'weak' user object with some data used in AssingmentChatPanel
                // (for ticket preview when user is not loaded)
                // TODO: move ticket preview to its own reducer
                ticketPreviewUser: {
                    id: ticket.user_id,
                    name: ticket.name,
                    image: ticket.profile_image,
                    messenger: ticket.messenger,
                },
            };
        }

        case CHAT_PAGE_RESET_MESSAGE:
            return {...state, message: '', attachment: undefined, messageBinding: true};

        case CHAT_PAGE_RESET_ATTACHMENT_PREVIEW:
            return {...state, uploadUrl: '', uploadError: undefined};

        case CHAT_PAGE_SET_FOCUS_TEXT_FIELD:
            return {
                ...state,
                focusTextField: payload,
            };

        case CHAT_PAGE_GET_TICKET_HISTORY_PENDING:
            // clear history when user changes
            if (meta.id !== state.requestUserId && meta.id !== state.user?.id) {
                return {
                    ...state,
                    history: [],
                };
            }
            return state;

        case CHAT_PAGE_GET_TICKET_HISTORY_FULFILLED:
            // ignore fulfilled requests for outdated user
            if (meta.id !== state.requestUserId && meta.id !== state.user?.id) {
                return state;
            }
            return {
                ...state,
                history: payload,
            };

        case TICKET_UPDATE_NOTE_FULFILLED: {
            if (!state.ticket) {
                return state;
            }

            return {
                ...state,
                ticket: updateSingleTicket(state.ticket, {...payload.ticket}),
            };
        }

        case CHAT_DELETE_PENDING: {
            const {ticketToUpdate, chatId, agentId} = meta;

            const newChats = ticketToUpdate.chats.reduce((previous: IChat[], current: IChat) => {
                if (current.id !== chatId) {
                    return [...previous, current];
                }

                const deletedChat = {
                    ...current,
                    chat: EChatMessage.Deleted,
                    chattype: 'text',
                    media: '',
                    meta: {
                        ...current.meta,
                        action: EChatAction.DeletedByAgent,
                        agent: agentId,
                    },
                };

                return [...previous, deletedChat];
            }, [] as IChat[]);

            const newTicket: ITicket = {...ticketToUpdate, chats: newChats};

            return {...state, ticket: newTicket};
        }
        case CHAT_DELETE_REJECTED: {
            const {ticketToUpdate} = meta;

            return {...state, ticket: ticketToUpdate};
        }

        case CHAT_PAGE_GET_LIVE_CHAT_CONVERSATION_REJECTED:
            // ignore fulfilled requests for outdated user
            if (meta.id !== state.requestUserId && meta.id !== state.user?.id) {
                return state;
            }
            return {
                ...state,
                lcConversationId: null, // set to null to mark as not found
                lcBeaconServer: undefined,
            };

        case CHAT_PAGE_GET_LIVE_CHAT_CONVERSATION_FULFILLED:
            // ignore fulfilled requests for outdated user
            if (meta.id !== state.requestUserId && meta.id !== state.user?.id) {
                return state;
            }
            return {
                ...state,
                lcConversationId: payload.uuid,
                lcBeaconServer: payload.server,
            };

        case CHAT_PAGE_TOGGLE_SIDEBAR:
            return {
                ...state,
                isSidebarOpen: !state.isSidebarOpen,
            };

        case CHAT_PAGE_TOGGLE_ARCHIVE:
            return {
                ...state,
                showArchive: payload,
            };

        case USER_SET_UNREAD_FULFILLED:
            if (state.user) {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        unread: payload.unread,
                    },
                };
            }
            break;

        case USER_START_FULFILLED:
        case USER_STOP_FULFILLED:
            if (payload.id && payload.id === state.user?.id) {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        status: payload.status,
                    },
                };
            }
            break;

        default:
            break;
    }

    return state;
};
