
export enum EMiaFormFields {
    Media = 'media',
    Text = 'text',
    Date = 'published',
    Targeting = 'targeting',
    Notification = 'notification',
    Keyword = 'pull_keyword',
}

export const MIA_FORM = 'mia';
export const MIA_MAX_NOTIFICATION_LENGTH = 150;
