
function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M32.204 13.831l-.313.195c-2.46 1.537-5.662.153-6.317-2.713-.388-1.701-2.76-1.701-3.148 0-.655 2.866-3.856 4.25-6.317 2.713l-.313-.195c-1.348-.842-2.935.775-2.04 2.177 1.595 2.497.26 5.865-2.595 6.502-1.562.35-1.562 2.63 0 2.98 2.854.637 4.19 4.006 2.596 6.502-.896 1.402.69 3.019 2.04 2.177l.311-.195c2.462-1.537 5.663-.153 6.318 2.713.388 1.701 2.76 1.701 3.148 0 .655-2.866 3.856-4.25 6.317-2.713l.313.195c1.348.842 2.935-.775 2.04-2.177-1.595-2.497-.26-5.865 2.595-6.502 1.562-.35 1.562-2.63 0-2.98-2.854-.637-4.19-4.006-2.596-6.502.896-1.402-.69-3.019-2.04-2.177zM27.51 10.85c-.867-3.799-6.155-3.799-7.022 0-.348 1.52-2.044 2.25-3.345 1.438l-.312-.195c-3.184-1.988-6.781 1.836-4.74 5.031.833 1.306.133 3.064-1.355 3.397-3.65.815-3.65 6.145 0 6.96 1.488.333 2.188 2.091 1.355 3.397-2.041 3.195 1.556 7.019 4.74 5.031l.312-.195c1.301-.812 2.998-.081 3.345 1.438.867 3.799 6.155 3.799 7.022 0 .348-1.52 2.044-2.25 3.345-1.438l.312.195c3.184 1.988 6.781-1.836 4.74-5.031-.833-1.306-.133-3.064 1.355-3.397 3.65-.815 3.65-6.145 0-6.96-1.488-.333-2.188-2.091-1.355-3.397 2.041-3.195-1.556-7.019-4.74-5.031l-.312.195c-1.301.812-2.998.081-3.345-1.438z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#FFBE3C"
                fillRule="evenodd"
                d="M24 21a3 3 0 100 6 3 3 0 000-6zm-5 3a5 5 0 1110 0 5 5 0 01-10 0z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Icon;
