import {change} from 'redux-form';

import {TAsyncDispatch} from '../../definitions/generic/generic.definitions';
import {uploadFormDataRequest} from '../../requests/uploadRequests';
import {FORM_UPLOADER_RESET_FILE, FORM_UPLOADER_UPLOAD_FILE} from "./formUploader.types";


export interface IReduxFormUploadResponse {
    value?: any;
}

// upload file, then dispatch form field change
export const reduxFormUploadFile = (payload: any, form: any, field: string) => (
    dispatch: TAsyncDispatch<IReduxFormUploadResponse>,
) =>
    dispatch({
        type: FORM_UPLOADER_UPLOAD_FILE,
        payload: uploadFormDataRequest(payload),
    }).then(response => dispatch(change(form, field, response.value)));

export const reduxFormResetFile = () => ({
    type: FORM_UPLOADER_RESET_FILE,
});
