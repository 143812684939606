
function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill="#007171"
                d="M32.175 18.4c0 .6.4 1 1 1 .2 0 .3 0 .5-.1l8.6-5c.5-.3.7-.9.4-1.4-.3-.5-.9-.7-1.4-.4l-8.6 5c-.3.2-.5.5-.5.9zM43.6 17.668a1 1 0 111 1.732l-7.022 4.054a1 1 0 11-1-1.732l7.022-4.054z"
            ></path>
            <path
                fill="#272727"
                d="M37.871 25.903a1.237 1.237 0 01.024-.123c.124-.485.565-.84 1.074-.807.55.036.971.512.901 1.06l-.018.141-.006.042a16 16 0 11-5.043-14.018l.032.029.104.097c.403.377.382 1.012-.02 1.39-.371.35-.937.358-1.341.063a1.055 1.055 0 01-.097-.08 14 14 0 104.39 12.206z"
            ></path>
            <path
                fill="#272727"
                d="M26 17a1 1 0 10-2 0v7.586l-4.707 4.707a1 1 0 001.414 1.414l5-5A1 1 0 0026 25v-8z"
            ></path>
        </svg>
    );
}

export default Icon;
