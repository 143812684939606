import {FC} from "react";
import {Button, createStyles, makeStyles, Paper, Typography} from "@material-ui/core";
import bgDesktop4x from './assets/bg-v4-desktop-4x.png';
import bgMobile4x from './assets/bg-v4-mobile-4x.png';
import bgDesktop2x from './assets/bg-v4-desktop-2x.png';
import bgMobile2x from './assets/bg-v4-mobile-2x.png';
import translations from "../../../providers/TranslationProvider/translations";

const useStyles = makeStyles(theme => createStyles({
    root: {
        marginBottom: 16,
        borderRadius: 20,
        backgroundColor: '#FFE8E4',
        overflow: 'hidden',

        [theme.breakpoints.up('md')]: {
            borderRadius: 40,
        },
    },
    flex: {
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    bg: {
        backgroundImage: `image-set(
          url(${bgMobile2x}) 1x,
          url(${bgMobile4x}) 2x
        )`,
        backgroundPosition: 'top center',
        backgroundSize: 'cover',
        height: '30vw',
        minHeight: 168,

        [theme.breakpoints.up('md')]: {
            height: 'auto',
            minHeight: 350,
            width: '60%',
            backgroundPosition: 'top left',
            backgroundImage: `image-set(
              url(${bgDesktop2x}) 1x,
              url(${bgDesktop4x}) 2x
            )`,
            order: 1,
        },

        '@media (min-device-pixel-ratio: 1.5)': {
            backgroundImage: `url(${bgDesktop4x})`,
        },
    },
    textBox: {
        padding: 24,

        [theme.breakpoints.up('md')]: {
            width: '40%',
            padding: 40,
        },
    },
    button: {
        textTransform: 'none',
    },
    listItem: {
        display: 'list-item',
    },
}));


export const DashboardSEOnboarding4: FC = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.root} elevation={0}>
            <div className={classes.flex}>
                <div className={classes.bg}/>
                <div className={classes.textBox}>
                    <Typography variant={"h2"} gutterBottom>
                        {translations.dashboard_SEOnboarding_title_v4}
                    </Typography>
                    <Typography variant={"body1"} gutterBottom paragraph>
                        {translations.dashboard_SEOnboarding_text_v4}
                    </Typography>
                    <Typography variant={"body1"} gutterBottom paragraph>
                        {translations.dashboard_SEOnboarding_text2_v4}
                    </Typography>
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        onClick={() => window.open(translations.dashboard_SEOnboarding_link_redirect_v4, "_blank")}
                        className={classes.button}
                    >
                        {translations.dashboard_SEOnboarding_button_v4}
                    </Button>
                </div>
            </div>
        </Paper>
    );
};
