import {BASE_URL, HEADER_X_CLIENT_DOMAIN} from '../config';
import {
    ITicketGetRequest,
    ITicketPutBatchRequest,
    ITicketPutRequest,
} from '../definitions/tickets/ticketRequestState.definitions';
import {request} from './Api/Api';
import {downloadRequest} from "./downloadRequest";

export const ticketRequest = (payload: ITicketGetRequest) => request({url: 'ticket/{id}', method: 'GET'}, payload);
export const ticketPutRequest = (payload: ITicketPutRequest) => request({url: 'ticket/{id}', method: 'PUT'}, payload);

export const ticketPutBatchRequest = (payload: ITicketPutBatchRequest) =>
    request({url: 'ticket', method: 'PUT'}, payload);

export const ticketOpenRequest = (userId: number) => request({url: 'ticket/{id}/open', method: 'PUT'}, {id: userId});
export const ticketDownloadRequest = (ticketId: number, language = 'en') => {
    const brand = HEADER_X_CLIENT_DOMAIN.toUpperCase() !== 'MCP' ? '&brand=sinch' : '';
    return downloadRequest(`${BASE_URL}/tickets/${ticketId}/pdf?language=${language}${brand}`);
};

export const ticketCloseBatchRequest = (batch_ids: number[], note: string, label_ids: number[]) =>
    request({url: 'ticket/close', method: 'PUT'}, {batch_ids, note, label_ids});

export const ticketPostNoteRequest = (ticketId: number, note: string) =>
    request({url: `ticket/${ticketId}/note`, method: 'POST'}, {note});
export const ticketPutNoteRequest = (ticketId: number, noteId: number, note: string) =>
    request({url: `ticket/${ticketId}/note/${noteId}`, method: 'PUT'}, {note});
export const ticketDeleteNoteRequest = (ticketId: number, noteId: number) =>
    request({url: `ticket/${ticketId}/note/${noteId}`, method: 'DELETE'});

export const putLabelToTicketRequest = (ticketId: number, labelId: number) =>
    request({url: `ticket/${ticketId}/label/${labelId}`, method: 'PUT'});
export const putLabelsToTicketsRequest = (ticketIds: number[], labelIds: number[]) =>
    request({url: `ticket/label`, method: 'PUT'}, {batch_ids: ticketIds, label_ids: labelIds});
export const deleteLabelFromTicketRequest = (ticketId: number, labelId: number) =>
    request({url: `ticket/${ticketId}/label/${labelId}`, method: 'DELETE'});

export const getTicketHistoryRequest = (userId: number) =>
    request({method: 'GET', url: 'ticket/ticket_history'}, {userId});

export const deleteChatRequest = (id: number) => request({method: 'DELETE', url: 'chat'}, {id});
