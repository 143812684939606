import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {NECTARY_COLORS} from '../../../theme/muiTheme';

export const useTableStyles = makeStyles(theme =>
    createStyles({
        dataGridRoot: {
            // background: 'rgba(0,0,0,0.03)',

            '& .MuiDataGrid-columnsContainer': {
                borderBottom: '0 none !important',
            },
            '& .MuiDataGrid-columnSeparator': {
                display: 'none !important',
            },
            '& .MuiDataGrid-iconButtonContainer': {
                display: 'none !important',
                marginLeft: `${theme.spacing(0.5)}px !important`,
                marginRight: `${theme.spacing(-2)}px !important`,
            },
            // '& .MuiDataGrid-columnHeaderWrapper': {
            //     background: darken(theme.palette.background.default, 0.05),
            // },
            '& .MuiDataGrid-columnHeaderTitleContainer': {
                overflow: 'visible !important',
            },
            '& .MuiDataGrid-columnHeaderTitleContainer:hover > .MuiDataGrid-iconButtonContainer': {
                display: 'block !important',
            },
        },
        dataGrid: {
            border:'0 none !important',
            marginTop: theme.spacing(0.25),
        },
        row: {
            // we reset the value 'width: fit-content' to prevent the rows from taking all the space on the right if they are not wider than 100%
            width: 'initial !important',
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            borderRadius: theme.shape.borderRadius,

            '&:nth-of-type(odd)': {
                backgroundColor: NECTARY_COLORS.snow600,
            },

            '&:nth-of-type(odd):hover': {
                backgroundColor: NECTARY_COLORS.snow700,
            },

            '& *': {
                userSelect: 'text',
            },
        },
        columnHeader: {

            '&:first-child > div': {
                marginLeft: theme.spacing(1),
            },
        },
        cell: {
            borderColor: 'transparent !important',

            '&:first-child': {
                paddingLeft: `${theme.spacing(2)}px !important`,
            },
            // This will only always work with disableExtendRowFullWidth set to true on the DataGrid component, because
            // DataGrid adds a blank cell to gain full width which will omit this rule on the last visible cell.
            '&:last-child': {
                paddingRight: `${theme.spacing(3)}px !important`,
            },
        },
    }),
);
