/* eslint-disable no-useless-escape */

// use "localhost" in resources instead of process.env.NODE_ENV === 'development',
// so we can use both, remote or localhost (docker run -p "8080:80" -it registry.messengerpeople.dev/chimera-staging)
import {ILanguageNativeNames} from './definitions/languages/languages.definitions';
import axios from "axios";

export const CLIENT_DOMAIN_URL_PARAM = 'domain';

export const HEADER_X_CLIENT_DOMAIN = process.env.REACT_APP_HEADER_X_CLIENT_DOMAIN || 'engage';

export let BASE_URL = `${process.env.REACT_APP_MCP_API_URL}/api/v${process.env.REACT_APP_MCP_API_VERSION}`;
export const setBaseUrl = (url: string) => {
    BASE_URL = url;
    axios.defaults.baseURL = BASE_URL;
};


export const DOCUMENTATION_URL = process.env.REACT_APP_MCP_API_URL || '';

export let CDN_URL = process.env.REACT_APP_CDN_URL || '';
export const setCDNUrl = (url: string) => CDN_URL = url;

export let BEACON_API_URL = process.env.REACT_APP_BEACON_API_URL || '';
export const setBeaconApiUrl = (url: string) => BEACON_API_URL = url;

export let BOT_BUILDER_URL = process.env.REACT_APP_BOT_BUILDER_URL || '';
export const setBotBuilderUrl = (url: string) => BOT_BUILDER_URL = url;

export let WEBSOCKET_URL = process.env.REACT_APP_UPDATE_STREAM_SERVER || '';
export const setWebsocketUrl = (url: string) => WEBSOCKET_URL = url;

export let IMAGE_SERVER_URL = process.env.REACT_APP_IMG_SERVER_URL || '';
export const setImageServerUrl = (url: string) => IMAGE_SERVER_URL = url;

// expose for debugging
if (process.env.NODE_ENV === 'development') {
    Object.assign(window, {
        setCDNUrl,
        setBaseUrl,
        setBeaconApiUrl,
        setBotBuilderUrl,
        setWebsocketUrl,
        setImageServerUrl,
    });
}

// default user image URL
export const getDefaultProfileImageUrl = () => `${IMAGE_SERVER_URL}/blank.jpg`;

// Email addresses
export let EMAIL_SUPPORT = 'support@messengerpeople.com';
export const setSupportEmail = (email: string) => EMAIL_SUPPORT = email;

//Password regex criteria
export const PASSWORD_CRITERIA = [
    /([0-9]+)/, // numbers
    /([a-z]+)/, // lowercase letters
    /([A-Z]+)/, // upper-case letters
    /([!"§$%&\/()=><|\-.,;:_#^€]+)/, // special chars
];

export const PASSWORD_MINIMAL_LENGTH = 6;

// This is a map of language shorthands to their respective native translations.
// This is NOT the list of languages available in frontend or in the widget. That is defined in config.ts
export const LanguageNativeNames: ILanguageNativeNames = {
    de: 'Deutsch',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    hr: 'Hrvatski',
    it: 'Italiano',
    nl: 'Nederlands',
    pl: 'Polski',
    pt: 'Português',
    ru: 'Pусский',
    tr: 'Türkçe',
    ar: 'العربية ',
    jp: '日本語',
};

// Frontend languages
export const APP_LANGUAGES = ['en', 'de', 'es', 'fr', 'pt', 'it'];

// Widget languages (by now we don't support other languages than in the app. but this will be the next feature.)
// Please leave the commented array
export const WIDGET_LANGUAGES = APP_LANGUAGES.slice(0); // ['ar', 'de', 'en', 'es', 'fr', 'hr', 'pt', 'it', 'tr'];
WIDGET_LANGUAGES.push('pl', 'ru');

// Urls
export let URL_CONTACT = 'https://www.messengerpeople.com/contact/';
export let URL_FAQ = 'https://www.messengerpeople.com/faq/';
export let URL_IMPRINT = 'https://www.messengerpeople.com/legal-information/';
export let URL_TERMS_AND_CONDITIONS = 'https://www.messengerpeople.com/terms-and-conditions/';
export let URL_TERMS_AND_CONDITIONS_BRAZIL = 'https://www.messengerpeople.com/pt-br/termos-e-condicoes-gerais/';
export let URL_TERMS_AND_CONDITIONS_MEXICO = 'https://www.messengerpeople.com/es/terminos-y-condiciones/#mexico';

export const setContactUrl = (url: string) => URL_CONTACT = url;
export const setFAQsUrl = (url: string) => URL_FAQ = url;
export const setImprintUrl = (url: string) => URL_IMPRINT = url;
export const setTACUrl = (url: string) => URL_TERMS_AND_CONDITIONS = url;
export const setTACUrlBrazil = (url: string) => URL_TERMS_AND_CONDITIONS_BRAZIL = url;
export const setTACUrlMexico = (url: string) => URL_TERMS_AND_CONDITIONS_MEXICO = url;

export {default as DEFAULT_IMAGE} from './resources/img/logo_icon.png';

export const FACEBOOK_GRAPH_API_V3 = 'https://graph.facebook.com/v3.1';
export const FACEBOOK_GRAPH_API_V5 = 'https://graph.facebook.com/v5.0';

export const INVOICE_DOWNLOAD_URL = `/files/invoice`;

export const FACEBOOK_APP_ID = 1080838208644271;

export const PAGINATION_LIMIT = 150;

// Google Tag Manager
export const GOOGLE_TAG_MANAGER_ID = 'GTM-KVXLQHF';
export const GOOGLE_TAG_MANAGER_DEV_AUTH_KEY = '_eKVz2ndG4y2vy61Cqqcig';
export const GOOGLE_TAG_MANAGER_DEV_PREVIEW = 'env-11';
export const GOOGLE_TAG_MANAGER_DATA_LAYER_NAME = 'MCP';

// Google Maps (https://console.cloud.google.com/google/maps-apis/credentials?project=messengerpeople-dev-databases)
export const GOOGLE_MAPS_API_KEY = 'AIzaSyChgSM-X6MFlZLkZCTlZcBhaBtTvDu8VMw';

export const STRIPE_PUBLIC_KEYS = {
    'MCP': process.env.REACT_APP_STRIPE_PUBLIC_KEY_MCP || '',
    'SINCH_BR': process.env.REACT_APP_STRIPE_PUBLIC_KEY_SINCH_BR || '',
    'SINCH_MX': process.env.REACT_APP_STRIPE_PUBLIC_KEY_SINCH_MX || '',
    'SINCH_INDIA': process.env.REACT_APP_STRIPE_PUBLIC_KEY_SINCH_INDIA || '',
};

// Regex
export const URL_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const blacklistedCustomersForSE = [48092, 48271, 48900, 49154, 49289, 49152, 49182, 49184, 49291, 48412, 48940];
