export enum EUserTableColumns {
    NUMBER = 'NUMBER',
    MESSENGER = 'MESSENGER',
    FIRST_CONTACT = 'FIRST_CONTACT',
    LAST_CHAT = 'LAST_CHAT',
    IS_BLOCKED = 'IS_BLOCKED',
    NAME = 'NAME',
    STATUS = 'STATUS',
    CUSTOM_FIELD = 'CUSTOM_FIELD',
}
