import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import {IPartialWidget, IWidgetEditorForm} from '../../../definitions/settings/widgetEditor/widgetEditor.definitions';
import {
    deleteWidgetRequest,
    getViberTokenRequest,
    getWidgetByIdRequest,
    getWidgetPossibleSettingsRequest,
    getWidgetRequest,
    postWidgetRequest,
    putWidgetRequest,
    sendViberMessageRequest,
} from '../../../requests/widgetRequests';
import {IState} from '../../root.reducer';
import {EWidgetDialogSteps} from '../../../enums/widget/EWidget';
import {getWidgetConfigFromForm} from './widgetEditor.selectors';
import {ITranslations} from '../../../providers/TranslationProvider/translations';
import {liveChatDefaultSettings} from "./widgetEditor.reducer";
import {
    SETTINGS_WIDGET_DELETE,
    SETTINGS_WIDGET_GET_ALL,
    SETTINGS_WIDGET_GET_BY_ID,
    SETTINGS_WIDGET_GET_POSSIBLE_SETTINGS,
    SETTINGS_WIDGET_GET_VIBER_TOKEN,
    SETTINGS_WIDGET_RESET_VIBER_SUCCESS,
    SETTINGS_WIDGET_SEND_VIBER_MESSAGE,
    SETTINGS_WIDGET_SET_STEP,
    WIDGET_EDITOR_CHANGE_VALUE,
    WIDGET_EDITOR_EDIT_WIDGET,
    WIDGET_EDITOR_RESET,
    WIDGET_EDITOR_RESET_CHIP_CONFIG,
    WIDGET_EDITOR_SAVE_WIDGET,
    WIDGET_EDITOR_SET_DEFAULTS,
} from './widgetEditor.types';


export const saveWidget = () => (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
    const config = getWidgetConfigFromForm(getState());

    return dispatch({
        type: WIDGET_EDITOR_SAVE_WIDGET,
        payload: config.uniqueid ? putWidgetRequest(config as IPartialWidget) : postWidgetRequest(config),
    });
};

export const setWidgetDefaults = (translations: ITranslations) => (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => IState,
) => {
    const {channel} = getState();

    return dispatch({
        type: WIDGET_EDITOR_SET_DEFAULTS,
        payload: {
            language: channel.language,
            privacy_link: channel.privacy_link,
            privacy_text: translations.widget_default_agreement_text,
            body: translations.widget_default_body,
            title: translations.widget_default_title,
        },
    });
};

export const setLiveChatWidgetDefaults = () => ({
    type: WIDGET_EDITOR_SET_DEFAULTS,
    payload: liveChatDefaultSettings,
});

export const editWidget = (id: string) => ({
    type: WIDGET_EDITOR_EDIT_WIDGET,
    payload: {id},
});

export const resetChipConfig = () => ({
    type: WIDGET_EDITOR_RESET_CHIP_CONFIG,
});

export const changeWidgetEditorFormValue = (key: keyof IWidgetEditorForm, value: any) => ({
    type: WIDGET_EDITOR_CHANGE_VALUE,
    payload: {key, value},
});

export const resetWidgetEditor = () => ({
    type: WIDGET_EDITOR_RESET,
});

export const setWidgetDialogStep = (step: EWidgetDialogSteps) => {
    return {
        type: SETTINGS_WIDGET_SET_STEP,
        payload: step,
    };
};

export const getWidgetById = (widgetUniqueId: string) => ({
    type: SETTINGS_WIDGET_GET_BY_ID,
    payload: getWidgetByIdRequest(widgetUniqueId),
});

export const getAllWidgets = () => ({
    type: SETTINGS_WIDGET_GET_ALL,
    // 0 gets all
    payload: getWidgetRequest(0),
});

export const getPossibleWidgetSettings = (language: string) => ({
    type: SETTINGS_WIDGET_GET_POSSIBLE_SETTINGS,
    payload: getWidgetPossibleSettingsRequest(language),
    meta: {language},
});

export const deleteWidget = (id: number) => ({
    type: SETTINGS_WIDGET_DELETE,
    payload: deleteWidgetRequest({id}),
});

export const subscribeToViber = (phoneNumber: string, apikey: string) => ({
    type: SETTINGS_WIDGET_SEND_VIBER_MESSAGE,
    payload: sendViberMessageRequest(phoneNumber, apikey),
});

export const resetViber = () => ({
    type: SETTINGS_WIDGET_RESET_VIBER_SUCCESS,
});

export const getTokenForViberSubscription = (widgetApiKey: string) => ({
    type: SETTINGS_WIDGET_GET_VIBER_TOKEN,
    payload: getViberTokenRequest(widgetApiKey),
});


