import {CreateShopConfigParams} from '../../../definitions/settings/shop/shop.definitions';
import {createShopConfigRequest, deleteShopRequest, readShopConfigRequest} from '../../../requests/shopRequests';
import {CREATE_SHOP_CONFIG, DELETE_SHOP_CONFIG, READ_SHOP_CONFIG} from "./settingsShop.types";


export const readShopConfig = () => ({
    type: READ_SHOP_CONFIG,
    payload: readShopConfigRequest(),
});

export const createShopConfig = (params: CreateShopConfigParams) => ({
    type: CREATE_SHOP_CONFIG,
    payload: createShopConfigRequest(params),
});

export const deleteShop = (shop?: string) => ({
    type: DELETE_SHOP_CONFIG,
    payload: deleteShopRequest(shop),
    meta: {shop},
});
