import cloneDeep from 'lodash/cloneDeep';
import {Reducer} from 'redux';
import {ITargetGroup} from '../../../definitions/targetGroup/targetGroup.definitions';
import {IQueryBuilderState, IQueryJson} from '../../../definitions/targeting/targeting.definitions';
import {EConditionOperator} from '../../../enums/targeting/EConditionOperator';
import {
    TARGETING_QUERY_BUILDER_ADD_ERROR,
    TARGETING_QUERY_BUILDER_GET_COUNT_FULFILLED,
    TARGETING_QUERY_BUILDER_GET_COUNT_PENDING,
    TARGETING_QUERY_BUILDER_GET_COUNT_REJECTED,
    TARGETING_QUERY_BUILDER_REMOVE_ERROR,
    TARGETING_QUERY_BUILDER_RESET_QUERY,
    TARGETING_QUERY_BUILDER_SET_ITEM,
    TARGETING_QUERY_BUILDER_SET_OUTDATED,
    TARGETING_QUERY_BUILDER_SET_PRISTINE,
    TARGETING_QUERY_BUILDER_UPDATE_ITEM,
} from './queryBuilder.types';

const initialItem: ITargetGroup = {
    id: 0,
    name: '',
    query: {
        condition: EConditionOperator.AND,
        rules: [
            {
                field: '',
            },
        ],
        valid: true,
    },
};

export const queryBuilderInitialState: IQueryBuilderState = {
    item: initialItem,
    targetCount: undefined,
    targetCountLoading: false,
    pristine: true,
    outdated: false,
    errors: [],
};

export const queryBuilderReducer: Reducer<IQueryBuilderState> = (state = queryBuilderInitialState, action) => {
    switch (action.type) {
        case TARGETING_QUERY_BUILDER_SET_ITEM: {
            // make clone, otherwise we will alter the item from the props and discard/reset item wont work
            const item = action.payload ? (cloneDeep(action.payload) as ITargetGroup) : {...initialItem};

            return {
                ...state,
                item,
                pristine: true,
                outdated: false,
                errors: [],
                targetCount: undefined,
            };
        }
        case TARGETING_QUERY_BUILDER_UPDATE_ITEM: {
            const item = action.payload ? {...action.payload} : {...initialItem};

            return {
                ...state,
                item,
                pristine: false,
                outdated: false,
            };
        }
        case TARGETING_QUERY_BUILDER_RESET_QUERY: {
            return {
                ...state,
                item: {
                    ...initialItem,
                    name: state.item.name,
                },
                pristine: true,
                outdated: false,
                errors: [],
            };
        }
        case TARGETING_QUERY_BUILDER_SET_OUTDATED: {
            return {
                ...state,
                outdated: action.payload,
            };
        }
        case TARGETING_QUERY_BUILDER_SET_PRISTINE: {
            return {
                ...state,
                pristine: action.payload,
            };
        }
        case TARGETING_QUERY_BUILDER_ADD_ERROR: {
            return {
                ...state,
                errors: [...state.errors.filter(error => error !== action.payload), action.payload],
                item: {
                    ...state.item,
                    query: {
                        ...state.item.query,
                        valid: false,
                    } as IQueryJson,
                },
            };
        }
        case TARGETING_QUERY_BUILDER_REMOVE_ERROR: {
            const errors = state.errors.filter(error => error !== action.payload);
            return {
                ...state,
                errors,
                item: {
                    ...state.item,
                    query: {
                        ...state.item.query,
                        valid: !errors.length,
                    } as IQueryJson,
                },
            };
        }
        case TARGETING_QUERY_BUILDER_GET_COUNT_PENDING: {
            return {
                ...state,
                targetCountLoading: true,
            };
        }
        case TARGETING_QUERY_BUILDER_GET_COUNT_FULFILLED: {
            return {
                ...state,
                targetCount: action.payload.count,
                targetCountLoading: false,
            };
        }
        case TARGETING_QUERY_BUILDER_GET_COUNT_REJECTED: {
            return {
                ...state,
                targetCount: undefined,
                targetCountLoading: false,
            };
        }
        default:
            return state;
    }
};
