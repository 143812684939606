
function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M25.076 10.128a1 1 0 00-1.152 0l-11.5 8.092a1 1 0 00-.424.818V35.5c0 .552.448 1.5 1 1.5h6V24.5a3 3 0 013-3h5a3 3 0 013 3V37h6c.552 0 1-.948 1-1.5V19.038a1 1 0 00-.425-.818l-11.5-8.092zM28 37V24.5a1 1 0 00-1-1h-5a1 1 0 00-1 1V37h7zM22.773 8.492a3 3 0 013.454 0l11.5 8.093A3 3 0 0139 19.038V35.5c0 1.657-1.343 3.5-3 3.5H13c-1.657 0-3-1.843-3-3.5V19.038a3 3 0 011.274-2.453l11.5-8.093z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#FFBE3C"
                fillRule="evenodd"
                d="M23.923 3.183a1 1 0 011.17.011l17 12.5a1 1 0 01-1.185 1.612L24.488 5.232 11.587 14.34l-3.99 2.964a1 1 0 11-1.193-1.606L10 13.026V7a1 1 0 011-1h3.5a1 1 0 011 1v2.128l8.423-5.945zM13.501 10.54V8H12v3.599l1.5-1.06z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Icon;
