import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import {useSelector} from 'react-redux';

import translations from '../../../../../../../providers/TranslationProvider/translations';
import {getEndOfTestPeriod} from '../../../../../../../redux/accounting/accounting.selectors';
import {translateSlug} from '../../../../../../../utils/translation/translation.utils';

export const getTestPhaseText = (endOfTestPeriod:number) => {
    return endOfTestPeriod <= moment().valueOf()
        ? translations.test_phase_is_over
        : translateSlug('test_phase_ending_x', moment(endOfTestPeriod).fromNow());

};

export const TestPhaseInformation = () => {
    const endOfTestPeriod = useSelector(getEndOfTestPeriod);
    return <Alert severity="warning" children={getTestPhaseText(endOfTestPeriod)} />;
};

export default TestPhaseInformation;
