export const USERS_GET = 'USERS_GET';
export const USERS_GET_FULFILLED = 'USERS_GET_FULFILLED';
export const USERS_GET_REJECTED = 'USERS_GET_REJECTED';
export const USERS_GET_PENDING = 'USERS_GET_PENDING';
export const USER_LIST_PAGE_SET_FILTER = 'USER_LIST_PAGE_SET_FILTER';

export const USER_DELETE = 'USER_DELETE';
export const USER_DELETE_FULFILLED = 'USER_DELETE_FULFILLED';
export const USER_BLOCK = 'USER_BLOCK';
export const USER_BLOCK_FULFILLED = 'USER_BLOCK_FULFILLED';
export const USER_UNBLOCK = 'USER_UNBLOCK';
export const USER_UNBLOCK_FULFILLED = 'USER_UNBLOCK_FULFILLED';

export const USER_START = 'USER_START';
export const USER_START_FULFILLED = 'USER_START_FULFILLED';
export const USER_STOP = 'USER_STOP';
export const USER_STOP_FULFILLED = 'USER_STOP_FULFILLED';

export const USERS_UPLOAD_CSV = 'USERS_UPLOAD_CSV';
export const USERS_UPLOAD_CSV_PENDING = 'USERS_UPLOAD_CSV_PENDING';
export const USERS_UPLOAD_CSV_REJECTED = 'USERS_UPLOAD_CSV_REJECTED';
export const USERS_UPLOAD_CSV_FULFILLED = 'USERS_UPLOAD_CSV_FULFILLED';

export const USER_SET_UNREAD = 'USER_SET_UNREAD';


export const USER_SET_UNREAD_FULFILLED = 'USER_SET_UNREAD_FULFILLED';
