export const CHANNEL_GET = 'CHANNEL_GET';
export const CHANNEL_GET_PENDING = 'CHANNEL_GET_PENDING';
export const CHANNEL_GET_REJECTED = 'CHANNEL_GET_REJECTED';
export const CHANNEL_GET_FULFILLED = 'CHANNEL_GET_FULFILLED';

export const CHANNEL_SET = 'CHANNEL_SET';
export const CHANNEL_SET_PENDING = 'CHANNEL_SET_PENDING';
export const CHANNEL_SET_REJECTED = 'CHANNEL_SET_REJECTED';
export const CHANNEL_SET_FULFILLED = 'CHANNEL_SET_FULFILLED';

export const CHANNEL_GET_AGENTS = 'CHANNEL_GET_AGENTS';
export const CHANNEL_GET_AGENTS_FULFILLED = 'CHANNEL_GET_AGENTS_FULFILLED';

export const CHANNEL_GET_LABELS = 'CHANNEL_GET_LABELS';
export const CHANNEL_GET_LABELS_FULFILLED = 'CHANNEL_GET_LABELS_FULFILLED';


export const CHANNEL_GET_TEAMS_FULFILLED = 'CHANNEL_GET_TEAMS_FULFILLED';

export const CHANNEL_CREATE_LABEL = 'CHANNEL_CREATE_LABEL';
export const CHANNEL_UPDATE_LABEL = 'CHANNEL_UPDATE_LABEL';
export const CHANNEL_DELETE_LABEL = 'CHANNEL_DELETE_LABEL';

export const CHANNEL_SET_IMAGE = 'CHANNEL_SET_IMAGE';
export const CHANNEL_SET_IMAGE_FULFILLED = 'CHANNEL_SET_IMAGE_FULFILLED';

export const CHANNEL_GET_INVOICES = 'CHANNEL_GET_INVOICES';
export const CHANNEL_GET_INVOICES_PENDING = 'CHANNEL_GET_INVOICES_PENDING';
export const CHANNEL_GET_INVOICES_REJECTED = 'CHANNEL_GET_INVOICES_REJECTED';
export const CHANNEL_GET_INVOICES_FULFILLED = 'CHANNEL_GET_INVOICES_FULFILLED';

export const CHANNEL_MESSENGER_USER_DELETE = 'CHANNEL_MESSENGER_USER_DELETE';
export const CHANNEL_MESSENGER_USER_DELETE_FULFILLED = 'CHANNEL_MESSENGER_USER_DELETE_FULFILLED';

export const CHANNEL_MESSENGER_MP_API_DELETE = 'CHANNEL_MESSENGER_MP_API_DELETE';
export const CHANNEL_MESSENGER_MP_API_DELETE_PENDING = 'CHANNEL_MESSENGER_MP_API_DELETE_PENDING';
export const CHANNEL_MESSENGER_MP_API_DELETE_FULFILLED = 'CHANNEL_MESSENGER_MP_API_DELETE_FULFILLED';
export const CHANNEL_MESSENGER_MP_API_DELETE_REJECTED = 'CHANNEL_MESSENGER_MP_API_DELETE_REJECTED';

export const CHANNEL_MESSENGER_MP_API_POST = 'CHANNEL_MESSENGER_MP_API_POST';
export const CHANNEL_MESSENGER_MP_API_POST_FULFILLED = 'CHANNEL_MESSENGER_MP_API_POST_FULFILLED';
