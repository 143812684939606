import {PureComponent} from 'react';

import {connect} from 'react-redux';

import {IActor} from '../../definitions/actor/actor.definitions';
import {IState} from '../../redux/root.reducer';
import {browserSupportsNotifications, NotificationPermission} from '../../utils/pushNotification/pushNotification.util';

interface IAskForPushNotificationProps {
    actor: IActor;
    isAllowed: boolean;
}

export class AskForPushNotification extends PureComponent<IAskForPushNotificationProps> {
    askForPermissionIfNotAlreadySet = () => {
        // Don't ask for permission if unchecked in settings, or browser has no support
        // or if actor already allowed or explicitly denied push notifications
        if (
            !this.props.isAllowed ||
            !browserSupportsNotifications() ||
            Notification.permission !== NotificationPermission.DEFAULT
        ) {
            return;
        }

        // Ask actor to activate push notifications for the current device
        // Callback version is deprecated, but we want to support older browsers
        // see: https://developer.mozilla.org/en-US/docs/Web/API/Notification/requestPermission
        // noinspection JSIgnoredPromiseFromCall
        Notification.requestPermission();
    };

    componentDidMount() {
        this.askForPermissionIfNotAlreadySet();
    }

    componentDidUpdate(prevProps: Readonly<IAskForPushNotificationProps>) {
        // only check if it wasn't allowed before, because if it was, we have already asked for permission
        if (!prevProps.isAllowed) {
            this.askForPermissionIfNotAlreadySet();
        }
    }

    render = () => null;
}

const mapStateToProps = ({actor}: IState) => ({
    actor, // we need this to update the whole profile (cannot update endpoints only)
    isAllowed: Boolean(actor && actor.settings && actor.settings.allowNotifications),
});

export default  connect(mapStateToProps)(AskForPushNotification);
