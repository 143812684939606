export const TARGET_GROUPS_GET = 'TARGET_GROUPS_GET';
export const TARGET_GROUPS_GET_PENDING = 'TARGET_GROUPS_GET_PENDING';
export const TARGET_GROUPS_GET_FULFILLED = 'TARGET_GROUPS_GET_FULFILLED';
export const TARGET_GROUPS_GET_REJECTED = 'TARGET_GROUPS_GET_FULFILLED';

export const TARGET_GROUP_POST = 'TARGET_GROUP_POST';
export const TARGET_GROUP_POST_FULFILLED = 'TARGET_GROUP_POST_FULFILLED';

export const TARGET_GROUP_PUT = 'TARGET_GROUP_PUT';
export const TARGET_GROUP_PUT_FULFILLED = 'TARGET_GROUP_PUT_FULFILLED';

export const TARGET_GROUP_DELETE = 'TARGET_GROUP_DELETE';
export const TARGET_GROUP_DELETE_FULFILLED = 'TARGET_GROUP_DELETE_FULFILLED';

