import {FC, memo, useEffect} from 'react';

import {connect} from 'react-redux';
import {compose} from 'recompose';
import {Field, reduxForm, submit} from 'redux-form';

import DialogTemplate from '../../../../components/DialogTemplate/DialogTemplate';
import FormRow from '../../../../components/FormRow/FormRow';
import {renderColorPicker, renderTextField} from '../../../../components/ReduxFormInput/ReduxFormInput';
import translations from '../../../../providers/TranslationProvider/translations';
import {IChannelTag} from '../../../../definitions/channel/channel.definitions';
import {IState} from '../../../../redux/root.reducer';
import {validateRequired} from '../../../../utils/formValidations/formValidation.utils';
import {getDialogActionsConfig} from '../../../../utils/reduxForms/reduxForm.utils';
import {TagColorPriority} from '../../../../utils/ticket/ticket.utils';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core/styles/createTheme";
import createStyles from "@material-ui/core/styles/createStyles";

interface ITagsSettingsDialogInitialValues {
    id?: number;
    name?: string;
    color_bg?: string;
}

interface ITagsSettingsDialogInnerProps {
    isOpen: boolean;
    onSubmit: (tag: IChannelTag) => void;
    handleClose: () => void;
    submit: (form: string) => void;
    initialValues: ITagsSettingsDialogInitialValues;
    tag?: IChannelTag;
    submitError?: string;
}

interface ITagsSettingsDialogOuterProps {
    isOpen: boolean;
    onSubmit: (tag: IChannelTag) => void;
    handleClose: () => void;
    tag?: IChannelTag;
    rows?: IChannelTag[];
    submitError?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorMessage: {
            color: theme.palette.error.contrastText,
        },
    }));

const TagsSettingsDialog: FC<ITagsSettingsDialogInnerProps> = props => {
    const {isOpen, submit, handleClose, initialValues, tag, submitError} = props;
    const classes = useStyles();

    const isCreate = !(initialValues && initialValues.id);
    const title = isCreate ? translations.tag_new : translations.tag_edit;

    const actionsConfig = getDialogActionsConfig({
        props: props,
        onClose: handleClose,
        onSubmit: () => {
            submit(FORM_NAME_TAGS);
        },
        isCreate,
    });

    useEffect(() => {

    }, []);

    return (
        <DialogTemplate
            open={isOpen}
            onClose={handleClose}
            ariaLabel="tag-form"
            title={title}
            closable={true}
            actionsConfig={actionsConfig}
            fullWidth
            maxWidth="xs"
            content={
            <>
                <FormRow>
                    <Field
                        name="name"
                        component={renderTextField}
                        label={translations.tag_settings_grid_row_label}
                        fullWidth
                        autoFocus
                    />
                    <Field
                        name="color_bg"
                        component={renderColorPicker}
                        stripHashAwayFromColor
                        // @ts-ignore-next-line
                        color={tag?.color_bg ? `#${tag.color_bg}` : '#fff'}
                        label={translations.color}
                        fullWidth
                        options={TagColorPriority}
                    />
                </FormRow>
                {submitError && <Typography className={classes.errorMessage}>
                    {submitError}
                </Typography>}
            </>
            }
        />
    );
};

export const FORM_NAME_TAGS = 'tagSettings';

const validate = (values: ITagsSettingsDialogInitialValues) => ({
    name: validateRequired(values.name),
    color_bg: validateRequired(values.color_bg),
});

const form = {
    form: FORM_NAME_TAGS,
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
    destroyOnUnmount: true,
    validate,
};

const mapStateToProps = (state: IState, {tag}: ITagsSettingsDialogInnerProps) => ({
    initialValues: tag,
});

const mapDispatchToProps = {
    submit,
};

export default compose<ITagsSettingsDialogInnerProps, ITagsSettingsDialogOuterProps>(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm(form),
    memo,
)(TagsSettingsDialog);
