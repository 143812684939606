import {FC} from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import {MessengerIconProps} from '../MessengerIcon';

const NotifyIcon: FC<Omit<MessengerIconProps, 'messenger'>> = props => {
    const {tooltip, ...rest} = props;

    const icon = (
        <SvgIcon {...rest} viewBox="0 0 3200 3200">
            <clipPath id="_clip1">
                <rect id="svg_1" height="3200" width="3200" y="0" x="0" />
            </clipPath>
            <g>
                <rect fill="none" id="canvas_background" height="602" width="802" y="-1" x="-1" />
            </g>
            <g>
                <rect fill="none" height="3200" width="3200" y="0" x="0" id="Logo-Notify-RGB-auf-Transparent" />
                <g stroke="null" id="svg_2" clipPath="url(#_clip1)">
                    <g stroke="null" id="svg_3">
                        <g stroke="null" id="svg_4">
                            <path
                                stroke="null"
                                id="svg_5"
                                fill="#0076ba"
                                d="m762.63282,2661.82039c-376.36032,-264.35223 -622.53327,-701.7368 -622.53327,-1196.16695c0,-806.21458 654.54304,-1460.75999 1460.75999,-1460.75999c806.21458,0 1460.75999,654.54304 1460.75999,1460.75999c0,806.21458 -654.54304,1460.75999 -1460.75999,1460.75999c-46.73706,0 -92.96522,-2.20049 -138.58602,-6.50302c-136.4911,166.60643 -343.80844,272.99643 -575.7796,272.99643c-155.10811,0 -299.19358,-47.56625 -418.45202,-128.89673c139.58484,-95.18944 245.16108,-236.63194 294.5933,-402.1921l-0.00237,0.00237z"
                            />
                            <path
                                stroke="null"
                                id="svg_6"
                                fill="#fff"
                                d="m2379.92678,1072.23367c0,-214.99119 -174.54481,-389.536 -389.53481,-389.536l-779.07199,0c-214.99,0 -389.53481,174.54481 -389.53481,389.536l0,779.07081c0,214.99 174.54481,389.536 389.53481,389.536l779.07199,0c214.99,0 389.53481,-174.546 389.53481,-389.536l0,-779.07081z"
                            />
                            <path
                                stroke="null"
                                id="svg_7"
                                fill="#0076ba"
                                d="m1445.04323,1874.69613c0,-27.09869 18.63124,-50.64101 45.00513,-56.86763c26.37389,-6.22544 53.56749,6.49828 65.68622,30.73692c89.58085,179.16051 200.9365,401.87181 200.9365,401.87181l-311.62785,0l0,-375.7411z"
                            />
                            <path
                                stroke="null"
                                id="svg_8"
                                fill="#0076ba"
                                d="m1756.67108,1048.84082c0,27.09869 -18.63124,50.64101 -45.00513,56.86763c-26.37389,6.22544 -53.56749,-6.49946 -65.68622,-30.73692c-89.58085,-179.16051 -200.9365,-401.873 -200.9365,-401.873l311.62785,0c0,0 0,201.7989 0,375.74228z"
                            />
                            <circle
                                stroke="null"
                                id="svg_9"
                                fill="#0076ba"
                                r="370.89505"
                                cy="726.29473"
                                cx="2309.70078"
                            />
                            <circle
                                stroke="null"
                                id="svg_10"
                                fill="#e44436"
                                r="253.19775"
                                cy="726.29473"
                                cx="2309.70078"
                            />
                        </g>
                        <rect
                            stroke="null"
                            id="svg_11"
                            fill="none"
                            height="3795.99967"
                            width="3795.99967"
                            y="-415.99987"
                            x="-297.14268"
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );

    return tooltip ? <Tooltip title="Notify">{icon}</Tooltip> : icon;
};

export default NotifyIcon;
