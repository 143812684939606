import {Reducer} from 'redux';

import {ICustomField, ICustomFieldState} from '../../definitions/customField/customField.definitions';
import {IEntityById} from '../../definitions/generic/generic.definitions';
import {ECustomFieldTypes} from '../../enums/customField/ECustomFieldTypes';
import {EUpdateStreamAction} from '../../enums/updateStream/EUpdateStreamAction';
import {EUpdateStreamMethod} from '../../enums/updateStream/EUpdateStreamMethod';
import {isUpdateStream} from '../../utils/redux/redux.utils';
import {UPDATE_STREAM} from '../connection/connection.types';
import {
    CUSTOM_FIELD_DELETE_FULFILLED,
    CUSTOM_FIELD_POST_FULFILLED,
    CUSTOM_FIELD_PUT_FULFILLED,
    CUSTOM_FIELDS_GET_FULFILLED,
} from './customField.types';

export const customFieldInitialState: ICustomFieldState = {
    byId: {} as IEntityById<ICustomField>,
    allIds: [],
};

// filter out type "entity", since we don't need it in frontend
const reduceCustomFieldsById = (fields: ICustomField[]) => {
    const filteredFields = fields.filter((field: ICustomField) => field.type !== ECustomFieldTypes.Entity);
    return filteredFields.reduce(
        (fields: IEntityById<ICustomField>, field: ICustomField, index) => ({
            ...fields,
            [field.id]: {
                ...field,
                is_last: index === filteredFields.length - 1,
            },
        }),
        {},
    );
};

const addOrUpdateFieldInState = (state: ICustomFieldState, field?: ICustomField, fields?: ICustomField[]) => {
    if (!field && !fields) {
        return state;
    }

    // add or update all
    const newById = reduceCustomFieldsById(fields || [...Object.values(state.byId), field]);

    return {
        ...state,
        byId: newById,
        allIds: Object.keys(newById).map(Number),
    };
};

const removeFieldFromState = (state: ICustomFieldState, id?: number) => {
    if (!id) {
        return state;
    }

    const {[id]: deleted, ...newFiltered} = state.byId;
    const newById = reduceCustomFieldsById(Object.values(newFiltered));

    return {
        ...state,
        byId: newById,
        allIds: Object.keys(newById).map(Number),
    };
};

export const customFieldReducer: Reducer<ICustomFieldState> = (state = customFieldInitialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case UPDATE_STREAM: {
            if (
                isUpdateStream(payload.message, EUpdateStreamAction.Custom, [
                    EUpdateStreamMethod.Post,
                    EUpdateStreamMethod.Put,
                ])
            ) {
                const {data, fields} = payload?.message?.payload || {};
                return addOrUpdateFieldInState(state, data, fields);
            }

            if (isUpdateStream(payload.message, EUpdateStreamAction.Custom, EUpdateStreamMethod.Delete)) {
                const {id} = payload?.message?.payload || {};
                return removeFieldFromState(state, id);
            }

            break;
        }

        case CUSTOM_FIELDS_GET_FULFILLED: {
            const payloadFields = payload.customFields || payload;
            if (!payloadFields) {
                return state;
            }

            const customFields = reduceCustomFieldsById(payloadFields);

            return {
                byId: customFields,
                allIds: Object.keys(customFields).map(Number),
            };
        }

        case CUSTOM_FIELD_POST_FULFILLED:
        case CUSTOM_FIELD_PUT_FULFILLED: {
            const {data, fields} = payload;
            return addOrUpdateFieldInState(state, data, fields);
        }

        case CUSTOM_FIELD_DELETE_FULFILLED: {
            const {id} = payload;
            return removeFieldFromState(state, id);
        }
        default:
            break;
    }
    return state;
};
