import {Reducer} from 'redux';
import {actionTypes} from 'redux-form';

import {
    FORM_UPLOADER_RESET_FILE,
    FORM_UPLOADER_UPLOAD_FILE_FULFILLED,
    FORM_UPLOADER_UPLOAD_FILE_PENDING,
    FORM_UPLOADER_UPLOAD_FILE_REJECTED,
} from './formUploader.types';

export interface IFormUploaderState {
    loading: boolean;
    error: string;
    uploadUrl: string;
}

const initialState: IFormUploaderState = {
    loading: false,
    error: '',
    uploadUrl: '',
};

export const formUploaderReducer: Reducer<IFormUploaderState> = (state = initialState, action) => {
    const {payload, type} = action;

    switch (type) {
        case FORM_UPLOADER_UPLOAD_FILE_REJECTED:
            return {...state, error: payload.message, loading: false};
        case FORM_UPLOADER_UPLOAD_FILE_PENDING:
            return {...state, error: '', uploadUrl: '', loading: true};
        case FORM_UPLOADER_UPLOAD_FILE_FULFILLED:
            return {...state, error: '', uploadUrl: payload, loading: false};
        case FORM_UPLOADER_RESET_FILE:
        case actionTypes.RESET:
            return {...state, error: '', uploadUrl: ''};
        default:
            break;
    }
    return state;
};
