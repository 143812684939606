
function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill="#007171"
                fillRule="evenodd"
                d="M32.829 6.178a1 1 0 01.653 1.254l-1.098 3.49a1 1 0 01-1.908-.6l1.099-3.49a1 1 0 011.254-.654zm7.483 2.38a1 1 0 01-.103 1.41l-4.817 4.16a1 1 0 11-1.307-1.514l4.816-4.16a1 1 0 011.41.104zM41.475 17a1 1 0 01-1 1H37a1 1 0 110-2h3.475a1 1 0 011 1z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#272727"
                fillRule="evenodd"
                d="M16.939 14a10 10 0 0117.07 7.07 9.178 9.178 0 01-3.46 7.61c-1.01.94-1.54 1.47-1.54 2.39l-.004 2-1.995-.8v-1.2c0-1.823 1.067-2.82 2.118-3.801l.062-.058a7.2 7.2 0 002.82-6.14 8 8 0 10-16 0 7.2 7.2 0 002.81 6.14c1.08.99 2.19 2.02 2.19 3.86v1.2l-1.995.8-.005-2c0-.92-.54-1.45-1.54-2.38a9.192 9.192 0 01-3.46-7.62A10 10 0 0116.939 14z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#272727"
                d="M19.005 36a1 1 0 011-1h8a1 1 0 110 2h-8a1 1 0 01-1-1zM21.008 40a1 1 0 011-1h4a1 1 0 110 2h-4a1 1 0 01-1-1z"
            ></path>
        </svg>
    );
}

export default Icon;
