import {getTokenFromStorage} from '../utils/storage/storage.utils';
import {IMAGE_SERVER_URL} from "../config";

const postUpload = (body: any) => fetch(
    `${ IMAGE_SERVER_URL }/upload.php`,
    {
        method: 'POST',
        headers: new Headers({
            Authorization: `Bearer ${getTokenFromStorage()}`,
            Accept: 'application/json',
        }),
        body,
    }
)
    .then(response => {
        // if response is not ok, we have to throw in order to trigger the ..._REJECTED action
        if (!response.ok) {
            const contentType = response.headers.get('content-type');

            // upload.php returns a json object on error with a property 'error' that we can translated and shown
            if (contentType && contentType.indexOf('application/json') !== -1) {
                return response.json().then(data => {
                    throw Error(data.error);
                });
            }

            // if for some reason the server doesn't return json, we use the HTTP response text as fallback
            throw Error(response.statusText);
        }

        return response.json().then(data => data);
    })
    .then(data => data.url);

/**
 * Upload form data
 *
 * @param formDataObject
 * @param messenger
 */
export const uploadFormDataRequest = (formDataObject: any, messenger?: string) => {
    const body = Object.keys(formDataObject).reduce((result, key) => {
        result.append(key, formDataObject[key] || '');
        return result;
    }, new FormData());

    // add 'asjson' parameter to get back a json object in the format
    body.append('asjson', '1');

    // pass the messenger to the upload script to it can check for compatibility
    if (messenger) {
        body.append('messenger', messenger);
    }

    return postUpload(body);
};

/**
 * Upload file
 *
 * @param file
 * @param channelId
 */
export const uploadFileRequest = (file: File, channelId: string | number) => {
    const body = new FormData();
    body.append('attachment', file);
    body.append('channel_id', channelId.toString());
    body.append('asjson', '1');

    return postUpload(body);
};
