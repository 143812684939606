import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ticketCounter: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            maxWidth: 270,

            '& > *': {
                margin: `0 ${theme.spacing(1)}px`,

                '&:nth-child(2)': {
                    margin: 0,
                },

                [theme.breakpoints.down('xs')]: {
                    margin: `0 ${theme.spacing(2)}px`,
                },
            },
            '& a': {
                textDecoration: 'none',
            },
        },
        label: {
            fontWeight: 400,

            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        flexGrow: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
        },
        slash: {
            marginLeft: 6,
        },
        warningIcon: {
            fontSize: 16,
            paddingTop: 4,
            marginBottom: -1,
            marginRight: 2,
        },
        hidden: {
            display: 'none',
        },
        inactive: {
            '& *': {
                color: `${theme.palette.text.disabled} !important`,
            },
        },
    }),
);
