import {IGetMiaContentParams, IGetTargetingParameters} from '../redux/mia/content/content.actions';
import {request} from './Api/Api';

const CONTENT_URL = 'content';

export const createContentRequest = (data: any) =>
    request({method: 'POST', url: CONTENT_URL}, JSON.parse(JSON.stringify(data)));
export const updateContentRequest = (data: any) =>
    request({method: 'PUT', url: CONTENT_URL}, JSON.parse(JSON.stringify(data)));
export const deleteContentRequest = (id: number) => request({method: 'DELETE', url: CONTENT_URL}, {id});
export const getContentRequest = (params: IGetMiaContentParams = {limit: 300}) =>
    request({method: 'GET', url: CONTENT_URL}, params);

export const getTargetingRequest = (params: IGetTargetingParameters) =>
    request({method: 'GET', url: 'content/targeting'}, JSON.parse(JSON.stringify(params)));

export const getTargetingCountRequest = (id: number) => request({method: 'GET', url: 'targeting/count'}, {id});
