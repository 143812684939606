import {PureComponent} from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import {connect} from 'react-redux';

import {ILinkStats} from '../../definitions/statistics/statistics.definitions';
import {IState} from '../../redux/root.reducer';
import Twemoji from '../Twemoji/Twemoji';
import LinkStats from './LinkStats';

interface ILinkButtonProps {
    classes: {
        link: string;
    };
    href: string;
    linkStats?: ILinkStats;
    showLinkStats?: boolean;
    linkStatsRefId?: number;
    message?: string;
    self?: boolean;
    icon?: JSX.Element;
    className?: string;
    stop?: boolean;
}

class LinkButton extends PureComponent<ILinkButtonProps> {
    render = () => (
        <>
            <a
                target={!this.props.self ? '_blank' : undefined}
                rel="noopener noreferrer"
                className={this.props.className ? this.props.className : this.props.classes.link}
                href={this.props.href}
                onClick={event => {
                    if (this.props.stop) {
                        event.stopPropagation();
                    }
                }}
            >
                {this.props.message?.length ? (
                    <Twemoji>{this.props.message}</Twemoji>
                ) : this.props.icon ? (
                    this.props.icon
                ) : (
                    this.props.href
                )}
            </a>
            {this.renderStats()}
        </>
    );

    renderStats = () => {
        if (!this.props.linkStats || !this.props.showLinkStats || !this.props.linkStatsRefId) {
            return null;
        }

        const linkItem = Object.values(this.props.linkStats).find(
            linkStatsItem =>
                linkStatsItem.url === this.props.href && linkStatsItem.ref_id === this.props.linkStatsRefId,
        );

        return linkItem && <LinkStats linkItem={linkItem} />;
    };
}

const styles = (theme: any) => ({
    link: {
        fontSize: theme.typography.fontSize,
        color: theme.palette.primary.main,
        textOverflow: 'ellipsis',
        overflowX: 'hidden' as any, // TS bugs out with the decorator stack
    },
});

const mapStateToProps = ({statistics: {linkStats}}: IState) => ({linkStats});

const ConnectedLinkButton = connect(mapStateToProps)(withStyles(styles)(LinkButton));

export default ConnectedLinkButton;
