import {Reducer} from 'redux';

import {IChannel, IChannelState} from '../../definitions/channel/channel.definitions';
import {ACTOR_GET_PROFILE_FULFILLED, PARTNER_LOGIN_FULFILLED} from '../actor/actor.types';
import {IEntityById} from "../../definitions/generic/generic.definitions";

export const channelInitialState: IChannelState = {
    byId: {} as IEntityById<IChannel>,
    allIds: [],
};

export const channelsReducer: Reducer<IChannelState> = (state = channelInitialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case ACTOR_GET_PROFILE_FULFILLED: {
            const {channels} = payload;
            if (!channels) {
                return state;
            }

            const newByIds = {
                ...state.byId,
                ...channels,
            };

            return {
                ...state,
                byId: newByIds,
                allIds: Object.keys(newByIds).map(Number),
            };
        }

        case PARTNER_LOGIN_FULFILLED: {
            return {
                ...state,
                byIdWithTokens: payload.channels,
            };
        }
    }

    return state;
};
