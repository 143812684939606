import {IIndexSignature} from '../definitions/generic/generic.definitions';
import {IMessengerMenuItem} from '../definitions/settings/messengerMenu/messengerMenu.definitions';
import {ITextModule, ITextModuleGetRequestParams} from '../definitions/textModule/textModule.definitions';
import {request} from './Api/Api';
import createRequestHash from '../utils/redux/utils/createRequestHash';

const BOT_URL = 'bot';
const BOT_TOPICS_URL = `${BOT_URL}/topics`;
const BOT_MENU_ITEM_URL = `${BOT_URL}/menuitem`;
const BOT_MENU_URL = `${BOT_URL}/menu`;
const BOT_CHATBLOCK_URL = `${BOT_URL}/chatblock`;

export const botTopicsForAgentsRequest = {agent_can_activate: 1};
export const botTopicsForAgentsRequestHash = createRequestHash();
export const botTopicsForAgentsRequestSelectorProps = {
    stateKey: 'botTopics',
    requestHash: botTopicsForAgentsRequestHash,
};

export const getBotTopicsRequest = (parameters?: any) =>
    request(
        {url: BOT_TOPICS_URL, method: 'GET'},
        {
            with_fields: 1,
            ...parameters,
        },
    );

export const postBotTopicRequest = (form: {activeTopic: number; customfields?: IIndexSignature}, userId: number) =>
    request(
        {url: 'chat/bot', method: 'POST'},
        {
            id: userId,
            topic_id: form.activeTopic,
            customfields: form.customfields ? JSON.stringify(form.customfields) : undefined,
        },
    );

export const getMessengerMenuItemsRequest = () => request({url: BOT_MENU_ITEM_URL, method: 'GET'});
export const postMessengerMenuItemsRequest = (item: IMessengerMenuItem) =>
    request({url: BOT_MENU_ITEM_URL, method: 'POST'}, item);
export const putMessengerMenuItemsRequest = (item: IMessengerMenuItem) =>
    request({url: BOT_MENU_ITEM_URL, method: 'PUT'}, item);
export const deleteMessengerMenuItemsRequest = (id: number) =>
    request({url: BOT_MENU_ITEM_URL, method: 'DELETE'}, {id});

export const getMessengerMenuSettingsRequest = () => request({url: BOT_MENU_URL, method: 'GET'});
export const putMessengerMenuSettingsRequest = (settings: any) => request({url: BOT_MENU_URL, method: 'PUT'}, settings);

export const getTextModulesRequest = (params?: ITextModuleGetRequestParams) =>
    request({url: BOT_CHATBLOCK_URL, method: 'GET'}, params);
export const postTextModuleRequest = (textModule: ITextModule) =>
    request({url: BOT_CHATBLOCK_URL, method: 'POST'}, textModule);
export const putTextModuleRequest = (textModule: ITextModule) =>
    request({url: BOT_CHATBLOCK_URL, method: 'PUT'}, textModule);
export const deleteTextModuleRequest = ({id}: ITextModule) =>
    request({url: BOT_CHATBLOCK_URL, method: 'DELETE'}, {id});
