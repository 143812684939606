import {storeAppleParams} from "../../pages/ImessageLandingPage/Redux/ImessageOnboardingReducer";
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Location} from 'history';
import {useEffect} from "react";

export const AppleMessageHandler = () => {
    const location = useLocation<{ from: Location<any> }>(); // we get the referrer from our AccessControl component
    const dispatch = useDispatch();

    // imessage onboarding params
    const appleId = new URLSearchParams(location.search).get('id') || new URLSearchParams(window.location.search).get('id');
    const appleMessagesName = appleId && (new URLSearchParams(location.search).get('name') || new URLSearchParams(window.location.search).get('name')); // if there is no appleId this can be skipped to boost performance

    useEffect(() => {
        if (appleId) {
            dispatch(storeAppleParams(appleId, appleMessagesName || 'Internal Account'));
        }
    }, [appleId, appleMessagesName, dispatch]);

    return null;
};

export default AppleMessageHandler;
