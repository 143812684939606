import {AnyAction, Reducer} from 'redux';

import translations from '../../providers/TranslationProvider/translations';
import {NotificationDefinitions} from '../../definitions/notification/notification.definitions';
import {ENotificationLevel} from '../../enums/notifications/ENotificationLevel';
import {
    ABSENCE_PUT_FULFILLED,
    ABSENCE_SET_FULFILLED,
    ABSENCES_LIST_DELETE_ITEM_FULFILLED,
} from '../absence/absence.types';
import {
    ACCOUNT_INFO_SET_FULFILLED,
    ACCOUNT_PURCHASE_FULFILLED,
    ACCOUNT_SET_DEFAULT_PAYMENT_SOURCE_FULFILLED,
} from '../accounting/accounting.types';
import {
    ACTOR_CHANGE_PASSWORD_FULFILLED,
    ACTOR_DELETE_FULFILLED,
    ACTOR_LOGIN_REJECTED,
    ACTOR_RESET_FULFILLED,
    ACTOR_UPDATE_FULFILLED,
    ACTOR_UPDATE_SETTINGS_FULFILLED,
} from '../actor/actor.types';
import {AGENT_ADD_FULFILLED, AGENT_UPDATE_REJECTED} from '../agents/agents.types';
import {CHANNEL_SET_FULFILLED} from '../channel/channel.types';
import {CHAT_PAGE_PUT_USER_FULFILLED} from '../chatPage/chatPage.types';
import {
    CUSTOM_FIELD_DELETE_FULFILLED,
    CUSTOM_FIELD_POST_FULFILLED,
    CUSTOM_FIELD_PUT_FULFILLED,
} from '../customField/customField.types';
import {SETTINGS_AVV_SIGN_FULFILLED} from '../settings/avv/avv.types';
import {
    SETTINGS_MESSENGER_WHATSAPP_BUSINESS_PUT_FULFILLED,
} from '../settings/messenger/messenger.types';
import {
    SETTINGS_CREATE_NOTIFICATION_TEMPLATE_FULFILLED,
    SETTINGS_DELETE_NOTIFICATION_TEMPLATE_FULFILLED,
    SETTINGS_REVIEW_NOTIFICATION_TEMPLATE_FULFILLED,
    SETTINGS_UPDATE_NOTIFICATION_TEMPLATE_FULFILLED,
} from '../settings/notificationTemplates/notificationTemplates.types';
import {STATISTICS_AGENTS_GET_REJECTED} from '../statistics/statistics.types';
import {
    TARGET_GROUP_DELETE_FULFILLED,
    TARGET_GROUP_POST_FULFILLED,
    TARGET_GROUP_PUT_FULFILLED,
} from '../targetGroup/targetGroup.types';
import {
    TEXT_MODULE_DELETE_FULFILLED,
    TEXT_MODULE_POST_FULFILLED,
    TEXT_MODULE_PUT_FULFILLED,
} from '../textModule/textModule.types';
import {NOTIFICATION_ADD, NOTIFICATION_DELETE} from './notifications.types';
import {createSnackNotification, getErrorFromPayload} from './notifications.utils';
import {FACEBOOK_PAGE_SET_FULFILLED} from '../settings/facebook/facebook.types';
import {CREATE_SHOP_CONFIG_FULFILLED, DELETE_SHOP_CONFIG_FULFILLED} from '../settings/shop/settingsShop.types';
import {CHAT_SEND_WB_NOTIFICATION_FULFILLED} from '../chat/chat.types';
import {POST_IMESSAGE_CONNECT_TO_CHANNEL_FULFILLED} from '../../pages/ImessageLandingPage/Redux/ImessageOnboardingReducer';
import {isTypeRejected} from "../utils";

const initialState: NotificationDefinitions[] = [];

const getErrorState: Reducer<NotificationDefinitions[]> = (state = initialState, action: AnyAction) => {
    const error = getErrorFromPayload(action.payload);

    if (error) {
        return [...state, createSnackNotification(translations[error] || error, ENotificationLevel.Error)];
    }

    return [
        ...state,
        createSnackNotification(
            `${translations.an_error_occurred}: [${action.type}] ${translations.an_error_occurred_info}`,
            ENotificationLevel.Error,
        ),
    ];
};

export const notificationReducer: Reducer<NotificationDefinitions[]> = (state = initialState, action) => {
    const {payload, type, meta} = action;

    // silent flag can be used to prevent notification
    if (meta?.silent === true) {
        return state;
    }

    switch (type) {
        case CHANNEL_SET_FULFILLED:
        case TARGET_GROUP_POST_FULFILLED:
        case TARGET_GROUP_PUT_FULFILLED:
        case TARGET_GROUP_DELETE_FULFILLED:
        case ABSENCE_SET_FULFILLED:
        case ABSENCE_PUT_FULFILLED:
        case ABSENCES_LIST_DELETE_ITEM_FULFILLED:
        case AGENT_ADD_FULFILLED:
        case CHAT_PAGE_PUT_USER_FULFILLED:
        case CUSTOM_FIELD_POST_FULFILLED:
        case CUSTOM_FIELD_DELETE_FULFILLED:
        case CUSTOM_FIELD_PUT_FULFILLED:
        case ACTOR_UPDATE_FULFILLED:
        case ACTOR_DELETE_FULFILLED:
        case ACTOR_UPDATE_SETTINGS_FULFILLED:
        case ACTOR_CHANGE_PASSWORD_FULFILLED:
        case SETTINGS_MESSENGER_WHATSAPP_BUSINESS_PUT_FULFILLED:
        case SETTINGS_DELETE_NOTIFICATION_TEMPLATE_FULFILLED:
        case SETTINGS_UPDATE_NOTIFICATION_TEMPLATE_FULFILLED:
        case SETTINGS_CREATE_NOTIFICATION_TEMPLATE_FULFILLED:
        case SETTINGS_REVIEW_NOTIFICATION_TEMPLATE_FULFILLED:
        case TEXT_MODULE_POST_FULFILLED:
        case TEXT_MODULE_PUT_FULFILLED:
        case TEXT_MODULE_DELETE_FULFILLED:
        case ACCOUNT_INFO_SET_FULFILLED:
        case ACTOR_RESET_FULFILLED:
        case CREATE_SHOP_CONFIG_FULFILLED:
        case POST_IMESSAGE_CONNECT_TO_CHANNEL_FULFILLED:
        case ACCOUNT_SET_DEFAULT_PAYMENT_SOURCE_FULFILLED:
        case ACCOUNT_PURCHASE_FULFILLED:
            return [...state, createSnackNotification(translations.save_operation_success, ENotificationLevel.Success)];

        case DELETE_SHOP_CONFIG_FULFILLED:
            return [...state, createSnackNotification(translations.delete_operation_success, ENotificationLevel.Success)];

        case FACEBOOK_PAGE_SET_FULFILLED: {
            if (action.payload.facebook_error) {
                return state;
            }
            return [...state, createSnackNotification(translations.save_operation_success, ENotificationLevel.Success)];
        }

        case SETTINGS_AVV_SIGN_FULFILLED:
            return [...state, createSnackNotification(translations.avv_sign_success, ENotificationLevel.Success)];

        case ACTOR_LOGIN_REJECTED: {
            return [...state, createSnackNotification(payload.response.statusText, ENotificationLevel.Error, true)];
        }

        case AGENT_UPDATE_REJECTED: {
            return [...state, createSnackNotification(payload.statusText, ENotificationLevel.Error)];
        }

        case STATISTICS_AGENTS_GET_REJECTED:
            if (action.payload.status === 401) {
                return state;
            }

            // TODO combine with logic below (default case) and build for global, not only for STATISTICS_AGENTS_GET_REJECTED
            return getErrorState(state, action);

        case NOTIFICATION_ADD: {
            return [...state, {...payload}];
        }

        case NOTIFICATION_DELETE:
            return state.filter(item => item.id !== action.payload);

        case CHAT_SEND_WB_NOTIFICATION_FULFILLED: {
            const {success_ids, failed_ids} = action.payload;

            const sum = (success_ids?.length || 0) + (failed_ids?.length || 0);

            if (failed_ids?.length) {
                const replaced = translations.notification_not_send_to_user.replace('%s', failed_ids.length);
                return [...state, createSnackNotification(replaced.replace('%b', sum), ENotificationLevel.Error)];
            }

            const replaced = translations.notification_send_to_user.replace('%s', sum);

            return [...state, createSnackNotification(replaced.replace('%b', sum), ENotificationLevel.Success)];
        }

        default:
            if (isTypeRejected(action.type)) {
                console.error(action.payload);
                const error = getErrorFromPayload(action.payload);

                if (error) {
                    return [...state, createSnackNotification(translations[error] || error, ENotificationLevel.Error)];
                }

                // skip empty errors (dont show default error)
                if (error !== '') {
                    return [
                        ...state,
                        createSnackNotification(
                            `${translations.an_error_occurred}: [${action.type}] ${translations.an_error_occurred_info}`,
                            ENotificationLevel.Error,
                        ),
                    ];
                }
            }

            return state;
    }
};
