import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import withStyles, {WithStyles} from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import {ChromePicker, ColorResult} from 'react-color';
import {v4 as uuid} from 'uuid';
import {compose} from 'recompose';
import withTheme from '@material-ui/core/styles/withTheme';
import {Theme} from '@material-ui/core/styles/createTheme';
import {Check, Close} from '@material-ui/icons';
import OverflowEllipsisTooltip from '../../OverflowEllipsisTooltip/OverflowEllipsisTooltip';
import Box from '@material-ui/core/Box';
import translations from '../../../providers/TranslationProvider/translations';
import {Component} from 'react';

interface ICustomColorPickerOuterProps {
    label: string;
    color?: string;
    onChangeCompleted: (color?: string) => void;
}

interface ICustomColorPickerInnerProps extends WithStyles<typeof styles>, ICustomColorPickerOuterProps {
    theme: Theme;
}

class CustomColorPicker extends Component<ICustomColorPickerInnerProps> {
    state = {
        anchorEl: null,
        color: this.props.color,
        id: uuid(),
    };

    componentDidUpdate(prevProps: ICustomColorPickerInnerProps) {
        if (prevProps.color !== this.props.color) {
            this.setState({
                color: this.props.color,
            });
        }
    }

    handleClick = (event: MouseEvent) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleOnChangeComplete = (color: ColorResult) => {
        this.setState({color: color.hex}, () => this.props.onChangeCompleted(color.hex));
    };

    render() {
        const {anchorEl, id, color} = this.state;
        const open = Boolean(this.state.anchorEl);

        const style = {
            backgroundColor: color,
            color: color && this.props.theme.palette.getContrastText(color),
        };

        return (
            <div>
                <Button
                    variant='outlined'
                    fullWidth
                    aria-owns={anchorEl ? id : undefined}
                    aria-haspopup='true'
                    onClick={this.handleClick as any}
                    color={'default'}
                    className={this.props.classes.button}
                    style={style}
                >
                    <Typography color='inherit' variant='caption'>
                        <OverflowEllipsisTooltip>
                            {this.props.label}
                        </OverflowEllipsisTooltip>
                    </Typography>
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    style={{
                        marginTop: 4,
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <ChromePicker
                        color={color}
                        onChangeComplete={this.handleOnChangeComplete}
                        disableAlpha
                        className={this.props.classes.colorPicker}
                    />
                    {this.props.color && (
                        <Box p={1} textAlign={'right'}>
                            <Button
                                size={'small'}
                                onClick={() => this.props.onChangeCompleted(undefined)}
                                startIcon={<Close fontSize={'small'} />}
                            >
                                {translations.reset}
                            </Button>
                            <Button
                                size={'small'}
                                onClick={this.handleClose}
                                startIcon={<Check fontSize={'small'} />}
                            >
                                {translations.ok}
                            </Button>
                        </Box>
                    )}
                </Popover>
            </div>
        );
    }
}

const styles = () => ({
    colorPicker: {
        boxShadow: 'none !important',
    },
    button: {
        paddingLeft: 6,
        paddingRight: 6,
        height: 53,
    },
});

export default compose<ICustomColorPickerInnerProps, ICustomColorPickerOuterProps>(
    withStyles(styles),
    withTheme,
)(CustomColorPicker);
