import memoize from 'memoize-one';

import translations from '../../providers/TranslationProvider/translations';
import {reduceObjectToKeys} from '../global/global.utils';

export interface IDialogActionsConfigParameter {
    props: any;
    onClose: () => void;
    onSubmit: () => void;
    isCreate?: boolean;
    submitLabel?: string;
    submittingLabel?: string;
    closeLabel?: string;
    appendActions?: any[];
    prependActions?: any[];
    insertActions?: any[];
    disabled?: boolean;
}

export const getDialogActionsConfig = ({
    props: {pristine, submitting, valid, dirty},
    onClose,
    onSubmit,
    isCreate,
    submitLabel,
    submittingLabel,
    closeLabel,
    appendActions = [],
    prependActions = [],
    insertActions = [],
    disabled = false,
}: IDialogActionsConfigParameter) => {
    const submitLabelText = submitting
        ? submittingLabel || translations.submitting
        : submitLabel || (isCreate ? translations.create : translations.save);

    return [
        ...prependActions,
        {
            label: closeLabel || translations.cancel,
            onClick: onClose,
            disabled: submitting,
        },
        ...insertActions,
        {
            label: submitLabelText,
            onClick: onSubmit,
            disabled: submitting || pristine || !valid || !dirty || disabled,
            variant: 'contained',
            color: 'primary',
        },
        ...appendActions,
    ];
};

export const filteredFormData = memoize((object, fields) => reduceObjectToKeys(object, fields));
