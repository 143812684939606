import {IEntityByKey} from '../../../definitions/generic/generic.definitions';

/**
 * Build filter query string
 * @param parameters
 * @returns {string}
 */
export const buildSortedFilterString = (parameters: IEntityByKey<any> = {}) => {
    // sort by keys so the hash will always be the same, even if order of keys change
    const sortedKeys = Object.keys(parameters).sort((a, b) => {
        a = a.toLowerCase();
        b = b.toLowerCase();
        return a > b ? 1 : b > a ? -1 : 0;
    });

    // build query string
    let requestString = sortedKeys.reduce((string, key) => {
        return `${string}${encodeURIComponent(key)}=${encodeURIComponent(parameters[key])}&`;
    }, '');

    // return empty string if nothing defined
    if (!requestString) {
        return '';
    }

    // cut off last "&"
    requestString = requestString.slice(0, -1);

    return `?${requestString}`;
};
