
function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill="#007171"
                fillRule="evenodd"
                d="M39.542 37l-10.24-9.986 1.396-1.431L41 35.627v-6.277a1 1 0 112 0v8.65a1 1 0 01-1 1h-9a1 1 0 110-2h6.542z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#272727"
                fillRule="evenodd"
                d="M32 9c0-.56.462-1 1-1h9.026c.538 0 .974.453.974 1.013l-.026 8.974c0 .56-.436 1.013-.974 1.013s-.974-.453-.974-1.013L41 11.5 28.116 25.685a.957.957 0 01-.706.315H5.974C5.436 26 5 25.547 5 24.987c0-.56.444-.987.982-.987H27l12.5-14H33c-.538 0-1-.44-1-1z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Icon;
