import {removeDeviceIdFromStorage} from "@sinch-engage/mcp-update-stream";

export const LOCAL_STORAGE_TOKEN_KEY = 'token';
export const LOCAL_STORAGE_LAST_ACTION_KEY = 'lastAction';
export const LOCAL_STORAGE_LANGUAGE_KEY = 'language';
export const LOCAL_STORAGE_SSO_KEY = 'sso';

// language
export const getLanguageFromStorage = () => global.localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY);
export const updateLanguageInStorage = (langauge: string) =>
    global.localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, langauge);

// idle timer
export const getTimeOfLastAction = () => Number(localStorage.getItem(LOCAL_STORAGE_LAST_ACTION_KEY));
export const resetTimeOfLastAction = () => localStorage.removeItem(LOCAL_STORAGE_LAST_ACTION_KEY);
export const updateTimeOfLastAction = () => localStorage.setItem(LOCAL_STORAGE_LAST_ACTION_KEY, Date.now().toString());

// token
export const getTokenFromStorage = () => global.localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || '';
export const removeTokenFromStorage = () => global.localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
export const updateTokenInStorage = (token?: string) => !token ?
    removeTokenFromStorage() : global.localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);

// sso
export const enableSSOInStorage = () => localStorage.removeItem(LOCAL_STORAGE_SSO_KEY);
export const disableSSOInStorage = () => localStorage.setItem(LOCAL_STORAGE_SSO_KEY, 'disabled');
export const isSSOEnabledInStorage = () => localStorage.getItem(LOCAL_STORAGE_SSO_KEY) !== 'disabled';

// clear session
export const clearSessionInStorage = () => {
    resetTimeOfLastAction();
    removeTokenFromStorage();
    removeDeviceIdFromStorage();
};
