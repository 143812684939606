import {ITicketTableColumn, IUserTableColumn} from '../../definitions/actor/actor.definitions';
import {ETicketTableColumns} from '../../enums/ui/ETicketTableColumns';
import {EUserTableColumns} from '../../enums/ui/EUserTableColumns';

export const defaultTicketTableConfig: ITicketTableColumn[] = [
    {
        type: ETicketTableColumns.MESSENGER,
        position: 1,
    },
    {
        type: ETicketTableColumns.USERNAME,
        position: 2,
    },
    {
        type: ETicketTableColumns.WAITING_SINCE,
        position: 3,
    },
    {
        type: ETicketTableColumns.CLOSED_SINCE,
        position: 4,
    },
    {
        type: ETicketTableColumns.TICKET_ID,
        position: 5,
    },
    {
        type: ETicketTableColumns.AGENT,
        position: 6,
    },
    {
        type: ETicketTableColumns.TAGS,
        position: 7,
    },
    {
        type: ETicketTableColumns.MOOD,
        position: 8,
    },
    {
        type: ETicketTableColumns.LAST_CHAT,
        position: 9,
    },
    {
        type: ETicketTableColumns.POSTPONED,
        position: 10,
    },
    {
        type: ETicketTableColumns.LAST_CHAT_DATE,
        position: 11,
    },
    {
        type: ETicketTableColumns.PREVIEW,
        position: 12,
    },
];

export const defaultUserTableConfig: IUserTableColumn[] = [
    {
        type: EUserTableColumns.NUMBER,
        position: 1,
    },
    {
        type: EUserTableColumns.MESSENGER,
        position: 2,
    },
    {
        type: EUserTableColumns.FIRST_CONTACT,
        position: 3,
    },
    {
        type: EUserTableColumns.LAST_CHAT,
        position: 4,
    },
    {
        type: EUserTableColumns.IS_BLOCKED,
        position: 5,
    },
    {
        type: EUserTableColumns.NAME,
        position: 6,
    },
    {
        type: EUserTableColumns.STATUS,
        position: 7,
    },
];
