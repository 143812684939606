import {createSelector} from 'reselect';

import translations from '../../providers/TranslationProvider/translations';
import {EPaymentMethods} from '../../enums/payment/EPaymentMethods';
import {IState} from '../root.reducer';
import {TPaymentMethodName} from "../../definitions/accounting/accounting.definitions";

export const translatePaymentMethodName = (paymentMethod?: EPaymentMethods | TPaymentMethodName | null) => {
    switch (paymentMethod?.toLowerCase()) {
        case 'lastschrift':
        case 'sepa_debit':
            return translations.sepa_debit;
        case 'creditcard':
        case 'card':
            return translations.credit_card;
        case 'paypal':
            return translations.paypal;
        case 'boleto':
            return translations.boleto;
        case 'rechnung':
        case undefined:
        case null:
            return translations.wire_transfer;
        default:
            return paymentMethod;
    }
};

export const getTranslatedPaymentMethod = createSelector(
    [(state: IState) => state.accounting.payment_method],
    translatePaymentMethodName,
);

export const isLoadingLanguage = (state: IState) =>
    Boolean(state.translations.languageLoading && state.translations.languageLoaded !== state.translations.languageLoading);
