import {BASE_URL} from '../config';
import {
    ICustomChartRequest,
    IPeriod,
    IPeriodData,
    IPeriodDataWithDetails,
    IStatisticRequestParameters,
    IStatisticsAgentsRequestParams,
    IStatisticsMiaUsersRequestParams,
    TMauStatisticsRequest,
} from '../definitions/statistics/statistics.definitions';
import {request} from './Api/Api';
import {downloadRequest} from "./downloadRequest";

const STATISTICS_URL = 'statistics';
const STATISTICS_CONTENT_URL = `${STATISTICS_URL}/content`;
const STATISTICS_CHATS_URL = `${STATISTICS_URL}/chats`;
const STATISTICS_KPI_URL = `${STATISTICS_URL}/kpi_rules`;
const STATISTICS_TICKETS_URL = `${STATISTICS_URL}/tickets`;
const STATISTICS_BOT_URL = `${STATISTICS_URL}/bot`;
const STATISTICS_MAU_URL = `${STATISTICS_URL}/mau`;
const STATISTICS_CUSTOM_URL = `${STATISTICS_URL}/custom`;
const STATISTICS_AGENT_URL = `${STATISTICS_URL}/agents`;
const STATISTICS_AGENT_BY_ID_URL = `${STATISTICS_URL}/agent`;
const STATISTICS_NOTIFICATIONS_URL = `${STATISTICS_URL}/notifications`;
const STATISTICS_LABELS_URL = `${STATISTICS_URL}/labels`;
const STATISTICS_AUTOMATION_URL = `${STATISTICS_URL}/automation`;
const STATISTICS_CLICKS_URL = `${STATISTICS_URL}/clicks`;
const STATISTICS_USER_URL = `stats/user`;
const STATISTICS_CHATBLOCKS_URL = `${STATISTICS_URL}/chat_blocks`;
const STATISTICS_SIMPLE_CHAT = `${STATISTICS_URL}/simple_chat`;
const STATISTICS_WHATSAPP_CONVERSATIONS = `${STATISTICS_URL}/whatsapp_conversations`;
const STATISTICS_CONVERSATIONS = `${STATISTICS_URL}/conversations`;

export const getContentStatisticsRequest = (params: any) =>
    request({method: 'GET', url: STATISTICS_CONTENT_URL}, params);

export const getStatisticsChatsRequest = (params: IPeriodData) =>
    request({method: 'GET', url: STATISTICS_CHATS_URL}, params);

export const getStatisticsBlacklistRequest = () => request({method: 'GET', url: STATISTICS_KPI_URL});

export const getStatisticsTicketsRequest = (params: IPeriodData) =>
    request({method: 'GET', url: STATISTICS_TICKETS_URL}, params);

export const getStatisticsChatbotsRequest = (params: IPeriodDataWithDetails) =>
    request({method: 'GET', url: STATISTICS_BOT_URL}, params);

export const getStatisticsMiaRequest = (params: TMauStatisticsRequest) =>
    request({method: 'GET', url: STATISTICS_MAU_URL}, params);

export const getStatisticsCustomChartRequest = (params: ICustomChartRequest) =>
    request({method: 'GET', url: STATISTICS_CUSTOM_URL}, params);

export const downloadStatsUrlRequest = (pathname: string, requestData: any) => {
    const params = new URLSearchParams(requestData);

    return downloadRequest(`${BASE_URL}/${pathname}?output_format=csv&${params.toString()}`);
};

export const downloadStatisticsCustomChartRequest = (requestData: ICustomChartRequest) => {
    return downloadStatsUrlRequest(STATISTICS_CUSTOM_URL, requestData);
};

export const downloadStatisticsMessagesRequest = (requestData: IStatisticRequestParameters) => {
    return downloadStatsUrlRequest(STATISTICS_CHATS_URL, {...requestData, source: 'egal'});
};

export const downloadStatisticsBotDetailsRequest = (requestData: IStatisticRequestParameters) => {
    return downloadStatsUrlRequest(STATISTICS_BOT_URL, requestData);
};

export const downloadStatisticsAutomationRequest = (requestData: IStatisticRequestParameters) => {
    return downloadStatsUrlRequest(STATISTICS_AUTOMATION_URL, requestData);
};

export const downloadStatisticsTicketsRequest = (requestData: IStatisticRequestParameters) => {
    return downloadStatsUrlRequest(STATISTICS_TICKETS_URL, requestData);
};

export const downloadStatisticsNotificationsRequest = (requestData: IStatisticRequestParameters) => {
    return downloadStatsUrlRequest(STATISTICS_NOTIFICATIONS_URL, requestData);
};

export const downloadStatisticsClicksRequest = (requestData: IStatisticRequestParameters) => {
    return downloadStatsUrlRequest(STATISTICS_CLICKS_URL, {source: 'content', ...requestData});
};

export const downloadStatisticsLabelsRequest = (requestData: IStatisticRequestParameters) => {
    return downloadStatsUrlRequest(STATISTICS_LABELS_URL, requestData);
};

export const downloadStatisticsAgentsRequest = (requestData: IStatisticRequestParameters) => {
    return downloadStatsUrlRequest(STATISTICS_AGENT_URL, requestData);
};

export const downloadStatisticsChatblocksRequest = (requestData: IStatisticRequestParameters) => {
    return downloadStatsUrlRequest(STATISTICS_CHATBLOCKS_URL, requestData);
};

export const downloadStatisticsMiaRequest = (requestData: IStatisticRequestParameters) => {
    return downloadStatsUrlRequest(STATISTICS_USER_URL, requestData);
};

export const downloadStatisticsMauRequest = (params: TMauStatisticsRequest) => {
    return downloadStatsUrlRequest(STATISTICS_MAU_URL, params);
};

export const getStatisticsAgentsRequest = (params: IPeriod) =>
    request({method: 'GET', url: STATISTICS_AGENT_URL}, params);

export const getAgentStatisticByIdRequest = (params: IStatisticsAgentsRequestParams) => {
    const {agent_id, ...rest} = params;
    const url = `${STATISTICS_AGENT_BY_ID_URL}/${agent_id}`;
    return request({method: 'GET', url}, rest);
};

export const getStatisticsMiaUsersRequest = (params: IStatisticsMiaUsersRequestParams) =>
    request({method: 'GET', url: STATISTICS_USER_URL}, params);

export const getStatisticsNotificationsRequest = (params: IPeriod) =>
    request({method: 'GET', url: STATISTICS_NOTIFICATIONS_URL}, params);

export const getStatisticsLabelsRequest = (params: IStatisticRequestParameters) =>
    request({method: 'GET', url: STATISTICS_LABELS_URL}, params);

export const getStatisticsAutomationRequest = (params: IPeriodData) =>
    request({method: 'GET', url: STATISTICS_AUTOMATION_URL}, params);

export const getStatisticsClicksRequest = (params: IPeriod) =>
    request({method: 'GET', url: STATISTICS_CLICKS_URL}, {source: 'content', ...params});

export const getStatisticsChatblocksRequest = (params: IPeriod) =>
    request({method: 'GET', url: STATISTICS_CHATBLOCKS_URL}, params);

export const getSimpleChatStatisticsRequest = () =>
    request({method: 'GET', url: STATISTICS_SIMPLE_CHAT});

export const fetchOldTicketStats = (performance: boolean) => request({
    method: 'GET',
    url: 'stats/ticket',
}, performance ? {current: 1} : {});

export const getWhatsAppConversationStatisticsRequest = (params?: IPeriod) =>
    request({method: 'GET', url: STATISTICS_WHATSAPP_CONVERSATIONS}, params);

export const getConversationStatisticsRequest = (params?: IPeriod) =>
    request({method: 'GET', url: STATISTICS_CONVERSATIONS}, {interval: 'months', ...params});
