import React, {FC, ReactNode} from 'react';

import {Theme} from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

interface IFormSectionProps {
    children: ReactNode;
    headline?: string;
}

const FormSection: FC<IFormSectionProps> = props => {
    const {children, headline} = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {headline && <Typography className={classes.headline} variant='body1' children={headline} gutterBottom />}
            {children}
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        margin: `${theme.spacing(2)}px 0`,

        '&:first-of-type': {
            marginTop: 0,
        },
        '&:last-of-type': {
            marginBottom: 0,
        },
    },
    headline: {
        fontWeight: 500,
        margin: `${theme.spacing(1)}px 0`,

        '&:first-child': {
            marginTop: 0,
        },
    },
}));

export default FormSection;
