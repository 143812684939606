// All icons (custom and MUI) for this project
export enum EIcons {
    BOT_BLOCK_JET = 'BOT_BLOCK_JET',
    BOT_BLOCK_WITH_ARROW_JET = 'BOT_BLOCK_WITH_ARROW_JET',
    ADD_TO_CHAT = 'ADD_TO_CHAT',
    CANCEL = 'CANCEL',
    CARD_BOTS = 'CARD_BOTS',
    CARD_HISTORY = 'CARD_HISTORY',
    CARD_NOTES = 'CARD_NOTES',
    CARD_PERSON = 'CARD_PERSON',
    CARD_CHAT_BLOCKS = 'CARD_CHAT_BLOCKS',
    DELETE = 'DELETE',
    FILE_IMAGE = 'FILE_IMAGE',
    FILE_AUDIO = 'FILE_AUDIO',
    FILE_VIDEO = 'FILE_VIDEO',
    FILE_DOCUMENT = 'FILE_DOCUMENT',
    FILE = 'FILE',
    QUEUE = 'QUEUE',
    CHAT_BLOCK = 'CHAT_BLOCK',
    VISIBILITY = 'VISIBILITY',
    UNDO = 'UNDO',
    TRASH = 'TRASH',
    SCHEDULE = 'SCHEDULE',
    TAKEBACK = 'TAKEBACK',
    SETTINGS = 'SETTINGS',
    SEND = 'SEND',
    SEARCH = 'SEARCH',
    SAVE_ACCEPTED = 'SAVE_ACCEPTED',
    ROUTING = 'ROUTING',
    RELAY = 'RELAY',
    LOCK_OPEN = 'LOCK_OPEN',
    LOCK_CLOSED = 'LOCK_CLOSED',
    INFO = 'INFO',
    HELP = 'HELP',
    SMILEY = 'SMILEY',
    DETAILS = 'DETAILS',
    DASHBOARD = 'DASHBOARD',
    CHAT = 'CHAT',
    BOT = 'BOT',
    ANALYTICS = 'ANALYTICS',
    ADD = 'ADD',
    VERTICAL_POINTS = 'VERTICAL_POINTS',
    CHEVRON_RIGHT = 'CHEVRON_RIGHT',
    NAVIGATE_BEFORE = 'NAVIGATE_BEFORE',
    ARROW_RIGHT = 'ARROW_RIGHT',
    MENU = 'MENU',
    REFRESH = 'REFRESH',
    OFFLINE = 'OFFLINE',
    PERSON = 'PERSON',
    EXIT = 'EXIT',
    USER = 'USER',
    LOCATION = 'LOCATION',
    EXPAND_MORE = 'EXPAND_MORE',
    SORT = 'SORT',
    FILTER = 'FILTER',
    REPLY = 'REPLY',
    RELEASES = 'RELEASES',
    LIST = 'LIST',
    ATTACHMENT = 'ATTACHMENT',
    PAYMENT = 'PAYMENT',
    SWITCHEROO = 'SWITCHEROO',
    CHECKMARK = 'CHECKMARK',
    DOWNLOAD = 'DOWNLOAD',
    DOWNLOAD_CLOUD = 'DOWNLOAD_CLOUD',
    UPLOAD = 'UPLOAD',
    CONTACT = 'CONTACT',
    SCROLL_TO_BOTTOM = 'SCROLL_TO_BOTTOM',
    QUESTION_ANSWER = 'QUESTION_ANSWER',
    MIA = 'MIA',
    SMILEY_NEUTRAL = 'SMILEY_NEUTRAL',
    SMILEY_HAPPY = 'SMILEY_HAPPY',
    SMILEY_VERY_HAPPY = 'SMILEY_VERY_HAPPY',
    SMILEY_SAD = 'SMILEY_SAD',
    SMILEY_VERY_SAD = 'SMILEY_VERY_SAD',
    CREATE = 'CREATE',
    NOTIFY = 'NOTIFY',
    TRENDING_UP = 'TRENDING_UP',
    TRENDING_DOWN = 'TRENDING_DOWN',
    OPEN_IN_NEW = 'OPEN_IN_NEW',
    ADD_CHATBLOCK = 'ADD_CHATBLOCK',
    ADD_CHATBOT = 'ADD_CHATBOT',
    TWO_FACTOR_AUTH = 'TWO_FACTOR_AUTH',
    LOCAL_OFFER = 'LOCAL_OFFER',
    SMARTPHONE = 'SMARTPHONE',
    VISIBILITY_OFF = 'VISIBILITY_OFF',
    EDIT = 'EDIT',
    ASSIGNMENT = 'ASSIGNMENT',
}
