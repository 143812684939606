import {FC, ReactNode, useCallback} from 'react';

import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import classNames from 'classnames';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';

import {changeDrawerState, hideContent, showMenu} from '../../../../../../redux/ui/ui.actions';
import {DrawerChildProps} from '../../../Drawer';
import {DrawerMenuItem} from '../../DrawerMenuItem';

type BadgeStyles = {
    position: any;
    top: number;
    right: number;
    backgroundColor: string;
    color: string;
    borderRadius: number;
    padding: string;
    fontSize: number;
    transition: string;
    zIndex: number;
    fontWeight: number;
    lineHeight: string;
}

type BadgeSmallStyles = {
    top: number;
    right: number;
    padding: number;
    fontSize: 0,
}

export const getBadgeStyles = (theme: Theme): BadgeStyles => ({
    position: 'absolute',
    top: 16,
    right: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 90,
    padding: `4px ${theme.spacing(1)}px`,
    fontSize: 9,
    fontWeight: 800,
    transition: 'all 100ms ease-in-out',
    zIndex: 1,
    lineHeight: '1.25',
});

export const getBadgeSmall = (theme: Theme): BadgeSmallStyles => ({
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: theme.spacing(0.5),
    fontSize: 0,
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        link: {
            position: 'relative',
            display: 'block',
        },
        badge: {
            ...getBadgeStyles(theme),
        },
        badgeSmall: {
            ...getBadgeSmall(theme),
        },
    }),
);

interface IDrawerMenuItemProps extends DrawerChildProps {
    path: string;
    name: string;
    icon: ReactNode;
    highlight: boolean;
    badge?: string;
}

export const MainMenuItem: FC<IDrawerMenuItemProps> = props => {
    const {path, icon, name, highlight, badge, isDrawerOpen} = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));
    const hasBadge = Boolean(badge && parseInt(badge));
    const showBadge = isDrawerOpen && hasBadge;

    const handleClick = useCallback(() => {
        dispatch(changeDrawerState(false));
        if (isSmallDevice) {
            dispatch(hideContent());
            dispatch(showMenu());
        }
    }, [dispatch, isSmallDevice]);

    return (
        <Link className={classes.link} to={path} onClick={handleClick}>
            <DrawerMenuItem icon={icon} name={name} highlight={highlight} />
            {hasBadge && (
                <span className={classNames(classes.badge, !showBadge && classes.badgeSmall)}>{badge}</span>
            )}
        </Link>
    );
};
