import React, {FC, useEffect, useRef, useState} from 'react';

import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip, {TooltipProps} from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import Twemoji from "../Twemoji/Twemoji";
import {Omit} from "@material-ui/core";

const useStyles = makeStyles(() =>
    createStyles({
        element: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    }),
);

interface OverflowEllipsisTooltipProps extends Omit<TooltipProps, 'children' | 'title'> {
    children: string;
    className?: string;
    twemoji?: boolean;
    cutoff?: number;
}

const OverflowEllipsisTooltip: FC<OverflowEllipsisTooltipProps> = (
    {
        children,
        className,
        twemoji,
        cutoff,
        interactive= true,
        ...tooltipProps
    }
) => {
    const textElementRef = useRef<HTMLDivElement>(null);
    const [disableHoverListener, setDisableHoverListener] = useState(false);
    const classes = useStyles();

    // cut off text if necessary
    const text = (!cutoff || children.length <= cutoff ? children : `${children.substring(0, cutoff + 1)} [...]`) || '';

    useEffect(() => {
        const checkEllipsis = () => {
            if (!textElementRef.current) {
                return;
            }
            const isEllipsis = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
            setDisableHoverListener(isEllipsis);
        };

        window.addEventListener('resize', checkEllipsis);
        checkEllipsis();

        return () => window.removeEventListener('resize', checkEllipsis);
    }, []);

    return (
        <Tooltip title={text} interactive={interactive} disableHoverListener={!disableHoverListener} {...tooltipProps}>
            <div
                ref={textElementRef}
                className={classNames(classes.element, className)}
                children={twemoji ? <Twemoji>{text}</Twemoji> : text}
            />
        </Tooltip>
    );
};

export default OverflowEllipsisTooltip;
