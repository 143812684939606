import {FC, Ref} from 'react';


import Select, {ActionMeta, OptionsType, ValueType} from 'react-select';

import {useStyles} from './CustomAutoSelect.styles';
import components from './CustomAutoSelectBase';

interface IOptionProps {
    value: string;
    label: string;
}

interface ICustomAutoSelectBaseProps {
    isMulti: boolean;
    label: string;
    value: any;
    onChange: (value: any) => void;
    apiParse?: (value: any) => void;
    inputRef?: Ref<any>;
}

interface ICustomAutoSelectProps extends ICustomAutoSelectBaseProps {
    options: OptionsType<IOptionProps>;
    commaSeparated?: false;
}

interface ICustomAutoSelectCommaSeparatedProps extends ICustomAutoSelectBaseProps {
    options: string | OptionsType<IOptionProps>;
    commaSeparated: true;
}

type OnChangeType = (value: ValueType<IOptionProps, boolean>, actionMeta: ActionMeta<IOptionProps>) => void;

const CustomAutoSelect: FC<ICustomAutoSelectProps | ICustomAutoSelectCommaSeparatedProps> = props => {
    const {commaSeparated, isMulti, options, label, value, onChange, apiParse, inputRef} = props;
    const classes = useStyles();

    const handleChange: OnChangeType = curValue => {
        if (!commaSeparated) {
            onChange(value);
        } else if (Array.isArray(curValue)) {
            onChange(curValue.map((mapValue: IOptionProps) => mapValue.value).join(','));
        } else {
            console.error('Unsupported onchange value.');
            onChange('');
        }
    };

    const parseOptions = (curValue: string | null | undefined): IOptionProps[] => {
        const splitValues = curValue ? curValue.split(',') : [];
        return splitValues.length ? splitValues.map((value: string) => ({value, label: value})) : [];
    };

    const parseValueToArray = (curValue: any) => {
        if (commaSeparated) {
            return parseOptions(curValue);
        }

        if (apiParse) {
            return apiParse(curValue);
        }

        return curValue;
    };

    const optionsArray = commaSeparated && !options ? [] : typeof options === 'string' ? parseOptions(options) : options;
    const valuesArray = parseValueToArray(value);

    return (
        <Select
            ref={inputRef}
            className={classes.root}
            isMulti={Boolean(isMulti)}
            defaultValue={valuesArray}
            label={label}
            options={optionsArray}
            classes={classes}
            components={components}
            value={valuesArray}
            onChange={handleChange}
        />
    );
};

CustomAutoSelect.displayName = 'CustomAutoSelect';

export default CustomAutoSelect;
