import {FC} from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';

import translations from '../../../../providers/TranslationProvider/translations';
import {EBaseRoutes} from '../../../../enums/routes/ERoutes';
import {setFilter} from '../../../../redux/ticketPage/ticketPage.actions';
import {defaultFilterState, TicketFilterConfig} from '../../../../components/TicketTable/Filter/filter.constants';
import PendingCounter from './PendingCounter';
import {useStyles} from './TicketCounter.styles';

interface ITicketCounterProps {
    ticketStats: {
        answered: number;
        assigned: number;
        critical: number;
        pending: number;
        overdue: number;
    };
    isMobile: boolean;
    agentId: number;
    inactive?: boolean;
    mostCriticalTicketId?: number;
}

const TicketCounter: FC<ITicketCounterProps> = props => {
    const {isMobile, mostCriticalTicketId, ticketStats, inactive = false, agentId} = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleClick = (ticketFilter: string) => {
        dispatch(setFilter({...defaultFilterState, tickets: ticketFilter, selectedAgent: agentId}));
    };

    if (isMobile) {
        return (
            <div className={classNames(classes.ticketCounter, {[classes.inactive]: inactive})}>
                <Link to={EBaseRoutes.TicketList} onClick={() => handleClick(TicketFilterConfig.OPEN.filterKey)}>
                    <Box color="info.main">
                        <Typography variant="body2" children={ticketStats.assigned} />
                    </Box>
                </Link>
                <Link to={EBaseRoutes.TicketList} onClick={() => handleClick(TicketFilterConfig.ANSWERED.filterKey)}>
                    <Box color="success.main">
                        <Typography variant="body2" children={ticketStats.answered} />
                    </Box>
                </Link>
                <PendingCounter ticketStats={ticketStats} mostCriticalTicketId={mostCriticalTicketId} />
            </div>
        );
    }

    return (
        <Grid item sm={2} md={4} lg={3}>
            <div className={classNames(classes.ticketCounter, {[classes.inactive]: inactive})}>
                <div>
                    <Typography
                        className={classes.label}
                        variant="body2"
                        children={translations.active}
                        color="textPrimary"
                    />
                    <Link to={EBaseRoutes.TicketList} onClick={() => handleClick(TicketFilterConfig.OPEN.filterKey)}>
                        <Box color="info.main">
                            <Typography variant="body2" children={ticketStats.assigned} />
                        </Box>
                    </Link>
                </div>
                <div>
                    <Typography
                        className={classes.label}
                        variant="body2"
                        children={translations.answered}
                        color="textPrimary"
                    />
                    <Link
                        to={EBaseRoutes.TicketList}
                        onClick={() => handleClick(TicketFilterConfig.ANSWERED.filterKey)}
                    >
                        <Box color="success.main">
                            <Typography variant="body2" children={ticketStats.answered} />
                        </Box>
                    </Link>
                </div>
                <div>
                    <Typography
                        className={classes.label}
                        variant="body2"
                        children={translations.waiting}
                        color="textPrimary"
                    />
                    <PendingCounter ticketStats={ticketStats} mostCriticalTicketId={mostCriticalTicketId} />
                </div>
            </div>
        </Grid>
    );
};

export default TicketCounter;
