import {ReactElement} from 'react';
import TwoFactorAuthIcon from '@iconify/icons-mdi/two-factor-authentication';
import {Icon as Iconify} from '@iconify/react';
import AddIcon from '@material-ui/icons/Add';
import MuiArrowRightIcon from '@material-ui/icons/ArrowRight';
import MuiAttachmentIcon from '@material-ui/icons/AttachFile';
import CallMade from '@material-ui/icons/CallMade';
import CheckCircle from '@material-ui/icons/CheckCircle';
import MuiChevronRightIcon from '@material-ui/icons/ChevronRight';
import MuiCreateIcon from '@material-ui/icons/Create';
import MuiDeleteIcon from '@material-ui/icons/Delete';
import MuiDoneIcon from '@material-ui/icons/Done';
import MuiExitToAppIcon from '@material-ui/icons/ExitToApp';
import MuiExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiFilterListIcon from '@material-ui/icons/FilterList';
import MuiGetAppIcon from '@material-ui/icons/GetApp';
import InsertEmoticon from '@material-ui/icons/InsertEmoticon';
import MuiListIcon from '@material-ui/icons/List';
import MuiLocalOffer from '@material-ui/icons/LocalOffer';
import MuiLocationOnIcon from '@material-ui/icons/LocationOn';
import MuiMenuIcon from '@material-ui/icons/Menu';
import MuiMoreVertIcon from '@material-ui/icons/MoreVert';
import MuiNavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import MuiNewReleasesIcon from '@material-ui/icons/NewReleases';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import MuiPaymentIcon from '@material-ui/icons/Payment';
import MuiPeopleIcon from '@material-ui/icons/People';
import MuiContactIcon from '@material-ui/icons/Person';
import MuiPersonIcon from '@material-ui/icons/Person';
import MuiPublishIcon from '@material-ui/icons/Publish';
import MuiQuestionAnswerIcon from '@material-ui/icons/QuestionAnswerRounded';
import MuiRefreshIcon from '@material-ui/icons/Refresh';
import MuiReplyIcon from '@material-ui/icons/Reply';
import Schedule from '@material-ui/icons/Schedule';
import Search from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import MuiSmartphoneIcon from '@material-ui/icons/Smartphone';
import MuiSortIcon from '@material-ui/icons/Sort';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import MuiVerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';
import MuiWifiOffIcon from '@material-ui/icons/WifiOff';
import MuiVisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import MuiVisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';

import {EIcons} from '../../enums/icons/EIcons';
import {getFileExtensionFromUrl} from '../../utils/global/global.utils';
import translations from '../../providers/TranslationProvider/translations';
import CustomIcon from './components/CustomIcon';
import CustomSmiley from './components/CustomSmiley';
import {
    ADD_CHATBLOCK_PATH,
    ADD_CHATBOT_PATH,
    ADD_TO_CHAT_ICON_PATH,
    ANALYTICS_ICON_PATH,
    BOT_ICON_PATH,
    BOT_ICON_WITH_ARROW_PATH,
    CANCEL_ICON_PATH,
    CARD_BOTS_ICON_PATH,
    CARD_CHAT_BLOCKS_ICON_PATH,
    CARD_HISTORY_ICON_PATH,
    CARD_NOTES_ICON_PATH,
    CARD_PERSON_ICON_PATH,
    CHAT_BLOCK_ICON_PATH,
    CHAT_ICON_PATH,
    DASHBOARD_ICON_PATH,
    DETAILS_ICON_PATH,
    DOWNLOAD_CLOUD_PATH,
    FILE_AUDIO_ICON_PATH,
    FILE_DOCUMENT_ICON_PATH,
    FILE_ICON_PATH,
    FILE_IMAGE_ICON_PATH,
    FILE_VIDEO_ICON_PATH,
    HELP_ICON_PATH,
    INFO_ICON_PATH,
    LOCK_CLOSED_ICON_PATH,
    LOCK_OPEN_ICON_PATH,
    MIA_ICON_PATH,
    QUEUE_ICON_PATH,
    ROUTING_ICON_PATH,
    SETTINGS_ICON_PATH,
    SMILEY_HAPPY,
    SMILEY_NEUTRAL,
    SMILEY_SAD,
    SMILEY_VERY_HAPPY,
    SMILEY_VERY_SAD,
    SWITCHEROO_PATH,
    TAKEBACK_ICON_PATH,
    TRASH_ICON_PATH,
    UNDO_ICON_PATH,
} from './CustomSVGPaths';
import {SvgIconProps} from '@material-ui/core/SvgIcon/SvgIcon';

// this interface is specific for this case and this case only
interface IIconFactoryIconProps extends SvgIconProps {
    customIconColor?: string;
}

// Icons map
const getIconWithProps = (icon: EIcons, props?: IIconFactoryIconProps): ReactElement => {
    switch (icon) {
        case EIcons.ADD:
            return <AddIcon {...props} />;
        case EIcons.ADD_TO_CHAT:
            return <CustomIcon {...props} path={ADD_TO_CHAT_ICON_PATH} />;
        case EIcons.SEARCH:
            return <Search {...props} />;
        case EIcons.RELAY:
            return <CallMade {...props} />;
        case EIcons.SAVE_ACCEPTED:
            return <CheckCircle {...props} />;
        case EIcons.CANCEL:
            return <CustomIcon {...props} path={CANCEL_ICON_PATH} />;
        case EIcons.CARD_BOTS:
            return <CustomIcon {...props} path={CARD_BOTS_ICON_PATH} />;
        case EIcons.CARD_HISTORY:
            return <CustomIcon {...props} path={CARD_HISTORY_ICON_PATH} />;
        case EIcons.CARD_NOTES:
            return <CustomIcon {...props} path={CARD_NOTES_ICON_PATH} />;
        case EIcons.CARD_PERSON:
            return <CustomIcon {...props} path={CARD_PERSON_ICON_PATH} />;
        case EIcons.CARD_CHAT_BLOCKS:
            return <CustomIcon {...props} path={CARD_CHAT_BLOCKS_ICON_PATH} />;
        case EIcons.DELETE:
            return <MuiDeleteIcon {...props} />;
        case EIcons.FILE_IMAGE:
            return <CustomIcon {...props} path={FILE_IMAGE_ICON_PATH} />;
        case EIcons.FILE_AUDIO:
            return <CustomIcon {...props} path={FILE_AUDIO_ICON_PATH} />;
        case EIcons.FILE_VIDEO:
            return <CustomIcon {...props} path={FILE_VIDEO_ICON_PATH} />;
        case EIcons.FILE_DOCUMENT:
            return <CustomIcon {...props} path={FILE_DOCUMENT_ICON_PATH} />;
        case EIcons.FILE:
            return <CustomIcon {...props} path={FILE_ICON_PATH} />;
        case EIcons.QUEUE:
            return <CustomIcon {...props} path={QUEUE_ICON_PATH} />;
        case EIcons.CHAT_BLOCK:
            return <CustomIcon {...props} path={CHAT_BLOCK_ICON_PATH} />;
        case EIcons.VISIBILITY:
            return <MuiVisibilityIcon {...props} />;
        case EIcons.UNDO:
            return <CustomIcon {...props} path={UNDO_ICON_PATH} />;
        case EIcons.TRASH:
            return <CustomIcon {...props} path={TRASH_ICON_PATH} />;
        case EIcons.SCHEDULE:
            return <Schedule {...props} />;
        case EIcons.TAKEBACK:
            return <CustomIcon {...props} path={TAKEBACK_ICON_PATH} />;
        case EIcons.SETTINGS:
            return <CustomIcon {...props} path={SETTINGS_ICON_PATH} />;
        case EIcons.SEND:
            return <SendIcon {...props} />;
        case EIcons.ROUTING:
            return <CustomIcon {...props} path={ROUTING_ICON_PATH} />;
        case EIcons.LOCK_OPEN:
            return <CustomIcon {...props} path={LOCK_OPEN_ICON_PATH} />;
        case EIcons.LOCK_CLOSED:
            return <CustomIcon {...props} path={LOCK_CLOSED_ICON_PATH} />;
        case EIcons.INFO:
            return <CustomIcon {...props} path={INFO_ICON_PATH} />;
        case EIcons.HELP:
            return <CustomIcon {...props} path={HELP_ICON_PATH} />;
        case EIcons.BOT_BLOCK_JET:
            return <CustomIcon {...props} path={BOT_ICON_PATH} />;
        case EIcons.BOT_BLOCK_WITH_ARROW_JET:
            return <CustomIcon {...props} path={BOT_ICON_WITH_ARROW_PATH} />;
        case EIcons.ADD_CHATBLOCK:
            return <CustomIcon {...props} path={ADD_CHATBLOCK_PATH} style={{width: '48px'}} />;
        case EIcons.ADD_CHATBOT:
            return <CustomIcon {...props} path={ADD_CHATBOT_PATH} style={{width: '48px'}} />;
        case EIcons.SMILEY:
            return <InsertEmoticon {...props} />;
        case EIcons.DETAILS:
            return <CustomIcon {...props} path={DETAILS_ICON_PATH} />;
        case EIcons.DASHBOARD:
            return <CustomIcon {...props} path={DASHBOARD_ICON_PATH} />;
        case EIcons.CHAT:
            return <CustomIcon {...props} path={CHAT_ICON_PATH} />;
        case EIcons.BOT:
            return <CustomIcon {...props} path={BOT_ICON_PATH} />;
        case EIcons.ANALYTICS:
            return <CustomIcon {...props} path={ANALYTICS_ICON_PATH} />;
        case EIcons.DOWNLOAD_CLOUD:
            return <CustomIcon {...props} path={DOWNLOAD_CLOUD_PATH} />;
        case EIcons.UPLOAD:
            return <MuiPublishIcon {...props} />;
        case EIcons.VISIBILITY_OFF:
            return <MuiVisibilityOffIcon {...props} />;
        case EIcons.VERTICAL_POINTS:
            return <MuiMoreVertIcon {...props} />;
        case EIcons.CHEVRON_RIGHT:
            return <MuiChevronRightIcon {...props} />;
        case EIcons.NAVIGATE_BEFORE:
            return <MuiNavigateBeforeIcon {...props} />;
        case EIcons.ARROW_RIGHT:
            return <MuiArrowRightIcon {...props} />;
        case EIcons.MENU:
            return <MuiMenuIcon {...props} />;
        case EIcons.REFRESH:
            return <MuiRefreshIcon {...props} />;
        case EIcons.OFFLINE:
            return <MuiWifiOffIcon {...props} />;
        case EIcons.PERSON:
            return <MuiPersonIcon {...props} />;
        case EIcons.EXIT:
            return <MuiExitToAppIcon {...props} />;
        case EIcons.USER:
            return <MuiPeopleIcon {...props} />;
        case EIcons.LOCATION:
            return <MuiLocationOnIcon {...props} />;
        case EIcons.EXPAND_MORE:
            return <MuiExpandMoreIcon {...props} />;
        case EIcons.SORT:
            return <MuiSortIcon {...props} />;
        case EIcons.FILTER:
            return <MuiFilterListIcon {...props} />;
        case EIcons.REPLY:
            return <MuiReplyIcon {...props} />;
        case EIcons.RELEASES:
            return <MuiNewReleasesIcon {...props} />;
        case EIcons.LIST:
            return <MuiListIcon {...props} />;
        case EIcons.ATTACHMENT:
            return <MuiAttachmentIcon {...props} />;
        case EIcons.PAYMENT:
            return <MuiPaymentIcon {...props} />;
        case EIcons.SWITCHEROO:
            return <CustomIcon {...props} path={SWITCHEROO_PATH} />;
        case EIcons.CHECKMARK:
            return <MuiDoneIcon {...props} />;
        case EIcons.CREATE:
            return <MuiCreateIcon {...props} />;
        case EIcons.DOWNLOAD:
            return <MuiGetAppIcon {...props} />;
        case EIcons.CONTACT:
            return <MuiContactIcon {...props} />;
        case EIcons.SCROLL_TO_BOTTOM:
            return <MuiVerticalAlignBottom {...props} />;
        case EIcons.QUESTION_ANSWER:
            return <MuiQuestionAnswerIcon {...props} />;
        case EIcons.LOCAL_OFFER:
            return <MuiLocalOffer {...props} />;
        case EIcons.TRENDING_UP:
            return <TrendingUpIcon {...props} />;
        case EIcons.TRENDING_DOWN:
            return <TrendingDownIcon {...props} />;
        case EIcons.OPEN_IN_NEW:
            return <OpenInNewIcon {...props} />;
        case EIcons.SMARTPHONE: {
            if (props?.customIconColor) {
                const {customIconColor, ...rest} = props;
                return <MuiSmartphoneIcon {...rest} htmlColor={customIconColor} />;
            }
            return <MuiSmartphoneIcon {...props} />;
        }
        case EIcons.EDIT:
            return <EditIcon {...props} />;
        case EIcons.ASSIGNMENT:
            return <AssignmentIcon {...props} />;
        case EIcons.MIA:
            return <CustomIcon {...props} path={MIA_ICON_PATH} />;
        case EIcons.SMILEY_NEUTRAL:
            return (
                <CustomSmiley
                    {...props}
                    path={SMILEY_NEUTRAL}
                    tooltip={translations.neutral}
                    eyes={[
                        {cx: '15.5', cy: '9.5', r: '1.5'},
                        {cx: '8.5', cy: '9.5', r: '1.5'},
                    ]}
                />
            );
        case EIcons.SMILEY_HAPPY:
            return (
                <CustomSmiley
                    {...props}
                    path={SMILEY_HAPPY}
                    tooltip={translations.good}
                    eyes={[
                        {cx: '15.5', cy: '9.5', r: '1.5'},
                        {cx: '8.5', cy: '9.5', r: '1.5'},
                    ]}
                />
            );
        case EIcons.SMILEY_VERY_HAPPY:
            return <CustomSmiley {...props} path={SMILEY_VERY_HAPPY} tooltip={translations.very_good} />;
        case EIcons.SMILEY_SAD:
            return (
                <CustomSmiley
                    {...props}
                    path={SMILEY_SAD}
                    tooltip={translations.bad}
                    eyes={[
                        {cx: '15.5', cy: '9.5', r: '1.5'},
                        {cx: '8.5', cy: '9.5', r: '1.5'},
                    ]}
                />
            );
        case EIcons.SMILEY_VERY_SAD:
            return <CustomSmiley {...props} path={SMILEY_VERY_SAD} tooltip={translations.very_bad} />;
        case EIcons.TWO_FACTOR_AUTH:
            return <Iconify icon={TwoFactorAuthIcon} color={props?.customIconColor} />;
        default:
            console.error(`Icon ${icon} is not configured in IconFactory::getIcon.`);
            return <div />;
    }
};

export default class IconFactory {
    static getIconWithProps = (iconType: EIcons, props?: IIconFactoryIconProps) => getIconWithProps(iconType, props);

    static getAttachmentIconByUrl = (url?: string, props = {}) => {
        const type = getFileExtensionFromUrl(url);

        if (type.includes('image')) {
            return getIconWithProps(EIcons.FILE_IMAGE, props);
        }
        if (type.includes('video')) {
            return getIconWithProps(EIcons.FILE_VIDEO, props);
        }
        if (type.includes('audio')) {
            return getIconWithProps(EIcons.FILE_AUDIO, props);
        }
        if (type.includes('pdf')) {
            return getIconWithProps(EIcons.FILE_DOCUMENT, props);
        }
        return getIconWithProps(EIcons.FILE, props);
    };
}
