import {request} from './Api/Api';

const JOBS_GET_LAST_URL = 'jobs/last';

export interface IJobRequestParams {
    type?: 'user_import';
    from?: number;
    limit?: number;
    offset?: number;
    id?: number;
    asc?: boolean;
}

export const getLastJobRequest = (parameters: IJobRequestParams) =>
    request({method: 'GET', url: JOBS_GET_LAST_URL}, parameters);
