import {createSelector} from 'reselect';

import translations from '../../providers/TranslationProvider/translations';
import {ICustomField} from '../../definitions/customField/customField.definitions';
import {IState} from '../root.reducer';

export const getCustomFieldsById = (state: IState) => state.entities.customFields.byId;

export const getSortedCustomFields = createSelector([getCustomFieldsById], (customFields) =>
    Object.values(customFields).sort((a: ICustomField, b: ICustomField) => a.position - b.position)
);

const _getCustomFieldsForUi = (customFields: ICustomField[]) =>
    customFields.filter((customField: ICustomField) => (typeof customField === 'object' && !customField.hidden));

export const getCustomFieldsForUi = createSelector([getSortedCustomFields], _getCustomFieldsForUi);

/* prefix and suffix all available custom field's */
const _getAutocompleteOptions = (customFields: ICustomField[]) => [
    {name: '$profile_name$', label: translations.name} as ICustomField,
    {name: '$profile_number$', label: translations.number} as ICustomField,
    {name: '$profile_link$', label: translations.profile_link} as ICustomField,
    {name: '$ticket_id$', label: translations.ticket_list_header_ticketId} as ICustomField,
    {name: '$ticket_agent_firstname$', label: translations.ticket_agent_firstname} as ICustomField,
    {name: '$ticket_agent_lastname$', label: translations.ticket_agent_lastname} as ICustomField,
    ...customFields.map(customField => ({...customField, name: `$${customField.name}$`})),
];

export const getAutocompleteOptions = createSelector([getSortedCustomFields], _getAutocompleteOptions);
