import moment from 'moment';
import {createSelector} from 'reselect';

import translations from '../../providers/TranslationProvider/translations';
import {IAgent} from '../../definitions/agent/agent.definitions';
import {IChannel} from '../../definitions/channel/channel.definitions';
import {IEntityStateForUuids} from '../../definitions/generic/generic.definitions';
import {ITicket} from '../../definitions/tickets/tickets.definitions';
import {EAgentEmailTypes} from '../../enums/api/EAgentEmailTypes';
import {ChatsByDate} from "../../pages/ChatPage/ChatPanel/ChatList/ChatList";
import {idToPhone} from '../../utils/global/global.utils';
import {getCalculatedStats} from '../../utils/ticket/ticket.helpers';
import {getActorId} from '../actor/actor.selectors';
import {getAgentById} from '../agents/agents.selectors';
import {getChannel} from '../channel/channel.selectors';
import {isChannelAnonymized} from '../channel/channels.selectors';
import {IState} from '../root.reducer';
import {IChat} from "../../definitions/chat/chat.definitions";
import {IChatListUser} from '../../definitions/user/user.definitions';

export const getTickets = (state: IState) => state.entities.tickets;

const _getTicketStatsByProfileId = (tickets: IEntityStateForUuids<ITicket>, agentId: number, channel: IChannel) => {
    const ticketsByProfile = Object.values(tickets.byId).filter(ticket => {
        return ticket.agent_id === agentId && ticket.status === 1;
    });

    return getCalculatedStats(ticketsByProfile, channel);
};

const _getLastOutgoingChatId = (chats?: IChat[]) => {
    if (!chats) {
        return null;
    }

    const outgoingChats = chats.filter(chat => chat.outgoing && !chat.bot && !chat.notification);
    const sortedChats = outgoingChats.sort((a, b) => b.chattime - a.chattime);
    // it is possible for the filter to remove all chats
    return sortedChats[0]?.id || null;
};

export const getTicketStatsByProfileId = createSelector(
    [getTickets, getActorId, getChannel],
    _getTicketStatsByProfileId,
);

export const getLastOutgoingChatId = createSelector(
    [(state: IState, chats: IChat[]) => chats],
    _getLastOutgoingChatId,
);

export const getChatsForChatList = createSelector(
    [
        (state: IState) => state.entities.agents.byId,
        (state: IState, chats?: IChat[]) => chats,
        (state: IState, chats: IChat[], user: IChatListUser) => user,
    ],
    (agents, chats, user) => {
        if (!chats?.length) {
            return undefined;
        }

        const reversedChats = [...chats].reverse();

        return reversedChats.reduce((chatsByDate: ChatsByDate, chat) => {
            const chatDate = moment.unix(chat.chattime).format(translations.date_format_long);
            const chatAgent = chat.agent_id && agents[chat.agent_id];

            // supply each chat with additional data from user and agent
            const enrichedChat = {
                ...chat,
                // agentName: chatAgent && chatAgent.name ? chatAgent.name : null,
                userName: user.name,
                userId: user.id,
                image: chatAgent ? chatAgent.profile_image : !chat.outgoing && user.image, // this extra check is necessary for the deleted agent fringe case
            };

            return {
                ...chatsByDate,
                [chatDate]: [...(chatsByDate[chatDate] || []), enrichedChat],
            };
        }, {});
    },
);

export const getChatName = createSelector(
    [
        (state: IState, chat: IChat) => getAgentById(state, chat?.agent_id || 0),
        (state: IState) => isChannelAnonymized(state),
        (state: IState, chat: IChat) => chat.outgoing,
        (state: IState, chat: IChat) => chat.is_newsletter,
        (state: IState, chat: IChat) => chat.outgoing && !chat.agent_id && !chat.bot,
        (state: IState, chat: IChat) =>
            !chat.is_newsletter &&
            chat.agent_email !== EAgentEmailTypes.Api &&
            chat.agent_email !== EAgentEmailTypes.Bot &&
            chat.bot === 0,
        (state: IState, chat: IChat, user?: IChatListUser) => user?.name,
        (state: IState, chat: IChat, user?: IChatListUser) => user?.id,
        (state: IState, chat: IChat, user?: IChatListUser) => user?.messenger,
    ],
    (
        agent: IAgent | undefined,
        isChannelAnonymized,
        isOutgoing,
        isNewsletter,
        isApi,
        isDeleted,
        userName,
        userId,
        messenger,
    ) => {
        if (isOutgoing) {
            if (agent?.name) {
                return agent.name;
            }

            if (isNewsletter) {
                return translations.sender_is_newsletter;
            }

            if (isApi) {
                return EAgentEmailTypes.Api.toUpperCase();
            }

            if (isDeleted) {
                return translations.deleted_agent;
            }

            return EAgentEmailTypes.Bot;
        }

        if (userName?.length && !isChannelAnonymized) {
            return userName;
        }

        return idToPhone(userId, isChannelAnonymized, messenger);
    },
);

export const selectTicketPreviewByTicketOrUserId = (state: IState, id: number): ITicket | undefined =>
    state.entities.ticketPreviews.byId[id] ||
    Object.values(state.entities.ticketPreviews.byId)
        .filter(ticket => ticket.user_id === id)
        .sort((a, b) => b.id - a.id)[0];
