import {FC, memo, ReactNode} from 'react';

import {Theme} from '@material-ui/core/styles/createTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

interface IFormContentProps {
    children: ReactNode;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '& > p': {
            margin: `0 0 ${theme.spacing(3)}px`,

            '&:last-child': {
                marginBottom: 0,
            },
        },

        '&:first-child': {
            marginTop: 0,
        },
    },
}));

const FormContent: FC<IFormContentProps> = props => {
    const {className, children} = props;
    const classes = useStyles();

    return <div className={classNames(classes.root, className)}>{children}</div>;
};

export default memo(FormContent);
