import {AnyAction} from 'redux';

import {IChatpageState, IChatPageUser} from '../../definitions/chatpage/chatpage.definitions';
import {IUpdateStreamMessage} from '../../definitions/updateStream/updateStream.definitions';
import {addChat, updateSingleTicket} from '../tickets/tickets.reducer.utils';

export const handleUserPropertyUpdate = (state: IChatpageState, message: IUpdateStreamMessage) => {
    const {payload} = message;

    if (!state.user || state.user.id !== payload.id) {
        return state;
    }

    const currentCustomfields = state.user.customfields || [];
    const changedFields = payload.customfields || [];
    const unchangedFields = currentCustomfields.filter(
        field => !changedFields.find((newField: any) => newField.id === field.id),
    );

    return {
        ...state,
        ticket: state.ticket && {
            ...state.ticket,
            name: payload.name ?? state.ticket?.name,
        },
        user: state.user && {
            ...state.user,
            name: payload.name ?? state.user?.name,
            customfields: changedFields.length ? [...unchangedFields, ...changedFields] : currentCustomfields,
            archived: payload.archived,
        },
    };
};

export const handleUserStartStop = (state: IChatpageState, action: AnyAction) => {
    if (!state.user) {
        return state;
    }

    if (state.user.id !== action?.payload?.message?.payload?.id) {
        return state;
    }

    return {
        ...state,
        user: {
            ...state.user,
            status: action.payload.message.payload.status,
            post_chat_disabled: action.payload.message.payload.post_chat_disabled,
            post_chat_disabled_slug: action.payload.message.payload.post_chat_disabled_slug ?? '',
        } as IChatPageUser,
    };
};

// check if its the same ticket (real or virtual ticket)
const isSameConversation = (newChat: any, conversationId?: number) => !!conversationId &&
    (conversationId === newChat.ticket_id || conversationId === newChat.user_id);

export const handleReceiveChat = (state: IChatpageState, action: AnyAction) => {
    let updatedState = state;
    const newChat = {...action.payload.message.payload};

    if (!isSameConversation(newChat, state.activeConversationId)) {
        return state;
    }

    // if chats are loaded and the chat message contains a quote, attach the corresponding chat object
    if (updatedState.chats?.length && newChat?.meta?.context_id && state.chats) {
        const contextId = parseInt(newChat.meta.context_id);
        const contextChat = updatedState.chats.find(chat => chat.id === contextId);

        newChat.meta.context = {...contextChat};
    }

    // update chats
    updatedState = {
        ...updatedState,
        chats: addChat(newChat, updatedState.chats),
    };

    // update user
    if (state.user) {
        // current chat received a new message from user
        // only set post_chat_disabled to 0 if the current value is 1
        if (state.user.post_chat_disabled) {
            updatedState = {
                ...updatedState,
                user: {
                    ...state.user,
                    post_chat_disabled: 0,
                    post_chat_disabled_slug: '',
                },
            };
        }
    }

    return updatedState;
};

export const handleOutgoingChat = (state: IChatpageState, action: AnyAction) => {
    const newChat = action.payload.message.payload;

    if (!isSameConversation(newChat, state.activeConversationId)) {
        return state;
    }

    return {
        ...state,
        chats: addChat(newChat, state.chats),
    };
};

export const handleTicketNoteAndLabelUpdate = (state: IChatpageState, action: AnyAction) => {
    if (!state.ticket) {
        return state;
    }

    return {
        ...state,
        ticket: updateSingleTicket(state.ticket, action.payload.message.payload),
    };
};

export const handleTicketUpdate = (state: IChatpageState, action: AnyAction) => {
    if (!state.ticket) {
        return state;
    }

    return {
        ...state,
        ticket: updateSingleTicket(state.ticket, action.payload.message.payload.ticket),
    };
};

export const handleChatUpdate = (state: IChatpageState, action: AnyAction) => {
    const newChat = action.payload.message.payload;

    // use topic id from chat meta data if available to remove interim message (negative chat_id is a topic id)
    if (newChat.meta?.chat_id && newChat.meta.chat_id < 1) {
        newChat.interimUuid = Math.abs(newChat.meta?.chat_id);
    }

    if (!isSameConversation(newChat, state.activeConversationId)) {
        return state;
    }

    let updatedState = {
        ...state,
        chats: addChat(newChat, state.chats),
    };

    const isChatDisabled = newChat.outgoing ? state.user?.post_chat_disabled || 0 : 0;

    // update user only if post_chat_disabled changed
    if (updatedState.user && isChatDisabled !== state.user?.post_chat_disabled) {
        updatedState = {
            ...updatedState,
            user: {
                ...updatedState.user,
                post_chat_disabled: isChatDisabled,
            },
        };
    }

    return updatedState;
};
