import {reducer as formReducer} from 'redux-form';
import {MIA_FORM} from '../../pages/MiaPage/ContentFormPanel/MiaForm/config';
import {SKILLS_DIALOG_FORM} from '../../pages/SettingsPage/components/Skills/SkillsDialog';
import {FORM_NAME_CHATBLOCKS} from '../../pages/SettingsPage/components/TextModules/TextModulesDialog';
import {SKILL_POST_FULFILLED} from '../skill/skill.types';
import {TEXT_MODULE_POST_FULFILLED, TEXT_MODULE_PUT_FULFILLED} from '../textModule/textModule.types';
import {Reducer} from "redux";
import {FormStateMap} from "redux-form/lib/reducer";

export const customFormReducer: Reducer<FormStateMap> = formReducer.plugin({
    [SKILLS_DIALOG_FORM]: (state, action) => {
        switch (action.type) {
            case SKILL_POST_FULFILLED:
                return undefined; // <--- blow away form data
            default:
                return state;
        }
    },
    [FORM_NAME_CHATBLOCKS]: (state, action) => {
        switch (action.type) {
            case TEXT_MODULE_PUT_FULFILLED:
            case TEXT_MODULE_POST_FULFILLED:
                return undefined; // <--- blow away form data
            default:
                return state;
        }
    },
    [MIA_FORM]: (state, action) => {
        // this checks for whenever a form is initialized if that form is the mia form
        // and if it is a draft edit so that it can touch itself, gently
        if (action.type === '@@redux-form/INITIALIZE') {
            if (action?.meta?.form === MIA_FORM) {
                // a new form will never have a created date set
                if (action?.payload?.created) {
                    return {...state, anyTouched: true};
                }
            }
        }
        return state;
    },
});
