import {IUpdateStreamPayload} from "../../definitions/updateStream/updateStream.definitions";
import {isUpdateStream} from "../../utils/redux/redux.utils";
import {EUpdateStreamAction} from "../../enums/updateStream/EUpdateStreamAction";
import {IEntityStateForUuids} from "../../definitions/generic/generic.definitions";
import {IJob, IJobEntityState, IJobErrors} from "../../definitions/jobs/jobs.definitions";
import moment from "moment";

export const isJobUserImport = ({message}: IUpdateStreamPayload) => isUpdateStream(message, EUpdateStreamAction.Job)
    && message.payload?.job?.job_type === "user_import";

export const handleJobUpdateStream = (state: IJobEntityState, payload: IJob, errors: IJobErrors) => {
    return updateListItem(state, payload, errors);
};

export const updateListItem = (
    state: IEntityStateForUuids<IJob>,
    newJob: IJob,
    jobErrors: IJobErrors,
): IEntityStateForUuids<IJob> => {
    return {
        ...state,
        byId: {
            ...state.byId,
            [newJob.id]: newJob,
        },
        ids: [
            ...new Set(...state.ids, String(newJob.id)),
        ],
        errors: jobErrors,
        enabledLoader: false,
        lastUpdated: moment().unix(),
    };
};