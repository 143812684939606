import cloneDeep from 'lodash/cloneDeep';
import {AnyAction, combineReducers, Reducer} from 'redux';
import {IOnobardingStep} from '../../definitions/accounting/accounting.definitions';
import {EUpdateStreamAction} from '../../enums/updateStream/EUpdateStreamAction';
import {UPDATE_STREAM} from '../connection/connection.types';
import {
    ONBOARDING_GET_STATUS_FULFILLED,
    ONBOARDING_GET_STATUS_PENDING,
    ONBOARDING_GET_STATUS_REJECTED,
    SEND_WB_WELCOME_NOTIFICATION_FULFILLED,
    SEND_WB_WELCOME_NOTIFICATION_PENDING,
    SEND_WB_WELCOME_NOTIFICATION_REJECTED,
} from './onboarding.types';

/**
 * STEPS
 */

export interface IOnboardingStepsState {
    loading: boolean;
    items: IOnobardingStep[];
    allStepsCompleted: boolean;
}

export const onboardingStepsInitialState: IOnboardingStepsState = {
    loading: false,
    items: [],
    allStepsCompleted: true,
};

const onboardingStepsReducer: Reducer<IOnboardingStepsState> = (state = onboardingStepsInitialState, action) => {
    switch (action.type) {
        case ONBOARDING_GET_STATUS_PENDING: {
            return {
                ...state,
                loading: true,
            };
        }

        case ONBOARDING_GET_STATUS_REJECTED: {
            return {
                ...state,
                loading: false,
            };
        }

        case ONBOARDING_GET_STATUS_FULFILLED: {
            return {
                ...state,
                items: action.payload,
                allStepsCompleted: !action.payload.find((step: IOnobardingStep) => !step.completed),
                loading: false,
            };
        }

        case UPDATE_STREAM: {
            if (action.payload?.message?.action === EUpdateStreamAction.EmailVerify) {
                // set verify email step completed
                const items = cloneDeep(state.items);
                items.forEach(item => {
                    if (item.slug_header === 'onboarding_status_emailverification_header') {
                        item.completed = true;
                    }
                });

                return {
                    ...state,
                    items: items,
                };
            }
            break;
        }

        default:
            break;
    }

    return state;
};

/**
 * MISC
 */

export interface IOnboardingMiscState {
    sendingWelcomeNotificationWB: boolean;
}

export const onboardingMiscInitialState = {
    sendingWelcomeNotificationWB: false,
};

export const onboardingMiscReducer = (state: IOnboardingMiscState = onboardingMiscInitialState, action: AnyAction) => {
    switch (action.type) {
        case SEND_WB_WELCOME_NOTIFICATION_PENDING: {
            return {
                sendingWelcomeNotificationWB: true,
            };
        }

        case SEND_WB_WELCOME_NOTIFICATION_REJECTED:
        case SEND_WB_WELCOME_NOTIFICATION_FULFILLED: {
            return {
                sendingWelcomeNotificationWB: false,
            };
        }

        default:
            break;
    }

    return state;
};



export const onboardingReducer = combineReducers({
    steps: onboardingStepsReducer,
    misc: onboardingMiscReducer,
});
