import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {Jss} from 'jss';
import {
    createGenerateClassName,
    createTheme,
    StylesProvider,
    ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useDispatch} from 'react-redux';
import {addNotification} from "../../redux/notifications/notifications.actions";
import {ENotificationLevel} from "../../enums/notifications/ENotificationLevel";
import {makeCustomTheme, NECTARY_COLORS} from "../../theme/muiTheme";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";


// disable global stylesheets (conflicts with nested styled components having their own theme)
const generateClassName = createGenerateClassName({
    disableGlobal: false,
    seed: 'mcp',
    productionPrefix: 'mp',
});

interface CustomThemeManagerProps {
    jss?: Jss;
}

// unifies all the theming logic in a single component and takes it out of app
const ThemeProvider: FC<CustomThemeManagerProps> = props => {
    const dispatch = useDispatch();
    const [colorMode, setColorMode] = useState<'light' | 'dark' | 'auto'>('light');

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const themeType: 'dark' | 'light' = colorMode === 'auto' ? (prefersDarkMode ? 'dark' : 'light') : colorMode;

    // shortcut to copy api key from everywhere [Ctrl + Shift + Alt + A]
    const changeColorMode = useCallback(
        async (e: KeyboardEvent) => {
            if (e.ctrlKey && e.altKey && e.shiftKey && e.key.toUpperCase() === 'D') {
                setColorMode(mode => {
                    const newMode = mode === 'light' ? 'dark' : mode === 'dark' ? 'auto' : 'light';
                    dispatch(addNotification(`Changed color mode to ${newMode}`, ENotificationLevel.Info));
                    return newMode;
                });
            }
        },
        [dispatch],
    );

    useEffect(() => {
        document.addEventListener('keyup', changeColorMode);
        return () => document.removeEventListener('keyup', changeColorMode);
    }, [changeColorMode]);

    const theme = useMemo(() => {
        const paletteOptions = {
            type: themeType,
            ...(themeType === 'dark' ? {
                primary: NECTARY_COLORS.tropical100,
                text: NECTARY_COLORS.stormy200,
            } : {}),
        };
        return createTheme(makeCustomTheme(paletteOptions));
    }, [themeType]);

    // if we pass undefined as jss, StylesProvider is unhappy, so we pass nothing if not set
    const jss = props.jss ? {jss: props.jss} : {};

    return (
        <MuiThemeProvider theme={theme}>
            <StylesProvider {...jss} generateClassName={generateClassName}>
                <ErrorBoundary>
                    {props.children}
                </ErrorBoundary>
            </StylesProvider>
        </MuiThemeProvider>
    );
};
export default ThemeProvider;
