import {Reducer} from 'redux';
import {CHANNEL_FEATURES_GET_FULFILLED, CHANNEL_FEATURES_PUT_FULFILLED} from './channelFeatures.types';

export interface IChannelFeaturesState {
    [feature: string]: any;
}

const initialState: IChannelFeaturesState = {};

export const channelFeaturesReducer: Reducer<IChannelFeaturesState> = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case CHANNEL_FEATURES_GET_FULFILLED:
            return (payload && payload.bot_modules) || state;

        case CHANNEL_FEATURES_PUT_FULFILLED: {
            const modules = payload.modules;

            if (modules) {
                return Object.keys(payload.modules).reduce((values, key) => {
                    const feature = payload.modules[key];
                    values[key] = {
                        ...state[key],
                        ...feature,
                    };
                    return values;
                }, {} as IChannelFeaturesState);
            }

            break;
        }

        default:
            break;
    }

    return state;
};
