import {request} from './Api/Api';
import {IPaymentSource, TDefaultPaymentMethodRequestParams} from "../definitions/accounting/accounting.definitions";

const ACCOUNTING_URL = 'accounting';
const ACCOUNTING_PRICING_INFO_URL = `${ACCOUNTING_URL}/pricing_information`;
const ACCOUNTING_PAYMENT_INFO_URL = `${ACCOUNTING_URL}/payment_information`;
const ACCOUNTING_PURCHASE_URL = `${ACCOUNTING_URL}/purchase`;
const ACCOUNTING_PAYMENT_SOURCES = `${ACCOUNTING_URL}/payment_sources`;
const ACCOUNTING_INFO_URL = `${ACCOUNTING_URL}/info`;
const ACCOUNTING_COSTS_URL = `${ACCOUNTING_URL}/costs`;
const ACCOUNTING_BOTMODULE_URL = `${ACCOUNTING_URL}/botmodule`;

export interface IPurchaseRequest {
    pricing_model_id: number;
    payment_method_id: number;
}

export const getAccountRequest = () => request({method: 'GET', url: ACCOUNTING_URL});

export const setAccountRequest = (payload: any) => request({method: 'PUT', url: ACCOUNTING_URL}, payload);

export const getPricingInformationRequest = () => request({method: 'GET', url: ACCOUNTING_PRICING_INFO_URL});

export const getPaymentInformationRequest = (addPaymentSource?: boolean) =>
    request({method: 'GET', url: ACCOUNTING_PAYMENT_INFO_URL}, addPaymentSource ? {add_payment_source: 1} : undefined);

export const postPurchaseRequest = (payload: IPurchaseRequest) => request({
    method: 'PUT',
    url: ACCOUNTING_PURCHASE_URL,
}, payload);

export const setDefaultPaymentSourceRequest = (payload: TDefaultPaymentMethodRequestParams) => request({
    method: 'PUT',
    url: `${ACCOUNTING_PAYMENT_SOURCES}/default`,
}, payload);

export const deletePaymentSourceRequest = (payload: IPaymentSource) => request({
    method: 'DELETE',
    url: `${ACCOUNTING_PAYMENT_SOURCES}/{id}`,
}, payload);



export const getCostsRequest = () => request({method: 'GET', url: ACCOUNTING_COSTS_URL});

export const getAccountInfoRequest = () => request({method: 'GET', url: ACCOUNTING_INFO_URL});

export const setAccountInfoRequest = (payload: any) => request({method: 'PUT', url: ACCOUNTING_INFO_URL}, payload);

export const getChannelFeaturesRequest = () => request({method: 'GET', url: ACCOUNTING_BOTMODULE_URL});

export const putChannelFeaturesRequest = (payload: any) =>
    request({method: 'PUT', url: ACCOUNTING_BOTMODULE_URL}, payload);


