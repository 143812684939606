import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 691 704"
        >
            <defs>
                <linearGradient
                    id="linearGradient-1"
                    x1="50%"
                    x2="50%"
                    y1="0%"
                    y2="100%"
                >
                    <stop offset="0%" stopColor="#5DF877"></stop>
                    <stop offset="100%" stopColor="#0ABB28"></stop>
                </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g>
                    <path
                        fill="url(#linearGradient-1)"
                        d="M677.337 93.818c-6.716-17.384-22.216-41.857-44.194-59.1-12.691-9.955-27.84-19.473-48.651-25.756C562.307 2.263 534.985 0 500.979 0H190.028c-34.007 0-61.329 2.263-83.513 8.962-20.81 6.283-35.96 15.801-48.651 25.756-21.981 17.243-37.481 41.716-44.198 59.1C.136 128.84 0 168.578 0 193.601v316.798c0 25.026.136 64.762 13.666 99.786 6.717 17.384 22.217 41.858 44.195 59.097 12.69 9.955 27.838 19.473 48.65 25.756 22.185 6.699 49.507 8.962 83.514 8.962h310.95c34.007 0 61.33-2.263 83.514-8.962 20.808-6.283 35.96-15.801 48.65-25.756 21.978-17.243 37.478-41.716 44.195-59.097C690.864 575.161 691 535.425 691 510.4V193.601c0-25.023-.132-64.762-13.663-99.783z"
                    ></path>
                    <path
                        fill="#FFF"
                        d="M345.48 122.312c-139.984 0-253.464 95.437-253.464 213.166 0 74.915 43.78 137.199 111.804 176.79 21.493 12.511 1.514 37.916-17.238 57.918-9.997 10.666-8.428 13.976 4.618 10.65 65.404-16.683 67.27-43.387 96.76-37.706a303.9 303.9 0 0057.52 5.514c139.986 0 253.466-95.437 253.466-213.166 0-117.73-113.484-213.166-253.465-213.166z"
                    ></path>
                </g>
            </g>
        </svg>
    );
}

export default Icon;
