import React, {ChangeEvent, FC, forwardRef} from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select, {SelectProps} from '@material-ui/core/Select';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {FieldError} from 'react-hook-form';

import Twemoji from '../../Twemoji/Twemoji';

const useStyles = makeStyles(theme =>
    createStyles({
        inputLabel: {
            backgroundColor: theme.palette.background.paper,
            paddingLeft: 7,
            paddingRight: 7,
            left: -5,
        },
        error: {
            color: theme.palette.error.main,
        },
    }),
);

export interface ICustomSelect extends SelectProps {
    onChange?: (event: ChangeEvent<{name?: string; value: unknown}>) => void;
    identifier?: string | number;
    label?: string;
    margin?: 'dense';
    helperText?: string;
    FormHelperTextProps?: {
        error?: boolean;
    };
    fieldError?: FieldError;
}

const getFormattedValue = (value: unknown, multiple?: boolean) => {
    if (!multiple) {
        return value;
    }
    if (!value) {
        return [''];
    }
    if (typeof value === 'string') {
        return value.split(',');
    }
    return value;
};

export const CustomSelect: FC<ICustomSelect> = forwardRef((props, ref) => {
    const classes = useStyles();
    const {fullWidth, label, fieldError, value, multiple, helperText, className, FormHelperTextProps, ...rest} = props;

    return (
        <FormControl variant='outlined' fullWidth={fullWidth} className={className} innerRef={ref}>
            {!!label && (
                <InputLabel className={classes.inputLabel}>
                    <Twemoji>{label}</Twemoji>
                </InputLabel>
            )}
            <Select fullWidth={fullWidth} multiple={multiple} value={getFormattedValue(value, multiple)} {...rest} />
            {Boolean(helperText) && <FormHelperText className={classes.error} {...FormHelperTextProps}>{helperText}</FormHelperText>}
        </FormControl>
    );
});

export default CustomSelect;
