import {AnyAction, combineReducers, Reducer} from 'redux';
import {
    IHashedRequestState,
    IHashState,
    ILoadedRowsMap,
    IPostPutRequestState,
    IRequestState,
    TActionTypes,
} from '../../definitions/request/requestsState.definitions';
import {ABSENCE_GET_FULFILLED, ABSENCE_GET_PENDING, ABSENCE_GET_REJECTED} from '../absence/absence.types';
import {
    ACCOUNT_COSTS_GET_FULFILLED,
    ACCOUNT_COSTS_GET_PENDING,
    ACCOUNT_COSTS_GET_REJECTED,
    ACCOUNT_GET_FULFILLED,
    ACCOUNT_GET_PENDING,
    ACCOUNT_GET_REJECTED,
    ACCOUNT_INFO_GET_FULFILLED,
    ACCOUNT_INFO_GET_PENDING,
    ACCOUNT_INFO_GET_REJECTED,
    ACCOUNT_PAYMENT_INFO_GET_FULFILLED,
    ACCOUNT_PAYMENT_INFO_GET_PENDING,
    ACCOUNT_PAYMENT_INFO_GET_REJECTED,
    ACCOUNT_PRICING_INFO_GET_FULFILLED,
    ACCOUNT_PRICING_INFO_GET_PENDING,
    ACCOUNT_PRICING_INFO_GET_REJECTED,
} from '../accounting/accounting.types';
import {
    ACTOR_RESET_FULFILLED,
    ACTOR_RESET_PENDING,
    ACTOR_RESET_REJECTED,
    ACTOR_UPDATE_SETTINGS_FULFILLED,
    ACTOR_UPDATE_SETTINGS_PENDING,
    ACTOR_UPDATE_SETTINGS_REJECTED,
} from '../actor/actor.types';
import {AUTOROUTES_GET_FULFILLED, AUTOROUTES_GET_PENDING, AUTOROUTES_GET_REJECTED} from '../autoroute/autoroute.types';
import {GET_BOT_TOPICS_FULFILLED, GET_BOT_TOPICS_PENDING, GET_BOT_TOPICS_REJECTED} from '../bot/bot.types';
import {
    CHANNEL_GET_FULFILLED,
    CHANNEL_GET_INVOICES_FULFILLED,
    CHANNEL_GET_INVOICES_PENDING,
    CHANNEL_GET_INVOICES_REJECTED,
    CHANNEL_GET_PENDING,
    CHANNEL_GET_REJECTED,
} from '../channel/channel.types';
import {COUNTRIES_GET_FULFILLED, COUNTRIES_GET_PENDING, COUNTRIES_GET_REJECTED} from '../countries/countries.types';
import {
    CUSTOM_FIELDS_GET_FULFILLED,
    CUSTOM_FIELDS_GET_PENDING,
    CUSTOM_FIELDS_GET_REJECTED,
} from '../customField/customField.types';
import {
    DYNAMIC_ANSWERS_GET_FULFILLED,
    DYNAMIC_ANSWERS_GET_PENDING,
    DYNAMIC_ANSWERS_GET_REJECTED,
} from '../dynamicAnswer/dynamicAnswer.types';
import {JOBS_GET_LAST_FULFILLED, JOBS_GET_LAST_PENDING, JOBS_GET_LAST_REJECTED} from '../jobs/jobs.types';
import {
    MIA_GET_CONTENT_FULFILLED,
    MIA_GET_CONTENT_PENDING,
    MIA_GET_CONTENT_REJECTED,
} from '../mia/content/content.types';
import {
    NOTES_GET_FULFILLED,
    NOTES_GET_PENDING,
    NOTES_GET_REJECTED,
    NOTES_POST_FULFILLED,
    NOTES_POST_PENDING,
    NOTES_POST_REJECTED,
} from '../notes/notes.types';
import {
    ONBOARDING_GET_STATUS_FULFILLED,
    ONBOARDING_GET_STATUS_PENDING,
    ONBOARDING_GET_STATUS_REJECTED,
} from '../onboarding/onboarding.types';
import {
    GET_PRODUCT_DETAILS_FULFILLED,
    GET_PRODUCT_DETAILS_PENDING,
    GET_PRODUCT_DETAILS_REJECTED,
    SEARCH_PRODUCT_FULFILLED,
    SEARCH_PRODUCT_PENDING,
    SEARCH_PRODUCT_REJECTED,
} from '../products/products.types';
import {
    SETTINGS_AVV_GET_DATA_FULFILLED,
    SETTINGS_AVV_GET_DATA_PENDING,
    SETTINGS_AVV_GET_DATA_REJECTED,
} from '../settings/avv/avv.types';
import {
    FACEBOOK_GET_ACTIVE_PAGE_DATA_FULFILLED,
    FACEBOOK_GET_ACTIVE_PAGE_DATA_PENDING,
    FACEBOOK_GET_ACTIVE_PAGE_DATA_REJECTED,
    FACEBOOK_GET_CONNECTED_PAGE_FULFILLED,
    FACEBOOK_GET_CONNECTED_PAGE_PENDING,
    FACEBOOK_GET_CONNECTED_PAGE_REJECTED,
    FACEBOOK_GET_PAGES_FULFILLED,
    FACEBOOK_GET_PAGES_PENDING,
    FACEBOOK_GET_PAGES_REJECTED,
} from '../settings/facebook/facebook.types';
import {
    SETTINGS_MESSENGER_GET_FULFILLED,
    SETTINGS_MESSENGER_GET_PENDING,
    SETTINGS_MESSENGER_GET_REJECTED,
    SETTINGS_MESSENGER_WHATSAPP_BUSINESS_GET_TESTPHASE_NUMBER_FULFILLED,
    SETTINGS_MESSENGER_WHATSAPP_BUSINESS_GET_TESTPHASE_NUMBER_PENDING,
    SETTINGS_MESSENGER_WHATSAPP_BUSINESS_GET_TESTPHASE_NUMBER_REJECTED,
} from '../settings/messenger/messenger.types';
import {
    SETTINGS_GET_NOTIFICATION_CATEGORIES_FULFILLED,
    SETTINGS_GET_NOTIFICATION_CATEGORIES_PENDING,
    SETTINGS_GET_NOTIFICATION_CATEGORIES_REJECTED,
    SETTINGS_GET_NOTIFICATION_LANGUAGES_FULFILLED,
    SETTINGS_GET_NOTIFICATION_LANGUAGES_PENDING,
    SETTINGS_GET_NOTIFICATION_LANGUAGES_REJECTED,
    SETTINGS_GET_NOTIFICATION_TEMPLATES_FULFILLED,
    SETTINGS_GET_NOTIFICATION_TEMPLATES_PENDING,
    SETTINGS_GET_NOTIFICATION_TEMPLATES_REJECTED,
} from '../settings/notificationTemplates/notificationTemplates.types';
import {SKILLS_GET_FULFILLED, SKILLS_GET_PENDING, SKILLS_GET_REJECTED} from '../skill/skill.types';
import {
    STATISTICS_AUTOMATION_GET_FULFILLED,
    STATISTICS_AUTOMATION_GET_PENDING,
    STATISTICS_AUTOMATION_GET_REJECTED,
    STATISTICS_GET_BLACKLIST_FULFILLED,
    STATISTICS_GET_BLACKLIST_PENDING,
    STATISTICS_GET_BLACKLIST_REJECTED,
} from '../statistics/statistics.types';
import {
    TARGET_GROUPS_GET_FULFILLED,
    TARGET_GROUPS_GET_PENDING,
    TARGET_GROUPS_GET_REJECTED,
} from '../targetGroup/targetGroup.types';
import {
    TEXT_MODULES_GET_FULFILLED,
    TEXT_MODULES_GET_PENDING,
    TEXT_MODULES_GET_REJECTED,
} from '../textModule/textModule.types';
import {TIMEZONES_GET_FULFILLED, TIMEZONES_GET_PENDING, TIMEZONES_GET_REJECTED} from '../timezone/timezone.types';
import {
    getItemsFromPayloadByStateKey,
    getRequestReducerData,
    getTotalFromPayloadByStateKey,
    isTypeFulfilled,
    isTypePending,
    isTypeRejected,
    isTypeReset,
} from '../utils';
import {
    SETTINGS_WIDGET_GET_ALL_FULFILLED,
    SETTINGS_WIDGET_GET_ALL_PENDING,
    SETTINGS_WIDGET_GET_ALL_REJECTED,
    WIDGET_EDITOR_SAVE_WIDGET_FULFILLED,
    WIDGET_EDITOR_SAVE_WIDGET_PENDING,
    WIDGET_EDITOR_SAVE_WIDGET_REJECTED,
} from '../settings/widgetEditor/widgetEditor.types';
import {CHAT_PAGE_GET_USER_FULFILLED, CHAT_PAGE_GET_USER_PENDING} from "../chatPage/chatPage.types";

const createInitialHashedRequestState = (): IHashedRequestState => ({
    byHash: {},
});

const initialHashState: IHashState = {
    isFetching: 0,
    requestIds: [],
    total: 0,
};

const initialRequestState: IRequestState = {};

export const createHashedRequestReducer = (stateKey: string, actionTypes: string[]): Reducer<IHashedRequestState> => {
    return (state = createInitialHashedRequestState(), action) => {
        const {type, payload, meta} = action;
        const hash = meta?.hash;

        if (!state || !stateKey || !actionTypes.includes(type)) {
            return state;
        }

        const hashState = state.byHash[hash] || {...initialHashState};

        if (isTypePending(type)) {
            return {
                ...state,
                byHash: {
                    ...state.byHash,
                    [hash]: {
                        ...hashState,
                        // ...pagination(type, state.byHash[meta.hash], meta),
                        didInvalidate: false,
                        isFetching: +hashState.isFetching + 1,
                        requestIds: [...hashState.requestIds, meta.requestId],
                        loadedRowsMap: updateLoadedRowsMap(hashState, action),
                    },
                },
            };
        }

        if (isTypeFulfilled(type)) {
            const payloadItems = getItemsFromPayloadByStateKey(payload, stateKey);
            const payloadTotal = getTotalFromPayloadByStateKey(payload, stateKey);

            const getItemIdByIndex = (index: number) => (payloadItems[index] ? payloadItems[index].id : -1);

            const _action = {
                ...action,
                meta: {
                    ...action.meta,
                    offset: action.meta?.offset || 0,
                    limit: action.meta?.limit || payloadTotal, // in case there is no limit set, take all items from response
                },
            };

            return {
                ...state,
                byHash: {
                    ...state.byHash,
                    [hash]: {
                        ...hashState,
                        total: payloadTotal,
                        didInvalidate: false,
                        isFetching: hashState.isFetching - 1,
                        loadedRowsMap: updateLoadedRowsMap(hashState, _action, getItemIdByIndex),
                        lastUpdated: meta.timestamp,
                        requestIds: hashState.requestIds.filter(requestId => requestId !== meta.requestId),
                    },
                },
            };
        }

        if (isTypeRejected(type)) {
            return {
                ...state,
                byHash: {
                    ...state.byHash,
                    [hash]: {
                        ...state.byHash[hash],
                        isFetching: hashState.isFetching - 1,
                        loadedRowsMap: updateLoadedRowsMap(hashState, action),
                        requestIds: hashState.requestIds.filter(requestId => requestId !== meta.requestId),
                    },
                },
            };
        }

        return state;
    };
};

export const createRequestReducer = (stateKey: string, actionTypes: TActionTypes): Reducer<IRequestState> => {
    return (state = initialRequestState, action) => {
        const {type, meta} = action;

        const {isRelevant, key} = getRequestReducerData(type, actionTypes);

        if (!stateKey || !isRelevant) {
            return state;
        }

        if (isTypePending(type)) {
            return {
                ...state,
                [key]: true,
                meta: meta,
            };
        }

        if (isTypeFulfilled(type)) {
            return {
                ...state,
                [key]: false,
                loaded: true,
                meta: meta,
            };
        }

        if (isTypeRejected(type)) {
            return {
                ...state,
                [key]: false,
                meta: meta,
            };
        }

        return state;
    };
};

export const createPostPutDeleteRequestReducer = (
    stateKey: string,
    actionTypes: string[],
): Reducer<IPostPutRequestState> => {
    return (state = initialRequestState, action) => {
        const {type, meta} = action;

        if (!stateKey || !actionTypes.includes(type)) {
            return state;
        }

        if (isTypeReset(type)) {
            return {
                requesting: false,
                success: false,
            };
        }

        if (isTypePending(type)) {
            return {
                ...state,
                requesting: true,
                meta: meta,
            };
        }

        if (isTypeFulfilled(type)) {
            return {
                ...state,
                requesting: false,
                success: true,
                meta: meta,
            };
        }

        if (isTypeRejected(type)) {
            return {
                ...state,
                requesting: false,
                meta: meta,
            };
        }

        return state;
    };
};

export const requestReducer = combineReducers({
    invoices: createRequestReducer('invoices', [
        CHANNEL_GET_INVOICES_PENDING,
        CHANNEL_GET_INVOICES_REJECTED,
        CHANNEL_GET_INVOICES_FULFILLED,
    ]),
    autoroutes: createRequestReducer('autoroutes', [
        AUTOROUTES_GET_PENDING,
        AUTOROUTES_GET_REJECTED,
        AUTOROUTES_GET_FULFILLED,
    ]),
    paymentInformation: createRequestReducer('paymentInformation', [
        ACCOUNT_PAYMENT_INFO_GET_PENDING,
        ACCOUNT_PAYMENT_INFO_GET_REJECTED,
        ACCOUNT_PAYMENT_INFO_GET_FULFILLED,
    ]),
    pricingInformation: createRequestReducer('pricingInformation', [
        ACCOUNT_PRICING_INFO_GET_PENDING,
        ACCOUNT_PRICING_INFO_GET_REJECTED,
        ACCOUNT_PRICING_INFO_GET_FULFILLED,
    ]),
    skills: createRequestReducer('skills', [SKILLS_GET_PENDING, SKILLS_GET_REJECTED, SKILLS_GET_FULFILLED]),
    countries: createRequestReducer('countries', [
        COUNTRIES_GET_PENDING,
        COUNTRIES_GET_REJECTED,
        COUNTRIES_GET_FULFILLED,
    ]),
    widgets: createRequestReducer('invoices', [
        SETTINGS_WIDGET_GET_ALL_PENDING,
        SETTINGS_WIDGET_GET_ALL_REJECTED,
        SETTINGS_WIDGET_GET_ALL_FULFILLED,
    ]),
    widgetEditor: (state: { submitting?: boolean } = {}, action) => {
        switch (action.type) {
            case WIDGET_EDITOR_SAVE_WIDGET_PENDING:
                return {
                    ...state,
                    submitting: true,
                };
            case WIDGET_EDITOR_SAVE_WIDGET_REJECTED:
            case WIDGET_EDITOR_SAVE_WIDGET_FULFILLED:
                return {
                    ...state,
                    submitting: false,
                };
            default:
                return state;
        }
    },
    customFields: createRequestReducer('customFields', [
        CUSTOM_FIELDS_GET_PENDING,
        CUSTOM_FIELDS_GET_REJECTED,
        CUSTOM_FIELDS_GET_FULFILLED,
    ]),
    chatPageUser: createRequestReducer('chatPageUser', [
        CHAT_PAGE_GET_USER_PENDING,
        CHAT_PAGE_GET_USER_FULFILLED,
    ]),
    absenceMessages: createRequestReducer('absenceMessages', [
        ABSENCE_GET_PENDING,
        ABSENCE_GET_REJECTED,
        ABSENCE_GET_FULFILLED,
    ]),
    messengers: createRequestReducer('messengers', [
        SETTINGS_MESSENGER_GET_PENDING,
        SETTINGS_MESSENGER_GET_REJECTED,
        SETTINGS_MESSENGER_GET_FULFILLED,
    ]),
    textModules: createRequestReducer('textModules', [
        TEXT_MODULES_GET_PENDING,
        TEXT_MODULES_GET_REJECTED,
        TEXT_MODULES_GET_FULFILLED,
    ]),
    dynamicAnswers: createRequestReducer('dynamicAnswers', [
        DYNAMIC_ANSWERS_GET_PENDING,
        DYNAMIC_ANSWERS_GET_REJECTED,
        DYNAMIC_ANSWERS_GET_FULFILLED,
    ]),
    notificationTemplates: createRequestReducer('notificationTemplates', [
        SETTINGS_GET_NOTIFICATION_TEMPLATES_PENDING,
        SETTINGS_GET_NOTIFICATION_TEMPLATES_REJECTED,
        SETTINGS_GET_NOTIFICATION_TEMPLATES_FULFILLED,
    ]),
    notificationCategories: createRequestReducer('notificationCategories', [
        SETTINGS_GET_NOTIFICATION_CATEGORIES_PENDING,
        SETTINGS_GET_NOTIFICATION_CATEGORIES_REJECTED,
        SETTINGS_GET_NOTIFICATION_CATEGORIES_FULFILLED,
    ]),
    languages: createRequestReducer('languages', [
        SETTINGS_GET_NOTIFICATION_LANGUAGES_PENDING,
        SETTINGS_GET_NOTIFICATION_LANGUAGES_REJECTED,
        SETTINGS_GET_NOTIFICATION_LANGUAGES_FULFILLED,
    ]),
    timezones: createRequestReducer('timezones', [
        TIMEZONES_GET_PENDING,
        TIMEZONES_GET_REJECTED,
        TIMEZONES_GET_FULFILLED,
    ]),
    botTopics: createRequestReducer('botTopics', [
        GET_BOT_TOPICS_PENDING,
        GET_BOT_TOPICS_REJECTED,
        GET_BOT_TOPICS_FULFILLED,
    ]),
    accounting: createRequestReducer('accounting', [ACCOUNT_GET_REJECTED, ACCOUNT_GET_PENDING, ACCOUNT_GET_FULFILLED]),
    accountCosts: createRequestReducer('accountCosts', [
        ACCOUNT_COSTS_GET_PENDING,
        ACCOUNT_COSTS_GET_REJECTED,
        ACCOUNT_COSTS_GET_FULFILLED,
    ]),
    accountInfo: createRequestReducer('accountInfo', [
        ACCOUNT_INFO_GET_PENDING,
        ACCOUNT_INFO_GET_REJECTED,
        ACCOUNT_INFO_GET_FULFILLED,
    ]),
    avvData: createRequestReducer('avvData', [
        SETTINGS_AVV_GET_DATA_FULFILLED,
        SETTINGS_AVV_GET_DATA_PENDING,
        SETTINGS_AVV_GET_DATA_REJECTED,
    ]),
    getChannel: createRequestReducer('getChannel', [CHANNEL_GET_PENDING, CHANNEL_GET_REJECTED, CHANNEL_GET_FULFILLED]),
    facebookPage: createRequestReducer('facebookPage', [
        FACEBOOK_GET_CONNECTED_PAGE_PENDING,
        FACEBOOK_GET_CONNECTED_PAGE_REJECTED,
        FACEBOOK_GET_CONNECTED_PAGE_FULFILLED,
    ]),
    facebookPages: createRequestReducer('facebookPages', [
        FACEBOOK_GET_PAGES_PENDING,
        FACEBOOK_GET_PAGES_FULFILLED,
        FACEBOOK_GET_PAGES_REJECTED,
    ]),
    facebookPageData: createRequestReducer('facebookPageData', [
        FACEBOOK_GET_ACTIVE_PAGE_DATA_PENDING,
        FACEBOOK_GET_ACTIVE_PAGE_DATA_FULFILLED,
        FACEBOOK_GET_ACTIVE_PAGE_DATA_REJECTED,
    ]),
    whatsAppTestNumbers: createRequestReducer('whatsAppTestNumbers', [
        SETTINGS_MESSENGER_WHATSAPP_BUSINESS_GET_TESTPHASE_NUMBER_FULFILLED,
        SETTINGS_MESSENGER_WHATSAPP_BUSINESS_GET_TESTPHASE_NUMBER_REJECTED,
        SETTINGS_MESSENGER_WHATSAPP_BUSINESS_GET_TESTPHASE_NUMBER_PENDING,
    ]),
    miaContent: createRequestReducer('miaContent', [
        MIA_GET_CONTENT_FULFILLED,
        MIA_GET_CONTENT_PENDING,
        MIA_GET_CONTENT_REJECTED,
    ]),
    onboardingStatus: createRequestReducer('onboardingStatus', [
        ONBOARDING_GET_STATUS_FULFILLED,
        ONBOARDING_GET_STATUS_PENDING,
        ONBOARDING_GET_STATUS_REJECTED,
    ]),
    statisticsBlacklist: createRequestReducer('statisticsBlacklist', [
        STATISTICS_GET_BLACKLIST_PENDING,
        STATISTICS_GET_BLACKLIST_FULFILLED,
        STATISTICS_GET_BLACKLIST_REJECTED,
    ]),
    statisticsAutomation: createRequestReducer('statisticsAutomation', [
        STATISTICS_AUTOMATION_GET_PENDING,
        STATISTICS_AUTOMATION_GET_FULFILLED,
        STATISTICS_AUTOMATION_GET_REJECTED,
    ]),
    targetGroups: createRequestReducer('targetGroups', [
        TARGET_GROUPS_GET_PENDING,
        TARGET_GROUPS_GET_REJECTED,
        TARGET_GROUPS_GET_FULFILLED,
    ]),
    passwordReset: createPostPutDeleteRequestReducer('passwordReset', [
        ACTOR_RESET_PENDING,
        ACTOR_RESET_FULFILLED,
        ACTOR_RESET_REJECTED,
    ]),
    notes: createRequestReducer('notes', {
        get: [NOTES_GET_PENDING, NOTES_GET_FULFILLED, NOTES_GET_REJECTED],
        post: [NOTES_POST_PENDING, NOTES_POST_FULFILLED, NOTES_POST_REJECTED],
    }),
    jobs: createRequestReducer('jobs', {
        get: [JOBS_GET_LAST_PENDING, JOBS_GET_LAST_FULFILLED, JOBS_GET_LAST_REJECTED],
    }),
    products: createRequestReducer('products', {
        get: [SEARCH_PRODUCT_PENDING, SEARCH_PRODUCT_FULFILLED, SEARCH_PRODUCT_REJECTED],
        details: [GET_PRODUCT_DETAILS_PENDING, GET_PRODUCT_DETAILS_FULFILLED, GET_PRODUCT_DETAILS_REJECTED],
    }),
    actor: createRequestReducer('actor', {
        put: [ACTOR_UPDATE_SETTINGS_PENDING, ACTOR_UPDATE_SETTINGS_FULFILLED, ACTOR_UPDATE_SETTINGS_REJECTED],
    }),
});

export const updateLoadedRowsMap = (
    state: IHashState,
    action: AnyAction,
    getItemIdByIndex?: (index: number, map?: ILoadedRowsMap) => number,
) => {
    const loadedRowsMap = state.loadedRowsMap ?? {};

    const offset = action.meta?.offset || 0;

    // this function gets the ID of the item from the response (payload), or fallback to 0.
    // if the function returns -1, the row will be removed from list
    const getIdMethod =
        getItemIdByIndex ||
        ((index: number, map: ILoadedRowsMap, oldValue?: number) => map[offset + index] || oldValue || 0);

    return Array.from(Array(action.meta.limit || 0).keys()).reduce((previous: ILoadedRowsMap, index: number) => {
        const loadedItemId = getIdMethod(index, previous);

        // remove from list
        if (loadedItemId === -1) {
            const {[index]: removed, ...newLoadedRowsMap} = previous;
            return newLoadedRowsMap;
        }

        // update in list
        return {
            ...previous,
            [offset + index]: loadedItemId,
        };
    }, loadedRowsMap);
};
