export const AUTOROUTES_GET = 'AUTOROUTES_GET';
export const AUTOROUTES_GET_PENDING = 'AUTOROUTES_GET_PENDING';
export const AUTOROUTES_GET_FULFILLED = 'AUTOROUTES_GET_FULFILLED';
export const AUTOROUTES_GET_REJECTED = 'AUTOROUTES_GET_REJECTED';

export const AUTOROUTE_POST = 'AUTOROUTE_POST';

export const AUTOROUTE_PUT = 'AUTOROUTE_PUT';



export const AUTOROUTE_DELETE = 'AUTOROUTE_DELETE';
