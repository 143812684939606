import {FC} from 'react';

import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import {GOOGLE_MAPS_API_KEY} from '../../../config';
import {TLocation} from "../../../definitions/chat/chat.definitions";

export const useStyles = makeStyles(() =>
    createStyles({
        column: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
        },
        chatLocationContainer: {
            display: 'flex',
            maxHeight: 300,
            overflow: 'hidden',
        },
        mapContainer: {
            height: 160,
            width: 200,
            marginTop: -8,
            borderRadius: 4,
            overflow: 'hidden',
            cursor: 'pointer',
        },
    }),
);

interface ILocationContent {
    media: TLocation;
}

const LocationContent: FC<ILocationContent> = ({media}) => {
    const classes = useStyles();
    const center = {
        lat: Number(media.location.lat),
        lng: Number(media.location.lng),
    };

    const staticImage = `https://maps.googleapis.com/maps/api/staticmap?center=${media.location.lat},${media.location.lng}&zoom=14&size=200x160&markers=size:mid%7Ccolor:red%7C${media.location.lat},${media.location.lng}&key=${GOOGLE_MAPS_API_KEY}`;

    return (
        <>
            <div className={classes.chatLocationContainer}>
                <div className={classes.column}>
                    <Typography variant="overline" gutterBottom>
                        {media.address}
                    </Typography>
                    <div className={classes.mapContainer} onClick={() =>
                    {
                      if (window) {
                        window.open(
                          `https://www.google.com/maps/search/?api=1&query=${center.lat}%2C${center.lng}`,
                          '_blank');
                      }
                    }}>
                        <img src={staticImage} width={200} height={160} alt={media.address}/>
                    </div>
                </div>
            </div>
        </>
    );
};

LocationContent.displayName = 'LocationContent';

export default LocationContent;
