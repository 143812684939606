import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import {FC} from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';

import LoaderSpinner from '../../components/Loader/LoaderSpinner';
import Twemoji from '../../components/Twemoji/Twemoji';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: 'fixed',
            zIndex: 1399, // 1 lower than snackbars
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'white',
            transition: 'opacity 2s ease-in-out',
            opacity: 1,

            // fix center alignment of loader inside main content
            '& > div': {
                height: 'auto !important',
                overflow: 'hidden !important',
            },
        },
        logo: {
            width: 132,
            marginBottom: 32,
        },
        loadingText: {
            margin: 20,
            textAlign: 'center',
            lineHeight: 1.75,
        },
        loaderTransition: {
            '&-enter': {
                opacity: 0,
            },
            '&-enter-active': {
                opacity: 1,
                transition: 'opacity 250ms',
            },
            '&-exit': {
                opacity: 1,
            },
            '&-exit-active': {
                opacity: 0,
                transition: 'opacity 300ms',
            },
        },
    }),
);

interface LoadingPageProps {
    className?: string;
    text?: string;
    show: boolean;
}

/**
 * The Loading Page will mount/unmount itself automatically with a nice fade animation
 *
 * @param className
 * @param text
 * @param show
 * @constructor
 */
const LoadingPage: FC<LoadingPageProps> = ({className, text, show}) => {
    const classes = useStyles();

    return (
        <CSSTransition in={show} timeout={500} classNames={classes.loaderTransition} unmountOnExit mountOnEnter>
            <div className={classNames(className, classes.root)}>
                <div>
                    <div>
                        <LoaderSpinner proportions={120} />
                    </div>
                    {!!text && (
                        <Typography className={classes.loadingText} variant='h6'>
                            <Twemoji>{text}</Twemoji>
                        </Typography>
                    )}
                </div>
            </div>
        </CSSTransition>
    );
};

export default LoadingPage;
