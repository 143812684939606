import {Reducer} from 'redux';

import {
    IMessengerMenuSettingsActionsFulfilled,
    IMessengerMenuSettingsState,
} from '../../../definitions/settings/messengerMenu/messengerMenu.definitions';
import {
    MESSENGER_MENU_SETTINGS_GET_FULFILLED,
    MESSENGER_MENU_SETTINGS_PUT_FULFILLED,
} from './messengerMenuSettings.actions';

export const messengerMenuSettingsInitState: IMessengerMenuSettingsState = {
    loaded: false,
    title: '',
    keywords: [],
};

export const messengerMenuSettingsReducer: Reducer<
    IMessengerMenuSettingsState,
    IMessengerMenuSettingsActionsFulfilled
> = (state = messengerMenuSettingsInitState, action) => {
    const {type, payload} = action;

    switch (type) {
        case MESSENGER_MENU_SETTINGS_GET_FULFILLED:
        case MESSENGER_MENU_SETTINGS_PUT_FULFILLED: {
            const keywords = payload.keywords
                ? payload.keywords.map((keyword, index) => ({
                      id: index,
                      keyword,
                  }))
                : [];

            return {
                loaded: true,
                title: payload.title,
                keywords,
            };
        }
        default:
            return state;
    }
};
