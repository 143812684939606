export const QUERY_PARAM_SWITCH_CHANNEL = 'sc';
export const QUERY_PARAM_OPEN_DIALOG = 'dialog';
export const QUERY_PARAM_BOOKING_PLAN = 'plan';
export const QUERY_PARAM_GO_TO_CHANNEL = 'channel';
export const QUERY_PARAM_TOKEN = 'token';
export const QUERY_PARAM_ONBOARDING_WIZARD = 'wiz';
export const QUERY_PARAM_NUMBER = 'number';
export const QUERY_PARAM_DEVICE_ID = 'device_id';
export const QUERY_PARAM_AFFILIATE_ID = 'affiliate_id';
export const QUERY_PARAM_SPKG = 'spkg';
