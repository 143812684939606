import {FC, ReactNode, useMemo} from 'react';

import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';

import AccessControl from '../../../../../../components/AccessControl/AccessControl';
import translations from '../../../../../../providers/TranslationProvider/translations';
import ERights from '../../../../../../enums/actor/ERights';
import {EBaseRoutes, ESettingsRoutes} from '../../../../../../enums/routes/ERoutes';
import {usesMia, usesSimpleChat, usesTicketSystem} from '../../../../../../redux/channel/channel.selectors';
import {getNumberOfUnreadChats, getNumUnassignedTickets} from '../../../../../../redux/statistics/statistics.selectors';
import {DrawerChildProps} from '../../../Drawer';
import {MainMenuItem} from './MainMenuItem';
import ModularContent from '../../../../../../components/ModularContent/ModularContent';
import BrandedIconSinch from '../../../../../../components/BrandedIconsSinch/BrandedIconSinch';

interface IMainMenuItem {
    path: string;
    name: string;
    icon: ReactNode;
    accessRights?: ERights | ERights[];
    matchIfAny?: boolean;
    hideForSimpleChat?: boolean;
    badge?: string;
    hidden?: boolean;
}

export const getMainMenuItems = (
    isTicketSystemActive: boolean,
    isMiaAvailable: boolean,
    isSimpleChatActive: boolean,
    numUnassignedTickets: number,
    numUnreadChats?: number,
): IMainMenuItem[] => [
    {
        path: EBaseRoutes.Dashboard,
        name: translations.dashboard,
        icon: <BrandedIconSinch menuIcon='Home'/>,
    },
    {
        path: EBaseRoutes.Chat,
        name: isTicketSystemActive ? translations.work_interface : translations.chat,
        accessRights: [ERights.TicketRead, ERights.ChatRead],
        matchIfAny: true,
        icon: <BrandedIconSinch menuIcon='Contact'/>,
        hidden: !isSimpleChatActive && !isTicketSystemActive,
        badge: !isTicketSystemActive && numUnreadChats ? numUnreadChats >= 100 ? '99+' : numUnreadChats.toString() : undefined,
    },
    {
        path: EBaseRoutes.Assignment,
        accessRights: ERights.TicketShowAssignment,
        name: translations.assignment,
        icon: <BrandedIconSinch menuIcon='Path'/>,
        hidden: !isTicketSystemActive,
        badge: numUnassignedTickets ? numUnassignedTickets >= 100 ? '99+' : numUnassignedTickets.toString() : undefined,
    },
    {
        path: EBaseRoutes.TicketList,
        accessRights: ERights.TicketRead,
        name: translations.ticketlist,
        icon: <BrandedIconSinch menuIcon='ChatMessage'/>,
        hidden: !isTicketSystemActive,
    },
    {
        path: EBaseRoutes.Mia,
        name: 'MIA',
        accessRights: ERights.ContentEdit,
        icon: <BrandedIconSinch menuIcon='Campaigns'/>,
        hidden: !isMiaAvailable,
    },
    {
        path: EBaseRoutes.User,
        accessRights: ERights.UserShowList,
        name: translations.user,
        icon: <BrandedIconSinch menuIcon='Users'/>,
    },
    {
        path: EBaseRoutes.Statistics,
        accessRights: ERights.StatisticsChats,
        name: translations.stats,
        icon: <BrandedIconSinch menuIcon='BarChartUp'/>,
    },
    {
        path: ESettingsRoutes.Channel,
        accessRights: ERights.SettingsChannel,
        name: translations.settings,
        icon: <BrandedIconSinch menuIcon='Settings'/>,
    },
].filter((menuItem: IMainMenuItem) => !menuItem.hidden);

export const MainMenu: FC<DrawerChildProps> = ({isDrawerOpen}) => {
    const location = useLocation();
    const isMiaAvailable = useSelector(usesMia);
    const isTicketSystemActive = useSelector(usesTicketSystem);
    const isSimpleChatActive = useSelector(usesSimpleChat);
    const numUnassignedTickets = useSelector(getNumUnassignedTickets);
    const numUnreadChats = useSelector(getNumberOfUnreadChats);

    const menuItems = useMemo(
        () =>
            getMainMenuItems(isTicketSystemActive, isMiaAvailable, isSimpleChatActive, numUnassignedTickets, numUnreadChats)
                .map(menuItem => ({
                    ...menuItem,
                    highlight: location.pathname.startsWith(menuItem.path),
                })),
        [
            isTicketSystemActive,
            isMiaAvailable,
            isSimpleChatActive,
            numUnassignedTickets,
            numUnreadChats,
            location.pathname,
        ],
    );

    return (
        <>
            {menuItems.map(({accessRights, hideForSimpleChat, matchIfAny, ...menuItem}, index) => (
                <AccessControl key={index} requiredRights={accessRights} matchIfAny={matchIfAny}>
                    <ModularContent hideForSimpleChat={hideForSimpleChat}>
                        <MainMenuItem {...menuItem} isDrawerOpen={isDrawerOpen} />
                    </ModularContent>
                </AccessControl>
            ))}
        </>
    );
};
