import moment from 'moment';
import {FC} from 'react';

import MomentUtils from '@date-io/moment';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {useSelector} from 'react-redux';

import CustomSelect from '../../../components/CustomInputs/CustomSelect/CustomSelect';
import translations from '../../../providers/TranslationProvider/translations';
import {IPeriodData} from '../../../definitions/statistics/statistics.definitions';
import {EChartPeriod, isDynamicChartPeriod} from '../../../enums/chart/EChartPeriod';
import {getActorLanguage} from '../../../redux/actor/actor.selectors';
import {useTypedSelector} from "../../../utils/hooks/useTypedSelector";
import {getChartPeriodRange} from "../../../utils/statistic/statistic.utils";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/styles/createStyles";
import {getInvoicePeriod} from "../../SettingsPage/components/AccountInformationSettings/components/AccountInformationPanel/AccountInformation/account.selectors";

const useStyles = makeStyles((theme) => createStyles({
    datePickerContainer: {
        display: 'flex',
        marginTop: theme.spacing(2),
    },
    datePickerLeft: {
        marginRight: theme.spacing(1),
    },
    datePickerRight: {
        marginLeft: theme.spacing(1),
    },
    dateRangeIcon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1),
    },
}));

interface IEditPeriodProps {
    period: EChartPeriod;
    fromDate: number;
    toDate: number;
    handleUpdatePeriod: (event: any) => void;
    handleClose: () => void;
    minDate?: number;
    forbiddenChartPeriods?: EChartPeriod[];
}

const EditPeriod: FC<IEditPeriodProps> = (
    {
        period,
        fromDate,
        toDate,
        minDate,
        handleClose,
        handleUpdatePeriod,
        forbiddenChartPeriods,
    }
) => {
    const classes = useStyles();
    const language = useSelector(getActorLanguage);
    const invoicePeriod = useTypedSelector(getInvoicePeriod);

    const handleFromDateChange = (fromDate: MaterialUiPickersDate) => {
        if (!fromDate) {
            return false;
        }

        const newPeriodData = {
            period: period,
            from: fromDate.startOf('day').unix(),
            to: toDate,
        };

        handleUpdatePeriod(newPeriodData);
    };

    const handleToDateChange = (toDate: MaterialUiPickersDate) => {
        if (!toDate) {
            return false;
        }

        const newPeriodData = {
            period: period,
            from: fromDate,
            to: toDate.endOf('day').unix(),
        };

        handleUpdatePeriod(newPeriodData);
    };

    const handlePeriodChange = (event: any) => {

        if (!invoicePeriod) {
            return;
        }

        const value = event?.target.value || 'custom';
        const chartPeriod =
            value === EChartPeriod.Custom
                ? {
                    from: fromDate,
                    to: toDate,
                }
                : getChartPeriodRange(value, invoicePeriod);

        const periodData: IPeriodData = {
            period: value,
            from: chartPeriod.from,
            to: chartPeriod.to,
        };

        handleUpdatePeriod(periodData);

        if (value !== EChartPeriod.Custom) {
            handleClose();
        }
    };

    const periods = [
        {
            value: EChartPeriod.Custom,
            label: translations.custom,
        },
        {
            value: EChartPeriod.InvoicingPeriod,
            label: translations.current_billing_period,
        },
        {
            value: EChartPeriod.LastMonth,
            label: translations.last_month,
        },
        {
            value: EChartPeriod.Last30Days,
            label: translations.last_30_days,
        },
        {
            value: EChartPeriod.Last365Days,
            label: translations.last_365_days,
        },
    ];

    const now = moment().unix();
    const minDateFrom = minDate || 1420066800;
    const maxDateFrom = toDate > now ? now : toDate;

    return (
        <>
            <FormControl variant="outlined" fullWidth>
                <CustomSelect
                    name="period"
                    value={period}
                    onChange={handlePeriodChange}
                    label={translations.choose_timespan}
                >
                    {periods
                        .filter(period => !forbiddenChartPeriods || !forbiddenChartPeriods.includes(period.value))
                        .map(period => (
                            <MenuItem key={period.value} value={period.value}>
                                {period.label}
                            </MenuItem>
                        ))
                    }
                </CustomSelect>
            </FormControl>

            {!isDynamicChartPeriod(period) && (
                <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
                    <div className={classes.datePickerContainer}>
                        <DatePicker
                            disabled={period !== EChartPeriod.Custom}
                            label={translations.from}
                            value={fromDate * 1000}
                            minDate={minDateFrom * 1000}
                            maxDate={maxDateFrom * 1000}
                            onChange={handleFromDateChange}
                            className={classes.datePickerLeft}
                            InputProps={{
                                startAdornment: <DateRangeIcon className={classes.dateRangeIcon}/>,
                            }}
                            inputVariant="outlined"
                            format={translations.date_picker_format}
                            animateYearScrolling
                            autoOk
                        />
                        <DatePicker
                            disabled={period !== EChartPeriod.Custom}
                            label={translations.to}
                            value={toDate * 1000}
                            minDate={fromDate * 1000}
                            onChange={handleToDateChange}
                            className={classes.datePickerRight}
                            InputProps={{
                                startAdornment: <DateRangeIcon className={classes.dateRangeIcon}/>,
                            }}
                            inputVariant="outlined"
                            format={translations.date_picker_format}
                            animateYearScrolling
                            autoOk
                        />
                    </div>
                </MuiPickersUtilsProvider>
            )}
        </>
    );
};

export default EditPeriod;
