import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import {IRequestParameters} from '../../definitions/request/requestsState.definitions';

import {
    blockUserRequest,
    deleteUserRequest,
    getUserRequest,
    setUnreadRequest,
    startUserRequest,
    stopUserRequest,
    unblockUserRequest,
    uploadUserCsvRequest,
} from '../../requests/userRequest';
import {createHashedRequestAction} from '../request/request.actions';
import {IState} from '../root.reducer';
import {getChatPageUser} from "../chatPage/chatPage.selectors";
import {
    USER_BLOCK,
    USER_DELETE,
    USER_LIST_PAGE_SET_FILTER,
    USER_SET_UNREAD,
    USER_START,
    USER_STOP,
    USER_UNBLOCK,
    USERS_GET,
    USERS_UPLOAD_CSV,
} from './user.types';


export const getUsers = createHashedRequestAction('users', USERS_GET, getUserRequest);

export const deleteUser = (userId: number) => ({
    type: USER_DELETE,
    payload: deleteUserRequest(userId),
});

export const blockUser = (userId: number) => ({
    type: USER_BLOCK,
    payload: blockUserRequest(userId),
});

export const unblockUser = (userId: number) => ({
    type: USER_UNBLOCK,
    payload: unblockUserRequest(userId),
});

export const setUserFilter = (filter: IRequestParameters) => ({
    type: USER_LIST_PAGE_SET_FILTER,
    payload: filter,
});

export const startUser = (userId: number) => ({
    type: USER_START,
    payload: startUserRequest(userId),
});

export const stopUser = (userId: number) => ({
    type: USER_STOP,
    payload: stopUserRequest(userId),
});

export const uploadUserCsvAction = (formData: FormData) => ({
    type: USERS_UPLOAD_CSV,
    payload: uploadUserCsvRequest(formData),
});

export const setUnread = (ids: number | number[], unreadCount: number) => (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
    // dont set unread = 0 if already set
    if (!unreadCount) {
        const user = getChatPageUser(getState());
        if (!user?.unread) {
            return;
        }
    }
    dispatch({
        type: USER_SET_UNREAD,
        payload: setUnreadRequest(ids, unreadCount),
    });
};

// we need a thunk, otherwise we get an unhandled exception on failure
export const uploadUserCsv = (formData: FormData) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) =>
    dispatch(uploadUserCsvAction(formData));

export const startOrStopSelectedUser = () => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
        const user = getSelectedUser(getState());

        if (!user.status) {
            return dispatch(startUser(user.id));
        }

        return dispatch(stopUser(user.id));
    };
};

const getSelectedUserIdFromUserPageState = (state: IState) => {
    const userId = state.selected.userPage.selectedUserId;

    if (!userId) {
        throw Error('no selected userId');
    }

    return userId;
};

const getSelectedUser = (state: IState) => {
    const userId = getSelectedUserIdFromUserPageState(state);
    const user = state.entities.users.byId[userId];
    if (!user) {
        throw Error('no user found');
    }

    return user;
};

export const blockSelectedUser = () => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
        const userId = getSelectedUserIdFromUserPageState(getState());

        if (!userId) {
            return;
        }

        dispatch(blockUser(userId));
    };
};

export const deleteSelectedUser = () => {
    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
        const userId = getSelectedUserIdFromUserPageState(getState());

        if (!userId) {
            return;
        }

        dispatch(deleteUser(userId));
    };
};
