import {FC} from 'react';

import MuiAvatar from '@material-ui/core/Avatar';

import {getDefaultProfileImageUrl, IMAGE_SERVER_URL} from '../../config';
import {getThumbnailImage, toInitials} from '../../utils/global/global.utils';
import Twemoji from '../Twemoji/Twemoji';

interface IAvatarProps {
    name?: string;
    alt?: string;
    src?: string;
    width?: number;
    height?: number;
    variant?: 'circular' | 'rounded';
    className?: string;
}

export const Avatar: FC<IAvatarProps> = props => {
    const {name, src, variant, width = 100, height = 100} = props;

    if (!src || src === '') {
        return (
            <MuiAvatar {...props} variant={variant || 'circular'}>
                <Twemoji>{toInitials(name || '')}</Twemoji>
            </MuiAvatar>
        );
    }

    let imageUrl: string;
    if (src === getDefaultProfileImageUrl()) {
        imageUrl = '';
    } else if (src.startsWith(IMAGE_SERVER_URL)) {
        imageUrl = getThumbnailImage({
            url: src,
            width,
            height,
        });
    } else {
        imageUrl = src;
    }

    return <MuiAvatar {...props} src={imageUrl} />;
};

Avatar.displayName = 'Avatar';

export default Avatar;
