import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import {TRequestByHashStateKey} from '../../definitions/request/requestsState.definitions';
import createRequestHash from '../../utils/redux/utils/createRequestHash';
import {IState} from '../root.reducer';

export const createHashedRequestAction = (
    stateKey: TRequestByHashStateKey,
    actionType: string,
    requestMethod: (parameters: any) => any,
) => (requestParameters?: any, requestHash?: string, meta?: any) => {
    const hash = requestHash || createRequestHash(requestParameters);

    return (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
        const state = getState();
        const requestState = state.requestNew[stateKey].byHash[hash];

        const limit = requestParameters?.limit;
        const offset = requestParameters?.offset;

        // create request ID with offset and limit to check if this specific request is already fired.
        const requestId = `${hash}-${limit || 0}-${offset || 0}`;

        if (requestState?.requestIds?.includes(requestId)) {
            return;
        }

        return dispatch({
            type: actionType,
            payload: requestMethod(requestParameters),
            meta: {
                ...meta,
                hash,
                limit,
                offset,
                requestId,
            },
        });
    };
};
