import {FC} from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CustomTextField, {ICustomTextFieldProps} from '../CustomTextField/CustomTextField';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        inputAdornment: {
            marginTop: theme.spacing(0.5),
        },
    }),
);

interface IPhoneNumberTextFieldProps extends ICustomTextFieldProps {
    countryCode: string | number;
}

const PhoneNumberTextField: FC<IPhoneNumberTextFieldProps> = props => {
    const {countryCode, ...restProps} = props;

    const classes = useStyles();

    return (
        <CustomTextField
            fullWidth
            {...restProps}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start" className={classes.inputAdornment}>
                        {countryCode ? `+${countryCode}` : ''}
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default PhoneNumberTextField;
