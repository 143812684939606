// noinspection RegExpRedundantEscape,RegExpDuplicateCharacterInClass

import translations from '../../providers/TranslationProvider/translations';
import {checkIfEmailExistsRequest} from '../../requests/registerRequests';
import {translateSlug} from '../translation/translation.utils';
import {linkRegex, protocolRegex} from "../hookForm/validation.utils";

export const validationSlugs = Object.freeze({
    required: 'required',
    validation_length_min_x: 'validation_length_min_x',
    validation_length_max: 'validation_length_max',
    validation_num_min: 'validation_num_min',
    validation_num_max: 'validation_num_max',
    validation_name_length_min: 'validation_name_length_min',
    valiation_type_number: 'valiation_type_number',
    valiation_type_name: 'valiation_type_name',
    validation_type_email: 'validation_type_email',
    validation_email_exists: 'validation_email_exists',
    validation_type_url: 'validation_type_url',
    validation_password_no_match: 'validation_password_no_match',
    validation_password_strength: 'validation_password_strength',
    validation_greater_than_zero: 'validation_greater_than_zero',
    validation_greater_than_or_zero: 'validation_greater_than_or_zero',
    validation_type_word: 'validation_type_word',
    validation_array_not_empty: 'validation_array_not_empty',
    validation_future_date: 'validation_future_date',
    validation_greater_compare_value: 'validation_greater_compare_value',
    validation_lowercase_alphanumeric_and_underscores: 'validation_lowercase_alphanumeric_and_underscores',
    validation_alphanumeric_and_underscores: 'validation_alphanumeric_and_underscores',
    validation_no_hashtag: 'validation_no_hashtag',
    validation_no_comma: 'validation_no_comma',
    validation_notify_promo_text: 'validation_notify_promo_text',
    no_commando_keyword: 'no_commando_keyword',
    more_than_one_commando_keyword: 'more_than_one_commando_keyword',
    validation_unkwown_command: 'validation_unkwown_command',
    validation_need_at_least_one_value: 'validation_need_at_least_one_value',
});

const isNumber = (n: any) => !isNaN(parseFloat(n)) && isFinite(n);

export const validateTrue = (value: any) => (!value ? translateSlug(validationSlugs.required) : undefined);

// don't use this for showing error to actor, just to validate the bool
export const validateApiBool = (value: any) => {
    if ([0, 1].includes(parseInt(value))) {
        return undefined;
    }

    return 'no bool';
};

export const validateRequired = (value: any) =>
    !value || !String(value).trim() ? translateSlug(validationSlugs.required) : undefined;

export const validateArrayNotEmpty = (value: any) =>
    !value || !value.length ? translateSlug(validationSlugs.validation_array_not_empty) : undefined;

export const validateName = (value: string) => {
    const re = /^\w*$/;

    return value && !re.test(String(value).toLowerCase())
        ? translateSlug(validationSlugs.valiation_type_name)
        : undefined;
};


export const validateEmail = (email?: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return validateRequired(email) && email && !re.test(String(email).toLowerCase())
        ? translateSlug(validationSlugs.validation_type_email)
        : undefined;
};

export const validateUrl = (url: string, isMandatory = false) => {
    const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    const urlString = url ? String(url) : '';

    if (isMandatory && urlString.length === 0) {
        return translateSlug(validationSlugs.validation_type_url);
    }

    if (!isMandatory && urlString.length === 0) {
        return undefined;
    }

    return regex.test(String(urlString)) ? undefined : translateSlug(validationSlugs.validation_type_url);
};


export const validateUrlOrLink = (value: string) => {
    if (!protocolRegex.test(value)) {
        return translations.https_is_required;
    }
    if (!linkRegex.test(value)) {
        return translations.no_valid_url;
    }
    return undefined;
};

export const validateRequiredEmail = (value: string) => validateRequired(value) || validateEmail(value);

export const validateEmailExists = (value: string) => checkIfEmailExistsRequest(value);

export const validateTypeWord = (value: any) =>
    value && value.indexOf(' ') !== -1 ? translateSlug(validationSlugs.validation_type_word) : undefined;

export const validateNumber = (value: any) =>
    value && !isNumber(value) ? translateSlug(validationSlugs.valiation_type_number) : undefined;

export const validateRequiredNumber = (value: any) => validateRequired(value) || validateNumber(value);

export const validateNumberGreaterThanZero = (value: any) =>
    value <= 0 ? translateSlug(validationSlugs.validation_greater_than_zero) : undefined;

export const validateNumberGreaterThanOrZero = (value: any) =>
    value < 0 ? translateSlug(validationSlugs.validation_greater_than_or_zero) : undefined;

export const validateNumberGreaterThanOther = (value: number, compareValue: number, compareValueName: string) =>
    value <= compareValue
        ? translateSlug(
            validationSlugs.validation_greater_compare_value,
            translateSlug(compareValueName) || compareValueName,
        )
        : undefined;

export const validateStringLengthMin = (value: string, minLength: number) =>
    value.trim().length < minLength ? translateSlug(validationSlugs.validation_length_min_x, minLength) : undefined;

export const validateRequiredStringLengthMin = (value = '', minLength: number) =>
    validateRequired(value) || validateStringLengthMin(value, minLength);

export const validateMiaPlanningDate = (value: any) =>
    value > Date.now() / 1000 + 180 ? undefined : translateSlug(validationSlugs.validation_future_date);

export const validateAlphanumericAndUnderscores = (value: string) =>
    value && !/^[A-z\d]*$/.test(value)
        ? translateSlug(validationSlugs.validation_alphanumeric_and_underscores)
        : undefined;

export const validateCustomFieldName = (value: string) =>
    validateRequired(value) || validateAlphanumericAndUnderscores(value);

const validateNoComma = (value: string) =>
    value.indexOf(',') >= 0 ? translateSlug(validationSlugs.validation_no_comma) : undefined;
const validateNoHashtag = (value: string) =>
    value.indexOf('#') >= 0 ? translateSlug(validationSlugs.validation_no_hashtag) : undefined;

export const validateMaxLength = (max: number, value?: string) => {
    if (value && value.length > max) {
        return translateSlug(validationSlugs.validation_length_max, max.toString());
    }
};

export const validateLength = (min: number, max: number, value?: string) => {
    if (!value) {
        return translateSlug(validationSlugs.required);
    }
    if (value.trim().length < min) {
        return translateSlug(validationSlugs.validation_length_min_x, min.toString());
    }
    if (value.length > max) {
        return translateSlug(validationSlugs.validation_length_max, max.toString());
    }
    return undefined;
};

export const validateNumberMin = (min: number, value?: number) => {
    if (!value) {
        return translateSlug(validationSlugs.required);
    }
    if (value < min) {
        return translateSlug(validationSlugs.validation_num_min, min.toString());
    }
    return undefined;
};

export const validateNumberMax = (max: number, value?: number) => {
    if (!value) {
        return translateSlug(validationSlugs.required);
    }
    if (value > max) {
        return translateSlug(validationSlugs.validation_num_max, max.toString());
    }
    return undefined;
};


export const validateNumberBetween = (min: number, max: number, value?: number) => {
    return validateNumberMin(min, value) || validateNumberMax(max, value);
};

export const validateKeyword = (value: any) => {
    const required = validateRequired(value);
    if (required) {
        return required;
    }
    const lengthMinMax = validateLength(2, 7, value);
    if (lengthMinMax) {
        return lengthMinMax;
    }
    const noHashtag = validateNoHashtag(value);
    if (noHashtag) {
        return noHashtag;
    }
    const noComma = validateNoComma(value);
    if (noComma) {
        return noComma;
    }
    return undefined;
};

export const validatePromoText = (value: string) => {
    const notifyLink = '$notify_link$';

    if (!value || value.indexOf(notifyLink) === -1 || value.length <= notifyLink.length) {
        return translateSlug(validationSlugs.validation_notify_promo_text);
    }

    return undefined;
};

export const validatePromoTextIfDefined = (value: string) => {
    if (!value) {
        return undefined;
    }

    return validatePromoText(value);
};

export function composeAsyncValidators(validatorFns: any) {
    return async (values: any, dispatch: any, props: any, field: any) => {
        let errors;
        for (const validatorFn of validatorFns) {
            try {
                await validatorFn(values, dispatch, props, field);
            } catch (err) {
                errors = Object.assign(errors || {}, err);
            }
        }

        if (errors) throw errors;
    };
}
