
function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill="#FFBE3C"
                fillRule="evenodd"
                d="M24 13a4 4 0 100 8 4 4 0 000-8zm-6 4a6 6 0 1112 0 6 6 0 01-12 0z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M17.724 31.24C16.111 32.705 15 34.929 15 38a1 1 0 11-2 0c0-3.596 1.322-6.371 3.378-8.24 2.04-1.854 4.74-2.76 7.425-2.76 5.385 0 10.909 3.675 11.196 10.96a1 1 0 11-1.998.08C32.762 31.99 28.286 29 23.803 29c-2.25 0-4.451.76-6.08 2.24z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Icon;
