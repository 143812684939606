import {Reducer} from 'redux';

import {ISkill, ISkillState} from '../../definitions/skill/skill.definitions';
import {EUpdateStreamMethod} from '../../enums/updateStream/EUpdateStreamMethod';
import {isUpdateStream} from '../../utils/redux/redux.utils';
import {UPDATE_STREAM} from '../connection/connection.types';
import {SKILL_DELETE_FULFILLED, SKILL_POST_FULFILLED, SKILL_PUT_FULFILLED, SKILLS_GET_FULFILLED} from './skill.types';
import {IEntityById} from "../../definitions/generic/generic.definitions";

export const skillInitialState: ISkillState = {
    byId: {} as IEntityById<ISkill>,
    allIds: [],
};

export const skillReducer: Reducer<ISkillState> = (state = skillInitialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case SKILLS_GET_FULFILLED: {
            const payloadSkills = payload?.skills;
            if (!payloadSkills) {
                return state;
            }

            return {
                byId: payloadSkills,
                allIds: Object.keys(payloadSkills).map(Number),
            };
        }

        case SKILL_POST_FULFILLED:
        case SKILL_PUT_FULFILLED: {
            if (!payload.id || !payload.name) {
                return state;
            }

            const newByIds = {
                ...state.byId,
                [payload.id]: {
                    id: payload.id,
                    name: payload.name,
                },
            };

            return {
                byId: newByIds,
                allIds: Object.keys(newByIds).map(Number),
            };
        }

        case SKILL_DELETE_FULFILLED: {
            const {id} = payload;
            if (!id) {
                return state;
            }

            const {[id]: deleted, ...newById} = state.byId;

            return {
                byId: newById,
                allIds: Object.keys(newById).map(Number),
            };
        }

        case UPDATE_STREAM: {
            if (isUpdateStream(payload.message, 'skills', [EUpdateStreamMethod.Post, EUpdateStreamMethod.Put])) {
                const payloadSkill = payload?.message?.payload;
                if (!payloadSkill.id || !payloadSkill.name) {
                    return state;
                }

                const newByIds = {
                    ...state.byId,
                    [payloadSkill.id]: {
                        id: payloadSkill.id,
                        name: payloadSkill.name,
                    },
                };

                return {
                    byId: newByIds,
                    allIds: Object.keys(newByIds).map(Number),
                };
            }

            if (isUpdateStream(payload.message, 'skills', EUpdateStreamMethod.Delete)) {
                const {id} = payload?.message?.payload || {};
                if (!id) {
                    return state;
                }

                const {[id]: deleted, ...newById} = state.byId;

                return {
                    byId: newById,
                    allIds: Object.keys(newById).map(Number),
                };
            }
            return state;
        }
        default:
            return state;
    }
};
