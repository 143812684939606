import {FC, ReactNode} from "react";
import Typography from "@material-ui/core/Typography";
import translations from "../../../../providers/TranslationProvider/translations";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core/styles/createTheme";

const useStyles = makeStyles<Theme, { forInfiniteTableList?: boolean }>(theme => createStyles({
    root: {
        position: 'absolute',
        top: ({forInfiniteTableList}) => forInfiniteTableList ? 0 : 56,
        bottom: theme.spacing(1),
        left: theme.spacing(1),
        right: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgb(250,250,250)',
        borderRadius: theme.shape.borderRadius,
        zIndex: 1,
    },
}));

interface NoRowsOverlayProps {
    forInfiniteTableList?: boolean;
    emptyMessage?: ReactNode;
}

const NoRowsOverlay: FC<NoRowsOverlayProps> = (
    {
        forInfiniteTableList,
        emptyMessage,
    }
) => {
    const classes = useStyles({forInfiniteTableList});

    return (
        <div className={classes.root}>
            {/*<Alert severity="info">*/}
            <Typography variant={"body2"}>
                {emptyMessage || translations.no_data}
            </Typography>
        </div>
    );
};

export default NoRowsOverlay;
