import {Reducer} from 'redux';

import translations from '../../providers/TranslationProvider/translations';
import {IAgent, IAgentState, IAgentTicketsByChannel} from '../../definitions/agent/agent.definitions';
import {IEntityById} from '../../definitions/generic/generic.definitions';
import {ITicket} from '../../definitions/tickets/tickets.definitions';
import {EUpdateStreamAction} from '../../enums/updateStream/EUpdateStreamAction';
import {EUpdateStreamMethod} from '../../enums/updateStream/EUpdateStreamMethod';
import {isActorDeviceLogoutMessage, isActorLoginMessage} from '../../utils/actor/actor.utils';
import {isUpdateStream} from '../../utils/redux/redux.utils';
import {isTicketAdd, isTicketClose, isTicketReOpen, isTicketUpdate} from '../tickets/tickets.reducer.utils';
import {CHANNEL_GET_AGENTS_FULFILLED} from "../channel/channel.types";
import {ACTOR_DELETE_FULFILLED} from "../actor/actor.types";
import {UPDATE_STREAM} from "../connection/connection.types";
import {AGENT_ADD_FULFILLED, AGENTS_GET_FULFILLED} from "./agents.types";

export const agentInitialState: IAgentState = {
    byId: {} as IEntityById<IAgent>,
    allIds: [],
};

export const agentsReducer: Reducer<IAgentState> = (state = agentInitialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case UPDATE_STREAM: {
            const {message} = payload;

            if (isTicketAdd(payload) || isTicketUpdate(payload)) {
                const ticket = {...payload.message.payload.ticket};

                if (!ticket || ticket.agent_id === ticket.old_agent_id) {
                    return state;
                }

                const agentBefore = state.byId[ticket.old_agent_id];
                const agentAfter = state.byId[ticket.agent_id];

                if (!agentAfter && !agentBefore) {
                    return state;
                }

                let updatedAgents = state;

                if (agentAfter) {
                    updatedAgents = {
                        ...updatedAgents,
                        byId: {
                            ...updatedAgents.byId,
                            [agentAfter.id]: {
                                ...agentAfter,
                                tickets: updateTicket(agentAfter.tickets, ticket),
                            },
                        },
                    };
                }

                if (agentBefore) {
                    updatedAgents = {
                        ...updatedAgents,
                        byId: {
                            ...updatedAgents.byId,
                            [agentBefore.id]: {
                                ...agentBefore,
                                tickets: removeTicket(agentBefore.tickets, ticket),
                            },
                        },
                    };
                }

                return updatedAgents;
            }

            if (isTicketClose(payload) || isTicketReOpen(payload)) {
                const ticket = {...payload.message.payload.ticket};

                if (!ticket) {
                    return state;
                }

                const agentBefore = state.byId[ticket.old_agent_id];
                const agentAfter = state.byId[ticket.agent_id];
                let updatedAgents = state;

                if (agentBefore && agentAfter && agentBefore.id !== agentAfter.id) {
                    updatedAgents = {
                        ...updatedAgents,
                        byId: {
                            ...updatedAgents.byId,
                            [agentBefore.id]: {
                                ...agentBefore,
                                tickets: removeTicket(agentBefore.tickets, ticket),
                            },
                        },
                    };
                }

                // ticket is closed -> unassign it from current agent
                if (agentAfter && ticket.closed) {
                    updatedAgents = {
                        ...updatedAgents,
                        byId: {
                            ...updatedAgents.byId,
                            [agentAfter.id]: {
                                ...agentAfter,
                                tickets: removeTicket(agentAfter.tickets, ticket),
                            },
                        },
                    };
                }

                // ticket is reopened -> reassign it to previous agent
                if (agentAfter && !ticket.closed) {
                    updatedAgents = {
                        ...updatedAgents,
                        byId: {
                            ...updatedAgents.byId,
                            [agentAfter.id]: {
                                ...agentAfter,
                                tickets: updateTicket(agentAfter.tickets, ticket),
                            },
                        },
                    };
                }

                return updatedAgents;
            }

            // This is the update stream from the socket server, triggered when  you log in
            if (
                isUpdateStream(message, EUpdateStreamAction.AgentLogin) ||
                isUpdateStream(message, EUpdateStreamAction.AgentLogout)
            ) {
                const loggedInUserIds = message.payload;
                const agentsById = Object.values(state.byId).reduce(
                    (allAgents, agent) => ({
                        ...allAgents,
                        [agent.id]: {
                            ...agent,
                            logged_in: loggedInUserIds.includes(agent.id),
                        },
                    }),
                    {},
                );

                return {
                    byId: agentsById,
                    allIds: [...state.allIds],
                };
            }

            // status update
            if (isUpdateStream(message, EUpdateStreamAction.AgentStatus)) {
                const agentId = message.agent_id;
                if (state.byId[agentId]) {
                    const {status} = message.payload;
                    return {
                        byId: {
                            ...state.byId,
                            [agentId]: {
                                ...state.byId[agentId],
                                status,
                            },
                        },
                        allIds: [...state.allIds],
                    };
                }

                return state;
            }

            // update agent
            if (
                isUpdateStream(message, EUpdateStreamAction.Agent, [EUpdateStreamMethod.Put, EUpdateStreamMethod.Post])
            ) {
                const {firstname, lastname} = message.payload;

                if (message.payload && message.payload.id) {
                    const agent = {
                        ...(state.byId[message.payload.id] ? state.byId[message.payload.id] : {}),
                        ...message.payload,
                        ...(firstname && lastname ? {name: `${firstname} ${lastname}`} : {}),
                    };

                    const roleNames = getRoleNamesForAgent(agent);
                    return {
                        ...state,
                        byId: {
                            ...state.byId,
                            [agent.id]: {
                                ...agent,
                                channels: agent.channels || [],
                                roleNames,
                                searchableContent: agent.firstname?.concat(agent.lastname, agent.email, roleNames),
                            },
                        },
                    };
                }

                return state;
            }

            // remove agent
            if (isUpdateStream(message, EUpdateStreamAction.AgentDelete, EUpdateStreamMethod.Delete)) {
                const agents = {...state.byId};
                delete agents[message.payload.id];

                return {
                    byId: agents,
                    allIds: [...Object.keys(agents).map(Number)],
                };
            }

            // create agent
            if (isUpdateStream(message, EUpdateStreamAction.AgentCreate, EUpdateStreamMethod.Post)) {
                return {
                    ...state,
                    byId: {...state.byId, [message.payload.id]: parseAgentFromApi(message.payload)},
                    allIds: [...state.allIds, Number(message.payload.id)],
                };
            }

            // login from other agents
            if (isActorLoginMessage(message)) {
                const agentId = message.payload.loginId;
                if (state.byId[agentId]) {
                    return {
                        byId: {
                            ...state.byId,
                            [agentId]: {
                                ...state.byId[agentId],
                                logged_in: true,
                            },
                        },
                        allIds: [...state.allIds],
                    };
                }

                return state;
            }

            // logout from other agents
            if (isActorDeviceLogoutMessage(message)) {
                const agentId = message.payload.loginId;
                if (state.byId[agentId]) {
                    return {
                        byId: {
                            ...state.byId,
                            [agentId]: {
                                ...state.byId[agentId],
                                logged_in: false,
                            },
                        },
                        allIds: [...state.allIds],
                    };
                }

                return state;
            }

            return state;
        }

        case CHANNEL_GET_AGENTS_FULFILLED:
        case AGENTS_GET_FULFILLED: {
            // don't use channel agents if already fetched all agents
            if (type === CHANNEL_GET_AGENTS_FULFILLED && state.allIds.length) {
                return state;
            }

            const updatedAgents = parseAgentsFromApi(payload.agents);

            return {
                byId: updatedAgents,
                allIds: Object.keys(updatedAgents).map(Number),
            };
        }

        case AGENT_ADD_FULFILLED: {
            return {
                ...state,
                byId: {...state.byId, [payload.id]: parseAgentFromApi(payload)},
                allIds: [...state.allIds, Number(payload.id)],
            };
        }

        case ACTOR_DELETE_FULFILLED: {
            const byId = {...state.byId};
            delete byId[action.meta.id];

            return {
                ...state,
                byId,
                allIds: state.allIds.filter(id => id !== action.meta.id),
            };
        }

        default:
            return state;
    }
};

const getRoleNamesForAgent = (agent: IAgent): string => {
    if (!agent.roles) {
        return '';
    }

    return agent.roles
        .map(role => {
            if (translations[role]) {
                return translations[role];
            }
            return role;
        })
        .join(', ');
};

const removeTicket = (agentTickets: IAgentTicketsByChannel, ticket: ITicket): IAgentTicketsByChannel => ({
    ...agentTickets,
    [ticket.channel_id]: agentTickets
        ? Object.values(agentTickets[ticket.channel_id])
            .filter(agentTicket => agentTicket.id !== ticket.id)
            .reduce(
                (allTickets, currentTicket) => ({
                    ...allTickets,
                    [currentTicket.id]: currentTicket,
                }),
                {},
            )
        : {},
});

const updateTicket = (agentTickets: IAgentTicketsByChannel, ticket: ITicket): IAgentTicketsByChannel => {
    if (!agentTickets || !agentTickets[ticket.channel_id]) {
        return {
            ...(agentTickets ? agentTickets : {}),
            [ticket.channel_id]: {
                [ticket.id]: ticket,
            },
        };
    }

    return {
        ...agentTickets,
        [ticket.channel_id]: {
            ...agentTickets[ticket.channel_id],
            [ticket.id]: {
                ...agentTickets[ticket.channel_id][ticket.id],
                id: ticket.id,
                name: ticket.name,
                user_id: ticket.user_id,
                waiting_since: ticket.waiting_since,
            },
        },
    };
};

export const parseAgentFromApi = (agent: any): IEntityById<IAgent> => {
    const roleNames = getRoleNamesForAgent(agent);

    return {
        ...agent,
        channels: agent.channels || [],
        roleNames,
        name: agent.name || `${agent.firstname} ${agent.lastname}`.trim(),
        searchableContent: agent.firstname?.concat(agent.lastname, agent.email, roleNames),
    };
};

export const parseAgentsFromApi = (agents: any): IEntityById<IAgent> =>
    Object.values(agents as IEntityById<IAgent>).reduce((agentsById: IEntityById<IAgent>, agent: IAgent) => ({
        ...agentsById, [agent.id]: parseAgentFromApi(agent),
    }), {});

