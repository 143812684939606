export const DYNAMIC_ANSWERS_GET = 'DYNAMIC_ANSWERS_GET';
export const DYNAMIC_ANSWERS_GET_PENDING = 'DYNAMIC_ANSWERS_GET_PENDING';
export const DYNAMIC_ANSWERS_GET_FULFILLED = 'DYNAMIC_ANSWERS_GET_FULFILLED';
export const DYNAMIC_ANSWERS_GET_REJECTED = 'DYNAMIC_ANSWERS_GET_REJECTED';

export const DYNAMIC_ANSWER_POST = 'DYNAMIC_ANSWER_POST';

export const DYNAMIC_ANSWER_PUT = 'DYNAMIC_ANSWER_PUT';

export const DYNAMIC_ANSWER_DELETE = 'DYNAMIC_ANSWER_DELETE';



