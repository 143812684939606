import {IEntityByKey, IEntityStateForUuids, SubReducer} from '../../definitions/generic/generic.definitions';
import {IPreparedNote, TNoteActions} from '../../definitions/notes/notes.definitions';
import {
    isDeleteNoteUpdateStreamMessage,
    isPostNoteUpdateStreamMessage,
    isPutNoteUpdateStreamMessage,
} from '../../utils/guards/guards.utils';
import {UPDATE_STREAM} from '../connection/connection.types';
import {createEntityReducer} from '../entity/entity.reducer';
import {
    NOTES_DELETE_PENDING,
    NOTES_DELETE_REJECTED,
    NOTES_GET_FULFILLED,
    NOTES_GET_PENDING,
    NOTES_GET_REJECTED,
    NOTES_POST_FULFILLED,
    NOTES_PUT_PENDING,
    NOTES_PUT_REJECTED,
} from './notes.types';

const getNewState = (state: IEntityStateForUuids<IPreparedNote>, byId: IEntityByKey<IPreparedNote>) => {
    return {
        ...state,
        byId,
        ids: Object.keys(byId).map(String),
    };
};

const customNoteEntityReducer: SubReducer<IEntityStateForUuids<IPreparedNote>, TNoteActions> = (
    state,
    action: TNoteActions,
) => {
    if (!state) {
        return state;
    }

    switch (action.type) {
        case UPDATE_STREAM: {
            const {
                payload: {message},
            } = action;

            if (isPostNoteUpdateStreamMessage(message) || isPutNoteUpdateStreamMessage(message)) {
                const byId = {
                    ...state.byId,
                    [String(message.payload.id)]: {...message.payload},
                };

                return getNewState(state, byId);
            }

            if (isDeleteNoteUpdateStreamMessage(message)) {
                const {[message.payload.id]: _, ...byId} = state.byId;

                return getNewState(state, byId);
            }

            return state;
        }
        case NOTES_POST_FULFILLED: {
            const byId = {
                ...state.byId,
                [String(action.payload.id)]: {...action.payload},
            };

            return getNewState(state, byId);
        }
        case NOTES_DELETE_PENDING: {
            const {[action.meta.id]: _, ...byId} = state.byId;

            return getNewState(state, byId);
        }
        case NOTES_PUT_PENDING: {
            const byId = {
                ...state.byId,
                [String(action.meta.note.id)]: {...action.meta.note},
            };

            return getNewState(state, byId);
        }
        case NOTES_DELETE_REJECTED: {
            const byId = {
                ...state.byId,
                [String(action.meta.id)]: {...action.meta.oldNote},
            };

            return getNewState(state, byId);
        }
        case NOTES_PUT_REJECTED: {
            const byId = {
                ...state.byId,
                [String(action.meta.oldNote.id)]: {...action.meta.oldNote},
            };

            return getNewState(state, byId);
        }
    }
    return state;
};

export const notesEntityReducer = createEntityReducer<IPreparedNote, IEntityStateForUuids<IPreparedNote>, TNoteActions>(
    'notes',
    [NOTES_GET_PENDING, NOTES_GET_FULFILLED, NOTES_GET_REJECTED],
    customNoteEntityReducer,
);
