import {ICategory, ITemplateTranslations, ITranslation} from '@sinch-engage/whatsapp-message-template-editor';
import {Reducer} from 'redux';
import {
    ITemplateCategories,
    ITemplatesItems,
    ITemplatesState,
} from '../../../definitions/notificationTemplate/notificationTemplate.definitions';
import {UPDATE_STREAM} from '../../connection/connection.types';
import {
    SETTINGS_CREATE_NOTIFICATION_TEMPLATE_FULFILLED,
    SETTINGS_CREATE_NOTIFICATION_TEMPLATE_PENDING,
    SETTINGS_CREATE_NOTIFICATION_TEMPLATE_REJECTED,
    SETTINGS_DELETE_NOTIFICATION_TEMPLATE_FULFILLED,
    SETTINGS_DELETE_NOTIFICATION_TEMPLATE_PENDING,
    SETTINGS_DELETE_NOTIFICATION_TEMPLATE_REJECTED,
    SETTINGS_GET_NOTIFICATION_CATEGORIES_FULFILLED,
    SETTINGS_GET_NOTIFICATION_CATEGORIES_REJECTED,
    SETTINGS_GET_NOTIFICATION_TEMPLATE_FULFILLED,
    SETTINGS_GET_NOTIFICATION_TEMPLATES_FULFILLED,
    SETTINGS_GET_NOTIFICATION_TEMPLATES_PENDING,
    SETTINGS_GET_NOTIFICATION_TEMPLATES_REJECTED,
    SETTINGS_REVIEW_NOTIFICATION_TEMPLATE_FULFILLED,
    SETTINGS_REVIEW_NOTIFICATION_TEMPLATE_PENDING,
    SETTINGS_REVIEW_NOTIFICATION_TEMPLATE_REJECTED,
    SETTINGS_UPDATE_NOTIFICATION_TEMPLATE_FULFILLED,
    SETTINGS_UPDATE_NOTIFICATION_TEMPLATE_PENDING,
    SETTINGS_UPDATE_NOTIFICATION_TEMPLATE_REJECTED,
} from './notificationTemplates.types';
import {getCategoryDescription, getCategoryName} from './notificationTemplates.selectors';

export const notificationTemplatesInitialState: ITemplatesState = {
    ids: [],
    items: {},
    categories: {},
    fetching: false,
    saving: false,
    error: false,
    lastUpdated: 0,
};

export const notificationTemplatesReducer: Reducer<ITemplatesState> = (
    state = notificationTemplatesInitialState,
    action,
) => {
    const {type, payload, meta} = action;

    switch (type) {
        case SETTINGS_GET_NOTIFICATION_TEMPLATES_PENDING:
            return {...state, error: false, fetching: true, lastUpdated: Date.now()};

        case SETTINGS_GET_NOTIFICATION_TEMPLATES_FULFILLED: {
            const items: ITemplatesItems = {};
            const ids: number[] = [];

            payload.forEach((item: any) => {
                ids.push(item.id);
                items[item.id] = {
                    ...item,
                    translations: translationsToLanguageKeyMap(item), // use this in production !!!
                    // translations: undefined, // to test loading template details one by one (connectivity layer)
                };
            });

            return {
                ...state,
                items: items,
                ids: ids,
                fetching: false,
                lastUpdated: Date.now(),
            };
        }

        case SETTINGS_GET_NOTIFICATION_TEMPLATE_FULFILLED:
            return {
                ...state,
                items: {
                    ...state.items,
                    [payload.id]: {
                        ...state.items[payload.id],
                        ...payload,
                        translations: translationsToLanguageKeyMap(payload),
                    },
                },
            };

        case SETTINGS_CREATE_NOTIFICATION_TEMPLATE_PENDING:
        case SETTINGS_UPDATE_NOTIFICATION_TEMPLATE_PENDING:
        case SETTINGS_REVIEW_NOTIFICATION_TEMPLATE_PENDING:
        case SETTINGS_DELETE_NOTIFICATION_TEMPLATE_PENDING:
            return {...state, error: false, saving: true};

        case SETTINGS_GET_NOTIFICATION_CATEGORIES_REJECTED:
            return {...state, error: true};

        case SETTINGS_GET_NOTIFICATION_TEMPLATES_REJECTED:
            return {...state, error: true, fetching: false};

        case SETTINGS_CREATE_NOTIFICATION_TEMPLATE_REJECTED:
        case SETTINGS_UPDATE_NOTIFICATION_TEMPLATE_REJECTED:
        case SETTINGS_REVIEW_NOTIFICATION_TEMPLATE_REJECTED:
        case SETTINGS_DELETE_NOTIFICATION_TEMPLATE_REJECTED:
            return {...state, error: true, saving: undefined};

        case SETTINGS_CREATE_NOTIFICATION_TEMPLATE_FULFILLED:
        case SETTINGS_UPDATE_NOTIFICATION_TEMPLATE_FULFILLED:
        case SETTINGS_REVIEW_NOTIFICATION_TEMPLATE_FULFILLED: {
            const newItem = {
                ...payload,
                translations: translationsToLanguageKeyMap(payload),
            };

            return {
                ...state,
                items: {...state.items, [newItem.id]: newItem},
                ids: state.ids.includes(newItem.id) ? [...state.ids, newItem.id] : state.ids,
                saving: false,
            };
        }

        case SETTINGS_DELETE_NOTIFICATION_TEMPLATE_FULFILLED: {
            const newState = {...state};

            delete newState.items[meta.id];
            newState.ids = newState.ids.filter(id => id !== meta.id);
            newState.saving = false;

            return newState;
        }

        case SETTINGS_GET_NOTIFICATION_CATEGORIES_FULFILLED:
            return {
                ...state,
                categories: payload.reduce(
                    (accumulator: ITemplateCategories, category: string) => ({
                        ...accumulator,
                        [category]: {
                            name: category,
                            label: getCategoryName(category),
                            description: getCategoryDescription(category),
                        } as ICategory,
                    }),
                    {},
                ),
            };

        case UPDATE_STREAM:
            break;
        default:
            break;
    }
    return state;
};

const translationsToLanguageKeyMap = (payload: any) => payload.translations?.reduce(
    (acc: ITemplateTranslations, translation: ITranslation) => ({
        ...acc,
        [translation.language]: translation,
    }),
    {},
);
