
function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill="#272727"
                fillRule="evenodd"
                d="M36.8 11H11.2c-.59 0-1.2.448-1.2 1v24c0 .552.61 1 1.2 1h25.6c.59 0 1.2-.448 1.2-1V12c0-.552-.61-1-1.2-1zM11.2 9C9.433 9 8 10.343 8 12v24c0 1.657 1.433 3 3.2 3h25.6c1.767 0 3.2-1.343 3.2-3V12c0-1.657-1.433-3-3.2-3H11.2z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#007171"
                d="M30 14a1 1 0 00-1 1v18a1 1 0 001 1h4a1 1 0 001-1V15a1 1 0 00-1-1h-4zM21 22a1 1 0 011-1h4a1 1 0 011 1v11a1 1 0 01-1 1h-4a1 1 0 01-1-1V22zM13 27a1 1 0 011-1h4a1 1 0 011 1v6a1 1 0 01-1 1h-4a1 1 0 01-1-1v-6z"
            ></path>
        </svg>
    );
}

export default Icon;


