import {FC} from 'react';

import InputAdornment from '@material-ui/core/InputAdornment';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            alignSelf: 'flex-end',
            marginBottom: 16,
        },
    }),
);

interface IInputAdornmentEndProps {
    className?: string;
}

const InputAdornmentEnd: FC<IInputAdornmentEndProps> = props => {
    const {className, ...restProps} = props;
    const classes = useStyles();

    return (
        <InputAdornment position="end" className={classNames(classes.root, className && className)} {...restProps} />
    );
};

export default InputAdornmentEnd;
