export enum ETicketTableColumns {
    USERNAME = 'USERNAME',
    MESSENGER = 'MESSENGER',
    WAITING_SINCE = 'WAITING_SINCE',
    CLOSED_SINCE = 'CLOSED_SINCE',
    TICKET_ID = 'TICKET_ID',
    POSTPONED = 'POSTPONED',
    AGENT = 'AGENT',
    TAGS = 'TAGS',
    MOOD = 'MOOD',
    LAST_CHAT = 'LAST_CHAT',
    LAST_CHAT_DATE = 'LAST_CHAT_DATE',
    PREVIEW = 'PREVIEW',
    CUSTOM_FIELD = 'CUSTOM_FIELD',
}
