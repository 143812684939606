import {useCallback} from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useDispatch} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';

import translations from '../../../../../../providers/TranslationProvider/translations';
import {EBaseRoutes, EProfileRoutes} from '../../../../../../enums/routes/ERoutes';
import {changeDrawerState, hideContent, showMenu} from '../../../../../../redux/ui/ui.actions';
import {DrawerMenuItem} from '../../DrawerMenuItem';
import {Theme} from '@material-ui/core/styles/createTheme';
import BrandedIconSinch from '../../../../../../components/BrandedIconsSinch/BrandedIconSinch';
import {matchPath} from "../../../../../../utils/routes/routes.utils";

export const UserMenuProfileButton = () => {
    const dispatch = useDispatch();
    const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'));
    const location = useLocation();
    const highlight = matchPath(location.pathname, EBaseRoutes.Profile);

    const handleCloseDrawer = useCallback(() => {
        dispatch(changeDrawerState(false));
        if (isSmallDevice) {
            dispatch(hideContent());
            dispatch(showMenu());
        }
    }, [dispatch, isSmallDevice]);

    return (
        <Link to={EProfileRoutes.Password} onClick={handleCloseDrawer}>
            <DrawerMenuItem
                highlight={!!highlight}
                name={translations.profile}
                icon={<BrandedIconSinch menuIcon='User' />}
                isSubItem
            />
        </Link>
    );
};
