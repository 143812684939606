import {Reducer} from 'redux';

import {IAutoroute, IAutorouteState} from '../../definitions/autoroute/autoroute.definitions';
import {IEntityById} from '../../definitions/generic/generic.definitions';
import {EUpdateStreamMethod} from '../../enums/updateStream/EUpdateStreamMethod';
import {isUpdateStream} from '../../utils/redux/redux.utils';
import {UPDATE_STREAM} from '../connection/connection.types';
import {AUTOROUTES_GET_FULFILLED} from './autoroute.types';

export const autorouteInitialState: IAutorouteState = {
    byId: {} as IEntityById<IAutoroute>,
    allIds: [],
};

export const autorouteReducer: Reducer<IAutorouteState> = (state = autorouteInitialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case AUTOROUTES_GET_FULFILLED: {
            const {autoroutes: payloadAutoroutes} = payload;
            if (!payloadAutoroutes || Object.keys(payloadAutoroutes).length === 0) {
                return state;
            }

            const autoroutes = Object.values(payloadAutoroutes).reduce(
                (allAutoroutes: IEntityById<IAutoroute>, autoroute: any) => ({
                    ...allAutoroutes,
                    [autoroute.id]: parsePayloadData(autoroute),
                }),
                {},
            );

            return {
                byId: autoroutes as IEntityById<IAutoroute>,
                allIds: Object.keys(autoroutes).map(Number),
            };
        }

        case UPDATE_STREAM: {
            if (
                isUpdateStream(payload.message, 'skills_autoroutes', [EUpdateStreamMethod.Post]) ||
                isUpdateStream(payload.message, 'skills_autoroutes', [EUpdateStreamMethod.Put])
            ) {
                const {autoroutes: tmpAutoroutes} = payload?.message?.payload || {};

                if(!tmpAutoroutes){
                    return state;
                }

                const payloadAutoroutes = Object.values(tmpAutoroutes);
                if (!payloadAutoroutes || Object.keys(payloadAutoroutes).length === 0) {
                    return state;
                }

                const newAutoroutes = JSON.parse(JSON.stringify(state.byId));
                payloadAutoroutes.forEach((autoroute: any) => {
                    newAutoroutes[autoroute.id] = parsePayloadData(autoroute);
                });

                return {
                    byId: newAutoroutes as IEntityById<IAutoroute>,
                    allIds: Object.keys(newAutoroutes).map(Number),
                };
            }

            if (isUpdateStream(payload.message, 'skills_autoroutes', [EUpdateStreamMethod.Delete])) {
                const {id} = payload?.message?.payload || {};
                if (!id) {
                    return state;
                }

                const {[id]: deleted, ...newById} = state.byId;

                return {
                    byId: newById as IEntityById<IAutoroute>,
                    allIds: Object.keys(newById).map(Number),
                };
            }
            break;
        }

        default:
            break;
    }

    return state;
};

const parsePayloadData = (autoroute: any) => ({
    id: autoroute.id,
    skillsId: autoroute.skills_id,
    keywords: autoroute.keywords,
    position: autoroute.position,
});
