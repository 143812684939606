import {useSelector} from 'react-redux';
import {IRequestReducerData, TActionTypes} from '../definitions/request/requestsState.definitions';
import {isActionTypesMap} from '../utils/guards/guards.utils';

import {IState} from './root.reducer';

export const useParamSelector = (selector: any, props: any, ...args: any[]) =>
    useSelector((state: IState) => selector(state, props, ...args));

export const isTypePending = (type: string) => type.endsWith('_PENDING');
export const isTypeFulfilled = (type: string) => type.endsWith('_FULFILLED');
export const isTypeRejected = (type: string) => type.endsWith('_REJECTED');
export const isTypeReset = (type: string) => type.endsWith('_RESET');

export const getItemsFromPayloadByStateKey = (payload: any, stateKey: string) => {
    switch (stateKey) {
        case 'tickets':
            return payload.tickets || [];
        case 'users':
            return payload.user || [];
        case 'botTopics':
            return Object.values(payload.topics || {}) || [];
        case 'notes':
            return Object.values(payload) || [];
        case 'jobs':
            return payload.job ? [payload.job] : payload.jobs;
        case 'products':
            return payload.products || [];
        default:
            if (Array.isArray(payload)) {
                return payload;
            }
            console.warn(
                `Payload is not an array and state key ${stateKey} is not defined in getItemsFromPayloadByStateKey.`,
            );
            return payload[stateKey] ?? {};
    }
};

export const getTotalFromPayloadByStateKey = (payload: any, stateKey: string) => {
    switch (stateKey) {
        case 'tickets':
            return Number(payload.count);
        case 'users':
            return Number(payload.total);
        case 'botTopics':
            return Number(Object.values(payload.topics || {}).length);
        default:
            if (Array.isArray(payload)) {
                return payload.length;
            }
            console.warn(
                `Payload is not an array and state key ${stateKey} is not defined in getTotalFromPayloadByStateKey.`,
            );
            return (payload[stateKey] ?? []).length;
    }
};

export const getRequestReducerData = (actionType: string, actionTypes: TActionTypes): IRequestReducerData => {
    const irrelevantData: IRequestReducerData = {
        isRelevant: false,
        key: '',
    };

    if (Array.isArray(actionTypes) && actionTypes.includes(actionType)) {
        return {
            isRelevant: true,
            key: 'get',
        };
    }
    if (Array.isArray(actionTypes) && !actionTypes.includes(actionType)) {
        return irrelevantData;
    }
    // just so typescript knows its way around
    if (!isActionTypesMap(actionTypes)) {
        return irrelevantData;
    }

    return Object.keys(actionTypes).reduce((result: IRequestReducerData, key: string) => {
        const currentActionTypes = actionTypes[key];

        if (currentActionTypes?.includes(actionType)) {
            return {
                isRelevant: true,
                key,
            };
        }

        return result;
    }, irrelevantData);
};
