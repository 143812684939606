import {FC} from 'react';

import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {TypographyProps} from '@material-ui/core/Typography';
import classNames from 'classnames';

const animationDuration = 250;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            padding: '8px 0 8px 16px !important', // MIU override
        },
        highlighted: {
            color: theme.palette.primary.main,
            borderLeft: `4px solid ${theme.palette.primary.main}`,
            paddingLeft: '12px !important', // MIU override
        },
        default: {},
        listItemIcon: {
            transition: `margin ${animationDuration}ms ease-in-out`,
            marginRight: 0,
            minWidth: 0,
            height: 48,
            width: 48,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 6,
        },
        listItemText: {
            padding: '0 8px',
        },
        listItemTextPrimary: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        subListItem: {
            paddingLeft: 20,
            overflowX: 'hidden',

            '& svg': {
                fontSize: 20,
            },
        },
        subListItemIcon: {
            transition: `margin ${animationDuration}ms ease-in-out`,
            minWidth: 0,
        },
        subListItemIconExtended: {
            marginRight: 20,
        },
        subListItemTextPrimary: {
            paddingLeft: 8,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    }),
);

interface IMenuItemProps {
    icon: any;
    name?: string;
    onClick?: () => void;
    isSubItem?: boolean;
    highlight?: boolean;
}

export const DrawerMenuItem: FC<IMenuItemProps> = props => {
    const {name, icon, onClick, isSubItem, highlight} = props;
    const classes = useStyles();

    const highlightClasses = {root: classes.highlighted};
    const defaultClasses = {root: classes.default};

    const iconClasses = isSubItem ? classNames(classes.subListItemIcon) : classNames(classes.listItemIcon);

    const primaryTypographyProps: TypographyProps | undefined = isSubItem ? {variant: 'body1'} : {variant: 'h4'};

    return (
        <ListItem
            button
            className={isSubItem ? classes.subListItem : classes.listItem}
            classes={highlight ? highlightClasses : defaultClasses}
            onClick={onClick}
        >
            <ListItemIcon className={iconClasses}>{icon}</ListItemIcon>
            {name && (
                <ListItemText
                    className={classes.listItemText}
                    classes={{primary: isSubItem ? classes.subListItemTextPrimary : classes.listItemTextPrimary}}
                    primaryTypographyProps={primaryTypographyProps}
                >
                    <Box overflow='hidden' textOverflow='ellipsis' component='span'>
                        {name}
                    </Box>
                </ListItemText>
            )}
        </ListItem>
    );
};
