import Chip from '@material-ui/core/Chip';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import {FC, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import TicketAvatar from '../../../../components/TicketAvatar/TicketAvatar';
import {ITicket} from '../../../../definitions/tickets/tickets.definitions';
import {isChannelAnonymized} from '../../../../redux/channel/channels.selectors';
import {showNameOrNumber} from '../../../../utils/global/global.utils';
import {AlertLevel, getMessageTimesAndAlertStatus} from '../../../../utils/ticket/ticket.utils';
import Label from './Label';
import {NECTARY_COLORS} from '../../../../theme/muiTheme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-start',

            [theme.breakpoints.down('xs')]: {
                maxWidth: 160,
            },
        },
        [AlertLevel.light]: {
            overflow: 'hidden',
            backgroundColor: NECTARY_COLORS.honey500,
        },
        [AlertLevel.strong]: {
            overflow: 'hidden',
            backgroundColor: NECTARY_COLORS.raspberry500,
            color: NECTARY_COLORS.snow100,
        },
        chipActive: {
            backgroundColor: NECTARY_COLORS.success500,
            color: NECTARY_COLORS.snow100,

            '&:hover, &:focus': {
                backgroundColor: `${NECTARY_COLORS.success700} !important`,
            },
        },
        chipLabel: {
            width: 'calc(100% - 32px)',
            paddingRight: 0,
            lineHeight: 0,
            '& > div': {
                lineHeight: '10px',
            },
        },
    }),
);

interface TicketChipProps {
    ticket: ITicket;
    index: number;
    overdueTime: number;
    criticalTime: number;
    activeTicketId: number;
    handlePopoverOpen: (event: any, ticket: ITicket) => void;
    handlePopoverClose: () => void;
    handleClick: (ticket: ITicket) => void;
}

export const TicketChip: FC<TicketChipProps> = (
    {
        ticket,
        handleClick,
        overdueTime,
        criticalTime,
        activeTicketId,
        handlePopoverOpen,
        handlePopoverClose,
    },
) => {
    const [, setMinute] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setMinute(value => value + 1);
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    const classes = useStyles();
    const channelIsAnonymized = useSelector(isChannelAnonymized);
    const onMouseOver = (event: any) => handlePopoverOpen(event, ticket);
    const timingAndAlert = getMessageTimesAndAlertStatus(ticket, overdueTime, criticalTime);

    const chipClassName =
        activeTicketId && ticket.id === activeTicketId
            ? classNames(classes.chip, classes.chipActive)
            : classNames(classes.chip, classes[timingAndAlert.alertLevel]);

    const contactName = showNameOrNumber(ticket, channelIsAnonymized, ticket.messenger);

    return (
        <Chip
            className={chipClassName}
            avatar={<TicketAvatar ticket={ticket} classes={classes} width={32} height={32} />}
            label={<Label ticket={ticket} contactName={contactName} timingAndAlert={timingAndAlert} />}
            onMouseEnter={onMouseOver}
            onMouseLeave={handlePopoverClose}
            onFocus={onMouseOver}
            onBlur={handlePopoverClose}
            onClick={() => handleClick(ticket)}
            classes={{
                label: classes.chipLabel,
            }}
        />
    );
};

export default TicketChip;
