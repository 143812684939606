
function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M19 11a6 6 0 100 12 6 6 0 000-12zm-4 6a4 4 0 118 0 4 4 0 01-8 0z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#fff"
                d="M10 38c0-3.071 1.112-5.295 2.723-6.76 1.628-1.48 3.83-2.24 6.08-2.24 4.483 0 8.959 2.991 9.198 9.04a1 1 0 101.998-.08C29.712 30.676 24.188 27 18.803 27c-2.685 0-5.385.906-7.425 2.76C9.322 31.629 8 34.404 8 38a1 1 0 102 0z"
            ></path>
            <path
                fill="#FFBE3C"
                d="M30.296 11.457A6 6 0 0028 11v2a4 4 0 010 8v2a6 6 0 002.296-11.543zM32 29.804v-2.158c3.731 1.352 6.793 4.82 7 10.316a1 1 0 11-2 .075c-.16-4.26-2.296-6.982-5-8.233z"
            ></path>
        </svg>
    );
}

export default Icon;
