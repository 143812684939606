import {ENotificationLevel} from '../../enums/notifications/ENotificationLevel';
import {createSnackNotification} from './notifications.utils';
import {NOTIFICATION_ADD, NOTIFICATION_DELETE} from "./notifications.types";


export const addNotification = (message: string, level: ENotificationLevel, persist = false, id?: number | string) => ({
    type: NOTIFICATION_ADD,
    payload: createSnackNotification(message, level, persist, id),
});

export const resetError = (id: string | number) => ({
    type: NOTIFICATION_DELETE,
    payload: id,
});

export const addSuccessNotification = (message: string, persist = false, id?: number | string) =>
    addNotification(message, ENotificationLevel.Success, persist, id);

export const addWarningNotification = (message: string, persist = false, id?: number | string) =>
    addNotification(message, ENotificationLevel.Warning, persist, id);
export const addErrorNotification = (message: string, persist = false, id?: number | string) =>
    addNotification(message, ENotificationLevel.Error, persist, id);
