import {
    CreateShopConfigParams,
    DeleteCartParams,
    ProductDetailsParams,
    SearchProductParams,
    UpdateCartParams,
} from '../definitions/settings/shop/shop.definitions';
import {request} from './Api/Api';

const SHOP_URL = 'shop';
const SHOP_CONFIG_URL = `${SHOP_URL}/config`;
const SHOP_PRODUCT_URL = `${SHOP_URL}/product`;
const SHOP_CART_URL = `${SHOP_URL}/cart`;
const SHOP_ORDERS_URL = `${SHOP_URL}/orders`;

export const createShopConfigRequest = (params: CreateShopConfigParams) =>
    request({method: 'POST', url: SHOP_CONFIG_URL}, params);
export const readShopConfigRequest = () => request({method: 'GET', url: SHOP_CONFIG_URL});
export const deleteShopRequest = (shop?: string) => request({method: 'DELETE', url: SHOP_CONFIG_URL}, shop);

export const searchProductRequest = (params: SearchProductParams) =>
    request({method: 'GET', url: SHOP_PRODUCT_URL}, params);
export const getProductDetailsRequest = (id: string, params: ProductDetailsParams) => request({method: 'GET', url: `${SHOP_PRODUCT_URL}/${id}`}, params);

export const getCartRequest = (user_id: number) => request({method: 'GET', url: SHOP_CART_URL}, {user_id});
export const deleteCartRequest = (params: DeleteCartParams) => request({method: 'DELETE', url: SHOP_CART_URL}, params);
export const updateCartRequest = (params: UpdateCartParams) => request({method: 'PUT', url: SHOP_CART_URL}, params);

export const getOrdersRequest = (user_id: number, language: string) => request({method: 'GET', url: SHOP_ORDERS_URL}, {user_id, language});
