
function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill="#272727"
                fillRule="evenodd"
                d="M37.643 6.083c.953.142 2.045.523 2.743 1.222.298.298.46.688.557 1.02.103.353.166.754.2 1.175.07.844.034 1.867-.097 2.945-.26 2.142-1.085 4.626-2.173 6.53-1.086 1.902-2.941 3.741-4.46 5.07a39.759 39.759 0 01-2.408 1.944v8.464a2 2 0 01-.867 1.648l-6.38 4.386a2 2 0 01-3.073-1.163l-1.583-6.333-6.108-6.109L7.66 25.3a2 2 0 01-1.163-3.073l4.386-6.38a2 2 0 011.648-.866h7.95a30.882 30.882 0 012.054-2.578c1.405-1.58 3.408-3.479 5.6-4.351 1.991-.793 4.324-1.575 6.444-1.897 1.06-.16 2.112-.214 3.064-.071zM19.163 16.98H12.53l-4.386 6.379 5.91 1.477 5.107-7.856zm-3.378 8.865l5.334 5.334 9.312-6.518.002-.002.01-.006.038-.027.154-.111a37.704 37.704 0 002.462-1.975c1.481-1.296 3.126-2.957 4.04-4.556.912-1.597 1.684-3.797 1.924-5.78.12-.985.145-1.867.09-2.54a4.046 4.046 0 00-.127-.776.96.96 0 00-.072-.188c-.302-.292-.936-.54-1.604-.64-.684-.102-1.52-.072-2.469.071-1.897.288-4.065 1.006-6.004 1.778-1.739.692-3.485 2.292-4.847 3.822a28.839 28.839 0 00-2.04 2.582l-.113.164-.027.041-.006.01h-.001l-6.056 9.317zm14.22 1.555l-7.863 5.505 1.484 5.934 6.379-4.386V27.4z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#007171"
                d="M30 18.984a2 2 0 100-4 2 2 0 000 4zM12.583 38.532a3.5 3.5 0 10-4.193-5.605c-1.548 1.158-2.205 4.904-2.205 6.903 0 .501 4.85-.14 6.398-1.298z"
            ></path>
        </svg>
    );
}

export default Icon;
