// all KPIs must be the order that is display on the statistics page
export enum EChartAutomationKpi {
    AutomationRatioTicketsWithChatBlocks = 'automation_ratio_tickets_with_chat_blocks',
    AutomationRatioTicketsWithChatBot = 'automation_ratio_tickets_with_chat_bot',
    AutomationRatioAutomatedMessages = 'automation_ratio_automated_messages',
    AutomationRatioAutoRoutedTickets = 'automation_ratio_auto_routed_tickets',
    AutomationTimeSavingAutorouting = 'automation_time_saving_autorouting',
    AutomationTimeSavingChatBlocks = 'automation_time_saving_chat_blocks',
    AutomationTimeSavingOutgoingMessages = 'automation_time_saving_outgoing_messages',
}
