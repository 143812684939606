import {ITargeting} from '../../../definitions/mia/mia.definitions';
import {IState} from '../../root.reducer';

export const sortTargetingsByName = (t1: ITargeting, t2: ITargeting) => {
    return t2.id === 0 || t1.name.toLocaleLowerCase() > t2.name.toLocaleLowerCase() ? 1 : -1;
};

export const getTargetingsSortedByName = (state: IState) => {
    return Object
        .values(state.mia.targeting.byId || {})
        .sort(sortTargetingsByName);
};