export const CUSTOM_FIELDS_GET = 'CUSTOM_FIELDS_GET';
export const CUSTOM_FIELDS_GET_PENDING = 'CUSTOM_FIELDS_GET_PENDING';
export const CUSTOM_FIELDS_GET_FULFILLED = 'CUSTOM_FIELDS_GET_FULFILLED';
export const CUSTOM_FIELDS_GET_REJECTED = 'CUSTOM_FIELDS_GET_REJECTED';

export const CUSTOM_FIELD_POST = 'CUSTOM_FIELD_POST';
export const CUSTOM_FIELD_POST_FULFILLED = 'CUSTOM_FIELD_POST_FULFILLED';

export const CUSTOM_FIELD_PUT = 'CUSTOM_FIELD_PUT';
export const CUSTOM_FIELD_PUT_FULFILLED = 'CUSTOM_FIELD_PUT_FULFILLED';

export const CUSTOM_FIELD_DELETE = 'CUSTOM_FIELD_DELETE';
export const CUSTOM_FIELD_DELETE_FULFILLED = 'CUSTOM_FIELD_DELETE_FULFILLED';

