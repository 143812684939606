import {request} from '../../../requests/Api/Api';
import {IConnectChannelPayload} from './ImessageOnboardingReducer';

const URL = '/messenger/im/';

export const getAllConnectableChannels = (business_id: string) => request({
    method: 'GET',
    url: `${URL}setup?id=${business_id}`,
});

export const postConnectImessageToChannel = (payload: IConnectChannelPayload) => request({
    method: 'POST',
    url: `${URL}setup`,
}, payload);


