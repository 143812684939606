export const TEXT_MODULES_GET = 'TEXT_MODULES_GET';
export const TEXT_MODULES_GET_PENDING = 'TEXT_MODULES_GET_PENDING';
export const TEXT_MODULES_GET_FULFILLED = 'TEXT_MODULES_GET_FULFILLED';
export const TEXT_MODULES_GET_REJECTED = 'TEXT_MODULES_GET_REJECTED';

export const TEXT_MODULE_POST = 'TEXT_MODULE_POST';
export const TEXT_MODULE_POST_FULFILLED = 'TEXT_MODULE_POST_FULFILLED';

export const TEXT_MODULE_PUT = 'TEXT_MODULE_PUT';
export const TEXT_MODULE_PUT_FULFILLED = 'TEXT_MODULE_PUT_FULFILLED';

export const TEXT_MODULE_DELETE = 'TEXT_MODULE_DELETE';
export const TEXT_MODULE_DELETE_FULFILLED = 'TEXT_MODULE_DELETE_FULFILLED';

