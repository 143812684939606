import {FC, ReactElement, useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {LanguageNativeNames} from '../../config';

import {TLanguageCode} from '../../definitions/translations/translations.definitions';
import {getActorLanguage} from '../../redux/actor/actor.selectors';
import {getTranslation} from '../../redux/translations/translations.actions';
import {changeServiceUnavailable} from '../../redux/ui/ui.actions';
import {isLoadingLanguage} from "../../redux/translations/translations.selectors";
import {getLanguageFromStorage, updateLanguageInStorage} from "../../utils/storage/storage.utils";

export const MCP_TRANSLATION_MODULE = 'global';
export const DEFAULT_TRANSLATION_MODULE = 'sinch_engage';

/**
 * Language Select:
 * 1) If language from actor state is defined (initial actor language is 'en')
 * 2) If language is set in local storage, use it
 * 3) If query param 'language' is set and is valid -> use language from query param
 * 4) If Actor is logged out and no query param is set -> use browser language
 */
export const selectPreferredLanguage = (actorLanguage?: TLanguageCode): TLanguageCode => {
    // check given actor language
    if (actorLanguage) {
        return actorLanguage;
    }

    // check query language
    const urlParams = new URLSearchParams(window.location.search);
    const queryLanguage = urlParams.get('language');

    if (queryLanguage && Object.keys(LanguageNativeNames).includes(queryLanguage)) {
        return queryLanguage as TLanguageCode;
    }

    // check local storage
    const languageFromLocalStorage = getLanguageFromStorage();

    if (languageFromLocalStorage && Object.keys(LanguageNativeNames).includes(languageFromLocalStorage)) {
        return languageFromLocalStorage as TLanguageCode;
    }

    // check browser language
    const browserLanguage = window?.navigator?.language?.split('-')[0];

    if (browserLanguage && Object.keys(LanguageNativeNames).includes(browserLanguage)) {
        return browserLanguage as TLanguageCode;
    }

    return 'en';
};

interface ITranslationProviderProps {
    loadingContent?: ReactElement;
    module?: string;
}

const TranslationProvider: FC<ITranslationProviderProps> = (
    {
        children,
        loadingContent,
        module = DEFAULT_TRANSLATION_MODULE,
    }
) => {
    const dispatch = useDispatch();
    const actorLanguage = useSelector(getActorLanguage);

    // preselect language either
    const language = selectPreferredLanguage(actorLanguage);
    const loading = useSelector(isLoadingLanguage);

    useEffect(() => {
        // only load if we have language to load and are not loading it already
        if (!language) {
            return;
        }

        // set language in storage to load the correct actor language before logging in
        updateLanguageInStorage(language);

        Promise.resolve(dispatch(getTranslation(module, language))).catch(() => {
            dispatch(changeServiceUnavailable(true));
        });
    }, [dispatch, language, module]);

    if (loading) {
        return loadingContent || null;
    }

    return <>{children}</>;
};

export default TranslationProvider;
