import {createStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import {FC, useState} from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import {useTypedSelector} from '../../utils/hooks/useTypedSelector';
import CustomAlert from '../CustomAlert/CustomAlert';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import translations from '../../providers/TranslationProvider/translations';
import {useSelector} from "react-redux";
import {isLoadingLanguage} from "../../redux/translations/translations.selectors";

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            zIndex: 9999999,
            position: 'fixed',
            bottom: theme.spacing(1),
            right: theme.spacing(1),
            maxWidth: theme.spacing(38),
        },
        transition: {
            '&-enter': {
                opacity: 0,
                transform: 'scale(0.7)',
            },
            '&-enter-active': {
                opacity: 1,
                transition: 'opacity 200ms, transform 200ms',
                transform: 'scale(1)',
            },
            '&-exit': {
                opacity: 1,
                transform: 'scale(0.7)',
            },
            '&-exit-active': {
                opacity: 0,
                transition: 'opacity 200ms, transform 200ms',
                transform: 'scale(1)',
            },
        },
    }),
);

const UpdateAppButton: FC = () => {
    const classes = useStyles();
    const isUpdateAvailable = useTypedSelector(state => state.ui.updateAvailable);
    const [isUpdating, setIsUpdating] = useState(false);

    // don't show while loading language, otherwise it doesn't update after translation loaded
    const showUpdateAppButton = !useSelector(isLoadingLanguage) && isUpdateAvailable;

    const handleUpdate = () => {
        setIsUpdating(true);
        // eslint-disable-next-line no-console
        console.debug("call skipWaiting from button");
        navigator.serviceWorker.ready.then(registration => {
            if (!registration.waiting) {
                // TODO: inform user to retry
                // eslint-disable-next-line no-console
                console.debug("SW: No controller is waiting for skipWaiting");
                return setIsUpdating(false);
            }

            // send skip waiting to service worker to install itself and reload the page
            registration.waiting.postMessage('skipWaiting');
        });
    };

    return (
        <CSSTransition in={showUpdateAppButton} timeout={500} classNames={classes.transition} mountOnEnter
                       unmountOnExit>
            <Paper className={classes.root} elevation={3}>
                <OverlayLoader isLoading={isUpdating}>
                    <CustomAlert variant="success" customTitle={translations.app_update_available}>
                        <Typography paragraph>{translations.click_here_to_update_app}</Typography>
                        <Button variant="outlined" color="inherit" onClick={handleUpdate}>
                            {translations.update_app}
                        </Button>
                    </CustomAlert>
                </OverlayLoader>
            </Paper>
        </CSSTransition>
    );
};

export default UpdateAppButton;
