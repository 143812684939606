import {BASE_URL} from '../config';
import {request} from './Api/Api';
import {HttpMethod} from './baseRequest';
import {IPartialWidget, IWidget} from '../definitions/settings/widgetEditor/widgetEditor.definitions';

const WIDGET_URL = 'widget_info/{id}';
const WIDGET_SETTTINGS_URL = 'widget_info/settings/{id}';

/**
 * the @param widgetUniqueId is a valid apikey
 *
 * @param widgetUniqueId
 * @returns {Promise<unknown>}
 */
export const getWidgetByIdRequest = (widgetUniqueId: string) =>
    fetch(`${BASE_URL}/widget_info?apikey=${widgetUniqueId}`, {
        method: HttpMethod.GET,
    })
        .then(response => Promise.resolve(response.json()))
        .then(result => {
            if (!result.widgets) {
                throw Error(result.reason ?? 'error');
            }
            return {
                widget: result.widgets[widgetUniqueId],
                channelName: result.channel_name || '',
            };
        });

export const getWidgetRequest = (id: number) => request({method: 'GET', url: WIDGET_URL}, {id});
export const postWidgetRequest = (data: Partial<IWidget>) => request({method: 'POST', url: WIDGET_URL}, data);
export const putWidgetRequest = (data: IPartialWidget) => request({method: 'PUT', url: WIDGET_URL}, data);
export const deleteWidgetRequest = (data: Pick<IWidget, 'id'>) => request({method: 'DELETE', url: WIDGET_URL}, data);


export const getWidgetPossibleSettingsRequest = (language: string) =>
    request({method: 'GET', url: WIDGET_SETTTINGS_URL}, {language});

// noinspection JSCheckFunctionSignatures

export const sendViberMessageRequest = (phoneNumber: string, apiKey: string) => {
    const formData = new FormData();
    formData.append('phone', phoneNumber);

    return fetch(`${BASE_URL}/user/subscribe`, {
        method: HttpMethod.POST,
        body: formData,
        headers: {
            Authorization: `Bearer ${apiKey}`,
        },
    }).then(response => Promise.resolve(response.json()));
};

export const getViberTokenRequest = (widgetUniqueId: string) =>
    fetch(`${BASE_URL}/widget_info/token?apikey=${widgetUniqueId}`, {
        method: HttpMethod.GET,
    }).then(response => Promise.resolve(response.json()));

export const sendRcsMessageRequest = (phoneNumber: string, widgetIdAsApiKey: string) => {
    const formData = new FormData();
    formData.append('number', phoneNumber);

    return fetch(`${BASE_URL}/user/rcs`, {
        method: HttpMethod.POST,
        headers: {
            Authorization: `Bearer ${widgetIdAsApiKey}`,
        },
        body: formData,
    }).then(response => response.status);
};