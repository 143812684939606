import isPromise from 'is-promise';

import {reportErrorRequest} from "../../requests/Api/Api";
import {logout} from "../actor/actor.actions";
import {AGENT_ME_URL} from "../../requests/agentRequests";
import translations from "../../providers/TranslationProvider/translations";

// // monkey patch console.error for reporting (only in production)
// if (window.location.hostname.startsWith('app.messengerpeople.com')) {
//     const consoleError = console.error;
//
//     console.error = (...data: any[]) => {
//         const message = typeof data[0] !== 'string' ? JSON.stringify(data[0]) : data[0];
//
//         try {
//             reportErrorRequest({
//                 type: 'Console Error',
//                 uri: window.location.href,
//                 message,
//                 trace: JSON.stringify(data),
//             });
//         } catch(e) {
//             console.warn("Error reporting failed.", e);
//         }
//
//         consoleError(...data);
//     };
// }

export const errorMiddleware = ({dispatch}: any) => (next: any) => (action: any) => {
    // ℹ️ only handle specific async actions
    // ℹ️ translation request errors are handled in TranslationProvider
    if (isPromise(action.payload)) {
        return next(action).catch((error: any) => {
            console.error(error);

            const status = error?.status || error?.response?.status;

            // logout on 440 Login expired error
            if (status === 440) {
                return dispatch(logout());
            }

            // show alert message if account is blocked (login succeeded but agent/me fails with 404)
            if (error?.config?.url === AGENT_ME_URL && status === 404) {
                alert(translations.account_blocked);
                return dispatch(logout());
            }

            try {
                // dont report status 4xx
                if (status && (status < 400 && status >= 500)) {
                    reportErrorRequest({
                        type: 'Request Error',
                        uri: `${error?.config?.baseURL}/${error?.config?.url}`,
                        message: error?.data?.reason || `Error occurred in ${action.type}`,
                        trace: JSON.stringify(error),
                    });
                }
            } catch (e) {
                console.warn("Error reporting failed.", e);
            }

            // show error notification (see default case in notifications.reducer.ts)
            throw(error);
        });
    }

    return next(action);
};
