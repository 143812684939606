import {useCallback, useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment/moment';
import {useDispatch, useSelector} from 'react-redux';
import ContentSection from '../../components/ContentSection/ContentSection';
import CurrentChannelPicture from '../../components/CurrentChannelPicture/CurrentChannelPicture';
import CustomSwitch from '../../components/CustomInputs/CustomSwitch/CustomSwitch';
import FormRow from '../../components/FormRow/FormRow';
import PaperTemplate from '../../components/PaperTemplate/PaperTemplate';
import PaperTitleBar from '../../components/PaperTitleBar/PaperTitleBar';
import translations from '../../providers/TranslationProvider/translations';
import Twemoji from '../../components/Twemoji/Twemoji';
import {EAgentStatus} from '../../enums/agent/EAgentStatus';
import {setActorStatus} from '../../redux/actor/actor.actions';
import {getActor} from '../../redux/actor/actor.selectors';
import ModularContent from "../../components/ModularContent/ModularContent";
import {useTypedSelector} from "../../utils/hooks/useTypedSelector";
import ReactHtmlParser from "react-html-parser";
import {useSlugWithMarkup} from "../../utils/hooks/useSlugWithMarkup";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        welcomeText: {
            //marginTop: theme.spacing(2),
            //marginBottom: theme.spacing(3),
            //marginLeft: 16,
        },
        switchBase: {
            color: theme.palette.primary.main,
        },
        switchBar: {
            backgroundColor: theme.palette.primary.main,
        },
        switchBaseChecked: {
            color: theme.palette.warning.main,
        },
        switchBarChecked: {
            backgroundColor: theme.palette.warning.main,
        },
    }),
);

const START_DAY = 11;
const START_AFTERNOON = 16;
const START_EVENING = 19;
const GREETING_UPDATE_INTERVAL = 60000;

const DashboardWelcome = () => {
    const [greeting, setGreeting] = useState('');
    const actor = useSelector(getActor);
    const classes = useStyles();
    const dispatch = useDispatch();
    const userIsAbsent = actor.status === EAgentStatus.Absent;
    const welcomeMessage = useTypedSelector(state => state.channel.dashboard_welcome_text);

    const toggleActorStatus = () => {
        const newUserStatus = actor.status === EAgentStatus.Absent ? EAgentStatus.Online : EAgentStatus.Absent;
        dispatch(setActorStatus(newUserStatus));
    };

    const getGreetingByTime = () => {
        const currentHour = parseFloat(moment().format('HH'));

        if (currentHour >= START_DAY && currentHour < START_AFTERNOON) {
            return translations.good_day;
        }
        if (currentHour >= START_AFTERNOON && currentHour < START_EVENING) {
            return translations.good_afternoon;
        }
        if (currentHour >= START_EVENING) {
            return translations.good_evening;
        }
        return translations.good_morning;
    };

    const updateGreeting = useCallback(() => {
        const currentGreeting = getGreetingByTime();
        setGreeting(currentGreeting);
    }, []);

    useEffect(() => {
        if (greeting === '') {
            updateGreeting();
        }

        const interval = setInterval(() => {
            updateGreeting();
        }, GREETING_UPDATE_INTERVAL);

        return () => clearInterval(interval);
    }, [greeting, updateGreeting]);

    return (
        <PaperTemplate stretch titleBar={<PaperTitleBar title={translations.welcome}/>}>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={9} sm={6}>
                    <div>
                        <div className={classes.welcomeText}>
                            <Typography variant="h6" color="inherit" gutterBottom>
                                <Box fontWeight={300}>
                                    <Twemoji>{`${greeting} ${actor.firstname}!`}</Twemoji>
                                </Box>
                            </Typography>
                            <Typography variant="body2" color="inherit" gutterBottom component={'div'}>
                                {ReactHtmlParser(useSlugWithMarkup(welcomeMessage?.slug || ''))}
                            </Typography>
                        </div>
                    </div>
                    {Boolean(actor) && (
                        <ModularContent hideForSimpleChat>
                            <ContentSection headline={translations.status} titleGutter={0.5}>
                                <Typography variant={'body2'} gutterBottom>
                                    {translations.agent_status_description}
                                </Typography>
                                <FormRow switchesAndCheckboxes>
                                    <FormControlLabel
                                        control={
                                            <CustomSwitch
                                                onClick={toggleActorStatus}
                                                checked={userIsAbsent}
                                                color="default"
                                                classes={
                                                    userIsAbsent
                                                        ? {
                                                            switchBase: classes.switchBaseChecked,
                                                            track: classes.switchBarChecked,
                                                        }
                                                        : {
                                                            switchBase: classes.switchBase,
                                                            track: classes.switchBar,
                                                        }
                                                }
                                            />
                                        }
                                        label={userIsAbsent ? translations.not_available : translations.available}
                                    />
                                </FormRow>
                            </ContentSection>
                        </ModularContent>
                    )}
                </Grid>
                <Grid item xs={3} sm={5}>
                    <CurrentChannelPicture/>
                </Grid>
            </Grid>
        </PaperTemplate>
    );
};

export default DashboardWelcome;
