import {Reducer} from 'redux';

import {EUpdateStreamAction} from '../../enums/updateStream/EUpdateStreamAction';
import {isUpdateStream} from '../../utils/redux/redux.utils';
import {
    SOCKET_CONNECTED,
    SOCKET_DISCONNECTED,
    SOCKET_RECONNECT,
    SOCKET_RESET_ACTION,
    UPDATE_STREAM,
} from './connection.types';

export interface IConnectionState {
    connected: boolean;
    deviceId?: string;
    lastActionTimestamp?: number;
    performAction?: 'connect' | 'disconnect';
}

export const initialConnectionState: IConnectionState = {
    connected: false,
};

export const connectionReducer: Reducer<IConnectionState> = (state = initialConnectionState, action) => {
    const {type, payload} = action;

    switch (type) {
        case SOCKET_RECONNECT:
            return {...state, performAction: 'connect'};
        case SOCKET_RESET_ACTION:
            return {...state, performAction: undefined};
        case SOCKET_CONNECTED:
            return {...state, connected: true, deviceId: payload};
        case SOCKET_DISCONNECTED:
            return {...state, connected: false, deviceId: undefined};
        case UPDATE_STREAM: {
            if (isUpdateStream(payload.message, EUpdateStreamAction.SignOfLife)) {
                return {
                    ...state,
                    lastActionTimestamp: Date.now(),
                };
            }
            break;
        }
        default:
            break;
    }

    return state;
};
