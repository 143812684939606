
function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path fill="#fff" d="M23.5 19a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"/>
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M24.45 21.69a1 1 0 00-1.9 0l-7.5 23a1 1 0 001.9.62l.73-2.238 10.121-4.656 2.248 6.894a1 1 0 001.902-.62l-7.5-23zm-5.925 18.792l1.585-4.862 5.461 1.62-7.046 3.242zm3.605-6.348l4.733 1.404-1.118-3.429-3.615 2.025zm-.963-1.753l3.946-2.21-1.613-4.945-2.333 7.155z"
                clipRule="evenodd"
            />
            <path
                fill="#FFBE3C"
                d="M10 17.5c0-2.915.924-5.614 2.495-7.821.32-.45.954-.507 1.38-.155.425.353.48.981.165 1.436A11.447 11.447 0 0012 17.5c0 2.43.754 4.684 2.04 6.54.314.455.26 1.083-.166 1.436-.425.352-1.059.295-1.379-.154A13.438 13.438 0 0110 17.5zM33.126 25.476c.425.352 1.059.295 1.379-.154A13.438 13.438 0 0037 17.5c0-2.915-.924-5.614-2.495-7.821-.32-.45-.954-.507-1.38-.155-.425.353-.48.981-.165 1.436A11.447 11.447 0 0135 17.5c0 2.43-.754 4.684-2.04 6.54-.314.455-.26 1.083.166 1.436z"
            />
            <path
                fill="#FFBE3C"
                d="M30.046 22.924c.425.352 1.06.296 1.366-.164A9.456 9.456 0 0033 17.5a9.456 9.456 0 00-1.51-5.14c-.341-.53-1.068-.595-1.554-.192-.365.302-.41.84-.152 1.237A7.465 7.465 0 0131 17.5c0 1.46-.417 2.823-1.139 3.975-.293.468-.24 1.096.185 1.449zM26.856 20.28c.486.403 1.218.34 1.513-.219a5.476 5.476 0 00.631-2.56c0-.925-.228-1.797-.631-2.562-.295-.558-1.027-.622-1.513-.22-.365.303-.404.841-.199 1.268.22.458.343.971.343 1.513 0 .542-.123 1.055-.343 1.513-.205.427-.166.965.199 1.267zM20.382 19.092c.194.378.157.861-.17 1.132-.523.434-1.314.365-1.621-.241A5.477 5.477 0 0118 17.5c0-.924.228-1.796.631-2.56.295-.559 1.027-.623 1.513-.22.365.302.404.84.199 1.267-.22.458-.343.971-.343 1.513 0 .573.138 1.114.382 1.592zM17.139 21.475c.293.468.24 1.096-.185 1.449-.425.352-1.06.296-1.366-.164A9.456 9.456 0 0114 17.5c0-1.894.554-3.659 1.51-5.14.341-.53 1.068-.595 1.554-.192.365.302.41.84.152 1.237A7.465 7.465 0 0016 17.5c0 1.46.417 2.823 1.139 3.975z"
            />
        </svg>
    );
}

export default Icon;
