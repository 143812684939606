import moment from 'moment';
import {AnyAction, Reducer} from 'redux';
import translations from '../../providers/TranslationProvider/translations';
import {ITranslationsState} from '../../definitions/translations/translations.definitions';
import {TRANSLATION_GET_FULFILLED, TRANSLATION_GET_PENDING, TRANSLATION_GET_REJECTED} from './translations.types';

export const translationsInitialState: ITranslationsState = {};

export const translationReducer: Reducer<ITranslationsState> = (
    state = translationsInitialState,
    action: AnyAction,
) => {
    switch (action.type) {
        case TRANSLATION_GET_PENDING: {
            return {
                ...state,
                languageLoading: action.meta.language,
            };
        }
        case TRANSLATION_GET_FULFILLED: {
            const newLanguage = action.payload.language;
            const newTranslation = action.payload.translation;

            if (!newLanguage) {
                return state;
            }

            translations.setLanguage(newLanguage);
            translations.setContent({[newLanguage]: newTranslation});

            // load new language for moment
            moment.locale(newLanguage);

            return {
                ...state,
                languageLoaded: newLanguage,
                [newLanguage]: {...newTranslation},
            };
        }
        case TRANSLATION_GET_REJECTED: {
            return {
                ...state,
                languageLoading: undefined,
            };
        }
        default:
            return state;
    }
};
