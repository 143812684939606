import {IEntityByKey, IEntityStateForUuids, SubReducer} from '../../definitions/generic/generic.definitions';
import {ITicket} from '../../definitions/tickets/tickets.definitions';
import {UPDATE_STREAM} from '../connection/connection.types';
import {createEntityReducer} from '../entity/entity.reducer';
import {createHashedRequestReducer} from '../request/request.reducer';
import {
    TICKET_CLOSE_FULFILLED,
    TICKET_PREVIEW_GET,
    TICKET_PUT_LABELS_TO_TICKETS_PENDING,
    TICKET_UPDATE_NOTE_FULFILLED,
    TICKETS_GET_FULFILLED,
    TICKETS_GET_PENDING,
    TICKETS_GET_REJECTED,
} from './tickets.types';
import {handleTicketRelatedUpdateStream, updateListItem, updateUserName} from './tickets.reducer.utils';
import {isUserUpdate} from "../user/user.reducer";

export const INTERIM_MESSAGE_ID = 1e50; // 50x9 => 999999...we should be safe for a while with this approach

const customTicketEntityReducer: SubReducer<IEntityStateForUuids<ITicket>> = (state, action) => {
    const {type, payload} = action;

    switch (type) {
        case TICKET_CLOSE_FULFILLED:
            return updateListItem(state, {...payload.ticket});

        case TICKET_UPDATE_NOTE_FULFILLED: {
            return updateListItem(state, {...payload.ticket});
        }
        case TICKET_PUT_LABELS_TO_TICKETS_PENDING: {
            const {ticketIds, labelIds} = action.meta;

            const newByIds: IEntityByKey<ITicket> = ticketIds.reduce(
                (previous: IEntityByKey<ITicket>, currentId: string) => {
                    const currentTicket = state.byId[currentId];

                    const labels = currentTicket.labels
                        ? currentTicket.labels.filter(id => !labelIds.includes(id))
                        : [];

                    const newLabels = [...labels, ...labelIds];

                    return {...previous, [currentId]: {...currentTicket, labels: newLabels}};
                },
                state.byId,
            );

            return {...state, byId: newByIds};
        }
        default:
    }

    if (type === UPDATE_STREAM) {
        if (isUserUpdate(payload, 'name')) {
            return updateUserName(state, payload);
        }

        return handleTicketRelatedUpdateStream(state, payload, updateListItem);
    }

    return state;
};

export const ticketsEntityReducer = createEntityReducer<ITicket>(
    'tickets',
    [TICKETS_GET_PENDING, TICKETS_GET_REJECTED, TICKETS_GET_FULFILLED],
    customTicketEntityReducer,
);

export const ticketPreviewsEntityReducer = createEntityReducer<ITicket>('tickets', [
    `${TICKET_PREVIEW_GET}_PENDING`,
    `${TICKET_PREVIEW_GET}_REJECTED`,
    `${TICKET_PREVIEW_GET}_FULFILLED`,
]);

export const ticketsRequestReducer = createHashedRequestReducer('tickets', [
    TICKETS_GET_PENDING,
    TICKETS_GET_REJECTED,
    TICKETS_GET_FULFILLED,
]);
