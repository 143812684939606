
function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill="#272727"
                d="M40.761 11.657L7.085 20.52a.114.114 0 00-.017.216l9.857 4.413-1.855 1.36-8.82-3.948a2.115 2.115 0 01.326-3.975l34.355-9.04a1.506 1.506 0 011.884 1.587L41.03 31.653c-.02.23-.083.453-.186.658a5.471 5.471 0 01-7.129 2.547l-12.391-5.549 1.855-1.36 11.354 5.083a3.47 3.47 0 004.508-1.586l1.72-19.789z"
            ></path>
            <path
                fill="#007171"
                d="M24.854 30.89L19 37.394v-8.863L37.06 16.33a1 1 0 00-1.12-1.658L17 27.47v9.925c0 1.833 2.26 2.7 3.487 1.338l6.285-6.983-1.918-.86z"
            ></path>
        </svg>
    );
}

export default Icon;
