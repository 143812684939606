export const MESSENGER_MENU_ITEMS_GET = 'MESSENGER_MENU_ITEMS_GET';

export const MESSENGER_MENU_ITEMS_GET_FULFILLED = 'MESSENGER_MENU_ITEMS_GET_FULFILLED';
export const MESSENGER_MENU_ITEMS_POST = 'MESSENGER_MENU_ITEMS_POST';

export const MESSENGER_MENU_ITEMS_POST_FULFILLED = 'MESSENGER_MENU_ITEMS_POST_FULFILLED';
export const MESSENGER_MENU_ITEMS_PUT = 'MESSENGER_MENU_ITEMS_PUT';

export const MESSENGER_MENU_ITEMS_PUT_FULFILLED = 'MESSENGER_MENU_ITEMS_PUT_FULFILLED';
export const MESSENGER_MENU_ITEMS_DELETE = 'MESSENGER_MENU_ITEMS_DELETE';

export const MESSENGER_MENU_ITEMS_DELETE_FULFILLED = 'MESSENGER_MENU_ITEMS_DELETE_FULFILLED';
