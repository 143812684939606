import {Route, RouteProps} from 'react-router';

import ERights from '../../enums/actor/ERights';
import AccessControl from '../AccessControl/AccessControl';
import ContentEmptyMessage from '../ContentEmptyMessage/ContentEmptyMessage';
import translations from '../../providers/TranslationProvider/translations';
import {EBaseRoutes} from "../../enums/routes/ERoutes";

export interface ICustomRouteProps extends RouteProps {
    requiredRights?: ERights | ERights[];
    matchIfAny?: boolean;
}

export const CustomRoute = (props: ICustomRouteProps) => {
    const {requiredRights, matchIfAny, ...rest} = props;

    return (
        <AccessControl
            requiredRights={requiredRights}
            matchIfAny={matchIfAny}
            renderNoAccess={() => {
                return <ContentEmptyMessage message={translations['no_access']} redirect={EBaseRoutes.Dashboard}/>;
            }}
        >
            <Route {...rest} />
        </AccessControl>
    );
};
