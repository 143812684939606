import {Reducer} from 'redux';
import {
    IFacebookPage,
    IGetFacebookPagePayload,
    TFacebookActions,
    TFacebookGetStartedText,
} from '../../../definitions/settings/facebook/facebook.actions.definitions';
import {EFacebookParameters, IFacebookError} from '../../../requests/facebookRequest';
import {
    FACEBOOK_GET_ACTIVE_PAGE_DATA_FULFILLED,
    FACEBOOK_GET_ACTIVE_PAGE_DATA_PENDING,
    FACEBOOK_GET_ACTIVE_PAGE_DATA_REJECTED,
    FACEBOOK_GET_CONNECTED_PAGE_FULFILLED,
    FACEBOOK_GET_CONNECTED_PAGE_PENDING,
    FACEBOOK_GET_CONNECTED_PAGE_REJECTED,
    FACEBOOK_GET_PAGES_FULFILLED,
    FACEBOOK_GET_PAGES_PENDING,
    FACEBOOK_GET_PAGES_REJECTED,
    FACEBOOK_PAGE_SET_FULFILLED,
    FACEBOOK_PAGE_SET_PENDING,
    FACEBOOK_PAGE_SET_REJECTED,
    FACEBOOK_RESET,
    FACEBOOK_RESET_ERROR,
    FACEBOOK_SET_ERROR,
    FACEBOOK_SET_IS_PAGE_SELECTED,
    FACEBOOK_SET_SELECTED_PAGE,
} from './facebook.types';

export interface IFacebookState {
    selectedPage: number;
    error: any;
    isPageSelected: boolean;
    pages: IFacebookPage[];
    connectedPage: IGetFacebookPagePayload | null;
    loading: boolean | string;
    messenger_profile: {
        loading?: boolean;
        error?: IFacebookError;
        getStarted?: string;
    };
    whatsapp_business_token_permissions: any;
}

export const initFacebookState: IFacebookState = {
    selectedPage: 0,
    error: null,
    isPageSelected: false,
    pages: [],
    connectedPage: null,
    loading: false,
    messenger_profile: {},
    whatsapp_business_token_permissions: {},
};

// the error message from api will already be shown as a badge and is not available here.
// We can only acknowledge the error here -> this sort of helps identifying where the error came from within the component
// to debug check error.middleware.js
export const errorOriginatedFromRestMessengerPeople = 'errorOriginatedFromRestMessengerPeople';

export const facebookReducer: Reducer<IFacebookState, TFacebookActions> = (state = initFacebookState, action) => {
    switch (action.type) {
        case FACEBOOK_GET_CONNECTED_PAGE_PENDING:
            return {...state, loading: true};
        case FACEBOOK_GET_CONNECTED_PAGE_REJECTED:
            return {...state, loading: false};
        case FACEBOOK_GET_CONNECTED_PAGE_FULFILLED: {
            return {
                ...state,
                loading: false,
                connectedPage: {...action.payload} as IGetFacebookPagePayload,
            };
        }
        case FACEBOOK_GET_PAGES_PENDING:
            return {...state, loading: true};
        case FACEBOOK_GET_PAGES_REJECTED:
            return {...state, loading: false};
        case FACEBOOK_GET_PAGES_FULFILLED: {
            return {
                ...state,
                error: null,
                loading: false,
                pages: [...action.payload.data],
            };
        }
        case FACEBOOK_SET_SELECTED_PAGE:
            return {
                ...state,
                selectedPage: Number(action.payload),
            };
        case FACEBOOK_SET_IS_PAGE_SELECTED:
            return {
                ...state,
                isPageSelected: Boolean(action.payload),
            };
        case FACEBOOK_RESET:
            return {
                ...initFacebookState,
            };
        case FACEBOOK_PAGE_SET_PENDING:
            return {
                ...state,
                connectedPage: {
                    ...state.connectedPage,
                    facebook_error: '',
                    get_started: action.meta.parameters.get_started,
                } as IGetFacebookPagePayload,
                loading: errorOriginatedFromRestMessengerPeople,
            };
        case FACEBOOK_PAGE_SET_REJECTED: {
            const {error, ...rest} = initFacebookState;
            const rejectedState = {
                ...rest,
                error: true,
            };
            return {
                ...state,
                ...rejectedState,
            };
        }
        case FACEBOOK_PAGE_SET_FULFILLED:
            if (action.payload.facebook_error) {
                return {
                    ...state,
                    connectedPage: {
                        ...state.connectedPage,
                        facebook_error: action.payload.facebook_error.message,
                        get_started: action.meta.parameters.get_started ? 0 : 1,
                    } as IGetFacebookPagePayload,
                    loading: false,
                };
            }
            return {
                ...state,
                connectedPage: {
                    ...state.connectedPage,
                    ...action.payload,
                } as IGetFacebookPagePayload,
                messenger_profile: {
                    ...state.messenger_profile,
                    error: undefined,
                },
                loading: false,
            };
        case FACEBOOK_SET_ERROR: {
            return {
                ...state,
                error: action.payload,
            };
        }
        case FACEBOOK_RESET_ERROR: {
            return {
                ...state,
                error: null,
            };
        }
        case FACEBOOK_GET_ACTIVE_PAGE_DATA_PENDING: {
            return {
                ...state,
                messenger_profile: {
                    ...state.messenger_profile,
                    loading: true,
                    error: undefined,
                },
            };
        }
        case FACEBOOK_GET_ACTIVE_PAGE_DATA_REJECTED: {
            return {
                ...state,
                messenger_profile: {
                    ...state.messenger_profile,
                    error: action.payload as IFacebookError,
                    loading: false,
                },
            };
        }
        case FACEBOOK_GET_ACTIVE_PAGE_DATA_FULFILLED: {
            const {payload} = action;
            // check for success
            if (payload.error) {
                return {
                    ...state,
                    messenger_profile: {
                        ...state.messenger_profile,
                        error: {
                            ...payload.error,
                        } as IFacebookError,
                        loading: false,
                    },
                };
            }

            return {
                ...state,
                messenger_profile: {
                    ...state.messenger_profile,
                    getStarted: payload.data?.[0]?.[EFacebookParameters.getStarted]?.payload as TFacebookGetStartedText,
                    error: undefined,
                    loading: false,
                },
            };
        }
        default:
            break;
    }

    return state;
};
