import classNames from 'classnames';
import {FC, useEffect, useState} from 'react';
import './Loader.css';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';

export const useStyles = makeStyles(() =>
    createStyles({
        root: {
            position: 'relative',
            flexShrink: 0,
            flexGrow: 0,
            margin: 'auto',
            transform: 'rotate(5deg)',
            overflow: 'hidden',

            '& > div': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',

                // sizer
                '& > div': {
                    overflow: 'hidden',
                    borderBottomLeftRadius: 999,
                    borderBottomRightRadius: 999,

                    // circle
                    '& > div': {
                        borderWidth: '15px',
                        borderStyle: 'solid',
                        borderRadius: 9999,
                    },
                },
            },
        },
        animationStart: {
            animation: 'spinner_spin 0.6s infinite cubic-bezier(.4,.27,.29,.34)',
        },
        animationEnd: {
            animation: 'physics_inspired_breaking_motion 0.6s linear',
        },
        circle: {
            borderRadius: 9999,
            border: '15px solid',
        },
        bg_circle: {
            position: 'absolute',
            background: 'white',
            borderRadius: 9999,
        },
        inner_circle: {
            position: 'absolute',
            background: 'white',
            borderRadius: 99999,
            transform: 'rotate(240deg)',
        },
        circle_green: {
            borderColor: '#4aae20',
        },
        circle_orange: {
            borderColor: '#ff9300',
        },
        circle_red: {
            borderColor: '#e44436',
        },
        circle_purple: {
            borderColor: '#cd3292',
        },
        circle_blue: {
            borderColor: '#0076ba',
        },
        layer0: {
            transform: 'rotate(0deg)',
        },
        layer1: {
            transform: 'rotate(72deg)',
        },
        layer2: {
            transform: 'rotate(144deg)',
        },
        layer3: {
            transform: 'rotate(216deg)',
        },
        layer4: {
            transform: 'rotate(288deg)',
        },
    }),
);

interface ILoaderSpinnerProps {
    proportions?: number;
    loading?: boolean;
}

export const LOADER_SPINNER_DEFAULT_SIZE = 40;

export const LoaderSpinner: FC<ILoaderSpinnerProps> = ({proportions = LOADER_SPINNER_DEFAULT_SIZE, loading = true}) => {
    const classes = useStyles();
    const [animationClass, setAnimationClass] = useState<string>();

    useEffect(() => {
        if (loading) {
            setAnimationClass(classes.animationStart);
        }
    }, [loading, classes.animationStart]);

    // smoothly stop the animation
    const stopAnimation = () => {
        if (!loading) {
            setAnimationClass(classes.animationEnd);
        }
    };

    // size calculations
    const size = {
        height: proportions,
        width: proportions,
        // minHeight: proportions,
        // minWidth: proportions;
    };
    const sizerSize = {
        height: 0.6 * proportions,
        width: 0.4 * proportions,
    };
    const sizeColored = {
        height: proportions,
        width: proportions,
        borderWidth: 0.125 * proportions,
    };
    const sizerSizeLast = {
        height: 0.58 * proportions,
        width: (0.39 * proportions) / 2,
    };

    return (
        <div onAnimationIteration={stopAnimation} className={classNames(classes.root, animationClass)} style={size}>
            <div className={classes.layer0}>
                <div style={sizerSize}>
                    <div className={classes.circle_green} style={sizeColored} />
                </div>
            </div>
            <div className={classes.layer1}>
                <div style={sizerSize}>
                    <div className={classes.circle_orange} style={sizeColored} />
                </div>
            </div>
            <div className={classes.layer2}>
                <div style={sizerSize}>
                    <div className={classes.circle_red} style={sizeColored} />
                </div>
            </div>
            <div className={classes.layer3}>
                <div style={sizerSize}>
                    <div className={classes.circle_purple} style={sizeColored} />
                </div>
            </div>
            <div className={classes.layer4}>
                <div style={sizerSize}>
                    <div className={classes.circle_blue} style={sizeColored} />
                </div>
            </div>
            <div>
                <div style={sizerSizeLast}>
                    <div className={classes.circle_green} style={sizeColored} />
                </div>
            </div>
        </div>
    );
};

export default LoaderSpinner;
