import {Reducer} from 'redux';
import {IEntityByKey} from '../../../definitions/generic/generic.definitions';

import {IConfig, TRuleOperator} from '../../../definitions/targeting/targeting.definitions';
import {TARGETING_GET_CONFIGURATION_FULFILLED} from "../targeting.types";

export interface ITargetingVariables {
    [key: string]: IConfig;
}

export interface ITargetingRuleOperators {
    [key: string]: TRuleOperator[];
}

export interface IConfigState {
    variables: ITargetingVariables;
    operators: ITargetingRuleOperators;
}

export const configInitialState: IConfigState = {
    variables: {},
    operators: {},
};

export const configReducer: Reducer<IConfigState> = (state = configInitialState, action) => {
    switch (action.type) {
        case TARGETING_GET_CONFIGURATION_FULFILLED: {
            const config = action.payload;

            const variablesById = {} as ITargetingVariables;
            const operatorsById = {} as ITargetingRuleOperators;

            config.forEach((item: any) => {
                const {operators, ...rest} = item;

                // special case for "category" aka Mia Topcis. We need them by index, otherwise the query builder would
                // store the category labels instead of the index 0-9
                if (item.id === 'category') {
                    rest.values = rest.values.reduce(
                        (result: IEntityByKey<string>, label: string, index: number) => ({
                            ...result,
                            [index.toString()]: label,
                        }),
                        {},
                    );
                }

                variablesById[String(item.id)] = rest;
                operatorsById[String(item.id)] = operators;
            });

            return {
                ...state,
                variables: variablesById,
                operators: operatorsById,
            };
        }
        default:
            return state;
    }
};
