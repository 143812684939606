import {FC, MutableRefObject, ReactNode} from 'react';

import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
        },
        root_row: {
            margin: `${theme.spacing(1)}px -${theme.spacing(0.5)}px ${theme.spacing(2)}px -${theme.spacing(0.5)}px`,

            '& > *': {
                marginLeft: [theme.spacing(0.5), '!important'],
                marginRight: [theme.spacing(0.5), '!important'],
            },
            '&:first-child': {
                marginTop: 0,
            },
            '&:last-child': {
                marginBottom: (props: IFormRowProps) => (props.nested ? theme.spacing(1) : 0),
            },
        },
        root_switchesAndCheckboxes: {
            flexDirection: 'column',
            margin: 0,
            marginBottom: theme.spacing(2),
        },
        root_noTopGap: {
            marginTop: 0,
        },
        root_noBottomGap: {
            marginBottom: 0,
        },
        root_leftAlign: {
            justifyContent: 'flex-start',
        },
        root_rightAlign: {
            justifyContent: 'flex-end',
        },
        root_centerItems: {
            alignItems: 'center',
            justifyContent: 'center',
        },
        root_equalWidths: {
            '& > *': {
                flex: 1,
            },
        },
        root_hidden: {
            // don't use {display: "none"} here, because the shrink label won't display correctly on show
            overflow: 'hidden',
            visibility: 'hidden',
            maxHeight: 0,
        },
    }),
);

interface IFormRowProps {
    children: ReactNode;
    className?: string;
    switchesAndCheckboxes?: true;
    disableTopGap?: true;
    disableBottomGap?: true;
    leftAlign?: true;
    rightAlign?: true;
    equalWidths?: true;
    centerItems?: true;
    hidden?: boolean;
    divRef?: MutableRefObject<any | null>;
    nested?: boolean;
}

const FormRow: FC<IFormRowProps> = props => {
    const {
        className,
        switchesAndCheckboxes,
        disableBottomGap,
        disableTopGap,
        leftAlign,
        rightAlign,
        equalWidths,
        centerItems,
        hidden,
        children,
        divRef,
    } = props;

    const classes = useStyles(props);

    const combinedClasses = classNames(
        classes.root,
        !switchesAndCheckboxes ? classes.root_row : classes.root_switchesAndCheckboxes,
        disableTopGap && classes.root_noTopGap,
        disableBottomGap && classes.root_noBottomGap,
        leftAlign && !equalWidths && classes.root_leftAlign,
        rightAlign && classes.root_rightAlign,
        equalWidths && classes.root_equalWidths,
        centerItems && classes.root_centerItems,
        hidden && classes.root_hidden,
        className,
    );

    return (
        <div ref={divRef} className={combinedClasses}>
            {children}
        </div>
    );
};

export default FormRow;
