import {blueGrey, common} from '@material-ui/core/colors';
import {ThemeOptions} from '@material-ui/core/styles/createTheme';

import GilroyRegularWoff from '../fonts/gilroy/Gilroy-Regular.woff';
import GilroyRegularWoff2 from '../fonts/gilroy/Gilroy-Regular.woff2';
import GilroySemiBoldWoff from '../fonts/gilroy/Gilroy-SemiBold.woff';
import GilroySemiBoldWoff2 from '../fonts/gilroy/Gilroy-SemiBold.woff2';
import GilroyBoldWoff from '../fonts/gilroy/Gilroy-Bold.woff';
import GilroyBoldWoff2 from '../fonts/gilroy/Gilroy-Bold.woff2';

export interface IPaletteTheme {
    type?: 'dark' | 'light';
    primary?: string;
    secondary?: string;
    text?: string;
}

export const NECTARY_COLORS = {
    honey700: '#CC8800',
    honey600: '#FFAA00',
    honey500: '#FFBE3C',
    honey400: '#FFCC66',
    honey300: '#FFDD99',
    honey200: '#FFEECC',
    honey100: '#FFF7E6',
    tropical700: '#003D3D',
    tropical600: '#005757',
    tropical500: '#007171',
    tropical400: '#338D8D',
    tropical300: '#66AAAA',
    tropical200: '#99C6C6',
    tropical100: '#CCE3E3',
    stormy600: '#061927',
    stormy500: '#0A273D',
    stormy400: '#3B5264',
    stormy300: '#677784',
    stormy200: '#7F8F99',
    stormy100: '#9CA8B0',
    snow800: '#C6CDD2',
    snow700: '#D4DADD',
    snow600: '#E3E6E8',
    snow500: '#F1F3F4',
    snow100: '#FFFFFF',
    raspberry700: '#881125',
    raspberry600: '#B61631',
    raspberry500: '#E31C3D',
    raspberry400: '#E94964',
    raspberry300: '#EE778B',
    raspberry200: '#F4A4B1',
    raspberry100: '#F9D2D8',
    textDefault: '#0A273D',
    textInverted: '#FFFFFF',
    textMuted: '#677784', // these are duplicates -> stormy
    textLink: '#007171', // these are duplicates -> stormy
    textInvalid: '#E31C3D',
    error800: '#57000E',
    error500: '#E31C3D',
    error200: '#FCD7D4',
    success700: '#005419',
    success500: '#2E8540',
    success200: '#D7F1D8',
    informative700: '#003B7E',
    informative500: '#2071CE',
    informative200: '#D5E5F8',
    warning700: '#9C2E00',
    warning500: '#F35B1C',
    warning200: '#FFE8D6',
    charts: {
        night400: '#3247E9',
        night200: '#D1D6FA',
        aqua400: '#3DAED8',
        aqua200: '#AADBEE',
        grass400: '#39B93D',
        grass200: '#B4E4B5',
        bolt400: '#FFBE3C',
        bolt200: '#FFE6B3',
        orange400: '#FF8C34',
        orange200: '#FFD4B3',
        berry400: '#F95252',
        berry200: '#FCA7A7',
        candy400: '#E467C3',
        candy200: '#F6CBEA',
        mud400: '#8B6559',
        mud200: '#D7C6C1',
        dirt400: '#828282',
        dirt200: '#E0DDDC',
    },
};

export const makePalette = (options?: IPaletteTheme) => {
    const type = options?.type || 'light';

    return {
        type: type,
        contrastThreshold: 3,
        text: options?.text
            ? {
                text: options.text,
            }
            : {
                primary: NECTARY_COLORS.textDefault,
                secondary: NECTARY_COLORS.textMuted,
                disabled: NECTARY_COLORS.stormy100,
            },
        primary: options?.primary
            ? {
                main: options.primary,
            }
            : {
                main: NECTARY_COLORS.tropical500,
                contrastText: NECTARY_COLORS.textInverted,
            },

        secondary: options?.secondary
            ? {
                main: options.secondary,
            }
            : {
                main: blueGrey['50'],
            },


        warning: {
            main: NECTARY_COLORS.warning500,
        },

        success: {
            main: NECTARY_COLORS.success500,
        },

        info: {
            main: NECTARY_COLORS.informative500,
        },

        error: {
            main: NECTARY_COLORS.raspberry500,
            contrastText: common.white,
        },
        background: {
            default: type === 'light' ? NECTARY_COLORS.snow500 : '#303030',
        },
    };
};

const breakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1440,
        xl: 1920,
    },
};

const Gilroy = {
    fontFamily: 'Gilroy ',
    fontStyle: 'normal',
    fontDisplay: 'swap' as const,
    fontWeight: 400,
    src: `
    local('Gilroy'),
    url('${GilroyRegularWoff}') format('woff'),
    url('${GilroyRegularWoff2}') format('woff2')
  `,
};

const GilroySemi = {
    fontFamily: 'Gilroy ',
    fontStyle: 'semi bold',
    fontDisplay: 'swap' as const,
    fontWeight: 600,
    src: `
    local('Gilroy Semi Bold'),
    url('${GilroySemiBoldWoff}') format('woff'),
    url('${GilroySemiBoldWoff2}') format('woff2')
  `,
};

const GilroySemiBold = {
    fontFamily: 'Gilroy ',
    fontStyle: 'bold',
    fontDisplay: 'swap' as const,
    fontWeight: 800,
    src: `
    local('Gilroy Bold'),
    local('Gilroy-Bold'),
    url('${GilroyBoldWoff}') format('woff'),
    url('${GilroyBoldWoff2}') format('woff2')
  `,
};

export const makeCustomTheme = (paletteOptions?: IPaletteTheme): ThemeOptions => ({
    palette: makePalette(paletteOptions),
    typography: {
        fontFamily:
            '\'Gilroy\', \'Apple Color Emoji\', \'Twemoji Mozilla\', \'Noto Color Emoji\', \'Arial\', \'Segoe UI Emoji\', \'sans-serif\'', // leave the emoji fonts in the font stack. They don't hurt and will improve compatibility
        // these are the sinch font styles from the nectary style guide. This works but currently looks horrible
        fontSize: 16,
        body1: {
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
        },
        body2: {
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '24px',
        },
        h1: {
            fontSize: 40,
            fontWeight: 600,
            lineHeight: '48px',
        },
        h2: {
            fontSize: 24,
            fontWeight: 600,
            lineHeight: '32px',
        },
        h3: {
            fontSize: 20,
            fontWeight: 600,
            lineHeight: '24px',
        },
        h4: {
            fontSize: 16,
            fontWeight: 600,
            lineHeight: '24px',
        },
        h5: {
            fontSize: 14,
            fontWeight: 600,
            lineHeight: '22px',
        },
        caption: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '22px',
        },
    },
    shape: {
        borderRadius: 8,
    },
    props: {
        MuiTextField: {
            variant: 'outlined',
        },
        MuiSelect: {
            variant: 'outlined',
        },
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [Gilroy, GilroySemi, GilroySemiBold],
            },
        },
        MuiToolbar: {
            root: {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
            },
        },
        MuiInputBase: {
            root: {
                fontSize: '0.875rem',
            },
        },
        MuiFormLabel: {
            root: {
                fontSize: '0.875rem',
            },
        },
        MuiFormControlLabel: {
            root: {
                fontSize: '0.875rem',
            },
        },
        MuiPopover: {
            root: {
                fontSize: '0.875rem',
            },
        },
        MuiSvgIcon: {
            root: {
                fillRule: 'evenodd',
                clipRule: 'evenodd',
            },
        },
        MuiMenuItem: {
            root: {
                fontSize: '0.875rem',
            },
        },
        MuiExpansionPanelSummary: {
            root: {
                '&.Mui-expanded': {
                    minHeight: 0,
                },
            },
            content: {
                marginTop: 4,
                marginBottom: 4,
            },
        },
        MuiSnackbarContent: {
            root: {
                // fix action button alignment with long messages in snackbar
                flexWrap: 'nowrap',
            },
        },
        MuiTableCell: {
            head: {
                padding: '12px 16px',
            },
        },
        MuiChip: {
            root: {
                maxWidth: '100%',
            },
        },
    },
    breakpoints,
});
