/* eslint-disable import/order */

import {FC, forwardRef, ReactElement, ReactNode, Ref, useEffect} from 'react';

import Box, {BoxProps} from '@material-ui/core/Box';
import Button, {ButtonProps} from '@material-ui/core/Button';
import Dialog, {DialogProps} from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {TransitionProps} from '@material-ui/core/transitions';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

import translations from '../../providers/TranslationProvider/translations';
import {CustomDialogTitle} from './CustomDialogTitle';
import {Theme} from '@material-ui/core/styles/createTheme';
import {CircularProgress, Typography} from '@material-ui/core';

export interface ActionButtonProps extends ButtonProps {
    label: ReactNode;
    boxProps?: BoxProps;
}

interface DialogTemplateProps<TRef = unknown> extends Omit<DialogProps, 'title'> {
    actionsConfig?: ActionButtonProps[];
    content?: any;

    title?: ReactNode;
    contentText?: string;
    ariaLabel?: string;
    confirmLabel?: string;
    cancelLabel?: string;

    closable?: boolean;
    confirmDialog?: boolean;
    confirmDisabled?: boolean;
    confirmOnEnter?: () => any;
    preventFullScreenForMobile?: boolean;

    isSubmitting?: boolean;

    onConfirm?: () => void;
    onCancel?: () => void;
    onClose?: (event?: {}, reason?: string) => void;
    ref?: Ref<TRef> | undefined;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children?: ReactElement },
    ref: Ref<unknown>,
) {
    return <Slide direction='up' ref={ref} {...props} />;
});

export const DialogTemplate: FC<DialogTemplateProps> = forwardRef((props, ref) => {
    const {
        open,
        title,
        contentText,
        ariaLabel,
        actionsConfig,
        content,
        children,
        confirmDialog,
        confirmOnEnter,
        confirmDisabled,
        onConfirm,
        onClose,
        closable,
        scroll,
        fullScreen,
        fullWidth = true,
        maxWidth = 'sm',
        preventFullScreenForMobile,
        confirmLabel = translations.yes,
        cancelLabel = translations.cancel,
        isSubmitting,
        ...dialogProps
    } = props;

    const breakpointSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const ariaPrefix = ariaLabel || 'mui';

    const handleClick = async (e: KeyboardEvent) => {
        const disabled = actionsConfig?.find(action => action.onClick === confirmOnEnter)?.disabled;
        if (open && e.key === 'Enter' && ((confirmOnEnter && !disabled) || (onConfirm && !confirmDisabled))) {
            confirmOnEnter ? confirmOnEnter() : onConfirm!();
        }
    };

    useEffect(() => {
        if (confirmDialog || confirmOnEnter) {
            document.addEventListener('keypress', handleClick);
        }

        return () => document.removeEventListener('keypress', handleClick);
    });

    return (
        <Dialog
            fullScreen={!preventFullScreenForMobile && (fullScreen || breakpointSmall)}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            ref={ref}
            onClose={onClose}
            TransitionComponent={Transition}
            aria-labelledby={`${ariaPrefix}-dialog-title`}
            aria-describedby={!contentText ? undefined : `${ariaPrefix}-dialog-description`}
            {...dialogProps}
        >
            {closable && onClose ? (
                <CustomDialogTitle id={`${ariaPrefix}-dialog-title`} onClose={onClose}>
                    {title}
                </CustomDialogTitle>
            ) : (
                <DialogTitle id={`${ariaPrefix}-dialog-title`} disableTypography><Typography
                    variant={'h3'}>{title}</Typography></DialogTitle>
            )}

            {(contentText || content || confirmDialog) && (
                <DialogContent dividers={scroll === 'paper'}>
                    {contentText && (
                        <DialogContentText id={`${ariaPrefix}-dialog-description`}>{contentText}</DialogContentText>
                    )}
                    {content}
                </DialogContent>
            )}

            {children}

            {(actionsConfig || confirmDialog) && (
                <DialogActions disableSpacing>
                    {actionsConfig?.map(({label, boxProps, disabled, ...button}, index) => (
                        <Box key={index} mr={index === actionsConfig?.length - 1 ? 0 : 1} {...boxProps}>
                            <Button
                                children={label}
                                disableElevation
                                disabled={disabled || isSubmitting}
                                {...button}
                            />
                        </Box>
                    ))}
                    {confirmDialog && (
                        <>
                            <Box mr={1}>
                                <Button
                                    variant='text'
                                    disableElevation
                                    onClick={onClose}
                                    children={cancelLabel}
                                    disabled={isSubmitting}
                                />
                            </Box>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={onConfirm}
                                children={isSubmitting ?
                                    <CircularProgress size={24} color={'inherit'}/> : confirmLabel}
                                disabled={confirmDisabled || isSubmitting}
                                disableElevation
                            />
                        </>
                    )}
                </DialogActions>
            )}
        </Dialog>
    );
});

export default DialogTemplate;
