import {Reducer} from 'redux';
import {TOneOrZero} from '../../definitions/common.definitons';
import {ACTOR_GET_PROFILE_FULFILLED} from '../actor/actor.types';
import {
    USER_LIST_PAGE_SET_FILTER,
    USERS_UPLOAD_CSV_FULFILLED,
    USERS_UPLOAD_CSV_PENDING,
    USERS_UPLOAD_CSV_REJECTED,
} from '../user/user.types';
import {SET_USER_PAGE_SEARCH_TERM, SET_USER_PAGE_SORTING} from './userPage.types';

export interface IUserPageRequestParameters {
    last_chat: TOneOrZero;
    search?: string;
    customfields: TOneOrZero;
    order: string; // helps the compiler to get correct interface with typeof
    asc: TOneOrZero; // helps the compiler to get correct interface with typeof
    status?: TOneOrZero;
    targeting_id?: number;
    inbotchat: TOneOrZero;
}

export interface IUserPageState {
    userPageFilter: IUserPageRequestParameters;
    uploadingCsv?: boolean;
    uploadingCsvError?: {
        error: boolean;
        message?: string;
    }
}

const initialState: IUserPageState = {
    userPageFilter: {
        last_chat: 1,
        search: '',
        customfields: 1,
        order: 'created',
        asc: 0,
        status: undefined,
        inbotchat: 0,
    },
};

const userPageReducer: Reducer<IUserPageState> = (state = initialState, action) => {
    const {payload, type} = action;

    switch (type) {
        case SET_USER_PAGE_SEARCH_TERM: {
            return {
                ...state,
                userPageFilter: {
                    ...state.userPageFilter,
                    search: payload as string,
                } as IUserPageRequestParameters,
            };
        }

        case SET_USER_PAGE_SORTING: {
            return {
                ...state,
                userPageFilter: {
                    ...state.userPageFilter,
                    asc: payload.asc,
                    order: payload.order,
                } as IUserPageRequestParameters,
            };
        }

        case USERS_UPLOAD_CSV_PENDING: {
            return {
                ...state,
                uploadingCsv: true,
                uploadingCsvError: undefined,
            };
        }

        case USERS_UPLOAD_CSV_REJECTED: {
            return {
                ...state,
                uploadingCsv: false,
                uploadingCsvError: {
                    error: true,
                    message: payload?.data?.error,
                },
            };
        }

        case USERS_UPLOAD_CSV_FULFILLED: {
            return {
                ...state,
                uploadingCsv: false,
            };
        }

        case USER_LIST_PAGE_SET_FILTER: {
            return {
                ...state,
                userPageFilter: payload as IUserPageRequestParameters,
            };
        }

        case ACTOR_GET_PROFILE_FULFILLED: {
            // try set initial sorting from agent settings
            if (!payload?.settings?.usersSortColumn) {
                return state;
            }

            return {
                ...state,
                userPageFilter: {
                    ...state.userPageFilter,
                    asc: Number(payload.settings?.usersSortDirection === 'ASC'),
                    order: payload.settings.usersSortColumn,
                } as IUserPageRequestParameters,
            };
        }

        default:
            return state;
    }
};

export default userPageReducer;
