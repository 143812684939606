import {useLayoutEffect, useRef} from 'react';

const isBrowser = typeof window !== `undefined`;

function getScrollPosition({element}: {element?: any}) {
    if (!isBrowser || !element?.current) return {x: 0, y: 0};

    return element === window
        ? {x: window.scrollX, y: window.scrollY}
        : {x: element?.current.scrollLeft, y: element?.current.scrollTop};
}

export function useScrollPosition(effect: any, deps: any, element: any, throttle?: number) {
    const position = useRef(getScrollPosition({element}));
    const throttleTimeout = useRef<ReturnType<typeof setTimeout>>();

    useLayoutEffect(() => {
        const currentElement = element.current;

        const callback = () => {
            const currPos = getScrollPosition({element});
            effect({prevPos: position.current, currPos});
            position.current = currPos;
            throttleTimeout.current = undefined;
        };

        const handleScroll = () => {
            if (throttle) {
                if (!throttleTimeout.current) {
                    throttleTimeout.current = setTimeout(callback, throttle);
                }
            } else {
                callback();
            }
        };

        currentElement.addEventListener('scroll', handleScroll);

        return () => currentElement.removeEventListener('scroll', handleScroll);
    }, [effect, element, throttle]);
}
