import {PureComponent} from 'react';

import Switch from '@material-ui/core/Switch';

interface ICustomSwitchProps {
    color?: 'primary' | 'secondary' | 'default';
    checked: boolean;
    classes?: object;
    disabled?: boolean;

    onClick(): void;
}

interface ICustomSwitchState {
    checked: boolean;
}

/**
 * A Switch component which provides instant feedback but listens to the checked prop, if it changes
 */
export default class CustomSwitch extends PureComponent<ICustomSwitchProps, ICustomSwitchState> {
    state = {
        checked: this.props.checked,
    };

    componentDidUpdate(prevProps: ICustomSwitchProps) {
        if (prevProps.checked !== this.props.checked) {
            this.setState({checked: this.props.checked});
        }
    }

    render() {
        const color = this.props.color || 'primary';

        return (
            <Switch
                color={color}
                checked={Boolean(this.state.checked)}
                disabled={this.props.disabled}
                onClick={() => {
                    this.setState(
                        state => ({checked: !state.checked}),
                        () => this.props.onClick(),
                    );
                }}
                classes={this.props.classes}
            />
        );
    }
}
