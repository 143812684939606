import {Reducer} from 'redux';

import {IChannel} from '../../definitions/channel/channel.definitions';
import {UPDATE_STREAM} from '../connection/connection.types';
import {SETTINGS_MESSENGER_GET_FULFILLED} from '../settings/messenger/messenger.types';
import {
    CHANNEL_GET_AGENTS_FULFILLED,
    CHANNEL_GET_FULFILLED,
    CHANNEL_GET_INVOICES_FULFILLED,
    CHANNEL_GET_LABELS_FULFILLED,
    CHANNEL_GET_TEAMS_FULFILLED,
    CHANNEL_MESSENGER_MP_API_DELETE_FULFILLED,
    CHANNEL_MESSENGER_MP_API_DELETE_PENDING,
    CHANNEL_MESSENGER_MP_API_DELETE_REJECTED,
    CHANNEL_MESSENGER_MP_API_POST_FULFILLED,
    CHANNEL_MESSENGER_USER_DELETE_FULFILLED,
    CHANNEL_SET_FULFILLED,
    CHANNEL_SET_IMAGE_FULFILLED,
    CHANNEL_SET_PENDING,
    CHANNEL_SET_REJECTED,
} from './channel.types';
import {channelReducerInitialState} from './channel.reducer.initialState';
import {
    isChannelLabelAndMethodIsDelete,
    isChannelLabelAndMethodIsPutPost,
    isChannelUpdate,
} from './channel.reducer.utils';
import {EMessenger} from '../../enums/messenger/EMessenger';

export const channelReducer: Reducer<IChannel> = (state = channelReducerInitialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case UPDATE_STREAM: {
            const {message} = payload;
            const {channel_id} = message;

            if (isChannelUpdate(message)) {
                const {id, ...rest} = message.payload;

                if (id === channel_id) {
                    return {...state, ...rest};
                }
                break;
            }

            if (isChannelLabelAndMethodIsPutPost(message)) {
                if (message?.payload?.id) {
                    const {id, name, color_bg} = message.payload;

                    return {
                        ...state,
                        labels: {
                            ...state.labels,
                            [id]: {id, name, color_bg},
                        },
                    };
                }
            }

            if (isChannelLabelAndMethodIsDelete(message)) {
                const {id} = message.payload;
                const labels = {...state.labels};
                delete labels[id];

                return {...state, labels};
            }

            break;
        }

        case CHANNEL_SET_PENDING:
            return {...state, ...action.meta, backupState: state};

        case CHANNEL_SET_REJECTED:
            return state.backupState;

        case CHANNEL_GET_FULFILLED:
        case CHANNEL_SET_FULFILLED: {
            const {...channelData} = payload;
            // delete response code from payload before updating store, otherwise we have an invalid state
            delete channelData.code;

            const newState = {
                ...state,
                ...channelData,
            };
            delete newState.backupState;
            return newState;
        }

        case CHANNEL_SET_IMAGE_FULFILLED:
            return {...state, image: payload.image};

        case CHANNEL_GET_INVOICES_FULFILLED:
            return {...state, invoices: payload.invoices};

        case CHANNEL_GET_LABELS_FULFILLED:
            return {...state, labels: payload.labels};

        case CHANNEL_GET_TEAMS_FULFILLED:
            return {...state, teams: payload.teams};

        case SETTINGS_MESSENGER_GET_FULFILLED: {
            const {execution_time, ...rest} = payload; // varying execution times caused a rerender and that caused another request
            return {
                ...state,
                messengerSettings: rest,
            };
        }

        case CHANNEL_GET_AGENTS_FULFILLED: {
            return {
                ...state,
                agents: Object.keys(payload.agents).map(Number),
            };
        }

        case CHANNEL_MESSENGER_MP_API_POST_FULFILLED: {
            const messenger = payload.api_channels[0].messenger as EMessenger;
            return {
                ...state,
                messengerSettings: {
                    ...state.messengerSettings,
                    [messenger]: {
                        ...state.messengerSettings[messenger],
                        in_use: true,
                        caption: payload.caption,
                    },
                },
            };
        }

        case CHANNEL_MESSENGER_MP_API_DELETE_REJECTED:
        case CHANNEL_MESSENGER_MP_API_DELETE_PENDING: {
            const messenger = action.meta.messenger as EMessenger;
            return {
                ...state,
                messengerSettings: {
                    ...state.messengerSettings,
                    [messenger]: {
                        ...state.messengerSettings[messenger],
                        loading: action.type === CHANNEL_MESSENGER_MP_API_DELETE_PENDING,
                    },
                },
            };
        }

        case CHANNEL_MESSENGER_MP_API_DELETE_FULFILLED: {
            const messenger = action.meta.messenger as EMessenger;
            return {
                ...state,
                messengerSettings: {
                    ...state.messengerSettings,
                    [messenger]: {
                        ...state.messengerSettings[messenger],
                        in_use: false,
                        user_count: payload.user_count,
                        loading: false,
                    },
                },
            };
        }

        case CHANNEL_MESSENGER_USER_DELETE_FULFILLED: {
            const messenger = action.meta.messenger as EMessenger;
            return {
                ...state,
                messengerSettings: {
                    ...state.messengerSettings,
                    [messenger]: {
                        ...state.messengerSettings[messenger],
                        user_count: 0,
                    },
                },
            };
        }

        default:
            break;
    }

    return state;
};
