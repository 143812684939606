
function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill="#fff"
                d="M38.673 31.26A1 1 0 0138 33H10a1 1 0 01-.633-1.774c2.47-2.021 3.536-5.104 4.022-8.015.24-1.444.332-2.805.394-3.923l.02-.378c.022-.405.042-.78.067-1.098.023-.295.055-.612.116-.889 0-.058.003-.11.006-.155.011-.138.037-.287.07-.434.066-.3.177-.674.333-1.092.313-.838.828-1.907 1.587-2.966 1.458-2.03 3.877-4.09 7.518-4.264-.463.724-.69 1.177-1 2.128-2.257.435-3.833 1.826-4.893 3.303a10.975 10.975 0 00-1.339 2.5 6.37 6.37 0 00-.253.82c-.01.05-.018.088-.022.115a2.89 2.89 0 00-.044.438l-.001.003-.003.01a2.154 2.154 0 00-.033.179c-.017.12-.033.271-.048.46-.023.29-.04.631-.062 1.03l-.022.401c-.063 1.133-.16 2.584-.419 4.14-.402 2.415-1.213 5.183-2.996 7.46h23.368c-1.681-2.202-2.526-4.73-2.975-7H34.8c.556 2.558 1.646 5.237 3.873 7.26z"
            ></path>
            <path
                fill="#FFBE3C"
                d="M29.977 8.877a1 1 0 112 0v5.147a1 1 0 01-2 0V8.877zM32.023 16.985a1 1 0 11-2 0 1 1 0 012 0z"
            ></path>
            <path
                fill="#FFBE3C"
                fillRule="evenodd"
                d="M22 13c0-4.979 4.021-9 9-9 4.883 0 8.904 3.926 9 9 0 4.979-4.021 9-9 9a8.99 8.99 0 01-9-9zm1.915-.096A7.07 7.07 0 0031 19.99c3.83-.095 6.99-3.16 7.085-7.085A7.07 7.07 0 0031 5.82a7.07 7.07 0 00-7.085 7.085z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#FFBE3C"
                d="M28 35a4 4 0 01-8 0h2.007a1.992 1.992 0 103.986 0H28z"
            ></path>
            <path
                fill="#FFBE3C"
                d="M28 35a4 4 0 01-8 0h2.007a1.992 1.992 0 103.986 0H28z"
            ></path>
        </svg>
    );
}

export default Icon;



