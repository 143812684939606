import {request} from './Api/Api';

export const getUserRequest = (parameters: any) => {
    const url = parameters.id ? `user/${parameters.id}` : 'user';

    const params = parameters
        ? {
              ...parameters,
              fields: parameters.fields ? JSON.stringify(parameters.fields) : undefined,
          }
        : undefined;

    return request({url, method: 'GET'}, params);
};

export const putUserDataRequest = (id: number, fields: any) =>
    request({url: `user/${id}`, method: 'PUT'}, {fields: fields ? JSON.stringify(fields) : undefined});
export const deleteUserRequest = (id: number) => request({url: `user/${id}`, method: 'DELETE'});
export const blockUserRequest = (id: number) => request({url: `user/${id}/block`, method: 'PUT'});
export const unblockUserRequest = (id: number) => request({url: `user/${id}/unblock`, method: 'PUT'});
export const startUserRequest = (id: number) => request({url: `user/${id}/start`, method: 'PUT'});
export const stopUserRequest = (id: number) => request({url: `user/${id}/stop`, method: 'PUT'});
export const uploadUserCsvRequest = (formData: any) => request({url: `user/csv`, method: 'POST'}, formData);
export const setUnreadRequest = (ids: number | number[], unreadCount: number) => {
    const id = Array.isArray(ids) ? ids.join(',') : ids;
    return request({url: `user/${id}`, method: 'PUT'}, {unread: unreadCount});
};
