import {CircularProgress, createStyles, List, Paper, Theme, Zoom} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import {makeStyles} from '@material-ui/core/styles';
import debounce from 'lodash/debounce';
import {FC, useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';

import translations from '../../../../providers/TranslationProvider/translations';
import {getChatsForChatList} from '../../../../redux/tickets/tickets.selectors';
import {useParamSelector} from '../../../../redux/utils';
import useImagesLoaded from '../../../../utils/hooks/useImagesLoaded';
import usePrevious from '../../../../utils/hooks/usePrevious';
import {useScrollPosition} from '../../../../utils/hooks/useScrollPosition';
import {useTypedSelector} from '../../../../utils/hooks/useTypedSelector';
import {CHAT_LIST_INITIAL_NUM_CHATS_LOADED} from '../../config';
import ChatListDays from './ChatListDays';
import ScrollToBottomButton from './ScrollToBottomButton';
import Box from "@material-ui/core/Box";
import TypingIcon from "../TypingIcon";
import {IChatListUser} from "../../../../definitions/user/user.definitions";
import {IChat} from "../../../../definitions/chat/chat.definitions";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            overflow: 'hidden',
            position: 'relative',
            height: '100%',
            minWidth: 0,
            // flex: 1,
        },
        rootAutoHeight: {
            height: 'auto',
            maxHeight: '60vh',
        },
        list: {
            position: 'relative',
            height: '100%',
            width: '100%',
            overflowY: 'auto',

            '& *': {
                userSelect: 'text',
            },
        },
        truncatedChatsInfo: {
            position: 'absolute',
            zIndex: 2,
            top: theme.spacing(1),
            left: '50%',
            transform: 'translateX(-50%)',
            textTransform: 'lowercase',
        },
        loadAllChatsInfo: {
            position: 'absolute',
            zIndex: 2,
            top: theme.spacing(1),
            left: '50%',
            transform: 'translateX(-50%)',
            textTransform: 'lowercase',
            borderRadius: '50%',
            width: 36,
            height: 36,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    }),
);

const DATE_SEPARATOR_HEIGHT = 55;

export interface ChatsByDate {
    [date: string]: IChat[];
}


interface IChatList {
    user: IChatListUser;
    chats: IChat[];
    autoHeight?: boolean;
    searchTerm?: string;
    onLoadMore?: () => any;
    onResetSearch?: () => void;
    isTyping?: boolean;
}

const ChatList: FC<IChatList> = (
    {
        user,
        chats,
        autoHeight,
        searchTerm = '',
        onLoadMore,
        onResetSearch,
        isTyping,
    }
) => {
    const classes = useStyles();

    const scrollBar = useRef<HTMLDivElement | null>(null);
    const listEnd = useRef<HTMLDivElement | null>(null);
    const lockAutoScroll = useRef(false);

    const [lastReadMessageTime, setLastReadMessageTime] = useState(0);
    const [unreadMessages, setUnreadMessagesCount] = useState(0);
    const [searchResultPosition, setSearchResultPosition] = useState<number>(0);
    const [isLoadingAllChats, setIsLoadingAllChats] = useState(false);
    const [allChatsLoaded, setAllChatsLoaded] = useState(false);
    const [reachedTop, setReachedTop] = useState(false);

    const {imagesLoadedCount, imagesErrorCount, imagesLoaded} = useImagesLoaded(scrollBar);
    const lastMessageTime = chats?.length ? chats[0].chattime : 0;

    const previousSearchTerm: string | undefined = usePrevious(searchTerm);
    const previousSearchPosition: number | undefined = usePrevious(searchResultPosition);
    const previousScrollTop: number | undefined = usePrevious(scrollBar.current?.scrollTop);
    const previousScrollHeight: number | undefined = usePrevious(scrollBar.current?.scrollHeight);
    const previousChatsLength: number | undefined = usePrevious(chats.length || 0);
    const didChatLengthChange = previousChatsLength !== chats.length;

    const channelName = useTypedSelector(state => state.channel.name);
    const channelImage = useTypedSelector(state => state.channel.image);

    const scrollToBottom = useCallback((scrollParams?: any) => {
        listEnd.current?.scrollIntoView(scrollParams);
    }, []);

    const handleRegisterSearchPosition = useCallback((value: number) => {
        setSearchResultPosition(value);
    }, []);

    const handleScrollToBottomClick = useCallback(() => {
        scrollToBottom({behavior: 'smooth'});
    }, [scrollToBottom]);

    // We need the component to be re-rendered at the beginning and the end of user scrolling. For this we use debounce
    // with the leading and trailing options with a waiting time of something not too short and not too delayed.
    // Without this the scroll down and unread messages batch won't update/appear/disappear on after scrolling, since
    // there is no additional render because the change of the lockAutoScroll ref state doesn't trigger re-rendering.
    // See http://demo.nimius.net/debounce_throttle/ for visualization of debounce...
    const [, setIsScrolling] = useState(false);
    const debounceTime = 400;
    const startScrolling = useRef<any>(
        debounce(
            () => {
                if (scrollBar.current?.scrollTop) {
                    setReachedTop(false);
                }
                setIsScrolling(true);
            },
            debounceTime,
            {leading: true},
        ),
    );
    const stopScrolling = useRef<any>(
        debounce(
            () => {
                if (scrollBar.current?.scrollTop === 0) {
                    setReachedTop(true);
                }
                setIsScrolling(false);
            },
            debounceTime + 1,
            {trailing: true},
        ), // + 1 to overcome race conditions
    );

    useScrollPosition(
        ({prevPos, currPos}: any) => {
            const scrollDirectionDown = currPos.y > prevPos.y;

            if (!scrollBar.current) {
                return;
            }

            // scrolling up
            if (!scrollDirectionDown) {
                // lock scroll if actor scrolls up
                lockAutoScroll.current = true;

                // load more if reached top of list
                if (!allChatsLoaded && onLoadMore && scrollBar.current.scrollTop === 0) {
                    setIsLoadingAllChats(true);
                }
            }
            // scrolling down
            else {
                // if reached end of list, reset scroll lock and unread messages
                if (scrollBar.current.scrollTop + scrollBar.current.clientHeight >= scrollBar.current.scrollHeight) {
                    lockAutoScroll.current = false;
                    setUnreadMessagesCount(0);
                    setLastReadMessageTime(lastMessageTime);

                    // reset search if search position is out of view, so we dont jump back to the search result on list changes
                    if (
                        onResetSearch &&
                        searchResultPosition < scrollBar.current.scrollHeight - scrollBar.current.clientHeight
                    ) {
                        onResetSearch();
                        setSearchResultPosition(0);
                    }
                }
            }

            // Here comes the scroll debounce magic
            startScrolling.current();
            stopScrolling.current();

            return () => {
                startScrolling.current.clear();
                stopScrolling.current.clear();
            };
        },
        [allChatsLoaded, onLoadMore, onResetSearch, searchResultPosition, lastMessageTime],
        scrollBar,
        150,
    );

    // initially check if all chats loaded
    useEffect(() => {
        if (chats.length < CHAT_LIST_INITIAL_NUM_CHATS_LOADED) {
            setAllChatsLoaded(true);
        }
    }, [chats]);

    // count unread messages if auto-scrolling is locked
    useEffect(() => {
        if (lockAutoScroll.current && lastReadMessageTime) {
            // get only incoming chats that where not in the chat list before
            const newIncomingChatsCount = chats.filter(chat => chat.chattime > lastReadMessageTime).length || 0;
            setUnreadMessagesCount(newIncomingChatsCount);
        }
    }, [chats, lastReadMessageTime]);

    // load all chats on search if not already loaded
    useEffect(() => {
        if (searchTerm && !allChatsLoaded && onLoadMore) {
            setIsLoadingAllChats(true);
        }
    }, [allChatsLoaded, onLoadMore, searchTerm]);

    // dispatch load all chats if flag is set
    useEffect(() => {
        let isMounted = true;
        if (isLoadingAllChats && onLoadMore) {
            Promise.resolve(onLoadMore())
                .then(() => {
                    if (!isMounted) return;
                    setReachedTop(false); // this prevents the truncatedChatsInfo chip from being shown shortly
                    setAllChatsLoaded(true);
                })
                .finally(() => isMounted && setIsLoadingAllChats(false));
        }
        return () => {
            isMounted = false;
        };
    }, [isLoadingAllChats, onLoadMore]);

    // handle scroll position (on load, on lazy load, when scroll size changes or due to search)
    // 1. jump to search position if there is any
    // 2. scroll to bottom if autoScroll is not locked
    // 3. otherwise keep current scroll position (if height change is not affected by loaded media content)
    useLayoutEffect(() => {
        if (!scrollBar.current?.scrollHeight) {
            return;
        }

        // handle search
        if (
            searchTerm &&
            (searchResultPosition !== previousSearchPosition ||
                lastReadMessageTime !== lastMessageTime ||
                previousSearchTerm !== searchTerm)
        ) {
            // we need to use scrollTo instead of setting scrollTop directly, otherwise the scroll listener wont fire
            scrollBar.current.scrollTo(0, searchResultPosition - DATE_SEPARATOR_HEIGHT);
            return;
        }

        // if the chat length didn't change, the scroll height change must come from a (lazy) loaded media, so we don't
        // want the scroll position to 'jump', since the image or video is loaded inside of the users viewport anyways.
        // We also want to skip if the previous values are not defined yet, which means we didn't scroll before at all.
        if (!didChatLengthChange || previousScrollTop === undefined || previousScrollHeight === undefined) {
            return;
        }

        // if scroll height changes, keep current scroll position
        if (scrollBar.current.scrollHeight !== previousScrollHeight) {
            if (lockAutoScroll.current) {
                // if new items have been appended, we don't need to change
                // since we are already at the right scroll position
                if (lastReadMessageTime !== lastMessageTime) {
                    return;
                }

                // change scroll position to same visual position as before, so it won't jump to anywhere
                const scrollHeightDelta = scrollBar.current.scrollHeight - previousScrollHeight;
                scrollBar.current.scrollTo(0, previousScrollTop + scrollHeightDelta);

            } else if (!searchTerm) {
                scrollBar.current.scrollTo(0, scrollBar.current.scrollHeight - scrollBar.current.offsetHeight);
            }
        }
    }, [
        didChatLengthChange,
        previousScrollTop,
        previousScrollHeight,
        chats,
        searchResultPosition,
        previousSearchPosition,
        searchTerm,
        imagesLoadedCount,
        imagesErrorCount,
        lastReadMessageTime,
        lastMessageTime,
        previousSearchTerm,
    ]);

    // scroll to bottom on load and when images get loaded
    useEffect(() => {
        if (!lockAutoScroll.current) {
            scrollToBottom();
        }
    }, [imagesLoadedCount, imagesLoaded, scrollToBottom]);

    const chatsByDate = useParamSelector(getChatsForChatList, chats, user);

    return (
        <div className={`${classes.root} ${autoHeight && classes.rootAutoHeight}`}>
            {(isLoadingAllChats) && (
                <Paper className={classes.loadAllChatsInfo} elevation={4}>
                    <CircularProgress size={24}/>
                </Paper>
            )}
            {allChatsLoaded && chats.length > CHAT_LIST_INITIAL_NUM_CHATS_LOADED && reachedTop && (
                <Chip className={classes.truncatedChatsInfo} label={translations.chat_history_truncated}/>
            )}
            <div ref={scrollBar} className={classes.list}>
                <List subheader={<li/>}>
                    {!!chatsByDate && Object.keys(chatsByDate).map(chatDate => (
                        <ChatListDays
                            key={chatDate}
                            thisDaysChats={chatsByDate[chatDate]}
                            day={chatDate}
                            chats={chats}
                            user={user}
                            loadedImages={imagesLoadedCount + imagesErrorCount}
                            registerSearchPosition={handleRegisterSearchPosition}
                            channelImage={channelImage}
                            channelName={channelName}
                            searchTerm={searchTerm}
                        />
                    ))}
                </List>
                <div ref={listEnd}/>
            </div>
            <ScrollToBottomButton
                onClick={handleScrollToBottomClick}
                badgeContent={unreadMessages}
                hidden={!lockAutoScroll.current}
            />
            <Box position={'absolute'} mb={1} ml={2} bottom={0} left={0}>
                <Zoom in={isTyping} timeout={100}>
                    <Chip variant={'default'} size={"small"} label={<TypingIcon fontSize={"small"}/>}/>
                </Zoom>
            </Box>
        </div>
    );
};

ChatList.displayName = 'ChatList';

export default ChatList;
