import {FC} from 'react';

import Button from '@material-ui/core/Button';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        valueLink: {
            minWidth: 0,
            marginRight: theme.spacing(-1),
            paddingTop: 0,
            paddingBottom: 0,
        },
    }),
);

interface ZebraListValueProps {
    value: any;
    valueClickHandler?: () => void;
    valueTooltip?: string;
}

const ZebraListValue: FC<ZebraListValueProps> = ({value, valueClickHandler, valueTooltip}) => {
    const classes = useStyles();

    if (!value || (!valueClickHandler && !valueTooltip)) {
        return <>{value || value === 0 ? value : ''}</>;
    }

    const renderedValue = valueClickHandler ? (
        <Button className={classes.valueLink} variant="text" color="primary" onClick={valueClickHandler} component="p">
            {value}
        </Button>
    ) : (
        <div>{value}</div>
    );

    if (!valueTooltip) {
        return renderedValue;
    }

    return <Tooltip title={valueTooltip}>{renderedValue}</Tooltip>;
};

export default ZebraListValue;
