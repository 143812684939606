import {ChangeEvent, FormEvent, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {useDispatch, useSelector} from 'react-redux';
import ContainedButton from '../../components/Buttons/ContainedButton';
import CustomTextField from '../../components/CustomInputs/CustomTextField/CustomTextField';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import FormRow from '../../components/FormRow/FormRow';
import translations from '../../providers/TranslationProvider/translations';
import {EBaseRoutes} from '../../enums/routes/ERoutes';
import {requestPasswordReset} from '../../redux/actor/actor.actions';
import {IState} from '../../redux/root.reducer';
import ExternalPage from '../ExternalPage/ExternalPage';
import Teamwork from '../../resources/img/Illustrations/Teamwork_from_phone.svg';
import IconFactory from "../../components/IconFactory/IconFactory";
import {EIcons} from "../../enums/icons/EIcons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core/styles/createTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import OverlayLoader from "../../components/OverlayLoader/OverlayLoader";
import {translateSlug} from "../../utils/translation/translation.utils";

const useStyles = makeStyles((theme: Theme) => createStyles({
    completedContainer: {
        textAlign: 'center',
        marginTop: -30,

        '& > svg': {
            width: 150,
            height: 150,
            color: theme.palette.primary.main,
            fill: theme.palette.primary.main,
        },
    },
}));

export const ForgotPasswordLandingPage = () => {
    const [email, setEmail] = useState('');
    const classes = useStyles();
    const loading = useSelector((state: IState) => !!state.request.passwordReset.requesting);
    const success = useSelector((state: IState) => !!state.request.passwordReset.success);
    const dispatch = useDispatch();

    const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleSendEmailClick = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        dispatch(requestPasswordReset({username: email}));
    };

    const metaInfo = success ? undefined : {
        metaLink: translations.back_to_login,
        metaLinkUrl: EBaseRoutes.Login,
    };

    return (
        <>
            <ExternalPage
                header
                footer
                backGround={Teamwork}
                {...metaInfo}
            >
                {success ? (
                    <div className={classes.completedContainer}>
                        {IconFactory.getIconWithProps(EIcons.CHECKMARK)}
                        <Typography variant="body2" gutterBottom paragraph>
                            {translateSlug('password_reset_email_sent', email)}
                        </Typography>
                        <Typography variant="body2" gutterBottom paragraph>
                            {translations.you_can_close_this_window}
                        </Typography>
                    </div>
                ) : (
                    <OverlayLoader isLoading={loading}>
                        <form onSubmit={handleSendEmailClick}>
                            <FormRow>
                                <CustomTextField
                                    id='email'
                                    type='email'
                                    autoComplete='username'
                                    fullWidth
                                    label={translations.email}
                                    value={email}
                                    InputProps={{name: 'email'}}
                                    onChange={handleTextFieldChange}
                                />
                            </FormRow>
                            <Typography gutterBottom paragraph component='div'>
                                <ContainedButton
                                    fullWidth
                                    label={translations.send}
                                    disabled={loading || email === '' || !email.includes('@')}
                                    type='submit'
                                />
                            </Typography>
                        </form>
                    </OverlayLoader>
                )}
            </ExternalPage>
        </>
    );
};

const LandingPageWithBoundary = () => (
    <ErrorBoundary>
        <ForgotPasswordLandingPage/>
    </ErrorBoundary>
);

export default LandingPageWithBoundary;
