// noinspection RegExpRedundantEscape

import {useTheme} from "@material-ui/core";
import {translateSlug} from "../translation/translation.utils";

export interface MarkupParams {
    [key: string]: string | number,
}

export const useSlugWithMarkup = (slug: string, params?: MarkupParams) => {
    const theme = useTheme();

    let html = String(translateSlug(slug));

    // replace variables
    if (params) {
        Object.keys(params).map(key => html = html.replace(`{{${key}}`, params[key] as string));
    }

    // Look here for even more rules: https://betterprogramming.pub/create-your-own-markdown-parser-bffb392a06db
    // noinspection HtmlUnknownAttribute
    /**
     * |---------------------------------------------|---------------------------|
     * |__bold text__                                |<strong>bold text</strong> |
     * |**bold text**                                |<strong>bold text</strong> |
     * |_italic text_                                |<em>italic text</em>       |
     * |*italic text*                                |<em>italic text</em>       |
     * |`word`                                       |<code>word</code>          |
     * |Unordered list with "+", "*"                 |                           |
     * | + First item                                | • First item              |
     * | + Second item                               | • Second item             |
     * |[Guide](https://www.google.com)[_blank]      | Guide                     |
     * |-------------------------------------------------------------------------|
     */
    const markupRules = [
        //bold, italics and paragraph rules
        [/\*\*\s?([^\n]+)\*\*/g, "<b>$1</b>"],
        [/\*\s?([^\n]+)\*/g, "<i>$1</i>"],
        [/__([^_]+)__/g, "<b>$1</b>"],
        [/_([^_`]+)_/g, "<i>$1</i>"],
        [/([^\n]+\n?)/g, "<p>$1</p>"],

        // Links
        [
            /\[([^\]]+)\]\(([^)]+)\)(\[([^\]]+)\])?/g,
            `<a href="$2" target="$4" style="color: ${theme.palette.primary.main}">$1</a>`,
        ],

        // Lists
        [/([^\n]+)(\+)([^\n]+)/g, "<ul><li>$3</li></ul>"],
        [/([^\n]+)(\*)([^\n]+)/g, "<ul><li>$3</li></ul>"],
    ];

    markupRules.forEach(([rule, template]) => {
        html = html.replace(rule, template as any);
    });

    return html;
};
