import {FC} from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import {MessengerIconProps} from '../MessengerIcon';

const FacebookMessengerIcon: FC<Omit<MessengerIconProps, 'messenger'>> = props => {
    const {tooltip, ...rest} = props;

    const icon = (
        <SvgIcon {...rest} viewBox="0 0 1024 1024">
            <defs>
                <radialGradient
                    id="radialGradient-1"
                    r="108.959588%"
                    fy="99.4651948%"
                    fx="19.2474387%"
                    cy="99.4651948%"
                    cx="19.2474387%"
                >
                    <stop offset="0%" stopColor="#0099FF" />
                    <stop offset="60.9753877%" stopColor="#A033FF" />
                    <stop offset="93.482299%" stopColor="#FF5280" />
                    <stop offset="100%" stopColor="#FF7061" />
                </radialGradient>
            </defs>
            <g>
                <rect fill="none" id="canvas_background" height="602" width="802" y="-1" x="-1" />
            </g>
            <g>
                <g fillRule="evenodd" fill="none" id="logo">
                    <rect
                        stroke="null"
                        x="-156.647"
                        y="-165.99999"
                        height="1304.47057"
                        width="1304.47057"
                        fill="#FFFFFF"
                        fillOpacity="0"
                        id="bounding-box"
                    />
                    <g stroke="null" id="logo">
                        <path
                            stroke="null"
                            fill="url(#radialGradient-1)"
                            id="Path"
                            d="m511.49396,2.70235c-287.04977,0 -509.55882,210.26435 -509.55882,494.27205c0,148.55474 60.88209,276.9228 160.03,365.58807c8.32313,7.44975 13.34738,17.88551 13.68879,29.05504l2.77506,90.64032c0.88663,28.91237 30.75086,47.72528 57.20715,36.04619l101.14029,-44.64754c8.57384,-3.78093 18.18106,-4.48412 27.21554,-1.99747c46.47686,12.77974 95.94279,19.58744 147.50199,19.58744c287.04977,0 509.55882,-210.26435 509.55882,-494.27205c0,-284.0077 -222.50905,-494.27205 -509.55882,-494.27205z"
                        />
                        <path
                            stroke="null"
                            fill="#FFFFFF"
                            id="Path"
                            d="m205.50593,641.52197l149.6829,-237.47479c23.80965,-37.77869 74.7961,-47.18515 110.52127,-20.39254l119.05026,89.28795c10.9229,8.19371 25.95183,8.14988 36.82887,-0.10497l160.78313,-122.02405c21.46058,-16.2855 49.47307,9.39626 35.11166,32.18679l-149.6829,237.47683c-23.81168,37.77665 -74.79814,47.18515 -110.52127,20.39051l-119.05332,-89.28999c-10.92188,-8.19167 -25.95183,-8.14785 -36.82785,0.10701l-160.78415,122.02405c-21.45956,16.28448 -49.47307,-9.39728 -35.1086,-32.18679z"
                        />
                    </g>
                </g>
            </g>
        </SvgIcon>
    );

    return tooltip ? <Tooltip title="Facebook Messenger">{icon}</Tooltip> : icon;
};

export default FacebookMessengerIcon;
