import {Reducer} from 'redux';
import {AvvDialogStep} from '../../../pages/SettingsPage/components/AvvSettings/components/AvvDialog/AvvDialogStep';
import {
    SETTINGS_AVV_CHANGE_STEP,
    SETTINGS_AVV_GET_DATA_FULFILLED,
    SETTINGS_AVV_SAVE_FULFILLED,
    SETTINGS_AVV_SIGN_FULFILLED,
} from './avv.types';

export interface IAvvContract {
    signedDate?: number;
    createdDate?: number;
    isSigned?: boolean;
}

export interface ISettingsAvvState {
    activeStep: AvvDialogStep;
    contract: IAvvContract;
    signedDate: number;
}

export const settingsAvvInitialState: ISettingsAvvState = {
    activeStep: AvvDialogStep.Update,
    contract: {},
    signedDate: 0,
};

export const avvReducer: Reducer<ISettingsAvvState> = (state = settingsAvvInitialState, {type, payload}) => {
    switch (type) {
        case SETTINGS_AVV_CHANGE_STEP:
            return {...state, activeStep: payload};
        case SETTINGS_AVV_GET_DATA_FULFILLED:
        case SETTINGS_AVV_SAVE_FULFILLED:
            return {...state, contract: payload};
        case SETTINGS_AVV_SIGN_FULFILLED:
            return {...state, contract: {...state.contract, ...payload}};
        default:
            break;
    }

    return state;
};
