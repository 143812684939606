import {FC, forwardRef, HTMLAttributes, ReactNode, useLayoutEffect, useRef} from 'react';

import twemoji from 'twemoji';

interface ITwemoji extends HTMLAttributes<Element> {
    children: ReactNode | string | number;
}

const Twemoji: FC<ITwemoji> = forwardRef(({children, className}, ref) => {
    const textRef = useRef<any>(ref);

    useLayoutEffect(() => {
        if (navigator.userAgent.indexOf('Chrome') && navigator.userAgent.indexOf('Windows')) {
            if (textRef.current) {
                try {
                    twemoji.parse(textRef.current);
                } catch (e) {
                    console.error(`Failed to parse emoji. ${e}. Failed to parse child Node(s): ${children}`);
                }
            }
        }
    }, [children]);

    return (
        <span className={className} ref={textRef}>
            {children}
        </span>
    );
});

export default Twemoji;
