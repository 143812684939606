import SvgIcon from "@material-ui/core/SvgIcon";
import {SvgIconProps} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";

const useStyles = makeStyles(() => createStyles({
    dot: {
        fill: 'rgba(255, 255, 255, .7)',
        transformOrigin: '50% 50%',
        animation: '$ballBeat 1.1s 0s infinite cubic-bezier(0.445, 0.050, 0.550, 0.950)',

        '&:nth-child(2)': {animationDelay: '0.3s !important'},
        '&:nth-child(3)': {animationDelay: '0.6s !important'},
    },
    '@keyframes ballBeat': {
        '0%': {opacity: 0.7},
        '33.33%': {opacity: 0.55},
        '66.67%': {opacity: 0.4},
        '100%': {opacity: 1},
    },
}));

const TypingIcon = (props: SvgIconProps) => {
    const classes = useStyles();

    return(
        <SvgIcon width="24" height="24" viewBox="0 0 24 24" {...props}>
            <g>
                <circle className={classes.dot} cx="3" cy="14" r="3"/>
                <circle className={classes.dot} cx="12" cy="14" r="3"/>
                <circle className={classes.dot} cx="21" cy="14" r="3"/>
            </g>
        </SvgIcon>

    );
};

export default TypingIcon;
