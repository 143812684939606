// The api URL opens the desktop app if available
import {ITranslations} from '../../providers/TranslationProvider/translations';
import {EMessenger} from '../../enums/messenger/EMessenger';

const WA_API_URL = 'https://api.whatsapp.com/send';
// The web app url opens the web app in a browser
const WA_WEB_APP_URL = 'https://web.whatsapp.com/send';
// The app url opens the app on a mobile device
const WA_APP_URL = 'https://wa.me';

export const MESSENGERS_WITHOUT_MIA = [EMessenger.LiveChat, EMessenger.Instagram];

export type TWhatsAppUrlType = 'api' | 'web' | 'app';

export const getWhatsAppChatUrl = (type: TWhatsAppUrlType, number: string, message = '') => {
    if (type === 'web') {
        return `${WA_WEB_APP_URL}?phone=${number}&text=${encodeURIComponent(message)}`;
    }
    if (type === 'api') {
        return `${WA_API_URL}?phone=${number}&text=${encodeURIComponent(message)}`;
    }
    return `${WA_APP_URL}/${number}?text=${encodeURIComponent(message)}`;
};

export const getMessengerTranslation = (messenger: EMessenger, translations: ITranslations) => {
    switch (messenger) {
        case EMessenger.WhatsApp:
        case EMessenger.WhatsAppBusiness:
            return translations.whatsapp;
        case EMessenger.TelegramChannel:
        case EMessenger.Telegram:
            return translations.telegram;
        case EMessenger.IMessage:
            return translations.i_messenger;
        case EMessenger.FacebookMessenger:
            return translations.facebook;
        case EMessenger.InstaAndNotify:
        case EMessenger.Insta2:
            return translations.notify_chat;
        case EMessenger.Viber:
            return translations.viber;
        case EMessenger.CustomMessenger:
            return translations.custom_messenger;
        case EMessenger.LiveChat:
            return translations.livechat;
        case EMessenger.Instagram:
            return translations.instagram;
        case EMessenger.RCS:
            return translations.rcs_messenger;
        default:
            return '';
    }
};
