import {TLanguageCode} from '../../definitions/translations/translations.definitions';
import {translationRequest} from '../../requests/internalRequest';
import {TRANSLATION_GET} from "./translations.types";


export const getTranslation = (module: string, language: TLanguageCode) => ({
    type: TRANSLATION_GET,
    payload: translationRequest(module, language),
    meta: {
        language,
    },
});
