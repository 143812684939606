import createCachedSelector from 're-reselect';
import {createSelector} from 'reselect';

import {IAgent} from '../../definitions/agent/agent.definitions';
import {IChannel} from '../../definitions/channel/channel.definitions';
import {IEntityById} from '../../definitions/generic/generic.definitions';
import ERoles from '../../enums/actor/ERoles';
import {EAgentStatus} from '../../enums/agent/EAgentStatus';
import {getCalculatedStats, getInitStats} from '../../utils/ticket/ticket.helpers';
import {IState} from '../root.reducer';
import {getChannel} from "../channel/channel.selectors";
import sortBy from "lodash/sortBy";

export const getAgentById = (state: IState, agentId?: number) =>
    !agentId ? undefined : state.entities.agents.byId[agentId];

export const getTotalAmountOfAgents = (state: IState) => state.entities.agents.allIds.length;

const _getTicketIdsByAgentId = (channel: IChannel, agent?: IAgent): number[] => {
    if (!agent?.tickets || !agent.tickets[channel.id]) {
        return [];
    }

    return Object.keys(agent.tickets[channel.id]) as any;
};

export const getTicketIdsByAgentId = createCachedSelector(
    [getChannel, getAgentById],
    _getTicketIdsByAgentId,
)((state: IState, agentId: number) => agentId);

const _getTicketStatsByAgentId = (channel: IChannel, agent?: IAgent) => {
    if (!agent || !agent.tickets || !agent.tickets[channel.id]) {
        return getInitStats();
    }

    const tickets = Object.values(agent.tickets[channel.id]);

    return getCalculatedStats(tickets, channel);
};

export const getTicketStatsByAgentId = createCachedSelector(
    [getChannel, getAgentById],
    _getTicketStatsByAgentId,
)((state: IState, agentId: number) => agentId);

const sortAgentsByName = (agents: IAgent[]) =>
    sortBy(agents, agent => (agent.lastname ? agent.lastname.toUpperCase() : agent.name));

const _getAgentsFromActiveChannel = (agents: IEntityById<IAgent>, agentIds: number[]): IAgent[] => {
    if (!Object.values(agents).length || !agentIds) {
        return [];
    }
    const agentsFromChannel = agentIds
        .map(agentId => agents[agentId])
        .filter(agent => agent && agent.roles && !agent.roles.includes(ERoles.Editor));

    const inactive = agentsFromChannel.filter(agent => !agent.active);
    const online = agentsFromChannel.filter(
        agent => agent.logged_in && agent.active && agent.status !== EAgentStatus.Absent,
    );
    const absent = agentsFromChannel.filter(
        agent => agent.status === EAgentStatus.Absent && agent.active && agent.logged_in && !online.includes(agent),
    );

    const offline = agentsFromChannel.filter(
        agent => !inactive.includes(agent) && !absent.includes(agent) && !online.includes(agent),
    );

    return [
        ...sortAgentsByName(online),
        ...sortAgentsByName(offline),
        ...sortAgentsByName(absent),
        ...sortAgentsByName(inactive),
    ];
};

export const getSortedAgentsFromActiveChannel = createSelector(
    [(state: IState) => state.entities.agents.byId, (state: IState) => state.channel.agents],
    _getAgentsFromActiveChannel,
);

const _getFilteredAgentsByActiveChannel = (agents: IEntityById<IAgent>, activeChannel: number): IAgent[] => {
    return Object.values(agents).filter((agent: IAgent) => agent.channels.includes(activeChannel));
};

export const getFilteredAgentsByActiveChannel = createSelector(
    [(state: IState) => state.entities.agents.byId, (state: IState) => state.channel.id],
    _getFilteredAgentsByActiveChannel,
);

export const getNumberOfAgents = (state: IState) => state.entities.agents.allIds.length;
