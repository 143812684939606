import moment from 'moment/moment';
import {createSelector} from 'reselect';
import {IJob, IJobError} from '../../definitions/jobs/jobs.definitions';
import {IState} from '../root.reducer';
import {IEntityById} from "../../definitions/generic/generic.definitions";

const _selectLastJob = (jobs: IEntityById<IJob>, agentId?:number ) => {
    let job: IJob | undefined;
    const jobValues = Object.values(jobs);

    if (agentId) {
        job = jobValues.filter(job => job.agent_id === agentId).sort((a, b) => b.id - a.id)[0];
    } else {
        job = jobValues.sort((a, b) => b.id - a.id)[0];
    }

    // only return job if newer than 24 hours
    if (job?.created && job.created >= moment().subtract(1, 'd').unix()) {
        return job;
    }
};

export const selectLastJob = createSelector(
    [
        (state: IState) => state.entities.jobs.byId,
        (state: IState, agentId?: number) => agentId,
        (state: IState, agentId?: number, from?: number) => from,
    ],
    _selectLastJob,
);

export const selectJobErrors = (state: IState) => state.entities.jobs.errors;

const _getJobErrorsByJobId = (jobId?: number, errors?: IJobError[]): IJobError[] | undefined =>
    errors && jobId ? errors.filter((error: IJobError) => error.job_id === jobId) : undefined;

export const getJobErrorsByJobId = createSelector(
    [(state: IState, jobId?: number) => jobId, selectJobErrors],
    _getJobErrorsByJobId,
);
