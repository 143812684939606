export const STATISTICS_CHATS_GET = 'STATISTICS_CHATS_GET';
export const STATISTICS_CHATS_GET_PENDING = 'STATISTICS_CHATS_GET_PENDING';
export const STATISTICS_CHATS_GET_FULFILLED = 'STATISTICS_CHATS_GET_FULFILLED';
export const STATISTICS_CHATS_GET_REJECTED = 'STATISTICS_CHATS_GET_REJECTED';

export const STATISTICS_GET_BLACKLIST = 'STATISTICS_GET_BLACKLIST';
export const STATISTICS_GET_BLACKLIST_PENDING = 'STATISTICS_GET_BLACKLIST_PENDING';
export const STATISTICS_GET_BLACKLIST_FULFILLED = 'STATISTICS_GET_BLACKLIST_FULFILLED';
export const STATISTICS_GET_BLACKLIST_REJECTED = 'STATISTICS_GET_BLACKLIST_REJECTED';

export const STATISTICS_TICKETS_GET = 'STATISTICS_TICKETS_GET';
export const STATISTICS_TICKETS_GET_PENDING = 'STATISTICS_TICKETS_GET_PENDING';
export const STATISTICS_TICKETS_GET_FULFILLED = 'STATISTICS_TICKETS_GET_FULFILLED';
export const STATISTICS_TICKETS_GET_REJECTED = 'STATISTICS_TICKETS_GET_REJECTED';

export const OLD_TICKET_STATS_GET = 'OLD_TICKET_STATS_GET';

export const OLD_TICKET_STATS_GET_FULFILLED = 'OLD_TICKET_STATS_GET_FULFILLED';


export const STATISTICS_CHATBOTS_GET = 'STATISTICS_CHATBOTS_GET';
export const STATISTICS_CHATBOTS_GET_PENDING = 'STATISTICS_CHATBOTS_GET_PENDING';
export const STATISTICS_CHATBOTS_GET_FULFILLED = 'STATISTICS_CHATBOTS_GET_FULFILLED';
export const STATISTICS_CHATBOTS_GET_REJECTED = 'STATISTICS_CHATBOTS_GET_REJECTED';

export const STATISTICS_MIA_GET = 'STATISTICS_MIA_GET';
export const STATISTICS_MIA_GET_PENDING = 'STATISTICS_MIA_GET_PENDING';
export const STATISTICS_MIA_GET_FULFILLED = 'STATISTICS_MIA_GET_FULFILLED';
export const STATISTICS_MIA_GET_REJECTED = 'STATISTICS_MIA_GET_REJECTED';

export const STATISTICS_CUSTOM_CHART_GET = 'STATISTICS_CUSTOM_CHART_GET';
export const STATISTICS_CUSTOM_CHART_GET_PENDING = 'STATISTICS_CUSTOM_CHART_GET_PENDING';
export const STATISTICS_CUSTOM_CHART_GET_FULFILLED = 'STATISTICS_CUSTOM_CHART_GET_FULFILLED';
export const STATISTICS_CUSTOM_CHART_GET_REJECTED = 'STATISTICS_CUSTOM_CHART_GET_REJECTED';

export const STATISTICS_AGENTS_GET = 'STATISTICS_AGENTS_GET';
export const STATISTICS_AGENTS_GET_PENDING = 'STATISTICS_AGENTS_GET_PENDING';
export const STATISTICS_AGENTS_GET_FULFILLED = 'STATISTICS_AGENTS_GET_FULFILLED';
export const STATISTICS_AGENTS_GET_REJECTED = 'STATISTICS_AGENTS_GET_REJECTED';

export const STATISTICS_AGENTS_GET_BY_ID = 'STATISTICS_AGENTS_GET_BY_ID';
export const STATISTICS_AGENTS_GET_BY_ID_FULFILLED = 'STATISTICS_AGENTS_GET_BY_ID_FULFILLED';

export const STATISTICS_MIA_USERS_GET = 'STATISTICS_MIA_USERS_GET';
export const STATISTICS_MIA_USERS_GET_PENDING = 'STATISTICS_MIA_USERS_GET_PENDING';
export const STATISTICS_MIA_USERS_GET_FULFILLED = 'STATISTICS_MIA_USERS_GET_FULFILLED';
export const STATISTICS_MIA_USERS_GET_REJECTED = 'STATISTICS_MIA_USERS_GET_REJECTED';

export const STATISTICS_NOTIFICATIONS_GET = 'STATISTICS_NOTIFICATIONS_GET';
export const STATISTICS_NOTIFICATIONS_GET_FULFILLED = 'STATISTICS_NOTIFICATIONS_GET_FULFILLED';
export const STATISTICS_NOTIFICATIONS_GET_PENDING = 'STATISTICS_NOTIFICATIONS_GET_PENDING';
export const STATISTICS_NOTIFICATIONS_GET_REJECTED = 'STATISTICS_NOTIFICATIONS_GET_REJECTED';

export const STATISTICS_LABELS_GET = 'STATISTICS_LABELS_GET';
export const STATISTICS_LABELS_GET_FULFILLED = 'STATISTICS_LABELS_GET_FULFILLED';
export const STATISTICS_LABELS_GET_PENDING = 'STATISTICS_LABELS_GET_PENDING';
export const STATISTICS_LABELS_GET_REJECTED = 'STATISTICS_LABELS_GET_REJECTED';

export const STATISTICS_CHATBLOCKS_GET = 'STATISTICS_CHATBLOCKS_GET';
export const STATISTICS_CHATBLOCKS_GET_FULFILLED = 'STATISTICS_CHATBLOCKS_GET_FULFILLED';
export const STATISTICS_CHATBLOCKS_GET_PENDING = 'STATISTICS_CHATBLOCKS_GET_PENDING';
export const STATISTICS_CHATBLOCKS_GET_REJECTED = 'STATISTICS_CHATBLOCKS_GET_REJECTED';

export const STATISTICS_AUTOMATION_GET = 'STATISTICS_AUTOMATION_GET';
export const STATISTICS_AUTOMATION_GET_PENDING = 'STATISTICS_AUTOMATION_GET_PENDING';
export const STATISTICS_AUTOMATION_GET_FULFILLED = 'STATISTICS_AUTOMATION_GET_FULFILLED';
export const STATISTICS_AUTOMATION_GET_REJECTED = 'STATISTICS_AUTOMATION_GET_REJECTED';

export const STATISTICS_CLICKS_GET = 'STATISTICS_CLICKS_GET';
export const STATISTICS_CLICKS_GET_PENDING = 'STATISTICS_CLICKS_GET_PENDING';
export const STATISTICS_CLICKS_GET_FULFILLED = 'STATISTICS_CLICKS_GET_FULFILLED';
export const STATISTICS_CLICKS_GET_REJECTED = 'STATISTICS_CLICKS_GET_REJECTED';

export const STATISTICS_AGENTS_SET_FILTER = 'STATISTICS_AGENTS_SET_FILTER';

export const STATISTICS_SIMPLE_CHAT_GET = 'STATISTICS_SIMPLE_CHAT_GET';
export const STATISTICS_SIMPLE_CHAT_GET_FULFILLED = 'STATISTICS_SIMPLE_CHAT_GET_FULFILLED';

export const STATISTICS_WHATSAPP_CONVERSATIONS = 'STATISTICS_WHATSAPP_CONVERSATIONS';
export const STATISTICS_WHATSAPP_CONVERSATIONS_FULFILLED = 'STATISTICS_WHATSAPP_CONVERSATIONS_FULFILLED';

export const STATISTICS_CONVERSATIONS = 'STATISTICS_CONVERSATIONS';
export const STATISTICS_CONVERSATIONS_FULFILLED = 'STATISTICS_CONVERSATIONS_FULFILLED';

