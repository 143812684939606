import {FC} from 'react';

import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import {EIcons} from '../../../enums/icons/EIcons';
import {translateSlug} from '../../../utils/translation/translation.utils';
import {basename} from '../../../utils/global/global.utils';
import CustomIconButton from '../../CustomIconButton/CustomIconButton';
import HighlightSearchText from '../../HighlightSearchText/HighlightSearchText';
import IconFactory from '../../IconFactory/IconFactory';
import translations from '../../../providers/TranslationProvider/translations';

const useStyles = makeStyles(theme =>
    createStyles({
        mediaBoxContainer: {
            display: 'flex',
            alignItems: 'center',

            '& > *:last-child': {
                flexShrink: 0,
                margin: `0 ${theme.spacing(0.5)}px`,
            },
        },
        mediaBox: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: 4,
            background: theme.palette.action.focus,
            padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
            minHeight: 48,
            overflow: 'hidden',

            '& span': {
                marginLeft: theme.spacing(0.5),
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            },
        },
    }),
);

interface IDocumentContent {
    media: string;
    searchTerm?: string;
}

const DocumentContent: FC<IDocumentContent> = ({media, searchTerm}) => {
    const classes = useStyles();
    // use filename from media path
    const filename = basename(media);

    // handle unknown file types
    const buttonProps = media.endsWith('.null')
        ? {
              icon: EIcons.CANCEL,
              tooltip: translations.unsopported_file_type,
              onClick: () => null,
          }
        : {
              icon: EIcons.DOWNLOAD,
              tooltip: translateSlug('download_x', filename),
              onClick: () => window.open(media),
          };

    return (
        <Typography component="div" variant="caption" className={classes.mediaBoxContainer}>
            <div className={classes.mediaBox}>
                {IconFactory.getIconWithProps(EIcons.FILE_DOCUMENT, {fontSize: 'large'})}
                {filename && <HighlightSearchText text={` ${filename}`} searchTerm={searchTerm} />}
            </div>
            <CustomIconButton {...buttonProps} />
        </Typography>
    );
};

DocumentContent.displayName = 'DocumentContent';

export default DocumentContent;
