import {Reducer} from 'redux';
import {SETTINGS_GET_API_KEY_FULFILLED, SETTINGS_PUT_API_KEY_FULFILLED} from './api.types';
import {isUpdateStream} from "../../../utils/redux/redux.utils";
import {EUpdateStreamAction} from "../../../enums/updateStream/EUpdateStreamAction";
import {EUpdateStreamMethod} from "../../../enums/updateStream/EUpdateStreamMethod";

export interface IApiState {
    apiKey?: string;
}

const initialState: IApiState = {};

export const apiReducer: Reducer<IApiState> = (state = initialState, action) => {
    const {type, payload} = action;

    if (!payload) {
        return state;
    }

    if (type === SETTINGS_GET_API_KEY_FULFILLED || type === SETTINGS_PUT_API_KEY_FULFILLED) {
        return {
            ...state,
            apiKey: payload.apikey,
        };
    } else if (isUpdateStream(payload.message, EUpdateStreamAction.Channel, EUpdateStreamMethod.Put) && !!payload.message?.payload?.apikey) {
        return {
            ...state,
            apiKey: payload.message.payload.apikey,
        };
    }


    return state;
};
