import {RefObject, useCallback, useEffect, useReducer, useState} from 'react';

import counterReducer from '../reducer/counter.reducer';

const useImagesLoaded = (ref: RefObject<Element>) => {
    const [imagesLoadedCount, dispatchLoadedCount] = useReducer(counterReducer, 0);
    const [imagesErrorCount, dispatchErrorCount] = useReducer(counterReducer, 0);
    const [totalImagesCount, setTotalImagesCount] = useState(0);

    const onLoad = useCallback(() => {
        dispatchLoadedCount('increment');
    }, []);

    const onError = useCallback(() => {
        dispatchErrorCount('increment');
    }, []);

    const comparableImageSourcesString = [...(ref.current?.getElementsByTagName('img') || [])]
        .map(i => i.src)
        .sort()
        .join('');

    useEffect(() => {
        dispatchLoadedCount('reset');
        dispatchErrorCount('reset');

        const imageElements = ref.current?.getElementsByTagName('img') || [];

        setTotalImagesCount(imageElements.length);

        [...imageElements].forEach(img => {
            if (!img.complete) {
                img.removeEventListener('load', onLoad);
                img.removeEventListener('error', onError);
                img.addEventListener('load', onLoad, {once: true});
                img.addEventListener('error', onError, {once: true});
            } else {
                dispatchLoadedCount('increment');
            }
        });

        return () => {
            [...imageElements].forEach(img => {
                img.removeEventListener('load', onLoad);
                img.removeEventListener('error', onError);
            });
        };
    }, [comparableImageSourcesString, onError, onLoad, ref]);

    return {
        imagesLoaded: ref.current !== null && imagesLoadedCount + imagesErrorCount === totalImagesCount,
        imagesLoadedCount,
        imagesErrorCount,
        totalImagesCount,
    };
};

export default useImagesLoaded;
