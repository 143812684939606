import {FC} from 'react';

import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/HelpOutline';
import OverflowEllipsisTooltip from '../OverflowEllipsisTooltip/OverflowEllipsisTooltip';

import ZebraListValue from './ZebraListValue';
import {NECTARY_COLORS} from '../../theme/muiTheme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingBottom: theme.spacing(1),
            paddingTop: theme.spacing(1),

            '& > div:nth-child(even)': {
                //zebra effect
                backgroundColor: NECTARY_COLORS.snow600,
            },
        },
        chartRow: {
            display: 'flex',
            justifyContent: 'space-between',
            borderRadius: 4,
            minHeight: theme.spacing(6.5),
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
            margin: `0 ${theme.spacing(1)}px`,

            '&:hover $helpIcon': {
                color: theme.palette.text.secondary,
                transition: 'color 0.15s ease-out',
            },
        },
        label: {
            flex: 4,
            display: 'flex',
            alignItems: 'center',
        },
        helpIcon: {
            color: theme.palette.text.disabled,
            marginLeft: theme.spacing(1),
            transition: 'color 0.15s ease-in',
        },
        value: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(1),
            whiteSpace: 'nowrap',
            justifyContent: 'flex-end',
        },
    }),
);

export interface IZebraListEntry {
    label: string;
    value: any;
    description?: string;
    valueClickHandler?: () => void;
    valueTooltip?: string;
    hidden?: boolean;
}

interface IZebraList {
    data: IZebraListEntry[];
}

const ZebraList: FC<IZebraList> = ({data}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {data.map(item => {
                const {label, description, valueTooltip, valueClickHandler, value, hidden} = item;

                if (hidden) {
                    return null;
                }

                return (
                    <div key={label} className={classes.chartRow}>
                        <Typography variant="body2" className={classes.label} component="div">
                            <OverflowEllipsisTooltip>{label}</OverflowEllipsisTooltip>
                            {!!description && (
                                <Tooltip title={description} placement="right">
                                    <HelpIcon fontSize="small" className={classes.helpIcon} />
                                </Tooltip>
                            )}
                        </Typography>
                        <Typography variant="body2" className={classes.value} component="div">
                            <ZebraListValue
                                value={value}
                                valueClickHandler={valueClickHandler}
                                valueTooltip={valueTooltip}
                            />
                        </Typography>
                    </div>
                );
            })}
        </div>
    );
};

export default ZebraList;
