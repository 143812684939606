import {EChartInterval} from '../../enums/chart/EChartInterval';
import {EMessenger} from '../../enums/messenger/EMessenger';
import {EPanelDataTypes} from '../../enums/mia/EPanelDataTypes';
import {TPartialRecord} from '../generic/generic.definitions';
import {ILinkItem} from '../statistics/statistics.definitions';

export type TContentStatus = 'published' | 'draft' | 'scheduled';

export interface IMiaContent {
    id: number;
    active: boolean;
    created: number;
    created_by: string;
    notification?: string;
    error?: Array<string>;
    published: number;
    status: TContentStatus;
    targeting: number;
    text: string;
    buttons?: any;
    reached: number;
    clicks?: ILinkItem[];
    media?: string;
    media_type?: string;
    redirected_info?: string | null;
    promo?: string;
    pulled_from?: number;
    pushed_to?: number;
    teaser_to?: number;
    reached_by_messenger?: TPartialRecord<EMessenger, number>;
    pulled_from_by_messenger?: TPartialRecord<EMessenger, number>;
    pushed_to_by_messenger?: TPartialRecord<EMessenger, number>;
    teaser_to_by_messenger?: TPartialRecord<EMessenger, number>;
}

export interface ITargeting {
    id: number;
    name: string;
    count?: number;
}

export type TMiaStatistic = IMiaStatistic | 'no data';

export interface IMiaStatistic {
    name: string;
    total: {
        [key in EMessenger | 'total' | 'noData']?: number;
    };
    push?: {
        [key in EMessenger | 'total' | 'noData']?: number;
    };
    pull?: {
        [key in EMessenger | 'total' | 'noData']?: number;
    };
    year?: number;
    number?: string;
    interval_start?: number;
}

export interface IMiaStatisticsById {
    [key: string]: IMiaStatistic;
}

export interface IMiaStatisticsState {
    [EChartInterval.Days]: {
        ids: string[];
        byId: IMiaStatisticsById;
    };
    [EChartInterval.Weeks]: {
        ids: string[];
        byId: IMiaStatisticsById;
    };
    [EChartInterval.Months]: {
        ids: string[];
        byId: IMiaStatisticsById;
    };
}

export interface IMiaEdit {
    idToEdit: number | null;
    selectedAction: EPanelDataTypes;
}

export interface IMiaRequestState {
    loading: {
        statistic: boolean;
        content: {
            published: boolean;
            draft: boolean;
            scheduled: boolean;
            today: boolean;
        };
        targeting: boolean;
    };
}

export enum EMiaSettingsFormFields {
    StartWord = 'startword',
    EveryWordStarts = 'every_word_starts',
    LatestContent = 'content_pulled_all',
    ContentPullTime = 'content_pull_time',
    ContentPullNumber = 'content_pull_num',
    NotifyInstalled = 'notify_installed',
    MiaLogoffConfirmationText = 'goodbye_message',
    WelcomeMessageMia = 'welcomemessage_mia',
    WelcomeMessageMiaNotify = 'welcomemessage_mia_notify',
}
