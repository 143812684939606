import React, {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import EditChart from '../../components/EditChart/EditChart';
import PaperTemplate from '../../components/PaperTemplate/PaperTemplate';
import PaperTitleBar from '../../components/PaperTitleBar/PaperTitleBar';
import translations from '../../providers/TranslationProvider/translations';
import ZebraList from '../../components/ZebraList/ZebraList';
import {IPeriodData} from '../../definitions/statistics/statistics.definitions';
import {EChartAgentType} from '../../enums/chart/EChartAgentType';
import {EChartPeriod} from '../../enums/chart/EChartPeriod';
import {updateActorSettings} from '../../redux/actor/actor.actions';
import {getDashboardAgentsStatsPeriodData} from '../../redux/actor/actor.selectors';
import {IState} from '../../redux/root.reducer';
import {getAgentStatisticById} from '../../redux/statistics/statistics.actions';
import {getAbsoluteValue, getAverageDuration} from '../../utils/statistic/statistic.utils';
import {StatisticsPeriodInfo} from '../StatisticsPage/StatisticPanels/StatisticsPeriodInfo';

const DashboardAgentStats: FC = () => {
    const dispatch = useDispatch();

    const periodData = useSelector(getDashboardAgentsStatsPeriodData);
    const agent_id = useSelector((state: IState) => state.actor.id);
    const actorStats = useSelector((state: IState) => state.statistics.agents.stats[agent_id]);

    const {from, to} = periodData;

    useEffect(() => {
        dispatch(getAgentStatisticById({from, to, agent_id}));
    }, [dispatch, from, to, agent_id]);

    const handleChange = (period: IPeriodData) => {
        dispatch(updateActorSettings({dashboardAgentStats: {...period}}, true));
    };

    const listData =
        [
            {
                label: translations[EChartAgentType.AbsoluteTicketsClosed],
                value: actorStats ? getAbsoluteValue(actorStats.absolute_tickets_closed) : '-',
            },
            {
                label: translations[EChartAgentType.AbsoluteTicketsInvolved],
                value: actorStats ? getAbsoluteValue(actorStats.absolute_tickets_involved) : '-',
            },
            {
                label: translations.average_time_actor_active_in_ticket,
                value: actorStats ? getAverageDuration(actorStats.average_time_active_in_ticket) : '-',
            },
            {
                label: translations[EChartAgentType.OutgoingChats],
                value: actorStats ? getAbsoluteValue(actorStats.outgoing_chats_in_ticket) : '-',
            },
        ];

    return (
        <PaperTemplate
            stretch
            noPadding
            titleBar={
                <PaperTitleBar title={translations.dashboard_agent_stats}>
                    <StatisticsPeriodInfo periodFrom={from} periodTo={to} />
                    <EditChart
                        periodData={periodData}
                        onChangePeriod={handleChange}
                        forbiddenChartPeriods={[EChartPeriod.InvoicingPeriod]}
                    />
                </PaperTitleBar>
            }
        >
            <ZebraList data={listData} />
        </PaperTemplate>
    );
};

export default DashboardAgentStats;
