import SvgIcon, {SvgIconProps} from '@material-ui/core/SvgIcon';
import Tooltip, {TooltipProps} from '@material-ui/core/Tooltip';

import translations from '../../../providers/TranslationProvider/translations';

export interface ICustomSmileyProps extends SvgIconProps {
    path: string;
    tooltip?: string;
    eyes?: {cx: string, cy: string, r: string}[];
    tooltipProps?: TooltipProps;
}

// Wrapper for custom icons with tooltip option
const CustomSmiley = ({classes, tooltip, tooltipProps, path, eyes, ...iconProps}: ICustomSmileyProps) => {
    const Icon = (
        <SvgIcon fontSize="inherit" {...iconProps} classes={classes}>
            {eyes?.map((eyeConfig, index) => (
                <circle key={index} cx={eyeConfig.cx} cy={eyeConfig.cy} r={eyeConfig.r} />
            ))}
            <path d={path} />
        </SvgIcon>
    );

    return !tooltip ? (
        Icon
    ) : (
        <Tooltip title={`${translations.mood_tendency}: ${tooltip}`} {...tooltipProps} children={Icon} />
    );
};

export default CustomSmiley;
