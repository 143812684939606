// @ts-ignore
import shortHash from 'shorthash';

import {buildSortedFilterString} from './buildSortedFilterString';

const createRequestHash = (requestObject?: any) => {
    if (!requestObject) {
        return '_default_';
    }

    // remove offset, so we get the same request hash for every page of a paginated request
    const {offset, limit, ...alteredRequestObject} = requestObject;

    const requestHash = shortHash.unique(buildSortedFilterString(alteredRequestObject));
    return requestHash || '_default_';
};

export default createRequestHash;
