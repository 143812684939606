import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {FC, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import ContainedButton from '../../components/Buttons/ContainedButton';
import CustomIconButton from '../../components/CustomIconButton/CustomIconButton';
import IconFactory from '../../components/IconFactory/IconFactory';
import translations from '../../providers/TranslationProvider/translations';
import {QUERY_PARAM_ONBOARDING_WIZARD, QUERY_PARAM_SWITCH_CHANNEL} from '../../definitions/router/router.definitions';
import {EIcons} from '../../enums/icons/EIcons';
import {EBaseRoutes} from '../../enums/routes/ERoutes';
import {IState} from '../../redux/root.reducer';
import {getUrlParameter} from '../../utils/global/global.utils';
import LoadingPage from '../LoadingPage/LoadingPage';
import {Drawer} from './Drawer/Drawer';
import HeaderBar from './HeaderBar/HeaderBar';
import ModularContent from '../../components/ModularContent/ModularContent';
import {usesMia, usesSimpleChat, usesTicketSystem} from '../../redux/channel/channel.selectors';
import {isSSOEnabled} from "../../redux/ui/ui.selectors";
import {urlNeedsAuth} from "../../index";
import {clearSessionInStorage} from "../../utils/storage/storage.utils";
import {Button, Typography} from "@material-ui/core";
import {Info} from "@material-ui/icons";
import useSEOnboarding from "../../utils/hooks/useSEOnboarding";

const useStyles = makeStyles<Theme, {
    isSimpleChatActive: boolean,
    isOnlyMia: boolean,
}>((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            overflow: 'hidden',
            display: 'flex',
            height: '100%',
            maxHeight: '100%',
            zIndex: 1,

            [theme.breakpoints.down('xs')]: {
                overflow: 'auto',
                position: 'static',
                // we need a z-index to make the whole page content "relative",
                // otherwise every element with a z-index gets "over" the drawer backdrop
                // making it won't work on mobile devices
                zIndex: 0,
            },
        },
        icon: {
            width: 32,
            height: 32,
        },
        // DON'T TOUCH BEFORE READING
        // YES I MEAN YOU! READ.THE.COMMENT!!
        // if you make changes to this content class -> take HeaderBar root into consideration since the 2 Grid systems have to work together correctly!
        // And please don't try to handle scrolling and padding globally here... make adjustments in the different
        // pages, since they all have different scrolling mechanisms (i.e. dashboard, chat page, settings, tables).
        content: {
            display: 'flex', // make autoPageSize work for DataGrid
            // overflow: 'hidden',
            // overflowX: 'hidden',
            height: '100%',
            width: '100%',
            maxHeight: '100%',
            paddingTop: ({isSimpleChatActive, isOnlyMia}) => theme.spacing(isSimpleChatActive || isOnlyMia ? 2 : 8),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            paddingLeft: 96,

            [theme.breakpoints.down('xs')]: {
                position: 'static',
                paddingLeft: theme.spacing(2),

                '& > div': {
                    maxHeight: 'none',
                    alignContent: 'flex-start',
                },
            },
        },
        infoBar: {
            display: 'flex',
            alignItems: 'center',
            padding: '8px 16px',
            gap: 8,
            position: "absolute",
            right: 0,
            minHeight: theme.spacing(6),
            top: ( {isSimpleChatActive}) => isSimpleChatActive ? 0 : theme.spacing(6),
            left: theme.spacing(10),
            background: '#FFE8E4',

            [theme.breakpoints.down('xs')]: {
                left: 0,
            },

            '& > svg': {
                alignSelf: 'flex-start',
                marginTop: 6,
                color: theme.palette.info.main,
            },
        },
        infoBarContent: {
            display: 'flex',
            gap: 8,
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',

            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
            },

            '& > p': {
                paddingTop: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.5),
            },
            '& > button': {
                background: 'white !important',
                fontWeight: 'bold',
                textTransform: 'none',
                borderColor: 'black',
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                whiteSpace: 'nowrap',
                flexShrink: 0,
            },
        },
        wizardButtonWrapper: {
            position: 'fixed',
            top: 0,
            right: 0,
            left: 56,
            height: 50,
            background: '#f5f5f5', // use background color of header bar to align with mobile menu
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999999,
            textAlign: 'center',

            '& > button': {
                marginLeft: '16px !important',
                flexShrink: 0,
            },

            [theme.breakpoints.down('xs')]: {
                height: 48,
                left: 50,
                paddingRight: theme.spacing(1),
                fontSize: 14,
            },
        },
    }),
);

interface IBasePageInnerProps {
    children: any;
    globalDataLoaded?: boolean;
}

const BasePage: FC<IBasePageInnerProps> = ({children, globalDataLoaded = true}) => {
    const _usesSimpleChat = useSelector(usesSimpleChat);
    const _usesTicketSystem = useSelector(usesTicketSystem);
    const _usesMia = useSelector(usesMia);
    const isOnlyMia = _usesMia && !_usesSimpleChat && !_usesTicketSystem;
    const classes = useStyles({isSimpleChatActive: _usesSimpleChat, isOnlyMia});
    const history = useHistory();
    const location = useLocation();

    const clearLocalStorage = location.pathname.startsWith(EBaseRoutes.ResetPassword)
        || location.pathname.startsWith(EBaseRoutes.ForgotPassword)
        || location.pathname.startsWith(EBaseRoutes.Registration);

    const isOnboardingWizardEnabled = Boolean(getUrlParameter(QUERY_PARAM_ONBOARDING_WIZARD));

    const isXsDevice = useMediaQuery((theme: Theme) => theme?.breakpoints.only('xs'));

    const ssoEnabled = useSelector(isSSOEnabled);
    const actor = useSelector((state: IState) => state.actor);
    const actorId = actor.id;
    const loggingOut = actor.loggingOut;
    const loggingIn = actor.loggingIn || (ssoEnabled && !actorId && urlNeedsAuth(location.pathname) && actor.loggingOut);
    const initializing = actorId && !globalDataLoaded;
    const switchingChannel = !!getUrlParameter(QUERY_PARAM_SWITCH_CHANNEL);

    const showLoader = loggingOut || initializing || loggingIn || switchingChannel;
    const loaderMessage = switchingChannel
        ? translations.switching_channel_message
        : loggingOut
            ? translations.logging_out_message
            : initializing
                ? translations.initializing_message
                : loggingIn
                    ? translations.loggin_in_message
                    : translations.done;

    useEffect(() => {
        if (clearLocalStorage) {
            clearSessionInStorage();
        }
    }, [clearLocalStorage]);

    const handleGoToDashboard = () => history.push(EBaseRoutes.Dashboard);

    // don't show content while initializing to prevent unnecessary fetches
    const renderContent = !initializing;

    const contentRef = useRef<HTMLDivElement>(null);
    const infoBarRef = useRef<HTMLDivElement>(null);

    const showSEOnboarding = useSEOnboarding();

    useEffect(() => {
        if (!showSEOnboarding) {
            return;
        }

        const fixPadding = () => {
            if (!infoBarRef.current || !contentRef.current) {
                return;
            }
            contentRef.current.style.paddingTop = `${infoBarRef.current.clientHeight + infoBarRef.current.offsetTop + 12}px`;
        };

        window.addEventListener('resize', fixPadding);
        fixPadding();

        return () => window.removeEventListener('resize', fixPadding);
    }, [showLoader, renderContent, showSEOnboarding]);

    return (
        <>
            <LoadingPage text={loaderMessage} show={showLoader}/>
            {!actorId ? (
                // public stuff
                children
            ) : (
                // Stuff that needs authentication
                <>
                    <ModularContent hideForSimpleChat hideForMiaOnly>
                        <HeaderBar/>
                    </ModularContent>
                    {renderContent && <Drawer/>}
                    <div className={classes.root}>
                        <div className={classes.content} ref={contentRef}>
                            {showSEOnboarding && (
                                <div className={classes.infoBar} ref={infoBarRef}>
                                    <Info fontSize={'small'}/>
                                    <div className={classes.infoBarContent}>
                                        <Typography variant={"body2"}>
                                            {translations.global_SEOnboarding_text}
                                        </Typography>
                                        <Button
                                            size={"small"}
                                            variant="outlined"
                                            onClick={() => window.location.href = process.env.REACT_APP_SINCH_ENGAGE_DOMAIN!}
                                        >
                                            {translations.global_SEOnboarding_button}
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {renderContent && children}
                        </div>
                    </div>
                    {renderContent && isOnboardingWizardEnabled && (
                        <div className={classes.wizardButtonWrapper}>
                            {translations.onboarding_wizard_bar_text}
                            {isXsDevice ? (
                                <CustomIconButton icon={EIcons.DASHBOARD} onClick={handleGoToDashboard}/>
                            ) : (
                                <ContainedButton
                                    startIcon={IconFactory.getIconWithProps(EIcons.DASHBOARD)}
                                    label={translations.dashboard}
                                    onClick={handleGoToDashboard}
                                    color="primary"
                                />
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default BasePage;
