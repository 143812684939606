import React, {ReactElement} from 'react';

import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {
    ControlProps,
    IndicatorProps,
    MenuProps,
    MultiValueProps,
    OptionProps,
    SingleValueProps,
    ValueContainerProps,
} from 'react-select';

import {EIcons} from '../../../enums/icons/EIcons';
import IconFactory from '../../IconFactory/IconFactory';

const NoOptionsMessage = (props: any) => (
    <Typography color='textSecondary' className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
        {props.children}
    </Typography>
);

const inputComponent = ({inputRef, ...props}: any) => <div ref={inputRef} {...props} />;

const Control = (props: ControlProps<any, boolean>) => {
    const value = props.selectProps.isMulti
        ? props.selectProps.value && props.selectProps.value.length
            ? ' '
            : null
        : props.selectProps.value.label;

    return (
        <TextField
            className={props.selectProps.classes.textField}
            placeholder=' '
            label={props.selectProps.label}
            value={value}
            InputProps={{
                ...(props.selectProps.InputProps || {}),
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            InputLabelProps={{shrink: true}}
            {...props.selectProps.textFieldProps}
        />
    );
};

const Option = (props: OptionProps<any, boolean>) => (
    <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component='div'
        style={{
            fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
    >
        {props.children}
    </MenuItem>
);

const Placeholder = () => null;

const SingleValue = (props: SingleValueProps<any>) => (
    <Typography variant='subtitle1' className={props.selectProps.classes.singleValue} {...props.innerProps}>
        {props.children}
    </Typography>
);

const ValueContainer = (props: ValueContainerProps<any, boolean>) => (
    <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
);

const MultiValue = (props: MultiValueProps<any>) => (
    <Tooltip title={props.children as ReactElement}>
        <Chip
            classes={{
                root: props.selectProps.classes.multiRoot,
                label: props.selectProps.classes.multiLabel,
                outlined: props.selectProps.classes.multiOutlined,
            }}
            variant='outlined'
            onDelete={props.removeProps.onClick}
            deleteIcon={IconFactory.getIconWithProps(EIcons.CANCEL, {
                className: props.selectProps.classes.deleteButton,
            })}
            label={props.children}
        />
    </Tooltip>
);

const Menu = (props: MenuProps<any, boolean>) => (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
        {props.children}
    </Paper>
);

const DropdownIndicator = (props: IndicatorProps<any, boolean>) => {
    if (props.selectProps.InputProps && props.selectProps.InputProps.endAdornment) {
        return null;
    }
    return <ArrowDropDownIcon className={props.selectProps.classes.dropdown} />;
};

const ClearIndicator = () => null;

const Components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    DropdownIndicator,
    ClearIndicator,
    inputComponent,
};

export default Components;
