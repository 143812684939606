export const ACCOUNT_GET = 'ACCOUNT_GET';
export const ACCOUNT_GET_PENDING = 'ACCOUNT_GET_PENDING';
export const ACCOUNT_GET_REJECTED = 'ACCOUNT_GET_REJECTED';
export const ACCOUNT_GET_FULFILLED = 'ACCOUNT_GET_FULFILLED';

export const ACCOUNT_UPDATE = 'ACCOUNT_UPDATE';

export const ACCOUNT_UPDATE_FULFILLED = 'ACCOUNT_UPDATE_FULFILLED';


export const ACCOUNT_GET_PAYMENT_SOURCES_FULFILLED = 'ACCOUNT_GET_PAYMENT_SOURCES_FULFILLED';

export const ACCOUNT_SET_DEFAULT_PAYMENT_SOURCE = 'ACCOUNT_SET_DEFAULT_PAYMENT_SOURCE';
// export const ACCOUNT_SET_DEFAULT_PAYMENT_SOURCE_PENDING = 'ACCOUNT_SET_DEFAULT_PAYMENT_SOURCE_PENDING';
export const ACCOUNT_SET_DEFAULT_PAYMENT_SOURCE_FULFILLED = 'ACCOUNT_SET_DEFAULT_PAYMENT_SOURCE_FULFILLED';
// export const ACCOUNT_SET_DEFAULT_PAYMENT_SOURCE_REJECTED = 'ACCOUNT_SET_DEFAULT_PAYMENT_SOURCE_REJECTED';

export const ACCOUNT_REMOVE_PAYMENT_SOURCE = 'ACCOUNT_REMOVE_PAYMENT_SOURCE';
// export const ACCOUNT_REMOVE_PAYMENT_SOURCE_PENDING = 'ACCOUNT_REMOVE_PAYMENT_SOURCE_PENDING';
export const ACCOUNT_REMOVE_PAYMENT_SOURCE_FULFILLED = 'ACCOUNT_REMOVE_PAYMENT_SOURCE_FULFILLED';
// export const ACCOUNT_REMOVE_PAYMENT_SOURCE_REJECTED = 'ACCOUNT_REMOVE_PAYMENT_SOURCE_REJECTED';

export const ACCOUNT_PRICING_INFO_GET = 'ACCOUNT_PRICING_INFO_GET';
export const ACCOUNT_PRICING_INFO_GET_REJECTED = 'ACCOUNT_PRICING_INFO_GET_REJECTED';
export const ACCOUNT_PRICING_INFO_GET_PENDING = 'ACCOUNT_PRICING_INFO_GET_PENDING';
export const ACCOUNT_PRICING_INFO_GET_FULFILLED = 'ACCOUNT_PRICING_INFO_GET_FULFILLED';

export const ACCOUNT_PROJECT_PRICING_INFO_GET = 'ACCOUNT_PROJECT_PRICING_INFO_GET';


export const ACCOUNT_PROJECT_PRICING_INFO_GET_FULFILLED = 'ACCOUNT_PROJECT_PRICING_INFO_GET_FULFILLED';

export const ACCOUNT_PAYMENT_INFO_GET = 'ACCOUNT_PAYMENT_INFO_GET';
export const ACCOUNT_PAYMENT_INFO_GET_PENDING = 'ACCOUNT_PAYMENT_INFO_GET_PENDING';
export const ACCOUNT_PAYMENT_INFO_GET_REJECTED = 'ACCOUNT_PAYMENT_INFO_GET_REJECTED';
export const ACCOUNT_PAYMENT_INFO_GET_FULFILLED = 'ACCOUNT_PAYMENT_INFO_GET_FULFILLED';

export const ACCOUNT_PURCHASE = 'ACCOUNT_PURCHASE';
// export const ACCOUNT_PURCHASE_PENDING = 'ACCOUNT_PURCHASE_PENDING';
// export const ACCOUNT_PURCHASE_REJECTED = 'ACCOUNT_PURCHASE_REJECTED';
export const ACCOUNT_PURCHASE_FULFILLED = 'ACCOUNT_PURCHASE_FULFILLED';

export const ACCOUNT_COSTS_GET = 'ACCOUNT_COSTS_GET';
export const ACCOUNT_COSTS_GET_PENDING = 'ACCOUNT_COSTS_GET_PENDING';
export const ACCOUNT_COSTS_GET_FULFILLED = 'ACCOUNT_COSTS_GET_FULFILLED';
export const ACCOUNT_COSTS_GET_REJECTED = 'ACCOUNT_COSTS_GET_REJECTED';

export const ACCOUNT_INFO_SET = 'ACCOUNT_INFO_SET';
export const ACCOUNT_INFO_SET_FULFILLED = 'ACCOUNT_INFO_SET_FULFILLED';
export const ACCOUNT_INFO_SET_REJECTED = 'ACCOUNT_INFO_SET_REJECTED';

export const ACCOUNT_INFO_GET = 'ACCOUNT_INFO_GET';
export const ACCOUNT_INFO_GET_PENDING = 'ACCOUNT_INFO_GET_PENDING';
export const ACCOUNT_INFO_GET_REJECTED = 'ACCOUNT_INFO_GET_REJECTED';
export const ACCOUNT_INFO_GET_FULFILLED = 'ACCOUNT_INFO_GET_FULFILLED';
