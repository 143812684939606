import {combineReducers} from 'redux';

import {contentReducer} from './content/content.reducer';
import {editReducer} from './edit/edit.reducer';
import {requestReducer} from './request/request.reducer';
import {statisticReducer} from './statistic/statistic.reducer';
import {targetingReducer} from './targeting/targeting.reducer';

export const miaReducer = combineReducers({
    content: contentReducer,
    edit: editReducer,
    statistic: statisticReducer,
    request: requestReducer,
    targeting: targetingReducer,
});
