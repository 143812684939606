import {ActionType} from "redux-promise-middleware";

export const TICKET_GET_BY_ID = 'TICKET_GET_BY_ID';
export const TICKET_GET_BY_ID_PENDING = `${TICKET_GET_BY_ID}_${ActionType.Pending}`;
export const TICKET_GET_BY_ID_FULFILLED = `${TICKET_GET_BY_ID}_${ActionType.Fulfilled}`;
export const TICKET_GET_BY_ID_REJECTED = `${TICKET_GET_BY_ID}_${ActionType.Rejected}`;
export const TICKETS_GET = 'TICKETS_GET';
export const TICKETS_GET_PENDING = 'TICKETS_GET_PENDING';
export const TICKETS_GET_REJECTED = 'TICKETS_GET_REJECTED';
export const TICKETS_GET_FULFILLED = 'TICKETS_GET_FULFILLED';
export const TICKET_PREVIEW_GET = 'TICKET_PREVIEW_GET';
export const TICKET_ASSIGN = 'TICKET_ASSIGN';
export const TICKET_ASSIGN_FULFILLED = 'TICKET_ASSIGN_FULFILLED';
export const TICKET_CLOSE = 'TICKET_CLOSE';
export const TICKET_CLOSE_FULFILLED = 'TICKET_CLOSE_FULFILLED';
export const TICKET_OPEN = 'TICKET_OPEN';
export const TICKET_OPEN_FULFILLED = 'TICKET_OPEN_FULFILLED';
export const TICKET_REVOKE = 'TICKET_REVOKE';
export const TICKET_POSTPONE = 'TICKET_POSTPONE';
export const TICKET_UPDATE = 'TICKET_UPDATE';
export const TICKET_PUT_LABEL_TO_TICKET = 'TICKET_PUT_LABEL_TO_TICKET';
export const TICKET_PUT_LABELS_TO_TICKETS = 'TICKET_PUT_LABELS_TO_TICKETS';
export const TICKET_PUT_LABELS_TO_TICKETS_PENDING = 'TICKET_PUT_LABELS_TO_TICKETS_PENDING';
export const TICKET_DELETE_LABEL_FROM_TICKET = 'TICKET_DELETE_LABEL_FROM_TICKET';

export const TICKET_CREATE_NOTE = 'TICKET_CREATE_NOTE';
export const TICKET_UPDATE_NOTE = 'TICKET_UPDATE_NOTE';
export const TICKET_UPDATE_NOTE_FULFILLED = 'TICKET_UPDATE_NOTE_FULFILLED';
export const TICKET_DELETE_NOTE = 'TICKET_DELETE_NOTE';
