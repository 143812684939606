import {getLastJobRequest, IJobRequestParams} from '../../requests/jobsRequests';
import {JOBS_GET_LAST} from './jobs.types';


export const getLastJob = (params: IJobRequestParams) => ({
    type: JOBS_GET_LAST,
    payload: getLastJobRequest(params),
});


