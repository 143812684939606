import {EApprovalStatus, ITemplate, ITemplateTranslations} from '@sinch-engage/whatsapp-message-template-editor';
import {createSelector} from 'reselect';

import translations from '../../../providers/TranslationProvider/translations';
import {ITemplatesItems} from '../../../definitions/notificationTemplate/notificationTemplate.definitions';
import {IState} from '../../root.reducer';

export const getTranslations = (template: ITemplate) =>
    (template && template.translations) || ({} as ITemplateTranslations[]);



const getSettingsNotificationTemplatesItems = (state: IState) => state.settings.notificationTemplates.items;

const _getVisibleAndApprovedNotifications = (notificationTemplates: ITemplatesItems = {}): ITemplate[] =>
    Object.values(notificationTemplates)
        .filter(template => Boolean(template.visible_for_agents))
        .filter(
            // check for at least 1 approved translation
            template => Object.values(getTranslations(template)).filter(
                translation => translation.status === EApprovalStatus.Approved,
            ).length,
        )
        .reduce((result: ITemplate[], template: ITemplate) => {
            const templateTranslations = getTranslations(template);
            const approvedTranslations = Object.keys(templateTranslations).reduce(
                (approvedResult: any, language: string) => {
                    if (templateTranslations[language].status === EApprovalStatus.Approved) {
                        approvedResult[language] = templateTranslations[language];
                    }
                    return approvedResult;
                },
                {},
            );

            if (Object.keys(approvedTranslations).length) {
                const templateWithApprovedTranslationsOnly = {
                    ...template,
                    translations: approvedTranslations,
                };
                return [...result, templateWithApprovedTranslationsOnly];
            }
            return result;
        }, []);

export const getVisibleAndApprovedNotifications = createSelector(
    [getSettingsNotificationTemplatesItems],
    _getVisibleAndApprovedNotifications,
);

export const getCategoryName = (category: string): string => translations[`CATEGORY_NAME_${category}`] || category;

export const getCategoryDescription = (category: string): string =>
    translations[`CATEGORY_DESCRIPTION_${category}`] || category;

export const getIsNotificationTemplatesFetching = (state: IState) => state.settings.notificationTemplates.fetching;
