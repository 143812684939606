export const browserSupportsNotifications = () => !!window.Notification;

export const NotificationPermission = {
    UNSUPPORTED: '__unsupported',
    GRANTED: 'granted',
    DEFAULT: 'default',
    DENIED: 'denied',
};

export const isGranted = () => getNotificationPermission() === NotificationPermission.GRANTED;

export const getNotificationPermission = () => {
    if (!browserSupportsNotifications()) {
        return NotificationPermission.UNSUPPORTED;
    }
    switch (window.Notification.permission) {
        case NotificationPermission.GRANTED:
            return NotificationPermission.GRANTED;
        case NotificationPermission.DENIED:
            return NotificationPermission.DENIED;
        default:
            break;
    }
    return NotificationPermission.DEFAULT;
};
