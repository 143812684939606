import {Reducer} from 'redux';

import {ISelectedState} from '../../definitions/selected/selectedState.definitions';
import {SELECT_USER_ON_USER_PAGE} from './selected.types';

export const selectedInitialState = {
    userPage: {
        selectedUserId: null,
    },
};

export const selectedReducer: Reducer<ISelectedState> = (state = selectedInitialState, action) => {
    switch (action.type) {
        case SELECT_USER_ON_USER_PAGE: {
            const payload = action.payload as number;

            return {...state, userPage: {...state.userPage, selectedUserId: payload}};
        }
        default:
            break;
    }
    return state;
};
