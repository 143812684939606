export enum EWidgetTypeId {
    Unknown,
    Card,
    Chip,
    Cube,
    LiveChat,
}

export enum EWidgetCdnFileNames {
    LiveChat = 'livechat.js',
    Widget = 'widget.js',
    Chip = 'widget.chip.js',
    Cube = 'widget.cube.js',
    Card = 'widget.card.js',
}

export enum EWidgetDialogSteps {
    Basics,
    Privacy,
    Type,
    CardDesigner,
    CubeDesigner,
    ChipDesigner,
    CubeDesigner2,
    LiveChatDesigner,
    HtmlClipboard,
}

export enum EWidgetPrivacyCheck {
    Disabled,
    Enabled,
}

export enum ECubeStylingOptions {
    CustomButtonColorEnabled = 'customButtonColorEnabled',
    CubeHeight = 'cubeHeight',
    CubeWidth = 'cubeWidth',
}

export enum ECardNCubeConfigOption {
    Title = 'title',
    Body = 'body',
}

export enum ECardNCubeStylingOption {
    BackgroundColorLeft = 'backgroundColorLeft',
    BackgroundColorRight = 'backgroundColorRight',
    ButtonColor = 'buttonColor',
    ButtonTextColor = 'buttonTextColor',
    TextColor = 'textColor',
    ShowIMessageIconOnly = 'im_icon_only',
}

export enum EChipWidgetStylingOption {
    BackgroundColor = 'chipBackgroundColor',
    BackgroundColorHover = 'chipBackgroundColorHover',
    IconColor = 'chipIconColor',
    IconColorHover = 'chipIconColorHover',
    BackgroundImage = 'chipBackgroundImage',
    NotificationBubble = 'notificationBubble',
    ShowIMessageIconOnly = 'im_icon_only',
}

export enum EWidgetCommonConfigOption {
    ID = 'id',
    UniqueId = 'uniqueid',
    Name = 'name',
    Language = 'language',
    Messengers = 'messengers',
    PrivacyCheck = 'privacy_check',
    PrivacyLink = 'privacy_link',
    PrivacyText = 'privacy_text',
    WidgetType = 'widget_type_id',
}
