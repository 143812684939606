import {FC} from 'react';
import {useSelector} from 'react-redux';
import {usesMia, usesSimpleChat, usesTicketSystem} from '../../redux/channel/channel.selectors';

interface ModularContentProps {
    hideForSimpleChat?: boolean;
    hideForMiaOnly?: boolean;
    hideForTicketSystem?: boolean;
}

const ModularContent: FC<ModularContentProps> = (
    {
        children,
        hideForSimpleChat,
        hideForMiaOnly,
        hideForTicketSystem,
    }
) => {
    const _usesSimpleChat = useSelector(usesSimpleChat);
    const _usesTicketSystem = useSelector(usesTicketSystem);
    const _usesMia = useSelector(usesMia);
    const _useMiaOnly = _usesMia && !_usesSimpleChat && !_usesTicketSystem;

    if (hideForSimpleChat && _usesSimpleChat) {
        return null;
    }

    if (hideForTicketSystem && _usesTicketSystem) {
        return null;
    }

    if (hideForMiaOnly && _useMiaOnly) {
        return null;
    }

    return <>{children}</>;
};

export default ModularContent;
