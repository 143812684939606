import {Reducer} from 'redux';
import {TMiaEditActions} from '../../../definitions/mia/mia.actions.definitions';
import {IMiaEdit} from '../../../definitions/mia/mia.definitions';
import {EPanelDataTypes} from '../../../enums/mia/EPanelDataTypes';
import {MIA_DELETE_CONTENT_PENDING} from '../content/content.types';
import {MIA_SET_ACTION, MIA_SET_EDIT_ITEM, REDUX_FORM_RESET} from './edit.types';

export const editInitState: IMiaEdit = {
    idToEdit: null,
    selectedAction: EPanelDataTypes.Published,
};

export const editReducer: Reducer<IMiaEdit, TMiaEditActions> = (state = editInitState, action) => {
    switch (action.type) {
        case MIA_DELETE_CONTENT_PENDING:
            return {...state, idToEdit: null};
        case MIA_SET_EDIT_ITEM:
            return {
                ...state,
                idToEdit: action.payload.id,
                selectedAction: action.payload.type,
            };
        case MIA_SET_ACTION:
            return {
                ...state,
                selectedAction: action.payload,
            };
        case REDUX_FORM_RESET:
            return {
                ...state,
                idToEdit: null,
            };
        default:
            return state;
    }
};
