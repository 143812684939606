import {IEntityStateForUuids} from '../../definitions/generic/generic.definitions';
import {ITicket} from '../../definitions/tickets/tickets.definitions';
import {IUser} from '../../definitions/user/user.definitions';
import {EUpdateStreamAction} from '../../enums/updateStream/EUpdateStreamAction';
import {EUpdateStreamMethod} from '../../enums/updateStream/EUpdateStreamMethod';
import {isUpdateStream} from '../../utils/redux/redux.utils';
import {
    IUpdateStreamPayload,
    IUpdateStreamTicketPayload,
} from "../../definitions/updateStream/updateStream.definitions";
import {IChat} from '../../definitions/chat/chat.definitions';

export interface INewTicket extends Partial<ITicket> {
    id: number;
    chats: IChat[];
}

//
// Update Stream type checks
//
export const isOutgoingChat = ({message}: IUpdateStreamPayload) =>
    isUpdateStream(message, EUpdateStreamAction.Chat, EUpdateStreamMethod.Post);

export const isReceiveChat = ({message}: IUpdateStreamPayload) => isUpdateStream(message, EUpdateStreamAction.ChatReceive);

export const isOutgoingWbNotification = ({message}: IUpdateStreamPayload) =>
    isUpdateStream(message, EUpdateStreamAction.ChatNotification, EUpdateStreamMethod.Post);

export const isTicketUpdate = ({message}: IUpdateStreamPayload) =>
    isUpdateStream(message, EUpdateStreamAction.Ticket, EUpdateStreamMethod.Put);

export const isMultiChannelTicketUpdate = ({message}: IUpdateStreamPayload) =>
    isUpdateStream(message, EUpdateStreamAction.MultiChannel, '');

export const isChatUpdate = ({message}: IUpdateStreamPayload) =>
    isUpdateStream(message, EUpdateStreamAction.Chat, EUpdateStreamMethod.Put);

export const isUnreadChatUpdate = (payload: IUpdateStreamPayload) =>
    isChatUpdate(payload) && payload.message.payload.unread;

export const isChatDelete = ({message}: IUpdateStreamPayload) =>
    isUpdateStream(message, EUpdateStreamAction.Chat, EUpdateStreamMethod.Delete);

export const isTicketAdd = ({message}: IUpdateStreamPayload) =>
    isUpdateStream(message, EUpdateStreamAction.Ticket, EUpdateStreamMethod.Post);

export const isTicketClose = ({message}: IUpdateStreamPayload) => isUpdateStream(message, EUpdateStreamAction.TicketClose);

export const isTicketReOpen = ({message}: IUpdateStreamPayload) =>
    isUpdateStream(message, EUpdateStreamAction.TicketOpen, EUpdateStreamMethod.Put);

export const isTicketNote = ({message}: IUpdateStreamPayload) => isUpdateStream(message, EUpdateStreamAction.TicketNote);

export const isLabelAdd = ({message}: IUpdateStreamPayload) =>
    isUpdateStream(message, EUpdateStreamAction.TicketLabel, EUpdateStreamMethod.Put);

export const isLabelDelete = ({message}: IUpdateStreamPayload) =>
    isUpdateStream(message, EUpdateStreamAction.TicketLabel, EUpdateStreamMethod.Delete);

export const isChatUpdateStreamPayload = (payload: IUpdateStreamPayload): payload is IUpdateStreamPayload<'', IChat> => {
    if (isReceiveChat(payload)) {
        return true;
    }
    if (isOutgoingChat(payload)) {
        return true;
    }
    if (isOutgoingWbNotification(payload)) {
        return true;
    }
    if (isChatUpdate(payload)) {
        return true;
    }
    return isChatDelete(payload);
};

export const isUpdateStreamPayloadWithTicket = (
    payload: IUpdateStreamPayload,
): payload is IUpdateStreamPayload<'', IUpdateStreamTicketPayload> => {
    if (isTicketReOpen(payload)) {
        return true;
    }
    if (isTicketClose(payload)) {
        return true;
    }
    if (isTicketAdd(payload)) {
        return true;
    }

    return isTicketUpdate(payload);
};

export const isTicketNoteOrLabelAdd = (payload: IUpdateStreamPayload): payload is IUpdateStreamPayload<'', ITicket> => {
    if (isTicketNote(payload)) {
        return true;
    }
    if (isLabelAdd(payload)) {
        return true;
    }

    return isLabelDelete(payload);
};

//
// Utilities
//
export const getTicketWithSortedChats = (ticket: ITicket) => ({
    ...ticket,
    chats: sortChats(ticket.chats),
});

export const removeInterimMessage = (chats: IChat[], newChat: IChat) =>
    chats.filter(
        chat =>
            !(
                chat.interimMessage &&
                (
                    chat.interimUuid === newChat.interimUuid || // same interim id
                    chat.agent_id === newChat.agent_id || // same agent id
                    (chat.notification && newChat.notification === chat.notification) // same notification
                )
            ),
    );

export const sortChats = (chats: IChat[] = []) => {
    // filter out duplicate chats (reverse because new items will be at the end of the list)
    const uniqueChats = chats.reverse().reduce((result: IChat[], chat) => {
        if (!result.find(item => item.id === chat.id)) {
            result = [...result, chat];
        }
        return result;
    }, []);

    // sort chats by chat time. if chat time is same for two chats, then sort by id
    return uniqueChats.sort((a, b) => {
        if (a.chattime === b.chattime) {
            return b.id - a.id;
        }
        return b.chattime - a.chattime;
    });
};

export const updateUserNameInTicket = (name: string, ticket: ITicket) => ({...ticket, name});

export const updateUserInTicketList = (user: IUser, tickets: ITicket[]) => {
    return tickets.reduce((list: ITicket[], ticket) => {
        return [...list, ticket.user_id === user.id && user.name ? updateUserNameInTicket(user.name, ticket) : ticket];
    }, []);
};

export const updateUserName = (state: IEntityStateForUuids<ITicket>, payload: any) => {
    const {user_id, name} = payload.message.payload;

    // We have to update ALL tickets of the actor
    const updatedTickets = Object.values(state.byId).reduce((result, ticket) => {
        return {...result, [ticket.id]: ticket.user_id === user_id ? updateUserNameInTicket(name, ticket) : ticket};
    }, {});

    return {
        ...state,
        byId: updatedTickets,
        lastUpdated: Date.now(),
    };
};

//
// Merge utilities
//
export const updateSingleTicket = (stateTicket: ITicket, newTicket: INewTicket) => {
    // do nothing if it is not the same ticket
    if (stateTicket.id !== newTicket.id) {
        return stateTicket;
    }

    // merge with current single item state
    return {
        ...stateTicket,
        ...newTicket,
    } as ITicket;
};


export const addChat = (newChat: IChat, stateChats?: IChat[]) => {
    const mergedChats = [...(stateChats || []), newChat];

    // try to remove interim message
    const chats = removeInterimMessage(mergedChats, newChat);

    return sortChats(chats) as IChat[];
};

export const updateListItem = (
    state: IEntityStateForUuids<ITicket>,
    newTicket: INewTicket,
    newChat?: IChat,
    addToState?: boolean,
): IEntityStateForUuids<ITicket> => {
    const isTicketInState = !!state.byId[newTicket.id];

    // do nothing if ticket is not in the list
    if (!isTicketInState && !addToState) {
        return state;
    }

    // add new ticket object
    if (!isTicketInState && addToState) {
        return {
            ...state,
            byId: {
                ...state.byId,
                [String(newTicket.id)]: getTicketWithSortedChats(newTicket as ITicket),
            },
            ids: [...state.ids, String(newTicket.id)],
            lastUpdated: Date.now(),
        };
    }

    // merge chats
    const mergedChats = [...(state.byId[newTicket.id].chats || []), ...(newTicket.chats || [])];

    // if newChat is defined, try to remove interim message
    const chats = !newChat ? mergedChats : [newChat, ...removeInterimMessage(mergedChats, newChat)];

    // merge with item in the items list
    return {
        ...state,
        byId: {
            ...state.byId,
            [newTicket.id]: {
                ...state.byId[newTicket.id],
                ...newTicket,
                chats: sortChats(chats),
            },
        },
        lastUpdated: Date.now(),
    };
};

export const handleTicketRelatedUpdateStream = (
    state: any,
    payload: any,
    ticketUpdateHandler: (state: any, newTicket: any, newChat?: IChat, addToState?: boolean) => any,
) => {
    if (isReceiveChat(payload)) {
        const newChat = payload.message.payload;
        const newTicket = {id: newChat.ticket_id || newChat.user_id, chats: [newChat]};

        return ticketUpdateHandler(state, newTicket);
    }
    if (isOutgoingChat(payload) || isOutgoingWbNotification(payload)) {
        const newChat = payload.message.payload;
        const newTicket = {id: newChat.ticket_id || newChat.user_id, chats: [newChat]};

        return ticketUpdateHandler(state, newTicket);
    }
    if (isTicketNote(payload) || isLabelAdd(payload) || isLabelDelete(payload)) {
        const {...newTicket} = payload.message.payload;

        return ticketUpdateHandler(state, newTicket);
    }
    if (isTicketReOpen(payload) || isTicketClose(payload) || isTicketAdd(payload) || isTicketUpdate(payload)) {
        if (!payload?.message?.payload?.ticket) {
            return state;
        }
        const {...newTicket} = payload.message.payload.ticket;
        return ticketUpdateHandler(state, newTicket, undefined, true);
    }
    if (isChatUpdate(payload) || isChatDelete(payload)) {
        const newChat = payload.message.payload;
        const newTicket = {id: newChat.ticket_id, chats: [newChat]};

        return ticketUpdateHandler(state, newTicket, newChat);
    }

    return state;
};
