import {IUpdateStream} from '../../definitions/updateStream/updateStream.definitions';
import {EUpdateStreamAction} from '../../enums/updateStream/EUpdateStreamAction';
import {EUpdateStreamMethod} from '../../enums/updateStream/EUpdateStreamMethod';
import {isUpdateStream} from '../../utils/redux/redux.utils';

export const isChannelUpdate = (message: IUpdateStream) => isUpdateStream(message, EUpdateStreamAction.Channel);
export const isChannelLabelAndMethodIsPutPost = (message: IUpdateStream) =>
    isUpdateStream(message, EUpdateStreamAction.ChannelLabels, [EUpdateStreamMethod.Put, EUpdateStreamMethod.Post]);
export const isChannelLabelAndMethodIsDelete = (message: IUpdateStream) =>
    isUpdateStream(message, EUpdateStreamAction.ChannelLabels, EUpdateStreamMethod.Delete);
