import moment from 'moment';
import translations from '../../providers/TranslationProvider/translations';
import {IChannel} from '../../definitions/channel/channel.definitions';
import {
    ICustomChart,
    ICustomChartRequest,
    IPeriod,
    IPeriodData,
} from '../../definitions/statistics/statistics.definitions';
import {EChartPeriod, isDynamicChartPeriod} from '../../enums/chart/EChartPeriod';
import {
    EChartTicketKpi,
    shouldShowKpiInHours,
    shouldShowKpiInPercent,
    shouldShowKpiInTimePeriod,
} from '../../enums/chart/EChartTicketKpi';
import {translateSlug} from '../translation/translation.utils';
import {EChartInterval} from '../../enums/chart/EChartInterval';
import {useCallback} from 'react';
import {EChartChatKpi} from '../../enums/chart/EChartChatKpi';
import {GridCellValue} from "@material-ui/data-grid";

const getPeriod = (chart: ICustomChart, invoicePeriod: IPeriod): IPeriod => {
    if (chart.period === EChartPeriod.Custom) {
        if (!chart.from || !chart.to) {
            // use last month as default
            return getChartPeriodRange(EChartPeriod.LastMonth);
        }

        const from = chart.from > moment().unix() ? moment().seconds(0).minutes(0).hours(0).unix() : chart.from;

        return {from, to: chart.to};
    }

    return getChartPeriodRange(chart.period, invoicePeriod);
};

export const getRequestData = (chart?: ICustomChart, invoicePeriod?: IPeriod): ICustomChartRequest | undefined => {

    if (!chart || !invoicePeriod) {
        return;
    }

    const {id, interval, source, kpi, ticket_type} = chart;

    const {from, to} = getPeriod(chart, invoicePeriod);

    return {
        id,
        from,
        to,
        source,
        kpi: kpi ? kpi : ticket_type,
        interval: validateIntervalByFromTo(from, to, interval),
    };
};

export const getChartPeriodWithRange = (chart: IPeriodData, invoicePeriod?: IPeriod, minDate?: number): IPeriodData => {
    if (isDynamicChartPeriod(chart.period)) {
        // update chart period from and to values according to period
        return {
            ...chart,
            ...getChartPeriodRange(chart.period, invoicePeriod, minDate),
        };
    }

    return chart;
};
/**
 * Get chart period (defaults to last 30 days)
 *
 * @param chartPeriod
 * @param invoicePeriod
 * @param minDate
 * @param maxDate
 */
export const getChartPeriodRange = (
    chartPeriod: EChartPeriod,
    invoicePeriod: IPeriod = {from: 0, to: 0},
    minDate = 0,
    maxDate = 0,
): IPeriod => {
    switch (chartPeriod) {
        case EChartPeriod.InvoicingPeriod:
            return {
                from: invoicePeriod.from,
                to: moment().endOf('day').unix(),
            };

        case EChartPeriod.LastInvoicingPeriod:
            return {
                from: Math.max(minDate, moment.unix(invoicePeriod.from).subtract(1, 'months').unix()),
                to: moment.unix(invoicePeriod.to).subtract(1, 'months').unix(),
            };

        case EChartPeriod.LastMonth:
            return {
                from: Math.max(minDate, moment().subtract(1, 'months').startOf('month').unix()),
                to: moment().subtract(1, 'months').endOf('month').unix(),
            };

        case EChartPeriod.Last365Days:
            return {
                from: Math.max(minDate, moment().subtract(365, 'days').startOf('day').unix()),
                to: moment().endOf('day').unix(),
            };
        case EChartPeriod.Custom: {
            if (!minDate || !maxDate) {
                return {
                    from: moment().subtract(30, 'days').startOf('day').unix(),
                    to: moment().endOf('day').unix(),
                };
            }
            return {
                from: minDate,
                to: maxDate,
            };
        }
        case EChartPeriod.Last30Days:
        default:
            return {
                from: Math.max(minDate, moment().subtract(30, 'days').startOf('day').unix()),
                to: moment().endOf('day').unix(),
            };
    }
};

export const formatKpi = (kpi: string, value: string | number | undefined | null, userLanguage: string) => {
    if (
        value === null ||
        value === undefined ||
        value.toString().indexOf('NaN') > -1 ||
        value.toString().indexOf('Invalid') > -1
    ) {
        return '-';
    }

    if (typeof value === 'number') {
        if (shouldShowKpiInHours(kpi)) {
            const float = (value / 3600).toLocaleString(userLanguage, {
                maximumFractionDigits: 1,
                minimumFractionDigits: 0,
            });
            return `${float} ${translations.hours}`;
        }

        if (shouldShowKpiInTimePeriod(kpi)) {
            return moment.duration(Math.round(value), 'seconds').humanize();
        }

        if (shouldShowKpiInPercent(kpi)) {
            const percent = (value * 100).toLocaleString(userLanguage, {
                maximumFractionDigits: 1,
                minimumFractionDigits: 0,
            });
            return `${percent} %`;
        }

        return value.toLocaleString(userLanguage, {
            maximumFractionDigits: 1,
            minimumFractionDigits: 0,
        });
    }

    return value.toString();
};

export const translateKpiDescription = (slug: string, uiSettings: IChannel['ui_settings']) => {
    return translateSlug(`${slug}_description`, uiSettings[slug as keyof IChannel['ui_settings']]);
};

export const getFromToForTheLastYear = () => {
    // get 12 months back to show difference
    const from = moment().subtract(12, 'months').unix();
    const to = moment().unix();

    return {from, to};
};

export const getAverageDuration = (value: number) => {
    if (!value) {
        return '-';
    }
    return moment.duration(Math.round(value), 'seconds').humanize();
};

export const getAbsoluteValue = (value?: GridCellValue) => {
    if (!value && value !== 0) {
        return '-';
    }
    return value;
};

export const getForbiddenIntervalsForPeriod = (from: number, to: number): EChartInterval[] => {

    const allowed = getAllowedIntervalsForPeriod(from, to);

    return [EChartInterval.Hours, EChartInterval.Days, EChartInterval.Weeks, EChartInterval.Months].filter(
        interval => !allowed.includes(interval));
};

export const getAllowedIntervalsForPeriod = (from: number, to: number): EChartInterval[] => {
    const _to = to || moment().unix();
    const days = (_to - from) / 86400;

    const allowed: EChartInterval[] = [];

    if (days <= 7) {
        allowed.push(EChartInterval.Hours);
    }
    if (days >= 2 && days <= 84) {
        allowed.push(EChartInterval.Days);
    }
    if (days >= 7) {
        allowed.push(EChartInterval.Weeks);
    }

    return allowed;
};

export const validateIntervalByFromTo = (from: number, to: number, interval?: EChartInterval) => {
    const allowed = getAllowedIntervalsForPeriod(from, to);

    if (!interval) {
        return EChartInterval.Weeks;
    }

    if (!allowed.includes(interval)) {
        return allowed[0];
    }

    return interval;
};

export const useFormatChartTickY = (kpi?: EChartTicketKpi | EChartChatKpi) => useCallback((value: any) => {
    switch (kpi) {
        case EChartTicketKpi.AverageDurationUntilTicketAnswered:
        case EChartTicketKpi.AverageDurationUntilTicketClosed:
            return `${value} h`;
        case EChartTicketKpi.AverageDurationUntilUserReaction:
        case EChartTicketKpi.AverageDurationUntilInitialAgentReaction:
        case EChartTicketKpi.AverageDurationUntilFirstAssignment:
            return value > 0 ? moment.duration(value, 'seconds').humanize() : 0;
        default:
            return value;
    }
}, [kpi]);

export const useFormatTooltopLabel = (interval?: EChartInterval) => useCallback((value: any) => {
    switch (interval) {
        case EChartInterval.Hours:
            return `${moment.unix(value).format(`${translations.date_picker_format} - HH`)} ${
                translations.o_clock
            }`;
        case EChartInterval.Days:
            return moment.unix(value).format(translations.date_picker_format);
        default:
        case EChartInterval.Weeks:
            return moment.unix(value).format(translations.date_picker_format);
    }
}, [interval]);

export const formatTickX = (value: number) => moment.unix(value).format('DD.MM.');

export const getCancellationRate = (active: number, stopped: number, userLanguage?: string) => {
    if (!active) {
        return 0;
    }
    const cancellationRate = (stopped * 100) / active;

    // only return formatted value if userLanguage is given
    if (userLanguage) {
        return cancellationRate.toLocaleString(userLanguage, {maximumFractionDigits: 1, minimumFractionDigits: 0});
    }

    return cancellationRate;

};
