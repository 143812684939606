export enum EBaseRoutes {
    Login = '/login',
    LoginWithSSO = '/sso',
    Registration = '/registration',
    ForgotPassword = '/forgot',
    ResetPassword = '/password',
    Policies = '/policies',
    ForwardWidget = '/forward',
    ForwardFlyer = '/forward-flyer',
    Profile = '/profile',
    ProfilePanel = '/profile/:activePanel?',
    Dashboard = '/dashboard',
    Assignment = '/assignment',
    Chat = '/chat',
    Conversation = '/chat/:chatId?',
    User = '/user',
    Statistics = '/statistics',
    Settings = '/settings',
    SettingsPanel = '/settings/:activeCategory?/:activePanel?`',
    TicketList = '/ticketList',
    Mia = '/mia',
    ImessageLandingPage = '/imessage-registration'
}

export enum ESettingsRoutes {
    Api = '/settings/api',
    Account = '/settings/account',
    Agents = '/settings/agents',
    Invoices = '/settings/invoices',
    Avv = '/settings/order-processing-contract',
    NotificationTemplates = '/settings/notification-templates',
    Channel = '/settings/settings',
    Autorouting = '/settings/autorouting',
    Messenger = '/settings/messenger',
    MpApiOauthRedirect = '/settings/messenger/mpapi/oauthredirect',
    Targeting = '/settings/targeting',
    WidgetsAndMore = '/settings/widget-editor',
    Tags = '/settings/tags',
    Features = '/settings/features',
    MessengerMenu = '/settings/messenger-menu',
    CustomerInformation = '/settings/customer-information',
    Absence = '/settings/absence',
    TextModules = '/settings/text-modules',
    Skills = '/settings/skills',
    Notes = '/settings/notes',
    Shop = '/settings/shop',
}

export enum EProfileRoutes {
    AccountSettings = '/profile/account-settings',
    SystemSettings = '/profile/system-settings',
    Password = '/profile/pwd-form',
}
