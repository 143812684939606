import moment from 'moment';
import {createSelector} from 'reselect';

import translations from '../../../../../../../providers/TranslationProvider/translations';
import {
    IAccountingState,
    IChannelPricing,
    IPaymentMethod,
    IPaymentSource,
} from '../../../../../../../definitions/accounting/accounting.definitions';
import {
    getPaymentMethods,
    getPaymentSources,
    getPricing,
} from '../../../../../../../redux/accounting/accounting.selectors';
import {getTotalAmountOfAgents} from '../../../../../../../redux/agents/agents.selectors';
import {IState} from '../../../../../../../redux/root.reducer';
import {getTranslatedPaymentMethod} from '../../../../../../../redux/translations/translations.selectors';
import {formatPrice} from "../../../../../../../utils/global/global.utils";

interface IDetailedAccountInformation {
    // BaseInfo
    name: string;
    currency: string;
    payment_method: string;
    base_price: string;
    channel_count: number;
    contract_start: string;
    // AgentInfo
    agents_included: number;
    agent_count: number;
    agent_price: string;
    // UserInfo
    user_count_method?: 'active' | 'mau';
    users_included: number;
    user_price: string;
    users_active_total: number;
    // ChatInfo
    chats_total: number;
    chats_included: number;
    price_for_chats: string;
}

const _getDetailedPricing = (
    accounting: IAccountingState,
    agent_count: number,
    payment_method: string | null,
    pricing?: IChannelPricing,
    // currency,
    // mauPrice,
): IDetailedAccountInformation | null => {
    if (!pricing) {
        return null;
    }

    const currency = pricing.booked_package.currency;
    const userPrice = pricing.userprice?.all?.centpreis_1 || pricing.userprice?.wa?.centpreis_1 || 0;
    const priceAdditionalUser = userPrice / 100;

    const chats_total = accounting?.costs?.usedChats?.total || 0;

    const priceForOneChatInCents = pricing.chatprice?.all?.centpreis_1 || 0;
    const priceForChatPackage = Math.max(0, (priceForOneChatInCents * pricing.chat_package) / 100);
    const price_for_chats = `${formatPrice(priceForChatPackage, currency)} ${translations.per} ${pricing.chat_package} ${translations.per_month_short}`;
    const contractStart = accounting.contract_start ? moment.unix(accounting.contract_start).format('L') : '-';
    const base_price =
        accounting.costs.channel_pricing?.reduce((total, pricing) => total + pricing.base_price, 0) || '';
    const channel_count = accounting.costs.channel_pricing?.length || 0;

    return {
        contract_start: contractStart,
        name: pricing.booked_package.name,
        currency,
        payment_method: payment_method || '-',
        base_price: formatPrice(base_price, currency),
        channel_count,
        agents_included: pricing.agents_included,
        agent_count,
        agent_price: formatPrice(pricing.agent_price, currency),
        user_count_method: accounting.costs.user_count_method,
        users_included: pricing.users_included,
        user_price: formatPrice(priceAdditionalUser, currency),
        users_active_total: accounting.costs.users_active_total || 0,
        chats_total,
        chats_included: pricing.chats_included,
        price_for_chats,
    };
};

export const getDetailedPricing = createSelector(
    [(state: IState) => state.accounting, getTotalAmountOfAgents, getTranslatedPaymentMethod, getPricing],
    _getDetailedPricing,
);

export const getInvoicePeriod = (state: IState) => state.accounting.invoice_period;

export const getDefaultPaymentSource = (state: IState) => {
    return getPaymentSources(state)?.find(ps => ps.is_default);
};

export const getDefaultPaymentMethod = createSelector(
    [
        getDefaultPaymentSource,
        getPaymentMethods,
    ],
    (defaultPaymentSource?: IPaymentSource, paymentMethods?: IPaymentMethod[]) => {
        // get payment method id from default payment source
        return defaultPaymentSource?.payment_method_id ? paymentMethods?.find(pm => pm.id === defaultPaymentSource.payment_method_id) : undefined;
    }
);
