import {createStyles, LinearProgress, Tooltip} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import moment from 'moment/moment';
import {FC, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import CSSTransition from 'react-transition-group/CSSTransition';
import {IAgent} from '../../definitions/agent/agent.definitions';
import {IJobError} from '../../definitions/jobs/jobs.definitions';
import {EBaseRoutes} from '../../enums/routes/ERoutes';
import {getActorId} from '../../redux/actor/actor.selectors';
import {getAgentById} from '../../redux/agents/agents.selectors';
import {getLastJob} from '../../redux/jobs/jobs.actions';
import {getJobErrorsByJobId, selectLastJob} from '../../redux/jobs/jobs.selectors';
import {useParamSelector} from '../../redux/utils';
import {translateSlug} from '../../utils/translation/translation.utils';
import usePrevious from '../../utils/hooks/usePrevious';
import {useTypedSelector} from '../../utils/hooks/useTypedSelector';
import {jobErrorsTableConfig} from '../../utils/jobs/jobs.utils';
import CustomAlert from '../CustomAlert/CustomAlert';
import DialogTemplate from '../DialogTemplate/DialogTemplate';
import LoaderSpinner from '../Loader/LoaderSpinner';
import TableList from '../TableList/TableList';
import translations from '../../providers/TranslationProvider/translations';
import ZebraList, {IZebraListEntry} from '../ZebraList/ZebraList';
import {ucfirst} from "../../utils/global/global.utils";
import {matchPath} from "../../utils/routes/routes.utils";

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            zIndex: theme.zIndex.modal - 20,
            position: 'fixed',
            bottom: theme.spacing(1),
            right: theme.spacing(1),
            transition: 'all 400ms',
            overflow: 'hidden',
            maxHeight: '100vh',
            width: 'auto',
            minWidth: 320,
        },
        root_collapsed: {
            maxHeight: 46,
            width: theme.spacing(38),
        },
        content: {
            opacity: 1,
            transition: 'all 400ms ease-in',
            maxHeight: '85vh',
            overflow: 'auto',
            '-webkit-overflow-scrolling': 'touch',
            '& > div:nth-child(2)': {
                width: 'calc(100% + 32px)',
            },
        },
        content_collapsed: {
            maxHeight: 46,
            overflow: 'hidden',
        },
        smallProgress: {
            opacity: 0,
            transition: 'opacity 400ms ease-out',
        },
        smallProgress_collapsed: {
            opacity: 1,
        },
        hideButtonContainer: {
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            top: 11,
            right: theme.spacing(1),
        },
        hideButtonContainer_collapsed: {
            '& > *:first-child': {
                transform: 'rotate(180deg)',
            },
        },
        infoTable: {
            [theme.breakpoints.down('sm')]: {
                minWidth: 280,
            },
        },
        innerContent: {
            paddingTop: theme.spacing(2),
            marginLeft: theme.spacing(-4),
        },
        transition: {
            '&-enter': {
                opacity: 0,
                transform: 'scale(0.7)',
            },
            '&-enter-active': {
                opacity: 1,
                transition: 'opacity 200ms, transform 200ms',
                transform: 'scale(1)',
            },
            '&-exit': {
                opacity: 1,
                transform: 'scale(0.7)',
            },
            '&-exit-active': {
                opacity: 0,
                transition: 'opacity 200ms, transform 200ms',
                transform: 'scale(1)',
            },
        },
    }),
);

interface IUserImportProgressInfo {
    showActorJobsOnly?: boolean;
}

const UserImportProgressInfo: FC<IUserImportProgressInfo> = ({showActorJobsOnly}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();

    const [uploadedAt, setUploadedAt] = useState<number>();
    const [showInfo, setShowInfo] = useState(false);
    const [hasInfo, setHasInfo] = useState(false);
    const [dismissed, setDismissed] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [hasBeenOpenOnce, setHasBeenOpenOnce] = useState(false);

    const isUploadingCsv = useTypedSelector(state => state.userPage.uploadingCsv);
    const enabledLoader = useTypedSelector(state => state.entities.jobs.enabledLoader);
    const wasUploadingCsv = usePrevious(isUploadingCsv);
    const uploadError = useTypedSelector(state => state.userPage.uploadingCsvError);
    const actorId = useSelector(getActorId);
    const lastJob = useParamSelector(selectLastJob, showActorJobsOnly ? actorId : undefined, uploadedAt);
    const agent: IAgent | undefined = useParamSelector(getAgentById, lastJob?.agent_id);
    const jobId = lastJob?.id;
    const jobErrors: IJobError[] | undefined = useParamSelector(getJobErrorsByJobId, jobId);

    const isJobRunning = lastJob?.state === 'running' || lastJob?.state === 'pending';
    const jobProgress = lastJob?.progress || 0;

    const rootClassName = classNames(classes.root, !showInfo && classes.root_collapsed);
    const contentClassName = classNames(classes.content, !showInfo && classes.content_collapsed);
    const smallProgressClassName = classNames(
        classes.smallProgress,
        hasInfo && !showInfo && classes.smallProgress_collapsed,
    );
    const hideButtonClassName = classNames(
        classes.hideButtonContainer,
        !showInfo && classes.hideButtonContainer_collapsed,
    );

    const hasUserImport = lastJob?.job_type === 'user_import';
    const hasUserImportRunning = hasUserImport && isJobRunning;

    const formattedData: IZebraListEntry[] = useMemo(() => {
        if (!lastJob) {
            return [];
        }

        return Object.keys(lastJob.meta).map(key => {
            const value = lastJob.meta[key];

            return {
                label: translations[`user_csv_import_info_${key}`] || key,
                value: value || 0,
            };
        });
    }, [lastJob]);

    // close this if the upload failed
    useEffect(() => {
        if (uploadError) setDismissed(true);
    }, [uploadError]);

    // show info on uploading csv
    useEffect(() => {
        if (isUploadingCsv) {
            setHasInfo(true);
            setShowInfo(true);
            setDismissed(false);
            setUploadedAt(moment().unix());
        }
    }, [isUploadingCsv]);

    // if a new job is coming in, show it! also show again if it was dismissed.
    useEffect(() => {
        if (!jobId) {
            return;
        }
        setDismissed(false);
        setHasInfo(true);
    }, [jobId]);

    useEffect(() => {
        if (wasUploadingCsv) {
            dispatch(getLastJob({type: 'user_import'}));
        }
    }, [dispatch, wasUploadingCsv]);

    useEffect(() => {
        if (dismissed) {
            setShowInfo(false);
        }
    }, [dismissed]);

    // show only on user page or if the info has been opened once
    const show = !!matchPath(location.pathname, EBaseRoutes.User, true) || hasBeenOpenOnce;

    useEffect(() => {
        if (showInfo) {
            setHasBeenOpenOnce(true);
        }
    }, [showInfo]);

    const progressVariant = showInfo && hasUserImportRunning ? 'indeterminate' : 'determinate';

    return (
        <>
            <CSSTransition
                in={hasInfo && !dismissed && show}
                timeout={500}
                classNames={classes.transition}
                mountOnEnter
                unmountOnExit
            >
                <Paper className={rootClassName} elevation={3}>
                    <CustomAlert
                        variant="info"
                        customTitle={
                            <Box display="flex" alignItems="center" mr={6}>
                                {translations.user_csv_import_info_title}
                                <Box ml={2} className={smallProgressClassName}>
                                    <Typography variant="caption">{`${(jobProgress * 100).toFixed(2)} %`}</Typography>
                                </Box>
                            </Box>
                        }
                        className={contentClassName}
                    >
                        <Box className={classes.innerContent}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                                <Box mr={4}>
                                    <Typography variant="body1" gutterBottom>
                                        {isUploadingCsv
                                            ? translations.user_csv_import_info_uploading
                                            : hasUserImportRunning
                                                ? translations.user_csv_import_info_importing
                                                : translations.user_csv_import_info_import_completed}
                                    </Typography>
                                    {!showActorJobsOnly && (
                                        <Typography variant="caption">
                                            {translateSlug(
                                                'user_csv_import_info_started_by_agent_x',
                                                agent?.name || 'API',
                                            )}
                                        </Typography>
                                    )}
                                </Box>
                                {!!jobErrors?.length && (
                                    <Button onClick={() => setShowDialog(true)} variant="outlined">
                                        {translations.show_details}
                                    </Button>
                                )}
                            </Box>
                            {isUploadingCsv || (wasUploadingCsv && enabledLoader) ? (
                                <LoaderSpinner loading proportions={24}/>
                            ) : (
                                <>
                                    <Box display="flex" alignItems="center">
                                        <Box width="100%" mr={1}>
                                            <LinearProgress variant={progressVariant} value={jobProgress * 100}/>
                                        </Box>
                                        <Box minWidth={40}>
                                            <Typography variant="body2" color="textSecondary">
                                                {`${Math.round(jobProgress * 100)}%`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box mx={-1} className={classes.infoTable}>
                                        <ZebraList data={formattedData}/>
                                    </Box>
                                    <Box className={hideButtonClassName}>
                                        <Tooltip title={showInfo ? translations.minimize : ucfirst(translations.minimize)}>
                                            <IconButton
                                                color="inherit"
                                                size="small"
                                                onClick={() => setShowInfo(!showInfo)}
                                            >
                                                <ArrowDropDownIcon fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={translations.close}>
                                            <IconButton color="inherit" size="small" onClick={() => setDismissed(true)}>
                                                <CloseIcon fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </CustomAlert>
                </Paper>
            </CSSTransition>
            <DialogTemplate
                title={translations.import_errors}
                open={showDialog}
                onClose={() => setShowDialog(false)}
                closable
                maxWidth="md"
                content={
                    <Box mb={2}>
                        {!jobErrors ? (
                            translations.no_errors_logged
                        ) : (
                            <TableList rows={jobErrors} config={jobErrorsTableConfig} autoHeight noPages hideFooter/>
                        )}
                    </Box>
                }
            />
        </>
    );
};

export default UserImportProgressInfo;
