import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';
import {IMAGE_SERVER_URL} from '../../config';
import {EMessenger} from '../../enums/messenger/EMessenger';
import {ITicket} from "../../definitions/tickets/tickets.definitions";


export const toInitials = (name: string) => {
    if (name) {
        const initials = name
            .split(' ')
            .map(part => (part.length ? part[0] : ''))
            .map(part => part.toUpperCase())
            .join('');
        // never use more than 2 letters for initials to avoid problems with double names etc (use only first and last letter)
        if (initials.length > 1) {
            return [initials[0], initials[initials.length - 1]].join('');
        }
        return initials;
    }
    return '';
};

// find a known name or else return a formatted phone number(WA) or just the messenger-number
export const showNameOrNumber = (ticket: ITicket, channelIsAnonymized?: boolean, messenger?: string) => {
    if (!ticket?.name) {
        return idToPhone(ticket.user_id, channelIsAnonymized, messenger);
    }
    return ticket.name;
};

// currently only WhatsApp and WhatsApp business use phone numbers we need to format
const notWhatsApp = (messenger: string) =>
    !(messenger === EMessenger.WhatsApp || messenger === EMessenger.WhatsAppBusiness);

export const idToPhone = (originalId?: string | number, channelIsAnonymized = false, messenger = '') => {
    if (!originalId) {
        return '';
    }
    const id = originalId.toString();

    if (channelIsAnonymized || notWhatsApp(messenger)) {
        return id;
    }

    // refer to https://en.wikipedia.org/wiki/List_of_country_calling_codes
    const beginsWithOneOrSeven = id.startsWith('1') || id.startsWith('7');
    if (beginsWithOneOrSeven) {
        return `${id[0]} ${id.substring(1, 4)} ${id.substring(4, 7)} ${id.substring(7)}`;
    }
    const prefixes = [
        20,
        27,
        28,
        30,
        31,
        32,
        33,
        34,
        36,
        39,
        40,
        41,
        43,
        44,
        45,
        46,
        47,
        48,
        49,
        51,
        52,
        53,
        54,
        55,
        56,
        57,
        58,
        60,
        61,
        62,
        63,
        64,
        65,
        66,
        81,
        82,
        83,
        84,
        86,
        89,
        90,
        91,
        92,
        93,
        94,
        95,
        98,
    ];
    const beginsWithOneOfPrefixes = prefixes.some(number => id.startsWith(number.toString()));

    if (beginsWithOneOfPrefixes) {
        return `${id.substring(0, 2)} ${id.substring(2, 5)} ${id.substring(5)}`;
    }
    return `${id.substring(0, 3)} ${id.substring(3, 6)} ${id.substring(6)}`;
};


export const getCurrencySymbol = (key: string) => {
    switch (key) {
        case 'D':
        case 'USD':
            return '$';
        case 'EUR':
            return '€';
        case 'BRL':
            return 'R$';
        default:
            return key;
    }
};

export const getFileExtensionFromUrl = (url?: string) => {
    if (!url) {
        return '';
    }

    const urlSplit = url.split('.');

    return urlSplit[urlSplit.length - 1].toLowerCase();
};

export const getAttachmentTypeByFileExtensionOrUrl = (fileExtensionOrUrl: string) => {
    const fileExtension =
        fileExtensionOrUrl.indexOf('.') === -1
            ? fileExtensionOrUrl.toLowerCase()
            : getFileExtensionFromUrl(fileExtensionOrUrl);

    if (!fileExtension) {
        console.error('Type other than string passed to getAttachmentTypeByFileExtensionOrUrl.', fileExtension);
        return '';
    }

    switch (fileExtension) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif': {
            return 'image';
        }
        case 'mp4':
        case 'avi':
        case 'm4v':
        case 'mov':
        case 'mpeg':
        case 'mpg':
        case 'quicktime': {
            return 'video';
        }
        case 'mp3': {
            return 'audio';
        }
        case 'xls':
        case 'xlsx':
        case 'doc':
        case 'docx':
        case 'pdf': {
            return 'document';
        }
        default:
            return 'unknown';
    }
};

export const reduceObjectToKeys = (object: any, allowedKeys: string[]) =>
    !isEmpty(object)
        ? Object.keys(object)
            .filter(key => allowedKeys.includes(key))
            .reduce((obj: any, key: string) => {
                obj[key] = object[key];
                return obj;
            }, {})
        : object;

export const ucfirst = (string = '') => string.charAt(0).toUpperCase() + string.slice(1);

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const deepDiffObject = (object: any, base: any) =>
    transform(object, (result: any, value, key) => {
        if (!isEqual(value, base[key])) {
            result[key] = isObject(value) && isObject(base[key]) ? deepDiffObject(value, base[key]) : value;
        }
    });

export const getFirstValueInDeepObject: any = (object: any) => {
    if (isEmpty(object)) {
        return null;
    }
    const value = Object.values(object)[0];
    if (isObject(value)) {
        return getFirstValueInDeepObject(value);
    }
    return value;
};

export const getChangedValueOfDeepObject = (obj: any, objBase: any) =>
    getFirstValueInDeepObject(deepDiffObject(obj, objBase));

export const downloadVcf = (vcfString: string, filename = 'vCard') => {
    const type = 'text/vcard';

    const fileName = `${filename}.vcf`;
    const blob = new Blob([vcfString], {type});

    const a = window.document.createElement('a');
    const windowUrl = encodeURI(window.URL.createObjectURL(blob));

    a.type = type;
    a.href = windowUrl;
    a.download = fileName;

    document.body.appendChild(a);

    a.click();

    window.URL.revokeObjectURL(windowUrl);
    document.body.removeChild(a);
};

// eslint-disable-next-line
export const basename = (path: string) => (!path ? '' : path.replace(/^.*[\\\/]/, ''));

export const getChannelFromDeviceId = (deviceId: string) => deviceId?.split('_')[0] || Date.now().toString();

export const getUrlParameter = (parameter: string) => {
    const url = new URL(window.location.href);
    return url.searchParams.get(parameter);
};

export const getCookie = (key: string) => {
    const b = document.cookie.match(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`);
    return b ? b.pop() : undefined;
};

export const getThumbnailImage = ({url, width, height}: any) =>
    `${IMAGE_SERVER_URL}/thumbnail.php?url=${url}&width=${width}&height=${height}`;

export const formatPrice = (price: string | number, currency: string) => {
    return price ? `${price} ${currency.toUpperCase() === 'EUR' ? '€' : currency}` : '';
};

export const isProductionUrl = () => window.location.hostname.startsWith('app.')
|| window.location.hostname.startsWith('shadowmcp.');
