export const SETTINGS_AVV_GET_DATA = 'SETTINGS_AVV_GET_DATA';
export const SETTINGS_AVV_GET_DATA_FULFILLED = 'SETTINGS_AVV_GET_DATA_FULFILLED';
export const SETTINGS_AVV_GET_DATA_PENDING = 'SETTINGS_AVV_GET_DATA_PENDING';
export const SETTINGS_AVV_GET_DATA_REJECTED = 'SETTINGS_AVV_GET_DATA_REJECTED';

export const SETTINGS_AVV_SAVE = 'SETTINGS_AVV_SAVE';

export const SETTINGS_AVV_SAVE_FULFILLED = 'SETTINGS_AVV_SAVE_FULFILLED';

export const SETTINGS_AVV_SIGN = 'SETTINGS_AVV_SIGN';

export const SETTINGS_AVV_SIGN_FULFILLED = 'SETTINGS_AVV_SIGN_FULFILLED';

export const SETTINGS_AVV_CHANGE_STEP = 'SETTINGS_AVV_CHANGE_STEP';
