import {FC, useEffect, useState} from 'react';
import {Box} from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import image from '../../resources/img/offline.png';
import DialogTemplate from '../DialogTemplate/DialogTemplate';
import translations from '../../providers/TranslationProvider/translations';

const useStyles = makeStyles(() =>
    createStyles({
        image: {
            maxWidth: '100%',
            height: 'auto',
        },
    }),
);

const OfflineDialog: FC = () => {
    const classes = useStyles();
    const [online, setOnline] = useState(global.navigator.onLine);

    useEffect(() => {
        const goOnline = () => setOnline(true);
        const goOffline = () => setOnline(false);
        const check = () => setOnline(global.navigator.onLine);


        global.addEventListener('online', goOnline);
        global.addEventListener('offline', goOffline);

        // check after load, otherwise we would not see the offline message when loading PWA in offline mode
        global.addEventListener('DOMContentLoaded', check);
        global.addEventListener('load', check);

        return () => {
            global.removeEventListener('online', goOnline);
            global.removeEventListener('offline', goOffline);
            global.removeEventListener('DOMContentLoaded', check);
            global.removeEventListener('load', check);
        };
    }, []);

    return (
        <DialogTemplate
            open={!online}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            title={translations.offline}
            content={
                <>
                    <Typography variant="body1">{translations.youre_currently_offline}</Typography>
                    <Box mt={2} style={{textAlign: 'center'}}>
                        <img src={image} alt="⚡" className={classes.image} />
                    </Box>
                </>
            }
        />
    );
};

export default OfflineDialog;
