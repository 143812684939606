import {QUERY_PARAM_TOKEN} from "../../definitions/router/router.definitions";
import {pageLogin} from "../../redux/actor/actor.actions";
import {getTokenFromStorage, updateTokenInStorage} from "../../utils/storage/storage.utils";
import {EBaseRoutes} from "../../enums/routes/ERoutes";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {Location} from 'history';
import {matchPath} from "../../utils/routes/routes.utils";

/**
 * Try to login with token from URL
 *
 * deprecated for SSO
 *
 * @constructor
 */
const AutoLoginHandler = () => {
    const location = useLocation<{ from: Location<any> }>(); // we get the referrer from our AccessControl component
    const dispatch = useDispatch();

    // use actors active channel instead of state.channel.id, because its probably not loaded yet
    const [done, setDone] = useState(false);
    const route = location.pathname;

    const tokenFromUrl = (
        new URLSearchParams(location.pathname).get(QUERY_PARAM_TOKEN)
        || new URLSearchParams(window.location.search).get(QUERY_PARAM_TOKEN)
    );
    const loginToken = tokenFromUrl || getTokenFromStorage();

    // if we have a login token, make a login request (even if we are already logged in)
    useEffect(() => {
        // only login on login route (the user will be redirected to login if authentication is required)
        if (done || !matchPath(route, EBaseRoutes.Login)) {
            return;
        }

        if (loginToken) {
            updateTokenInStorage(loginToken);
            dispatch(pageLogin());
        }

        setDone(true);
    }, [dispatch, loginToken, done, route]);

    return null;
};

export default AutoLoginHandler;
