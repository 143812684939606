import {createSelector} from 'reselect';

import {BOT_BUILDER_URL} from '../../config';
import {
    IActor,
    IActorSettings,
    IDndItem,
    IDragAndDropState,
    ITicketTableColumn,
    IUserTableColumn,
} from '../../definitions/actor/actor.definitions';
import {IPeriodData} from '../../definitions/statistics/statistics.definitions';
import ERights from '../../enums/actor/ERights';
import {EChartPeriod} from '../../enums/chart/EChartPeriod';
import {EMessenger} from '../../enums/messenger/EMessenger';
import {EUserTableColumns} from '../../enums/ui/EUserTableColumns';
import {STATISTICS_PAGE_DND_NAME} from '../../pages/StatisticsPage/config';
import {MessengerFilter} from '../../components/TicketTable/Filter/filter.constants';
import {actorHasRights as _actorHasRights} from '../../utils/actor/actor.utils';
import {getChartPeriodRange} from '../../utils/statistic/statistic.utils';
import {getChannelId, usesMia} from '../channel/channel.selectors';
import {IState} from '../root.reducer';
import {defaultTicketTableConfig, defaultUserTableConfig} from './actor.config';
import {Selector} from "react-redux";

export const getActor = (state: IState): IActor => state.actor;
export const getActorId = (state: IState) => state.actor.id;
export const getActorLanguage = (state: IState) => state.actor.language;
export const getActorRights = (state: IState) => state.actor.rights;

export const getActorSettings = (state: IState) => state.actor.settings;
export const getActiveChannel = (state: IState) => state.actor.activeChannel;
export const isActorAuthenticated = (state: IState) => Boolean(state.actor.success);
export const isActorLoggingOut = (state: IState) => state.actor.loggingOut;

const _isActorAdmin = (actor: IActor) => Boolean(actor?.rights?.includes(ERights.Administrate));

export const isActorAdmin = createSelector<[Selector<IState, IActor>], boolean>([getActor], _isActorAdmin);

export const hasActorTargetingAccess = (state: IState) => !!state.actor.rights?.includes(ERights.UserShowList);

export const actorHasRights = createSelector([
    getActor,
    (state: IState, requiredRights?: ERights | ERights[]) => requiredRights,
    (state: IState, requiredRights?: ERights | ERights[], matchIfAny?: boolean) => matchIfAny,
], _actorHasRights);

// !! NOTE when using this: you have to append the current token to the end of the url (chatBotUrl/token)
const _getChatBotLink = (actor: IActor) => {
    const activeChannel = actor.activeChannel;
    return `${BOT_BUILDER_URL}/cs_botbuilder/${activeChannel}`;
};

export const getChatBotLink = createSelector([getActor], _getChatBotLink);

export const getMessengersByActiveChannel = (state: IState) => {
    const messengers = state.channel.messenger;

    return Object.keys(messengers).reduce(
        (allValues, currentValue) => [
            ...allValues,
            ...(messengers[currentValue as EMessenger] === 1 && MessengerFilter[currentValue]
                ? [
                    {
                        ...MessengerFilter[currentValue],
                    },
                ]
                : []),
        ],
        [{...MessengerFilter.ALL}],
    );
};

const _getTicketTableConfig = (settings: IActorSettings, channelId: number): ITicketTableColumn[] => {
    return settings?.ticketTableConfig?.[channelId] || defaultTicketTableConfig;
};

export const getTicketTableConfig = createSelector([getActorSettings, getChannelId], _getTicketTableConfig);

const _getTicketTableConfigVersion = (settings: IActorSettings, channelId: number): number => {
    return settings?.ticketTableConfigVersion?.[channelId] || 0;
};

export const getTicketTableConfigVersion = createSelector([getActorSettings, getChannelId], _getTicketTableConfigVersion);

const _getUserTableConfig = (settings: IActorSettings, channelId: number, miaIsActive: boolean): IUserTableColumn[] => {
    return (settings?.userTableConfig?.[channelId] || defaultUserTableConfig).filter(
        (columnType: IUserTableColumn) => columnType.type !== EUserTableColumns.STATUS || miaIsActive,
    ).sort((a: IUserTableColumn, b: IUserTableColumn) => a.position - b.position);
};

export const getUserTableConfig = createSelector([getActorSettings, getChannelId, usesMia], _getUserTableConfig);

export const getTextModuleFilter = (state: IState) => {
    if (!state.actor.settings.chatPage) {
        return true;
    }
    return state.actor.settings.chatPage.textmoduleFilter;
};

export const getBotFilter = (state: IState) => {
    if (!state.actor.settings.chatPage) {
        return true;
    }
    return state.actor.settings.chatPage.botFilter;
};

export const getDashboardAgentsStatsPeriodData = (state: IState): IPeriodData => {
    if (state.actor.settings.dashboardAgentStats) {
        return state.actor.settings.dashboardAgentStats;
    }

    return {
        period: EChartPeriod.LastMonth,
        ...getChartPeriodRange(EChartPeriod.LastMonth),
    };
};

const _isClickStatisticMissing = (settings: IActorSettings, isTrackingActive: boolean) => {
    if (!isTrackingActive) {
        return false;
    }
    if (!settings.dragAndDrop?.[STATISTICS_PAGE_DND_NAME]) {
        return false;
    }

    return Object.values(settings.dragAndDrop[STATISTICS_PAGE_DND_NAME].columns).reduce(
        (previousValue, currentValue) => {
            if (currentValue.itemOrder.includes('clicks')) {
                return false;
            }
            return previousValue;
        }, true);
};

export const isClickStatisticMissing = createSelector([
        getActorSettings,
        (state: IState) => Boolean(state.channel.enable_urltracking),
    ],
    _isClickStatisticMissing,
);

const _getAllowedItemIds = (dragAndDropSettings: IDragAndDropState<IDndItem<string>> | undefined, allowAgentStats: boolean, page: string, id: string) =>
    [...new Set(dragAndDropSettings?.[page]?.columns[id]?.itemOrder) || []].filter(id => (id === 'overviewAgents' ? allowAgentStats : true));

export const getAllowedItemIds = createSelector([
        (state: IState) => state.actor.settings.dragAndDrop,
        (state: IState) => Boolean(state.channel.allow_agent_stats),
        (state: IState, props: { page: string, id: string }) => props.page,
        (state: IState, props: { page: string, id: string }) => props.id,
    ],
    _getAllowedItemIds
);

export const selectActorTickets = (state: IState) => state.actor.tickets;
