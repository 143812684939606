export const SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_GET = 'SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_GET';
export const SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_GET_FULFILLED =
    'SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_GET_FULFILLED';

export const SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_OPEN_DIALOG =
    'SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_OPEN_DIALOG';

export const SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_CLOSE_DIALOG =
    'SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_CLOSE_DIALOG';

export const SETTINGS_MESSENGER_GET = 'SETTINGS_MESSENGER_GET';
export const SETTINGS_MESSENGER_GET_FULFILLED = 'SETTINGS_MESSENGER_GET_FULFILLED';
export const SETTINGS_MESSENGER_GET_PENDING = 'SETTINGS_MESSENGER_GET_PENDING';
export const SETTINGS_MESSENGER_GET_REJECTED = 'SETTINGS_MESSENGER_GET_REJECTED';

export const SETTINGS_MESSENGER_WHATSAPP_BUSINESS_GET_TESTPHASE_NUMBER =
    'SETTINGS_MESSENGER_WHATSAPP_BUSINESS_GET_TESTPHASE_NUMBER';
export const SETTINGS_MESSENGER_WHATSAPP_BUSINESS_GET_TESTPHASE_NUMBER_FULFILLED =
    'SETTINGS_MESSENGER_WHATSAPP_BUSINESS_GET_TESTPHASE_NUMBER_FULFILLED';
export const SETTINGS_MESSENGER_WHATSAPP_BUSINESS_GET_TESTPHASE_NUMBER_PENDING =
    'SETTINGS_MESSENGER_WHATSAPP_BUSINESS_GET_TESTPHASE_NUMBER_PENDING';
export const SETTINGS_MESSENGER_WHATSAPP_BUSINESS_GET_TESTPHASE_NUMBER_REJECTED =
    'SETTINGS_MESSENGER_WHATSAPP_BUSINESS_GET_TESTPHASE_NUMBER_REJECTED';

export const SETTINGS_MESSENGER_WHATSAPP_BUSINESS_PUT = 'SETTINGS_MESSENGER_WHATSAPP_BUSINESS_PUT';

export const SETTINGS_MESSENGER_WHATSAPP_BUSINESS_PUT_FULFILLED = 'SETTINGS_MESSENGER_WHATSAPP_BUSINESS_PUT_FULFILLED';

export const SETTINGS_MESSENGER_LC_GET_SETUP = 'SETTINGS_MESSENGER_LC_GET_SETUP';
// export const SETTINGS_MESSENGER_LC_GET_SETUP_PENDING = 'SETTINGS_MESSENGER_LC_GET_SETUP_PENDING';
// export const SETTINGS_MESSENGER_LC_GET_SETUP_REJECTED = 'SETTINGS_MESSENGER_LC_GET_SETUP_REJECTED';
export const SETTINGS_MESSENGER_LC_GET_SETUP_FULFILLED = 'SETTINGS_MESSENGER_LC_GET_SETUP_FULFILLED';

export const WHATSAPP_PHONE_NUMBER_FACEBOOK_SET_ERROR = 'WHATSAPP_PHONE_NUMBER_FACEBOOK_SET_ERROR';
export const WHATSAPP_PHONE_NUMBER_FACEBOOK_RESET_ERROR = 'WHATSAPP_PHONE_NUMBER_FACEBOOK_RESET_ERROR';


export const SETTINGS_MESSENGER_WA_BUSINESS_SETUP_STATUS_POST =
    'SETTINGS_MESSENGER_WA_BUSINESS_SETUP_STATUS_POST';

export const SETTINGS_MESSENGER_WA_BUSINESS_SETUP_STATUS_POST_FULFILLED =
    'SETTINGS_MESSENGER_WA_BUSINESS_SETUP_STATUS_POST_FULFILLED';

export const SETTINGS_MESSENGER_WA_BUSINESS_SETUP_STATUS_POST_PENDING =
    'SETTINGS_MESSENGER_WA_BUSINESS_SETUP_STATUS_POST_PENDING';

export const SETTINGS_MESSENGER_WA_BUSINESS_SETUP_STATUS_POST_REJECTED =
    'SETTINGS_MESSENGER_WA_BUSINESS_SETUP_STATUS_POST_REJECTED';
