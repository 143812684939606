import Keycloak, {KeycloakInitOptions} from "keycloak-js";
import {isProductionUrl} from "../utils/global/global.utils";

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
    url: (isProductionUrl() ? process.env.REACT_APP_SSO_LOGIN_URL : process.env.REACT_APP_SSO_LOGIN_URL_LAB) || '',
    realm: process.env.REACT_APP_SSO_KEYCLOAK_REALM || '',
    clientId: process.env.REACT_APP_SSO_KEYCLOAK_CLIENT_ID || '',
});

/**
 * Update token if its not valid for at least 10 seconds
 */
export const getTokenOrUpdate = async (): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {
        if (!keycloak.authenticated) {
            return resolve(false);
        }
        return keycloak.updateToken(10).then((refreshed) => resolve(refreshed)).catch(reject);
    });
};

export const keycloakInitOptions: KeycloakInitOptions = {
    onLoad: 'login-required',
    // checkLoginIframeInterval: 8,
    enableLogging: true,
};

export default keycloak;
