export const CHAT_PAGE_SEARCH_TERM_SET = 'CHAT_PAGE_SEARCH_TERM_SET';
export const CHAT_PAGE_PUT_USER = 'CHAT_PAGE_PUT_USER';

export const CHAT_PAGE_PUT_USER_REJECTED = 'CHAT_PAGE_PUT_USER_REJECTED';
export const CHAT_PAGE_PUT_USER_FULFILLED = 'CHAT_PAGE_PUT_USER_FULFILLED';

export const CHAT_PAGE_SET_MESSAGE = 'CHAT_PAGE_SET_MESSAGE';
export const CHAT_PAGE_RESET_MESSAGE = 'CHAT_PAGE_RESET_MESSAGE';
export const CHAT_PAGE_RESET_ATTACHMENT_PREVIEW = 'CHAT_PAGE_RESET_ATTACHMENT_PREVIEW';
export const CHAT_PAGE_RESET_UPLOAD = 'CHAT_PAGE_RESET_UPLOAD';
export const CHAT_PAGE_UPLOAD_MEDIA = 'CHAT_PAGE_UPLOAD_MEDIA';
export const CHAT_PAGE_UPLOAD_MEDIA_PENDING = 'CHAT_PAGE_UPLOAD_MEDIA_PENDING';
export const CHAT_PAGE_UPLOAD_MEDIA_FULFILLED = 'CHAT_PAGE_UPLOAD_MEDIA_FULFILLED';
export const CHAT_PAGE_UPLOAD_MEDIA_REJECTED = 'CHAT_PAGE_UPLOAD_MEDIA_REJECTED';
export const CHAT_PAGE_RESET = 'CHAT_PAGE_RESET';
export const CHAT_PAGE_GET_USER = 'CHAT_PAGE_GET_USER';
export const CHAT_PAGE_GET_USER_PENDING = 'CHAT_PAGE_GET_USER_PENDING';
export const CHAT_PAGE_GET_USER_FULFILLED = 'CHAT_PAGE_GET_USER_FULFILLED';
export const CHAT_PAGE_GET_USER_REJECTED = 'CHAT_PAGE_GET_USER_REJECTED';
export const CHAT_PAGE_TOGGLE_IMAGE_LIGHTBOX = 'CHAT_PAGE_TOGGLE_IMAGE_LIGHTBOX';
export const CHAT_PAGE_SET_FOCUS_TEXT_FIELD = 'CHAT_PAGE_SET_FOCUS_TEXT_FIELD';
export const CHAT_PAGE_GET_TICKET_HISTORY = 'CHAT_PAGE_GET_TICKET_HISTORY';
export const CHAT_PAGE_GET_TICKET_HISTORY_PENDING = 'CHAT_PAGE_GET_TICKET_HISTORY_PENDING';
export const CHAT_PAGE_GET_TICKET_HISTORY_FULFILLED = 'CHAT_PAGE_GET_TICKET_HISTORY_FULFILLED';
export const CHAT_PAGE_GET_LIVE_CHAT_CONVERSATION = 'CHAT_PAGE_GET_LIVE_CHAT_CONVERSATION';
// export const CHAT_PAGE_GET_LIVE_CHAT_CONVERSATION_PENDING = 'CHAT_PAGE_GET_LIVE_CHAT_CONVERSATION_PENDING';
export const CHAT_PAGE_GET_LIVE_CHAT_CONVERSATION_REJECTED = 'CHAT_PAGE_GET_LIVE_CHAT_CONVERSATION_REJECTED';
export const CHAT_PAGE_GET_LIVE_CHAT_CONVERSATION_FULFILLED = 'CHAT_PAGE_GET_LIVE_CHAT_CONVERSATION_FULFILLED';
export const CHAT_PAGE_TOGGLE_SIDEBAR = 'CHAT_PAGE_TOGGLE_SIDEBAR';
export const CHAT_PAGE_TOGGLE_ARCHIVE = 'CHAT_PAGE_TOGGLE_ARCHIVE';

export const CHATS_GET_BY_USER = 'CHATS_GET_BY_USER';
export const CHATS_GET_BY_USER_PENDING = 'CHATS_GET_BY_USER_PENDING';
export const CHATS_GET_BY_USER_REJECTED = 'CHATS_GET_BY_USER_REJECTED';
export const CHATS_GET_BY_USER_FULFILLED = 'CHATS_GET_BY_USER_FULFILLED';

export const CHAT_PAGE_ADD_INTERIM_CHAT = 'CHAT_PAGE_ADD_INTERIM_CHAT';
