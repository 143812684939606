import Home from './Icons/HomeDark';
import HomeLight from './Icons/HomeLight';
import BarChartUp from './Icons/BarChartUpDark';
import BarChartUpLight from './Icons/BarChartUpLight';
import Rocket from './Icons/RocketDark';
import RocketLight from './Icons/RocketLight';
import Settings from './Icons/SettingsDark';
import SettingsLight from './Icons/SettingsLight';
import Time from './Icons/TimeDark';
import TimeLight from './Icons/TimeLight';
import User from './Icons/UserDark';
import UserLight from './Icons/UserLight';
import Users from './Icons/UsersDark';
import UsersLight from './Icons/UsersLight';
import Campaigns from './Icons/CampaignsDark';
import CampaignsLight from './Icons/CampaignsLight';
import ChatLayer from './Icons/ChatLayerDark';
import ChatLayerLight from './Icons/ChatLayerLight';
import Contact from './Icons/ContactDark';
import ContactLight from './Icons/ContactLight';
import Support from './Icons/SupportDark';
import SupportLight from './Icons/SupportLight';
import Bell from './Icons/BellNotificationDark';
import BellLight from './Icons/BellNotificationLight';
import Idea from './Icons/IdeaDark';
import IdeaLight from './Icons/IdeaLight';
import Lock from './Icons/LockDark';
import LockLight from './Icons/LockLight';
import MobileIntegration from './Icons/MobileIntegrationDark';
import MobileIntegrationLight from './Icons/MobileIntegrationDark';
import ChatMessage from './Icons/ChatMessageDark';
import ChatMessageLight from './Icons/ChatMessageLight';
import Operators from './Icons/OperatorsDark';
import OperatorsLight from './Icons/OperatorsLight';
import Announcement from './Icons/AnnouncementDark';
import AnnouncementLight from './Icons/AnnouncementLight';
import Path from './Icons/PathDark';
import PathLight from './Icons/PathLight';
import {SvgIcon, useTheme} from '@material-ui/core';
import {SvgIconProps} from '@material-ui/core/SvgIcon/SvgIcon';
import {FC} from 'react';

type TMainMenuIcon =
    'Home'
    | 'BarChartUp'
    | 'Rocket'
    | 'Settings'
    | 'Time'
    | 'User'
    | 'Users'
    | 'Campaigns'
    | 'ChatLayer'
    | 'Support'
    | 'Bell'
    | 'Idea'
    | 'Lock'
    | 'Path'
    | 'ChatMessage'
    | 'MobileIntegration'
    | 'Operators'
    | 'Announcement'
    | 'Contact';

interface IBrandedIcon extends SvgIconProps {
    menuIcon: TMainMenuIcon;
}

const BrandedIconSinch: FC<IBrandedIcon> = (props) => {
    const {menuIcon, ..._props} = props;
    const theme = useTheme();
    const lightMode = theme.palette.type !== 'dark';

    switch (menuIcon) {
        case 'BarChartUp':
            return (
                <SvgIcon {..._props}>
                    {
                        lightMode ? <BarChartUp /> : <BarChartUpLight />
                    }
                </SvgIcon>
            );
        case 'Home':
            return (
                <SvgIcon {..._props}>
                    {lightMode ? <Home /> : <HomeLight />}
                </SvgIcon>
            );
        case 'Rocket':
            return (
                <SvgIcon {..._props}>
                    {lightMode ? <Rocket /> : <RocketLight />}
                </SvgIcon>
            );
        case 'Settings':
            return (
                <SvgIcon {..._props}>
                    {lightMode ? <Settings /> : <SettingsLight />}
                </SvgIcon>
            );
        case 'Time':
            return (
                <SvgIcon {..._props}>
                    {lightMode ? <Time /> : <TimeLight />}
                </SvgIcon>
            );
        case 'User':
            return (
                <SvgIcon {..._props}>
                    {lightMode ? <User /> : <UserLight />}
                </SvgIcon>
            );
        case 'Users':
            return (
                <SvgIcon {..._props}>
                    {lightMode ? <Users /> : <UsersLight />}
                </SvgIcon>
            );
        case 'Campaigns':
            return (
                <SvgIcon {..._props}>
                    {lightMode ? <Campaigns /> : <CampaignsLight />}
                </SvgIcon>
            );
        case 'ChatLayer':
            return (
                <SvgIcon {..._props}>
                    {lightMode ? <ChatLayer /> : <ChatLayerLight />}
                </SvgIcon>
            );
        case 'Contact':
            return (
                <SvgIcon {..._props}>
                    {lightMode ? <Contact /> : <ContactLight />}
                </SvgIcon>
            );
        case 'Support':
            return (
                <SvgIcon {..._props}>
                    {lightMode ? <Support /> : <SupportLight />}
                </SvgIcon>
            );
        case 'Bell':
            return (
                <SvgIcon {..._props}>
                    {lightMode ? <Bell /> : <BellLight />}
                </SvgIcon>
            );
        case 'ChatMessage':
            return (
                <SvgIcon {..._props}>
                    {lightMode ? <ChatMessage /> : <ChatMessageLight />}
                </SvgIcon>
            );
        case 'Idea':
            return (
                <SvgIcon {..._props}>
                    {lightMode ? <Idea /> : <IdeaLight />}
                </SvgIcon>
            );
        case 'Lock':
            return (
                <SvgIcon {..._props}>
                    {lightMode ? <Lock /> : <LockLight />}
                </SvgIcon>
            );
        case 'Operators':
            return (
                <SvgIcon {..._props}>
                    {lightMode ? <Operators /> : <OperatorsLight />}
                </SvgIcon>
            );
        case 'Announcement':
            return (
                <SvgIcon {..._props}>
                    {lightMode ? <Announcement /> : <AnnouncementLight />}
                </SvgIcon>
            );
        case 'MobileIntegration':
            return (
                <SvgIcon {..._props}>
                    {lightMode ? <MobileIntegration /> : <MobileIntegrationLight />}
                </SvgIcon>
            );
        case 'Path':
            return (
                <SvgIcon {..._props}>
                    {lightMode ? <Path /> : <PathLight />}
                </SvgIcon>
            );
        default:
            throw Error(`MenuIcon ${menuIcon} not found`);
    }
};

export default BrandedIconSinch;