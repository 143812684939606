import translations from '../../providers/TranslationProvider/translations';
import {isGranted} from '../../utils/pushNotification/pushNotification.util';
import {
    isChatUpdate,
    isReceiveChat,
    isTicketReOpen,
    isTicketUpdate,
    isUnreadChatUpdate,
} from '../tickets/tickets.reducer.utils';
import {getActiveChannel, getActorId} from '../actor/actor.selectors';
import {sendMessageToServiceWorker} from "../../utils/serviceWorker/serviceWorker.utils";
import {usesTicketSystem} from "../channel/channel.selectors";

const NOTIFICATION_ICON_PATH = 'https://app.messengerpeople.com/img/favicons/android-chrome-256x256.png';

export const pushNotificationMiddleware = (store: any) => (next: any) => (action: any) => {
    next(action);
    const newState = store.getState();

    const actionLogData = action?.payload?.message?.payload;
    const notificationSettings = newState.actor.settings;

    if (!actionLogData || !notificationSettings?.allowNotifications || document.hasFocus() || !isGranted()) {
        return;
    }

    const actorId = getActorId(newState);
    const activeChannel = getActiveChannel(newState);
    const channelName = (newState.channel && newState.channel.name) || '';

    const areTicketsActive = usesTicketSystem(newState);
    const newTicketsById = newState.entities.tickets.byId;

    if (notificationSettings.pushNewTicket && isTicketReOpen(action.payload)) {
        const ticketId = actionLogData.ticket?.id;
        const ticketAgentId = actionLogData.ticket?.agent_id;
        const ticketChannelId = actionLogData.ticket?.channel_id;

        if (ticketChannelId !== activeChannel) {
            return;
        }

        if (ticketAgentId) {
            // if update stream delivers an agent id -> only show notification to target agent
            if (actorId !== ticketAgentId) {
                return;
            }

            if (notificationSettings.pushAssignedTicket) {
                return sendNotification(ticketId, channelName, translations.new_ticket_assigned);
            }
        }

        return sendNotification(ticketId, channelName, translations.new_ticket_opened);
    }

    if (notificationSettings.pushAssignedTicket && isTicketUpdate(action.payload)) {
        const ticketId = actionLogData.ticket?.id;
        const agentId = actionLogData.ticket?.agent_id;
        const oldAgentId = actionLogData.ticket?.old_agent_id;
        const ticketChannelId = actionLogData.ticket?.channel_id;

        if (
            !ticketId ||
            !agentId ||
            !ticketChannelId ||
            actorId !== agentId || // not an actors ticket
            agentId === oldAgentId || // no assignment (because old and new agents are same)
            activeChannel !== ticketChannelId // not current channel
        ) {
            return;
        }

        return sendNotification(ticketId, channelName, translations.new_ticket_assigned);
    }

    if (notificationSettings.pushReceivedChat && isReceiveChat(action.payload)) {
        // if outgoing is true then no notification is needed, because i wrote the message myself...
        if (actionLogData.outgoing) {
            return;
        }

        const ticketId = actionLogData.ticket_id;
        const ticketChannelId = actionLogData?.channelid;
        const currentTicket = newTicketsById[ticketId];

        if (
            !ticketId || // no ticket id
            !currentTicket ||
            currentTicket.agent_id !== actorId || // not an actors ticket
            ticketChannelId !== activeChannel || // not current channel
            Number(actionLogData?.bot) === 1 // bot is going to answer the message, no notification needed
        ) {
            return;
        }

        return sendNotification(ticketId, channelName, translations.new_chat_received);
    }

    if (notificationSettings.pushAllChat && isChatUpdate(action.payload)) {
        if (areTicketsActive) {
            if (!actionLogData?.ticket_id) {
                return;
            }
            return sendNotification(actionLogData.ticket_id, channelName, translations.new_chat_received);
        } else {
            if (!actionLogData?.user_id || !isUnreadChatUpdate(action.payload)) {
                return;
            }
            return sendNotification(actionLogData.user_id, channelName, translations.new_chat_received);
        }

    }
};

const sendNotification = (ticketId: number, title: string, body: string) => {
    // for local testing:
    // console.log('>>>>>>>>>>>>>>>>>>>>>>>send', ticketId, title, body);
    const ticketUrl = `/chat/${ticketId}`;
    const notificationOptions = {
        body: body,
        icon: NOTIFICATION_ICON_PATH,
        data: {
            ticketId,
            url: ticketUrl,
        },
    };
    sendMessageToServiceWorker({title, options: notificationOptions});
};
