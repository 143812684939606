import {IChannel, TChannelMessengerErrors} from '../../definitions/channel/channel.definitions';

export const channelReducerInitialState: IChannel = {
    id: 0,
    name: '',
    channelname: '',
    statusmessage: '',
    welcomemessage: '',
    welcomemessage_insta: '',
    confirmation_facebook: '',
    read_confirmation: 0,
    linkpreview: 0,
    every_word_starts: 0,
    startword: '',
    timezone: '',
    token: '',
    inactive_delete_timeout: 0,
    ticket_overdue_time: 0,
    ticket_critical_time: 0,
    blocked_user: 0,
    inactive_user: 0,
    active_user: 0,
    targeting: {},
    agents: [],
    teams: [],
    messenger: {
        FB: 0,
        TG: 0,
        TC: 0,
        WN: 0,
        IM: 0,
        VI: 0,
        WA: 0,
        WB: 0,
        IN: 0,
        CC: 0,
        LC: 0,
        IG: 0,
        RC: 0,
        LI: 0,
    },
    messenger_errors: {} as TChannelMessengerErrors,
    messengerSettings: {
        FB: {in_use: false},
        TG: {in_use: false},
        TC: {in_use: false},
        WN: {in_use: false},
        IM: {in_use: false},
        VI: {in_use: false},
        WA: {in_use: false},
        WB: {in_use: false},
        IN: {in_use: false},
        CC: {in_use: false},
        LC: {in_use: false},
        IG: {in_use: false},
        RC: {in_use: false},
        LI: {in_use: false},
    },
    type: '',
    widget_hash: '',
    widget_domain: '',
    ticket_reopen_period: 0,
    ticket_open_if_bot: 0,
    ticket_use_autoroutes: 0,
    ticket_assign_by_keywords: 1,
    ticket_auto_assign: 0,
    ticket_assign_last_agent: 0,
    ticket_deactivate_bot: 0,
    ticket_for_bot: 0,
    ui_settings: {},
    use_dynamic_chatblocks: 0,
    modules: [],
    applepay_settings: {},
    ticket_max_per_agent: -1,
    ticket_assign_online_agent: 0,
    language: '',
    facebookPages: [],
    image: '',
    invoices: [],
    labels: {},
    content_pulled_all: '',
    content_pull_num: 1,
    content_pull_time: 86400,
    use_content: 0,
    content_notifications: [],
    enable_urltracking: 0,
    urltracking_parameters: '',
    categories_auto_subscribe: 0,
    categories_info_in_welcome: 0,
    categories_info: '',
    categories_enabled: 0,
    use_tickets: 0,
    use_simple_chat: 0,
    use_shop: 0,
    use_chatbot_mp: 0,
    notify_installed: '',
    notify_chat: 0,
    notify_description: '',
    hidden_phone: 0,
    onboarding_status: 0,
    onboarding_uuid: '',
    onboarding_skipped_until: 0,
    mandatory_note_on_ticket_close: 0,
    mandatory_label_on_ticket_close: 0,
    allow_agent_stats: 0,
    webchat_availability: {},
};
