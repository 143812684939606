import {useUpdateStreamConsumer} from "@sinch-engage/mcp-update-stream";
import {createUpdateActionFromMessageBusMessage} from "../../redux/connection/connection.actions";
import {useDispatch} from "react-redux";

export const useUpdateStreamOverMessageBus = (enablePerformanceCheck?: boolean) => {
    const dispatch = useDispatch();

    useUpdateStreamConsumer(message => {
        dispatch(createUpdateActionFromMessageBusMessage(message, enablePerformanceCheck));
    });
};

export default useUpdateStreamOverMessageBus;
