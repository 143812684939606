import {FC} from 'react';

import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
// @ts-ignore
import VCard from 'vcf';

import {EIcons} from '../../../enums/icons/EIcons';
import {downloadVcf} from '../../../utils/global/global.utils';
import CustomIconButton from '../../CustomIconButton/CustomIconButton';
import HighlightSearchText from '../../HighlightSearchText/HighlightSearchText';
import IconFactory from '../../IconFactory/IconFactory';
import translations from '../../../providers/TranslationProvider/translations';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mediaBox: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: 4,
            padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
            minHeight: 48,
            overflow: 'hidden',

            '& span': {
                marginLeft: theme.spacing(0.5),
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            },
        },
        mediaBoxContainer: {
            display: 'flex',
            alignItems: 'center',

            '& > *:last-child': {
                flexShrink: 0,
                margin: `0 ${theme.spacing(0.5)}px`,
            },
        },
    }),
);

interface IContactContent {
    media: string;
    searchTerm?: string;
}

const ContactContent: FC<IContactContent> = ({media, searchTerm}) => {
    const classes = useStyles();

    const parseVCard: any = () => {
        try {
            const mediaWithFixedLineEndings = media.replace(new RegExp(String.fromCharCode(10), 'g'), '\r\n');
            return new VCard().parse(mediaWithFixedLineEndings);
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    const vCard = parseVCard();
    const content = vCard ? vCard.get('fn') : '';
    const vCardText = content ? content.valueOf().replace(new RegExp(';', 'g'), '') : '';

    return (
        <Typography component='div' variant='caption' className={classes.mediaBoxContainer}>
            <div className={classes.mediaBox}>
                {IconFactory.getIconWithProps(EIcons.CONTACT, {fontSize: 'large'})}
                <HighlightSearchText text={` ${vCardText}`} searchTerm={searchTerm} />
            </div>
            <CustomIconButton
                icon={EIcons.DOWNLOAD}
                tooltip={translations.download_vcard || ''}
                onClick={() => downloadVcf(media)}
            />
        </Typography>
    );
};

ContactContent.displayName = 'ContactContent';

export default ContactContent;
