import {getTokenFromStorage} from "../utils/storage/storage.utils";

/**
 * Helper method for downloading files while providing an Authorization header
 * Filename is taken from content-disposition header, make sure it will be exposed to the client by adding
 * 'Access-Control-Expose-Headers: Content-Disposition' to the response from the server.
 * Filename can be overridden with the second argument
 * @param url
 * @param filenameOverride
 */
export const downloadRequest = (url: string, filenameOverride?: string) => {
    return fetch(url, {
        method: 'GET',
        headers: new Headers({
            "Authorization": `Bearer ${getTokenFromStorage()}`,
        }),
    })
        .then(response => {
            let filename = filenameOverride;
            if (!filename) {
                const disposition = response.headers.get("content-disposition");
                filename = disposition?.split(/;(.+)/)[1].split(/=(.+)/)[1];
                filename = filename?.toLowerCase().startsWith("utf-8''")
                    ? decodeURIComponent(filename.replace("utf-8''", ''))
                    : filename?.replace(/['"]/g, '');
            }
            return {filename, blob: response.blob()};
        })
        .then(({blob, filename}) => {
            blob.then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename || (new Date()).getTime().toString();
                document.body.appendChild(a); // append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove(); // cleanup garbage
            });
        });
};
