export const CHAT_SEND = 'CHAT_SEND';
export const CHAT_SEND_PENDING = 'CHAT_SEND_PENDING';
export const CHAT_SEND_FULFILLED = 'CHAT_SEND_FULFILLED';

export const CHAT_SEND_WB_NOTIFICATION = 'CHAT_SEND_WB_NOTIFICATION';
export const CHAT_SEND_WB_NOTIFICATION_FULFILLED = 'CHAT_SEND_WB_NOTIFICATION_FULFILLED';

export const CHAT_DELETE = 'CHAT_DELETE';
export const CHAT_DELETE_PENDING = 'CHAT_DELETE_PENDING';
export const CHAT_DELETE_REJECTED = 'CHAT_DELETE_REJECTED';
