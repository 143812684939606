import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Popper, {PopperPlacementType} from '@material-ui/core/Popper';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {ReferenceObject} from 'popper.js';
import {FC, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import LoaderSpinner from '../../../../components/Loader/LoaderSpinner';
import {OVERLAY_LOADER_DEFAULT_SIZE} from '../../../../components/OverlayLoader/OverlayLoader';
import {getTicketPreview} from '../../../../redux/tickets/tickets.actions';
import {selectTicketPreviewByTicketOrUserId} from '../../../../redux/tickets/tickets.selectors';
import {useTypedSelector} from '../../../../utils/hooks/useTypedSelector';
import ChatList from "../../../ChatPage/ChatPanel/ChatList/ChatList";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popover: {
            pointerEvents: 'none',
            zIndex: 1400,
        },
        popperDisablePortal: {
            position: 'relative',
        },
        paper: {
            padding: theme.spacing(1),
            marginTop: theme.spacing(0.5),
        },
    }),
);

interface IPopOverProps {
    ticketId?: number;
    anchorEl: null | ReferenceObject;
    throttle?: number;
    maxWidth?: number;
    placement?: PopperPlacementType;
}

const PopOver: FC<IPopOverProps> = (
    {
        ticketId,
        anchorEl,
        throttle = 10,
        placement = 'bottom-start',
        maxWidth = 300,
    }
) => {
    const classes = useStyles();
    const open = !!(ticketId && anchorEl);
    const dispatch = useDispatch();

    // In order to throttle the requests we create a map to store timestamps of last fetch for each ticket
    const [lastUpdatedMap, setLastUpdatedMap] = useState<{ [key: number]: number }>({});

    const ticketPreview = useTypedSelector(state =>
        ticketId ? selectTicketPreviewByTicketOrUserId(state, ticketId) : undefined,
    );
    const isFetching = !!ticketId && !ticketPreview;
    const lastUpdated = ticketId && lastUpdatedMap[ticketId];

    const chats = ticketPreview?.chats;
    const user = useTypedSelector(() => ticketPreview && ({
        id: ticketPreview.user_id,
        name: ticketPreview.name,
        image: ticketPreview.profile_image,
        messenger: ticketPreview.messenger,
    }));

    useEffect(() => {
        // dont fetch if no ticketId defined or if last fetch is not older than some seconds
        if (!ticketId || (lastUpdated && lastUpdated > Date.now() - throttle * 1000)) {
            return;
        }

        dispatch(getTicketPreview(ticketId));

        setLastUpdatedMap({
            ...lastUpdatedMap,
            [ticketId]: Date.now(),
        });
    }, [dispatch, ticketId, lastUpdated, lastUpdatedMap, throttle]);

    return (
        <Popper
          disablePortal={false}
          className={classes.popover}
          anchorEl={anchorEl}
          open={open}
          placement={placement}
        >
            <Paper className={classes.paper}>
                {isFetching ? (
                    <LoaderSpinner loading proportions={OVERLAY_LOADER_DEFAULT_SIZE}/>
                ) : chats?.length && user ? (
                    <Box maxWidth={maxWidth}>
                        <ChatList chats={chats} user={user} autoHeight/>
                    </Box>
                ) : null}
            </Paper>
        </Popper>
    );
};

export default PopOver;
