import {ISetPasswordRequestParams} from '../definitions/auth/auth.definitions';
import {request} from './Api/Api';
import keycloak from "../sso/keycloak";

export const loginRequest = () => request({url: 'login', method: 'POST'});

export const resetPasswordRequest = (parameters: ISetPasswordRequestParams) =>
    request({url: 'login', method: 'POST'}, parameters, true);

export const logoutRequest = () => {
    if (keycloak.authenticated) {
        return keycloak.logout().finally(() => keycloak.clearToken());
    }
    return request({url: 'login', method: 'DELETE'});
};

export const requestPasswordResetRequest = (parameters: any) => request({url: 'login/code', method: 'POST'}, parameters);
