import translations from '../../providers/TranslationProvider/translations';
import {translateSlug} from '../translation/translation.utils';
import {ITableConfig} from '../../definitions/components/tableConfig.definitions';

export const jobErrorsTableConfig: ITableConfig[] = [
    {
        attributeKey: 'identifier',
        translationKey: 'user',
    },
    {
        attributeKey: 'error_code',
        cellRenderer: params => renderError(params.row.error_code),
        translationKey: 'error',
        fullWidth: true,
    },
];

export const renderError = (error_code: string) => {
    if (error_code.startsWith('validation_error_')) {
        return (
            translateSlug('import_error_validation_error_x', error_code.replace('validation_error_', '')) || error_code
        );
    }

    return translations[`import_error_${error_code}`] || error_code;
};
