import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {LOCAL_STORAGE_DEVICE_ID_KEY} from "@sinch-engage/mcp-update-stream";

export const DEVICE_ID_CHANNEL_CHANGED_ACTION = 'DEVICE_ID_CHANNEL_CHANGED';

// handles changes to device id in localStorage
// (for example: login in other tab, login to another channel/customer in same browser)
const useSecurityCheck = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key !== LOCAL_STORAGE_DEVICE_ID_KEY) {
                return;
            }

            const oldDeviceId = event.oldValue;
            const newDeviceId = event.newValue;

            // if the device id changed, dispatch something to react on it!
            if (newDeviceId && oldDeviceId && newDeviceId !== oldDeviceId) {
                dispatch({type: DEVICE_ID_CHANNEL_CHANGED_ACTION});
            }
        };

        global.addEventListener('storage', handleStorageChange);

        return () => global.removeEventListener('storage', handleStorageChange);

    }, [dispatch]);
};

export default useSecurityCheck;

