import {ChangeEvent, FC, ReactNode, useCallback, useEffect, useRef, useState} from 'react';

import AppBar, {AppBarProps} from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBase from '@material-ui/core/InputBase';
import {alpha} from '@material-ui/core/styles/colorManipulator';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';
import {useDispatch} from 'react-redux';

import {ETitleType} from '../../enums/paper/ETitleType';
import {hideContent, showMenu} from '../../redux/ui/ui.actions';
import OverflowEllipsisTooltip from '../OverflowEllipsisTooltip/OverflowEllipsisTooltip';
import translations from '../../providers/TranslationProvider/translations';

export const useStyles = makeStyles<Theme, PaperTitleBarProps>(theme =>
    createStyles({
        backButton: {
            marginRight: theme.spacing(2),
        },
        root: {
            borderRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
        rootCollapsed: {
            borderRadius: theme.shape.borderRadius,
        },
        root_warning: {
            color: theme.palette.warning.contrastText,
            backgroundColor: theme.palette.warning.main,
        },
        root_error: {
            color: theme.palette.error.contrastText,
            backgroundColor: theme.palette.error.main,
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            transitionDuration: '300ms',
            height: 'fit-content',
            maxWidth: 240,

            backgroundColor: alpha(theme.palette.action.hover, 0.05),
            '&:hover': {
                backgroundColor: alpha(theme.palette.action.hover, 0.12),
            },

            marginLeft: theme.spacing(3),
            marginRight: 0,

            [theme.breakpoints.up('lg')]: {
                maxWidth: '300px',
            },
        },
        searchInput: {
            borderRadius: theme.shape.borderRadius,
            transitionDuration: '300ms',
            maxWidth: 300,
            paddingRight: theme.spacing(1.5),
            paddingLeft: theme.spacing(1.5),
            backgroundColor: alpha(theme.palette.action.hover, 0.05),
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(1.5),

            '&:hover': {
                backgroundColor: alpha(theme.palette.action.hover, 0.12),
            },

            [theme.breakpoints.down('xs')]: {
                marginLeft: 0,
                marginRight: theme.spacing(1.5),
            },
        },
        searchInput_responsive: {
            maxWidth: 'none',
            width: '100%',

            [theme.breakpoints.up('sm')]: {
                width: `calc(100% + ${theme.spacing(2)}px)`,
                marginLeft: theme.spacing(-1),
                marginRight: theme.spacing(-1),
            },
        },
        toolbar: {
            display: 'flex',
            minHeight: 52,
            minWidth: 0,

            [theme.breakpoints.down('xs')]: {
                paddingRight: theme.spacing(0.5),
            },
        },
        leadingContent: {
            display: 'flex',
            alignItems: 'center',
            marginRight: theme.spacing(1),
            minWidth: 0,
            width: (props) => props.centerTitle ? '100%' : undefined,
            justifyContent: (props) => props.centerTitle ? 'center' : undefined,
        },
        trailingContent: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            marginLeft: 'auto',
            justifyContent: 'flex-end',
        },
        smallTrailingContent: {
            '& .mcp-MuiIconButton-root': {
                padding: 1,
            },
            '& .mcp-MuiIconButton-root:last-child': {
                paddingRight: 3,
            },
        },
    }),
);

interface PaperTitleBarProps {
    children?: any;
    className?: string;
    title?: any;
    titleType?: ETitleType;
    centerTitle?: boolean;
    onSearchTermChanged?: (value: string) => void;
    navigateBackForSmallDevices?: boolean;
    leadingButton?: ReactNode;
    debounceWait?: number; // ms
    AppBarProps?: AppBarProps;
    searchTerm?: string;
    isCollapsed?: boolean;
}

const PaperTitleBar: FC<PaperTitleBarProps> = props => {
    const {
        title,
        titleType,
        children,
        onSearchTermChanged,
        navigateBackForSmallDevices: backNavigationForSmallDevices,
        leadingButton,
        isCollapsed,
        debounceWait = 150,
        AppBarProps,
        searchTerm = '',
        className,
    } = props;

    const classes = useStyles(props);
    const dispatch = useDispatch();
    const toolbarRef: any = useRef();

    const [inputValue, setInputValue] = useState(searchTerm);
    const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const isVerySmall = useMediaQuery('(max-width: 400px)');
    const isNavigateBackButtonNeeded = backNavigationForSmallDevices ? isSmallDevice : false;
    const [isResponsiveSearchOpen, setIsResponsiveSearchOpen] = useState(false);
    const [isResponsiveSearch, setIsResponsiveSearch] = useState(false);

    const checkEllipsis = useCallback(() => {
        setIsResponsiveSearch(toolbarRef?.current?.clientWidth < 702);
    }, []);

    const handleBackButtonClick = useCallback(() => {
        dispatch(showMenu());
        dispatch(hideContent());
    }, [dispatch]);

    const handleSearchInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    }, []);

    const handleSearchOpenClick = useCallback(() => {
        if (!isResponsiveSearch) {
            return;
        }
        setIsResponsiveSearchOpen(true);
    }, [isResponsiveSearch]);

    const handleSearchCloseClick = useCallback(() => {
        setIsResponsiveSearchOpen(false);
        setInputValue('');
    }, []);

    useEffect(() => {
        setInputValue(searchTerm);
    }, [searchTerm]);

    useEffect(() => {
        window.addEventListener('resize', checkEllipsis);
        checkEllipsis();

        return () => {
            window.removeEventListener('resize', checkEllipsis);
        };
    }, [checkEllipsis]);

    useEffect(() => {
        if (!onSearchTermChanged) {
            return;
        }

        if (!debounceWait) {
            return onSearchTermChanged(inputValue);
        }

        const timeout = setTimeout(() => onSearchTermChanged(inputValue), debounceWait);

        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [inputValue, debounceWait, onSearchTermChanged]);

    useEffect(() => {
        if (isResponsiveSearch) {
            setIsResponsiveSearchOpen(false);
        }
    }, [isResponsiveSearch]);

    const appBarClassName = classNames({
        [classes.root]: !isCollapsed,
        [classes.rootCollapsed]: isCollapsed,
        [classes.root_warning]: titleType === ETitleType.Warning,
        [classes.root_error]: titleType === ETitleType.Error,
        [className || '']: true,
    });
    const searchInputClassNames = classNames(classes.searchInput, isResponsiveSearch && classes.searchInput_responsive);
    const trailingContentClassNames = classNames(classes.trailingContent, isVerySmall && classes.smallTrailingContent);

    return (
        <AppBar className={appBarClassName} position='static' color='inherit' elevation={0} {...AppBarProps}>
            <Toolbar variant='dense' className={classes.toolbar} ref={toolbarRef}>
                {(!isResponsiveSearch || !isResponsiveSearchOpen) && (
                    <div className={classes.leadingContent}>
                        {leadingButton}
                        {isNavigateBackButtonNeeded && (
                            <IconButton
                                className={classes.backButton}
                                edge='start'
                                color='inherit'
                                onClick={handleBackButtonClick}
                            >
                                <ArrowBackIcon fontSize='small'/>
                            </IconButton>
                        )}
                        {!!title && (
                            <Typography variant='h3' noWrap>
                                <OverflowEllipsisTooltip>{title}</OverflowEllipsisTooltip>
                            </Typography>
                        )}
                    </div>
                )}
                <div className={trailingContentClassNames}>
                    {onSearchTermChanged && (
                        <>
                            {(!isResponsiveSearch || isResponsiveSearchOpen) && (
                                <InputBase
                                    placeholder={`${translations['search']}...`}
                                    className={searchInputClassNames}
                                    onChange={handleSearchInputChange}
                                    startAdornment={
                                        <InputAdornment position='start'>
                                            <SearchIcon fontSize='small' color='action'/>
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        isResponsiveSearch &&
                                        isResponsiveSearchOpen && (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    disableTouchRipple={true}
                                                    onClick={handleSearchCloseClick}
                                                    size='small'
                                                >
                                                    <CloseIcon fontSize='small'/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }
                                    value={inputValue}
                                    autoFocus={isResponsiveSearch && isResponsiveSearchOpen}
                                />
                            )}
                            {isResponsiveSearch && !isResponsiveSearchOpen && (
                                <IconButton onClick={handleSearchOpenClick}>
                                    <SearchIcon fontSize='small'/>
                                </IconButton>
                            )}
                        </>
                    )}
                    {(!isResponsiveSearch || !isResponsiveSearchOpen) && children}
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default PaperTitleBar;
