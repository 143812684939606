import {IEntityByKey, IEntityStateForUuids, SubReducer} from '../../definitions/generic/generic.definitions';
import {Product} from '../../definitions/settings/shop/shop.definitions';
import {createEntityReducer} from '../entity/entity.reducer';
import {
    GET_PRODUCT_DETAILS_FULFILLED,
    SCROLL_PRODUCTS_FULFILLED,
    SCROLL_PRODUCTS_PENDING,
    SCROLL_PRODUCTS_REJECTED,
    SEARCH_PRODUCT_FULFILLED,
} from './products.types';

const customProductsEntityReducer: SubReducer<IEntityStateForUuids<Product>> = (state, action) => {
    switch (action.type) {
        case SEARCH_PRODUCT_FULFILLED: {
            const ids: string[] = [];
            const byId = Object.values<Product>(action.payload.products).reduce(
                (result, product) => {
                    ids.push(String(product.id));

                    return {
                        ...result,
                        [String(product.id)]: product,
                    };
                },
                {} as IEntityByKey<Product>,
            );

            return {
                ...state,
                byId,
                ids,
                lastUpdated: action.meta.timestamp,
            };
        }
        case GET_PRODUCT_DETAILS_FULFILLED:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [String(action.payload.product.id)]: {
                        ...action.payload.product,
                    },
                },
                lastUpdated: action.meta.timestamp,
            };
        default:
            return state;
    }
};

export const productsEntityReducer = createEntityReducer<Product>(
    'products',
    [SCROLL_PRODUCTS_PENDING, SCROLL_PRODUCTS_FULFILLED, SCROLL_PRODUCTS_REJECTED],
    customProductsEntityReducer,
);
