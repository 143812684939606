import {ErrorInfo, lazy, PropsWithChildren, PureComponent, ReactNode, Suspense} from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import translations from '../../providers/TranslationProvider/translations';
import {reportErrorRequest} from '../../requests/Api/Api';
import LoadingFallback from '../Loader/LoadingFallback';
import {IState} from '../../redux/root.reducer';
import {connect} from 'react-redux';
import {Paper} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CustomAlert from '../CustomAlert/CustomAlert';
import {Autorenew, Code, Send} from '@material-ui/icons';
import {EMAIL_SUPPORT} from '../../config';
import {getChannelId} from '../../redux/channel/channel.selectors';
import {getActorId} from '../../redux/actor/actor.selectors';
import DialogTemplate from '../DialogTemplate/DialogTemplate';

const ErrorPage = lazy(() => import('../../pages/ErrorPage/ErrorPage'));

const DURATION_UNTIL_RELOAD_AFTER_ERROR = 60000;

interface ErrorBoundaryProps extends PropsWithChildren<{
    fallbackUi?: ReactNode;
    setDidCatch?: (flag: boolean) => void;
}> {
}

interface ErrorBoundaryMappedProps {
    showServiceUnavailable?: boolean;
    actorId?: number;
    channelId?: number;
}

interface ErrorBoundaryState {
    hasError: boolean;
    error?: Error;
    showError: boolean;
}

const initialState = {
    hasError: false,
    error: undefined,
    showError: false,
};

// this is used as declared in the docs https://reactjs.org/docs/error-boundaries.html
// will catch pretty much everything down the tree, except events -> use a try-catch there
class ErrorBoundary extends PureComponent<ErrorBoundaryProps & ErrorBoundaryMappedProps> {
    static displayName = 'ErrorBoundary';

    state: ErrorBoundaryState = {
        ...initialState,
    };

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Log to console
        console.warn('error:', error);
        console.info('error info:', errorInfo.componentStack);

        // noinspection RegExpDuplicateCharacterInClass
        const cleanStackTrace = `${error?.stack}\n\nComponentStack:${errorInfo?.componentStack}`
            .replace(/\?__WB_REVISION__=[[:alnum:]]+n?:[[:alnum:]]+n?:[[:alnum:]]+n?/, '');

        // special error handlings...
        try {
            // immediately reload component on removeChild/insertBefore errors
            // (usually coming from google translate or other browser extensions manipulating the DOM)
            if (
                error.message.indexOf("Failed to execute 'removeChild' on 'Node'") !== -1 ||
                error.message.indexOf("Failed to execute 'insertBefore' on 'Node'") !== -1 ||
                error.message.indexOf("Node.insertBefore") !== -1
            ) {
                this.reload();
                return;
            }

            // don't report chunk load errors, try reloading instead
            if (error.message && /(.+)?Loading (CSS )?chunk \d+ failed(.+)?/gm.test(error.message)) {
                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker.getRegistrations().then(registrations => {
                        registrations.forEach(registration => {
                            if (registration.waiting) {
                                // send skip waiting to service worker to install itself and reload the page
                                registration.waiting.postMessage('skipWaiting');
                                window.location.reload();
                            } else {
                                registration.unregister().finally(() => {
                                    window.location.reload();
                                });
                            }
                        });
                    });
                } else {
                    // This should not happen, but lets report it if it does...
                    console.error("Chunk load error without service worker.");
                }
            }
        } catch(e) {
            console.error(e);
        }

        // Set error info for reporting
        this.setState({
            hasError: true,
            error: {
                ...error,
                stack: cleanStackTrace,
            },
        });

        if (this.props.setDidCatch) {
            this.props.setDidCatch(true);
        }

        reportErrorRequest({
            type: 'Error Boundary',
            uri: window.location.href,
            message: error.message,
            trace: cleanStackTrace,
        });
    }

    reload() {
        this.setState(initialState);
    }

    sendMail() {
        const {error} = this.state;
        const subject = "MCP Error Report";
        const body = `
Agent ID: ${this.props.actorId}
Channel ID: ${this.props.channelId}
URL: ${window.location.href},
Timestamp: ${Date.now()},
Error: ${error?.name}: ${error?.message}
Stack trace: ${error?.stack}
`;
        // mailto is limited to 2000 chars, otherwise it won't open the mail program
        const mailto = `${EMAIL_SUPPORT}?subject=${subject}&body=${encodeURIComponent(body)}`.substring(0, 2000);

        window.open(`mailto:${mailto}`);
    }

    render() {
        const {hasError, error, showError} = this.state;

        if (this.props.showServiceUnavailable) {
            // Automatically reload after some time
            setTimeout(() => window.location.reload(), DURATION_UNTIL_RELOAD_AFTER_ERROR);

            return (
                <Suspense fallback={<LoadingFallback/>}>
                    <ErrorPage/>
                </Suspense>
            );
        }

        if (hasError) {
            // You can render any custom fallback UI
            return this.props.fallbackUi || (
                <div>
                    <Paper>
                        <Box p={2} display={'flex'} flexDirection={'column'} alignItems={'center'}
                             justifyContent={'center'}>
                            <Box fontSize={32}>
                                😕
                            </Box>
                            <Typography variant="subtitle1">
                                {translations.an_error_occured}
                            </Typography>
                            <Box mt={2}>
                                <Box m={1}>
                                    <Button
                                        startIcon={<Autorenew/>}
                                        variant={"outlined"}
                                        fullWidth
                                        onClick={this.reload.bind(this)}
                                    >
                                        {translations.reload_component}
                                    </Button>
                                </Box>
                                <Box m={1}>
                                    <Button
                                        startIcon={<Send/>}
                                        variant={"outlined"}
                                        fullWidth
                                        onClick={this.sendMail.bind(this)}
                                    >
                                        {translations.report_error}
                                    </Button>
                                </Box>
                                <Box m={1}>
                                    <Button
                                        startIcon={<Code/>}
                                        variant={"outlined"}
                                        fullWidth
                                        onClick={() => this.setState({showError: true})}
                                    >
                                        {translations.show_error_details}
                                    </Button>
                                </Box>
                            </Box>
                            <DialogTemplate
                                open={showError}
                                onClose={() => this.setState({showError: false})} maxWidth={'xl'}
                                closable
                            >
                                <CustomAlert variant={"info"} customTitle={'Stack trace:'}>
                                    <pre style={{margin: 0, whiteSpace: 'pre-line'}}>
                                        {error?.stack}
                                    </pre>
                                </CustomAlert>
                            </DialogTemplate>
                        </Box>
                    </Paper>
                </div>
            );
        }

        return this.props.children;
    }
}

const mapStateToProps = (state: IState) => ({
    channelId: getChannelId(state),
    actorId: getActorId(state),
    showServiceUnavailable: state.ui.showServiceUnavailable,
});

export default connect<ErrorBoundaryMappedProps, {}, ErrorBoundaryProps, IState>(mapStateToProps)(ErrorBoundary);
