export const ACTOR_LOGIN_PENDING = 'ACTOR_LOGIN_PENDING';
export const ACTOR_LOGIN_REJECTED = 'ACTOR_LOGIN_REJECTED';

export const ACTOR_RESET = 'ACTOR_RESET';
export const ACTOR_RESET_PENDING = 'ACTOR_RESET_PENDING';
export const ACTOR_RESET_FULFILLED = 'ACTOR_RESET_FULFILLED';
export const ACTOR_RESET_REJECTED = 'ACTOR_RESET_REJECTED';

export const ACTOR_LOGOUT = 'ACTOR_LOGOUT';
export const ACTOR_LOGOUT_PENDING = 'ACTOR_LOGOUT_PENDING';
export const ACTOR_LOGOUT_FULFILLED = 'ACTOR_LOGOUT_FULFILLED';
export const ACTOR_LOGOUT_REJECTED = 'ACTOR_LOGOUT_REJECTED';

export const ACTOR_GET_STATISTICS_FULFILLED = 'ACTOR_GET_STATISTICS_FULFILLED';
export const ACTOR_SET_MIN_PASSWORD_LENGTH = 'ACTOR_SET_MIN_PASSWORD_LENGTH';

export const ACTOR_CHANGE_PASSWORD = 'ACTOR_CHANGE_PASSWORD';
export const ACTOR_CHANGE_PASSWORD_FULFILLED = 'ACTOR_CHANGE_PASSWORD_FULFILLED';

export const ACTOR_SET_STATUS = 'ACTOR_SET_STATUS';
export const ACTOR_SET_STATUS_FULFILLED = 'ACTOR_SET_STATUS_FULFILLED';

export const ACTOR_UPDATE = 'ACTOR_UPDATE';
export const ACTOR_UPDATE_PENDING = 'ACTOR_UPDATE_PENDING';
export const ACTOR_UPDATE_REJECTED = 'ACTOR_UPDATE_REJECTED';
export const ACTOR_UPDATE_FULFILLED = 'ACTOR_UPDATE_FULFILLED';

export const ACTOR_DELETE = 'ACTOR_DELETE';
export const ACTOR_DELETE_FULFILLED = 'ACTOR_DELETE_FULFILLED';

export const ACTOR_UPDATE_SETTINGS = 'ACTOR_UPDATE_SETTINGS';
export const ACTOR_UPDATE_SETTINGS_PENDING = 'ACTOR_UPDATE_SETTINGS_PENDING';
export const ACTOR_UPDATE_SETTINGS_REJECTED = 'ACTOR_UPDATE_SETTINGS_REJECTED';
export const ACTOR_UPDATE_SETTINGS_FULFILLED = 'ACTOR_UPDATE_SETTINGS_FULFILLED';

export const ACTOR_GET_INTERNAL_NEWS = 'ACTOR_GET_INTERNAL_NEWS';
export const ACTOR_GET_INTERNAL_NEWS_FULFILLED = 'ACTOR_GET_INTERNAL_NEWS_FULFILLED';

export const ACTOR_GET_PROFILE = 'ACTOR_GET_PROFILE';
export const ACTOR_GET_PROFILE_REJECTED = 'ACTOR_GET_PROFILE_REJECTED';
export const ACTOR_GET_PROFILE_FULFILLED = 'ACTOR_GET_PROFILE_FULFILLED';

// this is only for legacy partner login
export const PARTNER_LOGIN_FULFILLED = 'PARTNER_LOGIN_FULFILLED';

