import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import {FC, ReactNode} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
            zIndex: theme.zIndex.modal + 1,
        },
    }));

export interface DialogTitleProps {
    id: string;
    children: ReactNode;
    onClose: () => void;
}

export const CustomDialogTitle: FC<DialogTitleProps> = (
    {
        children,
        onClose,
        ...titleProps
    },
) => {
    const classes = useStyles();
    return (
        <>
            {!!children && (
                <DialogTitle disableTypography className={classes.root} {...titleProps}>
                    <Typography variant='h3'>{children}</Typography>
                </DialogTitle>
            )}
            {onClose ? (
                <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </>
    );
};
