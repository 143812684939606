import {IEntityStateForUuids, SubReducer} from '../../definitions/generic/generic.definitions';
import {IUpdateStreamPayload} from '../../definitions/updateStream/updateStream.definitions';
import {IUser} from '../../definitions/user/user.definitions';
import {EUpdateStreamAction} from '../../enums/updateStream/EUpdateStreamAction';
import {EUpdateStreamMethod} from '../../enums/updateStream/EUpdateStreamMethod';
import {isUpdateStream} from '../../utils/redux/redux.utils';
import {UPDATE_STREAM} from '../connection/connection.types';
import {createEntityReducer} from '../entity/entity.reducer';
import {createHashedRequestReducer} from '../request/request.reducer';
import {
    USER_BLOCK_FULFILLED,
    USER_DELETE_FULFILLED,
    USER_SET_UNREAD_FULFILLED,
    USER_START_FULFILLED,
    USER_STOP_FULFILLED,
    USER_UNBLOCK_FULFILLED,
    USERS_GET_FULFILLED,
    USERS_GET_PENDING,
    USERS_GET_REJECTED,
} from './user.types';
import {isChatUpdate} from "../tickets/tickets.reducer.utils";

export interface IUserEntityState extends IEntityStateForUuids<IUser> {
    archivedChats?: number;
}

const customUserEntityReducer: SubReducer<IUserEntityState> = (state, action) => {
    const {type, payload, meta} = action;

    // Handle update stream
    if (type === UPDATE_STREAM) {
        // update the user list should happen only in simple chat - in order to display last_message
        if (meta?.usesSimpleChat && isChatUpdate(payload)) {
            return invalidateUserState(state, meta);
        }
        if (didUsersInvalidate(payload)) {
            return invalidateUserState(state, meta);
        }

        if (isUserToggleBlocked(payload)) {
            const messagePayload = payload.message?.payload;
            if (!messagePayload) {
                return state;
            }

            const {id: userId, blocked} = messagePayload;
            if (!userId) {
                return state;
            }

            const newById = {
                ...state.byId,
                [String(userId)]: {
                    ...state.byId[userId],
                    blocked,
                },
            };

            return {
                ...state,
                byId: newById,
                ids: Object.keys(newById).map(String),
                lastUpdated: meta.timestamp,
            };
        }

        if (isUserStartOrStop(payload)) {
            if (!payload.message?.payload?.id) {
                return state;
            }

            const changedItem = state.byId[payload.message.payload.id];

            if (!changedItem) {
                return state;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [changedItem.id]: {
                        ...changedItem,
                        status: payload.message.payload.status,
                    },
                },
                lastUpdated: meta.timestamp,
            };
        }

        if (isUserDelete(payload)) {
            const messagePayload = payload.message?.payload;
            if (!messagePayload) {
                return state;
            }

            const userId = messagePayload.id;
            if (!userId) {
                return state;
            }

            const {[userId]: deleted, ...newById} = state.byId;

            return {
                ...state,
                byId: newById,
                ids: Object.keys(newById).map(String),
                lastUpdated: meta.timestamp,
            };
        }

        if (isChatUpdate(payload)) {
            const messagePayload = payload.message.payload;
            // if it's an incoming message and the actor is not currently viewing that conversation,
            // invalidate the state, so the user get request will be automatically done (throttled)
            if (!messagePayload.outgoing && !messagePayload.bot && messagePayload.user_id !== meta.activeConversationId) {
                return invalidateUserState(state, meta);
            }
            return state;
        }

        // if (isUserUpdate(payload, 'unread')) {
        //     console.log("UPDATE UNREAD FOR USER");
        //     return updateUnreadForUser(state, payload.message.user_id, payload.message.payload.unread, meta.timestamp);
        // }

        if (isUserUpdateArchive(payload)) {
            return invalidateUserState(state, meta);
        }

        if (isUserUpdate(payload)) {
            const userId = payload.message?.payload?.id;

            if (!userId || !state.byId[userId]) {
                return state;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [userId]: {
                        ...state.byId[userId],
                        ...payload.message?.payload,
                    },
                },
            };
        }

        return state;
    }

    // Handle single actions
    switch (type) {
        case USERS_GET_FULFILLED: {
            return {
                ...state,
                archivedChats: payload.chat_archives !== undefined ? payload.chat_archives : state.archivedChats,
            };
        }
        case USER_DELETE_FULFILLED: {
            const userId = payload.id;
            if (!userId) {
                return state;
            }

            const {[String(userId)]: deleted, ...newById} = state.byId;

            return {
                ...state,
                byId: newById,
                ids: Object.keys(newById).map(String),
                lastUpdated: meta.timestamp,
            };
        }
        case USER_BLOCK_FULFILLED:
        case USER_UNBLOCK_FULFILLED: {
            const {id: userId, blocked} = payload;
            if (!userId) {
                return state;
            }

            const newById = {
                ...state.byId,
                [String(userId)]: {
                    ...state.byId[userId],
                    blocked,
                },
            };

            return {
                ...state,
                byId: newById,
                ids: Object.keys(newById).map(String),
                lastUpdated: meta.timestamp,
            };
        }
        case USER_START_FULFILLED:
        case USER_STOP_FULFILLED:
            return {
                ...state,
                lastUpdated: meta.timestamp,
            };

        case USER_SET_UNREAD_FULFILLED:
            return updateUnreadForUser(state, payload.id, payload.unread, meta.timestamp);
    }

    return state;
};

// Check methods

export const isUserToggleBlocked = (payload: IUpdateStreamPayload) =>
    payload.message.action === EUpdateStreamAction.UserBlock ||
    payload.message.action === EUpdateStreamAction.UserUnblock;

export const didUsersInvalidate = (payload: IUpdateStreamPayload) =>
    payload.message.action === EUpdateStreamAction.InvalidateUsers;

export const isUserDelete = (payload: IUpdateStreamPayload) =>
    payload.message.action === EUpdateStreamAction.User && payload.message.method === EUpdateStreamMethod.Delete;

export const isUserStartOrStop = (payload: IUpdateStreamPayload) =>
    payload.message.action === EUpdateStreamAction.UserStart || payload.message.action === EUpdateStreamAction.UserStop;

export const isUserPropertyUpdate = (payload: IUpdateStreamPayload) =>
    isUpdateStream(payload.message, EUpdateStreamAction.User, EUpdateStreamMethod.Put) &&
    ('customfields' in payload.message.payload || 'name' in payload.message.payload);

export const isUserUpdate = ({message}: any, property?: string) =>
    isUpdateStream(message, EUpdateStreamAction.User, EUpdateStreamMethod.Put) && (!property || property in message.payload);

export const isUserUpdateArchive = (payload: IUpdateStreamPayload) =>
    isUserUpdate(payload) && !!payload.message.payload.archived !== undefined;


// Update methods

const invalidateUserState = (state: IUserEntityState, meta: { timestamp: number }) => ({
    ...state,
    lastUpdated: meta.timestamp,
});

const updateUnreadForUser = (state: IUserEntityState, userId: number, unreadCount?: number, timestamp?: number) => {
    if (!userId || !state.byId[userId]) {
        return state;
    }

    return {
        ...state,
        byId: {
            ...state.byId,
            [userId]: {
                ...state.byId[userId],
                unread: unreadCount,
            },
        },
        lastUpdated: timestamp || Date.now(),
    };
};

// ==================================================

export const userEntityReducer = createEntityReducer<IUser, IUserEntityState>(
    'users',
    [USERS_GET_PENDING, USERS_GET_REJECTED, USERS_GET_FULFILLED],
    customUserEntityReducer,
);

export const userRequestReducer = createHashedRequestReducer('users', [
    USERS_GET_PENDING,
    USERS_GET_REJECTED,
    USERS_GET_FULFILLED,
]);
