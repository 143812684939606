import {ChangeEvent, FC, forwardRef, MutableRefObject, ReactNode, Ref, useEffect, useRef} from 'react';


import TextField from '@material-ui/core/TextField';
import {StandardTextFieldProps} from '@material-ui/core/TextField/TextField';
import {FieldError} from 'react-hook-form';

import {isMutableRef} from '../../../utils/guards/guards.utils';

type TRefOnMount = (inputRef: Ref<HTMLInputElement | HTMLTextAreaElement | null>) => void;
type TMutableRefOnMount = (inputRef: MutableRefObject<HTMLInputElement | HTMLTextAreaElement | null>) => void;

export interface ICustomTextFieldProps extends StandardTextFieldProps {
    className?: string;
    error?: boolean;
    identifier?: string;
    fullWidth?: boolean;
    setFocusTextField?: (value: boolean) => void;
    validateUntouched?: boolean;
    startAdornment?: ReactNode;
    focus?: boolean;
    InputProps?: any;
    disabled?: boolean;
    emoji?: true;
    onMount?: TRefOnMount | TMutableRefOnMount;
    fieldError?: FieldError;
}

const CustomTextField: FC<ICustomTextFieldProps> = forwardRef(({
    id,
    setFocusTextField,
    validateUntouched,
    focus,
    fullWidth = true,
    onMount,
    inputRef,
    helperText,
    error,
    fieldError,
    ...props
}, forwardedRef) => {
    const ref = useRef<any>(forwardedRef);

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (props.onChange) {
            props.onChange(event);
        }
    };

    const handleBlur = (event: any) => {
        if (props.onBlur) {
            props.onBlur(event);
        }
    };

    const handleFocus = (event: any) => {
        if (props.onFocus) {
            props.onFocus(event);
        }
    };

    useEffect(() => {
        if (onMount && isMutableRef(ref)) {
            onMount(ref);
        }
    });

    useEffect(() => {
        if (setFocusTextField) {
            if (isMutableRef(ref)) {
                ref.current.focus();
            }
            setFocusTextField(false);
        }
    }, [setFocusTextField, ref]);

    const description = fieldError ? fieldError.message : helperText;
    const invalid = !!fieldError || error;

    return (
        <TextField
            {...props}
            inputRef={ref}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            fullWidth={fullWidth}
            helperText={description}
            error={invalid}
        />
    );
});

export default CustomTextField;
