import {FC, useCallback, useEffect, useState} from 'react';

import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useSelector} from 'react-redux';

import Avatar from '../../../../../../components/Avatar/Avatar';
import Twemoji from '../../../../../../components/Twemoji/Twemoji';
import {getActor} from '../../../../../../redux/actor/actor.selectors';
import {DrawerChildProps} from '../../../Drawer';
import {useSharedStyles} from '../../DrawerMenu.styles';
import {UserMenuLogoutButton} from './UserMenuLogoutButton';
import {UserMenuProfileButton} from './UserMenuProfileButton';
import {Typography} from '@material-ui/core';

export const useStyles = makeStyles(() =>
    createStyles({
        toolTipHelper: {
            ...TooltipHelper,
        },
        avatar: {
            // adding 'div' to fix import order on staging
            'div &': {
                width: 27,
                height: 27,
                fontSize: 14,
                margin: '0 10px',
            },
        },
        actorName: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingLeft: 7,
            '& span': {
                display: 'block',
            },
        },
        list: {
            paddingLeft: 20,
        },
        agentName: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        expandIcon: {
            // use absolute position to prevent the icon jumping on scrollbar toggle
            position: 'absolute',
            left: 204,
        },
    }),
);

export const TooltipHelper = {
    position: 'relative' as any, // it's a ts compiler thing
    display: 'flex',
    alignItems: 'center',
    width: '100%',
};

export const UserMenu: FC<DrawerChildProps> = ({isDrawerOpen}) => {
    const sharedClasses = useSharedStyles();
    const classes = useStyles();

    const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);

    const actor = useSelector(getActor);
    const actorName = `${actor.firstname} ${actor.lastname}`;

    // collapse menu when drawer gets closed
    useEffect(() => {
        if (!isDrawerOpen) {
            setUserMenuIsOpen(false);
        }
    }, [isDrawerOpen]);

    const handleClick = useCallback(() => {
        setUserMenuIsOpen(!userMenuIsOpen);
    }, [userMenuIsOpen]);

    return (
        <List disablePadding>
            <ListItem button onClick={handleClick} classes={{button: sharedClasses.button}}>
                <Avatar className={classes.avatar} src={actor.profile_image} name={actorName} width={22} height={22} />
                <ListItemText className={sharedClasses.name}>
                    <Typography variant='h4' component='div'
                                className={classes.actorName}><Twemoji>{actorName}</Twemoji></Typography>
                </ListItemText>
                {userMenuIsOpen && <ExpandLessIcon className={classes.expandIcon} />}
                {!userMenuIsOpen && <ExpandMoreIcon className={classes.expandIcon} />}
            </ListItem>
            <Collapse in={userMenuIsOpen} timeout='auto' addEndListener={() => {
            }}>
                <List component='div' disablePadding className={classes.list}>
                    <UserMenuProfileButton />
                    <UserMenuLogoutButton />
                </List>
            </Collapse>
        </List>
    );
};
