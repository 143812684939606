import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import {ITicketPutBatchRequest} from '../../definitions/tickets/ticketRequestState.definitions';
import {chatRequest} from '../../requests/chatRequest';
import {
    deleteLabelFromTicketRequest,
    putLabelsToTicketsRequest,
    putLabelToTicketRequest,
    ticketCloseBatchRequest,
    ticketDeleteNoteRequest,
    ticketOpenRequest,
    ticketPostNoteRequest,
    ticketPutBatchRequest,
    ticketPutNoteRequest,
    ticketPutRequest,
    ticketRequest,
} from '../../requests/ticketRequest';
import {getTicketRoute} from '../../utils/routes/routes.utils';
import {createHashedRequestAction} from '../request/request.actions';
import {IState} from '../root.reducer';
import {ITicket} from "../../definitions/tickets/tickets.definitions";
import {usesMia, usesTicketSystem} from "../channel/channel.selectors";
import {
    TICKET_ASSIGN,
    TICKET_CLOSE,
    TICKET_CREATE_NOTE,
    TICKET_DELETE_LABEL_FROM_TICKET,
    TICKET_DELETE_NOTE,
    TICKET_GET_BY_ID,
    TICKET_OPEN,
    TICKET_POSTPONE,
    TICKET_PREVIEW_GET,
    TICKET_PUT_LABEL_TO_TICKET,
    TICKET_PUT_LABELS_TO_TICKETS,
    TICKET_REVOKE,
    TICKET_UPDATE,
    TICKET_UPDATE_NOTE,
    TICKETS_GET,
} from "./tickets.types";
import {CHATS_GET_BY_USER} from "../chatPage/chatPage.types";


// Get a ticket or user conversation either by ticket or chat endpoint
export const getConversation = (id: number, numChats = 100000) =>
    (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
        const state = getState();
        const isTicketSystemActive = usesTicketSystem(state);

        if (isTicketSystemActive) {
            return dispatch({
                type: TICKET_GET_BY_ID,
                payload: ticketRequest({id: id, newsletter: 1, bot: 2, num_chats: numChats}),
                meta: {
                    id: id,
                    // we don't want to show errors when getting redirected to a ticket from another channel
                    silent: true,
                },
            });
        }

        const isMiaActive = usesMia(state);

        return dispatch({
            type: CHATS_GET_BY_USER,
            payload: chatRequest({id: id, limit: numChats, showstartstop: isMiaActive ? 1 : 0}),
            meta: {
                id: id,
                // we don't want to show errors when getting redirected to a conversation from another channel
                silent: true,
            },
        });
    };

export const getActorTickets = (actorId: number) =>
    getTickets({num_chats: 0, status: 1, notes: 0, newsletter: 0, agent_id: actorId}, undefined, {isOwnTickets: true});

export const getTickets = createHashedRequestAction('tickets', TICKETS_GET, ticketRequest);

export const getTicketPreview = (id: number, numChats = 3) => ({
    type: TICKET_PREVIEW_GET,
    payload: ticketRequest({id: id, bot: 2, notes: 0, num_chats: numChats, last_chat: 1, newsletter: 0}),
    meta: {
        id: id,
    },
});

export const assignTicket = (ticketId: number, agentId: number) => ({
    type: TICKET_ASSIGN,
    payload: ticketPutRequest({id: ticketId, agent_id: agentId}),
});

export const batchAssignTicket = (params: ITicketPutBatchRequest) => ({
    type: TICKET_ASSIGN,
    payload: ticketPutBatchRequest(params),
});

export const openTicket = (userId: number) => ({
    type: TICKET_OPEN,
    payload: ticketOpenRequest(userId),
});

export const displayLastTicket = (history: any) => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => IState) => {
        const userId = getState().selected.userPage.selectedUserId;

        if (!userId) {
            throw Error('no selected userId');
        }

        history.push(getTicketRoute(userId));
    };
};

export const closeTickets = (ids: number[], note: string, labelIds: number[]) => ({
    type: TICKET_CLOSE,
    payload: ticketCloseBatchRequest(ids, note, labelIds),
});



export const revokeTicket = (ticketId: number) => ({
    type: TICKET_REVOKE,
    payload: ticketPutRequest({id: ticketId, agent_id: 0}),
});

export const postponeTicket = (ticketIds: number[], postponed: number) => {

    if (ticketIds.length === 1) {
        return {
            type: TICKET_POSTPONE,
            payload: ticketPutRequest({id: ticketIds[0], postponed}),
        };
    }

    return {
        type: TICKET_POSTPONE,
        payload: ticketPutBatchRequest({batch_ids: ticketIds, postponed}),
    };
};

export const batchUnAssignTicket = (ids: number[]) => ({
    type: TICKET_REVOKE,
    payload: ticketPutBatchRequest({batch_ids: ids, agent_id: 0}),
});

export const updateTicket = (ticketId: number, data: Partial<ITicket>) => ({
    type: TICKET_UPDATE,
    payload: ticketPutRequest({id: ticketId, ...data}),
});

// NOTES
export const createTicketNote = (ticketId: number, note: string) => ({
    type: TICKET_CREATE_NOTE,
    payload: ticketPostNoteRequest(ticketId, note),
});

export const updateTicketNote = (ticketId: number, noteId: number, note: string) => ({
    type: TICKET_UPDATE_NOTE,
    payload: ticketPutNoteRequest(ticketId, noteId, note),
});

export const deleteTicketNote = (ticketId: number, noteId: number) => ({
    type: TICKET_DELETE_NOTE,
    payload: ticketDeleteNoteRequest(ticketId, noteId),
});

// LABELS
export const putLabelToTicket = (ticketId: number, labelId: number) => ({
    type: TICKET_PUT_LABEL_TO_TICKET,
    payload: putLabelToTicketRequest(ticketId, labelId),
});

export const putLabelsToTickets = (ticketIds: number[], labelIds: number[]) => ({
    type: TICKET_PUT_LABELS_TO_TICKETS,
    payload: putLabelsToTicketsRequest(ticketIds, labelIds),
    meta: {ticketIds, labelIds},
});

export const deleteLabelFromTicket = (ticketId: number, labelId: number) => ({
    type: TICKET_DELETE_LABEL_FROM_TICKET,
    payload: deleteLabelFromTicketRequest(ticketId, labelId),
});
