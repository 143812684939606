import {useEffect, useRef} from 'react';

function usePrevious<T = any>(value: T) {
    const ref = useRef<typeof value>();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}

export default usePrevious;
