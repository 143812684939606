import {ChangeEvent, FC, useEffect} from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import {useDispatch, useSelector} from 'react-redux';

import {getTargeting, getTargetingCount} from '../../redux/mia/targeting/targeting.actions';
import {getTargetingsSortedByName} from '../../redux/mia/targeting/targeting.selectors';
import CustomSelect, {ICustomSelect} from '../CustomInputs/CustomSelect/CustomSelect';
import translations from '../../providers/TranslationProvider/translations';

export interface TargetingSelectorProps extends ICustomSelect {
    onChangeValue?: (value: number) => any;
}

const TargetingSelector: FC<TargetingSelectorProps> = (
    {
        value,
        onChange,
        onChangeValue,
        name = 'category',
        label = translations.targeting,
        fullWidth,
    },
) => {
    const dispatch = useDispatch();
    const targeting = useSelector(getTargetingsSortedByName);

    useEffect(() => {
        dispatch(getTargeting({content: 1}));
    }, [dispatch]);

    const handleTargetingSelect = (event: ChangeEvent<any>) => {
        if (onChange) {
            onChange(event);
        }

        if (onChangeValue && typeof event.target.value === 'number') {
            onChangeValue(event.target.value);
        }
    };

    useEffect(() => {
        if (typeof value === 'number') {
            dispatch(getTargetingCount(value));
        }
    }, [dispatch, value]);

    return (
        <CustomSelect name={name} value={value} onChange={handleTargetingSelect} label={label} fullWidth={fullWidth}>
            {targeting.map(value => {
                const label =
                    value.count !== undefined ? `${value.name} (${value.count} ${translations.users})` : value.name;
                return (
                    <MenuItem key={value.id} value={value.id}>
                        {label}
                    </MenuItem>
                );
            })}
        </CustomSelect>
    );
};

export default TargetingSelector;
