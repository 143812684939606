import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import {FC, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Avatar from '../../../../../../components/Avatar/Avatar';
import Twemoji from '../../../../../../components/Twemoji/Twemoji';
import {IChannel} from '../../../../../../definitions/channel/channel.definitions';
import {changeChannel} from '../../../../../../redux/actor/actor.actions';
import {getChannels} from '../../../../../../redux/channel/channels.selectors';
import {IState} from '../../../../../../redux/root.reducer';
import {DrawerChildProps} from '../../../Drawer';
import {useSharedStyles} from '../../DrawerMenu.styles';
import {TooltipHelper} from '../UserMenu/UserMenu';
import {useTypedSelector} from '../../../../../../utils/hooks/useTypedSelector';
import {getBadgeSmall, getBadgeStyles} from '../MainMenu/MainMenuItem';
import clsx from 'clsx';
import {CircularProgress, Typography} from '@material-ui/core';

export const animationDuration = 250;

const transition = {transition: `margin ${animationDuration}ms ease-in-out`};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            overflowX: 'hidden',
            '& span': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },

            '& svg': {
                fontSize: 16,
            },
        },
        tooltipHelper: {
            ...TooltipHelper,
        },
        avatar: {
            width: 28,
            height: 28,
            fontSize: 14,
            marginRight: 16,
            margin: '0 10px',
            ...transition,
        },
        avatarSmall: {
            width: 22,
            height: 22,
            fontSize: 12,
            marginLeft: 20,
            marginRight: 16,
            ...transition,
        },
        listItemText: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        iconContainer: {
            position: 'relative',
        },
        activeChannelName: {
            maxWidth: '100%',
        },
        badge: {
            ...getBadgeStyles(theme),
            right: 0,
        },
        badgeSmall: {
            ...getBadgeSmall(theme),
            top: -4,
        },
        badgeChannels: {
            top: 4,
            right: -10,
        },
    }),
);

export const ChannelMenu: FC<DrawerChildProps> = ({isDrawerOpen}) => {
    const sharedClasses = useSharedStyles();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [channelMenuIsOpen, setChannelMenuIsOpen] = useState(false);

    const channels = useSelector(getChannels);
    // multiChannel can be activated for channels via a setting in the crm (backend)
    const multiChannelUnreadTickets = useTypedSelector(state => state.multiChannel);
    const activeChannel = useSelector((state: IState) => state.channel);

    const totalNumberOfNewUnreadTickets = multiChannelUnreadTickets && Object.values(multiChannelUnreadTickets)
        .reduce((totalCount, channelTickets) => totalCount + (channelTickets.length || 0), 0);

    // collapse menu when drawer gets closed
    useEffect(() => {
        if (!isDrawerOpen) {
            setChannelMenuIsOpen(false);
        }
    }, [isDrawerOpen]);

    const handleClick = useCallback(() => {
        setChannelMenuIsOpen(!channelMenuIsOpen);
    }, [channelMenuIsOpen]);

    const channelList: IChannel[] = Object.values(channels)
        .filter((channel: IChannel) => channel.id !== activeChannel.id)
        .sort((a: IChannel, b: IChannel) => (a.uiorder || 0) - (b.uiorder || 0));

    const [switchingChannelId, setSwitchingChannelId] = useState(0);

    const handleChangeChannel = (channelId: number) => {
        setSwitchingChannelId(channelId);
        Promise.resolve(dispatch(changeChannel(channelId))).finally(() => {
            setSwitchingChannelId(0);
        });
    };

    return (
        <List disablePadding>
            <ListItem button onClick={handleClick} classes={{button: sharedClasses.button}}>
                <div className={classes.iconContainer}>
                    <Avatar src={activeChannel.image} name={activeChannel.name} className={classes.avatar} />
                    {Boolean(totalNumberOfNewUnreadTickets && !channelMenuIsOpen) && (
                        <span className={clsx(classes.badge, classes.badgeSmall)}>x</span>
                    )}
                </div>
                <Tooltip title={activeChannel.name} className={classes.activeChannelName}>
                    <ListItemText className={classNames(sharedClasses.name, classes.listItemText)}>
                        <Box overflow='hidden' textOverflow='ellipsis'>
                            <Typography variant='h4'><Twemoji>{activeChannel.name}</Twemoji></Typography>
                        </Box>
                    </ListItemText>
                </Tooltip>
                {Boolean(channelList.length) && (
                    <>
                        {channelMenuIsOpen && <ExpandLessIcon className={sharedClasses.expandIcon} />}
                        {!channelMenuIsOpen && <ExpandMoreIcon className={sharedClasses.expandIcon} />}
                    </>
                )}
            </ListItem>
            {Boolean(channelList.length) && (
                <Collapse in={channelMenuIsOpen} addEndListener={() => {
                }}>
                    <List>
                        {channelList.map((channel) => {
                            // multiChannel ticket alerts
                            let numberOfNewUnreadTickets;
                            if (multiChannelUnreadTickets && multiChannelUnreadTickets[channel.id]) {
                                numberOfNewUnreadTickets = multiChannelUnreadTickets[channel.id].length;
                            }

                            return (
                                <ListItem
                                    key={channel.id}
                                    button
                                    className={classes.listItem}
                                    onClick={() => handleChangeChannel(channel.id)}
                                >
                                    <Tooltip title={channel.name} placement='right' enterDelay={650} leaveDelay={150}>
                                        <div className={classes.tooltipHelper}>
                                            {channel.id === switchingChannelId ? (
                                                <CircularProgress className={classes.avatarSmall} size={22} />
                                            ) : (
                                                <Avatar
                                                    className={classes.avatarSmall}
                                                    src={channel.image}
                                                    name={channel.name}
                                                />
                                            )}
                                            <ListItemText
                                                primaryTypographyProps={{variant: 'body2'}}
                                                classes={{root: classes.listItemText}}
                                            >
                                                <Box
                                                    overflow='hidden'
                                                    textOverflow='ellipsis'
                                                    component='span'
                                                >
                                                    <Twemoji>{channel.name}</Twemoji>
                                                </Box>
                                                {numberOfNewUnreadTickets && (
                                                    <span
                                                        className={clsx(classes.badge, classes.badgeChannels)}>{numberOfNewUnreadTickets}</span>
                                                )}
                                            </ListItemText>
                                        </div>
                                    </Tooltip>
                                </ListItem>
                            );
                        })}
                    </List>
                </Collapse>
            )}
        </List>
    );
};
