import copyToClipboard from 'copy-to-clipboard';
import {SnackbarProvider} from 'notistack';
import {FC, lazy, Suspense, useCallback, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {CrossTabInterface, useIdleTimer} from 'react-idle-timer';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect} from 'react-router';
import {Route, Switch} from 'react-router-dom';

import AskForPushNotification from './components/AskForPushNotification/AskForPushNotification';
import BlockDialog from './components/BlockDialog/BlockDialog';
import ContentEmptyMessage from './components/ContentEmptyMessage/ContentEmptyMessage';
import {CustomRoute} from './components/CustomRoute/CustomRoute';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import NotificationSnackbar from './components/NotificationSnackbar/NotificationSnackbar';
import OfflineDialog from './components/OfflineDialog/OfflineDialog';
import PageNotFound from './components/PageNotFound/PageNotFound';
import TestPeriodExpiredDialog from './components/TestPeriodExpiredDialog/TestPeriodExpiredDialog';
import translations from './providers/TranslationProvider/translations';
import UpdateAppButton from './components/UpdateAppButton/UpdateAppButton';
import UserImportProgressInfo from './components/UserImportProgressInfo/UserImportProgressInfo';
import {ELogoutReasons} from './definitions/security/security.definitions';
import {ERights} from './enums/actor/ERights';
import {EBaseRoutes} from './enums/routes/ERoutes';
import './index.css';
import AuthenticationPage from './pages/AuthenticationPage/AuthenticationPage';
import BasePage from './pages/BasePage/BasePage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import ForgotPasswordLandingPage from './pages/ForgotPasswordLandingPage/ForgotPasswordLandingPage';
import {logout} from './redux/actor/actor.actions';
import {getActorLanguage, isActorAdmin, isActorAuthenticated} from './redux/actor/actor.selectors';
import {getIdleTimeout} from './redux/channel/channel.selectors';
import {addSuccessNotification, addWarningNotification} from './redux/notifications/notifications.actions';
import {IState} from './redux/root.reducer';
import {getApiKeyRequest} from './requests/channelRequest';
import {
    getTimeOfLastAction,
    getTokenFromStorage,
    resetTimeOfLastAction,
    updateTimeOfLastAction,
} from './utils/storage/storage.utils';
import {createStyles, makeStyles} from '@material-ui/core/styles';
import LoadingPage from "./pages/LoadingPage/LoadingPage";
import AutoLogoutHandler from "./components/AutoLogoutHandler/AutoLogoutHandler";
import AppleMessageHandler from "./components/AppleMessageHandler/AppleMessageHandler";
import UrlSearchParamsHandler from "./components/UrlSearchParamsHandler/UrlSearchParamsHandler";
import usePrevious from "./utils/hooks/usePrevious";

const PasswordLandingPage = lazy(() => import('./pages/PasswordLandingPage/PasswordLandingPage'));
const UserPage = lazy(() => import('./pages/UserPage/UserPage'));
const MiaPage = lazy(() => import('./pages/MiaPage/MiaPage'));
const SettingsPage = lazy(() => import('./pages/SettingsPage/SettingsPage'));
const StatisticsPage = lazy(() => import('./pages/StatisticsPage/StatisticsPage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage/ProfilePage'));
const WidgetForwardingPage = lazy(() => import('./pages/WidgetForwardingPage/WidgetForwardingPage'));
const FlyerForwardingPage = lazy(() => import('./pages/FlyerForwardingPage/FlyerForwardingPage'));
const TicketPage = lazy(() => import('./pages/TicketPage/TicketPage'));
const AssignmentPage = lazy(() => import('./pages/AssignmentPage/AssignmentPage'));
const ChatPage = lazy(() => import('./pages/ChatPage/ChatPage'));
const ImessageLandingPage = lazy(() => import('./pages/ImessageLandingPage/ImessageLandingPage'));
const LoginPage = lazy(() => import('./pages/AuthenticationPage/LoginPage'));

const useStyles = makeStyles(theme => createStyles({
    snackbarProvider: {
        margin: theme.spacing(.75, 0),
        pointerEvents: 'all',
    },
}));

const CONVERSATION_RIGHTS = [ERights.TicketRead, ERights.ChatRead];

const idleTimerCrossTabSettings: CrossTabInterface = {
    type: 'localStorage',
    emitOnAllTabs: true,
};

interface AppProps {
    globalDataLoaded: boolean;
}

const App: FC<AppProps> = ({globalDataLoaded}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const idleTimeout = useSelector(getIdleTimeout);
    const isAdmin = useSelector(isActorAdmin);
    const isAuthenticated = useSelector(isActorAuthenticated);
    const channelId = useSelector((state: IState) => state.channel.id);
    const actorLanguage = useSelector(getActorLanguage);
    const currentActionTimestamp = useSelector((state: IState) => state.connection.lastActionTimestamp);
    const lastActionTimestamp = usePrevious(currentActionTimestamp);

    const handleIdle = useCallback(() => {
        if (isAuthenticated) {
            dispatch(addWarningNotification(translations[ELogoutReasons.Idle], true));
            dispatch(logout());
            alert(translations[ELogoutReasons.Idle]);
        }
    }, [dispatch, isAuthenticated]);

    const handleOnAction = useCallback((e: Event) => {

        // only save "lastAction" when user is logged in
        if (isAuthenticated) {
            const currentTime = Date.now();
            const previousTime = getTimeOfLastAction();

            // check if lastAction was already set - and if timestamp is too old
            if (previousTime && currentTime - previousTime > idleTimeout) {
                console.info('Idle Timeout debug info:');
                console.info('idleTimeout:', idleTimeout);
                console.info('currentTime:', currentTime);
                console.info('previousTime:', previousTime);

                handleIdle();
                return;
            }

            // eslint-disable-next-line no-console
            console.debug("[IdleTimer] reset timeout because of event of type:", e.type);
            updateTimeOfLastAction();
        }

        return true;
    }, [handleIdle, isAuthenticated, idleTimeout]);

    // shortcut to copy api key from everywhere [Ctrl + Shift + Alt + A]
    const copyApiKey = useCallback(
        async (e: KeyboardEvent) => {
            if (e.ctrlKey && e.altKey && e.shiftKey && e.key.toUpperCase() === 'A') {
                const response = await getApiKeyRequest();
                if (response) {
                    if (!response.apikey) {
                        return;
                    }

                    copyToClipboard(response.apikey);
                    dispatch(addSuccessNotification(translations.copy_to_clipboard_success));
                }
            }
        },
        [dispatch],
    );

    // shortcut to copy channel ID from everywhere [Ctrl + Shift + Alt + C]
    const copyChannelId = useCallback(
        (e: KeyboardEvent) => {
            if (e.ctrlKey && e.altKey && e.shiftKey && e.key.toUpperCase() === 'C') {
                if (!channelId) {
                    return;
                }

                copyToClipboard(String(channelId));
                dispatch(addSuccessNotification(translations.copy_to_clipboard_success));
            }
        },
        [channelId, dispatch],
    );

    // shortcut to copy user token from everywhere [Ctrl + Shift + Alt + T]
    const copyUserToken = useCallback(
        (e: KeyboardEvent) => {
            if (e.ctrlKey && e.altKey && e.shiftKey && e.key.toUpperCase() === 'T') {
                const token = getTokenFromStorage();
                if (!isAuthenticated || !token) {
                    return;
                }

                copyToClipboard(token);
                dispatch(addSuccessNotification(translations.copy_to_clipboard_success));
            }
        },
        [dispatch, isAuthenticated],
    );

    const createShortcutHandler = useCallback(() => {
        if (isAdmin) {
            document.addEventListener('keyup', copyApiKey);
        }
        document.addEventListener('keyup', copyChannelId);
        document.addEventListener('keyup', copyUserToken);
    }, [copyApiKey, copyChannelId, copyUserToken, isAdmin]);

    const removeShortcutHandler = useCallback(() => {
        if (isAdmin) {
            document.removeEventListener('keyup', copyApiKey);
        }
        document.removeEventListener('keyup', copyChannelId);
        document.removeEventListener('keyup', copyUserToken);
    }, [copyApiKey, copyChannelId, copyUserToken, isAdmin]);

    useEffect(() => {
        createShortcutHandler();
        return () => removeShortcutHandler();
    }, [createShortcutHandler, removeShortcutHandler]);

    const {reset: resetIdleTimer} = useIdleTimer({
        onIdle: handleIdle,
        timeout: idleTimeout,
        debounce: 500,
        onAction: handleOnAction,
        crossTab: idleTimerCrossTabSettings,
    });

    useEffect(() => {
        resetTimeOfLastAction();
    }, []);

    useEffect(() => {
        // because resetIdleTimer changes on every render cycle, we have to compare action timestamp with previous value
        if (lastActionTimestamp !== currentActionTimestamp) {
            resetIdleTimer();
            resetTimeOfLastAction();
        }
    }, [currentActionTimestamp, lastActionTimestamp, resetIdleTimer]);

    const initialRedirect = {
        pathname: EBaseRoutes.Dashboard,
        search: window.location.search,
    };

    return (
        <>
            <UrlSearchParamsHandler/>
            <AutoLogoutHandler/>
            <AppleMessageHandler/>
            <ErrorBoundary fallbackUi={(
                <ContentEmptyMessage message={translations.an_error_occured || 'Something went wrong.'}/>
            )}>
                <Helmet
                    htmlAttributes={{
                        lang: actorLanguage,
                        'xml:lang': actorLanguage,
                    }}
                >
                    <meta httpEquiv='Content-Language' content={actorLanguage}/>
                </Helmet>
                {/* Note: <Redirect exact from="/" to={EBaseRoutes.Login} /> does not work, it will strip URL params */}
                <Route exact path='/' render={() => <Redirect to={initialRedirect}/>}/>
                {/*<Route path={EBaseRoutes.Login} render={() => <Redirect to={initialRedirect}/>}/>*/}
                <Route path={EBaseRoutes.LoginWithSSO} render={() => <Redirect to={initialRedirect}/>}/>
                <SnackbarProvider maxSnack={3} preventDuplicate className={classes.snackbarProvider}>
                    <BasePage globalDataLoaded={globalDataLoaded}>
                        {/* We need the suspense to lazy load the route components without errors */}
                        <Suspense
                            fallback={
                                <LoadingPage text={""} show/>
                            }
                        >
                            <Switch>
                                <Route path={EBaseRoutes.Login} component={LoginPage}/>
                                <Route path={EBaseRoutes.Registration} component={AuthenticationPage}/>
                                <Route
                                    path={EBaseRoutes.ForgotPassword}
                                    component={ForgotPasswordLandingPage}
                                />
                                <Route path={EBaseRoutes.ForwardWidget} component={WidgetForwardingPage}/>
                                <Route path={EBaseRoutes.ForwardFlyer} component={FlyerForwardingPage}/>
                                <Route path={EBaseRoutes.ResetPassword} component={PasswordLandingPage}/>
                                <CustomRoute path={EBaseRoutes.ProfilePanel} component={ProfilePage}/>
                                <CustomRoute path={EBaseRoutes.Dashboard} component={DashboardPage}/>
                                <CustomRoute
                                    path={EBaseRoutes.Conversation} component={ChatPage}
                                    requiredRights={CONVERSATION_RIGHTS}
                                    matchIfAny/>
                                <CustomRoute
                                    path={EBaseRoutes.ImessageLandingPage}
                                    component={ImessageLandingPage}/>
                                <CustomRoute
                                    path={EBaseRoutes.Statistics}
                                    component={StatisticsPage}
                                    requiredRights={ERights.StatisticsChats}
                                />
                                <CustomRoute
                                    path={EBaseRoutes.TicketList}
                                    component={TicketPage}
                                    requiredRights={ERights.TicketRead}
                                />
                                <CustomRoute
                                    path={EBaseRoutes.Mia}
                                    component={MiaPage}
                                    requiredRights={ERights.ContentEdit}
                                />
                                <CustomRoute
                                    path={EBaseRoutes.Assignment}
                                    component={AssignmentPage}
                                    requiredRights={ERights.TicketShowAssignment}
                                />
                                <CustomRoute
                                    path={EBaseRoutes.User}
                                    component={UserPage}
                                    requiredRights={ERights.UserShowList}
                                />
                                <CustomRoute
                                    path={EBaseRoutes.Settings}
                                    component={SettingsPage}
                                    requiredRights={ERights.SettingsChannel}
                                />
                                <Route component={PageNotFound}/>
                            </Switch>
                        </Suspense>
                    </BasePage>
                    {isAuthenticated && (
                        <>
                            <TestPeriodExpiredDialog/>
                            <BlockDialog/>
                            <AskForPushNotification/>
                            <UserImportProgressInfo showActorJobsOnly/>
                        </>
                    )}
                    <OfflineDialog/>
                    <NotificationSnackbar/>
                    <UpdateAppButton/>
                </SnackbarProvider>
            </ErrorBoundary>
        </>
    );
};

export default App;
