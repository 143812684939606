import {AnyAction, Reducer} from 'redux';
import {
    ESetupFBMVerification,
    ESetupWbStatus,
    EVNameStatus,
    ISettingsMessenger,
} from '../../../definitions/settings/settingsState.definitions';
import {GetWhatsAppBusinessParameters} from '../../../requests/messengerRequests';
import {
    SETTINGS_MESSENGER_GET_FULFILLED, SETTINGS_MESSENGER_GET_PENDING, SETTINGS_MESSENGER_GET_REJECTED,
    SETTINGS_MESSENGER_LC_GET_SETUP_FULFILLED,
    SETTINGS_MESSENGER_WA_BUSINESS_SETUP_STATUS_POST_FULFILLED,
    SETTINGS_MESSENGER_WA_BUSINESS_SETUP_STATUS_POST_PENDING,
    SETTINGS_MESSENGER_WA_BUSINESS_SETUP_STATUS_POST_REJECTED,
    SETTINGS_MESSENGER_WHATSAPP_BUSINESS_GET_TESTPHASE_NUMBER_FULFILLED,
    SETTINGS_MESSENGER_WHATSAPP_BUSINESS_PUT_FULFILLED,
    SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_CLOSE_DIALOG,
    SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_GET_FULFILLED,
    SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_OPEN_DIALOG,
    WHATSAPP_PHONE_NUMBER_FACEBOOK_RESET_ERROR,
    WHATSAPP_PHONE_NUMBER_FACEBOOK_SET_ERROR,
} from './messenger.types';
import {
    FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER,
    FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER_SAVED_FULFILLED,
    FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER_SAVED_PENDING,
    FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER_SAVED_REJECTED,
} from "../facebook/facebook.types";

const settingsMessengerInitialState: ISettingsMessenger = {
    whatsAppBusinessTestphaseNumbers: [],
    whatsAppBusinessAccountInformation: {},
    whatsAppBusinessStatus: {
        isLoading: false,
    },
    setupStatus: {},
    openDialog: false,
    setup_data: {
        cc: undefined,
        phone_number: undefined,
        vname: undefined,
        business_manager_id: undefined,
        setup_request_confirmed: false,
        setup_fbm_verification: ESetupFBMVerification.unknown,
        setup_wb_status: ESetupWbStatus.none,
        setup_vname_status: EVNameStatus.none,
        setup_contact_type: 'whatsapp',
        setup_contact: '',
        numbers: [],
        selectedNumber: undefined,
        connectedPage: null,
        loading: true,
        error: null,
        setup_loading: false,
    },
};

export const messengerReducer: Reducer<ISettingsMessenger> = (state = settingsMessengerInitialState, action) => {
    switch (action.type) {
        case SETTINGS_MESSENGER_WHATSAPP_BUSINESS_GET_TESTPHASE_NUMBER_FULFILLED:
        case SETTINGS_MESSENGER_WHATSAPP_BUSINESS_PUT_FULFILLED: {
            return splitWhatsAppBusinessPayload(state, action);
        }
        case SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_OPEN_DIALOG:
            return {
                ...state,
                openDialog: true,
            };
        case SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_CLOSE_DIALOG:
            return {
                ...state,
                openDialog: false,
            };
        case SETTINGS_MESSENGER_GET_PENDING:
            return {
                ...state,
                setup_data: {
                    ...state.setup_data,
                    loading: true,
                },
            };
        case SETTINGS_MESSENGER_GET_REJECTED:
            return {
                ...state,
                setup_data: {
                    ...state.setup_data,
                    loading: false,
                },
            };
        case SETTINGS_MESSENGER_GET_FULFILLED:
            return {
                ...state,
                whatsAppBusinessStatus: action.payload.WB,
                setup_data: {
                    ...state.setup_data,
                    loading: false,
                },
            };
        case SETTINGS_MESSENGER_WHATSAPP_BUSINESS_SETUP_STATUS_GET_FULFILLED: {
            return {
                ...state,
                ...action.payload,
                setup_data: {
                    ...state.setup_data,
                    ...action.payload.setup_data,
                },
            };
        }

        case SETTINGS_MESSENGER_LC_GET_SETUP_FULFILLED: {
            return {
                ...state,
                setup_data: {
                    ...state.setup_data,
                    livechat: action.payload.webchat,
                },
            };
        }

        case SETTINGS_MESSENGER_WA_BUSINESS_SETUP_STATUS_POST_FULFILLED: {
            const optionalPhoneNumbers = action.payload.numbers ? {numbers: action.payload.numbers} : {};

            return {
                ...state,
                setup_data: {
                    ...state.setup_data,
                    ...action.payload.setup_data,
                    ...optionalPhoneNumbers,
                    setup_loading: false,
                },
            };
        }
        case SETTINGS_MESSENGER_WA_BUSINESS_SETUP_STATUS_POST_PENDING: {

            return {
                ...state,
                setup_data: {
                    ...state.setup_data,
                    setup_loading: true,
                },
            };
        }

        case SETTINGS_MESSENGER_WA_BUSINESS_SETUP_STATUS_POST_REJECTED: {

            return {
                ...state,
                setup_data: {
                    ...state.setup_data,
                    setup_loading: false,
                },
            };
        }


        case FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER: {
            return {
                ...state,
                setup_data: {
                    ...state.setup_data,
                    selectedNumber: action.payload,
                },
            };
        }

        case FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER_SAVED_PENDING: {
            return {
                ...state,
                whatsAppBusinessStatus: {
                    ...state.whatsAppBusinessStatus,
                    isLoading: true,
                },
            };
        }
        case FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER_SAVED_REJECTED: {
            return {
                ...state,
                whatsAppBusinessStatus: {
                    ...state.whatsAppBusinessStatus,
                    isLoading: false,
                },
            };
        }
        case FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER_SAVED_FULFILLED: {
            return {
                ...state,
                whatsAppBusinessStatus: {
                    ...state.whatsAppBusinessStatus,
                    status: "active",
                    isLoading: false,
                },
            };
        }
        case WHATSAPP_PHONE_NUMBER_FACEBOOK_SET_ERROR: {
            return {
                ...state,
                setup_data: {
                    ...state.setup_data,
                    error: action.payload,
                },
            };
        }

        case WHATSAPP_PHONE_NUMBER_FACEBOOK_RESET_ERROR: {
            return {
                ...state,
                setup_data: {
                    ...state.setup_data,
                    error: null,
                },
            };
        }
        default:
            break;
    }

    return state;
};

const splitWhatsAppBusinessPayload = (state: ISettingsMessenger, action: AnyAction): ISettingsMessenger => {
    const testNumbers = action.payload[GetWhatsAppBusinessParameters.TEST_NUMBERS];
    const payloadWithoutNumbers = {...action.payload};
    delete payloadWithoutNumbers[GetWhatsAppBusinessParameters.TEST_NUMBERS];

    const accountInformation =
        payloadWithoutNumbers[GetWhatsAppBusinessParameters.PROFILE] ||
        payloadWithoutNumbers[GetWhatsAppBusinessParameters.FIELDS];

    return {
        ...state,
        whatsAppBusinessTestphaseNumbers: testNumbers,
        whatsAppBusinessAccountInformation: accountInformation,
    };
};
