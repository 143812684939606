import {IAgent} from '../../definitions/agent/agent.definitions';
import {addAgentRequest, getAgentsRequest, updateAgentRequest} from '../../requests/agentRequests';
import {AGENT_ADD, AGENT_UPDATE, AGENTS_GET} from "./agents.types";


export const getAgents = (openTickets?: boolean) => ({
    type: AGENTS_GET,
    payload: getAgentsRequest(openTickets),
});

export const updateAgent = (payload: IAgent) => ({
    type: AGENT_UPDATE,
    payload: updateAgentRequest(payload),
});

export const addAgent = (agent: IAgent) => ({
    type: AGENT_ADD,
    payload: addAgentRequest(agent),
});
