export const FACEBOOK_GET_PAGES = 'FACEBOOK_GET_PAGES';
export const FACEBOOK_GET_PAGES_PENDING = 'FACEBOOK_GET_PAGES_PENDING';
export const FACEBOOK_GET_PAGES_REJECTED = 'FACEBOOK_GET_PAGES_REJECTED';
export const FACEBOOK_GET_PAGES_FULFILLED = 'FACEBOOK_GET_PAGES_FULFILLED';

export const FACEBOOK_GET_CONNECTED_PAGE = 'FACEBOOK_GET_CONNECTED_PAGE';
export const FACEBOOK_GET_CONNECTED_PAGE_PENDING = 'FACEBOOK_GET_CONNECTED_PAGE_PENDING';
export const FACEBOOK_GET_CONNECTED_PAGE_REJECTED = 'FACEBOOK_GET_CONNECTED_PAGE_REJECTED';
export const FACEBOOK_GET_CONNECTED_PAGE_FULFILLED = 'FACEBOOK_GET_CONNECTED_PAGE_FULFILLED';

export const FACEBOOK_GET_ACTIVE_PAGE_DATA_PENDING = 'FACEBOOK_GET_ACTIVE_PAGE_DATA_PENDING';
export const FACEBOOK_GET_ACTIVE_PAGE_DATA_REJECTED = 'FACEBOOK_GET_ACTIVE_PAGE_DATA_REJECTED';
export const FACEBOOK_GET_ACTIVE_PAGE_DATA_FULFILLED = 'FACEBOOK_GET_ACTIVE_PAGE_DATA_FULFILLED';

export const FACEBOOK_PAGE_SET = 'FACEBOOK_PAGE_SET';
export const FACEBOOK_PAGE_SET_PENDING = 'FACEBOOK_PAGE_SET_PENDING';
export const FACEBOOK_PAGE_SET_REJECTED = 'FACEBOOK_PAGE_SET_REJECTED';
export const FACEBOOK_PAGE_SET_FULFILLED = 'FACEBOOK_PAGE_SET_FULFILLED';

export const FACEBOOK_SET_SELECTED_PAGE = 'FACEBOOK_SET_SELECTED_PAGE';
export const FACEBOOK_SET_IS_PAGE_SELECTED = 'FACEBOOK_SET_IS_PAGE_SELECTED';
export const FACEBOOK_RESET = 'FACEBOOK_RESET';

export const FACEBOOK_SET_ERROR = 'FACEBOOK_SET_ERROR';
export const FACEBOOK_RESET_ERROR = 'FACEBOOK_RESET_ERROR';

export const FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER = 'FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER';
export const FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER_SAVED = 'FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER_SAVED';
export const FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER_SAVED_FULFILLED = 'FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER_SAVED_FULFILLED';
export const FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER_SAVED_PENDING = 'FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER_SAVED_PENDING';
export const FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER_SAVED_REJECTED = 'FACEBOOK_SET_WHATSAPP_SELECTED_PHONE_NUMBER_SAVED_REJECTED';

