import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import {IState} from '../root.reducer';
import {
    TOGGLE_SSO,
    UI_CHANGE_DRAWER_STATE,
    UI_CHANGE_LOCKER,
    UI_CHANGE_SERVICE_UNAVAILABLE,
    UI_CHAT_PAGE_SET_CHAT_TO_DELETE,
    UI_HIDE_CONTENT,
    UI_HIDE_MENU,
    UI_SET_CONFIRM_DIALOG,
    UI_SHOW_CONTENT,
    UI_SHOW_MENU,
    UI_SHOW_MIA_PANEL,
    UI_UPDATE_AVAILABLE,
    UI_UPDATE_URL_SEARCH_PARAMS,
} from "./ui.types";


export const hideMenu = () => ({
    type: UI_HIDE_MENU,
});

export const showMenu = () => ({
    type: UI_SHOW_MENU,
});

export const hideContent = () => ({
    type: UI_HIDE_CONTENT,
});

export const showContent = () => ({
    type: UI_SHOW_CONTENT,
});

export const showMiaPanelSystem = () => ({
    type: UI_SHOW_MIA_PANEL,
});

export const showUpdateAvailable = () => ({
    type: UI_UPDATE_AVAILABLE,
});



export const changeLocker = (lockStatus: boolean) => ({
    type: UI_CHANGE_LOCKER,
    payload: lockStatus,
});

export const updateUrlSearchParams = (params: URLSearchParams | string | Record<string, string>) => ({
    type: UI_UPDATE_URL_SEARCH_PARAMS,
    payload: new URLSearchParams(params),
});

export const changeDrawerState = (drawerOpen: boolean) => (
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => IState,
) => {
    if (drawerOpen || getState().ui.drawerOpen) {
        dispatch({
            type: UI_CHANGE_DRAWER_STATE,
            payload: drawerOpen,
        });
    }
};

export const setConfirmDialog = (
    open: boolean,
    title?: string,
    label?: string,
    actionCreator?: (...args: any) => any,
) => ({
    type: UI_SET_CONFIRM_DIALOG,
    payload: {
        open,
        title,
        label,
        actionCreator,
    },
});

export const changeServiceUnavailable = (unavailable = false) => ({
    type: UI_CHANGE_SERVICE_UNAVAILABLE,
    payload: unavailable,
});

export const setChatToDelete = (chatId: number | null) => ({
    type: UI_CHAT_PAGE_SET_CHAT_TO_DELETE,
    payload: chatId,
});

export const toggleSSO = () => ({
    type: TOGGLE_SSO,
});
