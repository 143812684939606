import {request} from './Api/Api';
import {IChatRequest} from '../definitions/chatpage/chatpage.definitions';

export const chatRequest = (payload: IChatRequest) => request({url: 'chat', method: 'GET'}, payload);

export const sendChatRequest = (
    userId: number,
    message = '',
    attachment: string | null = '',
    client_uid: string,
    chatBlockId?: number,
) => {
    const params: any = {
        id: userId,
        message,
        attachment,
        client_uid,
    };

    if (chatBlockId) {
        params.meta = {
            messagetype: 'chatblocks',
            chat_id: chatBlockId,
        };
    }

    return request({method: 'POST', url: 'chat'}, params);
};

export const sendNotificationRequest = (payload: any) => request({method: 'POST', url: 'chat/notification'}, payload);
