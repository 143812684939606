// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

import {applicationStore} from './redux/root.reducer';
import {showUpdateAvailable} from './redux/ui/ui.actions';
import {isActorAuthenticated} from "./redux/actor/actor.selectors";

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

export default function registerServiceWorker() {
    // Use service worker only in production
    if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {

        // Service worker only runs on secure pages so unregister it on localhost!
        if (isLocalhost) {
            unregisterSW(window.location.reload);

            return console.info("Service worker only runs on secure pages (https). Features relying on it like Push notifications won't work!");
        }

        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
            console.error(`SW: PUBLIC_URL ${publicUrl.origin} not in current domain ${window.location.origin}`);
            return;
        }

        // Wait for service worker to be installed unless all assets are loaded. Otherwise the service worker
        // would try to precache the files resulting in duplicate fetches slowing down page load times.
        window.addEventListener('load', () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

            // Register service worker and update handler
            registerServiceWorkerUpdateHandler();
            registerValidSW(swUrl);
        });
    }
}

const showUpdateAppButton = () => {
    // if authenticated, show button instead of immediately reloading the page
    if (isActorAuthenticated(applicationStore.getState())) {
        // eslint-disable-next-line no-console
        console.debug("SW: ask for update");
        applicationStore.dispatch(showUpdateAvailable());
    } else {
        // eslint-disable-next-line no-console
        console.debug("SW: skip waiting");
        navigator.serviceWorker.ready.then(registration =>  registration.waiting?.postMessage('skipWaiting'));
    }
};

function registerServiceWorkerUpdateHandler() {
    let isRefreshing = false;

    // Listen for controller changes (= new service worker getting active)
    navigator.serviceWorker.addEventListener('controllerchange', () => {
        // prevent infinite refresh loop when you use "Update on Reload"
        if (isRefreshing) {
            // eslint-disable-next-line no-console
            console.debug("SW: Controller changed while refreshing");
            return;
        }
        isRefreshing = true;

        // eslint-disable-next-line no-console
        console.debug('SW: Controller loaded');
        window.location.reload();
    });
}

function checkForUpdates(registration) {
    setTimeout(() => {
        console.info("SW: check for update");
        registration.update();

        checkForUpdates(registration);
    }, 30000);
}

function registerValidSW(swUrl) {
    navigator.serviceWorker
        .register(swUrl)
        .then(registration => {

            // frequently check for updates
            checkForUpdates(registration);

            if (!navigator.serviceWorker.controller) {
                // The window client isn't currently controlled so it's a new
                // service worker that will activate immediately
                // eslint-disable-next-line no-console
                console.debug("SW: Wait for controller to be activated.");
                return;
            }

            if (registration.waiting) {
                // eslint-disable-next-line no-console
                console.debug("SW: found waiting controller, show button");
                // SW is waiting to activate. Can occur if multiple clients open and
                // one of the clients is refreshed.
                return showUpdateAppButton();
            }

            if (registration.installing) {
                // eslint-disable-next-line no-console
                console.debug("SW: found installing controller, wait for installed and show button");
                registration.installing.addEventListener('statechange', (event) => {
                    if (event.target.state === 'installed') {
                        // A new service worker is available, inform the user
                        showUpdateAppButton();
                    }
                });
            }

            registration.onupdatefound = () => {

                // We only listen for new installing workers
                if (!registration.installing) {
                    // eslint-disable-next-line no-console
                    console.debug("SW: no installing worker");
                    return;
                }

                // eslint-disable-next-line no-console
                console.debug("SW: listen for state changes!");

                registration.installing.addEventListener('statechange', (event) => {
                    if (event.target.state === 'installed') {
                        if (!navigator.serviceWorker.controller) {
                            // At this point, everything has been precached.
                            // It's the perfect time to display a
                            // "Content is cached for offline use." message.
                            console.info('Content is cached for offline use.');

                            return;
                        }

                        // At this point, the updated precached content has been fetched, but the previous service
                        // worker will still serve the older content, See http://bit.ly/CRA-PWA
                        console.info('SW: New content is available and will be used when all tabs for this page are closed.');

                        // A new service worker is available, inform the user
                        showUpdateAppButton();
                    }
                });
            };

            console.info('service worker registered!');
        })
        .catch(error => {
            console.error(error);
        });
}

function unregisterSW(callback) {
    // Unregister service worker (if exists)
    navigator.serviceWorker.ready.then(registration => {
        return registration.unregister().then(callback);
    });
}

