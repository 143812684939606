import {Dispatch} from 'redux';

import {getTicketHistoryRequest} from '../../requests/ticketRequest';
import {uploadFormDataRequest} from '../../requests/uploadRequests';
import {getUserRequest, putUserDataRequest} from '../../requests/userRequest';
import {IState} from '../root.reducer';
import {getLiveChatConversationRequest} from "../../requests/messengerRequests";
import {
    CHAT_PAGE_ADD_INTERIM_CHAT,
    CHAT_PAGE_GET_LIVE_CHAT_CONVERSATION,
    CHAT_PAGE_GET_TICKET_HISTORY,
    CHAT_PAGE_GET_USER,
    CHAT_PAGE_PUT_USER,
    CHAT_PAGE_RESET,
    CHAT_PAGE_RESET_ATTACHMENT_PREVIEW,
    CHAT_PAGE_RESET_MESSAGE,
    CHAT_PAGE_RESET_UPLOAD,
    CHAT_PAGE_SEARCH_TERM_SET,
    CHAT_PAGE_SET_FOCUS_TEXT_FIELD,
    CHAT_PAGE_SET_MESSAGE,
    CHAT_PAGE_TOGGLE_ARCHIVE,
    CHAT_PAGE_TOGGLE_IMAGE_LIGHTBOX,
    CHAT_PAGE_TOGGLE_SIDEBAR,
    CHAT_PAGE_UPLOAD_MEDIA,
} from "./chatPage.types";


export const toggleChatPageImageLightbox = () => ({
    type: CHAT_PAGE_TOGGLE_IMAGE_LIGHTBOX,
});

export const setChatPageMessage = (message = '', attachment?: string, id?: number) => ({
    type: CHAT_PAGE_SET_MESSAGE,
    payload: {message, attachment, id},
});

export const resetChatPageMessage = () => ({
    type: CHAT_PAGE_RESET_MESSAGE,
});

export const resetChatPageUpload = () => ({
    type: CHAT_PAGE_RESET_UPLOAD,
});

export const resetChatPageAttachmentPreview = () => ({
    type: CHAT_PAGE_RESET_ATTACHMENT_PREVIEW,
});

export const setChatPageSearchTerm = (payload: string) => ({
    type: CHAT_PAGE_SEARCH_TERM_SET,
    payload,
});

export const putUser = (userId: number, data: any) => (dispatch: Dispatch, getState: () => IState) => {
    const oldUser = getState().chatPage.user;
    const newUser = {...oldUser, ...data};

    return dispatch({
        type: CHAT_PAGE_PUT_USER,
        payload: putUserDataRequest(userId, data),
        meta: {oldUser, newUser},
    });
};

export const uploadMedia = (postBody: any, messenger: string) => ({
    type: CHAT_PAGE_UPLOAD_MEDIA,
    payload: uploadFormDataRequest(postBody, messenger),
});

export const getUserForConversation = (userId: number, unread?: boolean) => ({
    type: CHAT_PAGE_GET_USER,
    payload: getUserRequest({id: userId, unread: unread ? 1 : 0, customfields: 1}),
    meta: {
        // we don't want to show errors when getting a user from another channel (which only happens on channel switch)
        silent: true,
        id: userId,
    },
});

export const resetChatPage = () => ({
    type: CHAT_PAGE_RESET,
});

export const setFocusTextField = (focus: boolean) => ({
    type: CHAT_PAGE_SET_FOCUS_TEXT_FIELD,
    payload: focus,
});

export const getTicketHistory = (userId: number) => ({
    type: CHAT_PAGE_GET_TICKET_HISTORY,
    payload: getTicketHistoryRequest(userId),
    meta: {id: userId},
});

export const getLiveChatConversation = (channelId: string, userId: string, conversationId?: string) => ({
    type: CHAT_PAGE_GET_LIVE_CHAT_CONVERSATION,
    payload: getLiveChatConversationRequest(channelId, userId, conversationId),
    meta: {silent: true, id: userId},
});

export const toggleSidebar = () => ({
    type: CHAT_PAGE_TOGGLE_SIDEBAR,
});

export const toggleArchive = (value: boolean) => ({
    type: CHAT_PAGE_TOGGLE_ARCHIVE,
    payload: value,
});

export const addInterimChat = (
    agentId: number,
    message?: string,
    notification?: string,
    attachment?: string,
    interimUuid?: string // this is the id to be used to remove the message in the fulfilled handle
) => ({
    type: CHAT_PAGE_ADD_INTERIM_CHAT,
    payload: {agentId, message, notification, attachment, interimUuid},
});
