import {createSelector} from 'reselect';

import {IChatPageUser} from '../../definitions/chatpage/chatpage.definitions';
import {ICustomField} from '../../definitions/customField/customField.definitions';
import {getCustomFieldsForUi} from '../customField/customField.selectors';
import {IState} from '../root.reducer';
import {IChatListUser} from "../../definitions/user/user.definitions";
import {lowerCase} from "lodash";

export const getFieldValue = (userCustomFields: ICustomField[], id: number) => {
    const fieldData = userCustomFields.find(field => field.id === id);
    if (!fieldData) {
        return '';
    }

    if (fieldData?.type === 'location' && fieldData?.value?.address) {
        return fieldData.value.address;
    }

    return fieldData.value;
};

const _getPanelUserInformationInitialValues = (
    activeConversationId: number,
    customFields: ICustomField[],
    isEqual: boolean,
    user?: IChatListUser,
    userProperties: ICustomField[] = [],
) => {
    if (!user || !isEqual) {
        return undefined;
    }

    const customerFieldValues = customFields.reduce(
        (map, {id, name}) => ({...map, [name]: getFieldValue(userProperties, id)}),
        {},
    );

    return {
        id: activeConversationId, // Add ticket ID to form data to compare in ThrottleFormProvider
        name: user.name,
        customfields: customerFieldValues,
    };
};

const _getUserInformationValues = (user?: IChatPageUser) => {
    const customFields = user?.customfields?.reduce((previousValue, currentValue) => {
        return {
            ...previousValue,
            [currentValue.id.toString()]: currentValue.value,
        };
    }, {});

    return {
        userId: user?.id,
        name: user?.name,
        customfields: customFields,
    };
};

export const getChatPageUser = (state: IState) => state.chatPage.user;

export const getChatPageUserId = (state: IState) => getChatPageUser(state)?.id;

export const getChatPageCustomFields = (state: IState) => getChatPageUser(state)?.customfields;

export const isChatPageUserActive = (state: IState): boolean => !!getChatPageUser(state)?.status;

export const getActiveConversationId = (state: IState) => state.chatPage.activeConversationId;

export const isActiveConversationIdEqualToPropTicketId = (state: IState, props: any, conversationId: number) =>
    state.chatPage.activeConversationId === conversationId;

export const createGetPanelUserInformationInitialValuesSelector = () =>
    createSelector(
        [
            getActiveConversationId,
            getCustomFieldsForUi,
            isActiveConversationIdEqualToPropTicketId,
            getChatPageUser,
            getChatPageCustomFields,
        ],
        _getPanelUserInformationInitialValues,
    );

export const getUserInformationValues = createSelector(
    [getChatPageUser],
    _getUserInformationValues,
);

export const getChatPageTicket = (state: IState) => state.chatPage.ticket;

export const getChatPageChats = (state: IState) => state.chatPage.chats;

export const getChatPageSearchTerm = (state: IState) => state.chatPage.searchTerm || '';

export const isChatPageSidebarOpen = (state: IState) => !!state.chatPage.isSidebarOpen;

export const isShowArchive = (state: IState) => state.chatPage.showArchive;

export const isLastMessageSatellite = (state: IState) => lowerCase(state.chatPage.chats?.[0]?.meta?.messageType) === 'rsa';