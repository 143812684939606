export const NOTES_GET = 'NOTES_GET';
export const NOTES_GET_PENDING = 'NOTES_GET_PENDING';
export const NOTES_GET_FULFILLED = 'NOTES_GET_FULFILLED';
export const NOTES_GET_REJECTED = 'NOTES_GET_REJECTED';

export const NOTES_POST = 'NOTES_POST';
export const NOTES_POST_PENDING = 'NOTES_POST_PENDING';
export const NOTES_POST_FULFILLED = 'NOTES_POST_FULFILLED';
export const NOTES_POST_REJECTED = 'NOTES_POST_REJECTED';

export const NOTES_PUT = 'NOTES_PUT';
export const NOTES_PUT_PENDING = 'NOTES_PUT_PENDING';

export const NOTES_PUT_REJECTED = 'NOTES_PUT_REJECTED';

export const NOTES_DELETE = 'NOTES_DELETE';
export const NOTES_DELETE_PENDING = 'NOTES_DELETE_PENDING';

export const NOTES_DELETE_REJECTED = 'NOTES_DELETE_REJECTED';
