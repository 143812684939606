import {FC} from 'react';

import {Theme} from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import moment from 'moment';

import translations from '../../../providers/TranslationProvider/translations';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        periodInfo: {
            alignItems: 'center',
            color: theme.palette.text.secondary,
            display: 'none',
            fontSize: 14,
            marginRight: theme.spacing(1),
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',

            [theme.breakpoints.up('sm')]: {
                display: 'flex',
            },
        },
    }),
);

interface IStatisticsPeriodInfoProps {
    periodFrom: number;
    periodTo: number;
}

const StatisticsPeriodInfo: FC<IStatisticsPeriodInfoProps> = ({periodFrom, periodTo}) => {
    const classes = useStyles();
    const from = moment.unix(periodFrom).format(translations.date_picker_format);
    // use now as the end of the period if it is 0 (API does the same)
    const to = moment.unix(periodTo || moment().unix()).format(translations.date_picker_format);

    return (
        <div className={classes.periodInfo}>
            {from} - {to}
        </div>
    );
};

export {StatisticsPeriodInfo};
