import {Reducer} from 'react';

export const FETCH_THROTTLE_TIME = 5000;
export const FORCE_FETCH_THROTTLE_COUNT = 10;

type TAction = 'increment' | 'reset' | {type: 'set' | 'add' | 'subtract'; payload: number};

const counterReducer: Reducer<number, TAction> = (
    state = 0,
    action,
) => {
    const _action = typeof action === 'string' ? action : action.type;
    const _value = typeof action === 'string' ? 0 : action.payload;

    // noinspection PointlessBooleanExpressionJS
    switch (_action) {
        case undefined || 'increment':
            return state + 1;
        case 'reset':
            return 0;
        case 'set':
            return _value;
        case 'add':
            return state + _value;
        case 'subtract':
            return state + -_value;
        default:
            throw new Error(`Invalid action ${action}`);
    }
};

export default counterReducer;
