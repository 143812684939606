import {SubReducer} from '../../definitions/generic/generic.definitions';
import {IJob, IJobEntityState} from '../../definitions/jobs/jobs.definitions';
import {createEntityReducer} from '../entity/entity.reducer';
import {
    JOBS_GET_FULFILLED,
    JOBS_GET_LAST_FULFILLED,
    JOBS_GET_LAST_PENDING,
    JOBS_GET_LAST_REJECTED,
    JOBS_GET_PENDING,
    JOBS_GET_REJECTED,
} from './jobs.types';
import {UPDATE_STREAM} from "../connection/connection.types";
import {isJobUserImport, handleJobUpdateStream} from "./jobs.reducer.utils";

const jobsInitialState = {
    errors: [],
    enabledLoader: true,
};

export const additionalReducer: SubReducer<IJobEntityState> = (state, action) => {
    const {type, payload} = action;

    switch (type) {
        case UPDATE_STREAM: {
            if (isJobUserImport(payload)) {
                return handleJobUpdateStream(state, payload?.message?.payload?.job, payload?.message?.payload?.errors);
            }
            break;
        }
        case JOBS_GET_LAST_FULFILLED: {
            return {
                ...state,
                errors: payload.errors || [],
            };
        }
    }

    return state;
};

export const jobsReducer = createEntityReducer<IJob, IJobEntityState>(
    'jobs',
    [
        JOBS_GET_LAST_PENDING,
        JOBS_GET_LAST_FULFILLED,
        JOBS_GET_LAST_REJECTED,
        JOBS_GET_PENDING,
        JOBS_GET_FULFILLED,
        JOBS_GET_REJECTED,
    ],
    additionalReducer,
    jobsInitialState,
);
