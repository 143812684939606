import LoaderSpinner from "../../../Loader/LoaderSpinner";
import Box from "@material-ui/core/Box";
import {FC} from "react";
import {OVERLAY_LOADER_DEFAULT_SIZE} from "../../../OverlayLoader/OverlayLoader";


const TableLoader:FC = () => {

    return (
        <Box
            position={'absolute'}
            width={'100%'}
            height={'100%'}
            display={'flex'}
            alignItems={'center'}
            flexGrow={1}
            zIndex={1}
        >
            <LoaderSpinner loading={true} proportions={OVERLAY_LOADER_DEFAULT_SIZE}/>
        </Box>
    );
};

export default TableLoader;