import {Reducer} from 'redux';
import {IUpdateStreamMessage} from '../../../definitions/updateStream/updateStream.definitions';
import {EUpdateStreamAction} from '../../../enums/updateStream/EUpdateStreamAction';
import {EUpdateStreamMethod} from '../../../enums/updateStream/EUpdateStreamMethod';
import {UPDATE_STREAM} from '../../connection/connection.types';
import {
    SETTINGS_WIDGET_GET_ALL_FULFILLED,
    SETTINGS_WIDGET_GET_ALL_PENDING,
    SETTINGS_WIDGET_GET_ALL_REJECTED,
    SETTINGS_WIDGET_GET_BY_ID_FULFILLED,
    SETTINGS_WIDGET_GET_POSSIBLE_SETTINGS_FULFILLED,
    SETTINGS_WIDGET_GET_VIBER_TOKEN_FULFILLED,
    SETTINGS_WIDGET_RESET,
    SETTINGS_WIDGET_RESET_VIBER_SUCCESS,
    SETTINGS_WIDGET_SEND_VIBER_MESSAGE_FULFILLED,
    SETTINGS_WIDGET_SET_STEP,
    WIDGET_EDITOR_CHANGE_VALUE,
    WIDGET_EDITOR_EDIT_WIDGET,
    WIDGET_EDITOR_RESET,
    WIDGET_EDITOR_RESET_CHIP_CONFIG,
    WIDGET_EDITOR_SAVE_WIDGET_FULFILLED,
    WIDGET_EDITOR_SET_DEFAULTS,
} from './widgetEditor.types';
import {
    ISettingsWidgets,
    ISettingsWidgetState,
    IWidget,
    IWidgetEditorForm,
    IWidgetLiveChatCustomization,
} from '../../../definitions/settings/widgetEditor/widgetEditor.definitions';
import {EWidgetDialogSteps, EWidgetTypeId} from '../../../enums/widget/EWidget';

const chipDefaultStyling = {
    chipBackgroundColor: '#09C697',
    chipBackgroundColorHover: '#ffffff',
    chipIconColor: '#ffffff',
    chipIconColorHover: '#000000',
    chipBackgroundImage: null,
    notificationBubble: false,
};

const cardNCubeDefaultStyling = {
    backgroundColorLeft: '#BCBCBC',
    backgroundColorRight: '#EFEFEF',
    textColor: '#000000',
    buttonColor: '#09C697',
    buttonTextColor: '#ffffff',
};

export const liveChatDefaultSettings: Partial<IWidgetLiveChatCustomization> = {
    liveChatCustomize: false,
    liveChatMode: 'light',
    liveChatShowFab: 1000,
    liveChatOpenChat: 0,
    liveChatChatWidth: 360,
    liveChatChatHeight: 460,
    liveChatShowUserName: true,
    liveChatShowUserImage: false,
};

const widgetFormInitial: IWidgetEditorForm = {
    // Basics
    uniqueid: undefined,
    name: '',
    language: '',
    messengers: {},
    im_icon_only: 0,
    // Privacy
    privacy_check: 0,
    privacy_link: '',
    privacy_text: '',
    // WidgetType
    widget_type_id: EWidgetTypeId.Card,
    // Chip
    ...chipDefaultStyling,
    // Cube
    customButtonColorEnabled: 0,
    cubeWidth: '360',
    cubeHeight: '360',
    // Card N Cube
    body: '',
    title: '',
    ...cardNCubeDefaultStyling,
    // Live Chat
    ...liveChatDefaultSettings,
};

export const settingsWidgetsInitialState: ISettingsWidgetState = {
    widgets: {},
    step: EWidgetDialogSteps.Basics,
    token: null,
    settings: {
        messengers: {},
        configOptions: {},
    },
    widgetForm: widgetFormInitial,
    messengerLanguage: '',
};

export const widgetEditorReducer: Reducer<ISettingsWidgetState> = (state = settingsWidgetsInitialState, action) => {
    switch (action.type) {
        case WIDGET_EDITOR_SAVE_WIDGET_FULFILLED:
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    [action.payload.widget.uniqueid]: {...action.payload.widget},
                },
                widgetForm: {
                    ...state.widgetForm,
                    uniqueid: action.payload.widget.uniqueid, // set unique id in case we created a new widget
                },
            };
        case WIDGET_EDITOR_SET_DEFAULTS:
            return {
                ...state,
                widgetForm: {
                    ...state.widgetForm,
                    ...action.payload,

                },
            };
        case WIDGET_EDITOR_EDIT_WIDGET: {
            const widget = state.widgets?.[action.payload.id];
            if (!widget) {
                return state;
            }

            const {settings, ...common} = widget;

            const configOptions = Object.keys(settings).reduce((_settings, key) => {
                return {
                    ..._settings,
                    // @ts-ignore
                    [key]: settings[key].value,
                };
            }, {});

            return {
                ...state,
                widgetForm: {
                    ...common,
                    ...configOptions,
                    // @ts-ignore
                    im_icon_only: settings.im_icon_only?.value || 0,
                },
            } as ISettingsWidgetState;
        }
        case WIDGET_EDITOR_RESET_CHIP_CONFIG:
            return {
                ...state,
                widgetForm: {
                    ...state.widgetForm,
                    ...chipDefaultStyling,
                },
            };
        case WIDGET_EDITOR_CHANGE_VALUE:
            return {
                ...state,
                widgetForm: {...state.widgetForm, [action.payload.key]: action.payload.value},
            };
        case WIDGET_EDITOR_RESET:
            return {
                ...state,
                step: EWidgetDialogSteps.Basics,
                widgetForm: {
                    ...widgetFormInitial,
                },
            };
        case SETTINGS_WIDGET_GET_BY_ID_FULFILLED: {
            const newWidget = action.payload.widget;
            const widgets = {
                ...state.widgets,
                [newWidget.uniqueid]: newWidget,
            };

            return {...state, widgets, channelName: action.payload.channelName};
        }

        case SETTINGS_WIDGET_GET_VIBER_TOKEN_FULFILLED: {
            return {...state, viberToken: action.payload.token};
        }

        case SETTINGS_WIDGET_SEND_VIBER_MESSAGE_FULFILLED: {
            if (action && action.payload && action.payload.code === 400) {
                return {...state, viberSuccess: false};
            }

            return {...state, viberSuccess: true};
        }

        case SETTINGS_WIDGET_RESET_VIBER_SUCCESS:
            return {...state, viberSuccess: false};

        case SETTINGS_WIDGET_SET_STEP:
            return {...state, step: action.payload};

        case SETTINGS_WIDGET_RESET:
            return {...settingsWidgetsInitialState};

        case SETTINGS_WIDGET_GET_ALL_PENDING:
            return {...state, widgets_loading: true};

        case SETTINGS_WIDGET_GET_ALL_REJECTED:
            return {...state, widgets_loading: false};

        case SETTINGS_WIDGET_GET_ALL_FULFILLED:
            return {...state, widgets_loading: undefined, widgets: action.payload.widgets};

        case SETTINGS_WIDGET_GET_POSSIBLE_SETTINGS_FULFILLED: {
            const {messengers, settings} = action.payload;

            return {
                ...state,
                settings: {messengers, configOptions: settings},
                messengerLanguage: action.meta.language,
            };
        }
        case UPDATE_STREAM: {
            const {message} = action.payload;
            if (isSaveNewWidget(message) || isUpdateWidget(message)) {
                const newWidget = message.payload.widget as IWidget;
                const widgets = {
                    ...state.widgets,
                    [newWidget.uniqueid]: newWidget,
                };

                return {...state, widgets};
            }

            if (isDeleteWidget(message)) {
                const newWidgets = {} as ISettingsWidgets;
                const deletedWidgetId = message.payload.id;
                const widgetsWithoutDeletedOne = (Object.values({...state.widgets}) as IWidget[]).filter(
                    widget => widget.id !== deletedWidgetId,
                );

                widgetsWithoutDeletedOne.forEach(widget => (newWidgets[widget.uniqueid] = widget));

                return {...state, widgets: newWidgets};
            }
            return state;
        }
        default:
            return state;
    }
};

const isSaveNewWidget = (message: IUpdateStreamMessage) =>
    message.method === EUpdateStreamMethod.Post && message.action === EUpdateStreamAction.WidgetInfo;
const isUpdateWidget = (message: IUpdateStreamMessage) =>
    message.method === EUpdateStreamMethod.Put && message.action === EUpdateStreamAction.WidgetInfo;
const isDeleteWidget = (message: IUpdateStreamMessage) =>
    message.method === EUpdateStreamMethod.Delete && message.action === EUpdateStreamAction.WidgetInfo;
