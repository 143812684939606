
function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 48 48"
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M17 10a1 1 0 00-1 1v4h11a3 3 0 013 3v6h6a1 1 0 001-1V11a1 1 0 00-1-1H17zm13 16v3c0 2-.5 4-3 4h-7.183L10 38.93V33H8a3 3 0 01-3-3V18a3 3 0 013-3h6v-4a3 3 0 013-3h19a3 3 0 013 3v11h1a3 3 0 013 3v12a3 3 0 01-3 3h-2v5.7L27.74 40H21.5c-1.657 0-2.5-1.343-2.5-3v-.5l2-1.5v2a1 1 0 001 1h6.26L36 42.3V38h4a1 1 0 001-1V25a1 1 0 00-1-1h-1.17A3.001 3.001 0 0136 26h-6zM8 17a1 1 0 00-1 1v12a1 1 0 001 1h4v4.07L19.183 31H27c1 0 1-.5 1-2V18a1 1 0 00-1-1H8z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#FFBE3C"
                d="M9 22a1 1 0 011-1h15a1 1 0 110 2H10a1 1 0 01-1-1zM34 31.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM38 31.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM10 25a1 1 0 100 2h15a1 1 0 100-2H10z"
            ></path>
        </svg>
    );
}

export default Icon;
