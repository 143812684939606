import {useEffect} from 'react';

import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import PaperTemplate from '../../components/PaperTemplate/PaperTemplate';
import PaperTitleBar from '../../components/PaperTitleBar/PaperTitleBar';
import translations from '../../providers/TranslationProvider/translations';
import ZebraList from '../../components/ZebraList/ZebraList';
import ERights from '../../enums/actor/ERights';
import {EBaseRoutes} from '../../enums/routes/ERoutes';
import {getActor} from '../../redux/actor/actor.selectors';
import {IState} from '../../redux/root.reducer';
import {getOldTicketStats, getStatisticsMau} from '../../redux/statistics/statistics.actions';
import {setFilter} from '../../redux/ticketPage/ticketPage.actions';
import {actorHasRights} from '../../utils/actor/actor.utils';
import MonthlyActiveUser from '../StatisticsPage/StatisticPanels/Users/MonthlyActiveUser';
import {defaultFilterState, TicketFilterConfig} from '../../components/TicketTable/Filter/filter.constants';

const DashboardStatistics = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const actor = useSelector(getActor);
    const generalStats = useSelector((state: IState) => state.statistics.general || {});
    const hasRightsForStatistics = actorHasRights(actor, [ERights.StatisticsChats]);

    const handleClick = (tickets?: string) => {
        if (tickets) {
            dispatch(setFilter({...defaultFilterState, tickets}));
        }
        history.push(EBaseRoutes.TicketList);
    };

    const data = [
        {
            label: translations.all_open_tickets,
            value: Number(generalStats.tickets_open_total) || '-',
            valueClickHandler: () => handleClick(TicketFilterConfig.OPEN.filterKey),
        },
        {
            label: translations.tickets_unassigned_total,
            value: Number(generalStats.tickets_unassigned_total) || '-',
            valueClickHandler: () => handleClick(TicketFilterConfig.UNASSIGNED.filterKey),
        },
        {
            label: translations.open_tickets_unsanswered,
            value: Number(generalStats.tickets_open_unanswered) || '-',
            valueClickHandler: () => handleClick(TicketFilterConfig.PENDING.filterKey),
        },
        {
            label: translations.open_tickets_answered,
            value: Number(generalStats.tickets_open_answered) || '-',
            valueClickHandler: () => handleClick(TicketFilterConfig.ANSWERED.filterKey),
        },
        {
            label: translations.statistics_mau,
            value: <MonthlyActiveUser />,
            valueClickHandler: () => history.push(EBaseRoutes.Statistics),
            hidden: !hasRightsForStatistics,
        },
    ];

    useEffect(() => {
        dispatch(getOldTicketStats());
        // poll data every 30 seconds (we have no update stream for this, because it would be to performance costly)
        const poll = setInterval(() => dispatch(getOldTicketStats()), 30000);
        return () => clearInterval(poll);
    }, [dispatch]);

    useEffect(() => {
        // only do this when the actor actually has the rights to see it
        if (hasRightsForStatistics) {
            // get 2 months back to show difference
            const to = moment().unix();
            const from = moment().subtract(2, 'months').unix();

            dispatch(getStatisticsMau({from, to, by_messenger: true}));
        }
    }, [dispatch, hasRightsForStatistics]);

    return (
        <PaperTemplate stretch noPadding titleBar={<PaperTitleBar title={translations.dashboard_ticket_overview} />}>
            <ZebraList data={data} />
        </PaperTemplate>
    );
};

export default DashboardStatistics;
