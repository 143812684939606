import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getChannels} from '../../../redux/channel/channels.selectors';
import {useTypedSelector} from '../../../utils/hooks/useTypedSelector';
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import {changeChannel} from '../../../redux/actor/actor.actions';
import translations from '../../../providers/TranslationProvider/translations';
import IconButton from '@material-ui/core/IconButton';
import {EBaseRoutes} from '../../../enums/routes/ERoutes';
import {actorHasRights} from '../../../utils/actor/actor.utils';
import ERights from '../../../enums/actor/ERights';
import {getActor} from '../../../redux/actor/actor.selectors';
import BrandedIconSinch from '../../../components/BrandedIconsSinch/BrandedIconSinch';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            marginTop: 'auto',
            '& > button': {
                height: 48,
                width: 48,
                display: 'block',
                padding: 0,
            },
        },
        item: {
            margin: '4px auto',
        },
    }),
);

export const MultiChannelTicketAlerts = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const channels = useSelector(getChannels);
    const actor = useSelector(getActor);
    const multiChannelUnreadTickets = useTypedSelector(state => state.multiChannel);
    const [ticketId, setTicketId] = useState<number>();
    const [channelId, setChannelId] = useState<number>();

    useEffect(() => {
        // if there are no channels set there are no new unread ticket
        if (!channels || !Object.keys(channels).length || !multiChannelUnreadTickets) {
            setTicketId(undefined);
            setChannelId(undefined);
            return;
        }

        // search channels for new unread Tickets
        // return channelID for first result that contains at least one new unread ticketId
        const firstUnreadChannelId = Number(
            Object.keys(multiChannelUnreadTickets)
                .find(id => multiChannelUnreadTickets[Number(id)] && multiChannelUnreadTickets[Number(id)][0]),
        );
        // get first ticketId of that channel
        const firstUnreadTicketId = multiChannelUnreadTickets[firstUnreadChannelId]
            && multiChannelUnreadTickets[firstUnreadChannelId][0]
            && Number(multiChannelUnreadTickets[firstUnreadChannelId][0]);

        if (!firstUnreadChannelId || !firstUnreadTicketId) {
            return;
        }

        // check if there is a new unread Ticket and if yes -> set it unless it has already been set
        if (firstUnreadChannelId !== channelId || firstUnreadTicketId !== ticketId) {
            setChannelId(firstUnreadChannelId);
            setTicketId(ticketId);
        }
    }, [channels, channelId, ticketId, multiChannelUnreadTickets]);

    const openTicketInChannel = () => {
        if (!channelId || !ticketId) {
            return;
        }

        const referrer: any = actorHasRights(actor, [ERights.Administrate, ERights.TicketReadOthers])
            ? {pathname: EBaseRoutes.Assignment}
            : {pathname: EBaseRoutes.Chat};

        dispatch(changeChannel(channelId, referrer));
    };

    // do not show anything in case no unread tickets in other channels can be found
    if (!ticketId || !channelId) {
        return null;
    }

    return (
        <div className={classes.container}>
            <IconButton aria-label={translations.alert} onClick={openTicketInChannel}>
                <BrandedIconSinch menuIcon='Bell' className={classes.item} />
            </IconButton>
        </div>
    );
};
