import {ChangeEvent, FC, useState} from 'react';


import DialogContent from '@material-ui/core/DialogContent';
import {useDispatch} from 'react-redux';
import CustomAutocompleteTextField
    from '../../../../../components/CustomInputs/CustomAutocompleteTextField/CustomAutocompleteTextField';

import CustomTextField from '../../../../../components/CustomInputs/CustomTextField/CustomTextField';
import DialogTemplate from '../../../../../components/DialogTemplate/DialogTemplate';
import FormRow from '../../../../../components/FormRow/FormRow';
import AttachmentPreview from '../../../../../components/AttachmentPreview/AttachmentPreview';
import translations from '../../../../../providers/TranslationProvider/translations';
import {ITextModule} from '../../../../../definitions/textModule/textModule.definitions';
import {postTextModule} from '../../../../../redux/textModule/textModule.actions';

interface IAddTextModuleDialogProps {
    isOpen: boolean;
    initialTextModule: ITextModule;
    handleClose: () => void;
}

const AddTextModuleDialog: FC<IAddTextModuleDialogProps> = props => {
    const {isOpen, initialTextModule, handleClose} = props;
    const dispatch = useDispatch();

    const [newTextModule, setNewTextModule] = useState(initialTextModule);

    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        setNewTextModule({
            ...newTextModule,
            [event.target.name]: event.target.value,
        });
    };

    const validateForm = () => newTextModule.name.length >= 2 && newTextModule.message.length >= 2;
    const formIsValid = validateForm();

    const handleSubmit = () => {
        if (formIsValid) {
            dispatch(postTextModule(newTextModule));
        }
        handleClose();
    };

    const actionsConfig = [
        {
            label: translations.cancel,
            onClick: handleClose,
        },
        {
            label: translations.save,
            onClick: handleSubmit,
            disabled: !formIsValid,
            color: 'primary' as any,
            variant: 'contained' as any,
        },
    ];

    return (
        <DialogTemplate
            open={isOpen}
            onClose={handleClose}
            ariaLabel='text-module'
            title={translations.new_text_module_draft}
            closable={true}
            actionsConfig={actionsConfig}
            disableEnforceFocus // this allows the Popper menu of the AutocompleteTextField to gain focus
        >
            <DialogContent>
                <FormRow>
                    <CustomTextField
                        name='name'
                        label={translations.name}
                        value={newTextModule.name}
                        onChange={handleInput}
                        fullWidth
                    />
                </FormRow>
                {newTextModule.attachment && (
                    <FormRow>
                        <AttachmentPreview uploadUrl={newTextModule.attachment} />
                    </FormRow>
                )}
                <FormRow>
                    <CustomAutocompleteTextField
                        name='message'
                        label={translations.text}
                        value={newTextModule.message}
                        onChange={handleInput}
                        multiline
                        emoji
                    />
                </FormRow>
            </DialogContent>
        </DialogTemplate>
    );
};

export default AddTextModuleDialog;
