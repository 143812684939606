import {FC, useState} from 'react';

import MediaError from '../../MediaError/MediaError';

interface IAudioContent {
    media: string;
}

const AudioContent: FC<IAudioContent> = ({media}) => {
    const [error, setError] = useState(false);

    if (error) {
        return <MediaError fileUrl={media} />;
    }

    return (
        <audio controls>
            <source src={media} onError={() => setError(true)} />
        </audio>
    );
};

AudioContent.displayName = 'AudioContent';

export default AudioContent;
