import {SortDirectionType} from 'react-virtualized';
import {Reducer} from 'redux';
import {
    EAssignmentTicketSortTypes,
    IAssignmentTicketFilter,
    IAssignmentTicketViewMode,
} from '../../pages/AssignmentPage/config';

import {
    ASSIGNMENT_PAGE_SET_FILTER,
    ASSIGNMENT_PAGE_SET_SEARCH_TERM,
    ASSIGNMENT_PAGE_SET_SELECTED_TICKETS,
    ASSIGNMENT_PAGE_SET_SORTING,
    ASSIGNMENT_PAGE_SET_TICKET_TO_CLOSE,
    ASSIGNMENT_PAGE_SET_VIEW_MODE,
} from './assignmentPage.types';

export interface IAssignmentPageState {
    ticketToCloseId?: number;
    filter?: IAssignmentTicketFilter;
    viewMode?: IAssignmentTicketViewMode;
    selectedTickets?: Array<number>;
    searchTerm?: string;
    sortBy?: EAssignmentTicketSortTypes;
    sortDirection?: SortDirectionType;
}

const initialState: IAssignmentPageState = {};

export const assignmentReducer: Reducer<IAssignmentPageState> = (state = initialState, action) => {
    const {payload, type} = action;

    switch (type) {
        case ASSIGNMENT_PAGE_SET_TICKET_TO_CLOSE:
            return {...state, ticketToCloseId: action.payload};
        case ASSIGNMENT_PAGE_SET_VIEW_MODE:
            return {
                ...state,
                viewMode: payload,
            };
        case ASSIGNMENT_PAGE_SET_FILTER:
            return {
                ...state,
                filter: payload,
            };
        case ASSIGNMENT_PAGE_SET_SELECTED_TICKETS:
            return {
                ...state,
                selectedTickets: payload,
            };
        case ASSIGNMENT_PAGE_SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: payload,
            };
        case ASSIGNMENT_PAGE_SET_SORTING:
            return {
                ...state,
                sortDirection: payload.sortDirection,
                sortBy: payload.sortBy,
            };
        default:
            return state;
    }
};
