import {Reducer} from 'redux';
import {IEntityById, IEntityState} from '../../../definitions/generic/generic.definitions';
import {TTargetingActions} from '../../../definitions/mia/mia.actions.definitions';
import {ITargeting} from '../../../definitions/mia/mia.definitions';
import {
    MIA_GET_TARGETING_COUNT_FULFILLED,
    MIA_GET_TARGETING_COUNT_PENDING,
    MIA_GET_TARGETING_FULFILLED,
} from './targeting.types';

export interface ITargetingReducer extends IEntityState<ITargeting> {
    selectedId: number;
}

export const targetingInitState: ITargetingReducer = {
    byId: {} as IEntityById<ITargeting>,
    ids: [],
    selectedId: 0, // all users
};

export const targetingReducer: Reducer<ITargetingReducer, TTargetingActions> = (state = targetingInitState, action) => {
    switch (action.type) {
        case MIA_GET_TARGETING_FULFILLED:
            return {
                ...state,
                ids: Object.keys(action.payload.list).map(Number),
                byId: action.payload.list,
            };
        case MIA_GET_TARGETING_COUNT_PENDING:
            return {
                ...state,
                selectedId: action.meta.id,
            };
        case MIA_GET_TARGETING_COUNT_FULFILLED: {
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.meta.id]: {
                        ...state.byId[action.meta.id],
                        count: action.payload.count,
                    },
                },
            };
        }
        default:
            return state;
    }
};
