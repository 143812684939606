import {FC, useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import CustomIconButton from '../../../../../../components/CustomIconButton/CustomIconButton';
import {changeLocker} from '../../../../../../redux/ui/ui.actions';
import BrandedIconSinch from '../../../../../../components/BrandedIconsSinch/BrandedIconSinch';

const localStorageKey = 'drawerLock';

interface IDrawerLockProps {
    isLocked: boolean;
}

const DrawerLock: FC<IDrawerLockProps> = props => {
    const {isLocked} = props;
    const dispatch = useDispatch();

    const saveToLocalStorage = useCallback(
        () => window.localStorage.setItem(localStorageKey, JSON.stringify(!isLocked)),
        [isLocked],
    );

    const loadFromLocalStorage = useCallback(() => {
        const drawerLock = window.localStorage.getItem(localStorageKey);
        if (!drawerLock) {
            return false;
        }
        return JSON.parse(drawerLock);
    }, []);

    const handleClick = useCallback(() => {
        dispatch(changeLocker(!isLocked));
        saveToLocalStorage();
    }, [dispatch, isLocked, saveToLocalStorage]);

    useEffect(() => {
        const drawerLock = loadFromLocalStorage();
        dispatch(changeLocker(drawerLock));
    }, [dispatch, loadFromLocalStorage]);

    return (
        <div>
            <CustomIconButton
                icon={<BrandedIconSinch menuIcon='Lock' />}
                iconProps={{fontSize: 'small'}}
                onClick={handleClick}
                color={isLocked ? "primary" : "inherit"}
            />
        </div>
    );
};

export default DrawerLock;
