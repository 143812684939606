import React, {FC, useEffect, useMemo, useReducer, useState} from 'react';
import GoogleTagManager, {TagManagerArgs} from 'react-gtm-module';
import {useSelector} from 'react-redux';
import {InjectedFormProps, reduxForm} from 'redux-form';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';

import {
    GOOGLE_TAG_MANAGER_DATA_LAYER_NAME,
    GOOGLE_TAG_MANAGER_DEV_AUTH_KEY,
    GOOGLE_TAG_MANAGER_DEV_PREVIEW,
    GOOGLE_TAG_MANAGER_ID,
} from '../../../config';

import {IState} from '../../../redux/root.reducer';
import {
    composeAsyncValidators,
    validateEmailExists,
    validateMaxLength,
    validateRequired,
    validateRequiredEmail,
    validateTrue,
} from '../../../utils/formValidations/formValidation.utils';
import counterReducer from '../../../utils/reducer/counter.reducer';
import {MAX_PASSWORD_LENGTH} from "../../../utils/password/password.utils";
import Button from "@material-ui/core/Button";
import translations from "../../../providers/TranslationProvider/translations";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const FORM_NAME = 'registration';

const tagManagerProps: TagManagerArgs = {
    gtmId: GOOGLE_TAG_MANAGER_ID,
    dataLayerName: GOOGLE_TAG_MANAGER_DATA_LAYER_NAME,
};

if (process.env.NODE_ENV !== 'production') {
    tagManagerProps.auth = GOOGLE_TAG_MANAGER_DEV_AUTH_KEY;
    tagManagerProps.preview = GOOGLE_TAG_MANAGER_DEV_PREVIEW;
}

interface ILoginFormProps {
    affiliate?: string;
    spkg?: string;
}

const RegistrationForm: FC<InjectedFormProps<any> & ILoginFormProps> = () => {

    const [loading] = useState(false);
    const [submitTries] = useReducer(counterReducer, 1);
    const [registrationCompleted] = useState(false);

    const formValues = useSelector((state: IState) => state.form?.registration?.values) || {};
    const {username: email, password, company, firstname, lastname, phone, country} = formValues;
    const language = useSelector((state: IState) => state.ui.language);

    const dataLayer = useMemo(
        () => ({
            language: language,
            page: 'registration',
            formData: {
                company,
                email,
                password: !!password,
                firstname,
                lastname,
                phone,
                country,
            },
            submitTries,
            registrationCompleted,
            error: undefined, // reset error
        }),
        [company, country, email, firstname, language, lastname, password, phone, registrationCompleted, submitTries],
    );

    useEffect(() => {
        GoogleTagManager.initialize(tagManagerProps);
    }, []);

    useEffect(() => {
      if (window && window.location) {
        window.location.href = `${process.env.REACT_APP_SINCH_ENGAGE_DOMAIN!}/signup${window.location.search}`;
      }
    }, []);

    useEffect(() => {
        if (registrationCompleted) {
            GoogleTagManager.dataLayer({
                dataLayer: {...dataLayer, event: 'submitFormSuccess'},
                dataLayerName: GOOGLE_TAG_MANAGER_DATA_LAYER_NAME,
            });
        }
    }, [dataLayer, registrationCompleted]);

    return (
        <OverlayLoader isLoading={loading}>
          <Box textAlign='center'>
            <Typography align={'center'}>{translations["redirect_failed_text"]}</Typography>
            <Button variant='contained'
                    color='primary'
                    onClick={() => window.location.href = `${process.env.REACT_APP_SINCH_ENGAGE_DOMAIN!}/signup${window.location.search}`}>
              {translations["follow_redirect"]}
            </Button>
          </Box>

        </OverlayLoader>
    );
};

const validate = (values: any) => ({
    company: validateRequired(values.company),
    country: validateRequired(values.country),
    firstname: validateRequired(values.firstname),
    lastname: validateRequired(values.lastname),
    username: validateRequiredEmail(values.username),
    password: validateRequired(values.password) || validateMaxLength(MAX_PASSWORD_LENGTH, values.password),
    legal: validateTrue(values.legal),
});

const validateEmail = (values: any) => validateEmailExists(values['username']);

const form = {
    form: FORM_NAME,
    destroyOnUnmount: true,
    validate,
    asyncValidate: composeAsyncValidators([validateEmail]),
    asyncBlurFields: ['username'],
};

export default reduxForm(form)(RegistrationForm);
