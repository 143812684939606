import {AnyAction, Reducer} from 'redux';
import {IEntityByKey, IEntityStateForUuids, SubReducer} from '../../definitions/generic/generic.definitions';
import {getItemsFromPayloadByStateKey, isTypeFulfilled, isTypePending, isTypeRejected} from '../utils';

export interface IEntityWithId {
    id: number | string;
}

export const createEntityReducer = <T extends IEntityWithId,
    S extends IEntityStateForUuids<T> = IEntityStateForUuids<T>,
    A extends AnyAction = AnyAction>(
    entityKey: string, // this is only necessary to find the right node in the legacy API response
    actionTypes: string[],
    additionalReducer?: SubReducer<S, A>,
    additionalInitialState?: Object,
): Reducer<S, A> => {
    const initialState = {
        ...additionalInitialState,
        byId: {} as IEntityByKey<T>,
        ids: [] as string[] | number[],
    } as S;

    return (state = initialState, action: A) => {
        const {type, payload, meta} = action;

        if (additionalReducer) {
            state = additionalReducer(state, action);
        }

        if (!entityKey || !state) {
            return state;
        }

        if (!actionTypes.includes(type)) {
            return state;
        }

        if (isTypePending(type)) {
            return state;
        }

        if (isTypeRejected(type)) {
            return state;
        }

        if (isTypeFulfilled(type)) {
            const payloadItems = getItemsFromPayloadByStateKey(payload, entityKey);
            const newIds: string[] = [];
            const newItems = payloadItems.reduce((result: IEntityByKey<T>, item: T) => {
                // push ids in the correct order
                newIds.push(String(item.id));

                // ...and use chats/notes array with more items
                return {
                    ...result,
                    [String(item.id)]: item,
                };
            }, {});

            const _newIds = [...new Set([...state.ids, ...newIds])];

            return {
                ...state,
                ids: _newIds,
                byId: {
                    ...state.byId,
                    ...newItems,
                },
                lastUpdated: meta.timestamp,
            };
        }

        return state;
    };
};
