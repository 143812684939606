import {IBotTopic} from '../../definitions/bot/botTopic.definitions';
import {GET_BOT_TOPICS_FULFILLED, GET_BOT_TOPICS_PENDING, GET_BOT_TOPICS_REJECTED} from '../bot/bot.types';
import {createEntityReducer} from '../entity/entity.reducer';
import {createHashedRequestReducer} from '../request/request.reducer';

export const botTopicsEntityReducer = createEntityReducer<IBotTopic>('botTopics', [
    GET_BOT_TOPICS_PENDING,
    GET_BOT_TOPICS_REJECTED,
    GET_BOT_TOPICS_FULFILLED,
]);

export const botTopicsRequestReducer = createHashedRequestReducer('botTopics', [
    GET_BOT_TOPICS_PENDING,
    GET_BOT_TOPICS_REJECTED,
    GET_BOT_TOPICS_FULFILLED,
]);
